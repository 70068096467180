import { MetaActivityAdLineItem } from "../../../../graphql/generated/schema";
import { Asset } from "../../../../components/Smartphone/Asset";
import * as Accordion from "@radix-ui/react-accordion";
import { MetricCardList } from "src/components/MetricCardList/MetricCardList";
import { Text } from "src/components/Text/Text";
import { Icon } from "src/components/Icon/Icon";
import {
  Announcement03,
  BarChart01,
  ChartBreakoutCircle,
  CreditCard02,
  Cursor02,
  CursorClick01,
  Eye,
  Repeat04,
  Wallet03,
} from "@untitled-ui/icons-react";
import { AccordionItem } from "src/modules/global/components/AccordionItem";
import format from "date-fns/format";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import MetaIcon from "src/icons/meta.svg?react";
import FacebookIcon from "src/modules/global/assets/facebook.svg?react";
import InstagramIcon from "src/icons/instagram-color.svg?react";
import { LineHeading } from "src/modules/global/components/LineHeading";
import classNames from "classnames";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";

export function OverviewTabContent({ adTemplate }) {
  const overviewStats = [
    {
      label: "Platform Support",
      value: "Meta Ads",
      icon: MetaIcon,
    },
    {
      label: "Platform Supported",
      value: (
        <div className="flex gap-6">
          <Icon icon={FacebookIcon} size="lg" />
          <Icon icon={InstagramIcon} size="lg" />
        </div>
      ),
    },
  ];

  const statsList: Array<{
    title: string;
    description: string;
    value: string | number;
    icon: any;
  }> = [
    {
      title: "Reach",
      description: "number of unique people exposed to your content",
      value: formatNumberByType(adTemplate.stats?.reach, "NUMBER"),
      icon: BarChart01,
    },
    {
      title: "Impression",
      description: "number of times your content is displayed",
      value: formatNumberByType(adTemplate.stats?.impressions, "NUMBER"),
      icon: Eye,
    },
    {
      title: "Conversions",
      description: "represent the number of users who take a desired action",
      value: formatNumberByType(adTemplate.stats?.conversions, "NUMBER"),
      icon: Repeat04,
    },
    {
      title: "Amount Spend",
      description:
        "money invested in a marketing campaign over a specific period",
      value: formatNumberByType(adTemplate.stats?.spend, "CURRENCY"),
      icon: CreditCard02,
    },
    {
      title: "Cost Per Result",
      description:
        "average amount spent to achieve a specific action or outcome",
      value: formatNumberByType(adTemplate.stats?.cpc, "CURRENCY"),
      icon: Wallet03,
    },
    {
      title: "Clicks",
      description:
        "number of times users interact with your content by clicking on a link",
      value: formatNumberByType(adTemplate.stats?.clicks, "NUMBER"),
      icon: Cursor02,
    },
  ];
  return (
    <div className="flex flex-col gap-32">
      <div className="border rounded-8 p-8">
        <MetricCardList
          stats={[
            {
              title: "Reach",
              value: formatNumberByType(adTemplate.stats?.reach, "NUMBER"),
              valueIcon: ChartBreakoutCircle,
            },
            {
              title: "Impression",
              value: formatNumberByType(
                adTemplate.stats?.impressions,
                "NUMBER",
              ),
              valueIcon: Announcement03,
            },
            {
              title: "Clicks",
              value: formatNumberByType(adTemplate.stats?.clicks, "NUMBER"),
              valueIcon: Cursor02,
            },
            {
              title: "CTR",
              value: formatNumberByType(adTemplate.stats?.ctr, "CURRENCY"),
              valueIcon: CursorClick01,
            },
          ]}
          columns={{
            xs: 4,
          }}
        />
      </div>
      <div className="flex flex-col gap-16">
        <LineHeading content={"Highlights"} />
        <div className="flex flex-col gap-12">
          {statsList.map((v) => (
            <div
              className={classNames(
                "flex justify-between py-6 items-center w-full relative",
              )}
            >
              <div className={"flex gap-6"}>
                <div className="flex gap-4">
                  <Icon icon={v.icon} />
                  <Text size="sm" weight="medium">
                    {v.title}
                  </Text>
                </div>
                <VerticalSeparator totalWidth={1} separatorHeight={10} />
                <Text size="xs" tone="subdued">
                  {v.description}
                </Text>
              </div>
              {v.value === "-" ? (
                <Text size="xs">-</Text>
              ) : (
                <BadgeV2 label={v.value} size="small" />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-16">
        <LineHeading content={"Information"} />
        <div className="flex flex-col gap-24">
          {overviewStats.map((v) => (
            <div className="flex flex-col gap-8">
              <Text tone="subdued" size="sm" weight="medium">
                {v.label}
              </Text>
              <div className="flex gap-8">
                {v.icon && <Icon icon={v.icon} />}
                {typeof v.value === "string" || typeof v.value === "number" ? (
                  <Text tone="neutral-700" weight="medium" size="sm">
                    {v.value}
                  </Text>
                ) : (
                  <>{v.value}</>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="flex flex-col gap-8">
        <Text tone="subdued" size="sm" weight="medium">
          Asset in this template
        </Text>
        <div className="flex flex-col">
          {adTemplate.adType === "CUSTOM_CREATIVE" && (
            <div>
              {adTemplate.LineItems.map((v, i) => (
                <LineItemCard lineItem={v} order={i} />
              ))}
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
}
function LineItemCard({
  lineItem,
  order,
}: {
  lineItem: MetaActivityAdLineItem;
  order: number;
}) {
  return (
    <Accordion.Root type="single" collapsible>
      <AccordionItem
        value={lineItem.id}
        className="border-0 border-b rounded-0"
        triggerClassName={"px-0 py-8"}
        trigger={
          <div className="flex gap-4 items-center">
            <Asset
              type={lineItem.PostAsset?.contentType}
              name={lineItem.headline}
              className={"w-6 h-6 border rounded-6 object-contain"}
              preview={lineItem.PostAsset?.uri}
            />
            <Text size="xs" weight="medium">
              {lineItem.PostAsset?.name}
            </Text>
          </div>
        }
      >
        <div></div>
      </AccordionItem>
    </Accordion.Root>
  );
}
