import { useFormikContext } from "formik";
import { MetaLeadCreateTypes } from "../misc/metaLeadCreateTypes";
import { MetaLeadFormPreview } from "src/components/MetaLeadFormPreview/MetaLeadFormPreview";
import facebookAvatar from "../../global/assets/facebookPreviewUserAvatar.png";
import { useKeenSlider } from "keen-slider/react";
import classNames from "classnames";
import { getTotalScreensCount } from "src/components/MetaLeadFormPreview/getTotalScreensCount";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react";
import { useEffect, useState } from "react";
import styles from "./MetaLeadFormPreviewSlider.module.scss";

export function MetaLeadFormPreviewSlider() {
  const { values } = useFormikContext<MetaLeadCreateTypes>();
  const totalSlides = getTotalScreensCount(values);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [sliderRef, sliderInstance] = useKeenSlider({
    initial: currentSlideIndex,
    slides: {
      number: totalSlides,
    },
    slideChanged(slideInstance) {
      setCurrentSlideIndex(slideInstance.track.details.abs);
    },
  });
  const slideClassName = classNames("keen-slider__slide", styles.slideContent);

  const slideArray = Array.from({ length: totalSlides }).map(
    (_, index) => index,
  );
  const hasPrev = currentSlideIndex !== 0;
  const hasNext = currentSlideIndex !== totalSlides - 1;

  // useEffect(() => {
  //   if (currentSlideIndex > totalSlides - 1) {
  //     // switch to last slide if the last slide is gone
  //     setCurrentSlideIndex(totalSlides - 1);
  //   }
  // }, [totalSlides, currentSlideIndex]);

  return (
    <div className={styles.previewSliderContainer}>
      <button
        className={classNames(styles.navigationButton, {
          [styles.navigationButtonDisabled]: !hasPrev,
        })}
        type="button"
        disabled={!hasPrev}
        onClick={() => sliderInstance.current?.prev()}
      >
        <ChevronLeft width={20} height={20} />
      </button>
      <div className={styles.previewScreenWithDots}>
        <div className="keen-slider" ref={sliderRef}>
          {slideArray.map((slideIndex) => (
            <div className={slideClassName} key={slideIndex}>
              <MetaLeadFormPreview
                screenIndex={slideIndex}
                leadForm={values}
                platform="FACEBOOK"
                userName="Your Brand Name"
                userAvatar={facebookAvatar}
              />
            </div>
          ))}
        </div>
        <div className={styles.navigationDots}>
          {slideArray.map((slideIndex) => (
            <button
              className={classNames(styles.navigationDot, {
                [styles.activeNavigationDot]: slideIndex === currentSlideIndex,
              })}
              type="button"
              onClick={() => {
                sliderInstance.current?.moveToIdx(slideIndex);
              }}
            />
          ))}
        </div>
      </div>

      <button
        className={classNames(styles.navigationButton, {
          [styles.navigationButtonDisabled]: !hasNext,
        })}
        type="button"
        disabled={!hasNext}
        onClick={() => sliderInstance.current?.next()}
      >
        <ChevronRight width={20} height={20} />
      </button>
    </div>
  );
}
