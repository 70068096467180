import React, { SVGAttributes } from "react";
import * as Select from "@radix-ui/react-select";
import styles from "./SelectButton.module.scss";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import classNames from "classnames";

interface Option {
  label: string;
  value: string;
  icon?: React.FC<SVGAttributes<any>>;
  disabled?: boolean;
}

export interface ISelectButton {
  size?: "small" | "medium" | "large";
  label?: React.ReactNode;
  icon?: React.FC<SVGAttributes<any>>;
  onChange: (value: string) => void;
  value: string;
  showValueOnLabel?: boolean;
  disabled?: boolean;
  options: Option[];
}

export function SelectButton({
  onChange,
  options,
  disabled,
  icon,
  label,
  showValueOnLabel,
  value,
  size = "medium",
}: ISelectButton) {
  const selectedOption = options.find((o) => o.value === value);
  const labelIcon =
    showValueOnLabel && selectedOption?.icon ? selectedOption?.icon : icon;
  const labelText =
    showValueOnLabel && selectedOption ? selectedOption?.label : label;

  return (
    <div className={styles.selectButton}>
      <Select.Root
        disabled={disabled}
        value={value}
        onValueChange={(value) => {
          // BUG in radix-ui/primitives
          // https://github.com/radix-ui/primitives/issues/3135
          if (value) {
            onChange(value);
          }
        }}
      >
        <ButtonV2
          size={size}
          variant="outline"
          as={Select.Trigger}
          leftIcon={labelIcon}
          rightIcon={ChevronDown}
        >
          {labelText}
        </ButtonV2>

        <Select.Portal>
          <Select.Content position="popper" className={styles.selectOptions}>
            {options.map((option) => {
              const Icon = option.icon;

              return (
                <Select.Item
                  key={option.value}
                  className={classNames(styles.selectOption, {
                    [styles.selectOptionWithIcon]: option.icon,
                    [styles.selectedSelectOption]: option.value === value,
                  })}
                  value={option.value}
                >
                  {Icon && <Icon width={16} height={16} />}
                  <span className={styles.selectOptionText}>
                    {option.label}
                  </span>
                </Select.Item>
              );
            })}
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
}
