// import { useContext } from "react";
import styles from "./AudienceQuickOverview.module.scss";
import StatCard from "src/components/StatCard/StatCard";
// import { useTinybirdQuery } from "src/lib/analyticsApi";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { MetricCard } from "src/components/MetricCard/MetricCard";
// import AuthContext from "src/stores/AuthContext";
// import { GraphLoadingError } from "src/modules/global/components/GraphLoadingError";
// import { convertDatetimeToDate } from "src/modules/global/functions/convertDatetimeToDate";
import MetaIcon from "src/icons/meta.svg?react";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";

type IAudienceQuickOverview = {
  insights: any;
  loading?: boolean;
  dateFrom?: string;
  dateTo?: string;
};

export function AudienceQuickOverview({ insights }: IAudienceQuickOverview) {
  const quickStats = [
    {
      title: "Estimate Size",
      subtitle: "Total size of Audience",
      value: insights?.estimatedSize,
      valueType: "STRING" as const,
    },
    {
      title: "ROAS",
      subtitle: "Return on Ad Spend by Audience",
      value: insights?.roas,
      valueType: "MULTIPLIER" as const,
      // learnMoreText: "asd",
    },
    {
      title: "CTR",
      subtitle: "Click through Rate by Audience",
      value: insights?.ctr,
      valueType: "MULTIPLIER" as const,
    },
    {
      title: "Total Spends",
      subtitle: "Spent on website by Audience",
      value: insights?.spend,
      valueType: "CURRENCY" as const,
    },
    {
      title: "Avg Spends",
      subtitle: "Average budget on Audience",
      value: insights?.spends,
      valueType: "CURRENCY" as const,
    },
  ];

  return (
    <div className="flex flex-col gap-16">
      <SectionHeader title={"Other relevant metrics"} />
      <div className={styles.quickOverview}>
        {quickStats.map((item, index) => (
          <div key={index}>
            <MetricCard
              value={
                item.valueType === "STRING"
                  ? item.value
                  : formatNumberByType(item.value, item.valueType)
              }
              title={item.title}
              sourceIcon={MetaIcon}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
