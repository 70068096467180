import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { WizardContext } from "src/components/Wizard/WizardContext";
import styles from "./ConnectWithMeta.module.scss";
import { useContext } from "react";

export function ConnectWithMeta({
  connectWithMetaFunc,
}: {
  connectWithMetaFunc: () => void;
}) {
  const { activeStepIndex } = useContext(WizardContext);
  return (
    <StepWizardStep
      isCompleted={activeStepIndex !== 0}
      isStepNotActive={activeStepIndex !== 0}
    >
      <div className={styles.section}>
        <div className={styles.contentSection}>
          <LegacySectionHeading
            heading={"Get started with meta business account"}
            description={
              "Connect Meta Platform to publish facebook and Instagram, ads, add signup forms, and access your Instagram ads in Macro’s Campaign builder. Manage Facebook and Audiences and Accounts"
            }
          />
        </div>

        <div>
          <ButtonV2 onClick={connectWithMetaFunc} size="small" variant="solid">
            Integrate Meta
          </ButtonV2>
        </div>
      </div>
    </StepWizardStep>
  );
}
