import { Audience } from "src/graphql/generated/schema";
import audiencePresets from "./audiencePresets";

export function generateAudienceInputFromAudienceSetAudience(
  audiences: Pick<Audience, "id" | "audiencePresetType" | "type">[],
  value: "EXCLUDE" | "INCLUDE"
) {
  return audiences?.reduce((acc, v) => {
    if (!v.audiencePresetType) {
      return acc;
    }
    const audiencePreset = audiencePresets.find(
      (a) => a.id === v.audiencePresetType
    );
    acc = {
      ...acc,
      [v.audiencePresetType]: {
        value: value,
        ...audiencePreset,
      },
    };
    return acc;
  }, {});
}
