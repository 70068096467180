import classNames from "classnames";
import { clampValue } from "src/lib/clampValue";
import styles from "./CharacterCounter.module.scss";

interface ICharacterCounter {
  maxCharacterCount: number;
  characterCount?: number;
  iconOnly?: boolean;
}

export function CharacterCounter({
  maxCharacterCount,
  characterCount,
  iconOnly,
}: ICharacterCounter) {
  const percentage = (characterCount / maxCharacterCount) * 100;
  // keeps 0 and 100 as the ceiling of the percentage
  const percentageCapped = clampValue(percentage, 0, 100);

  // Converts percentage into a set of dasharray and dashoffset
  // so we can show the count as a circular progress
  // https://nikitahl.com/svg-circle-progress
  const radiusOfProgressBar = 15;
  const circumferenceOfProgressBar = 2 * Math.PI * radiusOfProgressBar;
  const progressValue =
    (circumferenceOfProgressBar * (100 - percentageCapped)) / 100;

  return (
    <div className={styles.counter}>
      <span
        className={classNames(styles.characterCount, {
          [styles.limitExceeded]: percentage > 100,
          [styles.hide]: iconOnly,
        })}
      >
        {characterCount}/{maxCharacterCount}
      </span>

      <svg
        width={18}
        height={18}
        viewBox="-4.5 -4.5 45 45"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          r={radiusOfProgressBar}
          cx="18"
          cy="18"
          fill="transparent"
          stroke="#e0e0e0"
          strokeWidth="6"
          strokeDasharray={circumferenceOfProgressBar}
          strokeDashoffset="0"
        ></circle>
        <circle
          r={radiusOfProgressBar}
          cx="18"
          cy="18"
          stroke={
            percentage === 0
              ? "#e0e0e0"
              : percentage > 100
              ? "var(--color-critical)"
              : "#6D7175"
          }
          strokeWidth="6"
          strokeLinecap="round"
          strokeDashoffset={progressValue}
          fill="transparent"
          strokeDasharray={circumferenceOfProgressBar}
        ></circle>
      </svg>
    </div>
  );
}
