import File02 from "@untitled-ui/icons-react/build/esm/File02";
import styles from "./AdEmptyScreen.module.scss";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

export function AdEmptyScreen({ onSelectAd }: { onSelectAd: () => void }) {
  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.content}>
        <h3 className={styles.heading}>
          Choose an existing ad from the library to use in your activity
        </h3>
        <h4 className={styles.subheading}>
          To launch this activity, you'll need to select an Ad from the library.
          If you haven't created one yet, create an Ad in the library first
          before returning to the Activity Builder
        </h4>

        <div className={styles.buttonWrapper}>
          <ButtonV2 variant="outline" rightIcon={File02} onClick={onSelectAd}>
            Choose Creative
          </ButtonV2>
        </div>
      </div>
    </div>
  );
}
