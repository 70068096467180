import { Outlet, RouteObject } from "react-router-dom";
import { AppLayout } from "./components/AppLayout/AppLayout";
import Layout from "./components/Layout/Layout";
import { RequiresAuth } from "./components/RequiresAuth";
import { AudienceDuplicatePage } from "./modules/audience/pages/AudienceDuplicatePage";
import { AudienceListingPage } from "./modules/audience/pages/AudienceListingPage";
import { AudienceSetSinglePage } from "./modules/audience/pages/AudienceSetSinglePage";
import { AudienceWorkshopPage } from "./modules/audience/pages/AudienceWorkshopPage";
import { AcceptInvite } from "./modules/auth/pages/AcceptInvite";
import { Login } from "./modules/auth/pages/Login";
import { ActivityDuplicatePage } from "./modules/campaign-wizard/pages/CampaignDuplicatePage";
import { CampaignWorkshopPage } from "./modules/campaign-wizard/pages/CampaignWorkshopPage";
import { ActivitySinglePage } from "./modules/campaign/pages/ActivitySinglePage";
import { CampaignListingPage } from "./modules/campaign/pages/CampaignListingPage";
import { CampaignSinglePage } from "./modules/campaign/pages/CampaignSinglePage";
import { CreativeCreatePage } from "./modules/creative/pages/CreativeCreatePage";
import { CreativesListingPage } from "./modules/creative/pages/CreativesListingPage";
import { EditCreativePage } from "./modules/creative/pages/EditCreativePage";
import { CustomerCreatePage } from "./modules/customer/pages/CustomerCreatePage";
import { CustomerListingPage } from "./modules/customer/pages/CustomerListingPage";
import { Dashboard } from "./modules/dashboard/pages/Dashboard";
import { GlobalErrorHandler } from "./modules/global/components/GlobalErrorHandler";
import { NotFound } from "./modules/global/pages/NotFound";
import MetaIntegration from "./modules/metaIntegration/pages/MetaIntegration";
import { OnboardingPage } from "./modules/onboarding/pages/OnboardingPage";
import { OnboardingPage2 } from "./modules/onboarding/pages/OnboardingPage2";
import { OnboardingPage3 } from "./modules/onboarding/pages/OnboardingPage3";
import { OnboardingPage4 } from "./modules/onboarding/pages/OnboardingPage4";
import { ReportBuilder } from "./modules/reports/pages/ReportBuilder";
import Reports from "./modules/reports/pages/Reports";
import { OrganizationGeneralSettingsPage } from "./modules/settings/pages/OrganizationGeneralSettingsPage";
import { OrganizationBillingSettingsPage } from "./modules/settings/pages/OrganizationBillingSettingsPage";
import { OrganizationManageClientsPage } from "./modules/settings/pages/OrganizationManageClientsPage";
import { ClientUserNotificationPage } from "./modules/settings/pages/ClientUserNotificationPage";
import { OrganizationManageMembersPage } from "./modules/settings/pages/OrganizationManageMembersPage";
import { OrganizationUsagePage } from "./modules/settings/pages/OrganizationUsagePage";
import { ShopifyIntegration } from "./modules/shopifyIntegration/pages/ShopifyIntegrationPage";
import { WhatsappIntegration } from "./modules/whatsappIntegration/pages/WhatsappIntegration";
import { WhatsappIntegrationSingle } from "./modules/whatsappIntegration/pages/WhatsappIntegrationSingle";
import ShopifyAuthCallbackHandler from "./pages/Auth/Shopify/ShopifyAuthCallbackHandler";
import { Integration } from "./pages/Settings/Integration/Integration";
import { PasswordReset } from "./modules/auth/pages/PasswordReset";
import { PasswordResetInitiate } from "./modules/auth/pages/PasswordResetInitiate";
import { ClientSinglePage } from "./modules/settings/pages/ClientSinglePage";
import { ClientBrandSinglePage } from "./modules/settings/pages/ClientBrandSinglePage";
import { GoogleIntegration } from "./modules/googleIntegration/pages/GoogleIntegration";
import { CustomerSinglePage } from "./modules/customer/pages/CustomerSinglePage";
import { RedirectToActivityPage } from "./modules/campaign/pages/RedirectToActivityPage";

const fullWidthWithSidebarRoutes: RouteObject = {
  element: (
    <RequiresAuth>
      <AppLayout layoutType="fullWidthWithSidebar" />
    </RequiresAuth>
  ),
  errorElement: <GlobalErrorHandler />,
  children: [
    { path: "/", element: <Dashboard /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "*", element: <NotFound /> },
    { path: "/contacts/create", element: <CustomerCreatePage /> },
    { path: "/contacts/:id", element: <CustomerSinglePage /> },
    { path: "/contacts", element: <CustomerListingPage /> },
    { path: "/library/ad-library", element: <CreativesListingPage /> },
    { path: "/reports", element: <Reports /> },
    { path: "/audiences", element: <AudienceListingPage /> },
    { path: "/audience-sets/:id", element: <AudienceSetSinglePage /> },
    { path: "/campaigns", element: <CampaignListingPage /> },
    { path: "/campaigns/:id", element: <CampaignSinglePage /> },
    {
      path: "/activities/:id",
      element: <RedirectToActivityPage />,
    },
    {
      path: "/campaigns/:campaignId/activities/:id",
      element: <ActivitySinglePage />,
    },
  ],
};

const fullWidthNoSidebarRoutes: RouteObject = {
  element: (
    <RequiresAuth>
      <AppLayout layoutType="fullWidthNoSidebar" />
    </RequiresAuth>
  ),
  children: [
    { path: "/audiences/new", element: <AudienceWorkshopPage /> },
    { path: "/audiences/duplicate", element: <AudienceDuplicatePage /> },
    { path: "/campaigns/new", element: <CampaignWorkshopPage /> },
    { path: "/campaigns/duplicate", element: <ActivityDuplicatePage /> },
    { path: "/library/ad-library/create", element: <CreativeCreatePage /> },
    { path: "/library/ad-library/:id/edit", element: <EditCreativePage /> },
    { path: "/reports/new", element: <ReportBuilder /> },
  ],
};

const centerNoSidebarAuthRoutes: RouteObject = {
  element: (
    <RequiresAuth>
      <Layout layoutType="centeredNoSidebar">
        <Outlet />
      </Layout>
    </RequiresAuth>
  ),
  children: [
    { path: "/onboarding/pricing", element: <OnboardingPage2 /> },
    { path: "/onboarding/billing", element: <OnboardingPage3 /> },
    { path: "/onboarding/success", element: <OnboardingPage4 /> },
  ],
};

const centerNoSidebarPublicRoutes: RouteObject = {
  element: (
    <Layout layoutType="centeredNoSidebar">
      <Outlet />
    </Layout>
  ),
  children: [
    { path: "/auth/signup", element: <OnboardingPage /> },
    { path: "/auth/accept-invite", element: <AcceptInvite /> },
    { path: "/login", element: <Login /> },
    { path: "/password-recovery", element: <PasswordResetInitiate /> },
    { path: "/password-reset", element: <PasswordReset /> },
  ],
};

const settingsRoutes: RouteObject = {
  element: (
    <RequiresAuth>
      <AppLayout layoutType="fullWidthWithSidebar" />
    </RequiresAuth>
  ),
  children: [
    {
      path: "/settings/organization",
      element: <OrganizationGeneralSettingsPage />,
    },
    { path: "/settings/billing", element: <OrganizationBillingSettingsPage /> },
    { path: "/settings/usage", element: <OrganizationUsagePage /> },
    { path: "/settings/clients", element: <OrganizationManageClientsPage /> },
    { path: "/settings/clients/current", element: <ClientSinglePage /> },
    {
      path: "/settings/clients/current/brand",
      element: <ClientBrandSinglePage />,
    },
    { path: "/settings/members", element: <OrganizationManageMembersPage /> },
    {
      path: "/settings/notifications",
      element: <ClientUserNotificationPage />,
    },
    { path: "/settings/integrations", element: <Integration /> },
    { path: "/settings/integrations/shopify", element: <ShopifyIntegration /> },
    { path: "/settings/integrations/meta", element: <MetaIntegration /> },
    { path: "/settings/integrations/google", element: <GoogleIntegration /> },
    {
      path: "/settings/integrations/whatsapp/setup",
      element: <WhatsappIntegration />,
    },
    {
      path: "/settings/integrations/whatsapp",
      element: <WhatsappIntegrationSingle />,
    },
  ],
};

const shopifyAuthCallbackRoute: RouteObject = {
  element: <ShopifyAuthCallbackHandler />,
  path: "/auth/shopify/callback",
  errorElement: <GlobalErrorHandler />,
};

const routes: RouteObject[] = [
  fullWidthWithSidebarRoutes,
  fullWidthNoSidebarRoutes,
  centerNoSidebarAuthRoutes,
  centerNoSidebarPublicRoutes,
  settingsRoutes,
  shopifyAuthCallbackRoute,
];

export default routes;
