import { Form, Formik } from "formik";
import { MutableRefObject, useContext, useState } from "react";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { OtherTargetingFormState } from "../misc/audienceCreateTypes";
import { WizardStep } from "src/components/WizardStep/WizardStep";
import { ComboBoxV2 } from "src/components/ComboBoxV2/ComboBoxV2";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { minAgeOptions, maxAgeOptions } from "../../global/misc/ageOptions";
import { metaLanguages } from "../misc/metaLanguages";
import { SearchMd } from "@untitled-ui/icons-react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import * as Yup from "yup";
import { FormikError } from "src/components/FormikError/FormikError";
import { otherTargetingSchema } from "../misc/otherAudienceTargetingSchema";

interface IOtherAudienceTargeting {
  sectionRef: MutableRefObject<HTMLDivElement>;
}

export function OtherAudienceTargeting({
  sectionRef,
}: IOtherAudienceTargeting) {
  const { data, activeStepIndex, markStepAsDone } = useContext(WizardContext);
  const [isSkipping, setIsSkipping] = useState(false);

  const initialValues = {
    ...data,
    minAge: 18,
    maxAge: 65,
    gender: "ALL",
    languages: [],
  } as OtherTargetingFormState;

  const nextIndex = 5;

  async function handleSubmit(values: OtherTargetingFormState) {
    await markStepAsDone(nextIndex, values);
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={otherTargetingSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        isSubmitting,
        setFieldValue,
        resetForm,
        errors,
        submitCount,
        touched,
      }) => (
        <Form>
          <WizardStep
            isStepNotActive={activeStepIndex !== 4}
            isLastStep={false}
            ref={sectionRef}
            isCompleted={activeStepIndex > 4}
            title="Demographic Targeting Settings"
            description="Refine your target audience by specifying demographic details below to reach your ideal customers"
          >
            <div className="flex flex-col gap-20">
              <ComboBoxV2
                renderSelectedValue={(value: any) =>
                  metaLanguages.find((m) => m.value === value)?.label
                }
                placeholder="Search Language"
                label="Select Languages"
                icon={SearchMd}
                value={values.languages}
                options={metaLanguages.map((language) => ({
                  label: language.label,
                  value: language.value,
                }))}
                multiple
                onChange={(value) => {
                  setFieldValue("languages", value);
                }}
              />
              {errors.languages && (
                <FormikError
                  formikError={errors}
                  submitCount={submitCount}
                  touched={touched}
                  fieldName="languages"
                />
              )}

              <SelectV2
                isFixedWidth
                label="Select which gender you want to target"
                value={values.gender}
                options={[
                  { label: "All", value: "ALL" },
                  { label: "Male", value: "MALE" },
                  { label: "Female", value: "FEMALE" },
                ]}
                onChange={(value) => {
                  setFieldValue("gender", value);
                }}
              />
              {errors.gender && (
                <FormikError
                  formikError={errors}
                  submitCount={submitCount}
                  touched={touched}
                  fieldName="gender"
                />
              )}

              <div className="grid grid-cols-[200px_200px] gap-8">
                <SelectV2
                  label="Set minimum age"
                  options={minAgeOptions}
                  value={values.minAge}
                  error={(touched.minAge || !!submitCount) && errors.minAge}
                  onChange={(value) => {
                    setFieldValue("minAge", value);
                  }}
                />

                <SelectV2
                  label="Set maximum age"
                  options={maxAgeOptions}
                  value={values.maxAge}
                  error={(touched.maxAge || !!submitCount) && errors.maxAge}
                  onChange={(value) => {
                    setFieldValue("maxAge", value);
                  }}
                />
              </div>
            </div>

            <div className="pt-20 flex items-center justify-between">
              <div className="flex gap-8 justify-end">
                <ButtonV2 loading={isSubmitting} type="submit" variant="solid">
                  Create Audience
                </ButtonV2>
                <ButtonV2
                  type="submit"
                  loading={isSkipping}
                  disabled={isSubmitting}
                  onClick={async () => {
                    resetForm();
                    setIsSkipping(true);
                    await markStepAsDone(nextIndex, data);
                    setIsSkipping(false);
                  }}
                  variant="outline"
                >
                  Skip this step
                </ButtonV2>
              </div>
            </div>
          </WizardStep>
        </Form>
      )}
    </Formik>
  );
}
