import { PageHeader } from "src/components/PageHeader/PageHeader";
import {
  ShopifyIntegrationDocument,
  useShopifyIntegrationRevokeMutation,
  useShopifyIntegrationQuery,
} from "src/graphql/generated/schema";
import { IntegrationHeader } from "../../global/components/IntegrationHeader";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import HelpCircleIcon from "@untitled-ui/icons-react/build/esm/HelpCircle";
import FileIcon from "@untitled-ui/icons-react/build/esm/File02";
import ChatIcon from "@untitled-ui/icons-react/build/esm/MessageDotsCircle";
import styles from "./ShopifyIntegrationPage.module.scss";
import BoltIcon from "@untitled-ui/icons-react/build/esm/Zap";
import UserCheckIcon from "@untitled-ui/icons-react/build/esm/UserCheck01";
import BarLineChartIcon from "@untitled-ui/icons-react/build/esm/BarLineChart";
import ShoppingBagIcon from "@untitled-ui/icons-react/build/esm/ShoppingBag03";
import BookClosedIcon from "@untitled-ui/icons-react/build/esm/BookClosed";
import LinkExternalIcon from "@untitled-ui/icons-react/build/esm/LinkExternal01";
import shopifyProfile from "../assets/shopifyProfile.svg";
import { useSearchParams } from "react-router-dom";
import Building01Icon from "@untitled-ui/icons-react/build/esm/Building01";
import ExternalLinkIcon from "@untitled-ui/icons-react/build/esm/LinkExternal01";
import { ShopifyIntegrationWizard } from "../components/ShopifyIntegrationWizard";
import { ShopifyIntegrationSettings } from "../components/ShopifyIntegrationSettings";
import classNames from "classnames";

export function ShopifyIntegration() {
  const { error, data, loading } = useShopifyIntegrationQuery();
  const [searchParams] = useSearchParams();

  const [
    shopifyIntegrationRevokeFn,
    { loading: revokingShopifyIntegrationLoading },
  ] = useShopifyIntegrationRevokeMutation({
    refetchQueries: [
      {
        query: ShopifyIntegrationDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const breadcrumbs = [
    {
      label: "Settings",
      link: "/settings",
    },
    {
      label: "Integrations",
      link: "/settings/integrations",
    },
    {
      label: "Shopify",
      link: "/settings/integrations/shopify",
    },
  ];

  const showProfile =
    data.shopifyIntegration && !(searchParams.get("view") === "wizard");

  return (
    <>
      <PageHeader
        title={"Shopify Integration"}
        breadcrumbs={breadcrumbs}
        back="/settings/integrations"
        actions={
          <>
            <ButtonV2 size="small" variant="outline" leftIcon={FileIcon}>
              Help Docs
            </ButtonV2>
            <ButtonV2 size="small" variant="outline" leftIcon={ChatIcon}>
              Contact Support
            </ButtonV2>
          </>
        }
      />
      <div className={styles.page}>
        <IntegrationHeader
          isBeta={true}
          title="Shopify Store"
          description="Connect with Shopify store and sale to your contact with the help of our outreach marketing tool"
          imageSrc={shopifyProfile}
          isConnected={!!data?.shopifyIntegration}
          revokeIntegrationFn={shopifyIntegrationRevokeFn}
          revokeLoading={revokingShopifyIntegrationLoading}
        />

        <div className={styles.formWrapper}>
          {showProfile ? (
            <ShopifyIntegrationSettings />
          ) : (
            <ShopifyIntegrationWizard />
          )}

          <div
            className={classNames(styles.aboutSection, {
              [styles.leftLine]: !showProfile,
            })}
          >
            <h2 className={styles.aboutHeading}>About this Integration</h2>

            {data?.shopifyIntegration && (
              <div>
                <div className={styles.connectedStoreLabel}>
                  Connected Store
                </div>
                <a
                  href={`https://${data.shopifyIntegration.shop}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={styles.connectedStoreValue}
                >
                  <span className={styles.buildingIcon}>
                    <Building01Icon
                      color="var(--color-success-text)"
                      width={12}
                      height={12}
                    />
                  </span>
                  {data.shopifyIntegration.shopTitle}
                  <span>
                    <ExternalLinkIcon
                      color="var(--color-icon-disabled)"
                      width={16}
                      height={16}
                    />
                  </span>
                </a>
              </div>
            )}

            <div className={styles.aboutSubSection}>
              <h3 className={styles.aboutSubheading}>
                <BoltIcon width={14} height={14} /> Features unlocked
              </h3>
              <p className={styles.aboutText}>
                <UserCheckIcon width={14} height={14} /> Manage contacts and
                segment
              </p>
              <p className={styles.aboutText}>
                <BarLineChartIcon width={14} height={14} />
                Metrics & Analytics
              </p>
              <p className={styles.aboutText}>
                <ShoppingBagIcon width={14} height={14} />
                Product catalogue
              </p>
            </div>

            <div className={styles.aboutSubSection}>
              <h2 className={styles.aboutSubheading}>
                <HelpCircleIcon width={14} height={14} />
                Help & Support
              </h2>
              <p className={styles.aboutLink}>
                <BookClosedIcon width={14} height={14} />
                Documentation
                <LinkExternalIcon width={14} height={14} />
              </p>
              <p className={styles.aboutLink}>
                <FileIcon />
                Terms of services
                <LinkExternalIcon width={14} height={14} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
