import Close from "@material-symbols/svg-600/rounded/close.svg?react";
import Globe from "@material-symbols/svg-600/rounded/globe.svg?react";
import MoreHoriz from "@material-symbols/svg-600/rounded/more_horiz.svg?react";
import styles from "./FacebookFeed.module.scss";
import { LineItem } from "src/lib/types";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { useKeenSlider } from "keen-slider/react";
import { Asset } from "./Asset";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { transformUrlToConversionDomain } from "src/modules/campaign-wizard/functions/transformUrlToConversionDomain";
import ArrowLeft from "@material-symbols/svg-600/rounded/keyboard_arrow_left.svg?react";
import ArrowRight from "@material-symbols/svg-600/rounded/keyboard_arrow_right.svg?react";
import { FacebookShareIcon } from "src/modules/campaign-wizard/components/FacebookShareIcon";
import { FacebookCommentIcon } from "src/modules/campaign-wizard/components/FacebookCommentIcon";
import { FacebookLikeIcon } from "src/modules/campaign-wizard/components/FacebookLikeIcon";

interface IFacebookFeedContent {
  preview: string;
  type: string;
  imageName: string;
  title?: string;
  description?: string;
  link?: string;
  primaryText?: string;
  callToActionLabel?: string;
  showArrows?: boolean;
  activeSlide?: number;
  instanceRef?: any;
}

function FacebookFeedContent({
  preview,
  imageName,
  type,
  title,
  description,
  link,
  primaryText,
  callToActionLabel,
  instanceRef,
  activeSlide,
  showArrows = false,
}: IFacebookFeedContent) {
  return (
    <>
      <div className={styles.feedImageWrapper}>
        <div className={classNames(styles.feedImageContainer, styles.slide)}>
          {/* <AssetSlider
              preview={preview}
              mode={"post"}
              onSlideChange={(slider) =>
                setActiveSlide(slider.track.details.abs)
              }
            /> */}
          <Asset type={type} preview={preview} name={imageName} />
          {showArrows && (
            <>
              <button
                className={styles.arrowLeft}
                disabled={activeSlide === 0}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  instanceRef.current.prev();
                }}
              >
                <ArrowLeft className={styles.arrowIco} />
              </button>
              <button
                className={styles.arrowRight}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  instanceRef.current.next();
                }}
                disabled={preview ? activeSlide === preview?.length - 1 : true}
              >
                <ArrowRight className={styles.arrowIco} />
              </button>
            </>
          )}
        </div>
      </div>
      <div className={styles.feedFooterWrapper}>
        {link && (
          <p className={styles.link}>{transformUrlToConversionDomain(link)}</p>
        )}
        <div
          className={classNames(styles.feedFooterAction, {
            [styles.hasLink]: link,
          })}
        >
          <div className={styles.feedFooterActionText}>
            <p className={styles.feedFooterName}>{title || ""}</p>
            {!link && <p className={styles.feedFooterLink}>Website</p>}
          </div>
          <span className={styles.learnMore}>
            {snakeCaseToTitleCase(
              callToActionLabel ? callToActionLabel : "Shop now"
            )}
          </span>
        </div>
      </div>
    </>
  );
}

export function FacebookFeed({
  preview,
  primaryText,
  callToActionLabel,
  accountName,
  profilePicture,
  onSlideChange,
  defaultActiveSlide,
}: {
  preview?: LineItem | LineItem[];
  primaryText?: string;
  callToActionLabel?: string;
  accountName?: string;
  profilePicture?: string;
  onSlideChange?: (activeSlide: number) => void;
  defaultActiveSlide?: number;
}) {
  const [activeSlide, setActiveSlide] = useState(defaultActiveSlide);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: defaultActiveSlide,
    slideChanged: (slider) => {
      const activeSlide = slider.track.details.abs;
      setActiveSlide(activeSlide);
      if (onSlideChange) {
        onSlideChange(activeSlide);
      }
    },
  });

  // to manual trigger update of slider if previews change
  useEffect(() => {
    if (!instanceRef) return;
    instanceRef.current.update();
  }, [preview, instanceRef]);

  return (
    <div className={styles.feedContainer}>
      <div className={styles.feedCard}>
        <div className={styles.feedHeader}>
          <div className={styles.profile}>
            <img
              className={styles.avatar}
              width={26}
              height={26}
              src={profilePicture}
              // alt={"Profile Picture"}
            />
            <div className={styles.userDetails}>
              <div className={styles.username}>{accountName}</div>
              <div className={styles.time}>
                Sponsored <Globe className={styles.globeIco} />
              </div>
            </div>
          </div>
          <div className={styles.headerActions}>
            <MoreHoriz className={styles.moreVertIco} />
            <Close className={styles.storyClose} />
          </div>
        </div>
        {primaryText && <p className={styles.primaryText}>{primaryText}</p>}
        <div
          className={classNames("keen-slider", styles.slider)}
          ref={sliderRef}
        >
          {Array.isArray(preview) && preview.length > 0 ? (
            preview.map((v, idx) => (
              <div key={idx} className="keen-slider__slide">
                <FacebookFeedContent
                  type={v?.PostAsset?.contentType}
                  preview={v?.PostAsset?.uri}
                  imageName={v?.PostAsset?.name}
                  title={v?.headline}
                  description={v?.description}
                  link={v?.link}
                  primaryText={primaryText}
                  callToActionLabel={callToActionLabel}
                  activeSlide={activeSlide}
                  instanceRef={instanceRef}
                  showArrows
                />
              </div>
            ))
          ) : (
            <div className="keen-slider__slide">
              <FacebookFeedContent
                type={(preview as LineItem).PostAsset?.contentType}
                preview={(preview as LineItem).PostAsset?.uri}
                imageName={(preview as LineItem).PostAsset?.name}
                title={(preview as LineItem).headline}
                description={(preview as LineItem).description}
                link={(preview as LineItem).link}
                primaryText={primaryText}
                callToActionLabel={callToActionLabel}
              />
            </div>
          )}
        </div>
        <div className={styles.feedFooter}>
          <div className={styles.footerAction}>
            <FacebookLikeIcon className={styles.footerIco} />
            <span className={styles.footerText}>Like</span>
          </div>
          <div className={styles.footerAction}>
            <FacebookCommentIcon className={styles.footerIco} />
            <span className={styles.footerText}>Comment</span>
          </div>
          <div className={styles.footerAction}>
            <FacebookShareIcon className={styles.footerIco} />
            <span className={styles.footerText}>Share</span>
          </div>
        </div>
      </div>
    </div>
  );
}
