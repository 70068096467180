import * as Menu from "@radix-ui/react-dropdown-menu";
import styles from "./Dropdown.module.scss";
import classNames from "classnames";
import { ReactNode } from "react";
import { ButtonV2 } from "../ButtonV2/ButtonV2";

interface Item {
  children: ReactNode;
  loading?: boolean;
  onClick?: () => void;
  to?: string;
  target?: string;
  disabled?: boolean;
  isDestructive?: boolean;
  icon?: React.FC<React.SVGAttributes<any>>;
}

export interface IDropdown {
  title: React.ReactNode;
  items: Item[];
  btnClass?: string;
  className?: string;
}

/**
 * @deprecated Use DropdownV2
 */
export default function Dropdown({
  title,
  items,
  btnClass,
  className,
}: IDropdown) {
  return (
    <div className={styles.menu}>
      <Menu.Root>
        <Menu.Trigger className={classNames(styles.menuBtn, btnClass)}>
          {title}
        </Menu.Trigger>

        <Menu.Content className={styles.menuContainer}>
          {items.map((item, index) => (
            <Menu.Item
              key={index}
              onClick={item.onClick}
              className={classNames(styles.menuItem, {
                [styles.dangerBtn]: item.isDestructive,
                [styles.normalBtn]: !item.isDestructive,
              })}
            >
              {item.children}
            </Menu.Item>
          ))}
        </Menu.Content>
      </Menu.Root>
    </div>
  );
}
