import styles from "./SideDrawer.module.scss";
import * as Dialog from "@radix-ui/react-dialog";

export interface ISideDrawer {
  isOpen: boolean;
  onIsOpenChange: (i: boolean) => void;
  children: React.ReactNode;
  trigger: React.ReactNode;
  width: string | number;
  maxWidth?: string | number;
  closeOnOutsideClick?: boolean;
  closeOnEscapeButton?: boolean;
}

/**
 * the trigger should be a SideDrawerTrigger element which is defined below
 */
export function SideDrawer({
  trigger,
  isOpen,
  onIsOpenChange,
  children,
  width,
  maxWidth,
  closeOnEscapeButton,
  closeOnOutsideClick,
}: ISideDrawer) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      {trigger}
      <Dialog.Portal>
        <Dialog.Overlay
          onClick={(e) => {
            console.log(e);
            if (closeOnOutsideClick === false) {
              e.preventDefault();
            }
          }}
          className={styles.drawerOverlay}
        />

        <Dialog.Content
          onEscapeKeyDown={(e) => {
            if (closeOnEscapeButton === false) {
              e.preventDefault();
            }
          }}
          style={{ width, maxWidth }}
          className={styles.drawerContent}
        >
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export const SideDrawerTitle = Dialog.Title;

// export const SideDrawerDescription = Dialog.Description;

// export const SideDrawerClose = Dialog.Close;

export const SideDrawerTrigger = Dialog.Trigger;
