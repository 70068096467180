import ShoppingBag03 from "@untitled-ui/icons-react/build/esm/ShoppingBag03";
import SelectV2 from "src/components/SelectV2/SelectV2";
import {
  MetaProductCataloguesQuery,
  useMetaProductCataloguesQuery,
} from "src/graphql/generated/schema";

interface IMetaCatalogueSelector {
  adAccountId?: string;
  value: string;
  disabled?: boolean;
  placeholder?: string;
  btnClass?: string;
  emptyState?: {
    title: string;
    description: string;
    action?: React.ReactNode;
    to?: string;
  };
  onChange: (i: MetaProductCataloguesQuery["metaCatalogues"][0]) => void;
}

export function MetaCatalogueSelector({
  adAccountId,
  value,
  emptyState,
  placeholder,
  disabled,
  onChange,
}: IMetaCatalogueSelector) {
  const { data } = useMetaProductCataloguesQuery({
    variables: {
      adAccountId,
    },
  });

  let options =
    data?.metaCatalogues?.map((m) => ({
      label: m.name,
      value: m.id,
    })) ?? [];

  return (
    <SelectV2
      icon={ShoppingBag03}
      disabled={disabled}
      emptyState={emptyState}
      placeholder={placeholder}
      options={options}
      label={"Select a Catalogue"}
      value={value}
      onChange={(id) => {
        const selectedCatalogue = data.metaCatalogues.find((a) => a.id === id);
        onChange(selectedCatalogue);
      }}
    />
  );
}
