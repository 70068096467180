import PageInfo from "@material-symbols/svg-600/rounded/page_info.svg?react";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "src/components/Alert/Alert";
import { ErrorHandler } from "src/components/ErrorHandler";
import { IconButton } from "src/components/IconButton/IconButton";
import {
  CampaignDocument,
  CampaignsDocument,
  useActivityDeleteMutation,
  useActivityPublishMutation,
  useActivityQuery,
  useCampaignDeleteMutation,
  useCampaignQuery,
} from "src/graphql/generated/schema";
import { ExpandedBanner } from "src/modules/global/components/ExpandedBanner";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { DateFilter as CampaignDateFilter } from "src/modules/global/misc/dateFilterUtils";
import { useActivityAggregatedStats } from "../../global/functions/useActivityAggregatedStats";
import { ActivityAboutCard } from "../components/ActivityAboutCard";
import { ActivityAdsList } from "../components/ActivityAdsList";
import { ActivityAudienceReporting } from "../components/ActivityAudienceReporting";
import { ActivityOverviewLoader } from "../components/ActivityOverviewLoader";
import { ActivityOverviewStatCards } from "../components/ActivityOverviewStatCards";
import { ActivitySingleStatCards } from "../components/ActivitySingleStatCards";
import { getTinybirdDateFiltersFromDateFilter } from "../misc/campaignSingleFilters";
import styles from "./ActivitySinglePage.module.scss";
import { DropdownV2 } from "../components/DropdownV2";
import Header from "src/components/Header/Header";
import toast from "react-hot-toast";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { titleCase } from "src/lib/titleCase";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { EditBudgetModal } from "../components/EditBudgetModal";

const dateFilterOptions: {
  label: string;
  value: CampaignDateFilter;
}[] = [
  {
    label: "Last 7 Days",
    value: "LAST_7_DAYS",
  },
  {
    label: "Last 28 Days",
    value: "LAST_28_DAYS",
  },
  {
    label: "Last 3 months",
    value: "LAST_3_MONTHS",
  },
  {
    label: "Last 6 months",
    value: "LAST_6_MONTHS",
  },
  {
    label: "All time",
    value: "ALL_TIME",
  },
];

export function ActivitySinglePage() {
  const { id, campaignId } = useParams<"id" | "campaignId">();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedDate, setSelectedDate] =
    useState<CampaignDateFilter>("ALL_TIME");
  const { dateFrom, dateTo } = useMemo(
    () => getTinybirdDateFiltersFromDateFilter(selectedDate),
    [selectedDate],
  );
  const navigate = useNavigate();
  const [activityPublishFunc, { loading: activityPublishLoading }] =
    useActivityPublishMutation();
  const [activityDeleteFunc, { loading: activityDeleteLoading }] =
    useActivityDeleteMutation();
  const [campaignDeleteFunc, { loading: campaignDeleteLoading }] =
    useCampaignDeleteMutation();
  // const [
  //   campaignPresetDuplicateFunc,
  //   { loading: campaignPresetDuplicateLoading },
  // ] = useCampaignPresetDuplicateMutation();
  // const [activityUpdateStatusFunc, { loading: activityUpdateStatusLoading }] =
  //   useActivityStatusUpdateMutation();
  const {
    data: campaignData,
    loading: campaignLoading,
    error: campaignError,
  } = useCampaignQuery({
    variables: {
      campaignId: campaignId,
    },
  });

  const {
    data: activityData,
    loading: activityLoading,
    error: activityError,
  } = useActivityQuery({
    variables: {
      id,
    },
  });

  const {
    data: activityStatData,
    compareData,
    loading: activityStatLoading,
  } = useActivityAggregatedStats(id, {
    dateFrom,
    dateTo,
  });

  if (activityLoading || campaignLoading || activityStatLoading) {
    return <PageLoader />;
  }

  if (activityError || campaignError) {
    return <ErrorHandler error={activityError || campaignError} />;
  }

  const activity = activityData ? activityData.activity : null;
  const metaActivity = activity?.MetaActivity;
  const activitiesCount = campaignData
    ? campaignData?.campaign?.activitiesCount
    : 0;
  const metaAdsets = metaActivity?.MetaActivityAdsets || [];
  const hasProductSets =
    metaAdsets.length > 0
      ? !metaAdsets[0]?.Audience && !metaAdsets[0]?.MetaAudienceSet
      : true;

  async function onDelete() {
    try {
      if (activitiesCount > 1) {
        await activityDeleteFunc({
          variables: {
            id: id,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: CampaignDocument,
              variables: {
                campaignId: campaignId,
              },
            },
          ],
        });
        navigate(`/campaigns/${campaignId}`);
        return;
      }
      await campaignDeleteFunc({
        variables: {
          id: campaignId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CampaignsDocument,
            variables: {
              filters: {
                limit: 5,
                filter: "ERROR",
              },
              sortBy: {
                field: "CREATED_AT",
                direction: "DESC",
              },
            },
          },
        ],
      });
      navigate(`/campaigns`, {
        replace: true,
      });
      setShowDeleteAlert(false);
    } catch (err) {
      toast.error(err.message);
      setShowDeleteAlert(false);
    }
  }

  return (
    <div className={styles.page}>
      <Header
        title={activity.name}
        badges={
          <div>
            <BadgeV2
              label={titleCase(activity.status)}
              color={activity.status === "PAUSED" ? "yellow" : "green"}
            />
          </div>
        }
        showBack
        showNotifications={false}
        actions={
          <>
            <DropdownV2
              size="small"
              options={dateFilterOptions.map((v) => ({
                label: v.label,
                style: "plain",
                onClick: () => setSelectedDate(v.value),
                color: v.value === selectedDate ? "primary" : "normal",
              }))}
            >
              {dateFilterOptions.find((v) => v.value === selectedDate)?.label}
            </DropdownV2>
            <EditBudgetModal
              adsets={metaAdsets as any}
              activityId={activity.id}
            />
          </>
        }
      />
      {activity.status === "ERROR" && (
        <ExpandedBanner
          title={
            "Here are the possible issues, try again after fixing all issues"
          }
          type={"error"}
          actions={[
            {
              children: "Try Again",
              color: "danger",
              style: "solid",
              size: "small",
              borderRadius: "8px",
              width: "auto",
              loading:
                campaignDeleteLoading ||
                activityDeleteLoading ||
                activityPublishLoading,
              disabled:
                campaignDeleteLoading ||
                activityDeleteLoading ||
                activityPublishLoading,
              onClick: async () => {
                await activityPublishFunc({
                  variables: {
                    activityId: id,
                  },
                  refetchQueries: [
                    {
                      query: CampaignsDocument,
                      variables: {
                        sortBy: {
                          direction: "DESC",
                          field: "CREATED_AT",
                        },
                        filters: {
                          limit: 25,
                        },
                      },
                    },
                  ],
                });
                navigate("/campaigns");
              },
            },
          ]}
          children={activity?.errorMessage || "Unknown error occurred"}
          className={styles.bannerWrapper}
        />
      )}

      <div className={styles.main}>
        {metaActivity?.MetaActivityAds && (
          <>
            <ActivityOverviewStatCards
              statData={activityStatData}
              metaActivity={metaActivity as any}
            />
            <ActivityOverviewLoader
              statData={activityStatData}
              activityId={activity.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </>
        )}

        <div className={styles.section}>
          <div className={styles.headerWithAction}>
            <LegacySectionHeading heading="Other Relevant Metrics" />
            <IconButton
              icon={
                <PageInfo fill="rgba(26, 28, 29, 1)" width={16} height={16} />
              }
              color="subdued"
              style="outline"
              size="small"
            />
          </div>
          <div className={styles.statCardList}>
            <ActivitySingleStatCards
              activityStat={activityStatData}
              comparisonStat={compareData}
            />
          </div>
        </div>

        {/* HACK: If campaign uses ProductSets instead of normal audience and audience set then hide this section */}
        {!hasProductSets && (
          <div className={styles.section}>
            <ActivityAudienceReporting
              dateFrom={dateFrom}
              dateTo={dateTo}
              activityId={activity.id}
              metaActivityAdsets={metaAdsets}
            />
          </div>
        )}

        {metaActivity?.MetaActivityAds && (
          <ActivityAdsList
            activity={activity}
            ads={metaActivity?.MetaActivityAds}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        )}

        <div className={styles.section}>
          <SectionHeader title={"About this Activity"} />
          <div>
            <ActivityAboutCard activity={activity as any} />
          </div>
        </div>

        <Alert
          trigger={<></>}
          title={`Delete ${activitiesCount > 1 ? "activity" : "campaign"}`}
          isOpen={showDeleteAlert}
          onClose={function (): void {
            setShowDeleteAlert(false);
          }}
          primaryAction={{
            label: "Delete",
            loading: campaignDeleteLoading || activityDeleteLoading,
            onClick: onDelete,
          }}
          secondaryAction={{
            label: "Cancel",
            onClick: () => {
              setShowDeleteAlert(false);
            },
          }}
          description={
            activitiesCount > 1
              ? "All the ads in this activity will be deleted. Are you sure you want to delete"
              : "Are you sure you want to delete this campaign?"
          }
        />
      </div>
    </div>
  );
}
