import classNames from "classnames";
import { ReactNode } from "react";
import { BadgeV2, IBadgeV2 } from "../../modules/global/components/BadgeV2";
import styles from "./Badge.module.scss";

export interface IBadge {
  label: string | ReactNode;
  status?: "danger" | "success" | "attention" | "transparent" | "default";
  className?: string;
  /* @deprecated */
  icon?: ReactNode;
  leftAvatar?: {
    name?: string;
    email?: string;
    src?: string;
  };
  leftIconButton?: {
    icon?: React.FC<React.SVGAttributes<any>>;
    onClick?: () => void;
  };
  title?: string;
}

/**
 * @deprecated use BadgeV2
 */
export default function Badge({
  label,
  status,
  className,
  icon,
  leftAvatar,
  leftIconButton,
  title,
}: IBadge) {
  const statusToColor: Record<IBadge["status"], IBadgeV2["color"]> = {
    default: "purple",
    danger: "red",
    success: "green",
    attention: "yellow",
    transparent: "gray",
  };
  return (
    <BadgeV2
      label={label}
      color={statusToColor[status]}
      helpText={title}
      avatar={leftAvatar}
      leftIcon={leftIconButton?.icon}
      size="medium"
    />
  );
}
