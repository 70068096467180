import { useFormikContext } from "formik";
import { useState } from "react";
import styles from "./AudienceUseDefaultSettingsCheckbox.module.scss";
import InfoIcon from "@material-symbols/svg-600/rounded/info.svg?react";
import { AudienceFacebookSettingsModal } from "./AudienceFacebookSettingsModal";
import classNames from "classnames";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import { AudienceNameAndAccountFormState } from "../misc/audienceCreateTypes";

export function AudienceUseDefaultSettingsCheckbox() {
  const [isChangeAccountsModalOpen, setIsChangeAccountsModalOpen] =
    useState(false);
  const { values, setValues } =
    useFormikContext<AudienceNameAndAccountFormState>();

  return (
    <div className={styles.checkboxWrapper}>
      <span className={styles.checkboxLabel}>
        <Checkbox
          value="useDefaultMetaAccounts"
          onChange={() => {}}
          checked={values.useDefaultMetaAccounts}
        />
        Using Default Settings <InfoIcon width={16} height={16} />
      </span>

      <AudienceFacebookSettingsModal
        initialFormValues={values}
        handleSubmit={(formState) => {
          setValues({
            ...values,
            ...formState,
            useDefaultMetaAccounts: false,
          });
          setIsChangeAccountsModalOpen(false);
        }}
        isOpen={isChangeAccountsModalOpen}
        onClose={() => {
          setIsChangeAccountsModalOpen(false);
        }}
      />
      <button
        onClick={() => {
          setIsChangeAccountsModalOpen(true);
        }}
        className={classNames(styles.changeSettingsButton)}
        type="button"
      >
        Change Settings?
      </button>
    </div>
  );
}
