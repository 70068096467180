import {
  Check,
  ChevronDown,
  MarkerPin06,
  Trash03,
  X,
} from "@untitled-ui/icons-react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Popover } from "src/components/Popover/Popover";
import { Text } from "src/components/Text/Text";
import {
  SelectionType,
  GeoLocationTargetingFormState,
} from "../misc/audienceCreateTypes";
import { SwitchGroup } from "src/modules/global/components/SwitchGroup";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { formatLocationName } from "../functions/formatLocationName";
import emptyGeoLocation from "../assets/emptyGeolocation.png";
import { SliderInput } from "src/components/SliderInput/SliderInput";

interface ILocationList {
  locations: GeoLocationTargetingFormState["locations"];
  setFieldValue: (field: string, value: any) => void;
}

export function LocationInputList({ locations, setFieldValue }: ILocationList) {
  if (!locations.length) {
    return <EmptyLocationState />;
  }

  return (
    <div className="pt-16 flex flex-col gap-4 h-[220px] overflow-y-auto">
      {locations.map((location, index) => (
        <LocationListItem
          key={location.id}
          location={location}
          onRadiusChange={(value) => {
            if (value) {
              setFieldValue(`locations[${index}].radius`, Number(value));
            }
          }}
          onDelete={() => {
            setFieldValue(
              "locations",
              locations.filter((l) => l.id !== location.id),
            );
          }}
          onChange={(value) => {
            setFieldValue(`locations[${index}].value`, value);
          }}
        />
      ))}
    </div>
  );
}
interface ILocationListItem {
  location: GeoLocationTargetingFormState["locations"][0];
  onDelete: () => void;
  onChange: (i: SelectionType) => void;
  onRadiusChange: (i: number) => void;
}

function LocationListItem({
  location,
  onDelete,
  onChange,
  onRadiusChange,
}: ILocationListItem) {
  return (
    <div className="flex justify-between py-8 border-b gap-4">
      <div className="flex items-center">
        <div className="flex flex-shrink-0 items-center gap-8">
          <MarkerPin06 width={12} height={12} />
          {(location.type === "city" || location.type === "place") && (
            <Popover
              noPadding
              maxWidth={200}
              trigger={
                <Popover.Trigger>
                  <div className="flex items-center px-4 gap-4">
                    <Text isNumeric size="xs" tone="subdued" weight="medium">
                      + {location.radius}
                    </Text>
                    <ChevronDown width={12} height={12} />
                  </div>
                </Popover.Trigger>
              }
            >
              <div className="flex flex-col gap-8 py-8 px-14 w-[200px]">
                <Text
                  tone="subdued"
                  noWrap
                  weight="medium"
                  size="sm"
                  children="Cities within radius"
                />

                <div className="grid grid-cols-[auto_1fr_auto] gap-8">
                  <Text
                    isNumeric
                    size="xs"
                    tone="subdued"
                    weight="medium"
                    children="0"
                  />
                  <SliderInput
                    value={location.radius ?? 0}
                    max={80}
                    min={20}
                    step={1}
                    onValueChange={(value) => {
                      onRadiusChange(value);
                    }}
                    ariaLabel="Cities within radius"
                  />
                  <Text
                    isNumeric
                    noWrap
                    size="xs"
                    tone="subdued"
                    weight="medium"
                    children="80 kms"
                  />
                </div>
              </div>
            </Popover>
          )}
        </div>
        <VerticalSeparator separatorHeight={18} totalWidth={16} />
        <Text size="md" tone="subdued" weight="medium">
          {formatLocationName(location)}
        </Text>
      </div>
      <div className="flex items-center gap-24">
        <SwitchGroup
          noneValue="NONE"
          value={location.value}
          onChange={onChange}
          options={[
            {
              icon: Check,
              label: "Include",
              value: "INCLUDE",
              selectedColor: `var(--color-success)`,
              selectedBackgroundColor: `var(--color-success-subdued)`,
            },
            {
              icon: X,
              label: "Exclude",
              value: "EXCLUDE",
              selectedColor: `var(--color-danger)`,
              selectedBackgroundColor: `var(--color-surface-critical)`,
            },
          ]}
        />
        <ButtonV2
          onClick={onDelete}
          leftIcon={Trash03}
          variant="plain"
          size="small"
        />
      </div>
    </div>
  );
}

function EmptyLocationState() {
  return (
    <div className="relative w-full h-full py-4">
      <img
        className="w-full h-full object-cover object-center opacity-50"
        src={emptyGeoLocation}
        width={100}
        height={100}
      />
      <div className="flex flex-col gap-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[1/2]">
        <Text size="md" align="center" weight="medium">
          Search and select locations to define your target audience
        </Text>
        <Text size="2xs" align="center" tone="subdued" weight="medium">
          Your selected location will be shown here.
        </Text>
      </div>
    </div>
  );
}
