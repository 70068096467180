import {
  useMetaDefaultAdAccountHealthQuery,
  useMetaIntegrationDefaultSettingsQuery,
} from "src/graphql/generated/schema";
import Banner from "../../../components/Banner/Banner";
import { ButtonV2 } from "../../../components/ButtonV2/ButtonV2";
import { InfoCircle } from "@untitled-ui/icons-react";

export function IntegrationErrorBanner({
  hasSidebar,
}: {
  hasSidebar?: boolean;
}) {
  const { data, loading, error } = useMetaDefaultAdAccountHealthQuery();

  if (loading) {
    return null;
  }

  const sideBarClass = hasSidebar ? "pl-[200px]" : "";

  if (data?.metaDefaultAdAccountHealth?.userError) {
    const message = data.metaDefaultAdAccountHealth.userError.message;

    return (
      <div className={sideBarClass}>
        <Banner
          className={sideBarClass}
          type={
            data?.metaDefaultAdAccountHealth.userError.code ===
            "TOKEN_ABOUT_TO_EXPIRE"
              ? "warning"
              : "error"
          }
          content={message}
          icon={<InfoCircle width={16} height={16} />}
          action={
            <ButtonV2
              isDestructive
              to={`/settings/integrations/meta`}
              variant="link"
            >
              View Meta Integration
            </ButtonV2>
          }
        />
      </div>
    );
  }

  return null;
}
