import { useEffect } from "react";
import { ComboBoxV2 } from "src/components/ComboBoxV2/ComboBoxV2";
import {
  CountriesQuery,
  Country,
  useCountriesLazyQuery,
} from "src/graphql/generated/schema";
import { useDebouncedCallback } from "src/lib/useDebouncedCallback";

interface ICountrySelector {
  value: Country[];
  onChange: (value: Country[]) => void;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  error?: string;
  isFixedWidth?: boolean;
}

export function CountrySelector({
  value,
  onChange,
  disabled,
  label,
  placeholder,
  error: errorMessage,
  isFixedWidth,
}: ICountrySelector) {
  const [fetchCountries] = useCountriesLazyQuery();

  return (
    <ComboBoxV2<CountriesQuery["countries"][0]>
      value={value}
      onChange={onChange}
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      error={errorMessage}
      isFixedWidth={isFixedWidth}
      renderSelectedValue={(value) => value.name}
      multiple={true}
      loadOptions={async (query: string) => {
        const payload = await fetchCountries({
          variables: { filters: { name: query } },
        });

        return payload.data.countries.map((country) => ({
          label: country.name,
          value: country,
        }));
      }}
    />
  );
}
