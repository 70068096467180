import styles from "./GraphCard.module.scss";
import classNames from "classnames";
import {
  ILineGraphV2,
  LineGraphV2,
} from "src/modules/global/components/LineGraphV2";
import { useMemo } from "react";

interface IGraphCard {
  title: string;
  action?: React.ReactNode;
  chartHeight?: string;
  chartData: {
    time: any;
    [x: string]: any;
  }[];
  yAxis: ILineGraphV2["yAxis"];
}

export function GraphCard({
  title,
  action,
  chartData,
  yAxis,
  chartHeight,
}: IGraphCard) {
  const values = useMemo(() => {
    if (!chartData) return [];
    return chartData.map((v) => ({
      ...v,
      value: v[yAxis.dataKey] || 0,
    }));
  }, [chartData, yAxis.dataKey]);

  const statColor = "#605CE5";

  return (
    <div className={classNames(styles.card)}>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderContent}>
          <div className={styles.cardTitle}>{title}</div>
        </div>
        {action}
      </div>
      <div
        className={styles.cardGraph}
        style={{ height: chartHeight ?? "180px" }}
      >
        <LineGraphV2
          height={"100%"}
          width={"100%"}
          values={values}
          statLabel={{
            color: statColor,
            name: yAxis.label,
            dataKey: yAxis.dataKey,
          }}
          xAxis={{
            dataKey: "time",
          }}
          yAxis={yAxis}
        />
      </div>
      <div className={styles.cardFooter}>
        <span className={styles.cardLegend}>
          <span
            className={styles.cardLegendColor}
            style={{ backgroundColor: statColor }}
          />
          {yAxis.label}
        </span>
      </div>
    </div>
  );
}
