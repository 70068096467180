import { StatFilterOptions } from "../../global/functions/StatFilterOptions";
import differenceInDays from "date-fns/differenceInDays";
import { convertDatetimeToDate } from "../../global/functions/convertDatetimeToDate";
import { GrowPerTickResponse } from "../../global/misc/growPerTickResponse";
import differenceInHours from "date-fns/differenceInHours";
import { useGrowPerTickQuery } from "src/graphql/generated/schema";

export function useOverallPerTickStats({
  dateFrom,
  dateTo,
  loadingOnRefetch = false,
}: StatFilterOptions): GrowPerTickResponse {
  const groupHourly =
    dateFrom && dateTo ? differenceInHours(dateTo, dateFrom) <= 24 : true;
  const groupMonthly =
    dateFrom && dateTo ? differenceInDays(dateTo, dateFrom) >= 90 : true;

  const { data, loading, error } = useGrowPerTickQuery({
    variables: {
      filter: {
        insightType: "ACTIVITY_INSIGHT",
        dateFrom: convertDatetimeToDate(dateFrom),
        dateTo: convertDatetimeToDate(dateTo),
        groupMonthly,
        groupHourly,
      },
    },
    notifyOnNetworkStatusChange: loadingOnRefetch ? true : false,
  });

  return {
    data: data?.growPerTick,
    error,
    loading,
    interval: groupMonthly ? "MONTH" : groupHourly ? "HOUR" : "DAY",
  };
}
