import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import Fieldset from "src/components/Fieldset/Fieldset";
import CreatableSelect from "react-select/creatable";
import { Form, Formik } from "formik";
import { Label } from "src/components/Label/Label";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { FormikError } from "src/components/FormikError/FormikError";
import clientIndustryOptions from "src/modules/global/misc/clientIndustryOptions";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { CreateClientModalProps } from "../misc/CreateClientModalProps";
import { ClientCreateWizardInput } from "../misc/ClientCreateWizardInput";
import { timeZoneOptions } from "src/modules/global/misc/timeZoneOptions";
import { currencyOptions } from "src/currency";
import { createClientStep1Schema } from "../misc/createClientStep1Schema";
import { UploadProfilePictureField } from "src/modules/global/components/UploadProfilePicture";

type ICreateClientModal1 = CreateClientModalProps<ClientCreateWizardInput> & {
  hideInviteMembers?: boolean;
  submitLabel?: string;
  heading?: string;
  disableCurrencyField?: boolean;
  subheading?: string;
};

export function CreateClientModal1({
  submitLabel,
  onBack,
  onSubmit,
  hideInviteMembers = false,
  initialValues,
  heading,
  subheading,
  disableCurrencyField = false,
}: ICreateClientModal1) {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={createClientStep1Schema}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="h-full grid gap-20 grid-rows-[auto_1fr_auto]">
          <SectionHeader
            title={heading ?? "Create Client"}
            description={
              subheading ?? "Add client and manage your marketing need"
            }
          />
          <Fieldset>
            <UploadProfilePictureField
              value={values.clientCreate.assetId}
              onChange={(value) => {
                setFieldValue("clientCreate.assetId", value);
              }}
            />

            <InputFormikV2
              name="clientCreate.name"
              label="Client Name"
              placeholder="Enter client name"
            />
            <div>
              <SelectV2
                value={values.clientCreate.industry}
                options={clientIndustryOptions}
                onChange={(value) => {
                  setFieldValue("clientCreate.industry", value);
                }}
                name="clientCreate.industry"
                label="Nature of business"
                placeholder="Select industry"
              />
              <FormikError fieldName="clientCreate.industry" />
            </div>

            <Fieldset horizontal>
              <div className="w-full">
                <SelectV2
                  options={timeZoneOptions}
                  value={values.clientCreate.timeZone}
                  onChange={(value) => {
                    setFieldValue("clientCreate.timeZone", value);
                  }}
                  name="clientCreate.timeZone"
                  label="Timezone"
                  placeholder="Select timezone"
                />
              </div>
              <div className="w-full">
                <SelectV2
                  disabled={disableCurrencyField}
                  options={currencyOptions}
                  value={values.clientCreate.currencyCode}
                  onChange={(value) => {
                    setFieldValue("clientCreate.currencyCode", value);
                  }}
                  name="clientCreate.currencyCode"
                  label="Currency"
                  placeholder="Select currency"
                />
              </div>
            </Fieldset>

            {!hideInviteMembers && (
              <div>
                <Label label="Invite Members" />
                <CreatableSelect
                  name="clientCreate.invitedMembers"
                  placeholder="Select members"
                  options={[]}
                />
              </div>
            )}
          </Fieldset>

          <div className="flex gap-8">
            <ButtonV2 size="small" type="submit" loading={isSubmitting}>
              {submitLabel ?? "Continue"}
            </ButtonV2>
            <ButtonV2 size="small" variant="outline" onClick={onBack}>
              Cancel
            </ButtonV2>
          </div>
        </Form>
      )}
    </Formik>
  );
}
