import { Asset as IAsset } from "src/lib/types";
import styles from "./SelectedAsset.module.scss";
import { Asset } from "src/components/Smartphone/Asset";

export function SelectedAsset({
  asset,
  width,
  height,
  borderRadius,
}: {
  asset: IAsset;
  width: number;
  height: number;
  borderRadius: number;
}) {
  return (
    <div style={{ width, height, borderRadius, overflow: "hidden" }}>
      <Asset
        name="line item preview"
        type={asset.contentType}
        className={styles.preview}
        preview={asset.uri}
        loop={true}
      />
    </div>
  );
}
