import { Combobox, Transition } from "@headlessui/react";
import { Fragment, SVGAttributes, useEffect, useState } from "react";
import { ChevronDown } from "@untitled-ui/icons-react";
import { Checkbox } from "../Checkbox/Checkbox";
import CloseIcon from "@material-symbols/svg-600/rounded/close.svg?react";
import styles from "./ComboBoxV2.module.scss";
import { Label } from "../Label/Label";
import classNames from "classnames";
import { toggleArray } from "src/lib/toggleArray";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";

interface Option<T> {
  label: string;
  value: T;
}

export interface IComboBoxV2<T> {
  multiple: boolean;
  value: T | T[];
  displayValue?: (value: T) => string;
  // use loadOptions for dynamic options
  options?: Option<T>[];
  onChange: (value: T | T[]) => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
  // for handling options fetched from a server
  loadOptions?: (query: string) => Promise<Option<T>[]>;
  isFixedWidth?: boolean;
  icon?: React.FC<SVGAttributes<any>>;
  label?: React.ReactNode;
  name?: string;
  renderSelectedValue?: (value: T) => string;
  error?: React.ReactNode;
  size?: "large" | "medium" | "small";
}

export function ComboBoxV2<T>(props: IComboBoxV2<T>) {
  const {
    multiple,
    value,
    displayValue,
    options: staticOptions,
    onBlur,
    placeholder = "",
    onChange,
    disabled,
    icon: Icon,
    loadOptions,
    isFixedWidth = false,
    renderSelectedValue,
    label,
    name,
    error,
  } = props;
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Option<T>[]>(staticOptions ?? []);
  const debouncedQuery = useDebouncedVariable(query);

  const filteredOption = options.filter((o) =>
    o.label.toLowerCase().includes(query.toLowerCase()),
  );

  const shouldShowBadges =
    multiple && Array.isArray(value) && !!value.length && renderSelectedValue;

  useEffect(() => {
    if (loadOptions) {
      setLoading(true);
      loadOptions(debouncedQuery)
        .then((options) => setOptions(options))
        .finally(() => setLoading(false));
    }
  }, [debouncedQuery]);

  return (
    <div className={styles.wrapper}>
      {label && <Label label={label} />}
      <Combobox
        value={value}
        __demoMode={true}
        onChange={(value) => {
          onChange(value);

          // reset query after selected
          setQuery("");
        }}
        disabled={disabled}
        // types are not written properly for some reason
        multiple={multiple as any}
      >
        <div
          className={classNames(styles.box, {
            [styles.boxFixedSize]: isFixedWidth,
            [styles.boxFullWidth]: !isFixedWidth,
          })}
        >
          <div
            className={classNames(styles.comboBox, {
              [styles.hasBadges]: shouldShowBadges,
            })}
          >
            <div className={styles.inputAndIcon}>
              {!!Icon && !shouldShowBadges && (
                <Icon width={20} height={20} className={styles.icon} />
              )}

              <div className={styles.inputAndBadges}>
                {shouldShowBadges && (
                  <>
                    {value.map((v) => (
                      <span className={styles.selectedValueBadge}>
                        {renderSelectedValue(v)}
                        {/* FIXME: using a button here will causes problem. onChange is fired twice when using a button  */}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            onChange(toggleArray(value, v) as T);
                          }}
                          className={styles.closeIcon}
                        >
                          <CloseIcon
                            fill="rgba(92, 95, 98, 1)"
                            width={16}
                            height={16}
                          />
                        </a>
                      </span>
                    ))}
                  </>
                )}
                <Combobox.Input
                  name={name}
                  className={classNames(styles.input, {
                    [styles.inputHasBadges]: shouldShowBadges,
                    [styles.inputError]: error,
                    [styles.inputDisabled]: disabled,
                  })}
                  placeholder={placeholder}
                  onChange={(event) => {
                    setQuery(event.target.value);
                  }}
                  displayValue={displayValue}
                  onBlur={onBlur}
                  autoComplete="off"
                />
              </div>
            </div>

            <Combobox.Button className={styles.button}>
              <div className={styles.dropIcon}>
                <ChevronDown
                  width={18}
                  height={18}
                  color="var(--color-disabled)"
                />
              </div>
            </Combobox.Button>
          </div>

          <Transition
            as={Fragment}
            leave={styles.comboPanelLeave}
            leaveFrom={styles.comboPanelLeaveFrom}
            leaveTo={styles.comboPanelLeaveTo}
          >
            <Combobox.Options className={styles.selectOptions}>
              {loading ? (
                <div className={styles.loading}>Loading</div>
              ) : filteredOption.length === 0 ? (
                <div className={styles.notFound}>Nothing found.</div>
              ) : (
                filteredOption.map((opt, index) => (
                  <Combobox.Option
                    key={index}
                    className={({ selected, active }) =>
                      classNames(styles.comboOption, {
                        [styles.activeOption]: selected,
                        [styles.hoveredOption]: active,
                      })
                    }
                    value={opt.value}
                  >
                    {({ selected }) => {
                      return (
                        <>
                          {multiple && (
                            <Checkbox
                              value={opt.value}
                              size="medium"
                              checked={selected}
                            />
                          )}
                          <span className={styles.comboOptionText}>
                            {opt.label}
                          </span>
                        </>
                      );
                    }}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {/* {multiple && showTags && (
        <div className={styles.tags}>
          {Array.isArray(selected)
            ? selected.map((item, index) => (
                <span className={styles.badge} key={index}>
                  {renderTagLabel ? renderTagLabel(item) : item}
                  <button
                    type="button"
                    className={styles.closeBtn}
                    onClick={() => removeTag(item)}
                  >
                    <Close width={12} height={12} />
                  </button>
                </span>
              ))
            : ""}
        </div>
      )} */}
    </div>
  );
}
