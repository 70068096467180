import { MetaLeadCreateTypes } from "src/modules/creative/misc/metaLeadCreateTypes";

export function getTotalScreensCount(leadForm: MetaLeadCreateTypes) {
  // One for prefill questions, one for privacy and one for thank you
  let screensCount = 3;
  const { customQuestion } = leadForm;
  const hasCustomQuestions =
    customQuestion.isEnabled && customQuestion.questions.length > 0;

  if (hasCustomQuestions) {
    // One for each custom question
    screensCount += customQuestion.questions.length;
  }

  return screensCount;
}
