import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Radio } from "src/components/Radio/Radio";
import { TitleWithThumbnail } from "src/modules/global/components/TitleWithThumbnail";
import Modal from "../../../components/Modal/Modal";
import styles from "./MetaAdAccountDefaultSettingsSelectorModal.module.scss";
import Instagram from "../../../icons/instagram.svg?react";
import Meta from "../../../icons/facebook.svg?react";
import Globe02 from "@untitled-ui/icons-react/build/esm/Globe02";
import { useFormikContext } from "formik";
import { MetaAdAccountDefaultSettingsForm } from "../misc/MetaIntegrationFormTypes";
import { LineHeading } from "src/modules/global/components/LineHeading";

export function MetaAdAccountDefaultSettingsSelectorModal({
  isOpen,
  onClose,
  metaInstagramAccounts,
  metaPixels,
  metaPages,
  handleSubmit,
}) {
  const { values, setFieldValue, isSubmitting } = useFormikContext();

  // const { data: metaPagesData } = useMetaPagesQuery({
  //   variables: {
  //     adAccountId: selectedAdAccountId,
  //   },
  // });
  // const { data: metaInstagramData } = useMetaInstagramAccountsQuery({
  //   variables: {
  //     adAccountId: selectedAdAccountId,
  //   },
  // });
  // const { data: metaPixelData } = useMetaPixelsQuery({
  //   variables: {
  //     adAccountId: selectedAdAccountId,
  //   },
  // });

  const formListItems: Array<{
    label: string;
    icon: React.ReactNode;
    formValueKey: keyof MetaAdAccountDefaultSettingsForm;
    data: { id: string; name: string; thumbnail?: string }[];
  }> = [
    {
      label: "Instagram Accounts",
      formValueKey: "instagramAccountId",
      icon: <Instagram height={14} width={14} />,
      data: metaInstagramAccounts.map((v) => ({
        name: v.name,
        id: v.id,
      })),
    },
    {
      label: "Meta Page",
      icon: <Meta height={14} width={14} />,
      formValueKey: "pageId",
      data: metaPages.map((v) => ({
        id: v.id,
        name: v.name,
        thumbnail: v?.Business?.profilePicture,
      })),
    },
    {
      label: "Meta Pixel",
      icon: <Globe02 height={14} width={14} />,
      formValueKey: "pixelId",
      data: metaPixels.map((v) => ({
        id: v.id,
        name: v.name,
      })),
    },
  ];

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      maxWidth="500px"
      title="Default account setting"
      footer={{
        footerActions: (
          <>
            <ButtonV2
              size="small"
              variant="outline"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Back
            </ButtonV2>
            <ButtonV2
              size="small"
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Set as default
            </ButtonV2>
          </>
        ),
        footerContent: "",
        hideBorder: true,
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.description}>
          By selecting default setting, you are telling macro in future
          campaigns and audience use these default setting, so you don’t have to
          add them each time. Don’t worry you can change these setting in future
          anytime you want.
        </div>

        <div className={styles.formContainer}>
          {formListItems
            .filter((section) => section.data.length > 0)
            .map((section) => (
              <div className={styles.formList}>
                <LineHeading
                  content={section.label}
                  icon={section.icon}
                  className={styles.sectionHeading}
                />
                {section.data.map((v) => (
                  <ListItemRadioButton
                    item={v}
                    checked={values[section.formValueKey] === v.id}
                    key={v.id}
                    onChange={(newChecked) => {
                      setFieldValue(section.formValueKey, v.id);
                    }}
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
}

function ListItemRadioButton({
  item,
  onChange,
  checked,
}: {
  item: {
    id: string;
    name: string;
    thumbnail?: string;
  };
  checked: boolean;
  onChange: (i: boolean) => void;
}) {
  return (
    <div className={styles.listItemRadio}>
      <TitleWithThumbnail
        size="small"
        thumbnail={{
          uri:
            item?.thumbnail ??
            `https://api.dicebear.com/9.x/shapes/svg?seed=${item.name}`,
          contentType: "IMAGE",
        }}
        name={item.name}
        descriptionItems={[]}
      />
      <Radio
        value={item.id}
        checked={checked}
        size={12}
        onChange={(newChecked) => onChange(newChecked)}
      />
    </div>
  );
}
