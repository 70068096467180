import { useContext } from "react";
import { StatFilterOptions } from "./StatFilterOptions";
import AuthContext from "src/stores/AuthContext";
import differenceInDays from "date-fns/differenceInDays";
import { useTinybirdQuery } from "src/lib/analyticsApi";
import { convertDatetimeToDate } from "./convertDatetimeToDate";
import { GrowPerTickResponse } from "../misc/growPerTickResponse";
import { useGrowPerTickQuery } from "src/graphql/generated/schema";

export function useAdsetPerTickStats(
  adsetId: string,
  { dateFrom, dateTo }: StatFilterOptions,
  activityId?: string,
): GrowPerTickResponse {
  const dayGap = differenceInDays(dateTo, dateFrom);

  const payload = useGrowPerTickQuery({
    variables: {
      filter: {
        insightType: "ADSET_INSIGHT",
        dateFrom: convertDatetimeToDate(dateFrom),
        dateTo: convertDatetimeToDate(dateTo),
        adsetId,
        activityId,
        groupMonthly: dayGap > 90,
      },
    },
  });

  return {
    data: payload.data?.growPerTick,
    error: payload.error,
    loading: payload.loading,
    interval: dayGap > 90 ? "MONTH" : "DAY",
  };
}
