import classNames from "classnames";
import { Form, Formik, useFormikContext } from "formik";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { Button } from "../../../components/Button/Button";
import { CheckboxOption } from "../../../components/CheckboxGroup/CheckboxGroup";
import Modal from "../../../components/Modal/Modal";
import Select from "../../../components/Select/Select";
import { Spinner } from "../../../components/Spinner/Spinner";
import {
  Client,
  OrganizationClientsQuery,
  RoleEnum,
  useConnectClientToUserMutation,
  useDisconnectClientFromUserMutation,
  useOrganizationClientsQuery,
  useOrganizationUserRolesQuery,
  useUserRoleUpdateMutation,
} from "../../../graphql/generated/schema";
import { ClientThumbnail } from "../../global/components/ClientThumbnail";
import { Switch } from "../../global/components/Switch";
import styles from "./ManageMemberModal.module.scss";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import SelectV2 from "src/components/SelectV2/SelectV2";

interface IAddMemberModal {
  isOpen: boolean;
  onClose: () => void;
  selectedUserId: string;
}

interface FormValues {
  clientIds: {
    id: string;
    role: RoleEnum;
    roleChanged?: boolean;
    roleId?: string;
    method: "enabled" | "disabled";
  }[];
  userId: string;
}

export function ManageMemberModal({
  isOpen,
  onClose,
  selectedUserId,
}: IAddMemberModal) {
  const {
    data: organizationUserRolesData,
    loading: organizationUserRolesLoading,
  } = useOrganizationUserRolesQuery({
    variables: {
      id: selectedUserId,
    },
  });
  const { data, loading } = useOrganizationClientsQuery({
    variables: {
      sortBy: {
        field: "CREATED_AT",
        direction: "DESC",
      },
    },
  });

  const [connectClientToUserFunc] = useConnectClientToUserMutation();
  const [disconnectClientFromUser] = useDisconnectClientFromUserMutation();
  const [userRoleUpdate] = useUserRoleUpdateMutation();

  const clientIds: FormValues["clientIds"] = useMemo(
    () =>
      organizationUserRolesData &&
      organizationUserRolesData.organizationUserRoles
        ? organizationUserRolesData.organizationUserRoles.map((v) => ({
            role: v.role as RoleEnum,
            id: v.clientId,
            method: "enabled",
            roleId: v.id,
          }))
        : [],
    [organizationUserRolesData],
  );

  const initialValues = {
    clientIds,
    userId: selectedUserId,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        try {
          for (const client of values.clientIds) {
            if (client.roleId && client.method === "disabled") {
              console.log({ client });
              await disconnectClientFromUser({
                variables: {
                  input: {
                    clientId: client.id,
                    userId: values.userId,
                  },
                },
              });
            }

            if (!client.roleId && client.method === "enabled") {
              await connectClientToUserFunc({
                variables: {
                  input: {
                    role: client.role,
                    clientId: client.id,
                    userId: values.userId,
                  },
                },
              });
            }

            // check for update in role
            if (
              client.roleId &&
              client.method === "enabled" &&
              client.roleChanged
            ) {
              await userRoleUpdate({
                variables: {
                  input: {
                    role: client.role,
                    clientId: client.id,
                    id: values.userId,
                  },
                },
              });
            }
          }
          toast.success("Successfully added to client");
          onClose();
        } catch (err) {
          toast.error(err.message);
        }
      }}
    >
      {({ handleSubmit, isValid, isSubmitting }) => (
        <Form>
          <Modal
            title="Select client to add member"
            subtitle="Add member to other clients and give access to manage"
            isOpen={isOpen}
            onClose={onClose}
            closeOnBlur={false}
            maxWidth="720px"
            footer={{
              hideBorder: true,
              footerContent: <></>,
              footerActions: (
                <div className="flex gap-6">
                  <ButtonV2
                    size="small"
                    children="Cancel"
                    onClick={onClose}
                    variant="outline"
                  />

                  <ButtonV2
                    size="small"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    children="Save Permissions"
                  />
                </div>
              ),
            }}
          >
            <ClientSelectorList
              loading={loading || organizationUserRolesLoading}
              clients={
                data && data.organizationClients ? data.organizationClients : []
              }
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
}

function ClientSelectorList({
  loading,
  clients,
}: {
  loading: boolean;
  clients: OrganizationClientsQuery["organizationClients"];
}) {
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.clientListContainer}>
      {clients.map((v) => (
        <ClientSelectorItem client={v} />
      ))}
    </div>
  );
}

function ClientSelectorItem({
  client,
}: {
  client: OrganizationClientsQuery["organizationClients"][number];
}) {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const roleOptions = [
    {
      value: "MEMBER" as const,
      label: "Member",
    },
    {
      value: "ADMIN" as const,
      label: "Admin",
    },
  ];
  const currentValue = values.clientIds.find((v) => v.id === client.id);
  const currentValueIndex = values.clientIds.findIndex(
    (v) => v.id === client.id,
  );
  const isEnabled = currentValue?.method === "enabled" ? true : false;

  return (
    <CheckboxOption
      value={client.id}
      className={classNames(styles.displayItem, {
        [styles.disabled]:
          currentValue?.method === "disabled" || currentValueIndex < 0,
      })}
    >
      <div className={styles.titleWithCheckbox}>
        <Switch
          size="large"
          checked={isEnabled}
          onChange={() => {
            if (currentValue) {
              if (currentValue.roleId && currentValue.method === "disabled") {
                const updatedClientIds = values.clientIds;
                updatedClientIds[currentValueIndex].method = "enabled";
                console.log({ updatedClientIds });
                setFieldValue("clientIds", updatedClientIds);
                return;
              }

              if (currentValue.roleId && currentValue.method === "enabled") {
                const updatedClientIds = values.clientIds;
                updatedClientIds[currentValueIndex].method = "disabled";

                setFieldValue("clientIds", updatedClientIds);
                return;
              }

              // if it has no role id then just filter out the value
              setFieldValue(
                "clientIds",
                values.clientIds.filter((v) => v.id !== client.id),
              );
              return;
            }

            setFieldValue("clientIds", [
              ...values.clientIds,
              { id: client.id, role: "MEMBER", method: "enabled" },
            ]);
            return;
          }}
        />
        <ClientThumbnail asset={client.Thumbnail} clientName={client.name} />
        <div className={styles.title}>{client.name}</div>
        <div className={styles.subtext}>{client.usersCount} Members</div>
      </div>
      <div className={styles.actionContainer}>
        <SelectV2
          size="small"
          disabled={
            currentValue?.method === "disabled" || currentValueIndex < 0
          }
          options={roleOptions}
          value={currentValue?.role}
          placeholder="Select Role"
          onChange={(i) => {
            setFieldValue(`clientIds[${currentValueIndex}].role`, i);
            setFieldValue(`clientIds[${currentValueIndex}].roleChanged`, true);
          }}
        />
      </div>
    </CheckboxOption>
  );
}
