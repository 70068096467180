import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import Close from "@material-symbols/svg-600/rounded/close.svg?react";
import { IconButton } from "../IconButton/IconButton";
import classNames from "classnames";
import styles from "./Modal.module.scss";

export interface IModal {
  title?: React.ReactNode;
  titleMeta?: React.ReactNode;
  subtitle?: string;
  maxWidth?: string;
  height?: string;
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  onClose: (i: any) => void;
  footer?: {
    footerContent: React.ReactNode;
    footerActions: React.ReactNode;
    hideBorder?: boolean;
  } | null;
  noContainer?: boolean;
  showDismiss?: boolean;
  closeOnBlur?: boolean;
  titleBorder?: boolean;
}

export default function Modal(props: IModal) {
  const {
    title,
    subtitle,
    children,
    maxWidth,
    height,
    className,
    isOpen,
    onClose,
    noContainer,
    showDismiss = true,
    footer,
    closeOnBlur = true,
    titleBorder = false,
    titleMeta = null,
  } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={styles.dialog}
        onClose={(i) => {
          if (!closeOnBlur) {
            return;
          }
          onClose(i);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter={styles.overlayEnter}
          enterFrom={styles.overlayEnterFrom}
          enterTo={styles.overlayEnterTo}
          leave={styles.overlayLeave}
          leaveFrom={styles.overlayEnterTo}
          leaveTo={styles.overlayEnterFrom}
        >
          <div className={styles.overlay} />
        </Transition.Child>

        <div className={styles.panelContainer}>
          <div className={styles.panelWrapper}>
            <Transition.Child
              as={Fragment}
              enter={styles.panelEnter}
              enterFrom={styles.panelEnterFrom}
              enterTo={styles.panelEnterTo}
              leave={styles.panelLeave}
              leaveFrom={styles.panelEnterTo}
              leaveTo={styles.panelEnterFrom}
            >
              <Dialog.Panel
                className={classNames(className, styles.panel, {
                  [styles.panelPadding]: !noContainer,
                })}
                style={{ maxWidth: maxWidth, height }}
              >
                {title && (
                  <Dialog.Title
                    as="div"
                    className={classNames(styles.title, {
                      [styles.titlePadding]: noContainer,
                      [styles.titleVerticalPadding]: !noContainer,
                      [styles.titleBorder]: titleBorder,
                    })}
                  >
                    <div>
                      <div className={styles.titleTextContainer}>
                        <h3 className={styles.titleText}>{title}</h3>
                        {titleMeta}
                      </div>
                      {subtitle && (
                        <p className={styles.subtitle}>{subtitle}</p>
                      )}
                    </div>
                    {showDismiss && (
                      <div className={styles.closeBtn}>
                        <IconButton icon={<Close />} onClick={onClose} />
                      </div>
                    )}
                  </Dialog.Title>
                )}

                <div>{children}</div>
                {footer && (
                  <div
                    className={classNames(styles.footer, {
                      [styles.footerBorder]: !footer.hideBorder,
                    })}
                  >
                    <p className={styles.footerText}>{footer.footerContent}</p>
                    <div className={styles.btnGrp}>{footer.footerActions}</div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
