import format from "date-fns/format";
import { ReactNode, useCallback, useContext, useMemo } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import {
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import {
  ActivitiesQuery,
  ActivityStatusEnum,
  useActivityDeleteMutation,
  useActivityStatusUpdateMutation,
} from "src/graphql/generated/schema";
import { titleCase } from "src/lib/titleCase";
import { BadgeV2, IBadgeV2 } from "src/modules/global/components/BadgeV2";
import { DropdownV2 } from "./DropdownV2";
import { DotsVertical } from "@untitled-ui/icons-react";
import { useActivityAggregatedStats } from "src/modules/global/functions/useActivityAggregatedStats";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import EmailIcon from "../../../icons/email.svg?react";
import MetaIcon from "../../../icons/meta.svg?react";
import WhatsappIcon from "../../../icons/whatsapp.svg?react";
import GoogleAdsIcon from "../../../icons/google-ads.svg?react";
import { toast } from "sonner";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { useNavigate } from "react-router-dom";
import { IListingMappingValue } from "src/modules/global/misc/IListingMappingValue";
import { growStatOptions } from "../misc/growStatOptions";
import AuthContext from "src/stores/AuthContext";
import { clampValue } from "src/lib/clampValue";
import {
  TComparativePerformance,
  useAccountComparison,
} from "../hooks/useAccountComparison";
import Tooltip from "src/components/Tooltip/Tooltip";
import { Text } from "src/components/Text/Text";

interface IActivityItem {
  activity: ActivitiesQuery["activities"]["edges"][0]["node"];
  campaignAnalytics: IListingMappingValue;
  // triggerFetch: () => Promise<void>;
}

export function ActivityItem({ activity, campaignAnalytics }: IActivityItem) {
  const [
    activityDeleteFunc,
    { error: deleteError, loading: activityDeleteLoading },
  ] = useActivityDeleteMutation();
  const [
    activityUpdateStatusFunc,
    { error: updateStatusError, loading: activityUpdateStatusLoading },
  ] = useActivityStatusUpdateMutation();
  const formattedDate = useMemo(
    () => format(new Date(activity.createdAt), "dd MMM, yyyy"),
    [activity],
  );
  const navigate = useNavigate();

  const activityMoreOptions = [
    {
      label:
        activity.status === "ACTIVE" ? "Pause Activity" : "Resume Activity",
      async onClick() {
        try {
          await activityUpdateStatusFunc({
            variables: {
              input: {
                id: activity.id,
                status: activity.status === "ACTIVE" ? "PAUSED" : "ACTIVE",
              },
            },
            refetchQueries: ["activities"],
            awaitRefetchQueries: true,
          });
        } catch (err) {
          toast.error(err.message);
        }
      },
    },
    {
      label: "Duplicate Activity",
      onClick() {
        navigate(`/campaigns/duplicate?id=${activity.id}`);
      },
    },
    {
      label: "Archive Activity",
      async onClick() {
        try {
          await activityUpdateStatusFunc({
            variables: {
              input: {
                id: activity.id,
                status: "ARCHIVED",
              },
            },
            refetchQueries: ["activities"],
            awaitRefetchQueries: true,
          });
        } catch (err) {
          toast.error(err.message);
        }
      },
    },
    {
      label: "Delete Activity",
      disabled: activity.status !== "ERROR",
      async onClick() {
        try {
          await activityDeleteFunc({
            variables: {
              id: activity.id,
            },
            refetchQueries: ["activities"],
            awaitRefetchQueries: true,
          });
          toast.success("Activity deleted successfully");
        } catch (e) {
          toast.error(e.message);
        }
      },
    },
  ];

  const link = `/campaigns/${activity.Campaign.id}/activities/${activity.id}`;

  const platformIconMapper = {
    EMAIL: EmailIcon,
    GOOGLE_ADS: GoogleAdsIcon,
    META: MetaIcon,
    WHATSAPP: WhatsappIcon,
  };

  if (activityUpdateStatusLoading || activityDeleteLoading) {
    return (
      <TableRow id={activity.id} link={link}>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow id={activity.id} link={link}>
      <TablePrimaryCell
        width={450}
        icon={platformIconMapper[activity.platform]}
        link={link}
        title={activity.name}
        subtitle={formattedDate}
      />
      <TableCell width={120}>
        <BadgeV2
          size="small"
          color={badgeColorBasedOnStatus(activity.status)}
          label={titleCase(activity.status)}
        />
      </TableCell>
      <TableCell width={224}>
        <ButtonV2
          stopPropagation
          to={`/campaigns/${activity.Campaign.id}`}
          size="small"
          variant="link"
        >
          {activity.Campaign.name}
        </ButtonV2>
      </TableCell>
      <ActivityItemStats activityId={activity.id} />
      <TableCell width={48}>
        <DropdownV2
          options={activityMoreOptions}
          icon={DotsVertical}
          hideArrow
          buttonVariant="plain"
          size="small"
        />
      </TableCell>
    </TableRow>
  );
}

function ActivityItemStats({ activityId }: { activityId: string }) {
  const { activeClient } = useContext(AuthContext);
  const { loading, data } = useActivityAggregatedStats(activityId, {});
  const selectedCurrencyCode = useGetActiveCurrencySymbol();
  const statOptions = growStatOptions[activeClient.analyticsCategory];
  const calcAccountComparison = useAccountComparison();

  const calculateWidth = useCallback(function calculateWidth(str: string) {
    // at 12px of euclid, 1 char = 7px (approx)
    // according to chatgpt
    const width = Math.floor(str.length * 7);

    return clampValue(width, 112, 250);
  }, []);

  const statValues = useMemo(
    () =>
      statOptions.map((statOption) => {
        if (loading) {
          return {
            comparison: "NO_DATA" as const,
            accountAverage: 0,
            value: <Skeleton width={40} height={20} />,
            width: calculateWidth(statOption.label),
          };
        }

        if (!data) {
          return {
            comparison: "NO_DATA" as const,
            accountAverage: 0,
            value: "-",
            width: calculateWidth(statOption.label),
          };
        }

        if (!data[statOption.valueKey]) {
          return {
            comparison: "NO_DATA" as const,
            accountAverage: 0,
            value: "-",
            width: calculateWidth(statOption.label),
          };
        }

        const value = formatNumberByType(
          data[statOption.valueKey],
          statOption.valueType,
          {
            showDecimal: true,
            selectedCurrencyCode,
          },
        );

        const { showAccountAverageComparison } = statOption;

        if (showAccountAverageComparison) {
          let { comparison, accountAverage } = calcAccountComparison(
            data[statOption.valueKey],
            statOption.valueKey,
            statOption.performanceDirection,
          );

          return {
            comparison,
            accountAverage: formatNumberByType(
              accountAverage,
              statOption.valueType,
              {
                showDecimal: true,
                selectedCurrencyCode,
              },
            ),
            value,
            width: calculateWidth(statOption.label),
          };
        }

        return {
          comparison: "NO_DATA" as const,
          accountAverage: 0,
          value,
          width: calculateWidth(statOption.label),
        };
      }),
    [
      loading,
      data,
      selectedCurrencyCode,
      calcAccountComparison,
      calculateWidth,
    ],
  );

  function getBadgeColor(comparison: TComparativePerformance) {
    if (comparison === "ABOVE_AVERAGE") {
      return "var(--color-green-800)";
    }
    if (comparison === "BELOW_AVERAGE") {
      return "var(--color-red-800)";
    }

    return "var(--color-gray-500)";
  }

  const getTooltipContent = (
    comparison: TComparativePerformance,
    accountAverage: string,
  ) => {
    let title = "";
    let description: ReactNode = "";

    if (comparison === "NO_DATA") {
      title = "";
      description = <></>;
    } else if (comparison === "ABOVE_AVERAGE") {
      title = "Outperformed";
      description = (
        <>
          This Activity outperformed your account average of{" "}
          <strong className="font-bold">{accountAverage}</strong>
        </>
      );
    } else if (comparison === "BELOW_AVERAGE") {
      title = "Could be better";
      description = (
        <>
          This activity didn't meet your account average of{" "}
          <strong className="font-bold">{accountAverage}</strong>
        </>
      );
    } else {
      title = "Average";
      description = (
        <>
          This Activity performed similar to your account average of{" "}
          <strong className="font-bold">{accountAverage}</strong>
        </>
      );
    }

    return (
      <div className="flex flex-col gap-4">
        <span className="flex items-center gap-6">
          <span
            style={{
              backgroundColor: getBadgeColor(comparison),
            }}
            className="w-[6px] h-[6px] rounded-2"
          />

          <Text size="xs" tone="neutral-700" weight="medium">
            {title}
          </Text>
        </span>
        <span>
          <Text size="xs" tone="gray-700" weight="medium">
            {description}
          </Text>
        </span>
      </div>
    );
  };

  return (
    <>
      {statValues.map((statValue) => (
        <TableCell width={statValue.width}>
          {statValue.comparison !== "NO_DATA" ? (
            <Tooltip
              content={getTooltipContent(
                statValue.comparison,
                statValue.accountAverage,
              )}
            >
              <div className="flex items-center gap-[5px]">
                <span className="flex justify-center items-center w-2 h-2">
                  <span
                    className="w-[6px] h-[6px] rounded-2"
                    style={{
                      backgroundColor: getBadgeColor(statValue.comparison),
                    }}
                  />
                </span>
                {statValue.value}
              </div>
            </Tooltip>
          ) : (
            statValue.value
          )}
        </TableCell>
      ))}
    </>
  );
}

function badgeColorBasedOnStatus(
  status: ActivityStatusEnum,
): IBadgeV2["color"] {
  if (status === "ACTIVE") return "green";
  if (status === "PAUSED") return "yellow";
  if (status === "COMPLETED") return "green";
  if (status === "ERROR") return "red";
  if (status === "PUBLISHING") return "blue";

  return "gray";
}
