import React, { ReactNode, SVGAttributes, useContext, useState } from "react";
import styles from "./Table.module.scss";
import classNames from "classnames";
import { Checkbox } from "../Checkbox/Checkbox";
import { TableContext } from "./TableContext";
import { toggleArray } from "src/lib/toggleArray";
import { Link, useNavigate } from "react-router-dom";
import { VerticalSeparator } from "../VerticalSeparator/VerticalSeparator";
import { Avatar } from "../Avatar/Avatar";

interface RenderItemProp<T> {
  item: T;
  index: number;
}

interface TableProps<T> {
  isSelectable?: boolean;
  variant?: "page" | "card";
  headings: Array<{
    icon?: React.FC<any>;
    title: string | ReactNode;
    alignment?: "left" | "right" | "center";
  }>;
  onSelectedItemsChange?: (i: string[]) => void;
  selectedItems?: string[];
  renderItem: (i: RenderItemProp<T>) => React.ReactNode;
  isScrollable?: boolean;
  data: T[];
  idExtractor?: (i: T, index: number) => string;
}

export function Table<T>({
  data,
  headings,
  onSelectedItemsChange,
  // uses index as default idExtractor
  idExtractor = (item, index) => index.toString(),
  isScrollable = true,
  isSelectable = true,
  selectedItems,
  renderItem,
  variant = "page",
}: TableProps<T>) {
  const [hasHorizontallyScrolled, setHasHorizontallyScrolled] = useState(false);

  if (isSelectable && !Array.isArray(selectedItems)) {
    throw new Error("selectedItems is required if isSelectable is true");
  }

  let isSelectModeEnabled = isSelectable && selectedItems.length > 0;

  function onScroll(e: React.UIEvent<HTMLDivElement>) {
    const newValue = e.currentTarget.scrollLeft > 0;

    if (newValue !== hasHorizontallyScrolled) {
      setHasHorizontallyScrolled(newValue);
    }
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.cardWrapper]: variant === "card",
      })}
    >
      <TableContext.Provider
        value={{
          data,
          isSelectModeEnabled,
          isSelectable,
          onSelectedItemsChange,
          isScrollable,
          selectedItems,
          variant,
          hasHorizontallyScrolled,
        }}
      >
        <div
          className={classNames({
            [styles.scrollableTableWrapper]: isScrollable,
          })}
          onScroll={onScroll}
        >
          <table
            className={classNames(styles.table, {
              [styles.pageTableBorder]: variant === "page",
              [styles.tableScrollable]: isScrollable,
            })}
          >
            <TableHead headings={headings} idExtractor={idExtractor} />
            <tbody>
              {data.map((item, index) => renderItem({ item, index }))}
            </tbody>
          </table>
        </div>
      </TableContext.Provider>
    </div>
  );
}

interface TableHeadProps {
  headings: Array<{
    icon?: React.FC<any>;
    title: string | ReactNode;
    alignment?: "left" | "right" | "center";
  }>;
  idExtractor: (i: any, index: number) => string;
}

function TableHead({ headings, idExtractor }: TableHeadProps) {
  const {
    hasHorizontallyScrolled,
    selectedItems,
    isSelectable,
    isScrollable,
    data,
    onSelectedItemsChange,
  } = useContext(TableContext);

  let _headings = headings;

  let areAllSelected = selectedItems?.length === data?.length;

  function onSelectAll() {
    if (!isSelectable) {
      return;
    }

    // deselect all if all are selected
    if (selectedItems?.length === data?.length) {
      onSelectedItemsChange([]);
      return;
    }

    // select all
    onSelectedItemsChange(data.map((d, index) => idExtractor(d, index)));
  }

  // if has isSelectable ad space in heading for checkbox
  if (isSelectable) {
    const checkboxHeading = {
      title: (
        <span>
          <Checkbox
            value="ALL"
            checked={areAllSelected}
            onChange={onSelectAll}
          />
        </span>
      ),
    };

    _headings = [checkboxHeading, ...headings];
  }

  return (
    <thead>
      <tr className={classNames(styles.tableHeadRow)}>
        {_headings.map((heading, index) => {
          const Icon = heading.icon;
          const isCheckbox = isSelectable && index === 0;

          // first is checkbox, second is primary cell
          const isPrimaryCell = index === 1;
          const isSticky = isScrollable && (isPrimaryCell || isCheckbox);

          return (
            <th
              onClick={isCheckbox ? onSelectAll : undefined}
              style={{
                textAlign: heading.alignment || "left",
              }}
              className={classNames(styles.tableHeadCell, {
                [styles.tableHeadStickyCell]: isSticky,
                [styles.tableSideBoxShadow]:
                  isSticky && hasHorizontallyScrolled,
                [styles.tableHeadStickySecondCell]: isPrimaryCell && isSticky,
              })}
            >
              <div className={styles.tableHeadCellInner}>
                {!!Icon && <Icon width={12} height={12} />}
                {heading.title}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

interface TableRowProps {
  id: string;
  children: React.ReactNode;
  link?: string;
  onClick?: () => void;
}

export function TableRow({ id, link, onClick, children }: TableRowProps) {
  const {
    isSelectable,
    isSelectModeEnabled,
    onSelectedItemsChange,
    selectedItems,
    variant,
  } = useContext(TableContext);
  const navigate = useNavigate();

  const isRowSelected = isSelectable && selectedItems.includes(id);

  function handleSelectItem() {
    onSelectedItemsChange(toggleArray(selectedItems, id));
  }

  function handleClick(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    if (isSelectModeEnabled) {
      handleSelectItem();
      return;
    }

    if (link) {
      const shouldOpenInTab = event.ctrlKey || event.metaKey;

      if (shouldOpenInTab) {
        window.open(link, "_blank");
      } else {
        navigate(link);
      }

      return;
    }

    if (onClick) {
      onClick();
      return;
    }
  }

  return (
    <tr
      onClick={handleClick}
      className={classNames(styles.tableRowBorder, {
        [styles.tableClickableRow]: onClick || link || isSelectModeEnabled,
        [styles.removeBorderFromLastItem]: variant === "card",
      })}
    >
      {isSelectable && (
        <TableCell isSticky width={48}>
          <Checkbox
            value={id}
            onChange={handleSelectItem}
            checked={isRowSelected}
          />
        </TableCell>
      )}
      {children}
    </tr>
  );
}

interface TableCellProps {
  children: React.ReactNode;
  width?: number | string;
  isSticky?: boolean;
  leftOffset?: number | string;
}

export function TableCell({
  children,
  width,
  isSticky,
  leftOffset,
}: TableCellProps) {
  const isLeftDefined =
    typeof leftOffset !== "undefined" && leftOffset !== null;
  const { hasHorizontallyScrolled } = useContext(TableContext);

  return (
    <td
      style={{
        ...(isLeftDefined && {
          left: typeof leftOffset === "number" ? `${leftOffset}px` : leftOffset,
        }),
        width: typeof width === "number" ? `${width}px` : width,
      }}
      className={classNames(styles.tableCell, {
        [styles.tableCellSticky]: isSticky,
        [styles.tableSideBoxShadow]: isSticky && hasHorizontallyScrolled,
      })}
    >
      {children}
    </td>
  );
}

interface TablePrimaryCellProps {
  title: string;
  subtitle?: string;
  link?: string;
  onClick?: () => void;
  width?: number | string;
  imageSrc?: string;
  avatarSeed?: string;
  icon?: React.FC<SVGAttributes<any>> | React.FC<SVGAttributes<any>>[];
  as?: any;
}

export function TablePrimaryCell({
  imageSrc,
  link,
  avatarSeed,
  onClick,
  width,
  icon: Icon,
  title,
  subtitle,
  as,
}: TablePrimaryCellProps) {
  const {
    isSelectable,
    isSelectModeEnabled,
    isScrollable,
    hasHorizontallyScrolled,
  } = useContext(TableContext);
  const isIconList = Array.isArray(Icon);

  const shouldRenderLink = !isSelectModeEnabled && (link || onClick);
  const AsElement = as;

  return (
    <td
      style={{
        width: typeof width === "number" ? `${width}px` : width,
        left: isSelectable ? "48px" : "0",
      }}
      className={classNames(styles.tableCell, {
        [styles.tablePrimaryCellSticky]: isScrollable,
        [styles.tableSideBoxShadow]: hasHorizontallyScrolled,
      })}
    >
      <div className={styles.tablePrimaryCell}>
        {avatarSeed && <Avatar avatarSeed={avatarSeed} />}

        {!!imageSrc && (
          <img
            src={imageSrc}
            alt={title}
            width={24}
            height={24}
            className={styles.primaryCellImage}
          />
        )}

        {!!Icon &&
          (isIconList ? (
            <div className={styles.iconList}>
              {Icon.map((IconSingle) => (
                <IconSingle
                  width={16}
                  height={16}
                  color="var(--color-disabled)"
                />
              ))}
            </div>
          ) : (
            <Icon width={16} height={16} color="var(--color-disabled)" />
          ))}

        <div className={styles.titleAndSubtitle}>
          <div className={styles.primaryCellTitle}>
            {shouldRenderLink ? (
              AsElement ? (
                <AsElement onClick={onClick} children={title} />
              ) : (
                <Link to={link} onClick={onClick}>
                  {title}
                </Link>
              )
            ) : (
              title
            )}
          </div>
          {subtitle && (
            <>
              <VerticalSeparator separatorHeight={12} totalWidth={24} />
              <div className={styles.primaryCellSubtitle}>{subtitle}</div>
            </>
          )}
        </div>
      </div>
    </td>
  );
}
