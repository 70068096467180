import { Link } from "react-router-dom";
import { Activity } from "src/graphql/generated/schema";
import format from "date-fns/format";
import { Text } from "src/components/Text/Text";
import MetaIcon from "src/icons/meta.svg?react";
import { IconWithWrapper } from "src/components/IconWithWrapper/IconWithWrapper";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { MetricCardList } from "src/components/MetricCardList/MetricCardList";
import { titleCase } from "src/lib/titleCase";

export function ActivityAboutCard({ activity }: { activity: Activity }) {
  const metaActivity = activity?.MetaActivity;

  function getFacebookLink() {
    if (
      activity.status === "ACTIVE" ||
      activity.status === "COMPLETED" ||
      activity.status === "ARCHIVED"
    ) {
      return (
        `https://adsmanager.facebook.com/adsmanager/manage/campaigns/edit?` +
        `act=${activity.MetaActivity.MetaAdAccount.metaAccountId}` +
        `&selected_campaign_ids=${activity.platformCampaignId}`
      );
    }
  }

  const facebookLink = getFacebookLink();
  const placements = Array.from(
    new Set(activity?.MetaActivity?.adPlacements.map((v) => v.split("_")[0])),
  );
  const placementString = placements.map((v) => titleCase(v, "_")).join(" & ");

  const coreDetails = [
    {
      title: "Activity type",
      value: titleCase(activity.MetaActivity?.activityType, "_"),
    },
    {
      title: "Placement",
      value: placementString,
    },
  ];

  const metaDetails = [
    {
      title: "Ad Account",
      value: metaActivity?.MetaAdAccount?.metaName ?? "-",
    },
    {
      title: "Facebook Page",
      value: metaActivity?.MetaPage?.name ?? "-",
    },
    {
      title: "Instagram Page",
      value: metaActivity?.MetaInstagramAccount?.name
        ? `@${metaActivity?.MetaInstagramAccount?.name}`
        : "-",
    },
    {
      title: "Pixel Id",
      value: metaActivity?.metaPixelId ?? "-",
    },
    {
      title: "Conversion Domain",
      value: metaActivity?.conversionDomain ?? "-",
    },
  ];

  return (
    <div className={"flex flex-col border rounded-8 mt-16"}>
      <div className="p-16 flex items-center justify-between border-b">
        <div className="flex gap-8 items-center">
          <IconWithWrapper icon={MetaIcon} size="lg" />
          <div className="flex flex-col">
            <Link to={facebookLink} target="_blank">
              <Text size="sm">{activity.name}</Text>
            </Link>
            <Text size="xs" tone="subdued">
              Campaign: {activity?.Campaign?.name}
            </Text>
          </div>
        </div>
        <div className="flex gap-8 items-center">
          <Text size="xs" tone="subdued">
            Created on
          </Text>
          <VerticalSeparator totalWidth={1} separatorHeight={16} />
          <Text size="xs">
            {format(new Date(activity.createdAt), "dd MMM, yyyy")}
          </Text>
        </div>
      </div>
      <div className="p-14 border-b">
        <MetricCardList
          variant="small"
          columns={{ xs: 6 }}
          stats={coreDetails}
        />
      </div>
      <div className="p-14">
        <MetricCardList
          variant="small"
          columns={{ xs: 6 }}
          stats={metaDetails}
        />
      </div>
    </div>
  );
}
