import PersonPlay from "../../../icons/person_play.svg?react";
import Face05 from "../../../icons/behaviour.svg?react";
import Demography from "../../../icons/demography.svg?react";
import { InterestValue } from "../misc/audienceCreateTypes";

export function getInterestIcon(interest: InterestValue) {
  return interest.type === "interests"
    ? PersonPlay
    : interest.type === "behaviors"
    ? Face05
    : Demography;
}
