import * as Yup from "yup";
export const otherTargetingSchema = Yup.object({
  minAge: Yup.number()
    .positive("Minimum age must be positive")
    .min(18, "Minimum age must be 18")
    .max(64, "Maximum age must be 64")
    .required("Minimum age is required"),
  maxAge: Yup.number()
    .positive("Maximum age must be positive")
    .required("Maximum age is required")
    .min(19, "Minimum age must be 19")
    .max(65, "Maximum age must be 65")
    .min(
      Yup.ref("minAge"),
      "Maximum age must be greater than or equal to minimum age",
    ),
  gender: Yup.string()
    .oneOf(["MALE", "FEMALE", "ALL"])
    .required("Gender is required"),
  languages: Yup.array()
    .of(Yup.string())
    .required("At least one language is required"),
});
