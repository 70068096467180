import { useState } from "react";
import { CreateClientModal2 } from "./CreateClientModal2";
import { CreateClientModal1 } from "./CreateClientModal1";
import { ClientCreateDialogLayout } from "./ClientCreateDialogLayout";
import createClient1Image from "../assets/createClient1.png";
import createClient2Image from "../assets/createClient2.png";
import createClient3Image from "../assets/createClient3.png";
import createClient4Image from "../assets/createClient4.png";
import createClient5Image from "../assets/createClient5.png";
import { ClientCreateWizardInput } from "../misc/ClientCreateWizardInput";
import {
  useClientBrandAdditionalInfoUpsertMutation,
  useClientCreateMutation,
  useInviteUsersToClientMutation,
} from "src/graphql/generated/schema";
import { toast } from "sonner";
import { CreateClientModal3 } from "./CreateClientModal3";
import { CreateClientModal4 } from "./CreateClientModal4";
import { CreateClientModal5 } from "./CreateClientModal5";

interface ICreateClientModal {
  trigger: React.ReactNode;
}

export function CreateClientModal({ trigger }: ICreateClientModal) {
  const [isOpen, setIsOpen] = useState(false);
  const [clientBrandAdditionalInfoUpsert] =
    useClientBrandAdditionalInfoUpsertMutation();
  const initialValues: ClientCreateWizardInput = {
    clientCreate: {
      name: "",
      assetId: null,
      users: [],
      industry: "",
      timeZone: "Asia/Kolkata",
      currencyCode: "INR",
      analyticsCategory: "TRANSACTIONAL",
      invitedMembers: [],
    },
    clientProfile: {
      brandName: "",
      brandColor: "#000000",
      about: "",
      brandLogoId: null,
      audienceMinAge: 18,
      industry: "",
      audienceMaxAge: 65,
      audienceGender: "ALL",
      audienceInterests: "",
      locations: [],
    },
  };
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [data, setData] = useState<ClientCreateWizardInput>(initialValues);
  const [clientCreateFunc] = useClientCreateMutation();
  const [inviteUsersToClientFunc] = useInviteUsersToClientMutation();
  const steps = [
    CreateClientModal1,
    CreateClientModal2,
    CreateClientModal3,
    CreateClientModal4,
    CreateClientModal5,
  ];
  const stepImages = [
    createClient1Image,
    createClient2Image,
    createClient3Image,
    createClient4Image,
    createClient5Image,
  ];
  const lastStepIndex = steps.length - 1;

  async function onCreateClient(values: ClientCreateWizardInput) {
    try {
      const clientCreateInput = values.clientCreate;

      const { data } = await clientCreateFunc({
        variables: {
          input: {
            name: clientCreateInput.name,
            ...(clientCreateInput.assetId && {
              assetId: clientCreateInput.assetId,
            }),
            ...(clientCreateInput.industry && {
              industry: clientCreateInput.industry,
            }),
            currencyCode: clientCreateInput.currencyCode,
            timeZone: clientCreateInput.timeZone,
            users: clientCreateInput.users,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: ["organizationClients", "getUser"],
      });

      if (clientCreateInput.invitedMembers.length > 0) {
        await inviteUsersToClientFunc({
          variables: {
            input: {
              emails: clientCreateInput.invitedMembers,
              clientId: data.clientCreate.id,
            },
          },
        });
      }
      toast.success("Successfully created client");
    } catch (err) {
      console.log(err);
      toast.error("An unknown error occurred");
    }
  }

  async function onUpsertClientBrandAdditionalInfo(
    values: ClientCreateWizardInput,
  ) {
    const clientProfile = values.clientProfile;

    await clientBrandAdditionalInfoUpsert({
      variables: {
        input: {
          brandName: clientProfile.brandName,
          brandIndustry: clientProfile.industry,
          // brandDomain: clientProfile.brandDomain,
          brandColor: clientProfile.brandColor,
          brandAbout: clientProfile.about,
          audienceMinAge: clientProfile.audienceMinAge,
          audienceMaxAge: clientProfile.audienceMaxAge,
          audienceGender: clientProfile.audienceGender,
          audienceInterests: clientProfile.audienceInterests,
          targetCountryIds: clientProfile.locations.map(
            (location) => location.id,
          ),
          ...(clientProfile.brandLogoId && {
            brandLogoId: clientProfile.brandLogoId,
          }),
        },
      },
    });
  }

  function handlePreviousStep() {
    // if we are on the first step, close the modal
    if (currentStepIndex === 0) {
      setIsOpen(false);
      return;
    }

    if (currentStepIndex === lastStepIndex) {
      setIsOpen(false);
      return;
    }

    setCurrentStepIndex((prev) => prev - 1);
  }

  const brandAdditionalInfoFormIndexes = [lastStepIndex - 1, lastStepIndex];

  async function handleSubmit(values: ClientCreateWizardInput) {
    if (currentStepIndex === 1) {
      await onCreateClient(values);
      // save on all steps of client profile wizard
    } else if (brandAdditionalInfoFormIndexes.includes(currentStepIndex)) {
      await onUpsertClientBrandAdditionalInfo(values);
    }

    setData(values);
    setCurrentStepIndex((prev) => prev + 1);
  }

  const CurrentStep = steps[currentStepIndex];
  const currentStepImage = stepImages[currentStepIndex];

  return (
    <ClientCreateDialogLayout
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      trigger={trigger}
      image={{
        src: currentStepImage,
        alt: "image of ai assistant",
        width: 600,
        height: 400,
      }}
    >
      <CurrentStep
        // reset any internal state when key changes
        key={currentStepIndex}
        initialValues={data}
        onBack={handlePreviousStep}
        onSubmit={handleSubmit}
      />
    </ClientCreateDialogLayout>
  );
}
