import { useState } from "react";
import { Spinner } from "src/components/Spinner/Spinner";
import { useMetaInstagramAccountsFromPlatformQuery } from "src/graphql/generated/schema";
import { MetaDetailItem } from "./MetaDetailItem";
import { MetaIntegrationDetailsEmptyState } from "./MetaIntegrationDetailsEmptyState";

export function MetaInstagramAccountsList({
  selectedAccountId,
  selectedInstagramAccounts,
  setSelectedInstagramAccounts,
}) {
  const [loading, setLoading] = useState(false);
  const { data, loading: instagramAccountsFromPlatformLoading } =
    useMetaInstagramAccountsFromPlatformQuery({
      variables: {
        adAccountId: selectedAccountId,
      },
      notifyOnNetworkStatusChange: true,
    });

  if (instagramAccountsFromPlatformLoading) {
    return <Spinner height="screen" />;
  }

  const metaInstagramAccounts = data?.metaInstagramAccountsFromPlatform
    ? data?.metaInstagramAccountsFromPlatform
    : [];

  if (metaInstagramAccounts.length === 0) {
    return <MetaIntegrationDetailsEmptyState link={""} />;
  }

  return (
    <>
      {metaInstagramAccounts.map((instagramAccount) => (
        <div>
          <MetaDetailItem
            isDefault={instagramAccount?.isDefault}
            // TODO: handle error
            // error={selectedInstagram === instagramAccount.id && error}
            enableFunc={async () => {
              setLoading(true);
              await setSelectedInstagramAccounts(
                instagramAccount,
                metaInstagramAccounts?.length
              );
              setLoading(false);
            }}
            removeFunc={async () => {
              setLoading(true);
              await setSelectedInstagramAccounts(
                instagramAccount,
                metaInstagramAccounts?.length
              );
              setLoading(false);
            }}
            loading={loading}
            title={instagramAccount.name}
            isEnabled={
              selectedInstagramAccounts.find(
                (v) => v.metaInstagramId === instagramAccount.metaInstagramId
              )?.enabled || false
            }
          />
        </div>
      ))}
    </>
  );
}
