import { useFormikContext } from "formik";
import ShopifyPixel from "../assets/shopifyPixel.svg?react";
import { ShopifyWizardSettings } from "./ShopifyWizardSettings";
import { FormData } from "../misc/shopifyIntegrationTypes";
import { CheckboxOptionGroup } from "src/components/CheckboxOptionGroup/CheckboxOptionGroup";

export function ShopifyPixelWizardSettings() {
  const { values, setFieldTouched, setFieldValue } =
    useFormikContext<FormData>();

  const options = [
    {
      value: "captureUTM",
      title: "Capture UTM tags data",
      required: false,
      checked: values.shopifyPixel.captureUTM && values.shopifyPixel.enabled,
      onChange(newValue: boolean) {
        setFieldTouched("shopifyPixel.captureUTM");
        setFieldValue("shopifyPixel.captureUTM", newValue);
      },
      helpText: "",
    },
    {
      value: "anonymousProfile",
      title:
        "Use IP based profiles instead of cookie-based for GDPR compliance",
      required: false,
      checked:
        values.shopifyPixel.anonymousProfile && values.shopifyPixel.enabled,
      onChange(newValue: boolean) {
        setFieldTouched("shopifyPixel.anonymousProfile");
        setFieldValue("shopifyPixel.anonymousProfile", newValue);
      },
      helpText: "",
    },
  ];

  // return (
  //   <ShopifyWizardSettings
  //     imageSrc={shopifyPixel}
  //     isChecked={values.shopifyPixel.enabled}
  //     onChange={(newValue: boolean) => {
  //       setFieldTouched("shopifyPixel.enabled");
  //       setFieldValue("shopifyPixel.enabled", newValue);
  //     }}
  //     title="Shopify Pixel"
  //     subtitle="Allow permissions to add a pixel on your shopify store"
  //     options={options}
  //   />
  // );

  return (
    <CheckboxOptionGroup
      title="Shopify Pixel"
      subtitle="Allow permissions to add a pixel on your shopify store"
      options={options}
      variant="lg"
      isChecked={values.shopifyPixel.enabled}
      onChange={(newValue: boolean) => {
        setFieldTouched("shopifyPixel.enabled");
        setFieldValue("shopifyPixel.enabled", newValue);
      }}
      icon={ShopifyPixel}
    />
  );
}
