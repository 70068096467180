import { useState } from "react";
import SearchIcon from "@untitled-ui/icons-react/build/esm/SearchLg";
import styles from "./Searchbar.module.scss";
import classNames from "classnames";
import { SearchInput } from "../SearchInput/SearchInput";

export interface ISearchbar {
  alwaysExpanded?: boolean;
  value: string;
  onChange: (inputValue: string) => unknown;
  disabled?: boolean;
  searchResults?: { label: string; onClick?: () => unknown; link?: string }[];
  input?: {
    placeholder?: string;
  };
  loading?: boolean;
}

export function Searchbar({
  alwaysExpanded = false,
  value,
  onChange,
  searchResults,
  loading,
  input,
  disabled,
}: ISearchbar) {
  const [isInputVisible, setIsInputVisible] = useState(false);

  const InputEl = (
    <SearchInput
      autoFocus={alwaysExpanded ? false : true}
      value={value}
      loading={loading}
      placeholder={input?.placeholder}
      onBlur={() => {
        if (!value) {
          setIsInputVisible(false);
        }
      }}
      onChange={(value) => onChange(value)}
      searchResults={searchResults}
    />
  );

  if (alwaysExpanded) {
    return (
      <div
        className={classNames(styles.container, {
          [styles.isInputVisible]: isInputVisible,
        })}
      >
        {InputEl}
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.container, styles.isExpandable, {
        [styles.isInputVisible]: isInputVisible,
      })}
    >
      {isInputVisible ? (
        InputEl
      ) : (
        <button
          disabled={disabled}
          className={classNames(styles.searchButton, {
            [styles.searchDisabledButton]: disabled,
          })}
          type="button"
          onClick={() => setIsInputVisible(true)}
        >
          <SearchIcon color="var(--color-disabled)" width={16} height={16} />
        </button>
      )}
    </div>
  );
}
