export default function enableMetaIntegration() {
  const params = new URLSearchParams();

  params.set("client_id", import.meta.env.VITE_META_APP_CLIENT_ID);
  params.set("redirect_uri", import.meta.env.VITE_META_APP_REDIRECT_URI);
  // TODO: handle state better to prevent CSRF attacks
  params.set("state", "sdfdsf");
  params.set("config_id", import.meta.env.VITE_META_BUSINESS_LOGIN_CONFIG_ID);
  // params.set(
  //   "config_id",
  //   import.meta.env.VITE_META_WHATSAPP_BUSINESS_LOGIN_CONFIG_ID,
  // );

  params.set("response_type", "code");

  window.open(
    `https://www.facebook.com/v18.0/dialog/oauth?${params.toString()}`,
    "_blank"
  );
}
