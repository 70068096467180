import facebookCatalogue from "../assets/facebookCatalogue.svg?react";
import facebookStandard from "../assets/facebookStandard.svg?react";
import videoThruplay from "../../../icons/slow_motion_video.svg?react";
import instagram from "../../../icons/instagram.svg?react";
import messengerSelectIcon from "../../../icons/messenger.svg?react";
import whatsapp from "../../../icons/whatsapp.svg?react";
import PhoneIncoming01 from "@untitled-ui/icons-react/build/esm/PhoneIncoming01";
import instantForm from "../assets/instantForm.svg?react";
import EyeIcon from "@untitled-ui/icons-react/build/esm/Eye";
import CursorClick02 from "@untitled-ui/icons-react/build/esm/CursorClick02";
import Hourglass03 from "@untitled-ui/icons-react/build/esm/Hourglass03";
import {
  ActivityInputV2,
  MetaActivityPurpose,
} from "../misc/createCampaignTypes";

interface ActivityMetaPurposeOption {
  label: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  value: MetaActivityPurpose;
  category: "SALES" | "TRAFFIC" | "ENGAGEMENT";
  requiredFields: Array<keyof ActivityInputV2>;
}

export const activityMetaPurposeOptions: ActivityMetaPurposeOption[] = [
  {
    label: "Get more sales and conversions",
    icon: facebookStandard,
    value: "SALES_STANDARD" as MetaActivityPurpose,
    category: "SALES",
    requiredFields: ["metaPageId"],
  },
  {
    label: "Promote your product catalogue",
    icon: facebookCatalogue,
    value: "SALES_CATALOG" as MetaActivityPurpose,
    category: "SALES",
    requiredFields: ["metaPageId"],
  },
  {
    label: "Get more lead form submissions",
    icon: instantForm,
    value: "LEADS" as MetaActivityPurpose,
    category: "SALES",
    requiredFields: ["metaPageId"],
  },
  // traffic
  {
    label: "Make more people engage with your post",
    icon: EyeIcon,
    value: "PROMOTE_POST" as MetaActivityPurpose,
    category: "TRAFFIC",
    requiredFields: ["metaInstagramPageId"],
  },
  {
    label: "Make more people visit your website",
    icon: CursorClick02,
    value: "WEBSITE_VIEWS" as MetaActivityPurpose,
    category: "TRAFFIC",
    requiredFields: ["metaPixelId", "metaPageId"],
  },
  // engagement
  {
    label: "Get more messages on Instagram",
    icon: instagram,
    value: "MESSAGE_INSTAGRAM" as MetaActivityPurpose,
    category: "ENGAGEMENT",
    // WAS CAUSING BUILDER ERROR
    // requiredFields: ["metaInstagramAccountId", "metaPageId"],
    requiredFields: ["metaInstagramPageId"],
  },
  {
    label: "Get more messages on Whatsapp",
    icon: whatsapp,
    value: "MESSAGE_WHATSAPP" as MetaActivityPurpose,
    category: "ENGAGEMENT",
    requiredFields: ["metaPageId"],
  },
  {
    label: "Get more messages on Facebook Messenger",
    icon: messengerSelectIcon,
    value: "MESSAGE_MESSENGER" as MetaActivityPurpose,
    category: "ENGAGEMENT",
    requiredFields: ["metaPageId"],
  },
  {
    label: "Get more phone calls",
    icon: PhoneIncoming01,
    value: "CALL" as MetaActivityPurpose,
    category: "ENGAGEMENT",
    requiredFields: ["metaPageId"],
  },
  {
    label: "Make more people watch an entire video",
    icon: videoThruplay,
    value: "VIDEO_THRUPLAY" as MetaActivityPurpose,
    category: "ENGAGEMENT",
    requiredFields: ["metaPageId"],
  },
  {
    label: "Make more people watch a video for atleast 2 seconds",
    icon: Hourglass03,
    value: "VIDEO_2_SEC_VIEW" as MetaActivityPurpose,
    category: "ENGAGEMENT",
    requiredFields: ["metaPageId"],
  },
];
