import { SVGAttributes } from "react";
import styles from "./BaseCalloutCard.module.scss";
import { Icon } from "../Icon/Icon";
import classNames from "classnames";
import { Text } from "../Text/Text";
import {
  IconWithWrapper,
  IconWithWrapperProps,
} from "../IconWithWrapper/IconWithWrapper";

export interface BaseCalloutCardProps {
  imageSrc?: string;
  icon?: React.FC<SVGAttributes<any>> | React.FC<SVGAttributes<any>>[];
  iconColor?: IconWithWrapperProps["iconColor"];
  useIconWithWrapper?: boolean;
  title: string;
  variant?: "inline" | "card";
  description?: string;
  children: React.ReactNode;
}

export function BaseCalloutCard({
  icon,
  iconColor = "default",
  imageSrc,
  useIconWithWrapper = false,
  title,
  variant = "card",
  description,
  children,
}: BaseCalloutCardProps) {
  return (
    <div
      className={classNames({
        [styles.card]: variant === "card",
        [styles.inline]: variant === "inline",
      })}
    >
      <div className={styles.primarySection}>
        {imageSrc && (
          <img src={imageSrc} alt={imageSrc} className={styles.image} />
        )}
        {icon &&
          (Array.isArray(icon) ? (
            <div className={styles.iconList}>
              {icon.map((icon) => (
                <Icon icon={icon} size={variant === "card" ? "md" : "xl"} />
              ))}
            </div>
          ) : useIconWithWrapper ? (
            <IconWithWrapper icon={icon} iconColor={iconColor} size={"lg"} />
          ) : (
            <Icon
              icon={icon}
              size={variant === "card" ? "md" : "xl"}
              iconColor={iconColor}
            />
          ))}
        <div className={styles.header}>
          <Text weight="medium">{title}</Text>
          <Text size="2xs" tone="subdued">
            {description}
          </Text>
        </div>
      </div>

      {children}
    </div>
  );
}
