import { Audience, MetaAudienceSet } from "src/graphql/generated/schema";
import styles from "./AudienceReportingCard.module.scss";
import { AudienceTitleWithCategories } from "src/modules/global/components/AudienceTitleWithCategories";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Spinner } from "src/components/Spinner/Spinner";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { generateRangeString } from "src/lib/generateRangeString";
import {
  AdsetAggregateType,
  useAdsetAggregatedStats,
} from "src/modules/global/functions/useAdsetAggregatedStats";
import { useAdsetPerTickStats } from "src/modules/global/functions/useAdsetPerTickStats";
import { getMonthFromNumber } from "src/modules/campaign/misc/getMonthFromNumber";
import { GraphCard } from "src/modules/global/components/GraphCard";
import format from "date-fns/format";
import { Button } from "src/components/Button/Button";
import KeyboardArrowRight from "@material-symbols/svg-600/rounded/keyboard_arrow_right.svg?react";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { ArrowRight, BarLineChart, User01 } from "@untitled-ui/icons-react";
import { Text } from "src/components/Text/Text";
import Header from "src/components/Header/Header";
import DateFilterDropdown from "./DateFilterDropdown";
import DatePicker from "src/components/Datepicker/Datepicker";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { OverviewGraphCard } from "./OverviewGraphCard";

interface MetaActivityAdsetSummary {
  id: string;
  budget?: number;
  Audience?: Pick<
    Audience,
    "id" | "name" | "createdAt" | "MetaInsights" | "type" | "__typename"
  >;
  MetaAudienceSet?: Pick<
    MetaAudienceSet,
    | "id"
    | "name"
    | "createdAt"
    | "MetaInsights"
    | "__typename"
    | "AudienceCategories"
  >;
}

interface AudienceReportingCard {
  title: string;
  subtitle: string;
  metaActivityAdsets: MetaActivityAdsetSummary[];
  dateFrom?: Date;
  dateTo?: Date;
  activityId?: string;
}

export function AudienceReportingCard({
  title,
  subtitle,
  metaActivityAdsets,
  dateFrom,
  dateTo,
  activityId,
}: AudienceReportingCard) {
  const [selectedAdset, setSelectedAdset] = useState(
    metaActivityAdsets.length > 0 ? metaActivityAdsets[0].id : null,
  );

  const selectedActivityAdset = metaActivityAdsets.find(
    (v) => v.id === selectedAdset,
  );

  return (
    <div className={styles.card}>
      {/* Side Panel */}
      <div className="flex flex-col gap-6 border-r">
        <div className="flex px-24 pt-16 pb-12 border-b">
          <SectionHeader
            title={title}
            hasIconWrapper
            icon={User01}
            iconColor="default"
          />
        </div>
        <RadioGroup
          as="div"
          className={"flex flex-col gap-4 px-14 pt-16"}
          value={selectedAdset}
          onChange={setSelectedAdset}
        >
          {metaActivityAdsets.map((v) => (
            <RadioGroup.Option
              as="div"
              key={v.id}
              value={v.id}
              className={classNames(
                "flex justify-between align-center px-8 py-16 rounded-8",
                {
                  "bg-gray-100": selectedAdset === v.id,
                },
              )}
              onKeyUp={(e) => e.preventDefault()}
              onKeyDown={(e) => e.preventDefault()}
            >
              <AudienceTitleWithCategories
                audience={v.Audience ?? v.MetaAudienceSet}
                isActive={selectedAdset === v.id}
              />
              <Text size="sm">
                {formatNumberByType(v?.budget / 100, "CURRENCY")}
              </Text>
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        {/*
        {footer && (
          <div className={styles.footerWrapper}>
            <div className={styles.footer}>
              <div className={styles.footerHeading}>{footer.heading}</div>
              <div className={styles.footerValue}>{footer.value}</div>
            </div>
          </div>
        )}
        */}
      </div>

      {selectedActivityAdset && (
        <AdSetGraphStats
          adset={selectedActivityAdset}
          dateFrom={dateFrom}
          dateTo={dateTo}
          activityId={activityId}
        />
      )}
    </div>
  );
}

function AdSetGraphStats({
  adset,
  dateFrom,
  dateTo,
  activityId,
}: {
  adset: MetaActivityAdsetSummary;
  dateFrom?: Date;
  dateTo?: Date;
  activityId?: string;
}) {
  const { data, loading } = useAdsetPerTickStats(
    adset.id,
    {
      dateFrom,
      dateTo,
    },
    activityId,
  );
  const { data: adsetAggregatedStats, loading: adsetAggregatedStatsLoading } =
    useAdsetAggregatedStats(
      adset.id,
      {
        dateFrom,
        dateTo,
      },
      activityId,
    );

  if (loading || adsetAggregatedStatsLoading) {
    return (
      <div className="flex flex-col h-full relative">
        <Spinner height="full" />
      </div>
    );
  }
  const adsetItem = adset?.Audience || adset?.MetaAudienceSet;
  if (!adsetItem) return <div></div>;

  const name = adsetItem.name;
  const estimatedSizeUpperBound = adsetItem.MetaInsights?.upperBoundCount;
  const estimatedSizeLowerBound = adsetItem.MetaInsights?.lowerBoundCount;
  const createdAt = new Date(adsetItem.createdAt);
  const estimatedSizeRange = generateRangeString(
    estimatedSizeLowerBound,
    estimatedSizeUpperBound,
  );

  const chartData = data
    ? data.map((v) => ({
        time:
          "tickDate" in v ? v.tickDate : `${getMonthFromNumber(v.tickMonth)}`,
        ...v,
      }))
    : [];

  return (
    <div className="flex flex-col h-full relative">
      <div className="flex align-items justify-between px-24 pt-16 pb-12 border-b">
        <SectionHeader
          title={"Audience Stats"}
          icon={BarLineChart}
          hasIconWrapper
        />
        <DatePicker onChange={() => {}} />
      </div>
      {/* <div className={styles.graphSectionHeader}>
        <div>
          <div className={styles.graphStatsTitle}>{name}</div>
          <div className={styles.graphStatsSubtitle}>
            Estimate size: {estimatedSizeRange}
          </div>
        </div>
      </div> */}
      <div className="grid h-full place-items-center px-24 pb-48">
        <div className="flex gap-16">
          <OverviewGraphCard
            title={"Impressions"}
            subtitle={"as per selected date"}
            value={formatNumberByType(
              adsetAggregatedStats?.impressions,
              "NUMBER",
            )}
            chartData={chartData}
            yAxis={{
              dataKey: "impressions",
              label: "Impressions",
              hide: true,
            }}
          />
          <OverviewGraphCard
            title={"Clicks"}
            subtitle={"as per selected date"}
            value={formatNumberByType(adsetAggregatedStats?.clicks, "NUMBER")}
            chartData={chartData}
            yAxis={{
              dataKey: "clicks",
              label: "Clicks",
              hide: true,
            }}
          />
        </div>
      </div>

      <div className={"absolute bottom-0 border-t w-full px-20 py-16"}>
        <div className="flex justify-between items-center bottom-0">
          <ButtonV2
            size="extraSmall"
            variant="link"
            to={
              adsetItem.__typename === "Audience"
                ? `/audiences/${adsetItem.id}`
                : `/audiences-sets/${adsetItem.id}`
            }
          >
            Learn more about this audience
          </ButtonV2>

          <div className={styles.footerLabel}>
            Audience created on {format(createdAt, "dd MMM, yyyy")}
          </div>
        </div>
      </div>
    </div>
  );
}
