import { getIn, useFormikContext } from "formik";
import {
  IMultilineInput,
  MultilineInput,
} from "../MultilineInput/MultilineInput";

type MultilineInputPropsWithoutFormikFields = Omit<
  IMultilineInput,
  "onChange" | "onBlur" | "value" | "status" | "name"
>;

interface Props extends MultilineInputPropsWithoutFormikFields {
  name: string;
}

export function MultilineInputFormik({ name, ...props }: Props) {
  const { values, setFieldValue, handleBlur, errors, touched } =
    useFormikContext<any>();

  let shouldShowError = false;
  const value = getIn(values, name);
  const errorField = getIn(errors, name);
  const touchedField = getIn(touched, name);

  if (errorField && touchedField) {
    shouldShowError = true;
  }

  return (
    <MultilineInput
      name={name}
      onChange={(value) => setFieldValue(name, value)}
      onBlur={handleBlur}
      value={value}
      error={shouldShowError ? errorField : undefined}
      {...props}
    />
  );
}
