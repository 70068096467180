import styles from "./AudienceBudget.module.scss";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { AudienceBudgetLoader } from "./AudienceBudgetLoader";

export function AudienceBudget() {
  const { values, handleSubmit } = useFormikContext<ActivityInputV2>();
  const adsets = values.Adsets ?? [];

  return (
    <div className={styles.section}>
      <h2 className={styles.heading}>Budget Allocation</h2>
      <h3 className={styles.subheading}>
        Set your daily spending limit for each audience group in your ad set
      </h3>

      <div className={styles.cardList}>
        {adsets.length > 0 && <AudienceBudgetLoader adsets={adsets} />}
      </div>
    </div>
  );
}
