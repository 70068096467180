import { InlineSuggestions } from "src/components/InlineSuggestions/InlineSuggestions";
import {
  MetaInterest,
  useMetaInterestSuggestionsLazyQuery,
} from "src/graphql/generated/schema";
import { AudiencePresetAndInterestSelectorFormState } from "../misc/audienceCreateTypes";
import { useEffect } from "react";

type SelectedInterests =
  AudiencePresetAndInterestSelectorFormState["interests"];

interface IInterestInlineSuggestions {
  adAccountId?: string;
  selectedInterests?: SelectedInterests;
  onSelect: (interests: MetaInterest) => void;
}

export function InterestInlineSuggestions({
  adAccountId,
  selectedInterests,
  onSelect,
}: IInterestInlineSuggestions) {
  const [suggestionsFn, { data, loading }] =
    useMetaInterestSuggestionsLazyQuery({});

  useEffect(() => {
    if (!adAccountId || !selectedInterests) return;

    suggestionsFn({
      variables: {
        adAccountId,
        selectedInterests: Object.values(selectedInterests).map((v) => ({
          id: v.id,
          type: v.type,
        })),
      },
    });
  }, [suggestionsFn, adAccountId, selectedInterests]);

  function handleAddInterest(interest: MetaInterest) {
    onSelect(interest);
  }

  return (
    <InlineSuggestions
      name="Recommended Interests"
      suggestions={
        data
          ? data.metaInterestSuggestions.map((a) => ({
              label: a.name,
              onClick: () => handleAddInterest(a),
            }))
          : []
      }
      loading={loading}
    />
  );
}
