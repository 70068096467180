import { useState } from "react";
import { AudienceQuickOverview } from "../components/AudienceQuickOverview";
import { AudienceSingleAbout } from "../components/AudienceSingleAbout";
import { useAudienceSetQuery } from "src/graphql/generated/schema";
import { useParams } from "react-router-dom";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PageLoader } from "src/modules/global/components/PageLoader";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "../../global/misc/dateFilterUtils";
import { ResourceOverviewLoader } from "../components/ResourceOverviewLoader";
import { useAudienceSetAggregatedStats } from "src/modules/global/functions/useAudienceSetAggregatedStats";
import { AudienceDistribution } from "../components/AudienceDistribution";
import Header from "src/components/Header/Header";
import DatePicker from "src/components/Datepicker/Datepicker";
import { generateRangeString } from "src/lib/generateRangeString";

export function AudienceSetSinglePage() {
  const { id } = useParams<{ id: string }>();
  const [dateFilter, setDateFilter] = useState<DateFilter>("ALL_TIME");

  const { data, loading, error } = useAudienceSetQuery({
    variables: {
      id,
    },
  });

  const dateTo = getDateFilterFromFilterEnum(dateFilter)?.lte;
  const dateFrom = getDateFilterFromFilterEnum(dateFilter)?.gte;

  const {
    data: statData,
    loading: statLoading,
    error: statError,
  } = useAudienceSetAggregatedStats(id, {
    dateFrom: dateFrom,
    dateTo: dateTo,
  });

  if (loading || statLoading) {
    return <PageLoader />;
  }

  if (error || statError) {
    return <ErrorHandler error={error || statError} />;
  }

  const audienceSet = data.audienceSet;

  return (
    <>
      <Header
        title={data.audienceSet.name}
        showBack
        showNotifications={false}
        actions={
          <DatePicker
            onChange={() => {}}
            // showLabel={true}
            // setDateFilter={setDateFilter}
            // dateFilter={dateFilter}
          />
        }
      />

      <div className={"p-20 flex flex-col gap-40"}>
        <ResourceOverviewLoader
          statData={statData}
          dateFrom={dateFrom}
          dateTo={dateTo}
          audienceId={id}
        />
        <AudienceQuickOverview
          loading={statLoading}
          insights={{
            ...statData,
            estimatedSize: audienceSet.MetaInsights
              ? generateRangeString(
                  audienceSet.MetaInsights?.lowerBoundCount,
                  audienceSet.MetaInsights?.upperBoundCount,
                )
              : "-",
          }}
        />
        <div>
          <AudienceDistribution
            audienceSetData={audienceSet}
            audienceSetId={id}
          />
        </div>
        <AudienceSingleAbout audience={audienceSet as any} />
      </div>
    </>
  );
}
