import macroLogo from "src/modules/global/assets/macroLogo.svg";

export const sidebarDefaultSteps = {
  logo: {
    src: macroLogo,
    alt: "macro-logo",
  },
  primary: [
    {
      label: "Dashboard",
      link: "/",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM15.8329 7.33748C16.0697 7.17128 16.3916 7.19926 16.5962 7.40381C16.8002 7.60784 16.8267 7.92955 16.6587 8.16418C14.479 11.2095 13.2796 12.8417 13.0607 13.0607C12.4749 13.6464 11.5251 13.6464 10.9393 13.0607C10.3536 12.4749 10.3536 11.5251 10.9393 10.9393C11.3126 10.5661 12.9438 9.36549 15.8329 7.33748ZM17.5 11C18.0523 11 18.5 11.4477 18.5 12C18.5 12.5523 18.0523 13 17.5 13C16.9477 13 16.5 12.5523 16.5 12C16.5 11.4477 16.9477 11 17.5 11ZM6.5 11C7.05228 11 7.5 11.4477 7.5 12C7.5 12.5523 7.05228 13 6.5 13C5.94772 13 5.5 12.5523 5.5 12C5.5 11.4477 5.94772 11 6.5 11ZM8.81802 7.40381C9.20854 7.79433 9.20854 8.4275 8.81802 8.81802C8.4275 9.20854 7.79433 9.20854 7.40381 8.81802C7.01328 8.4275 7.01328 7.79433 7.40381 7.40381C7.79433 7.01328 8.4275 7.01328 8.81802 7.40381ZM12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5C11 5.94772 11.4477 5.5 12 5.5Z"></path>
        </svg>
      ),
    },
    {
      label: "Campaigns",
      link: "/campaigns",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12.334 10.6667C13.6226 10.6667 14.6673 8.72657 14.6673 6.33333C14.6673 3.9401 13.6226 2 12.334 2M12.334 10.6667C11.0453 10.6667 10.0007 8.72657 10.0007 6.33333C10.0007 3.9401 11.0453 2 12.334 2M12.334 10.6667L3.62968 9.08407C3.01135 8.97164 2.70219 8.91543 2.4522 8.79263C1.94363 8.54282 1.56489 8.08901 1.41008 7.54395C1.33398 7.27603 1.33398 6.9618 1.33398 6.33333C1.33398 5.70487 1.33398 5.39063 1.41008 5.12271C1.56489 4.57766 1.94363 4.12385 2.4522 3.87403C2.70219 3.75124 3.01135 3.69502 3.62968 3.5826L12.334 2M3.33398 9.33333L3.59656 13.0093C3.62149 13.3584 3.63396 13.533 3.7099 13.6653C3.77676 13.7817 3.87724 13.8753 3.99818 13.9337C4.13553 14 4.31053 14 4.66051 14H5.84878C6.24888 14 6.44893 14 6.59699 13.9202C6.72709 13.8501 6.83021 13.7387 6.89014 13.6037C6.95834 13.4499 6.94299 13.2504 6.91231 12.8515L6.66732 9.66667"
            stroke="#44474A"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      label: "Audiences",
      link: "/audiences",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M1.9987 14.666C1.63051 14.666 1.32766 14.366 1.37313 14.0007C1.7006 11.3691 3.94516 9.33268 6.66536 9.33268C9.38558 9.33268 11.6301 11.3691 11.9576 14.0007C12.0031 14.366 11.7002 14.666 11.332 14.666C10.9638 14.666 10.6712 14.3653 10.6105 14.0021C10.2943 12.109 8.64833 10.666 6.66536 10.666C4.68239 10.666 3.03644 12.109 2.7202 14.0021C2.65954 14.3653 2.36689 14.666 1.9987 14.666ZM6.66536 8.66602C4.45536 8.66602 2.66536 6.87602 2.66536 4.66602C2.66536 2.45602 4.45536 0.666016 6.66536 0.666016C8.87536 0.666016 10.6654 2.45602 10.6654 4.66602C10.6654 6.87602 8.87536 8.66602 6.66536 8.66602ZM6.66536 7.33268C8.1387 7.33268 9.33203 6.13935 9.33203 4.66602C9.33203 3.19268 8.1387 1.99935 6.66536 1.99935C5.19203 1.99935 3.9987 3.19268 3.9987 4.66602C3.9987 6.13935 5.19203 7.33268 6.66536 7.33268ZM11.914 10.4097C12.0652 10.0737 12.4631 9.92061 12.7774 10.1128C14.1305 10.9404 15.0861 12.3541 15.291 14.0009C15.3364 14.3662 15.0336 14.666 14.6654 14.666C14.2972 14.666 14.0045 14.3654 13.9439 14.0022C13.7589 12.8947 13.1189 11.9413 12.2225 11.3407C11.9167 11.1357 11.763 10.7454 11.914 10.4097ZM11.5176 3.01641C11.6306 2.62108 12.0675 2.40885 12.4064 2.64166C13.3682 3.30244 13.9987 4.41026 13.9987 5.66602C13.9987 7.32067 12.9041 8.71848 11.3991 9.17515C11.0143 9.29191 10.6654 8.97127 10.6654 8.56917C10.6654 8.22867 10.9219 7.94993 11.2359 7.81802C12.0759 7.46503 12.6654 6.63468 12.6654 5.66602C12.6654 4.95191 12.345 4.31298 11.84 3.88506C11.5857 3.6695 11.426 3.33699 11.5176 3.01641Z"
            fill="#44474A"
          />
        </svg>
      ),
    },
    {
      label: "Contacts",
      link: "/contacts",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clip-path="url(#clip0_1768_79)">
            <path
              d="M2.66636 14.545C3.06807 14.6667 3.61067 14.6667 4.53301 14.6667H11.4663C12.3887 14.6667 12.9313 14.6667 13.333 14.545M2.66636 14.545C2.58022 14.5189 2.50056 14.4872 2.42503 14.4487C2.0487 14.257 1.74274 13.951 1.55099 13.5747C1.33301 13.1469 1.33301 12.5868 1.33301 11.4667V4.53337C1.33301 3.41327 1.33301 2.85322 1.55099 2.42539C1.74274 2.04907 2.0487 1.74311 2.42503 1.55136C2.85285 1.33337 3.4129 1.33337 4.53301 1.33337H11.4663C12.5864 1.33337 13.1465 1.33337 13.5743 1.55136C13.9506 1.74311 14.2566 2.04907 14.4484 2.42539C14.6663 2.85322 14.6663 3.41327 14.6663 4.53337V11.4667C14.6663 12.5868 14.6663 13.1469 14.4484 13.5747C14.2566 13.951 13.9506 14.257 13.5743 14.4487C13.4988 14.4872 13.4191 14.5189 13.333 14.545M2.66636 14.545C2.66658 14.0054 2.66981 13.7199 2.71758 13.4798C2.928 12.422 3.75493 11.595 4.81277 11.3846C5.07036 11.3334 5.38013 11.3334 5.99967 11.3334H9.99967C10.6192 11.3334 10.929 11.3334 11.1866 11.3846C12.2444 11.595 13.0714 12.422 13.2818 13.4798C13.3295 13.7199 13.3328 14.0054 13.333 14.545M10.6663 6.33337C10.6663 7.80613 9.47243 9.00004 7.99967 9.00004C6.52692 9.00004 5.33301 7.80613 5.33301 6.33337C5.33301 4.86061 6.52692 3.66671 7.99967 3.66671C9.47243 3.66671 10.6663 4.86061 10.6663 6.33337Z"
              stroke="#44474A"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1768_79">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    // {
    //   label: "Reports",
    //   link: "/reports",
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 24 24"
    //       fill="currentColor"
    //     >
    //       <path d="M7 21.5C4.51472 21.5 2.5 19.4853 2.5 17C2.5 14.5147 4.51472 12.5 7 12.5C9.48528 12.5 11.5 14.5147 11.5 17C11.5 19.4853 9.48528 21.5 7 21.5ZM17 11.5C14.5147 11.5 12.5 9.48528 12.5 7C12.5 4.51472 14.5147 2.5 17 2.5C19.4853 2.5 21.5 4.51472 21.5 7C21.5 9.48528 19.4853 11.5 17 11.5ZM7 19.5C8.38071 19.5 9.5 18.3807 9.5 17C9.5 15.6193 8.38071 14.5 7 14.5C5.61929 14.5 4.5 15.6193 4.5 17C4.5 18.3807 5.61929 19.5 7 19.5ZM17 9.5C18.3807 9.5 19.5 8.38071 19.5 7C19.5 5.61929 18.3807 4.5 17 4.5C15.6193 4.5 14.5 5.61929 14.5 7C14.5 8.38071 15.6193 9.5 17 9.5ZM3 8C3 5.23858 5.23858 3 8 3H11V5H8C6.34315 5 5 6.34315 5 8V11H3V8ZM21 13H19V16C19 17.6569 17.6569 19 16 19H13V21H16C18.7614 21 21 18.7614 21 16V13Z"></path>
    //     </svg>
    //   ),
    // },
    {
      label: "Library",
      link: "/library/ad-library",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clip-path="url(#clip0_1768_60)">
            <path
              d="M13.0408 4.66667C13.1933 3.79682 12.5239 3 11.6408 3H4.35826C3.47516 3 2.80582 3.79682 2.95824 4.66667"
              stroke="#44474A"
            />
            <path
              d="M11.6666 3.00065C11.6855 2.82793 11.695 2.74155 11.6951 2.67022C11.6967 1.9878 11.1826 1.41441 10.5041 1.3416C10.4331 1.33398 10.3463 1.33398 10.1725 1.33398H5.82732C5.65356 1.33398 5.56668 1.33398 5.49574 1.3416C4.81722 1.41441 4.3032 1.9878 4.30469 2.67021C4.30484 2.74155 4.3143 2.82792 4.33322 3.00065"
              stroke="#44474A"
            />
            <path
              d="M10 12H6"
              stroke="#44474A"
              stroke-width="1.2"
              stroke-linecap="round"
            />
            <path
              d="M14.1297 11.1947C13.8965 12.8486 13.7799 13.6755 13.1818 14.1708C12.5837 14.666 11.7015 14.666 9.93705 14.666H6.06422C4.29986 14.666 3.41767 14.666 2.81954 14.1708C2.22142 13.6755 2.10482 12.8486 1.87164 11.1947L1.58966 9.19468C1.2923 7.08562 1.14363 6.03112 1.77553 5.34857C2.40743 4.66602 3.53237 4.66602 5.78224 4.66602H10.2191C12.4689 4.66602 13.5939 4.66602 14.2258 5.34857C14.725 5.8878 14.737 6.65921 14.5735 7.99935"
              stroke="#44474A"
              stroke-width="1.3"
              stroke-linecap="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1768_60">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      iconHover: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#312CCB"
        >
          <path d="M7 21.5C4.51472 21.5 2.5 19.4853 2.5 17C2.5 14.5147 4.51472 12.5 7 12.5C9.48528 12.5 11.5 14.5147 11.5 17C11.5 19.4853 9.48528 21.5 7 21.5ZM17 11.5C14.5147 11.5 12.5 9.48528 12.5 7C12.5 4.51472 14.5147 2.5 17 2.5C19.4853 2.5 21.5 4.51472 21.5 7C21.5 9.48528 19.4853 11.5 17 11.5ZM3 8C3 5.23858 5.23858 3 8 3H11V5H8C6.34315 5 5 6.34315 5 8V11H3V8ZM19 13V16C19 17.6569 17.6569 19 16 19H13V21H16C18.7614 21 21 18.7614 21 16V13H19Z"></path>
        </svg>
      ),
      children: [
        {
          label: "Media Center",
          link: "/library/media-center",
        },
        {
          label: "Creatives",
          link: "/library/ad-library",
        },
        {
          label: "Catalogues",
          link: "/library/catalogues",
        },
        {
          label: "Templates",
          link: "/templates",
        },
      ],
    },
    {
      label: "Reports",
      link: "/reports",
      icon: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00065 2.00065H10.8007C11.9208 2.00065 12.4808 2.00065 12.9086 2.21864C13.285 2.41038 13.5909 2.71635 13.7827 3.09267C14.0007 3.52049 14.0007 4.08055 14.0007 5.20065V10.8007C14.0007 11.9208 14.0007 12.4808 13.7827 12.9086C13.5909 13.285 13.285 13.5909 12.9086 13.7827C12.4808 14.0007 11.9208 14.0007 10.8007 14.0007H5.20065C4.08055 14.0007 3.52049 14.0007 3.09267 13.7827C2.71635 13.5909 2.41038 13.285 2.21864 12.9086C2.00065 12.4808 2.00065 11.9208 2.00065 10.8007V8.00065M5.33398 8.66732V11.334M10.6673 7.33398V11.334M8.00065 4.66732V11.334M1.33398 3.33398L3.33398 1.33398M3.33398 1.33398L5.33398 3.33398M3.33398 1.33398L3.33398 5.33398"
            stroke="#1A1C1D"
            stroke-width="1.3333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      iconHover: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00065 2.00065H10.8007C11.9208 2.00065 12.4808 2.00065 12.9086 2.21864C13.285 2.41038 13.5909 2.71635 13.7827 3.09267C14.0007 3.52049 14.0007 4.08055 14.0007 5.20065V10.8007C14.0007 11.9208 14.0007 12.4808 13.7827 12.9086C13.5909 13.285 13.285 13.5909 12.9086 13.7827C12.4808 14.0007 11.9208 14.0007 10.8007 14.0007H5.20065C4.08055 14.0007 3.52049 14.0007 3.09267 13.7827C2.71635 13.5909 2.41038 13.285 2.21864 12.9086C2.00065 12.4808 2.00065 11.9208 2.00065 10.8007V8.00065M5.33398 8.66732V11.334M10.6673 7.33398V11.334M8.00065 4.66732V11.334M1.33398 3.33398L3.33398 1.33398M3.33398 1.33398L5.33398 3.33398M3.33398 1.33398L3.33398 5.33398"
            stroke="#1A1C1D"
            stroke-width="1.3333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    // {
    //   label: "Conversations",
    //   link: "/conversations",
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 24 24"
    //       fill="currentColor"
    //     >
    //       <path d="M7 21.5C4.51472 21.5 2.5 19.4853 2.5 17C2.5 14.5147 4.51472 12.5 7 12.5C9.48528 12.5 11.5 14.5147 11.5 17C11.5 19.4853 9.48528 21.5 7 21.5ZM17 11.5C14.5147 11.5 12.5 9.48528 12.5 7C12.5 4.51472 14.5147 2.5 17 2.5C19.4853 2.5 21.5 4.51472 21.5 7C21.5 9.48528 19.4853 11.5 17 11.5ZM7 19.5C8.38071 19.5 9.5 18.3807 9.5 17C9.5 15.6193 8.38071 14.5 7 14.5C5.61929 14.5 4.5 15.6193 4.5 17C4.5 18.3807 5.61929 19.5 7 19.5ZM17 9.5C18.3807 9.5 19.5 8.38071 19.5 7C19.5 5.61929 18.3807 4.5 17 4.5C15.6193 4.5 14.5 5.61929 14.5 7C14.5 8.38071 15.6193 9.5 17 9.5ZM3 8C3 5.23858 5.23858 3 8 3H11V5H8C6.34315 5 5 6.34315 5 8V11H3V8ZM21 13H19V16C19 17.6569 17.6569 19 16 19H13V21H16C18.7614 21 21 18.7614 21 16V13Z"></path>
    //     </svg>
    //   ),
    // },
  ],
};

export const organizationSettingsSidebarConfig = [
  {
    label: "General",
    link: "/settings/organization",
  },
  {
    label: "Billing",
    link: "/settings/billing",
  },
  {
    label: "Overall Usage",
    link: "/settings/usage",
  },
  {
    label: "Clients",
    link: "/settings/clients",
  },
  {
    label: "Members",
    link: "/settings/members",
  },
];

export const clientSettingsSidebarConfig = [
  // {
  //   label: "Usage",
  //   link: "/settings/clients/usage",
  // },
  {
    label: "Notifications",
    link: "/settings/notifications",
  },
  {
    label: "Profile",
    link: "/settings/clients/current",
  },
  {
    label: "Brand",
    link: "/settings/clients/current/brand",
  },
  {
    label: "Integrations",
    link: "/settings/integrations",
  },
];
