import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import styles from "./OverallUsageStats.module.scss";
import { useOrganizationGrowOverallUsageQuery } from "src/graphql/generated/schema";
import { useMemo } from "react";
import { formatNumber } from "src/lib/formatNumber";
import KeyboardArrowRight from "@material-symbols/svg-600/rounded/keyboard_arrow_right.svg?react";
import {
  CreditCard01,
  List,
  Container,
  Users01,
} from "@untitled-ui/icons-react";
import { addCommas } from "src/lib/addCommas";
import { Link } from "react-router-dom";
import classNames from "classnames";

export function OverallUsageStats() {
  const { data, loading } = useOrganizationGrowOverallUsageQuery();
  // billing always in USD
  const selectedCurrencyCode = "$";

  const stats = useMemo(() => {
    const organizationGrowOverallUsage = data?.organizationGrowOverallUsage;

    return [
      {
        label: "Activities Count",
        value: formatNumber(organizationGrowOverallUsage?.activitiesCount || 0),
        valueIcon: Container,
        action: {
          icon: KeyboardArrowRight,
          label: "View All Activities",
          to: "/campaigns",
        },
      },
      {
        label: "Active Activities",
        value: formatNumber(
          organizationGrowOverallUsage?.activeActivitiesCount || 0,
        ),
        valueIcon: List,
        action: {
          icon: KeyboardArrowRight,
          label: "View All Activities",
          to: "/campaigns",
        },
      },
      {
        label: "Ad Spends (Monthly)",
        value: (
          <div>
            {selectedCurrencyCode}
            {addCommas(
              organizationGrowOverallUsage?.consumedAdSpend / 100 || 0,
            )}{" "}
            /{" "}
            {addCommas(
              organizationGrowOverallUsage?.allocatedAdSpend / 100 || 0,
            )}{" "}
          </div>
        ),
        valueIcon: CreditCard01,
        action: {
          label: `${addCommas(
            (organizationGrowOverallUsage?.allocatedAdSpend ?? 0) / 100,
          )} limit as per current plan`,
          to: "/settings/pricing",
          color: "subdued" as const,
        },
      },
      {
        label: "Audience Count",
        value: formatNumber(organizationGrowOverallUsage?.audienceCount || 0),
        valueIcon: Users01,
        action: {
          icon: KeyboardArrowRight,
          label: "View Audiences",
          to: "/audiences",
        },
      },
    ];
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <SectionHeader
        size="large"
        title={"Overview of usage"}
        description="View details of you selected plan and monitor usage effectively."
      />

      <div className={styles.statsCard}>
        <div className={styles.statsCardTitle}>Macro Grow Usage</div>

        <div className={styles.statsList}>
          {stats.map((v) => {
            const ValueIcon = v.valueIcon;
            const ActionIcon = v.action.icon;

            return (
              <div className={styles.stat}>
                <div className={styles.statContent}>
                  <div className={styles.label}>{v.label}</div>
                  <div className={styles.value}>
                    {v.valueIcon && <ValueIcon width={14} height={14} />}
                    {v.value}
                  </div>
                </div>
                <div>
                  <Link
                    className={classNames(styles.action, {
                      [styles.actionSubdued]: v.action.color === "subdued",
                    })}
                    to={v.action.to}
                  >
                    <span>{v.action.label}</span>
                    {v.action.icon && <ActionIcon width={12} height={12} />}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
