import { Form, Formik } from "formik";
import { RadioGroup } from "@headlessui/react";
import * as Yup from "yup";
import { SelectableItemCard } from "src/components/SelectableItemCard/SelectableItemCard";
import { clientAnalyticsCategoryOptions } from "src/modules/global/misc/clientAnalyticsCategoryOptions";
import { CreateClientModalProps } from "../misc/CreateClientModalProps";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ClientCreateWizardInput } from "../misc/ClientCreateWizardInput";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { FormikError } from "src/components/FormikError/FormikError";

const createClientSchema = Yup.object({
  clientCreate: Yup.object({
    analyticsCategory: Yup.string().required("Analytics category is required"),
  }),
});

type ICreateClientModal2 = CreateClientModalProps<ClientCreateWizardInput> & {
  submitLabel?: string;
};

export function CreateClientModal2({
  initialValues,
  onBack,
  onSubmit,
  submitLabel,
}: ICreateClientModal2) {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={createClientSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, setFieldTouched, isSubmitting }) => (
        <Form className="grid grid-rows-[auto_1fr_auto] h-full gap-16">
          <SectionHeader
            title="Select only one of the following KPIs"
            description="Choose the most impactful KPIs to drive your business growth."
          />

          <RadioGroup
            className="pt-16 flex flex-col gap-16"
            value={values.clientCreate.analyticsCategory}
            onChange={(value) => {
              setFieldValue("clientCreate.analyticsCategory", value);
              setFieldTouched("clientCreate.analyticsCategory");
            }}
          >
            {clientAnalyticsCategoryOptions.map((option) => (
              <SelectableItemCard
                key={option.value}
                iconPlacement="withTitle"
                selectorAlignment="center"
                selectorType="radio"
                value={option.value}
                label={option.title}
                description={option.subtitle}
                icon={option.icon}
              />
            ))}

            <FormikError fieldName="clientCreate.analyticsCategory" />
          </RadioGroup>

          <div className="flex gap-8">
            <ButtonV2 size="small" loading={isSubmitting} type="submit">
              {submitLabel ?? "Create Client"}
            </ButtonV2>
            <ButtonV2 size="small" variant="outline" onClick={onBack}>
              Back
            </ButtonV2>
          </div>
        </Form>
      )}
    </Formik>
  );
}
