import styles from "./SelectLabel.module.scss";
import Tooltip from "src/components/Tooltip/Tooltip";
import Info from "@material-symbols/svg-600/rounded/info.svg?react";

export function SelectLabel({ labelText, tooltipText }) {
  return (
    <span className={styles.selectLabel}>
      {labelText}
      <Tooltip content={tooltipText}>
        <Info width={16} height={16} />
      </Tooltip>
    </span>
  );
}
