import { MonthlyAdSpendRange } from "src/graphql/generated/schema";

export const adSpendOptions = [
  {
    label: "$2,000",
    value: "TIER_101" as MonthlyAdSpendRange,
    price: {
      standardMonthlyAmount: 59_00,
      standardAnnuallyAmount: 566_00,
      premiumMonthlyAmount: 79_00,
      premiumAnnuallyAmount: 758_00,
      currencyCode: "USD",
    },
  },
  {
    label: "$7,500",
    value: "TIER_201" as MonthlyAdSpendRange,
    price: {
      standardMonthlyAmount: 99_00,
      standardAnnuallyAmount: 950_00,
      premiumMonthlyAmount: 129_00,
      premiumAnnuallyAmount: 1238_00,
      currencyCode: "USD",
    },
  },
  {
    label: "$15,000",
    value: "TIER_301" as MonthlyAdSpendRange,
    price: {
      standardMonthlyAmount: 199_00,
      standardAnnuallyAmount: 1910_00,
      premiumMonthlyAmount: 249_00,
      premiumAnnuallyAmount: 2390_00,
      currencyCode: "USD",
    },
  },
];

export const customerCountOptions = [
  {
    label: "1 - 5000",
    value: "CUSTOMER_COUNT_1_TO_2500",
    price: {
      standardMonthlyAmount: 5_000_00,
      standardAnnuallyAmount: 5_000_00 * 10,
      premiumMonthlyAmount: 500000,
      premiumAnnuallyAmount: 500000 * 10,
      currencyCode: "INR",
    },
  },
  {
    label: "5000 - 25,000",
    value: "CUSTOMER_COUNT_2500_TO_10000",
    price: {
      standardMonthlyAmount: 15_000_00,
      standardAnnuallyAmount: 15_000_00 * 10,
      premiumMonthlyAmount: 1500000,
      premiumAnnuallyAmount: 1500000 * 10,
      currencyCode: "INR",
    },
  },
  {
    label: "25,000 - 50,000",
    value: "CUSTOMER_COUNT_10000_TO_50000",
    price: {
      standardMonthlyAmount: 2500000,
      standardAnnuallyAmount: 2500000 * 10,
      premiumMonthlyAmount: 2500000,
      premiumAnnuallyAmount: 2500000 * 10,
      currencyCode: "INR",
    },
  },
];
