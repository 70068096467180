import { useState } from "react";
import { ButtonV2 } from "../../../components/ButtonV2/ButtonV2";
import Modal from "../../../components/Modal/Modal";
import UserUp01 from "@untitled-ui/icons-react/build/esm/UserUp01";
import Info from "@untitled-ui/icons-react/build/esm/InfoCircle";
import CreatableSelect from "react-select/creatable";
import { Label } from "../../../components/Label/Label";
import styles from "./AddRecipientModal.module.scss";
import { useFormikContext } from "formik";
import { FormValues } from "../pages/ReportBuilder";
import { toggleArray } from "../../../lib/toggleArray";
import { FormikError } from "../../../components/FormikError/FormikError";

export function AddRecipientModal() {
  const { values, setFieldValue, errors, validateForm } =
    useFormikContext<FormValues>();

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setFieldValue(
          "recipientEmails",
          toggleArray(values.recipientEmails, inputValue)
        );
        setInputValue("");
        event.preventDefault();
    }
  };

  return (
    <>
      <div>
        <ButtonV2 variant="outline" onClick={() => setIsOpen(true)}>
          Add recipient
        </ButtonV2>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={function (i: any): void {
          setIsOpen(false);
        }}
        maxWidth="600px"
        title={
          <div className={styles.modalHeader}>
            <UserUp01 width={20} height={20} />
            <span className={styles.modalTitle}>Share report via email</span>
          </div>
        }
        footer={{
          footerContent: (
            <div className={styles.modalFooter}>
              <Info width={14} height={14} stroke={"var(--color-subdued)"} />
              <span className={styles.modalFooterText}>
                You can share this report up to 10 people
              </span>
            </div>
          ),
          footerActions: (
            <ButtonV2
              onClick={() => {
                validateForm(values);
                if (errors.recipientEmails) {
                  return;
                }

                setIsOpen(false);
              }}
            >
              Save Recipient
            </ButtonV2>
          ),
          hideBorder: true,
        }}
      >
        <div>
          <Label label="Add recipient emails"></Label>
          <CreatableSelect
            components={{
              DropdownIndicator: null,
            }}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder="Type something and press enter..."
            value={values.recipientEmails.map((v) => ({
              label: v,
              value: v,
            }))}
            onChange={(newValue) => {
              setFieldValue(
                "recipientEmails",
                newValue.map((v) => v.value)
              );
              setInputValue("");
            }}
          />
          {errors?.recipientEmails && (
            <FormikError formikError={errors} fieldName={"recipientEmails"} />
          )}
          {Array.isArray(errors?.recipientEmails) &&
            errors?.recipientEmails && (
              <FormikError
                formikError={errors}
                fieldName={"recipientEmails[0]"}
              />
            )}
        </div>
      </Modal>
    </>
  );
}
