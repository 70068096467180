import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import Fieldset from "src/components/Fieldset/Fieldset";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { Logo } from "src/components/Logo/Logo";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Text } from "src/components/Text/Text";
import AuthContext from "src/stores/AuthContext";
import * as Yup from "yup";
import loginIllustration from "../assets/login-illustration.png";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import onboardingGrid from "../assets/onboarding-grid.png";

const loginFormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Should be valid email")
    .required("Email is required"),
  password: Yup.string()
    .max(72, "Password must be at most 72 characters")
    .required("Password is required"),
});

export function Login() {
  const initialValues = {
    email: "",
    password: "",
  };
  const { login } = useContext(AuthContext);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   if (searchParams.get("shop")) {
  //     window.location.href = `${
  //       import.meta.env.VITE_SERVER_URI
  //     }/integrations/shopify/install/init?${searchParams.toString()}`;
  //   }
  // }, []);

  // FIXME: this causes page to reload when user tries to take any input. Occurs occasionally and is not yet replicated
  // if user is already logged in then don't allow user to come to login page.
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/", {
  //       replace: true,
  //     });
  //   }
  // }, [isAuthenticated]);

  async function handleSubmit(
    values: typeof initialValues,
    { setSubmitting, setFieldError }: FormikHelpers<typeof initialValues>,
  ) {
    try {
      await login({
        email: values.email,
        password: values.password,
      });
      // TODO: handle user errors
      const redirectUrl = localStorage.getItem("redirect_url");

      if (redirectUrl) {
        localStorage.removeItem("redirect_url");
      }

      window.location.replace(redirectUrl ?? "/");
    } catch (err) {
      if (err.code) {
        setFieldError(err.field.at(-1), err.message);
      }
    }
    setSubmitting(false);
  }

  const [searchParams] = useSearchParams();
  const existingError = searchParams.get("warn");

  useEffect(() => {
    if (existingError) {
      toast.error(existingError);
    }
  }, [existingError]);

  return (
    <div className="h-screen grid grid-rows-[auto_1fr_auto] py-20">
      <div className="p-4 flex items-center justify-center">
        <Logo type="full" style="black" size="sm" />
      </div>
      <div className="flex items-center justify-center">
        <div className="flex w-full max-w-[1100px] h-[590px]">
          <div className="w-full p-12 md:w-1/2 md:p-[82px] flex flex-col justify-center border rounded-16 md:border-r-0 md:rounded-r-[0] space-y-24">
            <Logo type="icon" style="black" embrossed={true} />
            <SectionHeader
              title="Welcome Back to Macro"
              description="Enter your email and password to continue"
              size="large"
            />
            <Formik
              initialValues={initialValues}
              validationSchema={loginFormSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Fieldset>
                    <InputFormikV2
                      name="email"
                      label="Email"
                      placeholder="alan.turing@example.com"
                      type="email"
                      size="small"
                    />
                    <InputFormikV2
                      label="Password"
                      placeholder="••••••••••••"
                      type="password"
                      name="password"
                      size="small"
                    />
                    <div className="mt-6">
                      <ButtonV2
                        type="submit"
                        isFullWidth
                        loading={isSubmitting}
                        disabled={isSubmitting}
                      >
                        Login
                      </ButtonV2>
                    </div>
                  </Fieldset>
                  <div className="flex flex-col gap-6 items-center mt-20">
                    <Text as="span" size="md" tone="subdued">
                      Don't have an account yet?{" "}
                      <ButtonV2 type="button" variant="link" to="/auth/signup">
                        Sign Up
                      </ButtonV2>
                    </Text>
                    <Text as="span" size="md" tone="subdued">
                      Forgot Password?{" "}
                      <ButtonV2
                        type="button"
                        variant="link"
                        to="/password-recovery"
                      >
                        Reset
                      </ButtonV2>
                    </Text>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div
            style={{
              backgroundImage: `url('${onboardingGrid}')`,
            }}
            className="hidden md:flex w-1/2 bg-gray-100 justify-center items-center border rounded-r-16 bg-center bg-no-repeat bg-cover "
          >
            <img
              src={loginIllustration}
              alt="Login Image"
              className="w-4/5 animate-fade-up animate-once animate-duration-500 animate-ease-in-out"
            />
          </div>
        </div>
      </div>
      <div className="p-4 flex items-center justify-center gap-16">
        <Text as="span" size="md" tone="subdued">
          © 2024 Codeword Pvt. Ltd.
        </Text>
        <ButtonV2 variant="link">Support</ButtonV2>
        <ButtonV2 variant="link">Help Center</ButtonV2>
      </div>
    </div>
  );
}
