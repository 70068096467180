import { RadioGroup } from "@headlessui/react";
import {
  Announcement03,
  File05,
  Route,
  UserEdit,
  Users01,
  UserUp01,
} from "@untitled-ui/icons-react";
import classNames from "classnames";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ComboBoxV2 } from "src/components/ComboBoxV2/ComboBoxV2";
import { FormikError } from "src/components/FormikError/FormikError";
import { Icon } from "src/components/Icon/Icon";
import { IconWithWrapper } from "src/components/IconWithWrapper/IconWithWrapper";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { SelectableItemCard } from "src/components/SelectableItemCard/SelectableItemCard";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { Text } from "src/components/Text/Text";
import { UploadProfilePicture } from "src/components/UploadProfilePicture/UploadProfilePicture";
import { currencyOptions } from "src/currency";
import {
  AnalyticsCategory,
  useClientCreateMutation,
} from "src/graphql/generated/schema";
import { clientAnalyticsCategoryOptions } from "src/modules/global/misc/clientAnalyticsCategoryOptions";
import clientIndustryOptions from "src/modules/global/misc/clientIndustryOptions";
import { timeZoneOptions } from "src/modules/global/misc/timeZoneOptions";
import AuthContext from "src/stores/AuthContext";
import * as Yup from "yup";
import onboardingGrid from "../assets/onboarding-grid.png";
import onboarding4_1 from "../assets/onboarding4-1.png";
import onboarding4_2_1 from "../assets/onboarding4-2-1.png";
import onboarding4_2_2 from "../assets/onboarding4-2-2.png";
import onboarding4_3_1 from "../assets/onboarding4-3-1.png";
import onboarding4_3_2 from "../assets/onboarding4-3-2.png";
import onboarding4_4_1 from "../assets/onboarding4-4-1.png";
import onboarding4_4_2 from "../assets/onboarding4-4-2.png";
import onboarding4_5_1 from "../assets/onboarding4-5-1.png";
import { OnboardingLayout2 } from "../components/OnboardingLayout2";
import styles from "./OnboardingPage4.module.scss";

export function OnboardingPage4() {
  const [activeSectionIndex, setActiveSectionIndex] = useState(1);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [clientKPIs, setClientKPIs] =
    useState<AnalyticsCategory>("TRANSACTIONAL");

  const [createClient, { loading: creatingClient }] = useClientCreateMutation();
  const navigate = useNavigate();
  const { setActiveClient, user, logout } = useContext(AuthContext);

  const [formValues, setFormValues] = useState({
    name: "",
    companySize: "",
    timezone: "",
    currency: "",
    industry: "",
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    // companySize: Yup.string().required("Company size is required"),
    timezone: Yup.string().required("Timezone is required"),
    currency: Yup.string().required("Currency is required"),
    industry: Yup.string().required("Industry is required"),
  });

  const featureList = [
    { icon: Route, name: "Cross Channel Campaign Builder" },
    { icon: Announcement03, name: "Meta & Google Ads Channel" },
    { icon: UserUp01, name: "Audience Templates & Insights" },
    { icon: UserEdit, name: "Custom Audience Builder" },
    { icon: File05, name: "Detailed analytics & Reporting" },
  ];

  const sections = [
    {
      icon: Users01,
      heading: "Welcome to Macro",
      description:
        "Welcome aboard! Your account is all set up and we're ready to get you started. Macro helps businesses grow through data-driven insights, streamlined operations, and powerful analytics capabilities.",
      features: {
        heading: "What can Macro do for you?",
        list: featureList,
      },
      buttons: [
        {
          text: "Let's Get Started",
          onClick: () => setActiveSectionIndex(2),
          variant: "solid",
        },
      ],
    },
    {
      icon: Users01,
      heading: "Introducing Campaign Builder",
      description:
        "Macro's Campaign Builder is a powerful tool that allows you to create, manage, and optimize your ad campaigns across multiple channels.",
      features: {
        heading: "What can our AI do for you?",
        list: featureList,
      },
      buttons: [
        {
          text: "Continue",
          onClick: () => setActiveSectionIndex(3),
          variant: "solid",
          counter: "1/5",
        },
        {
          text: "Back",
          onClick: () => setActiveSectionIndex(1),
          variant: "outline",
        },
      ],
    },
    {
      icon: Users01,
      heading: "Highly customizable audience builder",
      description:
        "Build highly targeted audiences with our customizable audience builder. Segment your audience based on demographics, interests, behaviors and more to create personalized campaigns that resonate with your target market.",
      features: {
        heading: "What can our AI do for you?",
        list: featureList,
      },
      buttons: [
        {
          text: "Continue",
          onClick: () => setActiveSectionIndex(4),
          variant: "solid",
          counter: "2/5",
        },
        {
          text: "Back",
          onClick: () => setActiveSectionIndex(2),
          variant: "outline",
        },
      ],
    },
    {
      icon: Users01,
      heading: "Innovative New Media Library",
      description:
        "Our Media Library is a comprehensive collection of high-quality images, videos, and audio files that you can use to enhance your ad campaigns. With our AI-powered search and selection tools, you can quickly find the perfect assets for your campaigns and save time and effort.",
      features: {
        heading: "What can our AI do for you?",
        list: featureList,
      },
      buttons: [
        {
          text: "Continue",
          onClick: () => setActiveSectionIndex(5),
          variant: "solid",
          counter: "3/5",
        },
        {
          text: "Back",
          onClick: () => setActiveSectionIndex(3),
          variant: "outline",
        },
      ],
    },
    {
      heading: "Create your first client",
      description:
        "Let's set up your first client. Fill in the details below to get started.",
      content: () => (
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setFormValues(values);
            setActiveSectionIndex(6);
          }}
        >
          {({
            isSubmitting,
            setFieldValue,
            values,
            errors,
            touched,
            submitCount,
            handleSubmit,
          }) => (
            <Form
              className={styles.createClientWrapper}
              onSubmit={handleSubmit}
            >
              <div className={styles.profilePictureWrapper}>
                {/* <LineHeading content="Profile Picture" tone="subdued" /> */}
                <UploadProfilePicture
                  helpText="*.png, *.jpeg files up to 10MB at least 400px by 400px"
                  currentAsset={
                    selectedFile
                      ? {
                          contentType: selectedFile.type as any,
                          name: selectedFile.name,
                          uri: selectedImage,
                        }
                      : null
                  }
                  onUpdate={(file) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setSelectedImage(reader.result as string);
                      setSelectedFile(file);
                    };
                    reader.readAsDataURL(file);
                  }}
                  onRemove={() => {
                    setSelectedFile(null);
                    setSelectedImage(null);
                  }}
                />
              </div>

              <div className={styles.fillYourDetailsWrapper}>
                {/* <LineHeading content="Fill your details" tone="subdued" /> */}

                <div className={styles.formItems}>
                  <InputFormikV2
                    name="name"
                    label="Client Name"
                    placeholder="Acme Flowers"
                    type="text"
                    size="small"
                  />
                  <div>
                    <ComboBoxV2
                      placeholder="Select Time zone"
                      label="Time zone"
                      onChange={(value) => setFieldValue("timezone", value)}
                      name="timezone"
                      options={timeZoneOptions}
                      value={values.timezone}
                      displayValue={(option) =>
                        timeZoneOptions.find((o) => o.value === option)?.label
                      }
                      multiple={false}
                    />
                    <FormikError
                      fieldName="timezone"
                      formikError={errors}
                      touched={touched}
                      submitCount={submitCount}
                    />
                  </div>
                  <div className={styles.fieldRow}>
                    <div className={styles.fieldItem}>
                      <SelectV2
                        placeholder="Select Industry"
                        label="Industry"
                        onChange={(value) => setFieldValue("industry", value)}
                        name="industry"
                        options={clientIndustryOptions}
                        value={values.industry}
                      />
                      <FormikError
                        fieldName="industry"
                        formikError={errors}
                        touched={touched}
                        submitCount={submitCount}
                      />
                    </div>
                    <div className={styles.fieldItem}>
                      <ComboBoxV2
                        placeholder="Select Currency"
                        label="Currency"
                        displayValue={(o) =>
                          currencyOptions.find((option) => option.value === o)
                            ?.label
                        }
                        onChange={(value) => setFieldValue("currency", value)}
                        name="currency"
                        options={currencyOptions}
                        value={values.currency}
                        multiple={false}
                      />
                      <FormikError
                        fieldName="currency"
                        formikError={errors}
                        touched={touched}
                        submitCount={submitCount}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.infoButtons}>
                <ButtonV2
                  isFullWidth={false}
                  type="submit"
                  size="small"
                  variant="solid"
                  disabled={isSubmitting}
                >
                  <span>Continue</span>
                  <span className={styles.buttonCounter}>4/5</span>
                </ButtonV2>
                <ButtonV2
                  isFullWidth={false}
                  size="small"
                  variant="outline"
                  onClick={() => setActiveSectionIndex(4)}
                >
                  Back
                </ButtonV2>
              </div>
            </Form>
          )}
        </Formik>
      ),
    },
    {
      heading: "Select Your Performance Metrics",
      description:
        "Select the key performance indicators that will help accelerate your business success.",
      content: () => (
        <div className={styles.kpiPickerWrapper}>
          <RadioGroup
            as="div"
            className={styles.kpiPicker}
            value={clientKPIs}
            onChange={setClientKPIs}
          >
            {clientAnalyticsCategoryOptions.map((option) => (
              <SelectableItemCard
                selectorType="radio"
                iconPlacement="withTitle"
                key={option.value}
                label={option.title}
                description={option.subtitle}
                icon={option.icon}
                value={option.value}
              />
            ))}
          </RadioGroup>
        </div>
      ),
      buttons: [
        {
          text: "Complete Setup",
          loading: creatingClient,
          onClick: async () => {
            const payload = await createClient({
              variables: {
                input: {
                  name: formValues.name,
                  currencyCode: formValues.currency,
                  industry: formValues.industry,
                  timeZone: formValues.timezone,
                  analyticsCategory: clientKPIs,
                },
              },
            });

            const redirectUrl = localStorage.getItem("redirect_url");
            setActiveClient(payload.data.clientCreate, redirectUrl);

            if (redirectUrl) {
              localStorage.removeItem("redirect_url");
            }
          },
          variant: "solid",
        },
        {
          text: "Back",
          onClick: () => setActiveSectionIndex(5),
          variant: "outline",
        },
      ],
    },
  ];

  const renderSection = (section, index) => (
    <div
      key={index}
      className={classNames(styles.section, {
        [styles.active]: activeSectionIndex === index + 1,
        [styles.createClientSection]: index === 4,
      })}
    >
      <div className={styles.infoHeadingWrapper}>
        {section.icon && <IconWithWrapper icon={section.icon} size="lg" />}
        <h3 className={styles.infoHeading}>{section.heading}</h3>
      </div>
      <p className={styles.infoDescription}>{section.description}</p>
      {section.content && section.content()}

      {section.features && (
        <div className={styles.features}>
          <div className={styles.featureHeading}>
            What can Macro do for you?
          </div>

          <div className={styles.featureList}>
            {featureList.map((feature) => (
              <div className="flex items-center gap-x-6">
                <Icon icon={feature.icon} size="md" iconColor="inherit" />
                <Text size="sm" as="span" weight="medium">
                  {feature.name}
                </Text>
              </div>
            ))}
          </div>
        </div>
      )}

      {section.buttons?.length > 0 && (
        <div className={styles.infoButtons}>
          {section.buttons.map((button, buttonIndex) => (
            <ButtonV2
              key={buttonIndex}
              isFullWidth={false}
              type={button.type}
              size="small"
              variant={button.variant}
              onClick={button.onClick}
            >
              {button.counter ? (
                <>
                  <span>{button.text}</span>
                  <span className={styles.buttonCounter}>{button.counter}</span>
                </>
              ) : (
                button.text
              )}
            </ButtonV2>
          ))}
        </div>
      )}
    </div>
  );

  const renderAsset = (index) => {
    const assets = [
      <img key={1} src={onboarding4_1} alt="onboarding illustration" />,
      <div
        key={2}
        className={classNames(styles.assetSection, {
          [styles.assetActive]: activeSectionIndex === 2,
        })}
      >
        <img src={onboarding4_2_1} alt="onboarding illustration" width="70%" />
        <img src={onboarding4_2_2} alt="onboarding illustration" width="70%" />
      </div>,
      <div
        key={3}
        className={classNames(styles.assetSection, {
          [styles.assetActive]: activeSectionIndex === 3,
        })}
      >
        <img src={onboarding4_3_1} alt="onboarding illustration" width="70%" />
        <img src={onboarding4_3_2} alt="onboarding illustration" width="70%" />
      </div>,
      <div
        key={4}
        className={classNames(styles.assetSection, {
          [styles.assetActive]: activeSectionIndex === 4,
        })}
      >
        <img src={onboarding4_4_1} alt="onboarding illustration" width="70%" />
        <img src={onboarding4_4_2} alt="onboarding illustration" width="70%" />
      </div>,
      <div
        key={5}
        className={classNames(styles.assetSection, {
          [styles.assetActive]: activeSectionIndex === 5,
        })}
      >
        <img src={onboarding4_5_1} alt="onboarding illustration" width="70%" />
      </div>,
    ];

    return assets[index - 1] || null;
  };

  return (
    <OnboardingLayout2>
      <div className="w-full p-12 md:w-1/2 md:px-[82px] md:py-[32px] flex flex-col justify-center border rounded-16 md:border-r-0 md:rounded-r-[0] space-y-24">
        <div className={styles.infoContent}>{sections.map(renderSection)}</div>
      </div>
      <div
        style={{ backgroundImage: `url('${onboardingGrid}')` }}
        className="hidden w-1/2 bg-gray-100 gap-14 p-56 md:flex flex-col justify-center border rounded-r-16 bg-center bg-no-repeat bg-cover"
      >
        {renderAsset(activeSectionIndex)}
      </div>
    </OnboardingLayout2>
  );
}
