import format from "date-fns/format";
import {
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import { AudienceAudienceSetIndexesQuery } from "src/graphql/generated/schema";
import { AudienceCategoryBadges } from "src/modules/global/components/AudienceCategoryBadges";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { useGrowAggregatedStats } from "src/modules/global/functions/useGrowAggregatedStats";
import { getAudienceIconList } from "src/modules/global/functions/getAudienceIconList";
import { getAudienceAudienceSetSourcePlatform } from "src/modules/audience/misc/getAudienceAudienceSetSourcePlatforms";
import { generateRangeString } from "src/lib/generateRangeString";

export function AudienceOverviewTable({
  audiences,
}: {
  audiences: AudienceAudienceSetIndexesQuery["audienceAudienceSetIndexes"]["edges"][0]["node"][];
}) {
  return (
    <>
      {audiences.map((audience, i) => {
        const metaElement = audience.Audience
          ? audience.Audience
          : audience.MetaAudienceSet;
        return (
          <TableRow id={audience.id}>
            <TablePrimaryCell
              icon={getAudienceIconList({
                audiencePlatforms:
                  getAudienceAudienceSetSourcePlatform(audience),
                categories:
                  metaElement.__typename === "Audience"
                    ? []
                    : audience.MetaAudienceSet?.AudienceCategories,
              })}
              title={audience.name}
              subtitle={format(new Date(audience.createdAt), "dd MMM yyyy")}
            />
            <TableCell width={328}>
              <AudienceCategoryBadges
                categories={
                  metaElement.__typename === "Audience"
                    ? []
                    : audience.MetaAudienceSet?.AudienceCategories
                }
              />
            </TableCell>

            <TableCell width={128}>
              {generateRangeString(
                metaElement?.MetaInsights?.lowerBoundCount,
                metaElement?.MetaInsights?.upperBoundCount,
              )}
            </TableCell>

            <AudienceAudienceSetStats
              id={metaElement.id}
              typename={metaElement.__typename}
              dateFrom={undefined}
              dateTo={undefined}
            />
          </TableRow>
        );
      })}
    </>
  );
}

function AudienceAudienceSetStats({
  id,
  typename,
  dateFrom,
  dateTo,
}: {
  id: string;
  typename: "Audience" | "MetaAudienceSet";
  dateFrom: Date | null;
  dateTo: Date | null;
}) {
  const { data } = useGrowAggregatedStats({
    others: {
      ...(typename === "Audience" && {
        audienceId: id,
      }),
      ...(typename === "MetaAudienceSet" && {
        audienceSetId: id,
      }),
      insightType: "ADSET_INSIGHT",
    },
    dateTo,
    dateFrom,
  });

  return (
    <>
      <TableCell width={96}>
        {formatNumberByType(data?.roas, "MULTIPLIER", {
          showDecimal: true,
        })}
      </TableCell>
      <TableCell width={96}>
        {formatNumberByType(data?.clicks, "NUMBER", {
          showDecimal: true,
        })}
      </TableCell>
    </>
  );
}
