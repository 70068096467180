import { ActivityInputV2, AdInputV2 } from "../misc/createCampaignTypes";
import styles from "./AdCarouselFields.module.scss";
import { SelectedAsset } from "./SelectedAsset";
import { useFormikContext } from "formik";
import { PageWelcomeMessageSelector } from "./PageWelcomeMessageSelector";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";
import { AdCallToActionLabelField } from "./AdCallToActionLabelField";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import { InputV2 } from "src/components/InputV2/InputV2";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import Link01 from "@untitled-ui/icons-react/build/esm/Link01";
import { useState } from "react";

interface IAdCarouselFields {
  selectedAdIndex: number;
  selectedAd: AdInputV2;
}

export function AdCarouselFields({
  selectedAd,
  selectedAdIndex,
}: IAdCarouselFields) {
  const { values, setValues, setFieldValue } =
    useFormikContext<ActivityInputV2>();
  const [applyToAllCard, setApplyToAllCards] = useState(false);

  const shouldShowPageWelcomeMessage =
    values.metaPurpose === "MESSAGE_INSTAGRAM" ||
    values.metaPurpose === "MESSAGE_MESSENGER" ||
    values.metaPurpose === "MESSAGE_WHATSAPP";
  const shouldShowLink =
    values.metaPurpose === "SALES_STANDARD" ||
    values.metaPurpose === "PROMOTE_POST" ||
    values.metaPurpose === "WEBSITE_VIEWS";

  const shouldShowPhoneNumber = values.metaPurpose === "CALL";
  const showAdTitleAndDescription =
    selectedAd.LineItems[0].headline || selectedAd.LineItems[0].description;

  function applyLinkToAllCards(link) {
    const adToUpdate = values.Ads[selectedAdIndex];
    const updatedLineItems = adToUpdate.LineItems.map((lineItem) => ({
      ...lineItem,
      link: link,
    }));
    setFieldValue(`Ads[${selectedAdIndex}].LineItems`, updatedLineItems);
  }

  return (
    <div className={styles.carouselFields}>
      <div className={styles.ctaSection}>
        <AdCallToActionLabelField
          name={`Ads[${selectedAdIndex}].callToActionLabel`}
          value={selectedAd.callToActionLabel}
          adType={selectedAd.adType}
        />

        {/* {shouldShowLink && (
          <div className={styles.allLinkSection}>
            <label className={styles.allLinkLabel} htmlFor="allLinks">
              <span className={styles.allLinkLabelText}>Website URL</span>
              <Checkbox
                size="small"
                value={"applyToAllCards"}
                checked={true}
                onChange={(value) => {
                  setFieldValue("applyToAllCards", value);
                }}
                label="Apply to all cards"
              />
            </label>
            <InputV2
              size="small"
              value="allLinks"
              name="allLinks"
              onChange={(link) => {
                const newAds = updatedAdValuesIfLink(
                  link,
                  values.applyToAllCards
                );
                setValues({
                  ...values,
                  Ads: newAds,
                  allLinks: link,
                });
              }}
              placeholder="https://example.com/product/item"
            />
          </div>
        )} */}

        {shouldShowPhoneNumber && (
          <PhoneNumberInput
            disabled
            defaultPhoneCode="+91"
            label="Phone Number"
            value={selectedAd.phoneNumber}
            countryCodeValue={selectedAd.phoneCountryCode}
            countryCodeOnChange={(value) => {
              setFieldValue(`Ads[${selectedAdIndex}].phoneCountryCode`, value);
            }}
            onChange={(value) => {
              setFieldValue(`Ads[${selectedAdIndex}].phoneNumber`, value);
            }}
          />
        )}

        <MultilineInput
          disabled={true}
          showCharacterCount={true}
          maxCharacterCount={500}
          value={selectedAd.primaryText}
          onChange={(value) => {
            setFieldValue(`Ads[${selectedAdIndex}].primaryText`, value);
          }}
          helpText="This is the caption of the ad"
          label="Caption text"
          rows={3}
        />
      </div>

      <div className={styles.carouselList}>
        {selectedAd.LineItems.map((lineItem, cardIndex) => (
          <div className={styles.carouselItem}>
            {lineItem.PostAsset && (
              <SelectedAsset
                width={showAdTitleAndDescription ? 88 : 40}
                height={showAdTitleAndDescription ? 88 : 40}
                borderRadius={showAdTitleAndDescription ? 10 : 5}
                asset={lineItem.PostAsset}
              />
            )}
            <div className={styles.carouselItemContent}>
              {lineItem.headline && (
                <InputFormikV2
                  disabled
                  name={`Ads[${selectedAdIndex}].LineItems[${cardIndex}].headline`}
                  label=""
                  size="small"
                  placeholder="Write your ad title"
                />
              )}

              {lineItem.description && (
                <InputFormikV2
                  disabled
                  name={`Ads[${selectedAdIndex}].LineItems[${cardIndex}].description`}
                  label=""
                  size="small"
                  placeholder="Write your ad description"
                />
              )}

              {shouldShowLink && (
                <InputV2
                  onChange={(value) => {
                    if (applyToAllCard) {
                      applyLinkToAllCards(value);
                      return;
                    }
                    setFieldValue(
                      `Ads[${selectedAdIndex}].LineItems[${cardIndex}].link`,
                      value,
                    );
                  }}
                  value={lineItem.link}
                  name={`Ads[${selectedAdIndex}].LineItems[${cardIndex}].link`}
                  label=""
                  size="small"
                  icon={Link01}
                  placeholder="https://example.com/product/item"
                />
              )}
              {cardIndex === 0 && (
                <div className="flex justify-end">
                  <Checkbox
                    value=""
                    label="Apply to all cards"
                    checked={applyToAllCard}
                    onChange={(value) => {
                      if (applyToAllCard) {
                        setApplyToAllCards(false);
                        return;
                      }
                      setApplyToAllCards(true);
                      applyLinkToAllCards(lineItem.link);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {shouldShowPageWelcomeMessage && (
        <div>
          <PageWelcomeMessageSelector
            value={selectedAd.pageWelcomeMessageId}
            onChange={() => {
              /** field is disabled */
            }}
          />
        </div>
      )}
    </div>
  );
}
