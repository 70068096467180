import { getMonthFromNumber } from "src/modules/campaign/misc/getMonthFromNumber";

export function highPerformanceMonthFormatter(value: string) {
  if (!value) {
    return "";
  }

  const [month, year] = value.split("-");

  if (!month || !year) {
    return "";
  }

  const isMonthValid = !isNaN(Number(month));

  if (!isMonthValid) {
    return "";
  }

  const formattedMonth = getMonthFromNumber(parseInt(month));

  return `${formattedMonth} ${year}`;
}
