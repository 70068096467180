import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { Spinner } from "../../../components/Spinner/Spinner";
import {
  RoleEnum,
  SortByInput,
  useCanCreateNewClientQuery,
  useGetUserQuery,
  useOrganizationClientsQuery,
} from "../../../graphql/generated/schema";
import { getTinybirdDateFiltersFromDateFilter } from "../../campaign/misc/campaignSingleFilters";
import { useDebouncedVariable } from "../../global/hooks/useDebouncedVariable";
import { DateFilter } from "../../global/misc/dateFilterUtils";
import { ClientsTable } from "../components/ClientsTable";
import { CreateClientModal1 } from "../components/CreateClientModal1";
import { EditClientModal } from "../components/EditClientModal";
import styles from "./OrganizationManageClientsPage.module.scss";
import Header from "src/components/Header/Header";
import { Plus } from "@untitled-ui/icons-react";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Dialog } from "src/components/Dialog/Dialog";
import { CreateClientModal } from "../components/CreateClientModal";

export function OrganizationManageClientsPage() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const createClient = searchParams.get("createClient");
  const { data: canCreateNewClientData } = useCanCreateNewClientQuery();
  const [showCreateClientModal, setShowCreateClientModal] = useState(
    (createClient && createClient === "true") || false,
  );

  const [sortBy, setSortBy] = useState<SortByInput>({
    direction: "DESC",
    field: "CREATED_AT",
  });
  const { data: userData, loading: userLoading } = useGetUserQuery();

  const [filter, setFilter] = useState<{
    name?: string;
    createdAt: DateFilter;
  }>({
    createdAt: "ALL_TIME",
  });

  const { dateTo, dateFrom } = useMemo(() => {
    return getTinybirdDateFiltersFromDateFilter(filter.createdAt);
  }, [filter.createdAt]);
  const debouncedName = useDebouncedVariable(filter?.name, 300);
  const { data, loading } = useOrganizationClientsQuery({
    variables: {
      sortBy,
      filter: {
        ...(debouncedName && {
          name: debouncedName,
        }),
        ...(filter?.createdAt &&
          dateTo &&
          dateFrom && {
            createdAt: {
              lte: dateTo,
              gte: dateFrom,
            },
          }),
      },
    },
  });

  if (userLoading) {
    return <Spinner />;
  }

  const userRole: RoleEnum =
    userData && userData.me ? userData.me.role : "MEMBER";

  if (userRole === "MEMBER") {
    return (
      <div className={styles.emptyStateContainer}>
        <EmptyState title={"Members cannot access this page"} />
      </div>
    );
  }

  const canCreateNewClient =
    canCreateNewClientData?.canCreateNewClient?.response;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Header
          title="Clients"
          actions={
            canCreateNewClient ? (
              <CreateClientModal
                trigger={
                  <ButtonV2
                    size="small"
                    as={Dialog.Trigger}
                    onClick={() => {
                      setShowCreateClientModal(true);
                    }}
                    leftIcon={Plus}
                  >
                    Add Client
                  </ButtonV2>
                }
              />
            ) : null
          }
        />
      </div>

      <div className={styles.container}>
        <SectionHeader
          title="Clients"
          description="Manage your client information and settings"
        />

        <ClientsTable
          clients={data ? data.organizationClients : []}
          loading={loading}
        />
      </div>
    </div>
  );
}
