import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { SegmentSelector } from "./SegmentSelector";
import { Button } from "src/components/Button/Button";

export function SegmentSelectorWizardForm({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { activeStepIndex, data, markStepAsDone } = useContext(WizardContext);

  return (
    <Formik
      // validationSchema={audienceSelectorSchema}
      enableReinitialize
      initialValues={data as ActivityInputV2}
      onSubmit={(values) => {
        markStepAsDone(3, values);
      }}
    >
      {({ errors, submitCount, touched, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 1}
            isCompleted={activeStepIndex > 1}
          >
            <SegmentSelector
              sectionRef={sectionRef}
              errors={errors}
              submitCount={submitCount}
              touched={touched}
            />

            <div className="pt-48">
              <Button onClick={handleSubmit} size="micro">
                Save & Continue
              </Button>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
