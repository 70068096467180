import { ReactNode, useState } from "react";
import {
  IWizardContext,
  WizardContext,
  WizardResetAlert,
} from "./WizardContext";
import Alert from "src/components/Alert/Alert";

interface IWizard<T> {
  initialData: T;
  children: ReactNode;
  onStepComplete: (
    nextStep: number,
    data: T,
    helpers?: {
      markStepAsDone: IWizardContext["markStepAsDone"];
      activeStepIndex: IWizardContext["activeStepIndex"];
    },
  ) => Promise<void> | void;
  initialActiveStepIndex?: number;
  onResetField: (
    fieldKey: string,
    fieldValue: string,
    data: T,
    stepIndex: number,
  ) => Promise<T> | T;
}

export function Wizard<T>({
  onStepComplete,
  initialActiveStepIndex,
  initialData,
  onResetField,
  children,
}: IWizard<T>) {
  const [data, setData] = useState<T>(initialData);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(
    initialActiveStepIndex ?? 0,
  );
  const resetAlertDefaultValue = {
    isShowing: false,
    fieldKey: "",
    data: data,
    fieldValue: "",
    stepIndex: 0,
  };
  const [resetAlert, setResetAlert] = useState<WizardResetAlert<T>>(
    resetAlertDefaultValue,
  );

  async function markStepAsDone(newIndex: number, data: any) {
    setData(data);
    await onStepComplete(newIndex, data, {
      activeStepIndex,
      markStepAsDone,
    });
    setActiveStepIndex(newIndex);
  }

  async function purgeDataWhenFieldReset(
    fieldName: string,
    fieldValue: any,
    data: T,
    stepIndex: number,
  ) {
    try {
      setIsResetLoading(true);
      const newData = await onResetField(
        fieldName,
        fieldValue,
        data,
        stepIndex,
      );
      setData(newData);
      // setActiveStepIndex(stepIndex);
    } finally {
      setIsResetLoading(false);
    }
  }

  return (
    <WizardContext.Provider
      value={{
        data,
        activeStepIndex,
        markStepAsDone,
        resetAlert,
        setResetAlert,
      }}
    >
      {children}
      <Alert
        trigger={<></>}
        isOpen={resetAlert.isShowing}
        onClose={() => setResetAlert({ ...resetAlert, isShowing: false })}
        secondaryAction={{
          label: "Yes",
          loading: isResetLoading,

          async onClick() {
            await purgeDataWhenFieldReset(
              resetAlert.fieldKey,
              resetAlert.fieldValue,
              resetAlert.data,
              resetAlert.stepIndex,
            );
            setResetAlert(resetAlertDefaultValue);
          },
        }}
        primaryAction={{
          label: "No",
          onClick() {
            setResetAlert({ ...resetAlert, isShowing: false });
          },
        }}
        title="Doing this change will reset your progress"
        description="By doing this change you will reset all the changes done in steps after this. Do you still want to continue?"
      />
    </WizardContext.Provider>
  );
}
