import { gql } from "@apollo/client";

const CAMPAIGNS = gql`
  query campaigns($filters: CampaignsFilterInput, $sortBy: CampaignsSortBy) {
    campaigns(filters: $filters, sortBy: $sortBy) {
      edges {
        cursor
        node {
          id
          name
          status
          objective
          createdAt
          updatedAt
          platforms
          activitiesCount
          Activities {
            id
            name
            status
            createdAt
            platform
          }
          Outreaches {
            id
            name
            status
            createdAt
            outreachType
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemsPerPage
        totalItems
        startCursor
        endCursor
      }
    }
  }
`;

export default CAMPAIGNS;
