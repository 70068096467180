import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { WhatsappTemplateSelector } from "./WhatsappTemplateSelector";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { useNavigate } from "react-router-dom";

export function WhatsappTemplateSelectorWizardForm({
  sectionRef,
}: {
  sectionRef: React.RefObject<HTMLElement>;
}) {
  const { activeStepIndex, data, markStepAsDone } = useContext(WizardContext);
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={data as ActivityInputV2}
      // FIXME: add validation
      // validationSchema={campaignCreateSchema}
      onSubmit={async (values) => {
        await markStepAsDone(4, values);
      }}
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <Form>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 3}
            isCompleted={activeStepIndex > 3}
            isLastStep={true}
          >
            <WhatsappTemplateSelector
              sectionRef={sectionRef}
              values={values}
              setFieldValue={setFieldValue}
            />
            <div className={"pt-32"}>
              <ButtonV2 onClick={() => navigate("/campaigns")}>Cancel</ButtonV2>
              <ButtonV2
                type="submit"
                loading={isSubmitting}
                disabled={!values.Ads?.length}
                onClick={handleSubmit}
              >
                Publish Activity
              </ButtonV2>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
