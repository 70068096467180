import { ReportTypeEnum } from "../../../graphql/generated/schema";
import Announcement03 from "@untitled-ui/icons-react/build/esm/Announcement03";
import ShoppingBag02 from "@untitled-ui/icons-react/build/esm/ShoppingBag02";
import CursorClick01 from "@untitled-ui/icons-react/build/esm/CursorClick01";
import ChartBreakoutCircle from "@untitled-ui/icons-react/build/esm/ChartBreakoutCircle";
import User03 from "@untitled-ui/icons-react/build/esm/User03";
import BarChartSquare01 from "@untitled-ui/icons-react/build/esm/BarChartSquare01";
import File02 from "@untitled-ui/icons-react/build/esm/File02";
import Briefcase01 from "@untitled-ui/icons-react/build/esm/Briefcase01";
import ActivityHeart from "@untitled-ui/icons-react/build/esm/ActivityHeart";
import BarLineChart from "@untitled-ui/icons-react/build/esm/BarLineChart";

export const reportTypes: Array<{
  id: ReportTypeEnum;
  name: string;
  icon: any;
  description: string;
  significanceTags: string[];
  idealForPointers: string[];
}> = [
  {
    id: "CAMPAIGN_PERFORMANCE",
    name: "Campaign Performance",
    icon: Announcement03,
    description:
      "Focuses on overall campaign metrics such as impressions, clicks, conversion rates, and ROI. Ideal for users wanting a snapshot of how their marketing efforts are performing across channels.",
    significanceTags: [
      "Measures Success",
      "Budget Allocation",
      "Optimization",
      "Decision Making",
    ],
    idealForPointers: [
      "How is my campaign performing across different channels?",
      "What are the key metrics that I should be focusing on?",
      "How can I optimize my campaign to improve its performance?",
      "How can I allocate my budget effectively?",
      "How can I make data-driven decisions to improve my campaign?",
    ],
  },
  {
    id: "ACTIVITY_AND_ADS",
    name: "Activity And Ads Report",
    icon: ChartBreakoutCircle,
    description:
      "Provides insights into the performance of your ads and activities, including impressions, clicks, conversions, and cost per acquisition. Ideal for users looking to optimize their ad campaigns and improve their ROI.",
    significanceTags: ["Measures Success", "Optimization", "Decision Making"],
    idealForPointers: [
      "How is my ad campaign performing?",
      "What are the key metrics that I should be focusing on?",
      "How can I optimize my ad campaign to improve its performance?",
      "How can I make data-driven decisions to improve my ad campaign?",
    ],
  },
  {
    id: "AUDIENCE_SET_PERFORMANCE",
    name: "Audience Set Performance",
    icon: File02,
    description:
      "Focuses on the performance of individual ad sets, providing insights into the performance of each ad in the set. Ideal for users looking to optimize their ad campaigns and improve their ROI.",
    significanceTags: ["Measures Success", "Optimization", "Decision Making"],
    idealForPointers: [
      "How is my ad set performing?",
      "What are the key metrics that I should be focusing on?",
      "How can I optimize my ad set to improve its performance?",
      "How can I make data-driven decisions to improve my ad set?",
    ],
  },
  {
    id: "BUDGET_VS_PERFORMANCE_REVENUE",
    name: "Budget Vs Performance Revenue Report",
    icon: Briefcase01,
    description:
      "Provides insights into the performance of your ad campaigns and the budget allocation for each campaign. Ideal for users looking to optimize their ad campaigns and improve their ROI.",
    significanceTags: ["Measures Success", "Optimization", "Decision Making"],
    idealForPointers: [
      "What budget allocation should I focus on?",
      "Which campaigns are performing well on which budget?",
      "How to optimize budget to get maximum ROI?",
    ],
  },
  {
    id: "ENGAGEMENT",
    name: "Engagement Report",
    icon: BarChartSquare01,
    description:
      "Provides insights into the engagement of your audience, including likes, comments, shares, and clicks. Ideal for users looking to understand how their audience is interacting with their content and identify areas for improvement.",
    significanceTags: ["Measures Success", "Optimization", "Decision Making"],
    idealForPointers: [
      "How to optimize budget to get maximum ROI?",
      "Which Audience is performing well on which budget?",
      "Which campaign has which core audience?",
      "Audience engagement trends over time",
    ],
  },
  {
    id: "EXPENSE_VS_REVENUE",
    name: "Expense Vs Revenue Report",
    icon: BarLineChart,
    description:
      "Compares expenses with revenue to provide insights into financial performance, profitability, and areas for cost optimization. Ideal for strategic financial decision-making and budgeting.",
    idealForPointers: [
      "What are the key metrics that I should be focusing on?",
      "How can I optimize my expense and revenue to improve its performance?",
      "How can I make data-driven decisions to improve my expense and revenue?",
    ],
    significanceTags: ["Measures Success", "Optimization", "Decision Making"],
  },
  {
    id: "OVERALL_BUSINESS",
    name: "Overall Business Report",
    icon: ActivityHeart,
    description:
      "Provides insights into the overall business performance of your company.",
    idealForPointers: [
      "What are the key metrics that I should be focusing on?",
      "How can I optimize my business to improve its performance?",
      "How can I make data-driven decisions to improve my business?",
    ],
    significanceTags: ["Measures Success", "Optimization", "Decision Making"],
  },
  {
    id: "SALES_AND_REVENUE",
    name: "Sales And Revenue Report",
    icon: ShoppingBag02,
    description:
      "Provides insights into the sales and revenue performance of your company.",
    idealForPointers: [
      "What are the key metrics that I should be focusing on?",
      "How can I optimize my sales and revenue to improve its performance?",
      "How can I make data-driven decisions to improve my sales and revenue?",
    ],
    significanceTags: ["Measures Success", "Optimization", "Decision Making"],
  },
  {
    id: "TRAFFIC_AND_AWARENESS",
    name: "Traffic And Awareness Report",
    icon: CursorClick01,
    description:
      "Provides insights into the traffic and awareness performance of your company.",
    idealForPointers: [
      "What are the key metrics that I should be focusing on?",
      "How can I optimize my traffic and awareness to improve its performance?",
      "How can I make data-driven decisions to improve my traffic and awareness?",
    ],
    significanceTags: ["Measures Success", "Optimization", "Decision Making"],
  },
];
