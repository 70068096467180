import { useLayoutEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyState from "src/components/EmptyState/EmptyState";
import emptyStateIllustration from "src/modules/global/assets/empty-state-media.png";
import { Spinner } from "src/components/Spinner/Spinner";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";
import {
  SortByInput,
  useAssetsLazyQuery,
} from "../../../graphql/generated/schema";
import { PaginationInput } from "../../global/misc/PaginationInput";
import { CreativeFilters } from "../misc/creativeFilters";
import { MediaListingTable } from "./MediaListingTable";
import { NoFilteredResultsFound } from "./NoFilteredResultsFound";

interface IMediaListingLoader {
  filters: CreativeFilters;
  setFilters: (i: CreativeFilters) => void;
  sortBy: SortByInput;
  setSortBy: (i: SortByInput) => void;
}

export function MediaListingLoader({
  filters,
  // setFilters,
  // setSortBy,
  sortBy,
}: IMediaListingLoader) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [pagination, _setPagination] = useState<PaginationInput>({
    take: itemsPerPage,
    cursor: null,
  });
  const debouncedQuery = useDebouncedVariable(filters.query);
  const query = filters.query.trim();
  const createdAt = filters.createdAt;

  const [assetsFn, { data, loading, called, fetchMore }] = useAssetsLazyQuery({
    variables: {
      sortBy,
      filters: {
        take: pagination.take,
        ...(pagination.cursor && {
          cursor: pagination.cursor,
        }),
        name: query,
        ...(createdAt && {
          createdAt: {
            gte: createdAt.from,
            lte: createdAt.to,
          },
        }),
      },
    },
  });

  useLayoutEffect(() => {
    const createdAt = filters.createdAt;
    assetsFn({
      variables: {
        filters: {
          ...(createdAt && {
            createdAt: {
              gte: createdAt.from,
              lte: createdAt.to,
            },
          }),
          name: debouncedQuery,
          ...(pagination.cursor && {
            cursor: pagination.cursor,
          }),
          take: pagination.take,
        },
        sortBy,
      },
    });
  }, [filters.createdAt, sortBy, assetsFn, debouncedQuery]);

  const areFiltersApplied = filters.query !== "";

  if (
    !loading &&
    called &&
    !data?.assets?.edges?.length &&
    !areFiltersApplied
  ) {
    return (
      <EmptyState
        title={"Upload your first asset"}
        description={
          "Upload your first asset to get started. Assets are the building blocks of your ads. They can be images, videos, or text that you can reuse across multiple campaigns."
        }
        illustration={emptyStateIllustration}
        buttonText="Let's Get Started"
        buttonVariant="solid"
        to="/library/ad-library/create"
      />
    );
  }

  if (loading || !called) {
    return <Spinner />;
  }

  if (!data.assets.edges.length) {
    return <NoFilteredResultsFound />;
  }

  const assets = data?.assets?.edges || [];

  async function fetchMoreAssets() {
    const endCursor = data?.assets?.pageInfo?.endCursor;

    await fetchMore({
      variables: {
        filters: {
          cursor: endCursor,
        },
      },

      // concatenate old and new entries
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.assets?.edges ?? [];
        const oldEdges = previousResult?.assets?.edges ?? [];

        return {
          assets: {
            ...fetchMoreResult.assets,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  return (
    <div>
      <InfiniteScroll
        next={fetchMoreAssets}
        hasMore={data?.assets?.pageInfo?.hasNextPage}
        loader={<Spinner height={200} />}
        dataLength={data.assets?.edges?.length || 0}
      >
        <MediaListingTable
          mediaList={assets}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
        />
      </InfiniteScroll>
    </div>
  );
}
