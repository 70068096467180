import ReactSkeleton, {
  SkeletonProps as ReactSkeletonProps,
} from "react-loading-skeleton";

export type ISkeleton = ReactSkeletonProps;

export function Skeleton({ ...props }: ISkeleton) {
  return (
    <ReactSkeleton
      baseColor="var(--color-gray-50)"
      highlightColor="var(--color-gray-100)"
      {...props}
    />
  );
}
