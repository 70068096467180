/** CSS files defined below can override
 * styles from the one above it
 */
import "react-loading-skeleton/dist/skeleton.css";
import "keen-slider/keen-slider.min.css";
import "./styles/imports.css";
import "./styles/tailwind.css";
import "./styles/core.css";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";

if (import.meta.env.VITE_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://01d26d82169cb3a2e59c0cbaa7ce7834@o1152986.ingest.sentry.io/4506693247696896",
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
