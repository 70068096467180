import {
  Building01,
  ChevronLeft,
  UserCircle,
  X,
} from "@untitled-ui/icons-react";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconWithWrapper } from "src/components/IconWithWrapper/IconWithWrapper";
import { Text } from "src/components/Text/Text";
import styles from "./Sidebar.module.scss";
import {
  clientSettingsSidebarConfig,
  organizationSettingsSidebarConfig,
} from "./SidebarDefaultSteps";
import { useActiveClientGet } from "../functions/activeClient";

export function SidebarSettings() {
  const location = useLocation();
  const navigate = useNavigate();
  const activeClient = useActiveClientGet();

  function isActivePath(link: string) {
    // to check for / (root) links
    if (link === "/") {
      return location.pathname === "/";
    }
    const currentLinkWithSearch = location.pathname;

    return currentLinkWithSearch === link;
  }

  return (
    <aside className={styles.container}>
      <div className={styles.sidebarHeader}>
        <button
          className={styles.userPanelTrigger}
          type="button"
          onClick={() => navigate("/")}
        >
          <div className={styles.userPanelTriggerDetails}>
            <IconWithWrapper icon={X} size="md" />
            <Text as={"h1"} size="lg" font="redhat" weight="medium">
              Settings
            </Text>
          </div>
        </button>
      </div>

      <div className={classNames(styles.primaryList, "space-y-16")}>
        <div>
          <div className="flex items-center gap-4 mb-8">
            <Building01 height={14} width={14} color="rgb(109, 113, 117)" />
            <Text size="xs" as="span" weight="medium" tone="subdued">
              Organization
            </Text>
          </div>
          {organizationSettingsSidebarConfig.map((item, index) => {
            return (
              <div key={index}>
                <Link
                  to={item.link}
                  className={classNames(styles.listItem, {
                    [styles.activeListItem]: isActivePath(item.link),
                  })}
                  key={index}
                >
                  <span className={styles.label}>{item.label}</span>
                </Link>
              </div>
            );
          })}
        </div>
        <div>
          <div className="flex items-center gap-4 mb-8">
            <UserCircle height={14} width={14} color="rgb(109, 113, 117)" />
            <Text size="xs" as="span" weight="medium" tone="subdued">
              {activeClient?.name}
            </Text>
          </div>
          {clientSettingsSidebarConfig.map((item, index) => {
            return (
              <div key={index}>
                <Link
                  to={item.link}
                  className={classNames(styles.listItem, {
                    [styles.activeListItem]: isActivePath(item.link),
                  })}
                  key={index}
                >
                  <span className={styles.label}>{item.label}</span>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </aside>
  );
}
