import { SelectButton } from "src/components/SelectButton/SelectButton";
import {
  MetaAllPagesQuery,
  useMetaAllPagesQuery,
} from "src/graphql/generated/schema";

interface IMetaAllPageSelector {
  label: string;
  value: string;
  onChange: (i: MetaAllPagesQuery["metaAllPages"][0]) => void;
}

export function MetaAllPageSelector({
  value,
  onChange,
  label,
}: IMetaAllPageSelector) {
  const { data, loading, error } = useMetaAllPagesQuery();

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  const pages = data.metaAllPages;
  const options = data.metaAllPages.map((m) => ({
    label: m.name,
    value: m.id,
  }));

  return (
    <SelectButton
      size="small"
      showValueOnLabel
      label={label}
      value={value}
      onChange={(value) => {
        const page = pages.find((page) => page.id === value);
        onChange(page);
      }}
      options={options}
    />
  );
}
