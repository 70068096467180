import { UserCircle } from "@untitled-ui/icons-react";
import { Icon } from "src/components/Icon/Icon";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { Switch } from "src/modules/global/components/Switch";
import { TitleWithThumbnail } from "src/modules/global/components/TitleWithThumbnail";

export function GoogleClientAccountItemToggle({
  account,
  selectedClientAccounts,
  setSelectedClientAccounts,
  showDescription = true,
}: {
  showDescription?: boolean;
  account: any;
  selectedClientAccounts: {
    id: string;
    name: string;
    enabled: boolean;
  }[];
  setSelectedClientAccounts: (accounts: Record<string, any>[]) => void;
}) {
  return (
    <div className="">
      <div className={"flex items-center"}>
        <div className={"flex-grow"}>
          <TitleWithThumbnail
            thumbnailIcon={{
              icon: UserCircle,
              iconColor: "primary",
              size: "lg",
            }}
            name={account.name}
            descriptionItems={showDescription ? [`ID: ${account.id}`] : []}
          />
        </div>
        {account?.isDefault && (
          <div className={"flex items-center justify-center w-32"}>
            <BadgeV2 label={"Default"} color={"gray"} />
          </div>
        )}
        <div className={"flex items-center justify-center w-32"}>
          <BadgeV2
            label={snakeCaseToTitleCase(account?.status)}
            color={
              account.status === "CLOSED"
                ? "red"
                : account.status === "ENABLED"
                ? "green"
                : "gray"
            }
          />
        </div>
        <div className={"flex items-center justify-center"}>
          <Switch
            checked={Boolean(
              selectedClientAccounts?.find((v) => v.id === account.id),
            )}
            disabled={account.status !== "ENABLED"}
            onChange={() => {
              if (selectedClientAccounts?.find((v) => v.id === account.id)) {
                const formattedAccounts = selectedClientAccounts.filter(
                  (v) => v.id !== account.id,
                );
                setSelectedClientAccounts(formattedAccounts);
                return;
              }
              setSelectedClientAccounts([
                ...selectedClientAccounts,
                {
                  id: account.id,
                  name: account.name,
                  enabled: true,
                },
              ]);
            }}
          />
        </div>
      </div>
    </div>
  );
}
