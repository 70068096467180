import { Button } from "src/components/Button/Button";
import styles from "./NotificationAlertSettings.module.scss";
import NotificationWhatsapp from "../assets/notificationWhatsapp.svg";
import { useFormikContext } from "formik";
import { InputFormik } from "src/components/InputFormik/InputFormik";

interface INotificationAlertSetting {
  type: "WHATSAPP" | "EMAIL";
}

type AlertConfig = {
  buttonLabel: string;
  title: string;
  subtitle: string;
  valueName: string;
  placeholder: string;
  icon: string;
};

export function NotificationAlertSettings({ type }: INotificationAlertSetting) {
  const { isSubmitting } = useFormikContext();

  const alertConfigs: Record<typeof type, AlertConfig> = {
    WHATSAPP: {
      buttonLabel: "Save WhatsApp Number",
      title: "Add WhatsApp Number",
      subtitle:
        "Once you add your WhatsApp number, you'll get all the notification and \n updates about what's happening in your macro",
      valueName: "whatsappPhoneNumber",
      placeholder: "919876654332",
      icon: NotificationWhatsapp,
    },
    EMAIL: {
      title: "Add Email",
      subtitle:
        "Once you add your email, you'll get all the notification and \n updates about what's happening in your macro",
      valueName: "email",
      placeholder: "johndoe@example.com",
      icon: NotificationWhatsapp,
      buttonLabel: "Save Email",
    },
  };

  const alertConfig = alertConfigs[type];

  return (
    <div>
      <div className={styles.iconWrapper}>
        <img src={alertConfig.icon} alt={"whatsapp"} />
        <span className={styles.dot}></span>
      </div>
      <h2 className={styles.title}>{alertConfig.title}</h2>
      <p className={styles.description}>{alertConfig.subtitle}</p>
      <InputFormik
        name={alertConfig.valueName}
        placeholder={alertConfig.placeholder}
      />
      <Button
        loading={isSubmitting}
        type="submit"
        className={styles.smsWtBtn}
        size="small"
      >
        {alertConfig.buttonLabel}
      </Button>
      {/* {isOpted ? (
        <div className={styles.optOutWrapper}>
          <p className={styles.title}>Opt out WhatsApp Notification</p>
          <p className={styles.description}>
            Not want notification update on WhatsApp? No issues, you can opt out
            and we won't send the update until you opt again
          </p>
          <Button
            type="submit"
            style="plain"
            color="danger"
            className={styles.smsWtBtn}
          >
            Opt out Notification
          </Button>
        </div>
      ) : null} */}
    </div>
  );
}

export function NotifySettingIcon({ img }: { img: string }) {
  return (
    <div className={styles.iconWrapper}>
      <img src={img} alt="icon" />
      <span className={styles.dot}></span>
    </div>
  );
}
