import { Grid } from "src/components/Grid/Grid";
import { GrowthCategoryCard } from "../GrowthCategoryCard/GrowthCategoryCard";

interface Tab {
  label: string;
  value: string;
  description: string;
}

interface GrowthInsightCategoryTabsProps<T> {
  tabs: Tab[];
  selectedTab: T;
  setSelectedTab: (id: T) => void;
}

export function GrowthInsightCategoryTabs<T = string>({
  tabs,
  selectedTab,
  setSelectedTab,
}: GrowthInsightCategoryTabsProps<T>) {
  return (
    <Grid column={{ xs: 5 }} columnGap={{ xs: 4 }}>
      {tabs.map((tab, i) => (
        <GrowthCategoryCard
          key={i}
          title={tab.label}
          description={tab.description}
          onClick={() => setSelectedTab(tab.value as T)}
          isSelected={selectedTab === tab.value}
        />
      ))}
    </Grid>
  );
}
