import {
  MetaAdAccount,
  useMetaEnableAdAccountMutation,
} from "src/graphql/generated/schema";
import META_AD_ACCOUNTS from "src/graphql/metaAdAccounts";
import metaEnabledAdAccounts from "src/graphql/metaEnabledAdAccounts";
import { META_INTEGRATION_DEFAULT_SETTING } from "src/graphql/metaIntegrationDefaultSetting";

export function useMultipleAdAccountEnable() {
  const [metaEnableAdAccountFunc] = useMetaEnableAdAccountMutation();

  async function handleMultipleAdAccountEnable(adAccounts: MetaAdAccount[]) {
    const enabledAdAccounts: MetaAdAccount[] = [];
    for (const adAccount of adAccounts) {
      let enabledAdAccount: MetaAdAccount;
      const { data } = await metaEnableAdAccountFunc({
        variables: {
          metaAdAccountId: adAccount.id,
        },
        refetchQueries: [
          { query: META_AD_ACCOUNTS, variables: {} },
          { query: metaEnabledAdAccounts, variables: {} },
          { query: META_INTEGRATION_DEFAULT_SETTING, variables: {} },
        ],
        awaitRefetchQueries: true,
      });
      if (data?.metaAdAccountEnable?.userError) {
        const graphqlError = data?.metaAdAccountEnable?.userError?.message;
        throw new Error(graphqlError);
      }
      enabledAdAccount = data.metaAdAccountEnable.response;
      // toast("Ad Account Enabled");
      enabledAdAccounts.push(enabledAdAccount);
    }

    return enabledAdAccounts;
  }

  return { handleMultipleAdAccountEnable };
}
