import styles from "./PageWelcomeMessageFaqQuestionCard.module.scss";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import Trash03 from "@untitled-ui/icons-react/build/esm/Trash03";
import ChevronUp from "@untitled-ui/icons-react/build/esm/ChevronUp";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import { MultilineInputFormik } from "src/components/MultilineInputFormik/MultilineFormik";

export function PageWelcomeMessageFaqQuestionCard({
  index,
  handleDelete,
  handleMove,
}) {
  return (
    <div className={styles.faqCardWrapper}>
      <div className={styles.cardHeader}>
        <div>Question - {index + 1}</div>
        <div className={styles.buttonGroup}>
          <button type="button" onClick={() => handleMove(index - 1)}>
            <ChevronUp height={14} width={14} />
          </button>
          <button type="button" onClick={() => handleMove(index + 1)}>
            <ChevronDown height={14} width={14} />
          </button>
          <button type="button" onClick={handleDelete} disabled={index === 0}>
            <Trash03 height={14} width={14} />
          </button>
        </div>
      </div>

      <div className={styles.questionFieldsContainer}>
        <InputFormikV2
          label={"Your question"}
          name={`MessageOptions[${index}].text`}
          placeholder={"Eg: How may I help you?"}
          showCharacterCount={true}
          maxCharacterCount={80}
        />
        <MultilineInputFormik
          name={`MessageOptions[${index}].automatedReply`}
          helpText="Automated responses will only work on Instagram and not WhatsApp."
          label="Automated response (optional)"
          placeholder={"Enter your automated response here"}
          showCharacterCount={true}
          rows={2}
          maxCharacterCount={300}
        />
      </div>
    </div>
  );
}
