import { BadgeV2 } from "../../global/components/BadgeV2";
import styles from "./MetaAccountItem.module.scss";
import ProgressActivity from "@material-symbols/svg-600/rounded/progress_activity.svg?react";
import { MetaAdAccountStatus } from "src/graphql/generated/schema";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";
import { Switch as ToggleSwitch } from "src/modules/global/components/Switch";
import { InfoCircle } from "@untitled-ui/icons-react";

interface IMetaIntegrationConnectedAccount {
  name?: string;
  amountSpent?: number;
  imageUrl?: string;
  enabled?: boolean;
  status?: MetaAdAccountStatus;
  onToggle: () => void;
  loading?: boolean;
  onViewDetails: () => void;
  isAmountExceeded?: boolean;
}

export function MetaAccountItem({
  name,
  amountSpent,
  isAmountExceeded,
  imageUrl,
  enabled,
  status,
  onToggle,
  loading,
  onViewDetails: _onViewDetails,
}: IMetaIntegrationConnectedAccount) {
  // const showBadge = enabled || status === "DISABLED" || isAmountExceeded;
  const selectedCurrencyCode = useGetActiveCurrencySymbol();

  return (
    <div className={styles.card}>
      <div className={styles.accountInfo}>
        <img
          src={
            imageUrl || `https://api.dicebear.com/7.x/shapes/svg?seed=${name}`
          }
          alt="profilePicture"
          className={styles.profile}
        />
        <div>
          <span className={styles.name}>{name}</span>
          <span className={styles.accountId}>
            Ad Spend in last 28 days:{" "}
            {formatNumberByType(amountSpent, "CURRENCY", {
              showDecimal: true,
              selectedCurrencyCode,
            })}
          </span>
        </div>
      </div>
      <div className={styles.actions}>
        <BadgeV2
          label={
            status === "DISABLED"
              ? "Disabled"
              : isAmountExceeded
              ? "Exceeded"
              : "Active"
          }
          leftIcon={InfoCircle}
          // onClick={() => {}}
          size="small"
          color={
            status === "DISABLED" ? "red" : isAmountExceeded ? "yellow" : "gray"
          }
        />
        <div className={styles.switchWrapper}>
          {loading ? (
            <>
              <span>
                <ProgressActivity
                  height={16}
                  width={16}
                  className={styles.loadingIcon}
                />
              </span>
            </>
          ) : (
            <>
              <ToggleSwitch
                title="toggle"
                checked={enabled}
                disabled={isAmountExceeded || status === "DISABLED"}
                onChange={onToggle}
              />
            </>
          )}
        </div>
      </div>
      {/*
        <div className={styles.footer}>
        <div className={styles.switchWrapper}>
          {loading ? (
            <>
              <span className={styles.footerLoadingText}>Loading</span>
                <ProgressActivity
                  height={16}
                  width={16}
                  className={styles.loadingIcon}
                />
              </span>
            </>
          ) : (
            <>
              <span className={styles.footerText}>Enable</span>
              <Switch
                checked={enabled}
                disabled={loading}
                onChange={onToggle}
                className={classNames(styles.switchContainer, {
                  [styles.darkContainer]: enabled,
                  [styles.lightContainer]: !enabled,
                })}
              >
                <span className={styles.srOnly}>{name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(styles.circle, {
                    [styles.enabledCircle]: enabled,
                    [styles.disabledCircle]: !enabled,
                  })}
                />
              </Switch>
            </>
          )}
        </div>
        <Button
          onClick={onViewDetails}
          // eslint-disable-next-line react/style-prop-object
          style="plain"
          color="primary"
        >
          <div className={styles.viewDetailBtn}>
            <span className={styles.viewBtnText}>View Detail</span>
            <ArrowRight className={styles.arrowIco} />
          </div>
        </Button>
      </div>
     */}
      {/* <Button
        size="micro"
        color={enabled ? "subdued" : "primary"}
        style={enabled ? "outline" : "solid"}
        className={styles.btn}
        onClick={onClick}
        loading={loading}
        disabled={loading}
      >
        {enabled ? "Revoke Access" : "Enable Account"}
      </Button> */}
    </div>
  );
}
