import classNames from "classnames";
import styles from "./ScrollArea.module.scss";

interface IScrollArea {
  height?: number | string;
  maxHeight?: number | string;
  children: React.ReactNode;
  className?: string;
}

export function ScrollArea({
  height,
  maxHeight,
  children,
  className,
}: IScrollArea) {
  const style: any = {
    ...(height && {
      "--scroll-area-height":
        typeof height === "number" ? `${height}px` : height,
    }),
    ...(maxHeight && {
      "--scroll-area-max-height":
        typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight,
    }),
  };

  return (
    <div className={classNames(styles.container, className)} style={style}>
      {children}
    </div>
  );
}
