import React from "react";

export interface IWizardContext<T = any> {
  markStepAsDone: (index: number, data: T) => Promise<void> | void;
  data: T;
  activeStepIndex: number;
  resetAlert: WizardResetAlert<T>;
  setResetAlert: (i: WizardResetAlert<T>) => void;
}

export type WizardResetAlert<TData> = {
  isShowing: boolean;
  fieldKey: string;
  data: TData;
  stepIndex: number;
  fieldValue: any;
};

export const WizardContext = React.createContext<IWizardContext>(null);

WizardContext.displayName = "WizardContext";
