import { useState } from "react";
import audiencePresets from "../misc/audiencePresets";
import { IAudiencePreset } from "../misc/IAudiencePreset";
import { SearchInput } from "src/components/SearchInput/SearchInput";

interface IAudiencePresetSearchInput {
  onSelect: (audiencePreset: IAudiencePreset) => void;
}

export function AudiencePresetSearchInput({
  onSelect,
}: IAudiencePresetSearchInput) {
  const [searchQuery, setSearchQuery] = useState("");

  let searchedAudiencePresets = audiencePresets
    .filter(
      (a) =>
        a.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        a.name.toLowerCase().includes(searchQuery.toLowerCase()),
    )
    .map((i) => ({
      label: i.name,
      onClick: () => {
        onSelect(i);
      },
    }));

  return (
    <SearchInput
      value={searchQuery}
      onChange={(i) => setSearchQuery(i)}
      searchResults={searchedAudiencePresets}
      loading={false}
      placeholder="Search for audience presets"
    />
  );
}
