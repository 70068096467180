import { Formik, FormikHelpers, Form } from "formik";
import { useContext, useState } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { WizardContext } from "src/components/Wizard/WizardContext";
import styles from "./DefaultAdAccountSettings.module.scss";
import * as Yup from "yup";
import { GooglePlatformAccounts } from "src/graphql/generated/schema";
import { GoogleAdAccountDefaultSettingsSelectorModal } from "./GoogleAdAccountDefaultSettingsSelectorModal";

const validationSchema = Yup.object({
  managerId: Yup.string().required(),
  clientAccountId: Yup.string().required(),
});

export function DefaultAdAccountSettings({ loading }) {
  const { activeStepIndex, markStepAsDone, data } = useContext(WizardContext);
  const [selectedManagerId, setSelectedManagerId] = useState<string | null>(
    null,
  );
  const enabledAccounts = data.accounts;

  let initialValues = {
    managerId: "",
    clientAccountId: "",
  };

  if (data.accounts.length === 1) {
    const account = data.accounts[0];
    const clientAccounts = account?.clientAccounts || [];

    if (clientAccounts.length === 1) {
      initialValues = {
        managerId: account.id,
        clientAccountId: clientAccounts[0]?.id,
      };
    }
  }

  async function formSubmit(
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>,
  ) {
    markStepAsDone(activeStepIndex + 1, {
      ...data,
      defaultSettings: {
        ...values,
      },
    });
    return;
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={formSubmit}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
        <Form>
          <StepWizardStep
            isCompleted={false}
            isStepNotActive={activeStepIndex < 3 || loading}
            isLastStep
          >
            <div className={styles.container}>
              <LegacySectionHeading
                heading={"Set Account as default"}
                description={
                  "Select Google Ads account which are connected to your manager account"
                }
              />

              <div className={styles.adAccountList}>
                {enabledAccounts.map((v) => (
                  <GoogleEnabledAdAccountItem
                    account={v}
                    defaultSettings={values}
                    setSelectedManagerId={(id: string) => {
                      setFieldValue("managerId", id);
                      setSelectedManagerId(id);
                    }}
                  />
                ))}
              </div>
            </div>
            {values.managerId && (
              <GoogleAdAccountDefaultSettingsSelectorModal
                isOpen={Boolean(selectedManagerId)}
                onClose={() => setSelectedManagerId(null)}
                handleSubmit={() => setSelectedManagerId(null)}
                clientAccounts={
                  enabledAccounts.find((v) => v.id === selectedManagerId)
                    ?.clientAccounts || []
                }
              />
            )}
            <div className={styles.buttonWrapper}>
              <ButtonV2
                type="submit"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={!isValid}
              >
                Finish Setup
              </ButtonV2>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}

function GoogleEnabledAdAccountItem({
  account,
  defaultSettings,
  setSelectedManagerId,
}: {
  account: GooglePlatformAccounts & {
    clientAccounts: GooglePlatformAccounts[];
  };
  defaultSettings: {
    managerId: string;
    clientAccountId: string;
  };
  setSelectedManagerId: (id: string) => void;
}) {
  const isDefault = account.id === defaultSettings.managerId;
  return (
    <div className={styles.containerCard}>
      <div className={styles.adAccountInfo}>
        <img
          src={`https://api.dicebear.com/7.x/shapes/svg?seed=${account.name}`}
          alt={account.name}
          className={styles.profile}
        />
        <div className={styles.info}>
          <div className={styles.connectionStatus}>
            <span className={styles.name}>
              {snakeCaseToTitleCase(account.name)}
            </span>
          </div>
          {/* <span className={styles.description}>Status: {account.status}</span> */}
        </div>
      </div>
      <div className={styles.badgeContainer}>
        {isDefault && <BadgeV2 label={"Default"} color="gray" size="small" />}
      </div>
      <div className={styles.actionButton}>
        <ButtonV2
          size="small"
          variant="outline"
          onClick={() => setSelectedManagerId(account.id)}
        >
          {isDefault ? "Change defaults" : "Set As Default"}
        </ButtonV2>
      </div>
    </div>
  );
}
