import { useState } from "react";
import { useDebounceEffect } from "./useDebounceEffect";

export function useDebouncedVariable<T>(
  originalVariable: T,
  delay: number = 500
): T {
  const [debouncedVariable, setDebouncedVariable] = useState(originalVariable);

  useDebounceEffect(
    () => {
      setDebouncedVariable(originalVariable);
    },
    delay,
    [originalVariable]
  );

  return debouncedVariable;
}
