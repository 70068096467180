import classNames from "classnames";
import styles from "./ActivityMetaPlacementSelector.module.scss";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { CheckboxGroup } from "src/components/CheckboxGroup/CheckboxGroup";
import facebookPlacement from "../assets/facebookPlacement.png";
import instagramPlacement from "../assets/instagramPlacement.png";
import { FormikError } from "src/components/FormikError/FormikError";
import { SelectableItemCard } from "src/components/SelectableItemCard/SelectableItemCard";
import {
  facebookPlacements,
  instagramPlacements,
} from "../misc/placementOptions";
import { useState } from "react";
import { AdvancedPlacementModal } from "./AdvancedPlacementModal";

export function ActivityMetaPlacementSelector() {
  const { values, setFieldValue, touched, submitCount, errors } =
    useFormikContext<ActivityInputV2>();
  const [showPlacementModal, setShowPlacementModal] = useState(false);

  const adPlacementOptionsPlatforms =
    Array.from(
      new Set<"INSTAGRAM" | "FACEBOOK">(
        values?.adPlacementOptions?.map((v) => v.split("_")[0]) as any,
      ),
    ) || [];

  const handlePlacementChange = (selectedPlatforms: string[]) => {
    console.log({ selectedPlatforms });
    const placementOptions = {
      FACEBOOK: facebookPlacements,
      INSTAGRAM: instagramPlacements,
    };

    const selectedPlacements = selectedPlatforms.flatMap(
      (platform) =>
        placementOptions[platform as keyof typeof placementOptions] || [],
    );

    setFieldValue("adPlacementOptions", selectedPlacements);
  };

  return (
    <div
      className={classNames(styles.placement, {
        [styles.placementDisabled]: !values.adAccountId,
      })}
    >
      <h3 className={styles.placementHeading}>
        Choose your ad placement locations
      </h3>
      <h4 className={styles.placementSubheading}>
        Choose where you want your ads to appear across different platforms
      </h4>

      <CheckboxGroup
        value={adPlacementOptionsPlatforms}
        onChange={handlePlacementChange}
      >
        <div className={styles.placementList}>
          <SelectableItemCard
            value="FACEBOOK"
            label={"Facebook"}
            description="Reach a diverse audience through Facebook's extensive network with targeted ads and engaging content"
            imageSrc={facebookPlacement}
            action={<AdvancedPlacementModal platform={"FACEBOOK"} />}
          />

          <SelectableItemCard
            value="INSTAGRAM"
            label="Instagram"
            disabled={!values.metaInstagramPageId}
            description="Engage with a visually-focused audience through Instagram's immersive platform with photo and video ads"
            imageSrc={instagramPlacement}
            action={<AdvancedPlacementModal platform={"INSTAGRAM"} />}
          />
        </div>

        <FormikError
          fieldName="adPlacementOptions"
          submitCount={submitCount}
          formikError={errors}
          touched={touched}
        />
      </CheckboxGroup>
    </div>
  );
}
