import Lightbulb03 from "@untitled-ui/icons-react/build/esm/Lightbulb03";
import { SVGAttributes } from "react";
import { Switch } from "src/modules/global/components/Switch";
import styles from "./FormGroupHeaderWithSwitch.module.scss";

interface IFormGroupHeaderWithSwitch {
  title: string;
  description: string;
  icon: React.FC<SVGAttributes<any>>;
  hasSwitch: boolean;
  isRecommended?: boolean;
  switchChecked?: boolean;
  onSwitchChange?: (i: boolean) => void;
}

export function FormGroupHeaderWithSwitch({
  switchChecked,
  onSwitchChange,
  isRecommended,
  hasSwitch,
  title,
  description,
  icon: Icon,
}: IFormGroupHeaderWithSwitch) {
  return (
    <div className={styles.additionalFormOption}>
      <span className={styles.additionalFormOptionIcon}>
        <Icon width={16} height={16} />
      </span>
      <div className={styles.additionalFormOptionContent}>
        <span className={styles.additionalFormOptionTitle}>
          <div className={styles.title}>{title}</div>
          {isRecommended && (
            <span className={styles.recommended}>
              <Lightbulb03 width={12} height={12} />
              Recommended
            </span>
          )}
        </span>
        <span className={styles.additionalFormOptionDescription}>
          {description}
        </span>
      </div>
      {hasSwitch && (
        <div className={styles.additionalFormOptionSwitch}>
          <Switch checked={switchChecked} onChange={onSwitchChange} />
        </div>
      )}
    </div>
  );
}
