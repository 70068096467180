import { getIn, useFormikContext } from "formik";
import {
  MetaCatalogue,
  MetaInstagramAccount,
  MetaPage,
  MetaPixel,
} from "src/graphql/generated/schema";
import { LineHeading } from "src/modules/global/components/LineHeading";
import Meta from "../../../icons/facebook.svg?react";
import Instagram from "../../../icons/instagram.svg?react";
import snakeCaseToTitleCase from "../../../lib/snakeCaseToTitleCase";
import { setArrayObjectsFormik } from "../functions/setArrayObjectsFormik";
import { MetaIntegrationFormValues } from "../misc/MetaIntegrationFormTypes";
import styles from "./MetaAdAccountSingleCard.module.scss";
import { MetaCataloguesList } from "./MetaCataloguesList";
import { MetaInstagramAccountsList } from "./MetaInstagramAccountsList";
import { MetaPagesList } from "./MetaPagesList";
import { MetaPixelsList } from "./MetaPixelsList";

export function MetaAdAccountSingleCardFormik({ metaAdAccount }) {
  const { values, setFieldValue } =
    useFormikContext<MetaIntegrationFormValues>();
  const adAccountIndex = values.adAccounts.findIndex(
    (v) => v.metaAccountId === metaAdAccount.metaAccountId
  );
  const instagramAccountKey = `adAccounts[${adAccountIndex}].MetaInstagramAccounts`;
  const metaPagesKey = `adAccounts[${adAccountIndex}].MetaPages`;
  const metaCataloguesKey = `adAccounts[${adAccountIndex}].MetaCatalogues`;
  const metaPixelsKey = `adAccounts[${adAccountIndex}].MetaPixels`;

  const selectedInstagramAccounts: MetaInstagramAccount[] =
    getIn(values, instagramAccountKey) || [];
  const selectedMetaPages = getIn(values, metaPagesKey) || [];
  const selectedMetaCatalogues = getIn(values, metaCataloguesKey) || [];
  const selectedMetaPixels = getIn(values, metaPixelsKey) || [];

  const setSelectedInstagramAccounts = (
    instagramAccount: Pick<
      MetaInstagramAccount,
      "id" | "metaInstagramId" | "clientId" | "isDefault" | "enabled"
    >
  ) => {
    setArrayObjectsFormik({
      values,
      setFieldValue,
      item: instagramAccount,
      itemKey: instagramAccountKey,
      identifierKey: "metaInstagramId",
    });
  };
  const setSelectedMetaPages = (
    metaPage: Pick<
      MetaPage,
      "id" | "metaPageId" | "clientId" | "isDefault" | "enabled"
    >
  ) => {
    setArrayObjectsFormik({
      values,
      setFieldValue,
      item: metaPage,
      itemKey: metaPagesKey,
      identifierKey: "metaPageId",
    });
  };
  const setSelectedMetaCatalogues = (
    metaCatalogues: Pick<
      MetaCatalogue,
      "id" | "metaCatalogueId" | "clientId" | "isDefault" | "enabled"
    >
  ) => {
    setArrayObjectsFormik({
      values,
      setFieldValue,
      item: metaCatalogues,
      itemKey: metaCataloguesKey,
      identifierKey: "metaCatalogueId",
    });
  };
  const setSelectedMetaPixels = (
    metaPixels: Pick<
      MetaPixel,
      "id" | "metaPixelId" | "clientId" | "isDefault" | "enabled"
    >
  ) => {
    setArrayObjectsFormik({
      values,
      setFieldValue,
      item: metaPixels,
      itemKey: metaPixelsKey,
      identifierKey: "metaPixelId",
    });
  };

  const adAccountSettings = [
    {
      heading: "Instagram Account",
      icon: <Instagram height={14} width={14} />,
      component: (
        <MetaInstagramAccountsList
          selectedInstagramAccounts={selectedInstagramAccounts}
          setSelectedInstagramAccounts={setSelectedInstagramAccounts}
          selectedAccountId={metaAdAccount.metaAccountId}
        />
      ),
    },
    {
      heading: "Facebook Page (Required)",
      icon: <Meta height={14} width={14} />,
      component: (
        <MetaPagesList
          selectedAccountId={metaAdAccount.metaAccountId}
          selectedMetaPages={selectedMetaPages}
          setSelectedMetaPages={setSelectedMetaPages}
        />
      ),
    },
    {
      heading: "Product Catalog",
      icon: <Meta height={14} width={14} />,
      component: (
        <MetaCataloguesList
          selectedAccountId={metaAdAccount.metaAccountId}
          setSelectedMetaCatalogues={setSelectedMetaCatalogues}
          selectedMetaCatalogues={selectedMetaCatalogues}
        />
      ),
    },
    {
      heading: "Meta Pixel",
      icon: <Meta height={14} width={14} />,
      component: (
        <MetaPixelsList
          selectedAccountId={metaAdAccount.metaAccountId}
          setSelectedMetaPixels={setSelectedMetaPixels}
          selectedMetaPixels={selectedMetaPixels}
        />
      ),
    },
  ];
  return (
    <div className={styles.containerCard}>
      <div className={styles.adAccountInfo}>
        <img
          src={
            metaAdAccount?.Business?.picture ??
            `https://api.dicebear.com/7.x/shapes/svg?seed=${metaAdAccount.metaName}`
          }
          alt={metaAdAccount?.Business?.picture || metaAdAccount?.metaName}
          className={styles.profile}
        />
        <div className={styles.info}>
          <div className={styles.connectionStatus}>
            <span className={styles.name}>
              {snakeCaseToTitleCase(metaAdAccount.metaName)}
            </span>
            <span className={styles.status}>Connected</span>
          </div>
          <span className={styles.description}>Business Account</span>
        </div>
      </div>
      <div className={styles.adAccountSettings}>
        {adAccountSettings.map((v) => (
          <div className={styles.section}>
            <LineHeading
              content={v.heading}
              icon={v.icon}
              className={styles.sectionHeading}
            />
            <div className={styles.sectionListing}>{v.component}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
