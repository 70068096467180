import { Activity } from "src/graphql/generated/schema";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import InstagramIcon from "src/icons/instagram.svg?react";
import {
  Speedometer03,
  CoinsStacked03,
  Users02,
} from "@untitled-ui/icons-react";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { GrowAggregateType } from "../../global/misc/GrowAggregateType";
import { GrowPerTickResponse } from "../../global/misc/growPerTickResponse";
import { getMonthFromNumber } from "../misc/getMonthFromNumber";
import { CampaignActivityCardStatItem } from "./CampaignActivityCardStatItem";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";
import { OverviewGraphCard } from "src/modules/global/components/OverviewGraphCard";
import { Grid } from "src/components/Grid/Grid";

export function CampaignActivityCardAnalyticsStatsList({
  activity,
  activityAggregateStats,
}: {
  activity: Activity;
  activityAggregateStats: GrowAggregateType;
}) {
  const selectedCurrencyCode = useGetActiveCurrencySymbol();
  const stats = [
    {
      label: "Objective",
      value: snakeCaseToTitleCase(activity?.Campaign?.objective ?? ""),
      icon: InstagramIcon,
    },
    {
      label: "Ad Spend",
      value: formatNumberByType(activityAggregateStats?.spend, "CURRENCY", {
        showDecimal: true,
        selectedCurrencyCode,
      }),
      icon: Speedometer03,
    },
    {
      label: "Budget",
      value: formatNumberByType(
        activity?.MetaActivity?.dailyBudget,
        "CURRENCY",
      ),
      icon: CoinsStacked03,
    },
    {
      label: "Audience Count",
      value: activity?.MetaActivity?.MetaActivityAdsets?.length,
      icon: Users02,
    },
  ];

  return (
    <>
      {stats.map((v) => (
        <CampaignActivityCardStatItem {...v} />
      ))}
    </>
  );
}

export function CampaignActivityCardAnalyticsGraphList({
  activityAggregateStats,
  activityPerTickStats,
}: {
  activityAggregateStats: GrowAggregateType;
  activityPerTickStats: GrowPerTickResponse["data"];
}) {
  const selectedCurrencyCode = useGetActiveCurrencySymbol();

  const chartData =
    activityPerTickStats?.map((v) => ({
      time: "tickDate" in v ? v.tickDate : `${getMonthFromNumber(v.tickMonth)}`,
      cpp: v.costPerPurchase,
      reach: v.reach,
      orderCount: v.countPurchase,
    })) ?? [];

  return (
    <Grid
      column={{
        xs: 2,
      }}
      columnGap={{ xs: 16 }}
      isHeightFull={true}
    >
      <OverviewGraphCard
        subtitle="Revenue for selected time"
        title="Total Revenue"
        value={formatNumberByType(
          activityAggregateStats?.totalValuePurchase,
          "CURRENCY",
          {
            showDecimal: true,
            selectedCurrencyCode,
          },
        )}
        chartData={chartData}
        yAxis={{
          dataKey: "cpp",
          label: "CPP",
          hide: true,
        }}
      />
      <OverviewGraphCard
        title="Order Counts"
        subtitle="Order counts for selected time"
        value={formatNumberByType(
          activityAggregateStats?.countPurchase,
          "NUMBER",
        )}
        chartData={chartData}
        yAxis={{
          dataKey: "orderCount",
          label: "Order Count",
          hide: true,
        }}
      />
    </Grid>
  );
}
