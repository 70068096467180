import styles from "./ManageAdAccount.module.scss";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import {
  IWizardContext,
  WizardContext,
} from "src/components/Wizard/WizardContext";
import { useContext } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MetaIntegrationFormValues } from "../misc/MetaIntegrationFormTypes";
import { MetaAdAccountSingleCardFormik } from "./MetaAdAccountSingleCard";

const manageAdAccountSchema = Yup.object({
  adAccounts: Yup.array(
    Yup.object({
      MetaPages: Yup.array(
        Yup.object({
          id: Yup.string().required(),
          enabled: Yup.bool().oneOf([true], "Page must be enabled always"),
        }),
      )
        .min(1)
        .required(),
    }),
  ).min(1),
});

export function ManageAdAccount({ loading }) {
  const {
    activeStepIndex,
    data: initialValues,
    markStepAsDone,
  } = useContext<IWizardContext<MetaIntegrationFormValues>>(WizardContext);

  async function formSubmit(values: MetaIntegrationFormValues) {
    markStepAsDone(activeStepIndex + 1, values);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={manageAdAccountSchema}
      onSubmit={formSubmit}
    >
      {({ values, isSubmitting, isValid, handleSubmit }) => (
        <Form>
          <StepWizardStep
            isCompleted={activeStepIndex === 2}
            isStepNotActive={activeStepIndex < 2 || loading}
          >
            <div className={styles.container}>
              <LegacySectionHeading
                heading={"Manage Your Account"}
                description={
                  "Select ad account which are connected to your business account"
                }
              />
              <div className={styles.adAccountList}>
                {values.adAccounts
                  .filter((v) => v.enabled)
                  .map((v) => (
                    <MetaAdAccountSingleCardFormik metaAdAccount={v} />
                  ))}
              </div>

              <div>
                <ButtonV2
                  onClick={handleSubmit}
                  disabled={!isValid}
                  loading={isSubmitting || loading}
                >
                  Continue
                </ButtonV2>
              </div>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
