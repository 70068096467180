import { GrowAggregateType } from "../misc/GrowAggregateType";
import { StatFilterOptions } from "./StatFilterOptions";
import { useGrowAggregatedStats } from "./useGrowAggregatedStats";

export interface AdTemplateAggregateType extends GrowAggregateType {
  adTemplateId: string;
}

export interface AdTemplateAggregatedStats {
  data: AdTemplateAggregateType;
  loading: boolean;
  error: { message: string } | null;
}

/**
 * returns aggregated stats for campaign with comparison values
 */
export function useAdTemplateAggregatedStats(
  adTemplateId: string,
  { dateFrom, dateTo }: StatFilterOptions,
): AdTemplateAggregatedStats {
  const growAggregatedStats = useGrowAggregatedStats<AdTemplateAggregatedStats>(
    {
      others: {
        insightType: "AD_INSIGHT",
        adTemplateId,
      },
      dateFrom,
      dateTo,
    },
  );

  return growAggregatedStats;
}
