import { FormikError } from "src/components/FormikError/FormikError";
import Select from "src/components/Select/Select";
import { SelectLabel } from "./SelectLabel";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import {
  MetaPagesQuery,
  useMetaPagesLazyQuery,
} from "src/graphql/generated/schema";
import { IconButton } from "src/components/IconButton/IconButton";
import ArrowOutwardIcon from "@material-symbols/svg-600/rounded/arrow_outward.svg?react";
import FacebookSvg from "../../../icons/facebook.svg?react";
import SelectV2 from "src/components/SelectV2/SelectV2";

export function MetaPageSelector({
  selectedAdAccountId,
  disabled,
  selectedPageId,
  setSelectedPageId,
  formKey,
}: {
  disabled?: boolean;
  selectedAdAccountId: string;
  selectedPageId: string;
  setSelectedPageId: (i: string, arr: MetaPagesQuery["metaPages"]) => void;
  formKey: string;
}) {
  const { touched, submitCount, errors: formikError } = useFormikContext();
  const [getMetaPages, { data: metaPagesData, loading: metaPagesLoading }] =
    useMetaPagesLazyQuery();

  useEffect(() => {
    if (!selectedAdAccountId) return;
    getMetaPages({
      variables: {
        adAccountId: selectedAdAccountId,
      },
    });
  }, [selectedAdAccountId, getMetaPages]);

  return (
    <div>
      <SelectV2
        emptyState={{
          title: "Connect Page",
          description: "Connect facebook page from which you want to run ads",
          to: "/settings/integrations/meta",
          action: (
            <IconButton
              icon={<ArrowOutwardIcon height={20} width={20} />}
              to="/settings/integrations/meta"
              type="button"
              color="primary"
              style="plain"
              shape="square"
            />
          ),
        }}
        icon={FacebookSvg}
        label={
          <SelectLabel
            labelText={"Choose Facebook Page"}
            tooltipText={"Your Facebook Page represents your business in ads"}
          />
        }
        options={
          metaPagesData && metaPagesData.metaPages
            ? metaPagesData?.metaPages.map((v) => ({
                label: v.name,
                value: v.id,
              }))
            : []
        }
        disabled={metaPagesLoading || disabled}
        value={selectedPageId}
        onChange={function (value: any) {
          // setActivityValue("metaPageId", value);
          setSelectedPageId(value, metaPagesData.metaPages);
        }}
        placeholder={
          metaPagesLoading ? "Loading Pages" : "Select Facebook Page"
        }
      />
      <FormikError
        touched={touched}
        formikError={formikError}
        submitCount={submitCount}
        fieldName={formKey}
      />
    </div>
  );
}
