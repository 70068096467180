import {
  ActivityInputV2,
  MetaActivityPurpose,
} from "../misc/createCampaignTypes";
import {
  MetaAdTypeEnum,
  MetaCallToActionTextEnum,
} from "src/graphql/generated/schema";
import { useFormikContext } from "formik";
import { FormikError } from "src/components/FormikError/FormikError";
import styles from "./AdCallToActionLabelField.module.scss";
import SelectV2 from "src/components/SelectV2/SelectV2";

interface Props {
  value: string;
  name: string;
  adType: MetaAdTypeEnum;
}

type CTAOption = {
  label: string;
  value: MetaCallToActionTextEnum;
};

const conversionOptions: CTAOption[] = [
  {
    label: "Contact us",
    value: "CONTACT_US",
  },
  {
    label: "Shop now",
    value: "SHOP_NOW",
  },
  { label: "Learn More", value: "LEARN_MORE" },
];

const engagementOptions: CTAOption[] = [
  { label: "Apply Now", value: "APPLY_NOW" },
  { label: "Contact Us", value: "CONTACT_US" },
  { label: "Learn More", value: "LEARN_MORE" },
  { label: "Sign Up", value: "SIGN_UP" },
  { label: "Subscribe", value: "SUBSCRIBE" },
];

const engagementExistingPostOptions: CTAOption[] = [
  {
    label: "Send Message",
    value: "CONTACT_US",
  },
  {
    label: "Shop now",
    value: "SHOP_NOW",
  },
];

const promotedPostOptions: CTAOption[] = [
  {
    label: "Learn More",
    value: "LEARN_MORE",
  },
  {
    label: "Shop now",
    value: "SHOP_NOW",
  },
];

const leadOptions: CTAOption[] = [
  { label: "Apply Now", value: "APPLY_NOW" },
  { label: "Download", value: "DOWNLOAD" },
  { label: "Get Quote", value: "GET_QUOTE" },
  { label: "Learn More", value: "LEARN_MORE" },
  { label: "Sign Up", value: "SIGN_UP" },
  { label: "Subscribe", value: "SUBSCRIBE" },
];

const engagementMetaPurposes: readonly MetaActivityPurpose[] = [
  "CALL",
  "MESSAGE_INSTAGRAM",
  "MESSAGE_MESSENGER",
  "MESSAGE_WHATSAPP",
  "PROMOTE_POST",
  "VIDEO_THRUPLAY",
  "VIDEO_2_SEC_VIEW",
];

export function AdCallToActionLabelField({ value, name, adType }: Props) {
  const { values, setFieldValue, touched, errors, submitCount } =
    useFormikContext<ActivityInputV2>();
  const isPromotedPostActivity = values.metaPurpose === "PROMOTE_POST";
  const isEngagementActivity = engagementMetaPurposes.includes(
    values.metaPurpose
  );
  const isLeadActivity = values.metaPurpose === "LEADS";

  let options: CTAOption[];

  if (isLeadActivity) {
    options = leadOptions;
  } else if (isEngagementActivity) {
    if (isPromotedPostActivity) {
      options = promotedPostOptions;
    } else if (adType === "EXISTING_POST") {
      options = engagementExistingPostOptions;
    } else {
      options = engagementOptions;
    }
  } else {
    options = conversionOptions;
  }

  return (
    <div className={styles.field}>
      <SelectV2
        size="small"
        placeholder="Select CTA"
        value={value}
        label="Call to Action"
        onChange={(value: MetaCallToActionTextEnum) => {
          setFieldValue(name, value);
        }}
        options={options}
      />
      <FormikError
        fieldName={name}
        formikError={errors}
        submitCount={submitCount}
        touched={touched}
      />
    </div>
  );
}
