import { useContext } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { useGoogleAuthenticationUrlQuery } from "src/graphql/generated/schema";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";

export function ConnectWithGoogle() {
  const { activeStepIndex } = useContext(WizardContext);
  const { data, loading, error } = useGoogleAuthenticationUrlQuery();

  return (
    <StepWizardStep
      isCompleted={activeStepIndex !== 0}
      isStepNotActive={activeStepIndex !== 0}
    >
      <div className="flex flex-col gap-24 pb-56">
        <div>
          <LegacySectionHeading
            heading={"Get started with meta business account"}
            description={
              "Connect Meta Platform to publish facebook and Instagram, ads, add signup forms, and access your Instagram ads in Macro’s Campaign builder. Manage Facebook and Audiences and Accounts"
            }
          />
        </div>

        <div>
          <ButtonV2
            disabled={loading || !data}
            to={data && data.googleAuthenticationUrl}
            target="blank"
            size="small"
            variant="solid"
          >
            Integrate Google
          </ButtonV2>
        </div>
      </div>
    </StepWizardStep>
  );
}
