import { CustomersQuery } from "src/graphql/generated/schema";
import styles from "./CustomerListItem.module.scss";
import {
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import format from "date-fns/format";
import { DotsVertical } from "@untitled-ui/icons-react";
import { DropdownV2 } from "src/modules/campaign/components/DropdownV2";
import WhatsappIcon from "../../../icons/whatsapp.svg?react";
import EmailIcon from "../../../icons/email.svg?react";
import { MessageTextCircle02 } from "@untitled-ui/icons-react";
import { CustomerSourceBadge } from "./CustomerSourceBadge";

export function CustomerListItem({
  customer,
}: {
  customer: CustomersQuery["customers"]["edges"][0]["node"];
}) {
  const link = `/contacts/${customer.id}`;
  const formattedDate = format(new Date(customer.createdAt), "MMM dd, yyyy");

  return (
    <TableRow id={customer.id} link={link}>
      <TablePrimaryCell
        avatarSeed={customer.name}
        title={customer.name}
        subtitle={formattedDate}
      />

      <TableCell width={150}>
        <CustomerSourceBadge customer={customer} />
      </TableCell>

      <TableCell width={250}>
        <span className={styles.tableText}>{customer.email} </span>
      </TableCell>

      <TableCell width={150}>
        <span className={styles.tableText}>{customer.phone}</span>
      </TableCell>

      <TableCell width={120}>
        <span className={styles.subscribedTo}>
          <WhatsappIcon
            color={
              customer.WhatsappConsent.state === "SUBSCRIBED"
                ? "var(--color-neutral-500)"
                : "var(--color-neutral-200)"
            }
            width={18}
            height={18}
          />

          <EmailIcon
            color={
              customer.EmailConsent.state === "SUBSCRIBED"
                ? "var(--color-neutral-500)"
                : "var(--color-neutral-200)"
            }
            width={18}
            height={18}
          />

          <MessageTextCircle02
            color={
              customer.TextMessageConsent.state === "SUBSCRIBED"
                ? "var(--color-neutral-500)"
                : "var(--color-neutral-200)"
            }
            width={18}
            height={18}
          />
        </span>
      </TableCell>
      <TableCell>
        <DropdownV2
          buttonVariant="plain"
          icon={DotsVertical}
          hideArrow
          size="small"
          options={[
            {
              label: "Edit",
              onClick: () => {},
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
}
