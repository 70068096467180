import styles from "./Avatar.module.scss";

interface IAvatar {
  size?: number;
  borderRadius?: number | "full";
  avatarSeed: string;
  imageSrc?: string;
}

export function Avatar({
  size = 24,
  borderRadius = "full",
  imageSrc,
  avatarSeed,
}: IAvatar) {
  const seedCharacter = avatarSeed?.toUpperCase() || "-";
  const seedCharacterCode = seedCharacter.charCodeAt(0);
  const seedColorHue = (seedCharacterCode * 24) % 360;

  return (
    <div
      className={styles.avatar}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: borderRadius === "full" ? "50%" : `${borderRadius}px`,
        backgroundColor: `hsl(${seedColorHue}, 70%, 90%)`,
        color: `hsl(${seedColorHue}, 70%, 25%)`,
      }}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={avatarSeed}
          width={size}
          height={size}
          className={styles.avatarImage}
        />
      ) : (
        seedCharacter[0]
      )}
    </div>
  );
}
