import { Mail03, UsersPlus } from "@untitled-ui/icons-react";
import styles from "./OrganizationMembersTableHeader.module.scss";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";

type TabValues = "MEMBERS" | "INVITES";

interface IOrganizationMembersTableHeader {
  selectedTab: TabValues;
  setSelectedTab: (i: TabValues) => void;
}

export function OrganizationMembersTableHeader({
  selectedTab,
  setSelectedTab,
}: IOrganizationMembersTableHeader) {
  return (
    <div className={styles.tableHeader}>
      <SectionHeader
        title="Manage Members"
        description="Manage your teams roles permissions and settings to ensure effective collaboration and security."
      />

      <TabsV2<TabValues>
        tabs={[
          {
            icon: UsersPlus,
            value: "MEMBERS" as const,
            label: "Members",
          },
          {
            icon: Mail03,
            value: "INVITES" as const,
            label: "Invites",
          },
        ]}
        selectedTab={selectedTab}
        onTabChange={(i) => {
          setSelectedTab(i);
        }}
      />
    </div>
  );
}
