import styles from "./NotificationPanel.module.scss";
import Close from "@material-symbols/svg-600/rounded/close.svg?react";
import { IconButton } from "src/components/IconButton/IconButton";
import Settings from "@material-symbols/svg-600/rounded/manufacturing-fill.svg?react";
import ArrowBack from "@material-symbols/svg-600/rounded/arrow_back.svg?react";
import NotificationMsgDetails from "./NotificationMsgDetails";
import { useContext, useEffect, useRef, useState } from "react";
import NotificationSettingsContent from "./NotificationSettingsContent";
import classNames from "classnames";
import { NotificationListLoader } from "./NotificationListLoader";
import { INotification } from "../misc/notification";
import AuthContext from "src/stores/AuthContext";
import { NotificationSettingsForm } from "./NotificationSettingsForm";
import { SideDrawer } from "./SideDrawer";
import { Text } from "src/components/Text/Text";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Bell02, ChevronLeft, Settings03 } from "@untitled-ui/icons-react";
import { useNotificationsCountQuery } from "src/graphql/generated/schema";
import { BadgeV2 } from "./BadgeV2";

export default function NotificationPanel() {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { data, loading } = useNotificationsCountQuery({
    pollInterval: 12 * 1000,
    variables: {
      filter: { readStatus: "UNREAD" },
    },
  });

  return (
    <SideDrawer
      isOpen={isNotificationOpen}
      onIsOpenChange={setIsNotificationOpen}
      trigger={
        <ButtonV2
          size="small"
          leftIcon={Bell02}
          onClick={() => setIsNotificationOpen(true)}
          variant="outline"
        />
      }
      width={"520px"}
    >
      <div>
        <div className="flex justify-between items-center p-20">
          <h3 className={"flex gap-8 items-center"}>
            <ButtonV2
              onClick={() => setIsNotificationOpen(false)}
              leftIcon={ChevronLeft}
              size="small"
              variant="plain"
            />
            <Text size="xl" weight="medium">
              Notifications
            </Text>
            <BadgeV2 label={data?.notificationsCount ?? 0} color="gray" />
          </h3>
          <ButtonV2
            type="button"
            to="/settings/notifications"
            leftIcon={Settings03}
            size="small"
            variant="outline"
          />
        </div>
        <div
          id="scrollTargetForInfiniteScroll"
          className={styles.contentWrapper}
        >
          <NotificationListLoader />
        </div>
      </div>
    </SideDrawer>
  );
}
