import {
  Camera03,
  Coins03,
  CreditCardRefresh,
  CursorClick02,
  MessageCircle01,
  Wallet02,
  Globe04,
} from "@untitled-ui/icons-react";
import MetaIcon from "src/icons/meta.svg?react";
import { AnalyticsCategory } from "src/graphql/generated/schema";
import { GrowAggregateType } from "./GrowAggregateType";

type statType = keyof GrowAggregateType;

export const analyticsCategoryMapping: Record<AnalyticsCategory, statType[]> = {
  TRANSACTIONAL: ["spend", "totalValuePurchase", "costPerPurchase", "roas"],
  LEADS: [
    "spend",
    "cpc",
    "costPerMessagingConversationStarted7d",
    "costPerLead",
  ],
  AWARENESS: ["spend", "cpm", "costPerVideoView", "cpc"],
  ENGAGEMENT: ["spend", "costPerPostEngagement", "costPerVideoView", "cpc"],
  TRAFFIC: ["spend", "cpc", "costPerLandingPageView", "cpm"],
};

const analyticsWidgetProps: Array<{
  title: string;
  valueKey: keyof GrowAggregateType;
  valueType: "NUMBER" | "CURRENCY" | "PERCENTAGE" | "MULTIPLIER";
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}> = [
  {
    title: "Total Spends",
    valueKey: "spend",
    icon: Wallet02,
    valueType: "CURRENCY",
  },
  {
    title: "Total Purchase Value",
    valueKey: "totalValuePurchase",
    icon: Wallet02,
    valueType: "CURRENCY",
  },
  {
    title: "Cost per acquisition (CPA)",
    valueKey: "costPerPurchase",
    icon: Coins03,
    valueType: "CURRENCY",
  },
  {
    title: "Cost Per Click (CPC)",
    valueKey: "cpc",
    icon: Coins03,
    valueType: "CURRENCY",
  },
  {
    title: "Total Clicks",
    valueKey: "clicks",
    icon: CursorClick02,
    valueType: "NUMBER",
  },
  {
    title: "Cost Per Video View",
    icon: Camera03,
    valueKey: "costPerVideoView",
    valueType: "CURRENCY",
  },
  {
    title: "Cost Per Mile (CPM)",
    icon: Coins03,
    valueKey: "cpm",
    valueType: "CURRENCY",
  },
  {
    title: "Cost Per Lead (CPL)",
    icon: MetaIcon,
    valueKey: "costPerLead",
    valueType: "CURRENCY",
  },
  {
    title: "Cost Per Message/DM",
    valueKey: "costPerMessagingConversationStarted7d",
    icon: MessageCircle01,
    valueType: "CURRENCY",
  },
  {
    title: "Cost Per Post Engagement",
    valueKey: "costPerPostEngagement",
    icon: MetaIcon,
    valueType: "CURRENCY",
  },
  {
    title: "Cost Per Landing Page View",
    valueKey: "costPerLandingPageView",
    icon: Globe04,
    valueType: "CURRENCY",
  },
  {
    title: "Return on Ad Spent (ROAS)",
    valueKey: "roas",
    icon: CreditCardRefresh,
    valueType: "MULTIPLIER",
  },
];

export function getWidgetPropsBasedAnalyticsCategory(
  category: AnalyticsCategory,
) {
  const analytics = analyticsCategoryMapping[category];
  const widgetProps = analytics.map((v) =>
    analyticsWidgetProps.find((s) => s.valueKey === v),
  );

  return widgetProps;
}
