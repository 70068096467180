import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import styles from "./FileInputV2.module.scss";
import Image01 from "@untitled-ui/icons-react/build/esm/Image01";
import { SVGAttributes } from "react";

export interface IFileInputV2 {
  title: string;
  onClick: () => void;
  icon?: React.FC<SVGAttributes<any>>;
}

export function FileInputV2({ icon, title, onClick }: IFileInputV2) {
  const Icon = icon || Image01;

  return (
    <div className={styles.wrapper}>
      <Icon color="var(--color-disabled)" />
      <ButtonV2 variant="outline" onClick={onClick}>
        {title}
      </ButtonV2>
    </div>
  );
}
