import { MetaMedia } from "src/graphql/generated/schema";
import { LineItem } from "src/lib/types";

export function generateLineItemsFromExistingPost(
  metaMedia: Pick<MetaMedia, "url" | "caption" | "type"> | null,
  displayUrl: string
): LineItem[] {
  if (!metaMedia) {
    return [];
  }

  return [
    {
      description: metaMedia.caption,
      headline: metaMedia.caption,
      link: displayUrl,
      order: 0,
      PostAsset: {
        contentType: metaMedia.type === "IMAGE" ? "IMAGE" : "VIDEO",
        name: metaMedia.url,
        uri: metaMedia.url,
      },
      StoryAsset: {
        contentType: metaMedia.type === "IMAGE" ? "IMAGE" : "VIDEO",
        name: metaMedia.url,
        uri: metaMedia.url,
      },
    },
  ];
}
