import { MetaActivityInterest } from "src/graphql/generated/schema";

export function generateInterestInputFromAudienceSetInterest(
  interests: MetaActivityInterest[],
  interestValue: "EXCLUDE" | "INCLUDE"
) {
  return interests?.reduce(
    (acc, interest) =>
      (acc = {
        ...acc,
        [interest.id]: {
          value: interestValue,
          audienceSizeLowerBound: interest.audienceSizeLowerBound,
          audienceSizeUpperBound: interest.audienceSizeUpperBound,
          name: interest.name,
          id: interest.id,
          metaInterestId: interest.metaInterestId,
          description: "",
          path: interest.path,
          type: interest.type,
        },
      }),
    {}
  );
}
