import { useState } from "react";
import NotificationSettingsContent from "../../global/components/NotificationSettingsContent";
import { NotificationSettingsForm } from "../../global/components/NotificationSettingsForm";

export function ClientUserNotificationPage() {
  const [selectedSetting, setSelectedSetting] = useState(null);

  return (
    <div className="max-w-[820px] w-full mx-auto py-24">
      <NotificationSettingsForm>
        <NotificationSettingsContent
          selectedSetting={selectedSetting}
          setSelectedSetting={setSelectedSetting}
        />
      </NotificationSettingsForm>
    </div>
  );
}
