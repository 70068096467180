import classNames from "classnames";
import styles from "./ActivityMetaObjectiveSelector.module.scss";
import { useFormikContext } from "formik";
import { RadioGroup } from "@headlessui/react";
import { LineHeading } from "src/modules/global/components/LineHeading";
import LocalMall from "@material-symbols/svg-600/rounded/local_mall.svg?react";
import CaptivePortal from "@material-symbols/svg-600/rounded/captive_portal.svg?react";
import Flag from "@material-symbols/svg-600/rounded/flag.svg?react";
import { SelectableItemCard } from "src/components/SelectableItemCard/SelectableItemCard";
import { FormikError } from "src/components/FormikError/FormikError";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { activityMetaPurposeOptions } from "../misc/activityMetaPurposeOptions";

export function ActivityMetaObjectiveSelector({
  disabled = false,
}: {
  disabled?: boolean;
}) {
  const { values, setFieldValue, touched, submitCount, errors } =
    useFormikContext<ActivityInputV2>();

  const salesOptions = activityMetaPurposeOptions
    .filter((o) => o.category === "SALES")
    .map((v) => ({
      ...v,
      disabled: !v.requiredFields.every(
        (v) => Object.keys(values).includes(v) && values[v],
      ),
    }));
  const trafficOptions = activityMetaPurposeOptions
    .filter((o) => o.category === "TRAFFIC")
    .map((v) => ({
      ...v,
      disabled: !v.requiredFields.every(
        (v) => Object.keys(values).includes(v) && values[v],
      ),
    }));
  const engagementOptions = activityMetaPurposeOptions
    .filter((o) => o.category === "ENGAGEMENT")
    .map((v) => ({
      ...v,
      disabled: !v.requiredFields.every(
        (v) => Object.keys(values).includes(v) && values[v],
      ),
    }));
  return (
    <div
      className={classNames(styles.objectives, {
        [styles.objectivesDisabled]: !values.adAccountId || disabled,
      })}
    >
      <RadioGroup
        value={values.metaPurpose}
        onChange={(value) => {
          setFieldValue("metaPurpose", value);
        }}
      >
        <div className={styles.objectiveGroup}>
          <LineHeading
            icon={
              <LocalMall fill="var(--color-disabled)" width={15} height={15} />
            }
            content="Sales and Conversions"
          />
          <div className={styles.optionsList}>
            {salesOptions.map((option) => (
              <SelectableItemCard
                selectorType="radio"
                disabled={option.disabled}
                icon={option.icon}
                label={option.label}
                value={option.value}
              />
            ))}
          </div>
        </div>

        <div className={styles.objectiveGroup}>
          <LineHeading
            icon={
              <CaptivePortal
                fill="var(--color-disabled)"
                width={15}
                height={15}
              />
            }
            content="Traffic & Awareness"
          />
          <div className={styles.optionsList}>
            {trafficOptions.map((option) => (
              <SelectableItemCard
                selectorType="radio"
                disabled={option.disabled}
                icon={option.icon}
                label={option.label}
                value={option.value}
              />
            ))}
          </div>
        </div>

        <div className={styles.objectiveGroup}>
          <LineHeading
            icon={<Flag fill="var(--color-disabled)" width={15} height={15} />}
            content="Engagement"
          />
          <div className={styles.optionsList}>
            {engagementOptions.map((option) => (
              <SelectableItemCard
                selectorType="radio"
                disabled={option.disabled}
                icon={option.icon}
                label={option.label}
                value={option.value}
              />
            ))}
          </div>
        </div>

        <FormikError
          fieldName="metaPurpose"
          submitCount={submitCount}
          formikError={errors}
          touched={touched}
        />
      </RadioGroup>
    </div>
  );
}
