import { Text } from "src/components/Text/Text";
import Meta from "../../../global/assets/meta.svg?react";
import Whatsapp from "../../../global/assets/whatsapp.svg?react";
import Email from "../../../global/assets/email.svg?react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { UnlockFeaturesModal } from "src/components/UnlockFeaturesModal/UnlockFeaturesModal";

export function OnboardingIntegrationStep({ isMetaIntegrated }) {
  const [isWhatsappUpgradeModalOpen, setIsWhatsappUpgradeModalOpen] =
    useState(false);
  const [isEmailUpgradeModalOpen, setIsEmailUpgradeModalOpen] = useState(false);

  const platforms = [
    {
      id: "meta",
      isChecked: isMetaIntegrated ? true : false,
      title: "Meta",
      icon: Meta,
    },
    {
      id: "whatsapp",
      isChecked: false,
      title: "Whatsapp",
      icon: Whatsapp,
      onClick: () => {
        setIsWhatsappUpgradeModalOpen(true);
      },
    },
    {
      id: "email",
      isChecked: false,
      title: "Email",
      icon: Email,
      onClick: () => {
        setIsEmailUpgradeModalOpen(true);
      },
    },
  ];

  return (
    <div className="flex flex-col gap-12">
      <Text size="sm" tone="subdued">
        Select a platform to begin integration. After completing the integration
        for your chosen platform, you can either integrate additional platforms
        or proceed to the next step
      </Text>
      <div className="flex gap-12 w-full">
        {platforms.map((platform) => (
          <BasicCard
            icon={platform.icon}
            isChecked={platform.isChecked}
            title={platform.title}
            onClick={platform.onClick}
            to={`/settings/integrations/${platform.id}`}
          />
        ))}
      </div>
      <UnlockFeaturesModal
        type="UNLOCK_ENGAGE"
        title="Unlock WhatsApp Messages With Engage"
        description="Access to WhatsApp Business automatic messages will be available to all Engage subscribers."
        isOpen={isWhatsappUpgradeModalOpen}
        onOpenChange={setIsWhatsappUpgradeModalOpen}
      />
      <UnlockFeaturesModal
        type="UNLOCK_ENGAGE"
        title="Unlock Email With Engage"
        description="Access to Email automatic messages will be available to all Engage subscribers."
        isOpen={isEmailUpgradeModalOpen}
        onOpenChange={setIsEmailUpgradeModalOpen}
      />
    </div>
  );
}

function BasicCard({ icon, title, isChecked, to, onClick }) {
  const CardIcon = icon;
  return (
    <Link
      to={to}
      className="border rounded-12 h-[72px] w-24 flex items-center justify-center flex-col gap-12"
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
    >
      <CardIcon width="20" height="20" />
      <div className="flex">
        <Text
          size="sm"
          weight="medium"
          tone={isChecked ? "success" : "default"}
        >
          {title}
        </Text>
      </div>
    </Link>
  );
}
