import { Formik } from "formik";
import { Form, useSearchParams } from "react-router-dom";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ErrorHandler } from "src/components/ErrorHandler";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { MultilineInputFormik } from "src/components/MultilineInputFormik/MultilineFormik";
import SelectV2 from "src/components/SelectV2/SelectV2";
import {
  BillingPlanCycleEnum,
  CustomerCountRange,
  MonthlyAdSpendRange,
  useCountriesQuery,
  useCreateBillingMutation,
} from "src/graphql/generated/schema";
import GET_BILLING from "src/modules/billing/api/getBilling";
import { indianStates } from "src/modules/global/misc/indianStatesList";
import * as Yup from "yup";
import onboarding3Image from "../assets/onboarding3-asset.png";
import styles from "./OnboardingPage3.module.scss";
import { Logo } from "src/components/Logo/Logo";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import onboardingGrid from "../assets/onboarding-grid.png";
import Fieldset from "src/components/Fieldset/Fieldset";
import Tooltip from "src/components/Tooltip/Tooltip";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { InfoCircle } from "@untitled-ui/icons-react";
import { DetailTooltip } from "src/modules/global/components/DetailTooltip";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { Text } from "src/components/Text/Text";
import { CSSProperties, useContext } from "react";
import classNames from "classnames";
import OnboardingPlanSelected from "../assets/onboarding-plan-selected.svg?react";
import { IconWithWrapper } from "src/components/IconWithWrapper/IconWithWrapper";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { titleCase } from "src/lib/titleCase";
import { useDeviceType } from "src/modules/global/hooks/useDeviceType";
import { ComboBoxV2 } from "src/components/ComboBoxV2/ComboBoxV2";
import AuthContext from "src/stores/AuthContext";

const billingFormInitialValues = {
  billingName: "",
  address: "",
  country: "",
  state: "",
  taxId: "",
};

const billingFormSchema = Yup.object().shape({
  billingName: Yup.string().required("Billing Name is required"),
  address: Yup.string().required("Address is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().when("country", {
    is: "IN",
    then: Yup.string().required("State is required"),
    otherwise: Yup.string().notRequired(),
  }),
  taxId: Yup.string().when("country", {
    is: "IN",
    then: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Invalid GST Number format",
      )
      .notRequired(),
    otherwise: Yup.string().notRequired(),
  }),
});

export function OnboardingPage3() {
  const { user, logout } = useContext(AuthContext);
  const [billingCreate, { loading }] = useCreateBillingMutation({
    refetchQueries: [{ query: GET_BILLING }],
  });
  // Get plan information from URL
  const [searchParams] = useSearchParams();
  const planType = searchParams.get("plantype").toUpperCase();
  const billingCycle = searchParams.get("billingcycle").toUpperCase();
  const customerCount = searchParams.get("customercount").toUpperCase();
  const adSpend = searchParams.get("adspend").toUpperCase();
  const isEngage = searchParams.get("isengage");
  const isGrow = searchParams.get("isgrow");
  const price = searchParams.get("price");

  async function handleStripeBillingCreate(
    values: typeof billingFormInitialValues,
  ) {
    const payload = await billingCreate({
      variables: {
        input: {
          paymentProvider: "STRIPE",
          cycle: billingCycle as BillingPlanCycleEnum,
          ...(isEngage === "true" && {
            customerCountRange: customerCount as CustomerCountRange,
          }),
          ...(isGrow === "true" && {
            monthlyAdSpendRange: adSpend as MonthlyAdSpendRange,
          }),
          [isEngage === "true" ? "engagePlanType" : "growPlanType"]: planType,
          // Billing details
          billingName: values.billingName,
          billingCountryAlpha2: values.country,
          billingAddress: values.address,
          billingState: values.state,
          ...(values.taxId && {
            billingTaxId: values.taxId,
          }),
        },
      },
    });

    window.location.href = payload.data.billingCreate.paymentIntent;
  }

  const {
    data: countriesData,
    loading: countriesLoading,
    error: countriesError,
  } = useCountriesQuery();

  if (countriesError) {
    return <ErrorHandler error={countriesError} />;
  }

  if (countriesLoading) {
    return;
  }

  const formattedCountries = countriesData?.countries.map((country) => ({
    label: country.name,
    value: country.alpha2,
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={billingFormInitialValues}
      validationSchema={billingFormSchema}
      onSubmit={handleStripeBillingCreate}
    >
      {({
        values,
        isSubmitting,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <div className="h-screen grid grid-rows-[auto_1fr_auto] py-20">
            <div className="p-4 flex items-center justify-center">
              <Logo type="full" style="black" size="sm" />
            </div>
            <div className="flex items-center justify-center">
              <div className="flex w-full max-w-[1100px] h-[590px]">
                <div
                  className="w-full p-12 md:w-1/2 md:px-[82px] md:py-[52px] flex flex-col justify-center border rounded-16 md:border-r-0 md:rounded-r-[0] space-y-24"
                  // className="w-1/2 p-10 justify-center border border-r-0 rounded-l-16 px-[82px] py-[52px] space-y-20"
                >
                  <div className="flex justify-between items-center">
                    <SectionHeader
                      title="Billing Information"
                      description="Enter your billing details to continue"
                      size="large"
                    />
                    <PlanDetailTooltip
                      plan={{
                        name: titleCase(planType),
                        description:
                          planType === "PREMIUM"
                            ? "For medium and large businesses"
                            : "For individual and small business",
                        amount: price,
                        interval: billingCycle.toLowerCase(),
                      }}
                    />
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <Fieldset>
                      <InputFormikV2
                        type="text"
                        label="Billing Name"
                        placeholder="Acme Inc."
                        name="billingName"
                        size="small"
                      />
                      <MultilineInputFormik
                        label="Billing Address"
                        placeholder="1234 Main St."
                        name="address"
                        rows={2}
                      />
                      <Fieldset horizontal>
                        <ComboBoxV2
                          placeholder={"Select Country"}
                          options={formattedCountries}
                          value={values.country}
                          displayValue={(item) =>
                            formattedCountries.find((c) => c.value === item)
                              ?.label
                          }
                          onChange={(value) => setFieldValue("country", value)}
                          label="Country"
                          name="country"
                          size="small"
                          error={
                            !!errors.country &&
                            touched.country &&
                            errors.country
                          }
                          multiple={false}
                        />
                        {values.country === "IN" && (
                          <SelectV2
                            placeholder="Select State"
                            options={indianStates.map((state) => ({
                              label: state.name,
                              value: state.code,
                            }))}
                            value={values.state}
                            onChange={(value) => setFieldValue("state", value)}
                            label="State"
                            name="state"
                            size="small"
                            error={
                              !!errors.state && touched.state && errors.state
                            }
                          />
                        )}
                      </Fieldset>
                      {values.country === "IN" && (
                        <InputFormikV2
                          name="taxId"
                          label="GST"
                          placeholder="22AAXXXXXXXXXXX"
                          size="small"
                          type="text"
                        />
                      )}
                    </Fieldset>

                    {/* <div className={styles.selectedPlanDetails}>
                      <div className={styles.selectedPlanDetails}>
                        <div className={styles.planCard}>
                          <div className={styles.planHeader}>
                            <div className={styles.planType}>
                              <span>Standard</span>
                            </div>
                            <ButtonV2 variant="link" size="small">
                              Edit
                            </ButtonV2>
                          </div>
                          <div className={styles.planDetails}>
                            <div className={styles.detailRow}>
                              <span>14 - day free trial</span>
                              <span>-</span>
                            </div>
                            <div className={styles.detailRow}>
                              <span>Total Amount</span>
                              <span>$59/mo (billed annually)</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="py-24">
                      <ButtonV2
                        type="submit"
                        isFullWidth={true}
                        loading={isSubmitting}
                      >
                        Continue
                      </ButtonV2>
                    </div>
                  </Form>
                </div>
                <div
                  style={{ backgroundImage: `url('${onboardingGrid}')` }}
                  className="hidden w-1/2 bg-gray-100 gap-14 p-56 md:flex flex-col justify-center border rounded-r-16 bg-center bg-no-repeat bg-cover"
                >
                  <img
                    src={onboarding3Image}
                    alt="Onboarding Image"
                    className={styles.imageAsset}
                  />
                </div>
              </div>
            </div>
            <div className="p-4 flex items-center justify-center gap-16">
              <Text as="span" size="md" tone="subdued">
                © 2024 Codeword Pvt. Ltd.
              </Text>
              <ButtonV2 variant="link">Support</ButtonV2>
              <ButtonV2
                variant="link"
                {...(user ? { onClick: logout } : { to: "/login" })}
              >
                {user ? "Logout" : "Login"}
              </ButtonV2>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

function PlanDetailTooltip({ plan }) {
  const deviceType = useDeviceType();
  return (
    <RadixTooltip.Provider delayDuration={100}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger
          type="button"
          // className={classNames({
          //   [styles.flexTrigger]: !isInline,
          // })}
        >
          <BadgeV2
            label={"Selected Plan"}
            rightIcon={InfoCircle}
            color="blue"
          />
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            style={{ "--tooltip-max-width": "300px" } as CSSProperties}
            className={classNames(
              "bg-white rounded-8  p-12 relative",
              styles.tooltip,
            )}
            align={deviceType === "MOBILE" ? "end" : "center"}
            side={deviceType === "MOBILE" ? "bottom" : "right"}
            sideOffset={4}
          >
            <div className="absolute top-[12px] right-[12px]">
              <BadgeV2 label={"14 days trial"} color="blue" />
            </div>
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-12">
                <IconWithWrapper
                  icon={OnboardingPlanSelected}
                  iconColor="info"
                  size="lg"
                />
                <div className="flex flex-col">
                  <Text font="euclid" size="md">
                    {plan.name}
                  </Text>
                  <Text font="euclid" size="xs" tone="subdued">
                    {plan.description}
                  </Text>
                </div>
              </div>
              <div className="pt-12 border-t">
                <div className="flex justify-between items-center">
                  <Text tone="subdued" size="sm">
                    Total Amount
                  </Text>
                  <Text tone="gray-900" size="sm" weight="medium">
                    {plan.amount}/month
                  </Text>
                </div>
                <div className="flex justify-end mt-2">
                  <Text tone="subdued" size="3xs">
                    billed {plan.interval}ly
                  </Text>
                </div>
              </div>
            </div>
            <RadixTooltip.Arrow
              height={6}
              width={12}
              radius={2}
              className={classNames(styles.tooltipArrow, {
                [styles.tooltipArrowMobile]: deviceType === "MOBILE",
              })}
            />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
