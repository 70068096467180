import { Switch as HeadlessSwitch } from "@headlessui/react";
import styles from "./Switch.module.scss";
import classNames from "classnames";

export interface ISwitch {
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  title?: string;
  size?: "medium" | "large";
}

export function Switch({ checked, disabled, onChange, size }: ISwitch) {
  return (
    <HeadlessSwitch
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      className={classNames(styles.switch, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
        [styles.sizeLarge]: size === "large",
      })}
    >
      <span
        aria-hidden="true"
        className={classNames(styles.circle, {
          [styles.enabledCircle]: checked,
          [styles.disabledCircle]: !checked,
        })}
      />
    </HeadlessSwitch>
  );
}
