import { AudienceSetQuery } from "src/graphql/generated/schema";
import { titleCase } from "src/lib/titleCase";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import * as Accordion from "@radix-ui/react-accordion";
import { FileSearch02, Globe04, ThumbsUp } from "@untitled-ui/icons-react";
import { getInterestIcon } from "../functions/getInterestIcon";
import { getAudienceIconList } from "src/modules/global/functions/getAudienceIconList";
import { AudienceAttributeRow } from "./AudienceAttributeRow";
import { AccordionItem } from "src/modules/global/components/AccordionItem";
import { AudienceDemographicsSection } from "./AudienceDemographicsSection";

interface IAudienceDistribution {
  audienceSetId: string;
  audienceSetData: AudienceSetQuery["audienceSet"];
}

export function AudienceDistribution({
  audienceSetData,
}: IAudienceDistribution) {
  const interests = [
    ...audienceSetData.Interests.map((v) => ({ ...v, status: "included" })),
    ...audienceSetData.ExcludedInterests.map((v) => ({
      ...v,
      status: "excluded",
    })),
  ];
  const audiences = [
    ...audienceSetData.Audiences.map((v) => ({ ...v, status: "included" })),
    ...audienceSetData.ExcludedAudiences.map((v) => ({
      ...v,
      status: "excluded",
    })),
  ];

  const accordionItems = [
    {
      value: "interests",
      trigger: (
        <SectionHeader
          icon={ThumbsUp}
          title="Interest, demographics & behaviour"
          description="Audience traits with seamless insights into interests, demographics, behaviors, and engagement patterns"
          hasIconWrapper
        />
      ),
      content: (
        <div className="border-t">
          {interests.map((interest) => (
            <AudienceAttributeRow
              subtitle={`Path: ${interest.path.join(" > ")}`}
              name={interest.name}
              type={interest.type ? titleCase(interest.type) : ""}
              isIncluded={interest.status === "included"}
              icon={getInterestIcon(interest as any)}
              hasNoBorder={interests.length === 1}
            />
          ))}
        </div>
      ),
    },
    {
      value: "audiences",
      trigger: (
        <SectionHeader
          icon={FileSearch02}
          hasIconWrapper
          title={"Audience Presets"}
          description={
            "Audience Presets are predefined segments that target specific groups based on demographics, interests, and behaviors."
          }
        />
      ),
      content: (
        <div className="border-t">
          {audiences.map((audience) => (
            <AudienceAttributeRow
              icon={getAudienceIconList({
                audiencePlatforms: audience.platform,
                categories: [],
              })}
              // subtitle={titleCase(audience.type, "_")}
              name={audience.name}
              isIncluded={audience.status === "included"}
              hasNoBorder={audiences.length === 1}
            />
          ))}
        </div>
      ),
    },
    {
      value: "demographics",
      trigger: (
        <SectionHeader
          icon={Globe04}
          hasIconWrapper
          title={"Demographics"}
          description={
            "Demographics are the characteristics of a group of people, such as age, gender, and location."
          }
        />
      ),
      content: (
        <AudienceDemographicsSection audienceSetData={audienceSetData} />
      ),
    },
  ];

  return (
    <div className={"pt-16"}>
      <div className={"flex items-start justify-between pb-16"}>
        <div className="flex flex-col gap-16 w-full">
          <SectionHeader title={"List of audiences and preset"} />
          <div>
            <Accordion.Root type="multiple" className="flex flex-col gap-14">
              {accordionItems.map((v) => (
                <AccordionItem
                  trigger={v.trigger}
                  value={v.value}
                  children={v.content}
                  className="rounded-8 border"
                />
              ))}
            </Accordion.Root>
          </div>
        </div>
      </div>
    </div>
  );
}
