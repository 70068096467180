import { useState } from "react";
import { Dialog } from "src/components/Dialog/Dialog";
import { SplitCreateCard } from "src/components/SplitCreateCard/SplitCreateCard";

interface IClientCreateDialogLayout {
  children: React.ReactNode;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  isEdit?: boolean;
  image: {
    src: string;
    alt: string;
    width: number;
    height: number;
  };
  trigger: React.ReactNode;
}

export function ClientCreateDialogLayout({
  children,
  image,
  trigger,
  isOpen,
  isEdit,
  onOpenChange,
}: IClientCreateDialogLayout) {
  return (
    <Dialog
      maxWidth="1050px"
      noPadding
      preventCloseOnEscape={isEdit ? false : true}
      preventCloseOnOutsideClick={isEdit ? false : true}
      trigger={trigger}
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      <SplitCreateCard image={image}>
        <div className="p-12 h-full">{children}</div>
      </SplitCreateCard>
    </Dialog>
  );
}
