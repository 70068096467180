import {
  ChartBreakoutCircle,
  Coins03,
  CursorClick01,
  Eye,
  Inbox01,
  Inbox02,
  Play,
  ThumbsUp,
  Users01,
  Wallet02,
} from "@untitled-ui/icons-react";
import { AnalyticsCategory } from "src/graphql/generated/schema";
import { IListingMappingValue } from "./IListingMappingValue";

export const campaignListingStatsMapping: Record<
  AnalyticsCategory,
  IListingMappingValue
> = {
  TRANSACTIONAL: {
    values: [
      { valueKey: "totalValuePurchase", valueType: "CURRENCY" },
      { valueKey: "spend", valueType: "CURRENCY" },
      { valueKey: "roas", valueType: "MULTIPLIER" },
    ],
    headers: [
      {
        title: "Revenue",
        icon: Wallet02,
      },
      {
        title: "Spend",
        icon: Coins03,
      },
      {
        title: "ROAS",
        icon: Coins03,
      },
    ],
  },
  TRAFFIC: {
    values: [
      { valueKey: "ctr", valueType: "PERCENTAGE" },
      { valueKey: "countLandingPageView", valueType: "NUMBER" },
      { valueKey: "clicks", valueType: "NUMBER" },
    ],
    headers: [
      {
        title: "CTR",
        icon: Inbox01,
      },
      {
        title: "Page Views",
        icon: Inbox02,
      },
      {
        title: "Clicks",
        icon: CursorClick01,
      },
    ],
  },
  LEADS: {
    values: [
      { valueKey: "countLead", valueType: "NUMBER" },
      { valueKey: "costPerLead", valueType: "CURRENCY" },
      { valueKey: "ctr", valueType: "PERCENTAGE" },
    ],
    headers: [
      {
        title: "Leads",
        icon: Inbox01,
      },
      {
        title: "CPL",
        icon: Inbox02,
      },
      {
        title: "CTR",
        icon: CursorClick01,
      },
    ],
  },
  ENGAGEMENT: {
    values: [
      { valueKey: "countPostEngagement", valueType: "NUMBER" },
      { valueKey: "messagingConversationStarted7d", valueType: "NUMBER" },
      { valueKey: "clicks", valueType: "NUMBER" },
    ],
    headers: [
      {
        title: "Engagements",
        icon: ThumbsUp,
      },
      {
        title: "DMs",
        icon: Inbox01,
      },
      {
        title: "Clicks",
        icon: CursorClick01,
      },
    ],
  },
  AWARENESS: {
    values: [
      { valueKey: "reach", valueType: "NUMBER" },
      { valueKey: "impressions", valueType: "NUMBER" },
      { valueKey: "clicks", valueType: "NUMBER" },
    ],
    headers: [
      {
        title: "Reach",
        icon: ChartBreakoutCircle,
      },
      {
        title: "Impressions",
        icon: Eye,
      },
      {
        title: "Clicks",
        icon: CursorClick01,
      },
    ],
  },
};
