import { Spinner } from "src/components/Spinner/Spinner";
import { useMetaPixelsFromPlatformQuery } from "src/graphql/generated/schema";
import { MetaDetailItem } from "./MetaDetailItem";
import { MetaIntegrationDetailsEmptyState } from "./MetaIntegrationDetailsEmptyState";
import { useState } from "react";

export function MetaPixelsList({
  selectedAccountId,
  setSelectedMetaPixels,
  selectedMetaPixels,
}) {
  const [loading, setLoading] = useState(false);
  const { data: metaPixelsData, loading: metaPixelsLoading } =
    useMetaPixelsFromPlatformQuery({
      variables: {
        adAccountId: selectedAccountId,
      },
      notifyOnNetworkStatusChange: true,
    });

  // const [
  //   metaEnablePixelFunc,
  //   { loading: metaEnableLoading, error: metaEnablePixelError },
  // ] = useMetaEnablePixelMutation();
  // const [metaRemovePixelFunc, { loading: metaRemoveLoading }] =
  //   useMetaRemovePixelMutation();

  if (metaPixelsLoading) {
    return <Spinner height="auto" />;
  }

  const metaPixels =
    metaPixelsData && metaPixelsData.metaPixelsFromPlatform
      ? metaPixelsData?.metaPixelsFromPlatform
      : [];

  if (metaPixels.length === 0) {
    return <MetaIntegrationDetailsEmptyState link={""} />;
  }

  return (
    <div>
      {metaPixels.map((pixel, index) => (
        <MetaDetailItem
          isDefault={pixel?.isDefault}
          // TODO: handle error
          // error={selectedPixel === pixel.id && metaEnablePixelError}
          enableFunc={async () => {
            setLoading(true);
            await setSelectedMetaPixels(pixel, metaPixels.length);
            setLoading(false);
          }}
          removeFunc={async () => {
            setLoading(true);
            setSelectedMetaPixels(pixel, metaPixels.length);
            setLoading(false);
          }}
          loading={loading}
          title={pixel?.name}
          isEnabled={
            selectedMetaPixels.find((v) => v.metaPixelId === pixel.metaPixelId)
              ?.enabled || false
          }
          key={index}
        />
      ))}
    </div>
  );
}
