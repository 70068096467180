import { MetaGeolocationItem } from "src/graphql/generated/schema";

export function formatLocationName(location: MetaGeolocationItem) {
  const name = location.name;
  const countryName = location.countryName || location.countryCode;
  const regionName = location.region;
  let fullName = name;

  if (regionName) {
    fullName += `, ${regionName}`;
  }

  if (countryName) {
    fullName += `, ${countryName}`;
  }

  return fullName;
}
