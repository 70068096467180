import { ErrorHandler } from "src/components/ErrorHandler";
import {
  GoogleIntegrationStatus,
  useGoogleIntegrationStatusQuery,
  useGoogleRevokeIntegrationMutation,
} from "src/graphql/generated/schema";
// import AuthContext from "src/stores/AuthContext";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { IntegrationHeader } from "src/modules/global/components/IntegrationHeader";
import googleIntegration from "../assets/Google.svg";
import { GoogleIntegrationSidePanel } from "../components/GoogleIntegrationSidePanel";
import { GoogleIntegrationWizard } from "../components/GoogleIntegrationWizard";
import { GoogleIntegrationSingle } from "../components/GoogleIntegrationSingle";

export function GoogleIntegration() {
  // const { user } = useContext(AuthContext);
  // Data loading and mutations
  const {
    data: statusData,
    error,
    loading: statusLoading,
  } = useGoogleIntegrationStatusQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [revokeIntegrationFunc, { loading: revokeLoading }] =
    useGoogleRevokeIntegrationMutation();

  if (statusLoading) {
    return <PageLoader />;
  }

  const integrationStatus: GoogleIntegrationStatus = statusData
    ? statusData.googleIntegrationStatus
    : "NOT_CONNECTED";

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div>
      <PageHeader
        title={"Google Integration"}
        breadcrumbs={[
          {
            label: "Settings",
            link: "/settings/integrations",
          },
          {
            label: "Integrations",
            link: "/settings/integrations",
          },
          {
            label: "Google",
            link: "/settings/integrations/google",
          },
        ]}
        back={"/settings/integrations"}
      />
      <IntegrationHeader
        isConnected={integrationStatus !== "NOT_CONNECTED"}
        revokeIntegrationFn={async () => {
          await revokeIntegrationFunc();
          window.location.reload();
          return {};
        }}
        revokeLoading={revokeLoading}
        title={"Google"}
        description={
          "Connect with google and create google ads and target your audience"
        }
        isBeta={false}
        imageSrc={googleIntegration}
      />
      <div className={"grid grid-cols-5"}>
        <div className="col-span-4">
          {integrationStatus === "ACTIVE" ? (
            <GoogleIntegrationSingle />
          ) : (
            <GoogleIntegrationWizard status={integrationStatus} />
          )}
        </div>
        <GoogleIntegrationSidePanel />
      </div>
    </div>
  );
}
