import { Edit03, MessageDotsCircle } from "@untitled-ui/icons-react";
import { Button } from "react-day-picker";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ErrorHandler } from "src/components/ErrorHandler";
import Header from "src/components/Header/Header";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Spinner } from "src/components/Spinner/Spinner";
import { Text } from "src/components/Text/Text";
import { useClientBrandAdditionalInfoQuery } from "src/graphql/generated/schema";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { ClientThumbnail } from "src/modules/global/components/ClientThumbnail";
import { ClientBrandEdit } from "../components/ClientBrandEdit";
import { Dialog } from "src/components/Dialog/Dialog";
import { ClientBrandAudienceEdit } from "../components/ClientBrandAudienceEdit";
import { titleCase } from "src/lib/titleCase";

export function ClientBrandSinglePage() {
  const { data, loading, error } = useClientBrandAdditionalInfoQuery();

  if (loading) return <Spinner height={400} />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      <Header
        title="Client Brand"
        actions={
          <ButtonV2
            variant="outline"
            size="small"
            rightIcon={MessageDotsCircle}
          >
            Contact Us
          </ButtonV2>
        }
      />

      <div className="flex flex-col gap-24 max-w-[820px] w-full mx-auto px-16 py-24">
        <SectionHeader
          title="Client Brand"
          description="Edit brand details and manage your persona all in one place!"
        />

        <div className="border border-neutral-50 flex flex-col gap-8 rounded-16">
          <div className="px-16 py-14 flex flex-col gap-16 border-b border-neutral-50">
            <div className="pb-14 flex justify-between items-center">
              <SectionHeader
                title="Brand Details"
                description="Capture essential brand information to set the foundation for the persona."
              />
              <ClientBrandEdit
                trigger={
                  <ButtonV2
                    as={Dialog.Trigger}
                    variant="outline"
                    size="small"
                    rightIcon={Edit03}
                  >
                    Edit
                  </ButtonV2>
                }
                initialValues={data.clientBrandAdditionalInfo}
              />
            </div>
            <div className="pt-14 flex gap-16 ">
              <ClientThumbnail
                size="36px"
                asset={data.clientBrandAdditionalInfo?.BrandLogo}
                clientName={data.clientBrandAdditionalInfo?.brandName || "N/A"}
              />
              <div className="flex flex-col gap-4">
                <Text size="xs" weight="medium" tone="subdued">
                  Profile Picture
                </Text>
                <Text weight="medium" tone="neutral-500">
                  {data.clientBrandAdditionalInfo?.brandName || "N/A"}
                </Text>
              </div>
            </div>
          </div>

          <div className="px-16 py-14 border-b border-neutral-50 flex items-start flex-col gap-8">
            <Text size="xs" weight="medium" tone="subdued">
              Brand Color
            </Text>
            <div className="inline-flex  rounded-6 gap-6 pl-6 pr-8 py-2 bg-gray-200 ">
              <div
                className="w-4 h-4 rounded-16"
                style={{
                  backgroundColor: data.clientBrandAdditionalInfo?.brandColor,
                }}
              />
              <Text size="sm" weight="medium" tone="subdued">
                {data.clientBrandAdditionalInfo?.brandColor || "N/A"}
              </Text>
            </div>
          </div>

          <div className="px-16 py-14 flex flex-col gap-8">
            <Text size="xs" weight="medium" tone="subdued">
              About Brand
            </Text>
            <Text tone="neutral-500">
              {data.clientBrandAdditionalInfo?.brandAbout || "N/A"}
            </Text>
          </div>
        </div>

        <div className="border border-neutral-50 flex flex-col gap-8 rounded-16">
          <div className="px-16 py-14 flex flex-col gap-16 border-b border-neutral-50">
            <div className="pb-14 flex justify-between items-center">
              <SectionHeader
                title="Audience Characteristics"
                description="Define the demographics and psychographics of the target audience."
              />
              <ClientBrandAudienceEdit
                trigger={
                  <ButtonV2
                    as={Dialog.Trigger}
                    variant="outline"
                    size="small"
                    rightIcon={Edit03}
                  >
                    Edit
                  </ButtonV2>
                }
                initialValues={data.clientBrandAdditionalInfo}
              />
            </div>
            <div className="pt-14 flex gap-16 ">
              <div className="flex gap-40">
                <div className="flex flex-col gap-4">
                  <Text size="xs" weight="medium" tone="subdued">
                    Age Range
                  </Text>
                  <Text weight="medium" tone="neutral-500">
                    {data.clientBrandAdditionalInfo?.audienceMinAge || "18"} -{" "}
                    {data.clientBrandAdditionalInfo?.audienceMaxAge || "65"}
                  </Text>
                </div>
                <div className="flex flex-col gap-4">
                  <Text size="xs" weight="medium" tone="subdued">
                    Gender
                  </Text>
                  <Text weight="medium" tone="neutral-500">
                    {data.clientBrandAdditionalInfo?.audienceGender
                      ? titleCase(data.clientBrandAdditionalInfo.audienceGender)
                      : "All"}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="px-16 py-14 border-b border-neutral-50 flex items-start flex-col gap-8">
            <Text size="xs" weight="medium" tone="subdued">
              Location
            </Text>
            <div className="flex gap-8 items-center">
              {data.clientBrandAdditionalInfo?.TargetCountries?.map(
                (country) => (
                  <BadgeV2 label={country.name} size="medium" color="gray" />
                ),
              )}
            </div>
          </div>

          <div className="px-16 py-14 border-b border-neutral-50 flex items-start flex-col gap-8">
            <Text size="xs" weight="medium" tone="subdued">
              Audience Interests
            </Text>
            <Text tone="neutral-500">
              {data.clientBrandAdditionalInfo?.audienceInterests || "N/A"}
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}
