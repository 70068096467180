import {
  AssetsQuery,
  useAssetDeleteMutation,
} from "src/graphql/generated/schema";
import { DotsVertical } from "@untitled-ui/icons-react";
import format from "date-fns/format";
import {
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import { DropdownV2 } from "src/modules/campaign/components/DropdownV2";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { formatFileSize } from "src/lib/formatFileSize";
import emptyImage from "../assets/emptyImage.png";

interface IMediaListingItem {
  media: AssetsQuery["assets"]["edges"][0]["node"];
}

export function MediaListingItem({ media }: IMediaListingItem) {
  const [mediaDeleteFunc, { error: deleteError }] = useAssetDeleteMutation();
  const formattedDate = format(new Date(media.createdAt), "dd MMM, yyyy");
  const usageCount = media.activitiesCount;

  const isUsed = usageCount > 0;

  return (
    <TableRow id={media.id}>
      <TablePrimaryCell
        title={media.name}
        imageSrc={
          media.contentType === "VIDEO"
            ? media.thumbnailUri
            : media.uri ?? emptyImage
        }
        subtitle={formattedDate}
      />
      <TableCell width={160}>
        <BadgeV2
          label={isUsed ? "In use" : "Not in use"}
          color={isUsed ? "green" : "gray"}
        />
      </TableCell>
      <TableCell width={120}>{usageCount}</TableCell>
      <TableCell width={120}>
        {media.width} x {media.height}
      </TableCell>
      <TableCell width={120}>{formatFileSize(media.fileSize)}</TableCell>
      <TableCell width={48}>
        <DropdownV2
          buttonVariant="plain"
          size="small"
          hideArrow
          options={[
            {
              label: "Delete Media",
              onClick() {
                mediaDeleteFunc({
                  variables: {
                    id: media.id,
                  },
                  refetchQueries: ["assets"],
                });
              },
            },
          ]}
          icon={DotsVertical}
        />
      </TableCell>
    </TableRow>
  );
}
