import classNames from "classnames";
import snakeCaseToTitleCase from "../../../lib/snakeCaseToTitleCase";
import { Switch } from "../../global/components/Switch";
import styles from "./MetaDetailItem.module.scss";
import { ApolloError } from "@apollo/client";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { useState } from "react";

interface IMetaAccountDetailItem {
  enableFunc: () => Promise<void> | void;
  removeFunc: () => Promise<void> | void;
  title: string;
  description?: string;
  isEnabled: boolean;
  isDefault?: boolean;
  error?: ApolloError;
  profilePicture?: string;
  loading?: boolean;
}

export function MetaDetailItem({
  profilePicture,
  enableFunc,
  removeFunc,
  isEnabled,
  isDefault = false,
  title,
  description,
  error,
  loading,
}: IMetaAccountDetailItem) {
  return (
    <div className={styles.container}>
      <div className={styles.profileContainer}>
        <img
          src={
            profilePicture ||
            `https://api.dicebear.com/7.x/shapes/svg?seed=${title}`
          }
          alt="meta item profile"
          className={styles.profile}
        />
        <div className={styles.content}>
          <div className={styles.title}>
            {snakeCaseToTitleCase(title || "")}
          </div>
          {description && (
            <div className={styles.descriptionContainer}>
              <span
                className={classNames(styles.description, {
                  [styles.error]: Boolean(error),
                })}
              >
                {error?.message || description}
              </span>

              {/* {error && (
                <Tooltip
                  children={
                    <Info height={15} width={15} className={styles.error} />
                  }
                  text={error.message}
                />
              )} */}
            </div>
          )}
        </div>
      </div>
      <div className={styles.secondarySection}>
        {isDefault && <BadgeV2 label={"Default"} color="gray" size="small" />}
        <Switch
          checked={isEnabled}
          onChange={async () => {
            if (isEnabled) {
              await removeFunc();
              return;
            }
            await enableFunc();
          }}
          title={title}
          disabled={loading}
        />
      </div>
    </div>
  );
}
