import {
  BillingLatestCardQuery,
  GetBillingQuery,
  OrganizationAddress,
  User,
} from "src/graphql/generated/schema";
import format from "date-fns/format";
import { titleCase } from "../../../lib/titleCase";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import Plus from "@material-symbols/svg-600/rounded/add.svg?react";
import { Text } from "src/components/Text/Text";
import { Edit05 } from "@untitled-ui/icons-react";

interface IBillingInformation {
  billing: GetBillingQuery["billing"];
  organization: {
    Address?: OrganizationAddress;
    Creator?: User;
    id: string;
    name: string;
    email?: string;
  };
  billingCard: BillingLatestCardQuery["billingLatestCard"];
}

function getCardImage(brand: string) {
  switch (brand) {
    case "amex":
      return "/imagesAndIcons/amex.svg";
    case "mastercard":
      return "/imagesAndIcons/mastercard.svg";
    case "visa":
      return "/imagesAndIcons/visa.png";
    default:
      return "/imagesAndIcons/generic.svg";
  }
}

export function BillingInformation({
  billing,
  billingCard,
  organization,
}: IBillingInformation) {
  const billingInformationList = [
    {
      label: "Billing Cycle",
      value: (
        <Text size="sm" weight="medium" tone="neutral-600">
          {billing.startedAt && billing.renewsAt
            ? `${format(
                new Date(billing.startedAt),
                "dd MMM, yyyy",
              )} to ${format(new Date(billing.renewsAt), "dd MMM, yyyy")}`
            : "-"}
        </Text>
      ),
    },
    {
      label: "Payment Method",
      value: !billingCard ? (
        <div>
          <ButtonV2
            children={"Add Payment Method"}
            size="medium"
            variant="outline"
            leftIcon={Plus}
          />
        </div>
      ) : (
        <div className="flex justify-between">
          <div className="flex gap-8">
            <img
              src={getCardImage(billingCard?.brand)}
              alt={getCardImage(billingCard.brand)}
              width={34}
              height={24}
            />
            <Text size="sm" weight="medium" tone="neutral-600">
              {titleCase(billingCard.brand)} ending in {billingCard?.last4},
              expiring {billingCard.expMonth}/{billingCard.expYear}
            </Text>
          </div>
          <div>
            <ButtonV2
              children={"Update Payment Method"}
              size="extraSmall"
              variant="link"
              rightIcon={Edit05}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Billing Address",
      value: (
        <Text size="sm" weight="medium" tone="neutral-600">
          {organization.Address
            ? `${[
                organization.Address?.addressLine1,
                organization.Address?.city,
                organization.Address?.state,
                organization.Address?.country,
              ].join(",")}`
            : "No address mentioned"}
        </Text>
      ),
    },
    {
      label: "Billing Email",
      value: (
        <Text size="sm" weight="medium" tone="neutral-600">
          {organization.email}{" "}
        </Text>
      ),
    },
  ];
  return (
    <div>
      {billingInformationList.map((v) => (
        <div className="border-b last:border-0" key={v.label}>
          <div className="px-16 py-14 flex flex-col gap-8">
            <Text size="xs" weight="medium" tone="gray-700">
              {v.label}
            </Text>
            <div>{v.value}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
