import React from "react";

import LogoBlackFull from "./macro-logo-black-full.svg?react";
import LogoBlackIcon from "./macro-logo-black-icon.svg?react";
import LogoWhiteFull from "./macro-logo-white-full.svg?react";
import LogoWhiteIcon from "./macro-logo-white-icon.svg?react";

interface ILogo {
  type: "icon" | "full";
  style: "white" | "black";
  size?: "sm" | "md" | "lg";
  embrossed?: boolean;
}

export const Logo: React.FC<ILogo> = ({
  type,
  style,
  size = "md",
  embrossed = false,
}) => {
  const LogoComponent =
    type === "full"
      ? style === "white"
        ? LogoWhiteFull
        : LogoBlackFull
      : style === "white"
      ? LogoWhiteIcon
      : LogoBlackIcon;

  const sizeClass =
    type === "full"
      ? {
          sm: "w-24 h-6", // THIS NEEDS TO BE DESIGNED
          md: "w-32 h-8",
          lg: "w-40 h-10", // THIS NEEDS TO BE DESIGNED
        }
      : {
          sm: "w-6 h-6", // THIS NEEDS TO BE DESIGNED
          md: "w-10 h-10",
          lg: "w-12 h-12", // THIS NEEDS TO BE DESIGNED
        };

  return (
    <LogoComponent
      className={`${sizeClass[size]} ${
        embrossed
          ? "p-8 border border-[rgba(68,68,68,0.25)] shadow-[0_0.609px_0.609px_0_#B2B3C9,0_3.321px_8.304px_-1.661px_rgba(23,24,24,0.02)] rounded-[4px]"
          : ""
      }`}
    />
  );
};
