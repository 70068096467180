/*
 * This list has been extracted from `Intl.supportedValues('timeZone')`
 */
export const timeZoneOptions = [
  {
    value: "Etc/GMT+12",
    label: "(GMT-12:00) International Date Line West",
  },
  {
    value: "Pacific/Pago_Pago",
    label: "(GMT-11:00) American Samoa",
  },
  {
    value: "Pacific/Midway",
    label: "(GMT-11:00) Midway Island",
  },
  {
    value: "Pacific/Honolulu",
    label: "(GMT-10:00) Hawaii",
  },
  {
    value: "America/Juneau",
    label: "(GMT-09:00) Alaska",
  },
  {
    value: "America/Los_Angeles",
    label: "(GMT-08:00) Pacific Time (US & Canada)",
  },
  {
    value: "America/Tijuana",
    label: "(GMT-08:00) Tijuana",
  },
  {
    value: "America/Phoenix",
    label: "(GMT-07:00) Arizona",
  },
  {
    value: "America/Mazatlan",
    label: "(GMT-07:00) Mazatlan",
  },
  {
    value: "America/Denver",
    label: "(GMT-07:00) Mountain Time (US & Canada)",
  },
  {
    value: "America/Guatemala",
    label: "(GMT-06:00) Central America",
  },
  {
    value: "America/Chicago",
    label: "(GMT-06:00) Central Time (US & Canada)",
  },
  {
    value: "America/Chihuahua",
    label: "(GMT-07:00) Chihuahua",
  },
  {
    value: "America/Mexico_City",
    label: "(GMT-06:00) Guadalajara, Mexico City",
  },
  {
    value: "America/Monterrey",
    label: "(GMT-06:00) Monterrey",
  },
  {
    value: "America/Regina",
    label: "(GMT-06:00) Saskatchewan",
  },
  {
    value: "America/Bogota",
    label: "(GMT-05:00) Bogota",
  },
  {
    value: "America/New_York",
    label: "(GMT-05:00) Eastern Time (US & Canada)",
  },
  {
    value: "America/Indiana/Indianapolis",
    label: "(GMT-05:00) Indiana (East)",
  },
  {
    value: "America/Lima",
    label: "(GMT-05:00) Lima, Quito",
  },
  {
    value: "America/Halifax",
    label: "(GMT-04:00) Atlantic Time (Canada)",
  },
  {
    value: "America/Caracas",
    label: "(GMT-04:00) Caracas",
  },
  {
    value: "America/Guyana",
    label: "(GMT-04:00) Georgetown",
  },
  {
    value: "America/La_Paz",
    label: "(GMT-04:00) La Paz",
  },
  {
    value: "America/Puerto_Rico",
    label: "(GMT-04:00) Puerto Rico",
  },
  {
    value: "America/Santiago",
    label: "(GMT-04:00) Santiago",
  },
  {
    value: "America/St_Johns",
    label: "(GMT-03:30) Newfoundland",
  },
  {
    value: "America/Sao_Paulo",
    label: "(GMT-03:00) Brasilia",
  },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "(GMT-03:00) Buenos Aires",
  },
  {
    value: "America/Montevideo",
    label: "(GMT-03:00) Montevideo",
  },
  {
    value: "America/Godthab",
    label: "(GMT-03:00) Greenland",
  },
  {
    value: "Atlantic/South_Georgia",
    label: "(GMT-02:00) Mid-Atlantic",
  },
  {
    value: "Atlantic/Azores",
    label: "(GMT-01:00) Azores",
  },
  {
    value: "Atlantic/Cape_Verde",
    label: "(GMT-01:00) Cape Verde Is.",
  },
  {
    value: "Europe/London",
    label: "(GMT+00:00) Edinburgh, London",
  },
  {
    value: "Europe/Lisbon",
    label: "(GMT+00:00) Lisbon",
  },
  {
    value: "Africa/Monrovia",
    label: "(GMT+00:00) Monrovia",
  },
  {
    value: "Etc/UTC",
    label: "(GMT+00:00) UTC",
  },
  {
    value: "Europe/Amsterdam",
    label: "(GMT+01:00) Amsterdam",
  },
  {
    value: "Europe/Belgrade",
    label: "(GMT+01:00) Belgrade",
  },
  {
    value: "Europe/Berlin",
    label: "(GMT+01:00) Berlin",
  },
  {
    value: "Europe/Zurich",
    label: "(GMT+01:00) Bern, Zurich",
  },
  {
    value: "Europe/Bratislava",
    label: "(GMT+01:00) Bratislava",
  },
  {
    value: "Europe/Brussels",
    label: "(GMT+01:00) Brussels",
  },
  {
    value: "Europe/Budapest",
    label: "(GMT+01:00) Budapest",
  },
  {
    value: "Africa/Casablanca",
    label: "(GMT+01:00) Casablanca",
  },
  {
    value: "Europe/Copenhagen",
    label: "(GMT+01:00) Copenhagen",
  },
  {
    value: "Europe/Dublin",
    label: "(GMT+00:00) Dublin",
  },
  {
    value: "Europe/Ljubljana",
    label: "(GMT+01:00) Ljubljana",
  },
  {
    value: "Europe/Madrid",
    label: "(GMT+01:00) Madrid",
  },
  {
    value: "Europe/Paris",
    label: "(GMT+01:00) Paris",
  },
  {
    value: "Europe/Prague",
    label: "(GMT+01:00) Prague",
  },
  {
    value: "Europe/Rome",
    label: "(GMT+01:00) Rome",
  },
  {
    value: "Europe/Sarajevo",
    label: "(GMT+01:00) Sarajevo",
  },
  {
    value: "Europe/Skopje",
    label: "(GMT+01:00) Skopje",
  },
  {
    value: "Europe/Stockholm",
    label: "(GMT+01:00) Stockholm",
  },
  {
    value: "Europe/Vienna",
    label: "(GMT+01:00) Vienna",
  },
  {
    value: "Europe/Warsaw",
    label: "(GMT+01:00) Warsaw",
  },
  {
    value: "Africa/Algiers",
    label: "(GMT+01:00) West Central Africa",
  },
  {
    value: "Europe/Zagreb",
    label: "(GMT+01:00) Zagreb",
  },
  {
    value: "Europe/Athens",
    label: "(GMT+02:00) Athens",
  },
  {
    value: "Europe/Bucharest",
    label: "(GMT+02:00) Bucharest",
  },
  {
    value: "Africa/Cairo",
    label: "(GMT+02:00) Cairo",
  },
  {
    value: "Africa/Harare",
    label: "(GMT+02:00) Harare",
  },
  {
    value: "Europe/Helsinki",
    label: "(GMT+02:00) Helsinki",
  },
  {
    value: "Asia/Jerusalem",
    label: "(GMT+02:00) Jerusalem",
  },
  {
    value: "Europe/Kaliningrad",
    label: "(GMT+02:00) Kaliningrad",
  },
  {
    value: "Europe/Kiev",
    label: "(GMT+02:00) Kyiv",
  },
  {
    value: "Africa/Johannesburg",
    label: "(GMT+02:00) Pretoria",
  },
  {
    value: "Europe/Riga",
    label: "(GMT+02:00) Riga",
  },
  {
    value: "Europe/Sofia",
    label: "(GMT+02:00) Sofia",
  },
  {
    value: "Europe/Tallinn",
    label: "(GMT+02:00) Tallinn",
  },
  {
    value: "Europe/Vilnius",
    label: "(GMT+02:00) Vilnius",
  },
  {
    value: "Asia/Baghdad",
    label: "(GMT+03:00) Baghdad",
  },
  {
    value: "Europe/Istanbul",
    label: "(GMT+03:00) Istanbul",
  },
  {
    value: "Asia/Kuwait",
    label: "(GMT+03:00) Kuwait",
  },
  {
    value: "Europe/Minsk",
    label: "(GMT+03:00) Minsk",
  },
  {
    value: "Europe/Moscow",
    label: "(GMT+03:00) Moscow, St. Petersburg",
  },
  {
    value: "Africa/Nairobi",
    label: "(GMT+03:00) Nairobi",
  },
  {
    value: "Asia/Riyadh",
    label: "(GMT+03:00) Riyadh",
  },
  {
    value: "Europe/Volgograd",
    label: "(GMT+03:00) Volgograd",
  },
  {
    value: "Asia/Tehran",
    label: "(GMT+03:30) Tehran",
  },
  {
    value: "Asia/Muscat",
    label: "(GMT+04:00) Abu Dhabi, Muscat",
  },
  {
    value: "Asia/Baku",
    label: "(GMT+04:00) Baku",
  },
  {
    value: "Europe/Samara",
    label: "(GMT+04:00) Samara",
  },
  {
    value: "Asia/Tbilisi",
    label: "(GMT+04:00) Tbilisi",
  },
  {
    value: "Asia/Yerevan",
    label: "(GMT+04:00) Yerevan",
  },
  {
    value: "Asia/Kabul",
    label: "(GMT+04:30) Kabul",
  },
  {
    value: "Asia/Almaty",
    label: "(GMT+06:00) Almaty",
  },
  {
    value: "Asia/Yekaterinburg",
    label: "(GMT+05:00) Ekaterinburg",
  },
  {
    value: "Asia/Karachi",
    label: "(GMT+05:00) Pakistan Standard Time",
  },
  {
    value: "Asia/Tashkent",
    label: "(GMT+05:00) Tashkent",
  },
  {
    value: "Asia/Kolkata",
    label: "(GMT+05:30) India Standard Time",
  },
  {
    value: "Asia/Colombo",
    label: "(GMT+05:30) Sri Jayawardenepura",
  },
  {
    value: "Asia/Kathmandu",
    label: "(GMT+05:45) Kathmandu",
  },
  {
    value: "Asia/Dhaka",
    label: "(GMT+06:00) Astana, Dhaka",
  },
  {
    value: "Asia/Urumqi",
    label: "(GMT+06:00) Urumqi",
  },
  {
    value: "Asia/Rangoon",
    label: "(GMT+06:30) Rangoon",
  },
  {
    value: "Asia/Bangkok",
    label: "(GMT+07:00) Bangkok, Hanoi",
  },
  {
    value: "Asia/Jakarta",
    label: "(GMT+07:00) Jakarta",
  },
  {
    value: "Asia/Krasnoyarsk",
    label: "(GMT+07:00) Krasnoyarsk",
  },
  {
    value: "Asia/Novosibirsk",
    label: "(GMT+07:00) Novosibirsk",
  },
  {
    value: "Asia/Shanghai",
    label: "(GMT+08:00) Beijing",
  },
  {
    value: "Asia/Chongqing",
    label: "(GMT+08:00) Chongqing",
  },
  {
    value: "Asia/Hong_Kong",
    label: "(GMT+08:00) Hong Kong",
  },
  {
    value: "Asia/Irkutsk",
    label: "(GMT+08:00) Irkutsk",
  },
  {
    value: "Asia/Kuala_Lumpur",
    label: "(GMT+08:00) Kuala Lumpur",
  },
  {
    value: "Australia/Perth",
    label: "(GMT+08:00) Perth",
  },
  {
    value: "Asia/Singapore",
    label: "(GMT+08:00) Singapore",
  },
  {
    value: "Asia/Taipei",
    label: "(GMT+08:00) Taipei",
  },
  {
    value: "Asia/Ulaanbaatar",
    label: "(GMT+08:00) Ulaanbaatar",
  },
  {
    value: "Asia/Tokyo",
    label: "(GMT+09:00) Osaka, Sapporo, Tokyo",
  },
  {
    value: "Asia/Seoul",
    label: "(GMT+09:00) Seoul",
  },
  {
    value: "Asia/Yakutsk",
    label: "(GMT+09:00) Yakutsk",
  },
  {
    value: "Australia/Adelaide",
    label: "(GMT+09:30) Adelaide",
  },
  {
    value: "Australia/Darwin",
    label: "(GMT+09:30) Darwin",
  },
  {
    value: "Australia/Brisbane",
    label: "(GMT+10:00) Brisbane",
  },
  {
    value: "Australia/Canberra",
    label: "(GMT+10:00) Canberra, Melbourne",
  },
  {
    value: "Pacific/Guam",
    label: "(GMT+10:00) Guam",
  },
  {
    value: "Australia/Hobart",
    label: "(GMT+10:00) Hobart",
  },
  {
    value: "Australia/Melbourne",
    label: "(GMT+10:00) Canberra, Melbourne",
  },
  {
    value: "Pacific/Port_Moresby",
    label: "(GMT+10:00) Port Moresby",
  },
  {
    value: "Australia/Sydney",
    label: "(GMT+10:00) Sydney",
  },
  {
    value: "Asia/Vladivostok",
    label: "(GMT+10:00) Vladivostok",
  },
  {
    value: "Asia/Magadan",
    label: "(GMT+11:00) Magadan",
  },
  {
    value: "Pacific/Noumea",
    label: "(GMT+11:00) New Caledonia",
  },
  {
    value: "Pacific/Guadalcanal",
    label: "(GMT+11:00) Solomon Is.",
  },
  {
    value: "Asia/Srednekolymsk",
    label: "(GMT+11:00) Srednekolymsk",
  },
  {
    value: "Pacific/Auckland",
    label: "(GMT+12:00) Auckland, Wellington",
  },
  {
    value: "Pacific/Fiji",
    label: "(GMT+12:00) Fiji",
  },
  {
    value: "Asia/Kamchatka",
    label: "(GMT+12:00) Kamchatka",
  },
  {
    value: "Pacific/Majuro",
    label: "(GMT+12:00) Marshall Is.",
  },
  {
    value: "Pacific/Chatham",
    label: "(GMT+12:45) Chatham Is.",
  },
  {
    value: "Pacific/Tongatapu",
    label: "(GMT+13:00) Nuku'alofa",
  },
  {
    value: "Pacific/Apia",
    label: "(GMT+13:00) Samoa",
  },
  {
    value: "Pacific/Fakaofo",
    label: "(GMT+13:00) Tokelau Is.",
  },
];
