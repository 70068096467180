import { Asset, LineItem } from "src/lib/types";
import { IAdPreview } from "../misc/creativePreviewTypes";
import styles from "./FacebookStoryAdPreview.module.scss";
import DotsHorizontal from "@untitled-ui/icons-react/build/esm/DotsHorizontal";
import X from "@untitled-ui/icons-react/build/esm/X";
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle";
import Link01 from "@untitled-ui/icons-react/build/esm/Link01";
import classNames from "classnames";
import InstagramVerified from "../../../icons/instagram_verified.svg?react";
import { titleCase } from "src/lib/titleCase";
import { clampValue } from "src/lib/clampValue";

export function FacebookStoryAdPreview({
  activeAssetIndex,
  caption,
  ctaLabel,
  isProductCatalogue,
  lineItems,
  setActiveAssetIndex,
  userName: username,
  userAvatar,
}: IAdPreview) {
  const activeAsset =
    lineItems[activeAssetIndex]?.StoryAsset ??
    lineItems[activeAssetIndex]?.PostAsset;

  return (
    <div className={styles.wrapper}>
      <div className={styles.preview}>
        <div className={styles.topOverlayBackground} />
        <div className={styles.storyHeader}>
          <div className={styles.storyProgress}>
            {lineItems.map((_, index) => (
              <div
                className={classNames(styles.storyProgressItem, {
                  [styles.activeStoryProgressItem]: index === activeAssetIndex,
                })}
              />
            ))}
          </div>

          <div className={styles.storyTopInfo}>
            <div className={styles.userInfo}>
              <img
                className={styles.userAvatar}
                src={userAvatar}
                alt={username}
                width={18}
                height={18}
              />
              <div className={styles.usernameWrapper}>
                <div className={styles.username}>
                  {username}
                  <InstagramVerified width={7} height={7} />
                </div>
                <div className={styles.sponsored}>Sponsored</div>
              </div>
            </div>
            <div className={styles.storyActions}>
              <DotsHorizontal width={10} height={10} />
              <X width={10} height={10} />
            </div>
          </div>
        </div>

        {/* non visible buttons that let you navigate between slides */}
        <div className={styles.navigationButtons}>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setActiveAssetIndex(
                clampValue(activeAssetIndex - 1, 0, lineItems.length - 1),
              );
            }}
            className={styles.leftNavigationButton}
          />
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setActiveAssetIndex(
                clampValue(activeAssetIndex + 1, 0, lineItems.length - 1),
              );
            }}
            className={styles.rightNavigationButton}
          />
        </div>

        <div className={styles.creativeContent}>
          <FacebookAsset
            activeAssetIndex={activeAssetIndex}
            lineItems={lineItems}
          />
        </div>

        <div className={styles.cta}>
          <Link01 width={16} height={16} />
          {titleCase(ctaLabel, "_")}
        </div>
      </div>
    </div>
  );
}

interface IFacebookAsset {
  lineItems: LineItem[];
  activeAssetIndex: number;
}

function FacebookAsset({ lineItems, activeAssetIndex }: IFacebookAsset) {
  const assets = lineItems.map(
    (lineItem) => lineItem.StoryAsset ?? lineItem.PostAsset,
  );

  const activeAsset = assets[activeAssetIndex];

  if (!activeAsset) {
    return (
      <div className={styles.emptyImageSection}>
        <AlertCircle color="var(--color-disabled)" width={14} height={14} />
        <span className={styles.emptyImageSectionText}>
          Select Ad to see the preview
        </span>
      </div>
    );
  }

  if (activeAsset.contentType === "IMAGE") {
    return (
      <div className={styles.post}>
        <img src={activeAsset.uri} alt="Post" className={styles.postImage} />
      </div>
    );
  }

  if (activeAsset.contentType === "VIDEO") {
    return (
      <div className={styles.post}>
        <video
          src={activeAsset.uri}
          className={styles.postImage}
          autoPlay
          muted
        >
          <source src={activeAsset.uri} />
        </video>
      </div>
    );
  }

  return null;
}
