import { AnalyticsCategory } from "src/graphql/generated/schema";
import { IListingMappingValue } from "src/modules/global/misc/IListingMappingValue";

export const audienceListingStatsMapping: Record<
  AnalyticsCategory,
  IListingMappingValue
> = {
  AWARENESS: {
    headers: [
      {
        title: "Clicks",
      },
      {
        title: "Impressions",
      },
    ],
    values: [
      {
        valueKey: "clicks",
        valueType: "NUMBER",
      },
      {
        valueKey: "impressions",
        valueType: "NUMBER",
      },
    ],
  },
  ENGAGEMENT: {
    headers: [
      {
        title: "Clicks",
      },
      {
        title: "Impressions",
      },
    ],
    values: [
      {
        valueKey: "clicks",
        valueType: "NUMBER",
      },
      {
        valueKey: "impressions",
        valueType: "NUMBER",
      },
    ],
  },
  LEADS: {
    headers: [
      {
        title: "CPC",
      },
      {
        title: "CPL",
      },
    ],
    values: [
      {
        valueKey: "cpc",
        valueType: "CURRENCY",
      },
      {
        valueKey: "costPerLead",
        valueType: "CURRENCY",
      },
    ],
  },
  TRAFFIC: {
    headers: [
      {
        title: "Clicks",
      },
      {
        title: "CPC",
      },
    ],
    values: [
      {
        valueKey: "clicks",
        valueType: "NUMBER",
      },
      {
        valueKey: "cpc",
        valueType: "CURRENCY",
      },
    ],
  },
  TRANSACTIONAL: {
    headers: [
      {
        title: "ROAS",
      },
      {
        title: "CPC",
      },
    ],
    values: [
      {
        valueKey: "roas",
        valueType: "MULTIPLIER",
      },
      {
        valueKey: "cpc",
        valueType: "CURRENCY",
      },
    ],
  },
};
