import { RadioGroup } from "@headlessui/react";
import NotificationSettingWithToggle from "./NotificationSettingWithToggle";
import styles from "./NotificationSettingsContent.module.scss";
import NotificationSettingItem from "./NotificationSettingItem";
import classNames from "classnames";
import { NotificationAlertSettings } from "./NotificationAlertSettings";
import { NotificationHealthReport } from "./NotificationHealthReport";
import { ReactNode } from "react";
import { useUserNotificationSettingsUpsertMutation } from "src/graphql/generated/schema";
import { notificationSettingFormType } from "../misc/notificationSettingFormType";
import { useFormikContext } from "formik";
import toast from "react-hot-toast";

const generalSettings: ISetting[] = [
  {
    title: "Campaign & Activities",
    description:
      "If campaign or activity published, change in status or edited, you'll get notified",
    settingKey: "isCampaignNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
  {
    title: "Audiences",
    description:
      "If any change or updates in Audience like creation, editing or delete, you'll get notified",
    settingKey: "isAudienceNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
  {
    title: "Segments and Customers",
    description:
      "If any change or updates in segment or customers like creation, editing or delete, you'll get notified",
    settingKey: "isCustomerNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
  {
    title: "Analytics and Reporting",
    description:
      "If there is any change in your analytics like sale increase, order count change or change in other metrics, you'll get notified",
    settingKey: "isReportNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
  {
    title: "Automation Flow",
    description:
      "If any change or updates in automation flow like creation, editing or delete, you'll get notified",
    settingKey: "isAutomationNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
];

const reminders: ISetting[] = [
  {
    title: "Scheduled Activities & Automations",
    description:
      "Notify you 60 min before when there is any campaign & automation is to be launched ",
    settingKey: "isAutomationNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
  {
    title: "Suggestions and Promotions",
    description:
      "Tips and suggestions on how you can increase your sales and achieve goals",
    settingKey: "isPromotionalNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
  {
    title: "Your Subscriptions",
    description: "Notify you 10 days before from your subscription end date",
    settingKey: "isBillingNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
];

const organizationTeam: ISetting[] = [
  {
    title: "Team Members",
    description: "Add or remove team member, change in roles and others",
    settingKey: "isOrganizationTeamNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
  {
    title: "Client and Organization",
    description:
      "Add or remove client or change in setting in organization level",
    settingKey: "isOrganizationNotificationEnabled",
    settingType: "INDIVIDUAL",
  },
];

interface ISetting {
  title: string;
  description: string;
  settingType: "INDIVIDUAL" | "GROUP";
  settingKey?: string;
  subSettings?: ReactNode;
}

interface INotificationSettingsContent {
  setSelectedSetting: (i: ISetting) => void;
  selectedSetting: ISetting;
}

export default function NotificationSettingsContent({
  selectedSetting,
  setSelectedSetting,
}: INotificationSettingsContent) {
  const { values } = useFormikContext<notificationSettingFormType>();
  // this mutation is duplicated to achieve automatic submit when toggles are enabled/disabled
  const [
    userNotificationSettingsUpsert,
    { loading: notificationSettingsUpsertLoading },
  ] = useUserNotificationSettingsUpsertMutation({});

  const alerts: ISetting[] = [
    {
      title: "Email",
      description: values.email
        ? `Connected email: ${values.email}`
        : `Not Connected Yet`,
      settingType: "GROUP",
      subSettings: <NotificationAlertSettings type="EMAIL" />,
    },
    {
      title: "Whatsapp",
      description: values.whatsappPhoneNumber
        ? `Connected number : ${values.whatsappPhoneNumber}`
        : "Not Connected Yet",
      settingType: "GROUP",
      subSettings: <NotificationAlertSettings type="WHATSAPP" />,
    },
    // {
    //   title: "Text SMS",
    //   description: "Connected number : +91 9876543210",
    //   settingType: "GROUP",
    //   subSettings: <NotificationAlertSettings type="SMS" />,
    // },
    // {
    //   title: "Browser Push Notification",
    //   description: "Notify's you when there is any issues with your Ad account",
    //   settingType: "GROUP",
    //   subSettings: <NotificationBrowserNotify />,
    // },
    {
      title: "Business Health Report",
      description: "An overview report of your business",
      settingType: "GROUP",
      subSettings: (
        <NotificationHealthReport
          loading={notificationSettingsUpsertLoading}
          onChange={async function (values: notificationSettingFormType) {
            try {
              await userNotificationSettingsUpsert({
                variables: {
                  input: values,
                },
              });
              toast.success("Updated settings successfully");
            } catch (err) {
              toast.error(err?.message || "unknown error occurred");
            }
          }}
        />
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.listTitle}>General</span>
        <div className={styles.settingsList}>
          {generalSettings.map((item, idx) => (
            <NotificationSettingWithToggle
              title={item.title}
              description={item.description}
              formKey={item.settingKey}
              key={idx}
              onChange={async (value) => {
                await userNotificationSettingsUpsert({
                  variables: {
                    input: {
                      ...values,
                      [item.settingKey]: value,
                    },
                  },
                });
              }}
            />
          ))}
        </div>
      </div>
      <div>
        <span className={styles.listTitle}>Alerts & Notifications</span>
        <div className={styles.subInfo}>
          <p className={styles.subTitle}>Receive notification and updates</p>
          <p className={styles.subDescription}>
            Where you want to receive updates and notification
          </p>
        </div>
        <div className={styles.settingsList}>
          <RadioGroup
            value={selectedSetting}
            onChange={setSelectedSetting}
            className={styles.radioList}
          >
            {alerts.map((item, idx) => (
              <RadioGroup.Option
                value={item}
                key={idx}
                className={classNames({
                  [styles.selectedItem]: selectedSetting?.title === item.title,
                })}
              >
                <NotificationSettingItem
                  title={item.title}
                  description={item.description}
                />
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
      </div>
      <div>
        <span className={styles.listTitle}>Reminders & Updates</span>
        <div className={styles.settingsList}>
          {reminders.map((item, idx) => (
            <NotificationSettingWithToggle
              title={item.title}
              description={item.description}
              formKey={item.settingKey}
              key={idx}
              // formik makes sure the last updated value is synchronized before
              // submitting so we don't have to wait for the value to be updated before submitting
              onChange={async (value) => {
                await userNotificationSettingsUpsert({
                  variables: {
                    input: {
                      ...values,
                      [item.settingKey]: value,
                    },
                  },
                });
              }}
            />
          ))}
        </div>
      </div>
      <div>
        <span className={styles.listTitle}>Organization & Team</span>
        <div className={styles.settingsList}>
          {organizationTeam.map((item, idx) => (
            <NotificationSettingWithToggle
              title={item.title}
              description={item.description}
              formKey={item.settingKey}
              key={idx}
              onChange={async (value) => {
                await userNotificationSettingsUpsert({
                  variables: {
                    input: {
                      ...values,
                      [item.settingKey]: value,
                    },
                  },
                });
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
