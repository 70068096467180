import { Asset } from "src/graphql/generated/schema";
import styles from "./MediaPicker.module.scss";
import DescriptionIcon from "@material-symbols/svg-600/outlined/description.svg?react";
import AspectRatioIcon from "@material-symbols/svg-600/outlined/aspect_ratio.svg?react";
import PackageIcon from "@material-symbols/svg-600/outlined/package_2.svg?react";
import Badge from "src/components/Badge/Badge";
import { getAspectRatio } from "../../misc/getAspectRatio";
import { formatFileSize } from "src/lib/formatFileSize";
import classNames from "classnames";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import Tooltip from "src/components/Tooltip/Tooltip";

interface IMediaPicker {
  multiple: boolean;
  assets: Array<
    Asset & {
      disabled?: boolean;
      disabledText?: string;
    }
  >;
  selectedAssets: Asset[];
  setSelectedAssets: (i: Asset[]) => void;
}

export function MediaPicker({
  multiple,
  assets,
  selectedAssets,
  setSelectedAssets,
}: IMediaPicker) {
  function handleSelect(asset: IMediaPicker["assets"][0]) {
    if (!multiple) {
      return setSelectedAssets([asset]);
    }

    const isSelected = selectedAssets.find((a) => a.id === asset.id);
    if (isSelected) {
      return setSelectedAssets(selectedAssets.filter((a) => a.id !== asset.id));
    }

    setSelectedAssets([...selectedAssets, asset]);
  }

  return (
    <div className={styles.wrapper}>
      {assets.map((asset) => {
        const isSelected = !!selectedAssets.find((a) => a.id === asset.id);

        if (asset.disabled) {
          return (
            <Tooltip
              align="center"
              side="top"
              content={asset.disabledText}
              isInline={false}
            >
              <MediaAsset
                asset={asset}
                isSelected={isSelected}
                key={asset.id}
                handleSelect={handleSelect}
                multiple={multiple}
              />
            </Tooltip>
          );
        }

        return (
          <MediaAsset
            asset={asset}
            isSelected={isSelected}
            key={asset.id}
            handleSelect={handleSelect}
            multiple={multiple}
          />
        );
      })}
    </div>
  );
}

function MediaAsset({
  asset,
  isSelected,
  handleSelect,
  multiple,
}: {
  asset: IMediaPicker["assets"][0];
  isSelected: boolean;
  handleSelect: (asset: IMediaPicker["assets"][0]) => void;
  multiple: boolean;
}) {
  return (
    <div
      onClick={() => {
        if (asset.disabled) {
          return;
        }
        handleSelect(asset);
      }}
      className={classNames(styles.assetItem, {
        [styles.selectedAssetItem]: isSelected,
        [styles.disableItem]: asset.disabled,
        [styles.isMultiSelect]: multiple,
      })}
    >
      {multiple && (
        <div>
          <Checkbox
            checked={isSelected}
            onChange={() => handleSelect(asset)}
            value={asset.id}
            disabled={asset.disabled}
          />
        </div>
      )}

      <img
        className={styles.assetPreview}
        src={asset.thumbnailUri || asset.uri}
        width={32}
        height={32}
        alt=""
      />

      <div className={styles.titleWrapper}>
        <span
          className={classNames(styles.title, {
            [styles.titleSelected]: isSelected,
          })}
        >
          <span className={styles.titleContentEllipsis}>{asset.name}</span>
        </span>
        <span className={styles.titleAdditionalContent}>
          <span className={classNames(styles.statItem)}>
            <span className={classNames(styles.statItemIcon, styles.adCount)}>
              <DescriptionIcon fill="currentColor" width={15} height={15} />
            </span>
            {asset.adsCount}
          </span>
          <span className={classNames(styles.statItem)}>
            <span
              className={classNames(styles.statItemIcon, styles.templateCount)}
            >
              <DescriptionIcon fill="currentColor" width={15} height={15} />
            </span>
            -
          </span>
        </span>
      </div>

      <div>
        {asset.adsCount > 0 ? (
          <Badge label="In Use" />
        ) : (
          <Badge label="Not in Use" />
        )}
      </div>
      <div>
        <span className={styles.aspectRatio}>
          {getAspectRatio(asset.width, asset.height)}
        </span>
      </div>
      <div>
        <span className={styles.dimensions}>
          {asset.width}x{asset.height}
          <AspectRatioIcon
            fill="var(--color-disabled)"
            width={20}
            height={20}
          />
        </span>
      </div>

      <div>
        <span className={styles.fileSize}>
          {formatFileSize(asset.fileSize)}
          <PackageIcon fill="var(--color-disabled)" width={20} height={20} />
        </span>
      </div>
    </div>
  );
}
