import React from "react";
import Flex from "src/components/Flex/Flex";
import { Grid } from "src/components/Grid/Grid";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { WidgetHeader } from "src/components/WidgetHeader/WidgetHeader";
import { WidgetLayout } from "src/components/WidgetLayout/WidgetLayout";
import { MetricCard } from "../MetricCard/MetricCard";
import { MetricCardList } from "../MetricCardList/MetricCardList";
import { Skeleton } from "../Skeleton/Skeleton";

export interface StatsCardWidgetProps {
  title: string;
  action?: React.ReactNode;
  loading?: boolean;
  stats: {
    title: string;
    value: string | React.ReactNode;
    valueIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
    performanceBadge?: {
      value: string;
      direction: "up" | "down";
    };
  }[];
}

export function StatsCardWidget({
  stats,
  loading,
  title,
  action,
}: StatsCardWidgetProps) {
  return (
    <WidgetLayout title={title} action={action}>
      <div className="p-8">
        {loading ? (
          <div className="grid grid-cols-4 gap-8">
            <Skeleton height={67} />
            <Skeleton height={67} />
            <Skeleton height={67} />
            <Skeleton height={67} />
          </div>
        ) : (
          <MetricCardList columns={{ xs: 4 }} stats={stats} />
        )}
      </div>
    </WidgetLayout>
  );
}
