import styles from "./BillHistory.module.scss";
import { Billing, useBillingInvoicesQuery } from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import format from "date-fns/format";
import { titleCase } from "src/lib/titleCase";
import { LegacySectionHeading } from "../../global/components/LegacySectionHeading";
import {
  Table,
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { Download01 } from "@untitled-ui/icons-react";

export function BillHistory({ billing }: { billing: Billing }) {
  const { data, loading, error: _error } = useBillingInvoicesQuery();

  const plan = billing.BillingGrowPlan
    ? billing.BillingGrowPlan
    : billing.BillingEngagePlan;

  const planType = plan.__typename === "BillingEngagePlan" ? "ENGAGE" : "GROW";

  if (loading) {
    return <Spinner height={300} />;
  }

  if (data && data.billingInvoices.length === 0) {
    return (
      <div className={styles.emptyCard}>
        <img src="/imagesAndIcons/EmptyBillHistory.png" alt="emptyBill" />
        <p>No History Yet</p>
      </div>
    );
  }

  return (
    <Table
      isSelectable={false}
      variant={"card"}
      headings={[
        {
          title: "Invoice/Date",
        },
        {
          title: "Status",
        },
        {
          title: "Amount",
        },
        {
          title: "Action",
        },
      ]}
      data={data.billingInvoices}
      idExtractor={(item) => item.id}
      renderItem={({ item }) => (
        <TableRow id={item.id}>
          <TablePrimaryCell
            subtitle={format(new Date(item.createdAt), "MMM dd, yyyy")}
            title={`Macro ${titleCase(planType)}/${
              billing.cycle === "ANNUAL" ? "year" : "mo"
            }`}
          />

          <TableCell>
            <BadgeV2
              label={titleCase(item.status)}
              color={
                item.status === "PAID"
                  ? "green"
                  : item.status === "OPEN"
                  ? "yellow"
                  : "red"
              }
            ></BadgeV2>
          </TableCell>
          <TableCell>{item.totalAmount / 100}</TableCell>

          <TableCell>
            <ButtonV2
              variant="link"
              size="extraSmall"
              to={item.hostedInvoiceUrl}
              rightIcon={Download01}
            >
              View Invoice
            </ButtonV2>
          </TableCell>
        </TableRow>
      )}
    ></Table>
  );
}
