import * as Yup from "yup";

export const clientCreateSchema = Yup.object({
  assetId: Yup.string().nullable(),
  name: Yup.string()
    .trim()
    .min(2, "Client Name should be more than 3 characters")
    .required("Client name is required"),
  industry: Yup.string().required("Industry is required"),
  timeZone: Yup.string()
    .required("Timezone is required")
    .typeError("Timezone is required"),
  currencyCode: Yup.string()
    .required("Currency is required")
    .typeError("Currency is required"),
  users: Yup.array(Yup.string().email("Value must be a user ids")),
  invitedMembers: Yup.array(Yup.string().email("Value must be a valid email")),
});

export const createClientStep1Schema = Yup.object({
  clientCreate: clientCreateSchema,
});
