import { useFormikContext } from "formik";
import { useState } from "react";
import { CheckboxOptionGroup } from "src/components/CheckboxOptionGroup/CheckboxOptionGroup";
import { MetaAdPlacementEnum } from "src/graphql/generated/schema";
import { toggleArray } from "src/lib/toggleArray";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { placementOptions } from "../misc/placementOptions";

export function PlacementSelectorCheckboxGroup({
  placement: placement,
  platform,
  setFormat,
}: {
  placement: any;
  platform: string;
  setFormat: (value: string) => void;
}) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();

  const options = placementOptions.filter(
    (o) => o.category === placement.value && o.platform === platform
  );

  return (
    <CheckboxOptionGroup
      title={placement.label}
      variant={"md"}
      options={options.map((v) => ({
        required: false,
        title: v.label,
        value: v.value,
        checked: values?.adPlacementOptions?.some((s) => s === v.value),
        onHover: () => {
          if (v.category === "STORY_AND_REEL") {
            setFormat(v.value.includes("STORY") ? "STORY" : "REEL");
            return;
          }

          setFormat("FEED");
        },
        onChange: () => {
          setFieldValue(
            "adPlacementOptions",
            toggleArray(values.adPlacementOptions, v.value)
          );
        },
      }))}
      icon={placement.icon}
      onChange={() => {
        const placements = options.flatMap(
          (v) => v.value as MetaAdPlacementEnum
        );

        const hasAnyItemEnabled = values.adPlacementOptions.some((v) =>
          placements.includes(v)
        );
        const newAdPlacementOptions = hasAnyItemEnabled
          ? values.adPlacementOptions.filter((v) => !placements.includes(v))
          : [...values.adPlacementOptions, ...placements];

        setFieldValue("adPlacementOptions", newAdPlacementOptions);
      }}
      isChecked={values?.adPlacementOptions?.some(
        (o) => options.find((p) => p.value === o)?.category === placement.value
      )}
    />
  );
}
