import Colors from "@untitled-ui/icons-react/build/esm/Colors";
import Disc01 from "@untitled-ui/icons-react/build/esm/Disc01";
import Type02 from "@untitled-ui/icons-react/build/esm/Type02";
import AnnotationQuestion from "@untitled-ui/icons-react/build/esm/AnnotationQuestion";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";
import { FieldArray, useFormikContext } from "formik";
import Tooltip from "src/components/Tooltip/Tooltip";
import {
  ICustomQuestionType,
  MetaLeadCreateTypes,
} from "../misc/metaLeadCreateTypes";
import { FormGroupHeaderWithSwitch } from "./FormGroupHeaderWithSwitch";
import styles from "./MetaLeadCustomQuestionFields.module.scss";
import Trash03 from "@untitled-ui/icons-react/build/esm/Trash03";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import Plus from "@untitled-ui/icons-react/build/esm/Plus";
import ChevronUp from "@untitled-ui/icons-react/build/esm/ChevronUp";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import X from "@untitled-ui/icons-react/build/esm/X";

export function MetaLeadCustomQuestionFields() {
  const { values, setFieldValue } = useFormikContext<MetaLeadCreateTypes>();
  const questions = values.customQuestion.questions;

  function handleAddQuestion(questionType: ICustomQuestionType) {
    setFieldValue("customQuestion.questions", [
      ...questions,
      {
        type: questionType,
        order: questions.length,
        label: "",
        ...(questionType === "SELECT_ONE" && { options: [] }),
      },
    ]);
  }

  function handleQuestionDelete(questionIndex: number) {
    const questionWithThisIndex = questions.filter(
      (q, i) => i !== questionIndex,
    );

    setFieldValue("customQuestion.questions", questionWithThisIndex);
  }

  function handleQuestionMove(questionIndex: number, direction: "UP" | "DOWN") {
    if (questionIndex === 0 && direction === "UP") {
      return;
    }

    if (questionIndex === questions.length - 1 && direction === "DOWN") {
      return;
    }

    // shallow copy we don't need deep copy we are only modifying order
    const questionWithNewOrder = [...questions];
    const elToBeMoved = questionWithNewOrder[questionIndex];
    const indexToMoveAt =
      direction === "UP" ? questionIndex - 1 : questionIndex + 1;

    const elementAtThatIndex = questionWithNewOrder[indexToMoveAt];
    questionWithNewOrder[questionIndex] = elementAtThatIndex;
    questionWithNewOrder[indexToMoveAt] = elToBeMoved;

    setFieldValue("customQuestion.questions", questionWithNewOrder);
  }

  return (
    <div className={styles.section}>
      <FormGroupHeaderWithSwitch
        hasSwitch={true}
        switchChecked={values.customQuestion.isEnabled}
        onSwitchChange={(value: boolean) => {
          setFieldValue("customQuestion.isEnabled", value);
        }}
        title="Ask Questions"
        description="Get the info that your business needs."
        icon={AnnotationQuestion}
      />
      {values.customQuestion.isEnabled && (
        <div className={styles.expandedContent}>
          <div className={styles.addQuestion}>
            <div className={styles.questionTypeSelectorLabel}>
              Choose the type of information you need{" "}
              <Tooltip content="Some text">
                <HelpCircle width={14} height={14} />
              </Tooltip>
            </div>

            <div className={styles.questionTypeSelectorList}>
              {questionOptions.map((question) => {
                const Icon = question.icon;

                return (
                  <button
                    onClick={() => handleAddQuestion(question.value)}
                    type="button"
                    className={styles.questionTypeSelector}
                  >
                    <div className={styles.questionTypeSelectorLeftSection}>
                      <Icon
                        width={12}
                        height={12}
                        color="var(--color-disabled)"
                      />
                      <span>{question.label}</span>
                    </div>
                    <span className={styles.questionTypeSelectorRightSection}>
                      Add
                    </span>
                  </button>
                );
              })}
            </div>
          </div>

          <div className={styles.questionsList}>
            {questions.map((question, questionIndex) => {
              const questionOption = questionOptions.find(
                (q) => q.value === question.type,
              );
              const Icon = questionOption.icon;

              return (
                <div className={styles.questionOption}>
                  <div className={styles.questionHeader}>
                    <div className={styles.questionHeaderLeftSection}>
                      <Icon width={14} height={14} />
                      <div>{questionOption.label}</div>
                    </div>

                    <div className={styles.questionHeaderRightSection}>
                      <div className={styles.navigationButtons}>
                        <button
                          type="button"
                          onClick={() => {
                            handleQuestionMove(questionIndex, "UP");
                          }}
                        >
                          <ChevronUp width={16} height={16} />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            handleQuestionMove(questionIndex, "DOWN");
                          }}
                        >
                          <ChevronDown width={16} height={16} />
                        </button>
                      </div>

                      <button
                        className={styles.deleteButton}
                        onClick={() => {
                          handleQuestionDelete(questionIndex);
                        }}
                        type="button"
                      >
                        <Trash03 width={16} height={16} />
                      </button>
                    </div>
                  </div>
                  <div className={styles.questionBody}>
                    <InputFormikV2
                      name={`customQuestion.questions[${questionIndex}].label`}
                      helpText="Write a good label for your question so that it's clear what information you need"
                      label="Your question"
                    />
                    {question.type === "SELECT_ONE" && (
                      <FieldArray
                        name={`customQuestion.questions[${questionIndex}].options`}
                      >
                        {(arrayHelpers) => (
                          <div className={styles.answers}>
                            <div className={styles.answerList}>
                              {question.options.map((_option, optionIndex) => (
                                <div className={styles.answerOption}>
                                  <InputFormikV2
                                    name={`customQuestion.questions[${questionIndex}].options[${optionIndex}]`}
                                    placeholder="Enter an option"
                                  />
                                  <button
                                    onClick={() => {
                                      arrayHelpers.remove(optionIndex);
                                    }}
                                    type="button"
                                  >
                                    <X width={20} height={20} />
                                  </button>
                                </div>
                              ))}
                            </div>

                            <div className={styles.addOptionButtonWrapper}>
                              <ButtonV2
                                onClick={() => {
                                  arrayHelpers.push("");
                                }}
                                rightIcon={Plus}
                                size="extraSmall"
                                variant="link"
                              >
                                Add Option
                              </ButtonV2>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

const questionOptions = [
  {
    label: "Yes / No only",
    value: "YES_OR_NO" as ICustomQuestionType,
    icon: Disc01,
  },
  {
    label: "Multiple choice",
    value: "SELECT_ONE" as ICustomQuestionType,
    icon: Colors,
  },
  {
    label: "Short Answer",
    value: "TEXT" as ICustomQuestionType,
    icon: Type02,
  },
];
