import React from "react";
import styles from "./SplitCreateCard.module.scss";
import { SplitCard } from "../SplitCard/SplitCard";
import classNames from "classnames";

interface ISplitCreateCard {
  children: React.ReactNode;
  variant?: "inline" | "modal";
  image: { src: string; alt: string; width: number; height: number };
}

export function SplitCreateCard({
  children,
  variant = "inline",
  image,
}: ISplitCreateCard) {
  const firstColumn = children;

  const secondColumn = (
    <div className={styles.imageContainer}>
      <img
        className={styles.image}
        src={image.src}
        alt={image.alt}
        width={image.width}
        height={image.height}
      />
    </div>
  );

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.modal]: variant === "modal",
      })}
    >
      <SplitCard
        noPaddingOnSecondColumn
        firstColumn={firstColumn}
        secondColumn={secondColumn}
      />
    </div>
  );
}
