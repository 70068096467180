import { Table } from "src/components/Table/Table";
import { CreativeTemplatesQuery } from "../../../graphql/generated/schema";
import { CreativeListingItem } from "./CreativeListingItem";

interface ICreativeListingTable {
  creativeTemplates: CreativeTemplatesQuery["creativeTemplates"]["edges"];
  setExpandedTemplateId: (i: string) => void;
  setSelectedTemplateId: (i: string[]) => void;
  selectedItems: string[];
  duplicateAdTemplate: (i: string) => void;
  editAdTemplate: (i: string) => void;
  deleteAdTemplate: (i: string) => void;
}

export function CreativeListingTable({
  creativeTemplates,
  duplicateAdTemplate,
  editAdTemplate,
  setExpandedTemplateId,
  setSelectedTemplateId,
  selectedItems,
  deleteAdTemplate,
}: ICreativeListingTable) {
  return (
    <div>
      <Table
        headings={[
          { title: "Creatives" },
          { title: "Status" },
          { title: "Times Used" },
          { title: "Type" },
          { title: "" },
        ]}
        isSelectable={true}
        onSelectedItemsChange={setSelectedTemplateId}
        selectedItems={selectedItems}
        data={creativeTemplates}
        idExtractor={(item) => item.node.id}
        renderItem={({ item }) => (
          <CreativeListingItem
            setExpandedTemplateId={setExpandedTemplateId}
            creative={item.node}
            duplicateAdTemplate={duplicateAdTemplate}
            editAdTemplate={editAdTemplate}
            deleteAdTemplate={deleteAdTemplate}
          />
        )}
      />
    </div>
  );
}
