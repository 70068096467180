import { useFormikContext } from "formik";
import { Spinner } from "src/components/Spinner/Spinner";
import { useGoogleListClientAccountsQuery } from "src/graphql/generated/schema";
import { Text } from "src/components/Text/Text";
import { FormikError } from "src/components/FormikError/FormikError";
import { GoogleClientAccountItemToggle } from "./GoogleClientAccountItemToggle";
import { TitleWithThumbnail } from "src/modules/global/components/TitleWithThumbnail";

export function GoogleClientAccountsList({
  managerAccount,
}: {
  managerAccount: any;
}) {
  const { values, setFieldValue } = useFormikContext<any>();
  const { data, loading, error } = useGoogleListClientAccountsQuery({
    variables: {
      googleManagerAccountId: managerAccount.id,
    },
  });

  if (loading) {
    return (
      <div>
        <Spinner height={"full"} />
      </div>
    );
  }

  const selectedAccountIndex = values?.accounts?.findIndex(
    (v) => v.id === managerAccount.id,
  );
  const selectedAccount = values?.accounts?.[selectedAccountIndex];
  return (
    <div className="border rounded-12 px-16 py-14 flex flex-col gap-24">
      <TitleWithThumbnail
        thumbnail={{
          contentType: "IMAGE",
          uri: `https://api.dicebear.com/7.x/shapes/svg?seed=${managerAccount.name}`,
        }}
        name={managerAccount.name}
        descriptionItems={["Manager Account"]}
      />
      <div className="flex flex-col gap-12">
        {data?.googleListClientAccounts.map((clientAccount) => (
          <GoogleClientAccountItemToggle
            key={clientAccount.id}
            account={clientAccount}
            selectedClientAccounts={selectedAccount?.clientAccounts || []}
            setSelectedClientAccounts={(accounts) => {
              setFieldValue(
                `accounts[${selectedAccountIndex}].clientAccounts`,
                accounts,
              );
            }}
          />
        ))}
      </div>
    </div>
  );
}
