import { useLayoutEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { useCustomersLazyQuery } from "src/graphql/generated/schema";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import { getDateFilterFromFilterEnum } from "src/modules/global/misc/dateFilterUtils";
import { CustomersEmpty } from "./CustomersEmpty";
import { CustomersTable } from "./CustomerTable";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { Searchbar } from "src/components/Searchbar/Searchbar";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import { LayersTwo02, User03 } from "@untitled-ui/icons-react";
import defaultSortOptions from "src/modules/global/misc/defaultSortOptions";
import { CustomerSegmentEditor } from "./CustomerSegmentEditor";

const tabOptions = [
  {
    icon: User03,
    label: "Contacts",
    value: "CUSTOMERS",
  },
  {
    icon: LayersTwo02,
    label: "Segments",
    value: "SEGMENTS",
  },
];

export function CustomersTableLoader({
  filters,
  setFilters,
  pagination,
  selectedCustomerIds,
  setSelectedCustomerIds,
  sortBy,
  setSortBy,
  showAddCustomerModal,
  setShowAddCustomerModal,
  selectedTab,
  setSelectedTab,
}) {
  // const navigate = useNavigate();
  // const {
  //   data: integrationsStatusData,
  //   loading: integrationsStatusLoading,
  //   error: integrationsStatusError,
  // } = useIntegrationsStatusQuery();

  const searchQueryDebounced = useDebouncedVariable(filters.searchQuery, 500);

  const [customersQueryFn, { data, error, loading, called, fetchMore }] =
    useCustomersLazyQuery();

  const areFiltersApplied = useMemo(
    () => filters.dateFilter !== "ALL_TIME" || filters.searchQuery !== "",
    [filters],
  );

  // useEffect(() => {
  //   if (!data) {
  //     return;
  //   }

  //   if (!integrationsStatusData) {
  //     return;
  //   }

  //   if (
  //     data.customers.edges.length === 0 &&
  //     integrationsStatusData.integrationsStatus.shopify === "INACTIVE" &&
  //     !areFiltersApplied
  //   ) {
  //     navigate("/contacts/create");
  //   }
  // }, [integrationsStatusData, data, areFiltersApplied, navigate]);

  useLayoutEffect(() => {
    customersQueryFn({
      variables: {
        input: {
          take: pagination.take,
          // ...(pagination.cursor && { cursor: pagination.cursor,
          // }),
          sortBy: {
            direction: sortBy.direction,
            field: sortBy.field,
          },
          filters: {
            createdAt: getDateFilterFromFilterEnum(filters.dateFilter),
            name: searchQueryDebounced,
            // createdAfter:
          },
        },
      },
    });
  }, [
    customersQueryFn,
    filters.dateFilter,
    searchQueryDebounced,
    sortBy.direction,
    sortBy.field,
    pagination.take,
  ]);

  async function fetchMoreCustomers() {
    await fetchMore({
      variables: {
        input: {
          cursor: data.customers.pageInfo.endCursor,
          take: pagination.take,
          sortBy: {
            direction: sortBy.direction,
            field: sortBy.field,
          },
          filters: {
            createdAt: getDateFilterFromFilterEnum(filters.dateFilter),
            name: searchQueryDebounced,
            // createdAfter:
          },
        },
      },

      // concatenate old and new entries
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges = fetchMoreResult?.customers?.edges ?? [];
        const oldEdges = previousResult?.customers?.edges ?? [];

        return {
          customers: {
            ...fetchMoreResult.customers,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  if (loading || !called) {
    return <Spinner height={"screen"} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  // when filters are applied that screen is shown with filters
  if (data?.customers?.edges.length === 0) {
    return <CustomersEmpty />;
  }

  return (
    <>
      <div className="flex justify-between px-24 pb-16">
        <TabsV2
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          tabs={tabOptions}
        />
        <div className="flex gap-4">
          <Searchbar onChange={() => {}} value="" />
          <SortByDropdown
            setSortBy={() => {}}
            sortBy={{ direction: "DESC", field: "CREATED_AT" }}
            sortOptions={defaultSortOptions}
          />
        </div>
      </div>

      {/* <CustomerSegmentEditor /> */}

      <InfiniteScroll
        next={fetchMoreCustomers}
        hasMore={data?.customers?.pageInfo?.hasNextPage}
        loader={<Spinner height={200} />}
        dataLength={data?.customers?.edges?.length || 0}
      >
        <CustomersTable
          selectedCustomerIds={selectedCustomerIds}
          setSelectedCustomerIds={setSelectedCustomerIds}
          customers={data?.customers?.edges || []}
          filters={filters}
          sortBy={sortBy}
          // setPagination={function (i: { cursor: any; take: number }): void {
          //   throw new Error("Function not implemented.");
          // }}
          // paginationPayload={{
          //   __typename: "Pagination",
          //   endCursor: "",
          //   hasNextPage: false,
          //   hasPreviousPage: false,
          //   itemsPerPage: 0,
          //   startCursor: "",
          //   totalItems: 0,
          // }}
          // setFilters={setFIlters}
          // setSortBy={setSortBy}
          // showPagination={false}
        />
      </InfiniteScroll>
    </>
  );
}
