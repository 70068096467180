import { useEffect } from "react";
import { LineItem } from "src/lib/types";
import { Asset } from "./Asset";
import { useKeenSlider } from "keen-slider/react";
import { KeenSliderHooks, KeenSliderInstance } from "keen-slider";
import classNames from "classnames";
import styles from "./AssetSlider.module.scss";
import ArrowLeft from "@material-symbols/svg-600/rounded/keyboard_arrow_left.svg?react";
import ArrowRight from "@material-symbols/svg-600/rounded/keyboard_arrow_right.svg?react";

export function AssetSlider({
  preview,
  mode = "post",
  onSlideChange,
  activeSlide,
  assetClass,
  containerClass,
  showArrows = false,
}: {
  preview: LineItem[] | LineItem;
  mode: "post" | "story";
  onSlideChange?: (slider: KeenSliderInstance<{}, {}, KeenSliderHooks>) => void;
  assetClass?: string;
  containerClass?: string;
  activeSlide?: number;
  showArrows?: boolean;
}) {
  const [sliderRef, instanceRef] = useKeenSlider({
    slideChanged: onSlideChange,
    initial: activeSlide,
  });

  // to manual trigger update of slider if previews change
  useEffect(() => {
    if (!instanceRef) return;
    instanceRef.current.update();
  }, [preview, instanceRef]);

  return (
    <div className={classNames("keen-slider")} ref={sliderRef}>
      {Array.isArray(preview) && preview.length > 0 ? (
        preview.map((v, idx) => (
          <div
            key={idx}
            className={classNames("keen-slider__slide", styles.slide)}
          >
            <Asset
              type={
                mode === "post"
                  ? v?.PostAsset?.contentType
                  : v?.StoryAsset?.contentType
              }
              preview={mode === "post" ? v?.PostAsset?.uri : v?.StoryAsset?.uri}
              name={mode === "post" ? v?.PostAsset?.name : v?.StoryAsset?.name}
              className={assetClass}
            />
            {showArrows && (
              <>
                <button
                  className={styles.arrowLeft}
                  disabled={activeSlide === 0}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    instanceRef.current.prev();
                  }}
                >
                  <ArrowLeft className={styles.arrowIco} />
                </button>
                <button
                  className={styles.arrowRight}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    instanceRef.current.next();
                  }}
                  disabled={activeSlide === preview.length - 1}
                >
                  <ArrowRight className={styles.arrowIco} />
                </button>
              </>
            )}
          </div>
        ))
      ) : (
        <div className="keen-slider__slide">
          <Asset
            type={
              mode === "post"
                ? (preview as LineItem).PostAsset?.contentType
                : (preview as LineItem).StoryAsset?.contentType
            }
            preview={
              mode === "post"
                ? (preview as LineItem).PostAsset?.uri
                : (preview as LineItem).StoryAsset?.uri
            }
            name={
              mode === "post"
                ? (preview as LineItem).PostAsset?.name
                : (preview as LineItem).StoryAsset?.name
            }
            className={assetClass}
          />
        </div>
      )}
    </div>
  );
}
