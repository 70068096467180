import classNames from "classnames";
import { InputHTMLAttributes, ReactNode } from "react";
import styles from "./Radio.module.scss";

// because our onChange is incompatible
// with the native implementation
type AllAttributesExceptOnChange = Omit<
  InputHTMLAttributes<unknown>,
  "onChange"
>;

export interface IRadio extends AllAttributesExceptOnChange {
  value: string;
  variant?: "small" | "large" | "medium";
  label?: string | ReactNode;
  name?: string;
  checked: boolean;
  onChange: (newChecked: boolean) => void;
}

export function Radio(props: IRadio) {
  const {
    label,
    name,
    value,
    checked,
    onChange,
    disabled,
    variant = "medium",
    ...otherProps
  } = props;

  return (
    <label
      className={classNames(styles.radioContainer, {
        [styles.disabled]: disabled,
        [styles.small]: variant === "small",
        [styles.large]: variant === "large",
        [styles.medium]: variant === "medium",
      })}
    >
      <input
        {...otherProps}
        className={styles.input}
        type="radio"
        value={value}
        name={name}
        checked={!!checked}
        onChange={() => onChange(!checked)}
        disabled={disabled}
      />
      <span className={styles.radioCheckmark}></span>
      {!!label && <span className={styles.label}>{label}</span>}
    </label>
  );
}
