import {
  Activity,
  ChartBreakoutCircle,
  CoinsHand,
  CoinsStacked04,
  CursorBox,
  CursorClick01,
  Eye,
  File04,
  File06,
  Film01,
  Globe02,
  Inbox01,
  LayoutGrid01,
  Link02,
  PackageCheck,
  PieChart01,
  Play,
  ShoppingBag01,
  ShoppingBag02,
  ShoppingBag03,
  ThumbsUp,
  Users02,
  UsersPlus,
} from "@untitled-ui/icons-react";
import { GrowAggregateType } from "../misc/GrowAggregateType";
import Library from "src/icons/library.svg?react";

export function getGrowAggregateStatsByType({
  type,
}: {
  type:
    | "TRANSACTIONAL"
    | "ENGAGEMENT"
    | "AWARENESS"
    | "TRAFFIC"
    | "LEADS"
    | "OVERVIEW";
}): {
  title: string;
  valueIcon?: React.FC<React.SVGAttributes<SVGElement>>;
  valueKey:
    | keyof GrowAggregateType
    | "activitiesCount"
    | "audiencesCount"
    | "creativesCount"
    | "segmentsCount"
    | "not_tracked";
  valueType: "NUMBER" | "CURRENCY" | "PERCENTAGE" | "MULTIPLIER";
}[] {
  if (type === "OVERVIEW") {
    return [
      {
        title: "Activity Count",
        valueKey: "activitiesCount" as const,
        valueType: "NUMBER" as const,
        valueIcon: LayoutGrid01,
      },
      {
        title: "Total Spends",
        valueKey: "spend",
        valueType: "CURRENCY" as const,
        valueIcon: CoinsStacked04,
      },
      {
        title: "Audiences Count",
        valueKey: "audiencesCount" as const,
        valueType: "NUMBER" as const,
        valueIcon: Users02,
      },
      {
        title: "Segments Count",
        valueKey: "segmentsCount" as const,
        valueType: "NUMBER" as const,
        valueIcon: PieChart01,
      },
      {
        title: "Creatives Count",
        valueKey: "creativesCount" as const,
        valueType: "NUMBER" as const,
        valueIcon: Library,
      },
    ];
  }
  if (type === "TRANSACTIONAL") {
    return [
      {
        title: "Total Sales",
        valueIcon: CoinsStacked04,
        valueKey: "totalValuePurchase",
        valueType: "CURRENCY" as const,
      },
      {
        title: "Total Orders",
        valueIcon: ShoppingBag03,
        valueKey: "countPurchase",
        valueType: "NUMBER" as const,
      },
      {
        title: "Add to Carts",
        valueIcon: ShoppingBag01,
        valueKey: "countAddToCart",
        valueType: "NUMBER" as const,
      },
      {
        title: "Initiate Checkouts",
        valueIcon: PackageCheck,
        valueKey: "countInitiateCheckout",
        valueType: "NUMBER" as const,
      },
      {
        title: "Abandoned Checkouts",
        valueIcon: ShoppingBag02,
        valueKey: "countInitiateCheckout",
        valueType: "NUMBER" as const,
      },
    ];
  } else if (type === "ENGAGEMENT") {
    return [
      {
        title: "DMs/Inbox Counts",
        valueIcon: Inbox01,
        valueKey: "messagingConversationStarted7d",
        valueType: "NUMBER" as const,
      },
      {
        title: "Engagement Count",
        valueIcon: ThumbsUp,
        valueKey: "countPostEngagement",
        valueType: "NUMBER" as const,
      },
      {
        title: "Click Through Rate",
        valueIcon: CursorClick01,
        valueKey: "ctr",
        valueType: "PERCENTAGE" as const,
      },
      {
        title: "Video Thru Plays",
        valueIcon: Play,
        valueKey: "videoThruplayWatched",
        valueType: "NUMBER" as const,
      },
      {
        title: "Video 3s Views",
        valueIcon: Film01,
        valueKey: "countVideoView",
        valueType: "NUMBER" as const,
      },
    ];
  }

  if (type === "AWARENESS") {
    return [
      {
        title: "Reach",
        valueIcon: ChartBreakoutCircle,
        valueKey: "reach",
        valueType: "NUMBER" as const,
      },
      {
        title: "Impressions",
        valueIcon: Eye,
        valueKey: "impressions",
        valueType: "NUMBER" as const,
      },
      {
        valueIcon: CursorBox,
        title: "Clicks",
        valueKey: "clicks",
        valueType: "NUMBER" as const,
      },
      {
        valueIcon: CursorClick01,
        title: "CTR",
        valueKey: "ctr",
        valueType: "PERCENTAGE" as const,
      },
      {
        valueIcon: Activity,
        title: "Frequency",
        valueKey: "frequency",
        valueType: "NUMBER" as const,
      },
    ];
  }

  if (type === "TRAFFIC") {
    return [
      {
        valueIcon: Globe02,
        title: "Website Visits",
        valueKey: "countLandingPageView",
        valueType: "NUMBER" as const,
      },
      {
        valueIcon: Link02,
        title: "Link Clicks",
        valueKey: "countLinkClick",
        valueType: "NUMBER" as const,
      },
      {
        valueIcon: Users02,
        title: "Profile Visits",
        valueKey: "not_tracked",
        // not tracked yet
        valueType: "NUMBER" as const,
      },
      {
        valueIcon: Eye,
        title: "Product Page Views",
        valueKey: "countViewContent",
        valueType: "NUMBER" as const,
      },
      {
        valueIcon: CoinsHand,
        title: "Cost Per Click",
        valueKey: "cpc",
        valueType: "NUMBER" as const,
      },
    ];
  }

  // TODO: Fix this
  if (type === "LEADS") {
    return [
      {
        valueIcon: UsersPlus,
        title: "Customers Added",
        valueKey: "not_tracked",
        valueType: "NUMBER" as const,
      },
      {
        valueIcon: File04,
        title: "Signups/Registrations",
        valueKey: "not_tracked",
        valueType: "NUMBER" as const,
      },
      //   {
      //     title: "DMs/Inbox Counts",
      //     valueKey: "not_tracked",
      //     // not tracked yet
      //     value: insights?.messagingConversationStarted7d,
      //     valueType: "NUMBER" as const,
      //   },
      {
        valueIcon: File06,
        title: "Form Submissions",
        valueKey: "not_tracked",
        valueType: "NUMBER" as const,
      },
    ];
  }
}
