import styles from "./CreativeMetaFields.module.scss";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { LineItemInput } from "./LineItemInput";
import { AddMedia } from "./AdTemplateCreateSlideOver/AddMedia";
import { getIn, setIn, useFormikContext } from "formik";
import {
  MetaLineItemFields,
  CreativeCreateTypesV2,
} from "../misc/creativeCreateTypesV2";
import { useState } from "react";
import { SelectButton } from "src/components/SelectButton/SelectButton";
import { ToggleButton } from "src/modules/global/components/ToggleButton";
import Image01 from "@untitled-ui/icons-react/build/esm/Image01";
import Expand06 from "@untitled-ui/icons-react/build/esm/Expand06";
import ImageIndentLeft from "@untitled-ui/icons-react/build/esm/ImageIndentLeft";
import Image04 from "@untitled-ui/icons-react/build/esm/Image04";
import LayoutBottom from "@untitled-ui/icons-react/build/esm/LayoutBottom";
import Rows02 from "@untitled-ui/icons-react/build/esm/Rows02";
import ShoppingBag03 from "@untitled-ui/icons-react/build/esm/ShoppingBag03";
import { FileInputV2 } from "src/modules/global/components/FileInputV2";
import { ExistingPostSelector } from "./ExistingPostSelector";
import { ExistingPostCard } from "./ExistingPostCard";
import { MetaCatalogueSelector } from "src/modules/global/components/MetaCatalogueSelector";
import { MetaCatalogueProductSetSelector } from "src/modules/global/components/MetaCatalogueProductSetSelector";
import Plus from "@untitled-ui/icons-react/build/esm/Plus";
import { DropdownV2 } from "src/modules/campaign/components/DropdownV2";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { CreativeMetaAdvanceOptions } from "./CreativeMetaAdvanceOptions";
import { FormikError } from "src/components/FormikError/FormikError";
import {
  metaPlacementOptions,
  MetaPlacementOptionValue,
} from "../misc/metaPlacementOptions";

interface ICreativeMetaFields {
  setActiveAssetIndex: (i: number) => void;
  activeAssetIndex: number;
  metaSelectedPlacement: MetaPlacementOptionValue;
  setMetaSelectedPlacement: (i: MetaPlacementOptionValue) => void;
}

export function CreativeMetaFields({
  activeAssetIndex,
  setActiveAssetIndex,
  metaSelectedPlacement,
  setMetaSelectedPlacement,
}: ICreativeMetaFields) {
  const { values, errors, touched, submitCount, setFieldValue } =
    useFormikContext<CreativeCreateTypesV2>();
  const [isExistingPostSelectorOpen, setIsExistingPostSelectorOpen] =
    useState(false);
  const [selectedLineItemMode, setSelectedLineItemMode] = useState(
    lineItemModeOptions[0].value,
  );
  const adFields = values.meta.edited ? values.meta : values.global;

  // this clones the global object and mark the metaAd as edited
  function setField(
    fieldNameOrObj: string | Record<string, any>,
    fieldValue?: any,
  ) {
    // clone from global if no edited object
    const metaCreative = values.meta.edited
      ? values.meta
      : // type is the only field which is specific to meta and not in global
        {
          type: values.meta.type,
          crop: values.meta.crop,
          ...structuredClone(values.global),
        };

    let updatedMetaAd = metaCreative;

    if (typeof fieldNameOrObj === "string") {
      updatedMetaAd = setIn(updatedMetaAd, fieldNameOrObj, fieldValue);
    } else {
      for (let [key, value] of Object.entries(fieldNameOrObj)) {
        updatedMetaAd = setIn(updatedMetaAd, key, value);
      }
    }

    updatedMetaAd = setIn(updatedMetaAd, "edited", true);

    setFieldValue("meta", updatedMetaAd);
  }

  function handleAddVariable(fieldKey: string, value: string) {
    let existingValue = getIn(adFields, fieldKey) ?? "";

    if (existingValue.length) {
      // add a space before the variable
      existingValue = existingValue + " ";
    }

    setField(fieldKey, existingValue + value);
  }

  const cataloguePrimaryTextVariableOptions = productVariableOptions.map(
    (variableOption) => ({
      label: variableOption.label,
      onClick() {
        handleAddVariable("primaryText", variableOption.value);
      },
    }),
  );

  const catalogueAdTitleVariableOptions = productVariableOptions.map(
    (variableOption) => ({
      label: variableOption.label,
      onClick() {
        handleAddVariable("LineItems[0].headline", variableOption.value);
      },
    }),
  );

  const catalogueAdDescriptionVariableOptions = productVariableOptions.map(
    (variableOption) => ({
      label: variableOption.label,
      onClick() {
        handleAddVariable("LineItems[0].description", variableOption.value);
      },
    }),
  );

  const isExistingPostCreative = values.meta.type === "EXISTING_POST";

  const isCatalogueCreative = values.meta.type === "CATALOGUE_AD";

  const isCustomCreative = !isExistingPostCreative && !isCatalogueCreative;

  return (
    <>
      <SelectV2
        label="Ad Type"
        onChange={(value) => {
          setField("type", value);
        }}
        value={values.meta.type ?? "SINGLE_IMAGE_OR_VIDEO"}
        options={adTypeOptions}
      />

      {isCustomCreative && (
        <AddMedia
          onSelect={(assets) => {
            const lineItems = adFields.LineItems;

            if (assets.length > 1) {
              const newLineItems = assets.map<MetaLineItemFields>(
                (asset, index) => ({
                  order: lineItems.length + index,
                  PostAsset: asset,
                }),
              );
              const updatedLineItems = lineItems.concat(newLineItems);

              setField({
                type: "CAROUSEL",
                LineItems: updatedLineItems,
              });
            } else {
              const updatedLineItems = lineItems.concat({
                order: lineItems.length + 1,
                PostAsset: assets[0],
              });

              setField(`LineItems`, updatedLineItems);
            }
          }}
          multiple={true}
        />
      )}

      {isExistingPostCreative && (
        <>
          {values.meta.existingPostId ? (
            <ExistingPostCard
              onDelete={() => {
                setField("existingPost", null);
                setField("existingPostId", null);
              }}
              onReplace={() => {
                setIsExistingPostSelectorOpen(true);
              }}
            />
          ) : (
            <ExistingPostSelector
              isOpen={isExistingPostSelectorOpen}
              onClose={() => {
                setIsExistingPostSelectorOpen(false);
              }}
              trigger={
                <FileInputV2
                  icon={Image04}
                  onClick={() => {
                    setIsExistingPostSelectorOpen(true);
                  }}
                  title="Select Post"
                />
              }
              selectedPost={values.meta.existingPost}
              setSelectedPost={(value) => {
                setField({
                  existingPost: value,
                  existingPostId: value.id,
                  primaryText: value.caption,
                });
              }}
            />
          )}
        </>
      )}

      {isCatalogueCreative && (
        <div className={styles.catalogueFieldsWrapper}>
          <MetaCatalogueSelector
            placeholder="Select a Catalogue"
            emptyState={{
              to: "/settings/integrations/meta",
              title: "No Catalogue found",
              description: "Please enable a catalogue to proceed",
            }}
            value={values.meta.catalogueId}
            onChange={(catalogue) => {
              setField("catalogueId", catalogue.id);
            }}
          />

          <MetaCatalogueProductSetSelector
            disabled={!values.meta.catalogueId}
            catalogueId={values.meta.catalogueId}
            value={values.meta.productSetId}
            onChange={(productSet) => {
              setField("productSetId", productSet.id);
            }}
          />
        </div>
      )}

      {isCustomCreative && adFields.LineItems.length > 0 && (
        <div className={styles.lineItemsWrapper}>
          <div className={styles.lineItemActions}>
            <SelectButton
              showValueOnLabel
              label="Placement"
              options={metaPlacementOptions}
              value={metaSelectedPlacement}
              onChange={(value) => {
                setMetaSelectedPlacement(value as MetaPlacementOptionValue);
              }}
            />
            <div className={styles.multipleActionsWrapper}>
              <ToggleButton
                options={lineItemModeOptions}
                onChange={setSelectedLineItemMode}
                value={selectedLineItemMode}
              />
              <SelectButton
                label="Crop"
                showValueOnLabel
                options={cropOptions}
                value={values.meta.crop}
                onChange={(value) => {
                  setField("crop", value);
                }}
              />
            </div>
          </div>

          <div className={styles.lineItems}>
            {adFields.LineItems.map((lineItem, lineItemIndex) => (
              <LineItemInput
                placement={
                  metaSelectedPlacement === "META_STORY" ? "STORY" : "POST"
                }
                lineItemIndex={lineItemIndex}
                activeAssetIndex={activeAssetIndex}
                setActiveAssetIndex={setActiveAssetIndex}
                onReplace={(value) => {
                  const updatedLineItems = structuredClone(values.meta);

                  updatedLineItems.LineItems[lineItemIndex] = {
                    ...updatedLineItems.LineItems[lineItemIndex],
                    ...(metaSelectedPlacement === "META_STORY"
                      ? { StoryAsset: value }
                      : { PostAsset: value }),
                  };

                  setField({
                    LineItems: updatedLineItems.LineItems,
                  });
                }}
                setFieldValue={(name, value) => {
                  setField(name, value);
                }}
                shouldShowAdvancedFields={
                  selectedLineItemMode === "ADVANCE_FIELDS"
                }
                onRemove={() => {
                  setField(
                    `LineItems`,
                    values.meta.LineItems.filter(
                      (_a, index) => index !== lineItemIndex,
                    ),
                  );
                }}
                baseKey={`LineItems[${lineItemIndex}]`}
                lineItem={lineItem}
              />
            ))}
          </div>

          <div className="py-4">
            {errors?.meta?.LineItems && (
              <FormikError
                formikError={errors}
                submitCount={submitCount}
                touched={touched}
                fieldName="meta.LineItems"
              />
            )}
          </div>
        </div>
      )}

      <MultilineInput
        disabled={isExistingPostCreative}
        label="Primary Text"
        placeholder="Type your content here...."
        showCharacterCount
        maxCharacterCount={500}
        showEmojiPicker
        onEmojiPick={(value) => {
          setField("primaryText", adFields.primaryText + value.emoji);
        }}
        floatingAction={
          isCatalogueCreative && (
            <DropdownV2
              size="small"
              icon={Plus}
              buttonVariant="outline"
              hideArrow
              options={cataloguePrimaryTextVariableOptions}
            />
          )
        }
        rows={3}
        value={adFields.primaryText}
        onChange={(value) => {
          setField("primaryText", value);
        }}
      />

      {isCatalogueCreative && (
        <>
          <InputFormikV2
            placeholder="Add title to your ad"
            name="meta.LineItems[0].headline"
            label="Ad title (optional)"
            showCharacterCount
            maxCharacterCount={40}
            action={
              isCatalogueCreative && (
                <DropdownV2
                  size="extraSmall"
                  icon={Plus}
                  buttonVariant="outline"
                  hideArrow
                  options={catalogueAdTitleVariableOptions}
                />
              )
            }
            helpText="Use this field to showcase any product specific detail like name or price"
          />
          <InputFormikV2
            placeholder="Add description to your ad"
            name="meta.LineItems[0].description"
            label="Ad description (optional)"
            helpText="Use this field to showcase any product specific detail like name or price"
            action={
              isCatalogueCreative && (
                <DropdownV2
                  size="extraSmall"
                  icon={Plus}
                  buttonVariant="outline"
                  hideArrow
                  options={catalogueAdDescriptionVariableOptions}
                />
              )
            }
          />
        </>
      )}

      <CreativeMetaAdvanceOptions values={values} setField={setField} />
    </>
  );
}

const adTypeOptions = [
  {
    label: "Single Image or Video",
    value: "SINGLE_IMAGE_OR_VIDEO",
    icon: LayoutBottom,
  },
  {
    label: "Carousel",
    value: "CAROUSEL",
    icon: Rows02,
  },
  {
    label: "Existing Post",
    value: "EXISTING_POST",
    icon: Image04,
  },
  {
    label: "Catalogue Ad",
    value: "CATALOGUE_AD",
    icon: ShoppingBag03,
  },
];

const cropOptions = [
  {
    label: "Default Fit",
    value: "DEFAULT",
    icon: Image01,
  },
  {
    label: "Auto Fit",
    value: "COVER",
    icon: Expand06,
  },
];

const lineItemModeOptions: [
  // its a tuple not an array
  { icon: React.FC<any>; value: string },
  { icon: React.FC<any>; value: string },
] = [
  {
    icon: Image01,
    value: "STANDARD_FIELDS",
  },
  {
    icon: ImageIndentLeft,
    value: "ADVANCE_FIELDS",
  },
];

const productVariableOptions = [
  {
    label: "Name",
    value: "{{product.name}}",
  },
  {
    label: "Price",
    value: "{{product.price}}",
  },
  {
    label: "Description",
    value: "{{product.description}}",
  },
  {
    label: "Short Description",
    value: "{{product.short_description}}",
  },
  {
    label: "Brand",
    value: "{{product.brand}}",
  },
];
