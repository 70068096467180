import classNames from "classnames";
import { InputHTMLAttributes, ReactNode } from "react";
import styles from "./Checkbox.module.scss";

// because our onChange is incompatible
// with the native implementation
type AllAttributesExceptOnChange = Omit<
  InputHTMLAttributes<unknown>,
  "onChange" | "size" | "value"
>;

export interface ICheckbox<T> extends AllAttributesExceptOnChange {
  value: T;
  size?: "small" | "medium" | "large";
  label?: ReactNode;
  name?: string;
  checked: boolean;
  // by default, the checkbox will stop propagation
  doNotStopPropagation?: boolean;
  onChange?: (newChecked: boolean) => void;
}

export function Checkbox<T>(props: ICheckbox<T>) {
  const {
    label,
    name,
    value,
    checked,
    doNotStopPropagation = false,
    disabled,
    onChange,
    size = "medium",
    ...otherProps
  } = props;

  const iconWidth = size === "large" ? 14 : size === "medium" ? 10 : 8;
  const iconHeight = size === "large" ? 11.67 : size === "medium" ? 12 : 6.67;

  return (
    <label
      onClick={(e) => {
        if (!doNotStopPropagation) {
          e.stopPropagation();
        }
      }}
      className={classNames(styles.checkboxWrapper, {
        [styles.isChecked]: checked,
      })}
    >
      <input
        {...otherProps}
        className={styles.hiddenCheckbox}
        type="checkbox"
        disabled={disabled}
        // value={value}
        name={name}
        checked={checked}
        onChange={() => {
          if (onChange) {
            onChange(!checked);
          }
        }}
      />
      <span
        className={classNames(styles.fakeCheckboxWrapper, {
          [styles.wrapperSizeDefault]: size === "medium",
          [styles.wrapperSizeSmall]: size === "small",
          [styles.wrapperSizeLarge]: size === "large",
          [styles.disabledCheckboxWrapper]: disabled,
        })}
      >
        <svg
          className={classNames(styles.fakeCheckbox)}
          width={iconWidth}
          height={iconHeight}
          viewBox="0 0 12 10"
        >
          <polyline
            points="1.5 6 4.5 9 10.5 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          ></polyline>
        </svg>
      </span>
      {label && <span className={styles.labelText}>{label}</span>}
    </label>
  );
}
