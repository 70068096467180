import { ActivityStatusEnum } from "src/graphql/generated/schema";
import { useMemo } from "react";
import format from "date-fns/format";
import WhatsappIcon from "src/icons/whatsapp.svg?react";
import EmailIcon from "src/icons/email.svg?react";
import MetaIcon from "src/icons/meta.svg?react";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { titleCase } from "src/lib/titleCase";

export function CampaignActivityCardItem({
  createdAt,
  name,
  publishedAt,
  scheduledAt,
  failedAt,
  platform,
  status,
}: {
  name: string;
  id: string;
  campaignId: string;
  createdAt: string;
  publishedAt?: string;
  failedAt?: string;
  scheduledAt?: string;
  platform: string;
  status?: ActivityStatusEnum;
}) {
  function getIcon(platform: string) {
    switch (platform) {
      case "WHATSAPP":
        return WhatsappIcon;
      case "EMAIL":
        return EmailIcon;
      // case "TEXT_MESSAGE":
      //   return message;
      // case "GOOGLE":
      //   return google;
      case "META":
        return MetaIcon;
      case "CONDITIONAL":
        return MetaIcon;
      default:
        return null;
    }
  }

  const formattedFailedAt = useMemo(
    () => (failedAt ? format(new Date(failedAt), "dd MMM hh:mm") : null),
    [failedAt],
  );

  const formattedCreatedAt = useMemo(
    () => (createdAt ? format(new Date(createdAt), "dd MMM hh:mm ") : null),
    [createdAt],
  );

  const formattedPublishedAt = useMemo(
    () => (publishedAt ? format(new Date(publishedAt), "dd MMM hh:mm ") : null),
    [publishedAt],
  );

  const formattedScheduledAt = useMemo(
    () => (scheduledAt ? format(new Date(scheduledAt), "dd MMM hh:mm ") : null),
    [scheduledAt],
  );

  let subText: {
    message: string;
    type: "ERROR" | "DRAFT" | "ACTIVE";
  };

  if (failedAt && status === "ERROR") {
    subText = { type: "ERROR", message: `Failed at: ${formattedFailedAt}` };
  } else if (publishedAt) {
    subText = {
      type: "ACTIVE",
      message: `Published at: ${formattedPublishedAt}`,
    };
  } else if (scheduledAt) {
    subText = {
      type: "ACTIVE",
      message: `Scheduled at: ${formattedScheduledAt}`,
    };
  } else {
    subText = { type: "DRAFT", message: `Created at: ${formattedCreatedAt}` };
  }

  return (
    <SectionHeader
      title={name}
      titleMetaData={
        <BadgeV2
          label={titleCase(status)}
          color={
            status === "ACTIVE"
              ? "green"
              : status === "PAUSED"
              ? "yellow"
              : "red"
          }
        />
      }
      description={subText.message}
      icon={getIcon(platform)}
      hasIconWrapper
      iconColor="default"
    />
  );
}
