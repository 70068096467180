import { GrowAggregateType } from "../../global/misc/GrowAggregateType";
import { IOverviewTabs } from "../misc/campaignSingleUiTypes";
import { getGrowAggregateStatsByType } from "src/modules/global/functions/getGrowAggregateStatsByType";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { MetricCardList } from "src/components/MetricCardList/MetricCardList";
import MetaIcon from "src/icons/meta.svg?react";

interface Insights extends GrowAggregateType {
  activitiesCount?: number;
  audiencesCount?: number;
  creativesCount?: number;
  segmentsCount?: number;
}

interface ICampaignSingleOverviewTabContent {
  // setIsMoreDetailsOpen: (i: boolean) => void;
  // isMoreDetailsOpen: boolean;
  selectedTab: IOverviewTabs;
  insights: Insights;
}

export function CampaignSingleOverviewTabContent({
  selectedTab,
  insights,
}: ICampaignSingleOverviewTabContent) {
  // let the stats handle the loading
  const stats = getGrowAggregateStatsByType({ type: selectedTab });
  const statsFormatted = stats.map((v) => ({
    ...v,
    value: formatNumberByType(insights?.[v.valueKey] || 0, v.valueType, {
      showDecimal: true,
    }),
    sourceIcon: MetaIcon,
  }));
  return (
    <div className="py-8 px-12">
      <MetricCardList
        stats={statsFormatted}
        columns={{
          xs: 5,
        }}
      />
    </div>
  );
}
