import SortByDropdown from "src/modules/global/components/SortByDropdown";
import { Searchbar } from "../Searchbar/Searchbar";
import { TabsV2 } from "../TabsV2/TabsV2";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import styles from "./TableFilter.module.scss";
import { SearchInput } from "../SearchInput/SearchInput";
import DatePicker, { DateFilterEnum } from "../Datepicker/Datepicker";
import { DateRange } from "react-day-picker";

interface ITableFilter {
  searchQuery: string;
  setSearchQuery: (i: string) => void;
  sortBy: { direction: "DESC" | "ASC"; field: string };
  setSortBy: (i: { direction: "DESC" | "ASC"; field: string }) => void;
  sortOptions: {
    type: string;
    value: string;
    options: {
      label: string;
      value: string;
      icon: any;
    }[];
  }[];
  searchInputPlaceholder: string;
  dateFilter: DateRange;
  setDateFilter: (i: DateRange) => void;
  defaultValue?: DateFilterEnum;
  hasTabs?: boolean;
  tabOptions?: { label: string; value: string }[];
  setSelectedTab?: (i: string) => void;
  selectedTab?: string;
}

export function TableFilter({
  searchQuery,
  setSearchQuery,
  sortBy,
  setSortBy,
  sortOptions,
  searchInputPlaceholder,
  hasTabs,
  tabOptions,
  setSelectedTab,
  selectedTab,
  dateFilter,
  defaultValue,
  setDateFilter,
}: ITableFilter) {
  return (
    <div className={styles.tableFilter}>
      <div className={styles.filterLeftSection}>
        {hasTabs ? (
          <TabsV2
            size="small"
            tabs={tabOptions}
            selectedTab={selectedTab}
            onTabChange={(value) => setSelectedTab(value)}
          />
        ) : (
          <SearchInput
            value={searchQuery}
            onChange={(i) => setSearchQuery(i)}
            placeholder={searchInputPlaceholder}
          />
        )}
      </div>

      <div className={styles.filterRightSection}>
        {hasTabs && (
          <Searchbar
            alwaysExpanded={false}
            value={searchQuery}
            onChange={(i) => setSearchQuery(i)}
            input={{ placeholder: searchInputPlaceholder }}
          />
        )}

        {/* <DateFilterDropdown
          setDateFilter={(i: DateFilter) => {
            setDateFilter(i);
          }}
          dateFilter={dateFilter}
        /> */}
        <DatePicker
          defaultValue={defaultValue}
          onChange={function (i: DateRange): void {
            setDateFilter(i);
          }}
        />
        <SortByDropdown
          sortOptions={sortOptions}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      </div>
    </div>
  );
}
