import { Share01 } from "@untitled-ui/icons-react";
import { useState } from "react";
import { toast } from "sonner";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Dialog } from "src/components/Dialog/Dialog";
import { Radio } from "src/components/Radio/Radio";
import { Text } from "src/components/Text/Text";
import { useCustomerExportMutation } from "src/graphql/generated/schema";

type ExportOption = "SELECTED" | "ALL_CONTACTS";

export function ExportCustomerModal({
  selectedIds,
}: {
  selectedIds: string[];
}) {
  const [customerExport, { loading }] = useCustomerExportMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<ExportOption>("ALL_CONTACTS");

  const exportOptions = [
    {
      label: "Selected contacts",
      value: "SELECTED",
    },
    {
      label: "Export all customers",
      value: "ALL_CONTACTS",
    },
  ];

  return (
    <Dialog
      maxWidth="424px"
      trigger={
        <ButtonV2
          as={Dialog.Trigger}
          size="small"
          leftIcon={Share01}
          variant="outline"
        >
          Export
        </ButtonV2>
      }
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Dialog.Header
        size="large"
        title="Export Contacts"
        icon={Share01}
        iconColor="default"
      />
      <div className="pt-8">
        <Text tone="gray-700" size="sm">
          By default, all exports include: full name, ID, address, email, phone
          number, company, orders, & leads. We'll send csv file to your email
          address.
        </Text>
      </div>
      <div className="pt-24 flex flex-col gap-12">
        {exportOptions.map((option) => (
          <div>
            <Radio
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => {
                setSelectedOption(option.value as ExportOption);
              }}
              label={
                <Text weight="medium" tone="neutral-700">
                  {option.label}
                </Text>
              }
            />
          </div>
        ))}
      </div>
      <div className="pt-40 flex justify-end gap-8">
        <ButtonV2 variant="outline" size="small">
          Cancel
        </ButtonV2>
        <ButtonV2
          loading={loading}
          onClick={async () => {
            await customerExport({
              variables: {
                ...(selectedOption === "SELECTED" && {
                  filters: {
                    ids: selectedIds,
                  },
                }),
              },
            });
            setIsOpen(false);
            toast.success("Export will be sent to your email shortly.");
          }}
          size="small"
        >
          Export
        </ButtonV2>
      </div>
    </Dialog>
  );
}
