import styles from "./SliderInput.module.scss";
import * as Slider from "@radix-ui/react-slider";

interface ISliderInput {
  value: number;
  max: number;
  min?: number;
  step: number;
  onValueChange: (value: number) => void;
  ariaLabel?: string;
}

export function SliderInput({
  value,
  max,
  min = 0,
  step,
  onValueChange,
  ariaLabel,
}: ISliderInput) {
  return (
    <Slider.Root
      className={styles.SliderRoot}
      defaultValue={[value]}
      max={max}
      min={min}
      step={step}
      onValueChange={(value) => {
        onValueChange(value[0]);
      }}
    >
      <Slider.Track className={styles.SliderTrack}>
        <Slider.Range className={styles.SliderRange} />
      </Slider.Track>
      <Slider.Thumb className={styles.SliderThumb} aria-label={ariaLabel} />
    </Slider.Root>
  );
}
