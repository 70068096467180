import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  MetaActivityAdTemplatesQuery,
  MetaAdTypeEnum,
  useCreativeTemplatesLazyQuery,
  useMetaActivityAdTemplatesLazyQuery,
} from "src/graphql/generated/schema";
import { AdTemplateItem } from "./AdTemplateItem";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "src/modules/global/misc/dateFilterUtils";
import { useEffect } from "react";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./AdTemplatesLoader.module.scss";
import UserAddIcon from "../assets/userAddIcon.svg";
import createAdImage from "../assets/createAds.svg";
import noResultAd from "../assets/noResultAds.svg";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

type AdTemplate =
  MetaActivityAdTemplatesQuery["metaActivityAdTemplates"]["edges"][0]["node"];

interface IAdTemplatesLoader {
  selectedAdTemplates: AdTemplate[];
  setSelectedAdTemplates: (i: AdTemplate[]) => void;
  selectedFilters: {
    searchQuery: string;
    dateFilter: DateFilter;
    adType: MetaAdTypeEnum | null;
  };
  selectedSortBy: { direction: "DESC" | "ASC"; field: "CREATED_AT" };
  multiple: boolean;
}

export function AdTemplatesLoader({
  selectedFilters,
  selectedSortBy,
  selectedAdTemplates,
  multiple,
  setSelectedAdTemplates,
}: IAdTemplatesLoader) {
  const debouncedSearchQuery = useDebouncedVariable(
    selectedFilters.searchQuery
  );
  const [creativeTemplatesFn, { data, loading, called, error, fetchMore }] =
    useCreativeTemplatesLazyQuery();

  const areFiltersApplied =
    debouncedSearchQuery !== "" ||
    selectedFilters.adType !== null ||
    selectedFilters.dateFilter !== "ALL_TIME";

  useEffect(() => {
    creativeTemplatesFn({
      variables: {
        sortBy: selectedSortBy,
        filter: {
          createdAt: getDateFilterFromFilterEnum(selectedFilters.dateFilter),
          ...(selectedFilters.adType && { adType: selectedFilters.adType }),
          name: debouncedSearchQuery,
          take: 25,
        },
      },
    });
  }, [
    creativeTemplatesFn,
    selectedSortBy,
    debouncedSearchQuery,
    selectedFilters.adType,
    selectedFilters.dateFilter,
  ]);

  if (loading || !called) {
    return <Spinner height={400} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  async function fetchMoreAdTemplates() {
    await fetchMore({
      variables: {
        filter: {
          cursor: data.creativeTemplates.pageInfo.endCursor,
        },
      },

      // concatenate old and new entries
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges = fetchMoreResult?.creativeTemplates?.edges ?? [];
        const oldEdges = previousResult?.creativeTemplates?.edges ?? [];

        return {
          creativeTemplates: {
            ...fetchMoreResult.creativeTemplates,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  // this should be above the generic handler of no result
  // because that one will match both of these cases
  if (!data.creativeTemplates.edges.length && areFiltersApplied) {
    return (
      <div className={styles.noResultScreen}>
        <img src={noResultAd} width={48} height={48} alt="no result audience" />
        <span className={styles.noResultText}>
          No search results found. Try changing the filters or use a different
          keyword.
        </span>
      </div>
    );
  }

  if (!data.creativeTemplates.edges.length) {
    return (
      <div className={styles.emptyScreen}>
        <img src={createAdImage} width={48} height={48} alt="create audience" />
        <span className={styles.emptyScreenText}>
          Oops! It looks like you don't have an ads for your campaign launch.
        </span>
        <div className={styles.buttonWrapper}>
          <ButtonV2 to="/library/ad-library">
            <div className={styles.createAudienceBtnContent}>
              <img
                src={UserAddIcon}
                width={16}
                height={16}
                role="none"
                alt="user add icon"
              />
              Create Ads
            </div>
          </ButtonV2>
        </div>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={data.creativeTemplates.edges.length}
      scrollableTarget="scrollTargetForInfiniteScroll"
      next={fetchMoreAdTemplates}
      hasMore={data.creativeTemplates.pageInfo.hasNextPage}
      loader={<Spinner height={200} />}
    >
      {data.creativeTemplates.edges.map((creativeTemplate) => (
        <AdTemplateItem
          multiple={multiple}
          key={creativeTemplate.node.id}
          selectedAdTemplates={selectedAdTemplates}
          setSelectedAdTemplates={setSelectedAdTemplates}
          metaActivityAdTemplate={
            creativeTemplate.node.MetaActivityAdTemplate
              ? {
                  ...creativeTemplate.node.MetaActivityAdTemplate,
                  name: creativeTemplate.node.name,
                }
              : {
                  ...creativeTemplate.node.DefaultTemplateField,
                  __typename: "MetaActivityAdTemplate",
                  name: creativeTemplate.node.name,
                  adType: "CUSTOM_CREATIVE",
                }
          }
        />
      ))}
    </InfiniteScroll>
  );
}
