import { Form, Formik } from "formik";
import { MutableRefObject, useContext } from "react";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { WizardStep } from "src/components/WizardStep/WizardStep";
import { GeoLocationSearchInput } from "./GeoLocationSearchInput";
import { GeoLocationTargetingFormState } from "../misc/audienceCreateTypes";
import { LocationBrowserPopover } from "./LocationBrowserPopover";
import { LocationInputList } from "./LocationInputList";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { geoLocationTargetingSchema } from "../misc/geoLocationSchema";
import { removeOverlapLocations } from "../misc/removedOverlapLocations";
import { toast } from "sonner";

interface IGeoLocationTargeting {
  sectionRef: MutableRefObject<HTMLDivElement>;
}
export function GeoLocationTargeting({ sectionRef }: IGeoLocationTargeting) {
  const { data, activeStepIndex, markStepAsDone } = useContext(WizardContext);
  const nextIndex = 4;

  function handleFormSubmit(values: GeoLocationTargetingFormState) {
    markStepAsDone(nextIndex, values);
  }

  return (
    <WizardStep
      isStepNotActive={activeStepIndex !== 3}
      isLastStep={false}
      ref={sectionRef}
      isCompleted={activeStepIndex > 3}
      title="Choose locations to target your audience"
      description="Choose the geographic locations where you want to reach your target audience"
    >
      <Formik
        validationSchema={geoLocationTargetingSchema}
        enableReinitialize
        initialValues={
          {
            ...data,
            locations: data.locations ?? [],
          } as GeoLocationTargetingFormState
        }
        onSubmit={handleFormSubmit}
      >
        {({ values, resetForm, setFieldValue }) => (
          <Form>
            <div className="p-20 bg-white rounded-12 border border-neutral-100">
              <div className="grid grid-cols-[auto_1fr] gap-16">
                <LocationBrowserPopover
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <GeoLocationSearchInput
                  supportedTypes={[
                    "city",
                    "country",
                    "region",
                    "neighborhood",
                    "place",
                  ]}
                  onSelect={(location) => {
                    const locations: GeoLocationTargetingFormState["locations"] =
                      [
                        ...values.locations,
                        {
                          ...location,
                          value: "INCLUDE",
                          ...(location.type === "city" && { radius: 20 }),
                          ...(location.type === "place" && { radius: 20 }),
                        },
                      ];
                    const uniqueLocations = removeOverlapLocations(locations);

                    if (uniqueLocations.length !== locations.length) {
                      toast.info(
                        "Your location was overlapping so we have removed the smaller region",
                      );
                    }

                    setFieldValue("locations", uniqueLocations);
                  }}
                />
              </div>
              <LocationInputList
                locations={values.locations}
                setFieldValue={setFieldValue}
              />
            </div>

            <div className="py-16 flex gap-8">
              <div className="flex gap-8">
                <ButtonV2 type="submit">Continue</ButtonV2>
                <ButtonV2
                  onClick={() => {
                    resetForm();
                    markStepAsDone(nextIndex, data);
                  }}
                  type="submit"
                  variant="outline"
                >
                  Skip this step
                </ButtonV2>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </WizardStep>
  );
}
