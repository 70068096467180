import styles from "./PlanDetails.module.scss";
import {
  GetBillingQuery,
  OrganizationUsageQuery,
  useUpgradeBillingMutation,
} from "src/graphql/generated/schema";
import { formatPrice } from "src/lib/formatPrice";
import format from "date-fns/format";
import { titleCase } from "src/lib/titleCase";
import { MetricCardList } from "src/components/MetricCardList/MetricCardList";
import {
  Announcement03,
  Calendar,
  CreditCard01,
} from "@untitled-ui/icons-react";
import { Text } from "src/components/Text/Text";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Icon } from "src/components/Icon/Icon";
import { isLastIndexOfArray } from "src/lib/isLastItemOfArray";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { UpgradePlanModal } from "src/components/UpgradePlanModal/UpgradePlanModal";
import { Dialog } from "src/components/Dialog/Dialog";
import { customerSupportEmail } from "src/modules/global/misc/customerSupportEmail";
import { useState } from "react";

interface PlanDetailsProps {
  billing: GetBillingQuery["billing"];
  organizationUsage?: OrganizationUsageQuery["organizationUsage"];
}

export function PlanDetails({ billing }: PlanDetailsProps) {
  const [upgradeBilling, { loading: upgradeBillingLoading }] =
    useUpgradeBillingMutation();
  const plan = billing.BillingGrowPlan
    ? billing.BillingGrowPlan
    : billing.BillingEngagePlan;

  const planType =
    billing.BillingGrowPlan && billing.BillingEngagePlan
      ? "ONE"
      : billing.BillingEngagePlan
      ? "ENGAGE"
      : "GROW";

  const billingSummary = [
    {
      label: "Next Invoice issue date",
      value: billing.renewsAt
        ? format(new Date(billing.renewsAt), "dd MMM, yyyy")
        : "-",
      icon: Calendar,
    },
    {
      label: "Invoice Amount",
      value: `${
        billing.cycle === "ANNUAL"
          ? formatPrice(billing.totalAmount / 100 / 12)
          : formatPrice(billing.totalAmount / 100)
      }/mo `,
      ...(billing.cycle === "MONTHLY" && {
        action: {
          children: "Switch to yearly plan",
          to: "/settings/billing",
        },
      }),
      icon: CreditCard01,
    },
    {
      label: "Subscribed to",
      value: `Macro ${titleCase(planType)}`,
      action: {
        children: "Change Plan",
        to: "/settings/billing",
      },
      icon: Announcement03,
    },
  ];

  return (
    <div className="border rounded-8">
      <div className="flex justify-between items-center py-8 px-20 border-b border-neutral-50">
        <Text tone="neutral-800" weight="medium" font="redhat">
          Macro {titleCase(planType)} Summary
        </Text>

        <UpgradePlanModal
          trigger={
            <ButtonV2 as={Dialog.Trigger} size="small" variant="outline">
              Change plan
            </ButtonV2>
          }
          defaultTab={"GROW"}
          growPlanType={billing.BillingGrowPlan?.type}
          growPlanTier={billing.BillingGrowPlan?.tier}
          onUpgrade={async (planId, monthlyAdSpend) => {
            const { data } = await upgradeBilling({
              variables: {
                input: {
                  growPlanType: planId,
                  monthlyAdSpendRange: monthlyAdSpend,
                },
              },
            });

            if (data?.billingUpgrade.paymentIntent) {
              window.location.href = data.billingUpgrade.paymentIntent;
            }
          }}
          onUpgradeLoading={upgradeBillingLoading}
          onEnterprisePlanInquiry={() => {
            window.open(
              `mailto:${customerSupportEmail}?subject=Enterprise Plan Inquiry`,
              "_blank",
            );
          }}
        />
      </div>
      <div className="grid grid-cols-3 p-8">
        {billingSummary.map((item, index) => (
          <div className="flex items-center" key={index}>
            <div className="flex-1 flex flex-col gap-8 p-12">
              <Text size="xs" weight="medium" tone="neutral-700">
                {item.label}
              </Text>
              <div className="flex items-center gap-4">
                <Icon icon={item.icon} />
                <Text weight="medium" tone="gray-900">
                  {item.value}
                </Text>
              </div>
            </div>
            {!isLastIndexOfArray(billingSummary, index) && (
              <VerticalSeparator totalWidth={1} separatorHeight={32} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
