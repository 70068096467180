// import styles from "./AdTemplateSingle.module.scss";
import { MetaActivityAdTemplate } from "../../../../graphql/generated/schema";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { useState } from "react";
import { Text } from "src/components/Text/Text";
import { Icon } from "src/components/Icon/Icon";
import {
  ChevronRight,
  Globe01,
  HeadingSquare,
  InfoSquare,
  LayoutGrid01,
  Link03,
  Type01,
} from "@untitled-ui/icons-react";
import { Link } from "react-router-dom";
import { getPlatformIcon } from "../../functions/getPlatformIcon";
import { OverviewTabContent } from "./OverviewTabContent";
import { LineHeading } from "src/modules/global/components/LineHeading";
import {
  Table,
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import notFound from "src/modules/creative/assets/notFound.png";
import { AdTemplateAggregatedStats } from "src/modules/global/functions/useAdTemplateAggregatedStats";

export function AdTemplateSingleDetails({
  adTemplate,
}: {
  adTemplate: MetaActivityAdTemplate & {
    stats: AdTemplateAggregatedStats;
  };
}) {
  type TabType = "OVERVIEW" | "ACTIVITY" | "DETAIL" | "LEADS";
  const [selectedTab, setSelectedTab] = useState<TabType>("OVERVIEW");

  const tabOptions = [
    {
      icon: Globe01,
      label: "Overview",
      value: "OVERVIEW",
    },
    {
      icon: LayoutGrid01,
      label: "Activity History",
      value: "ACTIVITY",
    },
    {
      icon: InfoSquare,
      label: "Detail",
      value: "DETAIL",
    },
  ];

  return (
    <div className="flex flex-col gap-16">
      <TabsV2
        tabs={tabOptions}
        selectedTab={selectedTab}
        onTabChange={(i: any) => setSelectedTab(i)}
      />
      {selectedTab === "OVERVIEW" && (
        <OverviewTabContent adTemplate={adTemplate} />
      )}
      {selectedTab === "ACTIVITY" && (
        <ActivityTabContent adTemplate={adTemplate} />
      )}
      {selectedTab === "DETAIL" && (
        <DetailsTabContent adTemplate={adTemplate} />
      )}
    </div>
  );
}

function DetailsTabContent({
  adTemplate,
}: {
  adTemplate: MetaActivityAdTemplate;
}) {
  const contentListWithType = [
    {
      type: `Primary Text`,
      content: adTemplate.primaryText,
      icon: Type01,
    },
  ];

  if (adTemplate.LineItems.length > 0) {
    contentListWithType.push(
      ...(adTemplate.LineItems?.filter((v) => v.description).map((v, i) => ({
        type: `Description ${i + 1}`,
        content: v.description,
        icon: Type01,
      })) ?? []),
    );
    contentListWithType.push(
      ...(adTemplate.LineItems?.filter((v) => v.headline).map((v, i) => ({
        type: `Headline ${i + 1}`,
        content: v.headline,
        icon: HeadingSquare,
      })) ?? []),
    );
    contentListWithType.push(
      ...adTemplate.LineItems?.filter((v) => v.link).map((v, i) => ({
        type: `Link ${i + 1}`,
        content: v.link,
        icon: Link03,
      })),
    );
  }

  return (
    <div>
      <div className="flex flex-col gap-16">
        <LineHeading content={"Content in this template"} />
        <Table
          headings={[
            {
              title: "Column Item",
            },
            {
              title: "Type",
            },
          ]}
          isSelectable={false}
          selectedItems={[]}
          variant="card"
          data={contentListWithType}
          idExtractor={(item, index) => String(index)}
          renderItem={({ item, index }) => (
            <TableRow id={String(index)}>
              <TablePrimaryCell
                title={item.content}
                subtitle={`${item.content.length} characters`}
              />
              <TableCell width={"128px"}>
                <div className="flex gap-4">
                  <Icon icon={item.icon} />
                  <Text size="sm" weight="medium" noWrap>
                    {item.type}
                  </Text>
                </div>
              </TableCell>
            </TableRow>
          )}
        />
      </div>
    </div>
  );
}

function ActivityTabContent({
  adTemplate,
}: {
  adTemplate: MetaActivityAdTemplate;
}) {
  if (adTemplate?.Activities?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-[70vh] w-full gap-8">
        <div className="flex flex-col gap-8 w-[300px] items-center justify-center">
          <img src={notFound} alt={notFound} height={100} width={120} />
          <Text size="xs" tone="subdued" align="center">
            It looks like there's nothing here right now. Start exploring or add
            something new to get started!{" "}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8">
      {adTemplate?.Activities?.map((v) => (
        <Link
          to={`/campaigns/${v.campaignId}/activities/${v.id}`}
          className="flex justify-between items-center border p-12 rounded-8"
        >
          <div className="flex gap-8">
            <Icon
              icon={getPlatformIcon({
                platformId: v.platform as any,
              })}
              size="sm"
            />
            <Text size="sm" weight="medium">
              {v.name}
            </Text>
          </div>
          <Icon icon={ChevronRight} />
        </Link>
      ))}
    </div>
  );
}
