import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import MacroLogo from "src/icons/logo.svg?react";
import { AuthLayout } from "./AuthLayout";
import passwordResetLinkExpired from "../assets/passwordLinkExpired.png";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import Fieldset from "src/components/Fieldset/Fieldset";

export function PasswordResetLinkExpired() {
  return (
    <AuthLayout image={passwordResetLinkExpired} title="Password Recovery">
      <Fieldset>
        <SectionHeader
          title={"Link Expired"}
          description={
            "Your password reset link has expired. Please request a new link or contact support"
          }
        />
        <ButtonV2 to="/login">Go back to login</ButtonV2>
      </Fieldset>
    </AuthLayout>
  );
}
