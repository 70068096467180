import { AudienceNameSection } from "../components/AudienceNameSection";
import { Wizard } from "../../../components/Wizard/Wizard";
import { useRef, useState } from "react";
import Banner from "src/components/Banner/Banner";
import Info from "@material-symbols/svg-600/rounded/info.svg?react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { useNavigate } from "react-router-dom";
import {
  MetaInterest,
  useMetaAudienceSetCreateMutation,
} from "src/graphql/generated/schema";
import styles from "./AudienceWorkshopPage.module.scss";
import { AudienceTemplateTypeSelector } from "../components/AudienceTemplateTypeSelector";
import { ReviewSelectedAudienceAndInterests } from "../components/ReviewSelectedAudienceAndInterests";
import { GeoLocationTargeting } from "../components/GeoLocationTargeting";
import {
  LocationValue,
  OtherTargetingFormState,
  ReviewAudienceAndInterestsFormState,
} from "../misc/audienceCreateTypes";
import { IAudiencePreset } from "../misc/IAudiencePreset";
import { toast } from "sonner";
import { OtherAudienceTargeting } from "../components/OtherAudienceTargeting";
import Header from "src/components/Header/Header";
import { LogOut01 } from "@untitled-ui/icons-react";
import Alert from "src/components/Alert/Alert";
import { IntegrationErrorBanner } from "src/modules/global/components/IntegrationErrorBanner";

export function AudienceWorkshopPage() {
  const navigate = useNavigate();
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);
  const [createAudienceSet] = useMetaAudienceSetCreateMutation();
  const audienceTemplateTypeRef = useRef<HTMLDivElement>();
  const audiencePresetRef = useRef<HTMLDivElement>();
  const geoLocationTargetingRef = useRef<HTMLDivElement>();
  const reviewSelectedAudienceAndInterestsRef = useRef<HTMLDivElement>();
  const otherAudienceTargetingRef = useRef<HTMLDivElement>();
  const [existingAudience, setExistingAudience] = useState<{
    id: string;
    name: string;
  } | null>(null);

  function transformInterests(interest: MetaInterest) {
    return {
      audienceSizeLowerBound: interest.audienceSizeLowerBound,
      audienceSizeUpperBound: interest.audienceSizeUpperBound,
      metaInterestId: interest.id,
      name: interest.name,
      path: interest.path,
      type: interest.type,
    };
  }

  function transformLocation(location: LocationValue) {
    return {
      metaLocationId: location.id,
      name: location.name,
      type: location.type,
      countryCode: location.countryCode,
      countryName: location.countryName,
      region: location.region,
      regionId: location.regionId,
      radius: location.radius,
    };
  }

  function transformAudience(audience: IAudiencePreset) {
    return {
      audiencePresetType: audience.id,
      category: audience.group.value,
      filter: audience.filter,
      type: audience.audienceType,
    };
  }

  async function createAudience(
    formValues: ReviewAudienceAndInterestsFormState | OtherTargetingFormState,
  ) {
    const interests = formValues.interests ?? {};
    const audiencePresets = formValues.audiencePresets ?? {};

    try {
      const res = await createAudienceSet({
        variables: {
          input: {
            name: formValues.name,
            adAccountId: formValues.adAccountId,
            instagramAccountId: formValues.instagramAccountId,
            metaPageId: formValues.pageId,
            pixelId: formValues.pixelId,
            Interests: Object.values(interests)
              .filter((v) => v.value === "INCLUDE")
              .map((interest) => transformInterests(interest)),
            ExcludeInterests: Object.values(interests)
              .filter((v) => v.value === "EXCLUDE")
              .map((interest) => transformInterests(interest)),
            Locations:
              "locations" in formValues
                ? formValues.locations
                    .filter((l) => l.value === "INCLUDE")
                    .map((l) => transformLocation(l))
                : [],
            ExcludeLocations:
              "locations" in formValues
                ? formValues.locations
                    .filter((l) => l.value === "EXCLUDE")
                    .map((l) => transformLocation(l))
                : [],
            audiencePresets: Object.values(audiencePresets)
              .filter((v) => v.value === "INCLUDE")
              .map((audience) => transformAudience(audience)),
            excludeAudiencePresets: Object.values(audiencePresets)
              .filter((v) => v.value === "EXCLUDE")
              .map((audience) => transformAudience(audience)),
            minAge: "minAge" in formValues ? formValues.minAge : 18,
            maxAge: "maxAge" in formValues ? formValues.maxAge : 65,
            gender: "gender" in formValues ? formValues.gender : "ALL",
            languages:
              "languages" in formValues
                ? formValues.languages.map((l) => l.toString())
                : [],
          },
        },
      });
      toast.success("Audience created successfully");
      navigate(`/audiences`);
    } catch (e) {
      toast.error("message" in e ? e.message : "Something went wrong");
    }
  }

  async function handleStepComplete(
    activeStepIndex: number,
    formValues: ReviewAudienceAndInterestsFormState | OtherTargetingFormState,
  ) {
    if (activeStepIndex === 0) {
      // no jump
    } else if (activeStepIndex === 1) {
      audiencePresetRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (activeStepIndex === 2) {
      geoLocationTargetingRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (activeStepIndex === 3) {
      //  check if user has opted for further targeting
      geoLocationTargetingRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    } else if (activeStepIndex === 4) {
      otherAudienceTargetingRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    } else if (activeStepIndex === 5) {
      await createAudience(formValues);
    }
  }

  let initialValues = {
    name: "",
    useDefaultMetaAccounts: false,
    pageId: "",
    adAccountId: "",
    instagramAccountId: "",
    pixelId: "",
  };

  return (
    <div className={styles.wrapper}>
      <Header
        hasSidebar={false}
        showNotifications={false}
        title="Audience Workshop"
        showBack
        actions={
          <>
            <ButtonV2
              size="small"
              variant="outline"
              leftIcon={LogOut01}
              onClick={() => setIsDiscardAlertShowing(true)}
            >
              Exit
            </ButtonV2>
          </>
        }
      />
      {existingAudience && (
        <Banner
          onClose={() => setExistingAudience(null)}
          content={
            <>
              Looks like same setting is also exist in your existing audience{" "}
              <ButtonV2
                variant="link"
                to={`/audience-sets/${existingAudience.id}`}
              >
                {existingAudience.name}
              </ButtonV2>
              . Please change settings to continue{" "}
            </>
          }
          icon={<Info height={14} width={14} />}
          type="error"
        />
      )}
      <IntegrationErrorBanner />
      <Alert
        trigger={<></>}
        isOpen={isDiscardAlertShowing}
        onClose={() => setIsDiscardAlertShowing(false)}
        title="Are you sure you want to exit?"
        description="You've made some changes that haven't been saved yet. Would you like to save your work before continuing, or discard the changes and proceed?"
        tone="danger"
        primaryAction={{
          label: "Discard Changes",
          onClick: () => {
            navigate("/audiences");
          },
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: () => setIsDiscardAlertShowing(false),
        }}
      />
      <div className={styles.contentWrapper}>
        <Wizard
          onResetField={(_fieldKey, _value, data) => {
            return data;
          }}
          onStepComplete={handleStepComplete}
          initialData={initialValues}
        >
          <div className={styles.formSection}>
            <div className={styles.formContentWrapper}>
              <AudienceNameSection />
              <AudienceTemplateTypeSelector
                sectionRef={audienceTemplateTypeRef}
              />

              <ReviewSelectedAudienceAndInterests
                sectionRef={reviewSelectedAudienceAndInterestsRef}
              />
              <GeoLocationTargeting sectionRef={geoLocationTargetingRef} />
              <OtherAudienceTargeting sectionRef={otherAudienceTargetingRef} />
            </div>
          </div>
          {/* <AIChatPanel /> */}
        </Wizard>
      </div>
    </div>
  );
}
