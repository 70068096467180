import React, { useState } from "react";
import { FormikError } from "src/components/FormikError/FormikError";
import { Input } from "src/components/Input/Input";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import styles from "./SegmentSelector.module.scss";
import SearchIcon from "@material-symbols/svg-600/rounded/search.svg?react";
import sortOptions from "src/modules/campaign/misc/campaignSortBy";
import { SegmentSelectorLoader } from "./SegmentSelectorLoader";

interface SegmentSelectorProps {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
  errors: any;
  submitCount: number;
  touched: any;
}

export function SegmentSelector({
  sectionRef,
  errors,
  submitCount,
  touched,
}: SegmentSelectorProps) {
  const [selectedFilters, setSelectedFilters] = useState({
    searchQuery: "",
    date: "ALL_TIME" as DateFilter,
    platform: "META" as const,
    audienceCategoryId: null,
    take: 25,
  });
  const [selectedSortBy, setSelectedSortBy] = useState({
    direction: "DESC" as "DESC" | "ASC",
    field: "CREATED_AT" as const,
  });

  return (
    <section ref={sectionRef} className={styles.section}>
      <div>
        <h2 className={styles.heading}>
          Select Segments for marketing activity
        </h2>
        <h3 className={styles.subheading}>
          Select segment whom you want to target for your marketing activity
        </h3>
      </div>

      <div className={styles.filters}>
        <Input
          value={selectedFilters.searchQuery}
          onChange={(value) => {
            setSelectedFilters({
              ...selectedFilters,
              searchQuery: value,
            });
          }}
          icon={SearchIcon}
          placeholder="Search your segment here"
        />
        <SortByDropdown
          setSortBy={setSelectedSortBy}
          sortBy={selectedSortBy}
          sortOptions={sortOptions}
        />
        <DateFilterDropdown
          dateFilter={selectedFilters.date}
          setDateFilter={(value) => {
            setSelectedFilters({
              ...selectedFilters,
              date: value,
            });
          }}
        />
      </div>

      <div className={styles.segmentBox}>
        <div className={styles.segmentListHeader}>
          <span>Segment Name</span>
          <span>Size</span>
          <span>Avg. LTV</span>
          <span className={styles.alignRight}>LTV</span>
          <span className={styles.alignRight}>Subscribed on</span>
        </div>

        <div id="scrollTargetForInfiniteScroll" className={styles.segmentList}>
          <SegmentSelectorLoader
            selectedFilters={selectedFilters}
            selectedSortBy={selectedSortBy}
          />
        </div>
      </div>

      <FormikError
        fieldName="Adsets"
        formikError={errors}
        submitCount={submitCount}
        touched={touched}
      />
    </section>
  );
}
