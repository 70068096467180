import {
  useBillingLatestCardQuery,
  useGetBillingQuery,
  useOrganizationQuery,
} from "src/graphql/generated/schema";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PlanDetails } from "../components/PlanDetails";
import { BillHistory } from "../components/BillHistory";
import { Spinner } from "src/components/Spinner/Spinner";
import Header from "src/components/Header/Header";
import { Text } from "src/components/Text/Text";
import { BillingInformation } from "../components/BillingInformation";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { EditBillingAddressModal } from "../components/EditBillingAddressModal";

export function OrganizationBillingSettingsPage() {
  const { data, loading, error } = useOrganizationQuery();

  const {
    data: billingData,
    loading: billingLoading,
    error: billingError,
  } = useGetBillingQuery();
  const {
    data: billingLatestCardData,
    loading: billingLatestCardLoading,
    error: billingLatestCardError,
  } = useBillingLatestCardQuery();

  if (billingLoading || loading || billingLatestCardLoading) {
    return <Spinner height={"screen"} />;
  }

  if (billingError) {
    return <ErrorHandler error={billingError} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (billingLatestCardError) {
    return <ErrorHandler error={billingLatestCardError} />;
  }

  return (
    <>
      <Header title="Billing & Invoices" />
      <div className="flex justify-center py-24 w-full max-w-[820px] mx-auto">
        <div className="flex flex-col gap-40 flex-1">
          <div className="flex flex-col gap-16">
            <SectionHeader
              size="large"
              title="Plan Summary"
              description="Access and manage your plan update billing details and review all past invoices with ease."
            />
            <PlanDetails billing={billingData.billing} />
          </div>
          <div>
            <div className="border rounded-8 pt-16 flex flex-col gap-16">
              <div className="px-16 flex justify-between items-center">
                <SectionHeader
                  title={"Billing Information"}
                  description={
                    "Update your organization's billing information here"
                  }
                />
                <EditBillingAddressModal
                  organization={data.organization}
                  billingAddress={data.organization.Address}
                />
              </div>
              {billingData.billing.paymentProvider === "STRIPE" && (
                <BillingInformation
                  billingCard={billingLatestCardData.billingLatestCard}
                  billing={billingData.billing}
                  organization={data.organization}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-12">
            <Text size="md" weight="medium" font="redhat">
              Billing History
            </Text>
            {billingData.billing.paymentProvider === "STRIPE" && (
              <BillHistory billing={billingData.billing} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
