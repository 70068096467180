import styles from "./AudienceSingleAbout.module.scss";
import format from "date-fns/format";
import { Audience, MetaAudienceSet } from "src/graphql/generated/schema";
import { generateRangeString } from "src/lib/generateRangeString";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import BehaviourIcon from "src/icons/behaviour.svg?react";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { Text } from "src/components/Text/Text";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { MetricCardList } from "src/components/MetricCardList/MetricCardList";
import InstagramIcon from "src/icons/instagram.svg?react";

interface IAudienceSingleAbout {
  audience: Audience | MetaAudienceSet;
}

export function AudienceSingleAbout({ audience }: IAudienceSingleAbout) {
  const isAudienceSet = audience.__typename === "MetaAudienceSet";

  const details = isAudienceSet
    ? [
        [
          // {
          //   title: "Audience ID",
          //   value: audience.,
          // },
          {
            title: "Estimate Size",
            value: generateRangeString(
              audience?.MetaInsights?.lowerBoundCount,
              audience?.MetaInsights?.upperBoundCount,
            ),
          },
          {
            title: "Created on",
            value: format(new Date(audience.createdAt), "dd MMM, yyyy"),
          },
          {
            title: "Facebook Ad Account",
            value: audience?.PlatformAccount?.metaName ?? "-",
          },
        ],
      ]
    : [
        [
          // {
          //   title: "Audience ID",
          //   value: audience.id,
          // },
          {
            title: "Estimate Size",
            value: generateRangeString(
              audience?.MetaInsights?.lowerBoundCount,
              audience?.MetaInsights?.upperBoundCount,
            ),
          },
          {
            title: "Created on",
            value: format(new Date(audience.createdAt), "dd MMM, yyyy"),
          },
          {
            title: "Facebook Ad Account",
            value: audience?.PlatformAccount?.metaName ?? "-",
          },
        ],
      ];

  return (
    <div className="flex flex-col gap-16">
      <SectionHeader title={"About this Audience"} />
      <div className="border rounded-8">
        <div className={"flex justify-between items-center p-16 border-b"}>
          <SectionHeader
            title={audience?.name}
            icon={BehaviourIcon}
            hasIconWrapper
            description={`Type: ${
              isAudienceSet ? "Audience Set" : audience?.type
            }`}
          />
          <div className="flex items-center gap-8">
            <Text size="xs" tone="gray-600">
              Source
            </Text>
            <VerticalSeparator separatorHeight={16} totalWidth={0} />
            <div className={styles.badgeWrapper}>
              <BadgeV2 label={"Instagram"} leftIcon={InstagramIcon} />
            </div>
          </div>
        </div>
        <div className="p-8">
          <MetricCardList
            stats={details[0]}
            variant="small"
            columns={{
              xs: 7,
            }}
          />
        </div>
      </div>
    </div>
  );
}
