import { UserSquare } from "@untitled-ui/icons-react";
import { useState } from "react";
import Header from "src/components/Header/Header";
import {
  CustomersSortBy,
  useCustomersCountQuery,
} from "src/graphql/generated/schema";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import { itemsPerPage } from "../../global/misc/itemsPerPage";
import { AddCustomerModal } from "../components/AddCustomerModal";
import { CustomersTableLoader } from "../components/CustomersTableLoader";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { ExportCustomerModal } from "../components/ExportCustomerModal";

export function CustomerListingPage() {
  const [selectedTab, setSelectedTab] = useState("CUSTOMERS");
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  const { data: countData } = useCustomersCountQuery();
  // const { data, loading, error } = useGetBillingQuery();
  const [pagination] = useState({
    cursor: null,
    take: itemsPerPage,
  });
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [sortBy, setSortBy] = useState<CustomersSortBy>({
    direction: "DESC",
    field: "CREATED_AT",
  });

  const [filters, setFilters] = useState({
    searchQuery: "",
    dateFilter: "ALL_TIME" as DateFilter,
  });

  // if (loading) {
  //   return <Spinner />;
  // }

  // if (error) {
  //   return <ErrorHandler error={error} />;
  // }

  // if (!data.billing.BillingEngagePlan) {
  //   return (
  //     <div className="flex items-center justify-center h-full">
  //       <UnlockFeatures
  //         title="Unlock Customer Contacts With Engage"
  //         description="Access to Contacts will be available to all Engage subscribers. Unlock this feature to manage and organize your customer information efficiently."
  //         unlockType="UNLOCK_ENGAGE"
  //       />
  //     </div>
  //   );
  // }

  return (
    <>
      <AddCustomerModal
        showAddCustomerModal={showAddCustomerModal}
        setShowAddCustomerModal={setShowAddCustomerModal}
      />

      <Header
        title="Contacts"
        badges={
          countData?.customersCount ? (
            <BadgeV2
              label={formatNumberByType(countData.customersCount, "NUMBER")}
            />
          ) : null
        }
        icon={UserSquare}
        iconColor="success"
        actions={
          <>
            <ExportCustomerModal selectedIds={selectedCustomerIds} />
            <ButtonV2 size="small" to="/customers">
              Add Contact
            </ButtonV2>
          </>
        }
      />
      <div className="pt-16">
        <CustomersTableLoader
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          filters={filters}
          setFilters={setFilters}
          selectedCustomerIds={selectedCustomerIds}
          setSelectedCustomerIds={setSelectedCustomerIds}
          pagination={pagination}
          sortBy={sortBy}
          setSortBy={setSortBy}
          showAddCustomerModal={showAddCustomerModal}
          setShowAddCustomerModal={setShowAddCustomerModal}
        />
      </div>
    </>
  );
}
