import { MetaActivityAd } from "src/graphql/generated/schema";
import styles from "./CampaignActivityCardCreatives.module.scss";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { Asset } from "src/components/Smartphone/Asset";
import classNames from "classnames";
import { CampaignActivityCardStatItem } from "./CampaignActivityCardStatItem";
import { getThumbnailAssetFromAd } from "../functions/getThumbnailAssetFromAd";
import { getAdTypeText } from "../functions/getAdTypeText";
import {
  Speedometer03,
  CoinsStacked03,
  Users02,
  LayoutGrid01,
} from "@untitled-ui/icons-react";

export function CampaignActivityCardStats({
  activity,
  adStats,
  selectedCurrencyCode,
}) {
  const stats = [
    {
      label: "Spends",
      value: formatNumberByType(adStats?.spend, "CURRENCY", {
        showDecimal: true,
        selectedCurrencyCode,
      }),
      icon: CoinsStacked03,
    },
    {
      label: "Ad Count",
      value: activity?.MetaActivity?.MetaActivityAds.length,
      icon: LayoutGrid01,
    },
    {
      label: "Reach",
      value: formatNumberByType(adStats?.reach, "NUMBER"),
      icon: Users02,
    },
    {
      label: "CTR",
      value: formatNumberByType(adStats?.ctr, "PERCENTAGE"),
      icon: Speedometer03,
    },
  ];

  return (
    <>
      {stats.map((v) => (
        <CampaignActivityCardStatItem {...v} />
      ))}
    </>
  );
}

export function CampaignActivityCardCreativesList({
  metaActivity,
  selectedAd,
  setSelectedAd,
}) {
  return (
    <div className={styles.creativesList}>
      {metaActivity?.MetaActivityAds.slice(0, 4).map((v) => (
        <CampaignActivityCreativeCard
          ad={v}
          isSelected={selectedAd === v.id}
          setSelectedAd={setSelectedAd}
        />
      ))}
    </div>
  );
}

function CampaignActivityCreativeCard({
  ad,
  isSelected,
  setSelectedAd,
}: {
  ad: MetaActivityAd;
  isSelected: boolean;
  setSelectedAd: any;
}) {
  const lineItem = getThumbnailAssetFromAd(ad);
  console.log(ad);
  const adTypeText = getAdTypeText(ad);

  return (
    <button
      type="button"
      onClick={() => {
        setSelectedAd(ad.id);
      }}
      className={classNames(styles.creativeCard)}
    >
      <Asset
        preview={lineItem.uri}
        type={lineItem.contentType}
        name={ad.name}
        loop={false}
        className={classNames(styles.creativeCardAsset)}
      />
      <div
        className={classNames(styles.creativeCardContent, {
          [styles.creativeCardSelected]: isSelected,
        })}
      >
        <span className={styles.adTypeBadge}>{adTypeText}</span>
        {isSelected && <div className={styles.adName}>{ad?.name}</div>}
      </div>
    </button>
  );
}
