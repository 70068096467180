import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { useMemo } from "react";
import { activeClientStorageKey } from "./modules/global/functions/activeClient";

interface IApolloWrapper {
  children: React.ReactNode;
  userToken: string;
  tracingId: string;
}

export function ApolloWrapper({
  children,
  userToken,
  tracingId,
}: IApolloWrapper) {
  const cachedActiveClientString = localStorage.getItem(activeClientStorageKey);
  let cachedActiveClient = null;
  if (cachedActiveClientString) {
    try {
      cachedActiveClient = JSON.parse(cachedActiveClientString);
    } catch (err) {
      console.error(err);
    }
  }
  const client = useMemo(() => {
    return new ApolloClient({
      uri: import.meta.env.VITE_GRAPHQL_URI,
      cache: new InMemoryCache({
        typePolicies: {
          MetaPage: {
            keyFields: ["id", "enabled"],
          },
        },
      }),
      headers: {
        authorization: userToken ? `Bearer ${userToken}` : "",
        "x-active-client": cachedActiveClient ? cachedActiveClient.id : "",
        "x-tracing-id": tracingId,
      },
    });
  }, [userToken, cachedActiveClient, tracingId]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
