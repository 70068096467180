import { Text } from "src/components/Text/Text";
import classNames from "classnames";
import Flex from "src/components/Flex/Flex";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { ArrowDown, ArrowUp } from "@untitled-ui/icons-react";
import { Skeleton } from "src/components/Skeleton/Skeleton";

export interface IMetricCard {
  titleIcon?: React.FC<React.SVGAttributes<SVGElement>>;
  title: string;
  value: string | React.ReactNode;
  performanceBadge?: {
    value: string;
    direction: "up" | "down";
  };
  border?: boolean;
  loading?: boolean;
  onClick?: () => void;
  selected?: boolean;
  sourceIcon?: React.FC<React.SVGAttributes<SVGElement>>;
  valueIcon?: React.FC<React.SVGAttributes<SVGElement>>;
  variant?: "small" | "large";
}

export function MetricCard({
  titleIcon: icon,
  title,
  value,
  onClick,
  selected,
  sourceIcon,
  valueIcon,
  performanceBadge,
  border = true,
  loading,
  variant = "large",
}: IMetricCard) {
  const SourceIcon = sourceIcon;
  const ValueIcon = valueIcon;
  const Icon = icon;
  const Component = onClick ? "button" : "div";

  return (
    <Component
      onClick={onClick}
      type="button"
      className={classNames(
        "relative flex flex-col items-start gap-8 p-12 w-full",
        {
          ["bg-gray-200"]: selected,
          "rounded-8 border border-neutral-50": border,
        },
      )}
    >
      <Flex gap={"4px"} alignItems="center">
        {Icon && <Icon height={14} width={14} />}
        <Text weight="medium" size="xs" tone="gray-700">
          {title}
        </Text>
      </Flex>

      <Flex gap={"8px"}>
        <Flex gap={"4px"} alignItems="center">
          {ValueIcon && (
            <ValueIcon
              height={variant === "large" ? 20 : 14}
              width={variant === "large" ? 20 : 14}
            />
          )}
          {loading ? (
            <Skeleton height={20} width={60} />
          ) : (
            <Text
              size={variant === "large" ? "xl" : "md"}
              weight="medium"
              tone="gray-900"
            >
              {value}
            </Text>
          )}
        </Flex>
        {performanceBadge && (
          <BadgeV2
            label={performanceBadge.value}
            color={performanceBadge.direction === "up" ? "green" : "red"}
            rightIcon={
              performanceBadge.direction === "up" ? ArrowUp : ArrowDown
            }
          />
        )}
      </Flex>
      {SourceIcon && (
        <div className="absolute bottom-4 right-4">
          <SourceIcon height={16} width={16} />
        </div>
      )}
    </Component>
  );
}
