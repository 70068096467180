import { Spinner } from "../../../components/Spinner/Spinner";
import {
  useGetBillingQuery,
  useOrganizationAdSpendsPerTickQuery,
  useOrganizationMetaUsagePerTickQuery,
  useOrganizationUsageQuery,
} from "../../../graphql/generated/schema";
import { formatNumber } from "../../../lib/formatNumber";
import { GraphCard } from "../../global/components/GraphCard";
import { OverallUsageStats } from "../components/OverallUsageStats";
import styles from "./OrganizationUsagePage.module.scss";
import DateFilterDropdown from "../../global/components/DateFilterDropdown";
import { DateFilter } from "../../global/misc/dateFilterUtils";
import { getMonthFromNumber } from "../../campaign/misc/getMonthFromNumber";
import { getTinybirdDateFiltersFromDateFilter } from "../../campaign/misc/campaignSingleFilters";
import { useMemo, useState } from "react";
import { convertDatetimeToDate } from "../../global/functions/convertDatetimeToDate";
import Header from "src/components/Header/Header";

const filterOptions = [
  {
    label: "Last 28 days",
    value: "LAST_28_DAYS" as const,
  },
  {
    label: "Last 3 Months",
    value: "LAST_3_MONTHS" as const,
  },
  {
    label: "Last 6 Months",
    value: "LAST_6_MONTHS" as const,
  },
  {
    label: "Last Year",
    value: "LAST_YEAR" as const,
  },
  {
    label: "All Time",
    value: "ALL_TIME" as const,
  },
];

export function OrganizationUsagePage() {
  const {
    data: organizationUsageData,
    loading: organizationUsageLoading,
    error: _error,
  } = useOrganizationUsageQuery();
  const { data: billingData, loading: billingLoading } = useGetBillingQuery();

  const [metaUsageDateFilter, setMetaUsageDateFilter] =
    useState<DateFilter>("LAST_6_MONTHS");
  const { dateTo: metaUsageDateTo, dateFrom: metaUsageDateFrom } =
    useMemo(() => {
      return getTinybirdDateFiltersFromDateFilter(metaUsageDateFilter);
    }, [metaUsageDateFilter]);
  const { data: metaUsagePerTickDate, loading: metaUsageLoading } =
    useOrganizationMetaUsagePerTickQuery({
      variables: {
        filter: {
          createdAt: {
            ...(metaUsageDateFrom && {
              gte: metaUsageDateFrom,
            }),
            ...(metaUsageDateTo && {
              lte: metaUsageDateTo,
            }),
          },
        },
      },
    });

  const [adSpendsDateFilter, setAdSpendsDateFilter] =
    useState<DateFilter>("LAST_6_MONTHS");
  const { dateTo: adSpendsDateTo, dateFrom: adSpendsDateFrom } = useMemo(() => {
    return getTinybirdDateFiltersFromDateFilter(adSpendsDateFilter);
  }, [adSpendsDateFilter]);

  const { data: adSpendsPerTick, loading: adSpendsLoading } =
    useOrganizationAdSpendsPerTickQuery({
      variables: {
        filter: {
          createdAt: {
            ...(adSpendsDateFrom && {
              gte: adSpendsDateFrom,
            }),
            ...(adSpendsDateTo && {
              lte: adSpendsDateTo,
            }),
          },
        },
      },
    });

  if (
    organizationUsageLoading ||
    billingLoading ||
    adSpendsLoading ||
    metaUsageLoading
  ) {
    return <Spinner height="screen" />;
  }

  const adSpendsChartData =
    adSpendsPerTick && adSpendsPerTick.organizationAdSpendsPerTick
      ? adSpendsPerTick.organizationAdSpendsPerTick.data.map((v: any) => ({
          ...v,
          spend: v.spend / 80,
          time:
            "tickDate" in v ? v.tickDate : `${getMonthFromNumber(v.tickMonth)}`,
        }))
      : [];

  const activityUsageChartData =
    metaUsagePerTickDate && metaUsagePerTickDate.organizationMetaUsagePerTick
      ? metaUsagePerTickDate.organizationMetaUsagePerTick.map((v) => ({
          count: v.count,
          time: v.date
            ? convertDatetimeToDate(new Date(v.date))
            : `${getMonthFromNumber(
                new Date(v.month).getMonth() + 1,
              )} ${new Date(v.month).getFullYear()}`,
        }))
      : [];

  return (
    <div>
      <Header title={"Overall Usage"} />
      <div className={styles.container}>
        <div className={styles.layout}>
          <OverallUsageStats />
          <GraphCard
            action={
              <DateFilterDropdown
                showLabel={true}
                filterOptions={filterOptions}
                setDateFilter={setAdSpendsDateFilter}
                dateFilter={adSpendsDateFilter}
              />
            }
            title={"Ad Spends (Monthly)"}
            chartData={adSpendsChartData}
            yAxis={{
              dataKey: "spend",
              label: "Ad Spends Monthly",
              tickFormatter: (v) =>
                formatNumber(v, {
                  showDecimal: false,
                }),
              tooltipFormatter: (v) => formatNumber(v),
            }}
          />
          <GraphCard
            action={
              <DateFilterDropdown
                showLabel={true}
                filterOptions={filterOptions}
                setDateFilter={setMetaUsageDateFilter}
                dateFilter={metaUsageDateFilter}
              />
            }
            title={"Meta Activity Usage"}
            chartData={activityUsageChartData}
            yAxis={{
              dataKey: "count",
              label: "Meta Usage",
              tickFormatter: (v) =>
                formatNumber(v, {
                  showDecimal: false,
                }),
            }}
          />
        </div>
      </div>
    </div>
  );
}
