import { LineHeading } from "src/modules/global/components/LineHeading";
import styles from "./ExistingPostAdFieldsShowcase.module.scss";
import ArticleIcon from "@material-symbols/svg-600/rounded/article.svg?react";
import AddLink from "@material-symbols/svg-600/rounded/add_link.svg?react";
import { useFormikContext } from "formik";
import { ActivityInputV2, AdInputV2 } from "../misc/createCampaignTypes";
import LoveIcon from "@material-symbols/svg-600/rounded/favorite.svg?react";
import CommentIcon from "@material-symbols/svg-600/rounded/tooltip.svg?react";
import { PageWelcomeMessageSelector } from "./PageWelcomeMessageSelector";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";
import { AdCallToActionLabelField } from "./AdCallToActionLabelField";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import Link01 from "@untitled-ui/icons-react/build/esm/Link01";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";

interface IExistingPostAdFieldsShowcase {
  selectedAd: AdInputV2;
  selectedAdIndex: number;
}

export function ExistingPostAdFieldsShowcase({
  selectedAdIndex,
  selectedAd,
}: IExistingPostAdFieldsShowcase) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();

  const shouldShowPageWelcomeMessage =
    values.metaPurpose === "MESSAGE_INSTAGRAM" ||
    values.metaPurpose === "MESSAGE_MESSENGER" ||
    values.metaPurpose === "MESSAGE_WHATSAPP";

  const shouldShowLink =
    values.metaPurpose === "SALES_STANDARD" ||
    values.metaPurpose === "PROMOTE_POST";

  const shouldShowPhoneNumber = values.metaPurpose === "CALL";

  return (
    <div className={styles.adFields}>
      <div className={styles.linksSection}>
        <AdCallToActionLabelField
          name={`Ads[${selectedAdIndex}].callToActionLabel`}
          value={selectedAd.callToActionLabel}
          adType={selectedAd.adType}
        />

        {shouldShowLink && (
          <InputFormikV2
            name={`Ads[${selectedAdIndex}].LineItems[0].link`}
            label="Website URL"
            icon={Link01}
          />
        )}

        {shouldShowPhoneNumber && (
          <PhoneNumberInput
            disabled
            defaultPhoneCode="+91"
            label="Phone Number"
            value={selectedAd.phoneNumber}
            countryCodeValue={selectedAd.phoneCountryCode}
            countryCodeOnChange={(value) => {
              setFieldValue(`Ads[${selectedAdIndex}].phoneCountryCode`, value);
            }}
            onChange={(value) => {
              setFieldValue(`Ads[${selectedAdIndex}].phoneNumber`, value);
            }}
          />
        )}

        <MultilineInput
          disabled={true}
          showCharacterCount={true}
          // there is no limit for this field in existing post ads
          maxCharacterCount={1024}
          value={selectedAd.primaryText}
          onChange={(value) => {
            setFieldValue(`Ads[${selectedAdIndex}].primaryText`, value);
          }}
          helpText="This is the caption of the ad"
          label="Caption text"
          rows={3}
        />
      </div>

      {shouldShowPageWelcomeMessage && (
        <div>
          <PageWelcomeMessageSelector
            value={selectedAd.pageWelcomeMessageId}
            onChange={() => {
              /** field is disabled */
            }}
          />
        </div>
      )}
    </div>
  );
}
