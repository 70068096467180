import React, { useState } from "react";
import styles from "./WhatsappTemplateSelector.module.scss";
import { Button } from "src/components/Button/Button";
import DescriptionIcon from "@material-symbols/svg-600/rounded/description.svg?react";
import { WhatsappTemplateFieldsShowcase } from "./WhatsappTemplateFieldsShowcase";
import { WhatsappTemplateEmptyScreen } from "./WhatsappTemplateEmptyScreen";
import { WhatsappTemplatePreviewSection } from "./WhatsappTemplatePreviewSection";
import { WhatsappTemplateSelectorModal } from "./WhatsappTemplateSelectorModal";
import { WhatsappSenderDetails } from "./WhatsappSenderDetails";

interface WhatsappTemplateSelectorProps {
  sectionRef: React.RefObject<HTMLElement>;
  values: any;
  setFieldValue: (field: string, value: any) => void;
}

export function WhatsappTemplateSelector({
  sectionRef,
  values,
  setFieldValue,
}: WhatsappTemplateSelectorProps) {
  const [isTemplateSelectorModalOpen, setIsTemplateSelectorModalOpen] =
    useState(false);

  return (
    <section ref={sectionRef} className={styles.section}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.heading}>
            Choose the Perfect Ads for your marketing need
          </h2>
          <h3 className={styles.subheading}>
            Marketing creative can be your Ads, Emails, SMS or WhatsApp
            templates which have created in Library
          </h3>
        </div>

        {values.WhatsappMessageObject && (
          <div>
            <Button
              onClick={() => setIsTemplateSelectorModalOpen(true)}
              size="small"
              style="outline"
              color="subdued"
            >
              <span className={styles.messageSelectButtonContent}>
                <DescriptionIcon
                  fill="var(--color-disabled)"
                  width={16}
                  height={16}
                />
                Select Template
              </span>
            </Button>
          </div>
        )}
      </div>

      <WhatsappTemplateSelectorModal
        onSelect={(whatsappTemplate) => {
          setFieldValue("WhatsappMessageObject", whatsappTemplate);
          setIsTemplateSelectorModalOpen(false);
        }}
        isOpen={isTemplateSelectorModalOpen}
        onClose={() => setIsTemplateSelectorModalOpen(false)}
      />

      <div className={styles.messageSection}>
        {values.WhatsappMessageObject ? (
          <>
            <div className={styles.currentMessageEditor}>
              <div className={styles.currentMessageEditorHeader}>
                <h3 className={styles.currentAdHeaderTitle}>
                  This is ad title
                </h3>
                <p className={styles.currentMessageEditorSubtitle}>
                  Single Images & Video
                </p>
              </div>
              <WhatsappSenderDetails />
              <WhatsappTemplateFieldsShowcase />
            </div>
          </>
        ) : (
          <WhatsappTemplateEmptyScreen
            onSelectTemplate={() => setIsTemplateSelectorModalOpen(true)}
          />
        )}

        <WhatsappTemplatePreviewSection />
      </div>
    </section>
  );
}
