import { monthAndYearFormatter } from "src/lib/monthAndYearFormatter";
import { useMemo } from "react";
import { LineGraphV2 } from "./LineGraphV2";
import format from "date-fns/format";
import { highPerformanceDateFormatter } from "src/lib/highPerformanceDateFormatter";
import { GrowPerTickType } from "src/modules/global/misc/GrowPerTickType";

type StatLabel = {
  color: string;
  name: string;
  dataKey: string;
};

interface ITimelineGraph {
  statValues: Array<GrowPerTickType>;
  statLabel: StatLabel;
  statComparisonLabel?: StatLabel;
  statComparisonValues?: Array<GrowPerTickType>;
  width: number | string;
  height: number | string;
  yAxis: {
    tooltipFormatter?: (i: string) => string;
    tickFormatter?: (i: string) => string;
    label: string;
    dataKey?: string;
  };
}

/*
 * Line graph V2 but works only when you are representing time on the X axis
 */
export function TimelineGraph({
  statValues,
  statLabel,
  yAxis,
  width,
  height,
}: ITimelineGraph) {
  const values = useMemo(() => {
    if (!statValues) return [];
    return statValues.map((v) => ({
      ...v,
      value: v[yAxis.dataKey] || 0,
      time: "tickDate" in v ? v.tickDate : `${v.tickYear}-${v.tickMonth}-01`,
    }));
  }, [statValues, yAxis.dataKey]);

  // isMonthlyView false by default
  const isMonthlyView = !(statValues[0] && "tickDate" in statValues[0]);

  return (
    <LineGraphV2
      values={values}
      height={height}
      statLabel={{
        color: statLabel.color,
        name: statLabel.name,
        dataKey: statLabel.dataKey,
      }}
      xAxis={{
        dataKey: "time",
        label: "Timeline",
        tooltipFormatter: isMonthlyView
          ? (val) => monthAndYearFormatter(val, { formatYear: false })
          : (a) => format(new Date(a), "EEEE, dd LLL"),
        tickFormatter: isMonthlyView
          ? (v, i) => monthAndYearFormatter(v)
          : highPerformanceDateFormatter,
      }}
      width={width}
      yAxis={{
        tooltipFormatter: yAxis.tooltipFormatter,
        tickFormatter: yAxis.tickFormatter,
        dataKey: yAxis.dataKey,
        label: yAxis.label,
      }}
    />
  );
}
