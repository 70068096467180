import classNames from "classnames";
import { Icon, IconProps } from "../Icon/Icon";
import styles from "./IconWithWrapper.module.scss";

export interface IconWithWrapperProps {
  icon: React.FC<React.SVGAttributes<SVGElement>>;
  size?: "sm" | "md" | "lg" | "xl";
  isBordered?: boolean;
  badge?: React.FC<React.SVGAttributes<SVGElement>>;
  fullRounded?: boolean;
  badgeColor?: IconProps["iconColor"];
  iconColor?:
    | "danger"
    | "info"
    | "warning"
    | "success"
    | "default"
    | "primary"
    | "inherit"
    | "transparent";
}

export function IconWithWrapper({
  icon,
  size = "md",
  badge,
  fullRounded,
  badgeColor,
  iconColor = "default",
  isBordered = false,
}: IconWithWrapperProps) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.sm]: size === "sm",
        [styles.md]: size === "md",
        [styles.lg]: size === "lg",
        [styles.xl]: size === "xl",
        [styles.colorPrimary]: iconColor === "primary",
        [styles.colorDanger]: iconColor === "danger",
        [styles.colorInfo]: iconColor === "info",
        [styles.colorWarning]: iconColor === "warning",
        [styles.colorSuccess]: iconColor === "success",
        [styles.colorDefault]: iconColor === "default",
        [styles.colorInherit]: iconColor === "inherit",
        [styles.colorTransparent]: iconColor === "transparent",
        [styles.bordered]: isBordered,
        [styles.fullRounded]: fullRounded,
      })}
    >
      <Icon icon={icon} size={size} iconColor={iconColor} />
      {badge && (
        <div
          className={classNames(
            "absolute bottom-[-2px] right-[-2px] rounded-8",
            {
              [styles.colorPrimary]: badgeColor === "primary",
              [styles.colorDanger]: badgeColor === "danger",
              [styles.colorInfo]: badgeColor === "info",
              [styles.colorWarning]: badgeColor === "warning",
              [styles.colorSuccess]: badgeColor === "success",
              [styles.colorDefault]: badgeColor === "default",
              [styles.colorInherit]: badgeColor === "inherit",
              [styles.colorTransparent]: badgeColor === "transparent",
              [styles.bordered]: isBordered,
            },
          )}
        >
          <Icon icon={badge} iconColor={badgeColor} />
        </div>
      )}
    </div>
  );
}
