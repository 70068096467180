import endOfYear from "date-fns/endOfYear";
import startOfYear from "date-fns/startOfYear";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "src/components/Header/Header";
import { SortByInput } from "../../../graphql/generated/schema";
import { BarChartSquareUp } from "@untitled-ui/icons-react";
import { UnlockFeatures } from "src/components/UnlockFeatures/UnlockFeatures";

export default function Reports() {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const [dateFilter, setDateFilter] = useState({
    gte: startOfYear(new Date()),
    lte: endOfYear(new Date()),
  });
  const [sortBy, setSortBy] = useState<SortByInput>({
    direction: "DESC",
    field: "CREATED_AT",
  });
  const [selectedTab, setSelectedTab] = useState("REPORTS");

  return (
    <>
      {/* <PageHeader
        title="Reports"
        actions={
          <>
            <Searchbar
              value={query}
              onChange={(i) => setQuery(i)}
              input={{ placeholder: "Search reports" }}
            />
            <ButtonV2
              size="small"
              leftIcon={PersonAdd}
              onClick={() => {
                navigate("/reports/new");
              }}
            >
              Create Report
            </ButtonV2>
          </>
        }
      /> */}
      <Header
        title="Reports"
        icon={BarChartSquareUp}
        iconColor="default"
        // actions={
        //   <ButtonV2 size="small" to="/reports/new">
        //     Create Report
        //   </ButtonV2>
        // }
      />
      <div className="flex items-center justify-center h-full">
        <UnlockFeatures
          title="Unlock Reports With Grow Premium"
          description="Access to Reports will be available to all Grow Premium subscribers. Unlock this feature to generate detailed analytics and insights from your customer data."
          unlockType="UPGRADE_GROW"
        />
      </div>
      {/* <ReportsTableHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setDateFilter={setDateFilter}
        dateFilter={dateFilter}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
      {selectedTab === "REPORTS" ? (
        <ReportsInfiniteLoader
          query={query}
          dateFilter={dateFilter}
          sortBy={sortBy}
        />
      ) : (
        <RecurringReportsInfiniteLoader
          query={query}
          dateFilter={dateFilter}
          sortBy={sortBy}
        />
      )} */}
    </>
  );
}
