import { useMemo, useState } from "react";
import { ErrorHandler } from "../../../components/ErrorHandler";
import { Spinner } from "../../../components/Spinner/Spinner";
import {
  SortByInput,
  useGetUserQuery,
  useOrganizationInvitesQuery,
  useOrganizationUsersQuery,
} from "../../../graphql/generated/schema";
import { LegacySectionHeading } from "../../global/components/LegacySectionHeading";
import { useDebouncedVariable } from "../../global/hooks/useDebouncedVariable";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "../../global/misc/dateFilterUtils";
import { AddMembersModal } from "../components/AddMembersModal";
import { ManageMemberModal } from "../components/ManageMemberModal";
import { OrganizationInvitesTable } from "../components/OrganizationInvitesTable";
import { OrganizationMembersTable } from "../components/OrganizationMembersTable";
import { OrganizationMembersTableHeader } from "../components/OrganizationMembersTableHeader";
import styles from "./OrganizationManageMembersPage.module.scss";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { toast } from "sonner";
import Header from "src/components/Header/Header";
import { UsersPlus } from "@untitled-ui/icons-react";
import { SearchInput } from "src/components/SearchInput/SearchInput";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import defaultSortOptions from "src/modules/global/misc/defaultSortOptions";
import { Dialog } from "src/components/Dialog/Dialog";

type IFilter = {
  name?: string;
  createdAt?: DateFilter;
};

export function OrganizationManageMembersPage() {
  const [filters, setFilters] = useState<IFilter>({
    name: "",
    createdAt: "ALL_TIME",
  });
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [tab, setTab] = useState<"MEMBERS" | "INVITES">("MEMBERS");

  const name = useDebouncedVariable(filters.name, 300);
  const [sortBy, setSortBy] = useState<SortByInput>({
    field: "CREATED_AT",
    direction: "DESC",
  });

  const createdAtFilter = useMemo(() => {
    return getDateFilterFromFilterEnum(filters.createdAt);
  }, [filters.createdAt]);

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useGetUserQuery();

  const {
    data: organizationInvitesData,
    loading: organizationInvitesLoading,
    error: organizationInvitesError,
  } = useOrganizationInvitesQuery({
    variables: {
      filter: {
        ...(name && {
          name: name,
        }),
        ...(createdAtFilter && {
          createdAt: createdAtFilter,
        }),
      },
      sortBy: sortBy,
    },
  });

  const {
    data: organizationUsersData,
    loading: organizationUsersLoading,
    error: organizationUsersError,
  } = useOrganizationUsersQuery({
    variables: {
      filter: {
        ...(name && {
          name: name,
        }),
        ...(createdAtFilter && {
          createdAt: createdAtFilter,
        }),
      },
      sortBy: sortBy,
    },
  });

  const isCurrentUserAdmin =
    userData && userData.me ? userData.me.role === "ADMIN" : false;

  if (userError) {
    return <ErrorHandler error={userError} />;
  }

  if (userLoading) {
    return <Spinner />;
  }

  const creatorId = userData?.me?.Organization?.Creator?.id;

  return (
    <div className={styles.page}>
      <Header
        title={"Members"}
        actions={
          <AddMembersModal
            trigger={
              <ButtonV2
                as={isCurrentUserAdmin ? Dialog.Trigger : "button"}
                onClick={() => {
                  if (!isCurrentUserAdmin) {
                    return toast.error("Members cannot invite other members");
                  }
                }}
                size="small"
                disabled={!isCurrentUserAdmin}
                rightIcon={UsersPlus}
              >
                Invite Member
              </ButtonV2>
            }
          />
        }
      />

      <div className={styles.container}>
        <OrganizationMembersTableHeader
          selectedTab={tab}
          setSelectedTab={setTab}
        />

        {tab === "MEMBERS" ? (
          <OrganizationMembersTable
            creatorId={creatorId}
            triggerManageMemberModal={setSelectedId}
            error={organizationUsersError}
            loading={organizationUsersLoading}
            isCurrentUserAdmin={isCurrentUserAdmin}
            organizationUsers={
              organizationUsersData && organizationUsersData.organizationUsers
                ? organizationUsersData.organizationUsers.edges.map(
                    (v) => v.node,
                  )
                : []
            }
          />
        ) : (
          <OrganizationInvitesTable
            error={organizationInvitesError}
            loading={organizationInvitesLoading}
            isCurrentUserAdmin={isCurrentUserAdmin}
            organizationInvites={
              organizationInvitesData &&
              organizationInvitesData.organizationInvites
                ? organizationInvitesData.organizationInvites.edges.map(
                    (v) => v.node,
                  )
                : []
            }
          />
        )}
      </div>
      {/* </div> */}
      {Boolean(selectedId) && (
        <ManageMemberModal
          selectedUserId={selectedId}
          isOpen={Boolean(selectedId)}
          onClose={() => {
            setSelectedId(null);
          }}
        />
      )}
      {/*
      {Boolean(selectedMemberForRoleChange) && (
        <ChangeMemberRoleModal
          isOpen={Boolean(selectedMemberForRoleChange)}
          onClose={() => setSelectedMemberForRoleChange(null)}
          memberId={selectedMemberForRoleChange}
        />
      )}
      {Boolean(selectedMemberForClientAdd) && (
        <AddMemberToClientModal
          isOpen={Boolean(selectedMemberForClientAdd)}
          onClose={() => {
            setSelectedMemberForClientAdd(null);
          }}
        />
      )}
      */}
    </div>
  );
}
