import styles from "./StaticAdFieldsShowcase.module.scss";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { AdSingleImageOrVideoFields } from "./AdSingleImageOrVideoFields";
import { AdCarouselFields } from "./AdCarouselFields";

export function StaticAdFieldsShowcase({ selectedAdIndex, selectedAd }) {
  return (
    <div className={styles.adFields}>
      {selectedAd.LineItems.length === 1 ? (
        <AdSingleImageOrVideoFields
          selectedAd={selectedAd}
          selectedAdIndex={selectedAdIndex}
        />
      ) : (
        <AdCarouselFields
          selectedAd={selectedAd}
          selectedAdIndex={selectedAdIndex}
        />
      )}
    </div>
  );
}
