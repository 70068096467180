import styles from "./MetaLeadFormPreview.module.scss";
import { SmartphoneShell } from "src/modules/global/components/SmartphoneShell";
import { MetaLeadCreateTypes } from "src/modules/creative/misc/metaLeadCreateTypes";
import { FacebookMetaLeadFormPreview } from "./FacebookLeadFormPreview";
import { InstagramMetaLeadFormPreview } from "./InstagramLeadFormPreview";

export interface IMetaLeadFormPreview {
  userName: string;
  leadForm: MetaLeadCreateTypes;
  userAvatar: string;
  platform: "INSTAGRAM" | "FACEBOOK";
  screenIndex?: number;
}

export function MetaLeadFormPreview({
  userName,
  leadForm,
  userAvatar,
  platform,
  screenIndex,
}: IMetaLeadFormPreview) {
  return (
    <div className={styles.wrapper}>
      <SmartphoneShell>
        {platform === "INSTAGRAM" ? (
          <InstagramMetaLeadFormPreview
            userName={userName}
            screenIndex={screenIndex}
            leadForm={leadForm}
            userAvatar={userAvatar}
          />
        ) : (
          <FacebookMetaLeadFormPreview
            userName={userName}
            screenIndex={screenIndex}
            leadForm={leadForm}
            userAvatar={userAvatar}
          />
        )}
      </SmartphoneShell>
    </div>
  );
}
