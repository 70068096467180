import Flex from "../Flex/Flex";
import { Text } from "../Text/Text";

interface WidgetHeaderProps {
  title: React.ReactNode;
  action?: React.ReactNode;
}

export function WidgetHeader({ title, action }: WidgetHeaderProps) {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text size="lg" weight="medium">
        {title}
      </Text>
      {action}
    </Flex>
  );
}
