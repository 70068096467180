import { Tabs } from "src/components/Tabs/Tabs";
import styles from "./NotificationList.module.scss";
import NotificationItem from "./NotificationItem";
import { RadioGroup } from "@headlessui/react";
import notificationsCount from "../../../graphql/notificationsCount";
import { useNotificationMarkAsReadMutation } from "src/graphql/generated/schema";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "src/components/Spinner/Spinner";
import { INotification } from "../misc/notification";
import { useState } from "react";

interface INotificationList {
  hasMoreNotifications: boolean;
  fetchMoreNotifications: () => Promise<void>;
  notifications: INotification[];
}

export default function NotificationList({
  notifications,
  fetchMoreNotifications,
  hasMoreNotifications,
}: INotificationList) {
  const [markAsRead] = useNotificationMarkAsReadMutation({
    awaitRefetchQueries: true,
    refetchQueries: ["notificationsCount"],
  });
  const [activeNotification, setActiveNotification] = useState(null);

  return (
    <div>
      <InfiniteScroll
        dataLength={notifications.length}
        scrollableTarget="scrollTargetForInfiniteScroll"
        next={fetchMoreNotifications}
        hasMore={hasMoreNotifications}
        loader={<Spinner height={200} />}
      >
        <div className="flex flex-col gap-16">
          {notifications.map((notification, idx) => (
            <NotificationItem
              isActive={activeNotification === notification.id}
              setIsActive={(id) => {
                setActiveNotification(id);
              }}
              notification={notification}
              markAsRead={markAsRead}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
