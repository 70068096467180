import styles from "./PlatformIconList.module.scss";

type PlatformID = "META" | "GOOGLE" | "EMAIL" | "WHATSAPP";

export interface IPlatformIconList {
  platforms: {
    icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    coloredIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    isActive: boolean;
  }[];
}

export function PlatformIconList({ platforms }: IPlatformIconList) {
  return (
    <div className={styles.list}>
      {platforms.map((platform) => {
        const CIcon = platform.coloredIcon;
        const Icon = platform.icon;
        const shouldShowColored = platform.isActive;

        if (shouldShowColored) {
          return <CIcon width={16} height={16} />;
        }

        return <Icon color="var(--color-neutral-500)" width={16} height={16} />;
      })}
    </div>
  );
}
