import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./LineHeading.module.scss";

export interface ILineHeading {
  icon?: React.ReactNode;
  content: ReactNode;
  className?: string;
  tone?: "default" | "subdued";
}

export function LineHeading({
  icon,
  content,
  className,
  tone = "default",
}: ILineHeading) {
  return (
    <div
      className={classNames(styles.heading, className, {
        [styles.subdued]: tone === "subdued",
      })}
    >
      <div className={styles.contentWrapper}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <h2 className={styles.copy}>{content}</h2>
      </div>
    </div>
  );
}
