import {
  ActiveClientQuery,
  useClientUpdateMutation,
} from "src/graphql/generated/schema";
import { ClientCreateDialogLayout } from "./ClientCreateDialogLayout";
import { useState } from "react";
import createClient1Image from "../assets/createClient1.png";
import { CreateClientModal1 } from "./CreateClientModal1";
import { toast } from "sonner";
import { ClientCreateWizardInput } from "../misc/ClientCreateWizardInput";

type IClientEditModal = {
  trigger: React.ReactNode;
  initialValues: ActiveClientQuery["activeClient"];
};

export function ClientEditModal({ trigger, initialValues }: IClientEditModal) {
  const [isOpen, setIsOpen] = useState(false);
  const [clientUpdate] = useClientUpdateMutation();

  async function handleSubmit(values: ClientCreateWizardInput) {
    try {
      await clientUpdate({
        variables: {
          input: {
            id: initialValues.id,
            name: values.clientCreate.name,
            assetId: values.clientCreate.assetId,
            analyticsCategory: values.clientCreate.analyticsCategory,
            currencyCode: values.clientCreate.currencyCode,
            timeZone: values.clientCreate.timeZone,
            industry: values.clientCreate.industry,
          },
        },
      });

      toast.success("Client updated successfully");
    } catch (e) {
      toast.error("Failed to update client");
    } finally {
      setIsOpen(false);
    }
  }

  function handleBack() {
    setIsOpen(false);
  }

  return (
    <ClientCreateDialogLayout
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      trigger={trigger}
      isEdit
      image={{
        src: createClient1Image,
        alt: "image of ai assistant",
        width: 600,
        height: 400,
      }}
    >
      <CreateClientModal1
        heading="Edit Client"
        subheading="Edit client information"
        disableCurrencyField
        submitLabel="Save Changes"
        hideInviteMembers
        initialValues={{
          clientCreate: {
            name: initialValues.name,
            invitedMembers: [],
            assetId: initialValues.Thumbnail?.id,
            analyticsCategory: initialValues.analyticsCategory,
            currencyCode: initialValues.currencyCode,
            timeZone: initialValues.timeZone,
            industry: initialValues.industry,
          },
          // empty data because not used in this step
          clientProfile: {
            brandName: "",
            brandColor: "",
            about: "",
            brandLogoId: "",
            audienceMinAge: 0,
            audienceMaxAge: 0,
            audienceGender: "ALL",
            locations: [],
            audienceInterests: "",
            industry: "",
          },
        }}
        onBack={handleBack}
        onSubmit={handleSubmit}
      />
    </ClientCreateDialogLayout>
  );
}
