import { InteractiveInputCard } from "src/components/InteractiveInputCard/InteractiveInputCard";
import { Grid } from "src/components/Grid/Grid";
import audiencePresets from "../misc/audiencePresets";
import { Star01 } from "@untitled-ui/icons-react";
import { AudiencePresetValue } from "../misc/audienceCreateTypes";
import { upsertItemInObject } from "../functions/upsertItemInObject";

interface IAudiencePresetSelectorLoader {
  selectedPresets: Record<string, AudiencePresetValue>;
  setSelectedPresets: (value: Record<string, AudiencePresetValue>) => void;
}

export function AudiencePresetSelectorLoader({
  selectedPresets,
  setSelectedPresets,
}: IAudiencePresetSelectorLoader) {
  return (
    <Grid column={{ xs: 4 }} columnGap={{ xs: 8 }} rowGap={{ xs: 12 }}>
      {audiencePresets.map((audiencePreset) => (
        <InteractiveInputCard
          key={audiencePreset.id}
          variant="card"
          icon={audiencePreset.icon}
          value={selectedPresets[audiencePreset.id]?.value ?? "NONE"}
          onChange={(value) => {
            setSelectedPresets(
              upsertItemInObject(selectedPresets, audiencePreset.id, {
                ...audiencePreset,
                value: value as any,
              }),
            );
          }}
          title={audiencePreset.name}
          inputType="SWITCH"
          hasDelete={false}
          description={audiencePreset.description}
        />
      ))}
    </Grid>
  );
}
