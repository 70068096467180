import { CreativeTemplateQuery } from "src/graphql/generated/schema";
import {
  EditCreativeTypes,
  GlobalCreativeEditFields,
  MetaCreativeEditFields,
} from "./creativeCreateTypesV2";
import { generateLeadCreateType } from "./generateLeadCreateType";
import { generatePageWelcomeMessageInput } from "./generatePageWelcomeMessageInput";

let defaultInitialValues: EditCreativeTypes = {
  id: "",
  name: "",
  platforms: ["META"],
  global: {
    id: "",
    primaryText: "",
    LineItems: [],
  },
  meta: {
    id: "",
    type: "SINGLE_IMAGE_OR_VIDEO",
    edited: false,
    primaryText: "",
    LineItems: [],
    crop: "DEFAULT",
  },
  whatsapp: {
    edited: false,
  },
};

export function generateCreativeInputFromCreativeTemplateQuery({
  creativeTemplateData,
}: {
  creativeTemplateData: CreativeTemplateQuery;
}) {
  const template = creativeTemplateData?.creativeTemplate || null;
  const global: GlobalCreativeEditFields = template.DefaultTemplateField
    ? {
        id: template.DefaultTemplateField.id,
        LineItems: template.DefaultTemplateField.LineItems as any[],
        primaryText: template.DefaultTemplateField.primaryText,
      }
    : null;
  const meta: MetaCreativeEditFields = template.MetaActivityAdTemplate
    ? {
        id: template.MetaActivityAdTemplate.id,
        LineItems: template.MetaActivityAdTemplate.LineItems.map((v) => ({
          id: v.id,
          PostAsset: v.PostAsset,
          order: v.order,
          StoryAsset: v.StoryAsset,
          description: v.description,
          headline: v.headline,
          link: v.link,
        })),
        edited: true,
        primaryText: template.MetaActivityAdTemplate.primaryText,
        type:
          template.MetaActivityAdTemplate.adType === "CUSTOM_CREATIVE"
            ? template.MetaActivityAdTemplate?.LineItems.length > 1
              ? "CAROUSEL"
              : "SINGLE_IMAGE_OR_VIDEO"
            : template.MetaActivityAdTemplate.adType === "CATALOG"
            ? "CATALOGUE_AD"
            : "EXISTING_POST",
        ...(template.MetaActivityAdTemplate.Catalogue?.id && {
          catalogueId: template.MetaActivityAdTemplate.Catalogue.id,
        }),
        ...(template.MetaActivityAdTemplate.metaProductSetId && {
          productSetId: template.MetaActivityAdTemplate.metaProductSetId,
        }),
        ...(template.MetaActivityAdTemplate.existingPostId && {
          existingPostId: template.MetaActivityAdTemplate.existingPostId,
        }),
        ...(template.MetaActivityAdTemplate.MetaActivityLeadGenFormTemplate && {
          leadGen: generateLeadCreateType(
            template.MetaActivityAdTemplate.MetaActivityLeadGenFormTemplate,
          ),
        }),
        ...(template.MetaActivityAdTemplate.PageWelcomeMessage && {
          pageWelcomeMessage: generatePageWelcomeMessageInput(
            template.MetaActivityAdTemplate.PageWelcomeMessage,
          ),
        }),
      }
    : null;

  const initialValues = {
    ...defaultInitialValues,
    id: template.id,
    name: `${template.name}`,
    platforms: template.platforms,
    ...(global && { global }),
    ...(meta && { meta }),
  };

  return initialValues;
}
