import {
  Announcement03,
  BarChart01,
  File05,
  GitBranch01,
  LayersTwo01,
  Route,
  UserEdit,
  Users01,
  UserUp01,
  Zap,
} from "@untitled-ui/icons-react";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import PaywallLock from "../../modules/global/assets/paywallLock.svg";
import { Text } from "../Text/Text";
import { Icon } from "../Icon/Icon";

interface UnlockFeaturesProps {
  title: string;
  description: string;
  unlockType:
    | "UPGRADE_ENGAGE"
    | "UPGRADE_GROW"
    | "UNLOCK_ENGAGE"
    | "UNLOCK_GROW";
}

const growFeatures = [
  { icon: Route, name: "Cross Channel Campaign Builder" },
  { icon: Announcement03, name: "Meta & Google Ads Channel" },
  { icon: UserUp01, name: "Audience Templates & Insights" },
  { icon: UserEdit, name: "Custom Audience Builder" },
  { icon: File05, name: "Detailed analytics & Reporting" },
];

const engageFeatures = [
  { icon: Route, name: "Cross Channel Campaign Builder" },
  { icon: LayersTwo01, name: "Advanced Contacts Segmentation" },
  { icon: Announcement03, name: "Whatsapp & Email Channel" },
  { icon: GitBranch01, name: "Personalized Automation Sequences" },
  { icon: Users01, name: "Real-time Contact Sync" },
  { icon: File05, name: "Detailed analytics & Reporting" },
];

export function UnlockFeatures({
  title,
  description,
  unlockType,
}: UnlockFeaturesProps) {
  return (
    <div className="flex flex-col w-full max-w-[400px]">
      <img src={PaywallLock} alt="Paywall Lock" className="w-20 h-20 mb-16" />
      <div className="mb-8">
        <Text as="h2" size="lg" weight="medium">
          {title}
        </Text>
      </div>
      <Text size="sm" tone="subdued">
        {description}
      </Text>
      <div className="flex flex-col space-y-12 mt-20">
        {unlockType.includes("GROW") && (
          <>
            <div className="mb-4">
              <Text size="md">Macro Grow Features:</Text>
            </div>
            {growFeatures.map((feature) => (
              <div className="flex items-center gap-x-6">
                <Icon icon={feature.icon} size="md" iconColor="inherit" />
                <Text size="sm" as="span" weight="medium">
                  {feature.name}
                </Text>
              </div>
            ))}
          </>
        )}
        {unlockType.includes("ENGAGE") && (
          <>
            <div className="mb-4">
              <Text size="md">Macro Engage Features:</Text>
            </div>
            {engageFeatures.map((feature) => (
              <div className="flex items-center gap-x-6">
                <Icon icon={feature.icon} size="md" iconColor="inherit" />
                <Text size="sm" as="span" weight="medium">
                  {feature.name}
                </Text>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="flex space-x-12 mt-32">
        <ButtonV2 size="small">
          {unlockType.includes("UPGRADE")
            ? "Upgrade to Premium"
            : unlockType === "UNLOCK_GROW"
            ? "Unlock Grow"
            : "Unlock Engage"}
        </ButtonV2>
        <ButtonV2 size="small" variant="outline">
          Explore all plans
        </ButtonV2>
      </div>
    </div>
  );
}
