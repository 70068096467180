import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styles from "./DropdownV2.module.scss";
import { ReactNode, SVGAttributes } from "react";
import classNames from "classnames";
import { ButtonV2, IButtonV2 } from "src/components/ButtonV2/ButtonV2";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";

type IDropdownOption = {
  icon?: React.FC<SVGAttributes<any>>;
  disabled?: boolean;
  // don't pass if item is disabled
  onClick?: () => void;
  label: string;
};

export type IDropdownV2 = {
  icon?: React.FC<SVGAttributes<any>>;
  buttonVariant?: IButtonV2["variant"];
  size?: IButtonV2["size"];
  hideArrow?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  options: IDropdownOption[];
};

export function DropdownV2({
  icon,
  hideArrow,
  buttonVariant = "outline",
  size,
  children,
  disabled,
  options,
}: IDropdownV2) {
  return (
    <div className={styles.dropdown}>
      <DropdownMenu.Root>
        <ButtonV2
          leftIcon={icon}
          variant={buttonVariant}
          size={size}
          rightIcon={hideArrow ? null : ChevronDown}
          as={DropdownMenu.Trigger}
          disabled={disabled}
        >
          {children}
        </ButtonV2>

        <DropdownMenu.Content className={styles.menuItems}>
          {options.map((option, index) => {
            const Icon = option.icon;

            return (
              <DropdownMenu.Item
                key={index}
                disabled={option.disabled}
                onClick={async (e) => {
                  // stop else the click will propagate to the item
                  e.stopPropagation();
                  if (option.disabled) {
                    return;
                  }
                  if (option.onClick) {
                    option.onClick();
                  }
                }}
                className={classNames(styles.selectOption, {
                  [styles.selectOptionWithIcon]: Icon,
                  [styles.disabled]: option.disabled,
                })}
              >
                {Icon && <Icon width={16} height={16} />}
                <span className={styles.selectOptionText}>{option.label}</span>
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}
