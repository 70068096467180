// import { init as initFullStory } from "@fullstory/browser";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Toaster as NotificationSonner } from "sonner";
import { ApolloWrapper } from "./ApolloWrapper";
import { AuthDataLoader } from "./components/AuthDataLoader";
import { Toaster as OldToast } from "./modules/global/components/Toaster";
import { authTokenGet } from "./modules/global/functions/authToken";
import { tracingIdGet } from "./modules/global/functions/tracingId";
import routes from "./routes";
import { ToasterNew } from "./modules/global/components/ToasterNew";
import React from "react";

const router = createBrowserRouter(routes);

// if (import.meta.env.REACT_APP_ENV === "prod") {
//   initFullStory({ orgId: "o-1ZQ8B4-na1" });
// }

export default function App() {
  const userToken = authTokenGet();
  const tracingId = tracingIdGet();

  return (
    <React.StrictMode>
      <ApolloWrapper tracingId={tracingId} userToken={userToken}>
        {/* Passing active client so as it stays updated in ApolloWrapper*/}
        <AuthDataLoader userToken={userToken}>
          <ToasterNew>
            <RouterProvider router={router} />
            <OldToast />
            <NotificationSonner position="top-right" expand={true} />
          </ToasterNew>
        </AuthDataLoader>
      </ApolloWrapper>
    </React.StrictMode>
  );
}
