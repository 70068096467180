import { FormFieldStatus } from "../FormFieldStatus/FormFieldStatus";
import { Label } from "../Label/Label";
import classNames from "classnames";
import styles from "./MultilineInput.module.scss";
import { CharacterCounter } from "src/modules/global/components/CharacterCounter";
import { EmojiPicker } from "../EmojiPicker/EmojiPicker";
import { EmojiClickData } from "emoji-picker-react";
import { ReactNode } from "react";

export interface IMultilineInput {
  label?: string;
  value?: string;
  rows?: number;
  onChange?: (i: string) => void;
  onBlur?: (e: any) => void;
  error?: string;
  isFixedWidth?: boolean;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  // no small size for now
  size?: /** "small" |*/ "default";
  helpText?: string;
  autocomplete?: string;
  showCharacterCount?: boolean;
  maxCharacterCount?: number;
  floatingAction?: ReactNode;
  showEmojiPicker?: boolean;
  onEmojiPick?: (i: EmojiClickData) => void;
}

export function MultilineInput(props: IMultilineInput) {
  const {
    label,
    error,
    autocomplete = "off",
    rows = 3,
    disabled,
    helpText,
    value,
    isFixedWidth = false,
    onBlur,
    name,
    onChange,
    placeholder,
    size = "default",
    showCharacterCount,
    maxCharacterCount,
    onEmojiPick,
    showEmojiPicker,
    floatingAction,
  } = props;

  const hasFloatingActions =
    showCharacterCount || showEmojiPicker || !!floatingAction;

  return (
    <div>
      {label && (
        <div className={styles.labelWrapper}>
          <Label
            label={label}
            status={error ? "error" : "default"}
            htmlFor={name}
            helpText={helpText}
          />
        </div>
      )}

      <div
        className={classNames(styles.textareaWrapper, {
          [styles.defaultSize]: size === "default",
          // [styles.smallSize]: size === "small",
          [styles.errorStatus]: error,
          [styles.fullWidth]: !isFixedWidth,
          [styles.fixedWidth]: isFixedWidth,
          [styles.disabledState]: disabled,
        })}
      >
        <textarea
          className={styles.textarea}
          autoComplete={autocomplete}
          rows={rows}
          disabled={disabled}
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          placeholder={placeholder}
        />

        {hasFloatingActions && (
          <div className={styles.floatingActions}>
            <div>
              {showEmojiPicker && <EmojiPicker onSelect={onEmojiPick} />}
            </div>

            <div className={styles.rightFloatingActions}>
              {showCharacterCount && (
                <CharacterCounter
                  maxCharacterCount={maxCharacterCount}
                  characterCount={value?.length ?? 0}
                />
              )}
              {!!floatingAction && floatingAction}
            </div>
          </div>
        )}
      </div>

      {error && <FormFieldStatus message={error} type={"error"} />}
    </div>
  );
}
