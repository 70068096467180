import metaImage from "../../../icons/meta.svg?react";
import whatsappImage from "../../../icons/whatsapp.svg?react";
import emailImage from "../../../icons/email.svg?react";
import googleAdsImage from "../../../icons/google-ads.svg?react";

export const platformOptions = [
  {
    value: "META",
    image: metaImage,
    title: "Meta",
    subtitle: "Targeting based on behaviour",
  },
  {
    value: "WHATSAPP",
    image: whatsappImage,
    title: "Whatsapp",
    subtitle: "Multimedia content supported",
  },
  {
    value: "EMAIL",
    image: emailImage,
    title: "Emails",
    subtitle: "Direct engage with your customers",
  },
  {
    value: "GOOGLE",
    image: googleAdsImage,
    title: "Google Ads",
    subtitle: "Targeted ads to reach your ideal audience",
  },
];
