import { AudienceAudienceSetIndexesQuery } from "src/graphql/generated/schema";
import { getAudienceAudienceSetSourcePlatform } from "src/modules/audience/misc/getAudienceAudienceSetSourcePlatforms";
import FacebookIcon from "../../../icons/facebook_in_circle.svg?react";
import InstagramIcon from "../../../icons/instagram.svg?react";
import WebsiteIcon from "../../../icons/web.svg?react";
import { SVGProps } from "react";
import { AUDIENCE_PLATFORMS } from "src/modules/audience/misc/audienceListingTypes";
import { formatNumber } from "src/lib/formatNumber";
import styles from "./AudienceSelectorItem.module.scss";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import { AudienceCategoryBadges } from "src/modules/global/components/AudienceCategoryBadges";
import { useFormikContext } from "formik";
import { ActivityInputV2, AdsetInputV2 } from "../misc/createCampaignTypes";
import { useAudienceAggregatedStats } from "src/modules/global/functions/useAudienceAggregatedStats";
import { useAudienceSetAggregatedStats } from "src/modules/global/functions/useAudienceSetAggregatedStats";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import classNames from "classnames";
import RoasIcon from "@material-symbols/svg-600/rounded/autorenew.svg?react";
import ClickIcon from "@material-symbols/svg-600/rounded/trackpad_input.svg?react";

interface IAudienceSelectorItem {
  audience: AudienceAudienceSetIndexesQuery["audienceAudienceSetIndexes"]["edges"][0];
}

export function AudienceSelectorItem({ audience }: IAudienceSelectorItem) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();

  const audiencePlatforms = getAudienceAudienceSetSourcePlatform(audience.node);
  const item = audience.node.audienceId
    ? audience.node.Audience
    : audience.node.MetaAudienceSet;

  const selectedAudiences = values.Adsets ?? [];
  const isSelected = !!selectedAudiences.find(
    (adset) => adset.templateId === audience.node.id,
  );

  function handleChange() {
    if (isSelected) {
      return setFieldValue(
        "Adsets",
        selectedAudiences.filter((s) => s.templateId !== audience.node.id),
      );
    }

    setFieldValue("Adsets", [
      ...selectedAudiences,
      {
        budget: "",
        type: audience.node.audienceId ? "AUDIENCE" : "AUDIENCE_SET",
        audienceId: audience.node.audienceId,
        audienceSetId: audience.node.metaAudienceSetId,
        ProductSetSpecs: [],
        templateId: audience.node.id,
      } as AdsetInputV2,
    ]);
  }

  const hasEstimateSize = item.MetaInsights?.lowerBoundCount;

  return (
    <div
      onClick={() => {
        handleChange();
      }}
      className={classNames(styles.item, {
        [styles.selectedItem]: isSelected,
      })}
    >
      <span>
        <Checkbox
          checked={isSelected}
          value={audience.node.id}
          onChange={handleChange}
        />
      </span>

      <span className={styles.titleContainer}>
        <span className={styles.platformIcons}>
          {audiencePlatforms.map((platform) => (
            <PlatformIcons platform={platform} />
          ))}
        </span>
        <span
          className={classNames(styles.title, {
            [styles.titleSeparator]: hasEstimateSize,
          })}
        >
          {audience.node.name}
        </span>
        {hasEstimateSize && (
          <span className={styles.estimateSize}>
            Est. size: {formatNumber(item.MetaInsights.lowerBoundCount)} -
            {formatNumber(item.MetaInsights.upperBoundCount)}
          </span>
        )}
      </span>

      <AudienceCategoryBadges
        categories={audience.node.MetaAudienceSet?.AudienceCategories}
      />

      <Stats
        id={item.id}
        type={audience.node.audienceId ? "AUDIENCE" : "AUDIENCE_SET"}
      />
    </div>
  );
}

interface IStats {
  id: string;
  type: "AUDIENCE" | "AUDIENCE_SET";
}

function Stats({ id, type }: IStats) {
  if (type === "AUDIENCE") {
    return <AudienceStats id={id} />;
  }

  return <AudienceSetStats id={id} />;
}

function AudienceStats({ id }: { id: string }) {
  const { data } = useAudienceAggregatedStats(id, {
    dateFrom: null,
    dateTo: null,
  });

  if (data) {
    return (
      <>
        <span className={styles.statItem}>
          {formatNumberByType(data.roas, "MULTIPLIER")}
          <RoasIcon className={styles.statIcon} width={16} height={16} />
        </span>
        <span className={styles.statItem}>
          {formatNumberByType(data.ctr, "PERCENTAGE")}
          <ClickIcon className={styles.statIcon} width={16} height={16} />
        </span>
      </>
    );
  }

  return (
    <>
      <span className={styles.statItem}>
        - <RoasIcon className={styles.statIcon} width={16} height={16} />
      </span>
      <span className={styles.statItem}>
        - <ClickIcon className={styles.statIcon} width={16} height={16} />
      </span>
    </>
  );
}

function AudienceSetStats({ id }: { id: string }) {
  const { data } = useAudienceSetAggregatedStats(id, {
    dateFrom: null,
    dateTo: null,
  });

  if (data) {
    return (
      <>
        <span className={styles.statItem}>
          {formatNumberByType(data.roas, "MULTIPLIER")}
          <RoasIcon className={styles.statIcon} width={16} height={16} />
        </span>
        <span className={styles.statItem}>
          {formatNumberByType(data.ctr, "PERCENTAGE")}
          <ClickIcon className={styles.statIcon} width={16} height={16} />
        </span>
      </>
    );
  }

  return (
    <>
      <span className={styles.statItem}>
        - <RoasIcon className={styles.statIcon} width={16} height={16} />
      </span>
      <span className={styles.statItem}>
        - <ClickIcon className={styles.statIcon} width={16} height={16} />
      </span>
    </>
  );
}

interface IPlatformIcons extends SVGProps<any> {
  platform: AUDIENCE_PLATFORMS;
}

function PlatformIcons({ platform }: IPlatformIcons) {
  switch (platform) {
    case "META":
      return (
        <FacebookIcon color="var(--color-disabled)" width={16} height={16} />
      );
    case "INSTAGRAM":
      return (
        <InstagramIcon color="var(--color-disabled)" width={16} height={16} />
      );
    default:
      return (
        <WebsiteIcon color="var(--color-disabled)" width={16} height={16} />
      );
  }
}
