import { addZeroIfNeeded } from "src/lib/addZeroIfNeeded";

export function convertDatetimeToDate(dateTime: Date): string {
  if (!dateTime) return null;

  const date = dateTime.getDate();

  // Months are zero-based
  const month = dateTime.getMonth() + 1;

  const year = dateTime.getFullYear();
  const addZero = addZeroIfNeeded;

  return `${addZero(year)}-${addZero(month)}-${addZero(date)}`;
}
