import {
  MetaActivityAdInput,
  MetaAdTypeEnum,
} from "src/graphql/generated/schema";
import {
  CreativeCreateTypesV2,
  EditCreativeTypes,
  MetaLineItemEditFields,
  MetaLineItemFields,
} from "./creativeCreateTypesV2";

export function generateInputFromLineItems(
  lineItems: Array<MetaLineItemFields | MetaLineItemEditFields>,
) {
  return lineItems.map(({ PostAsset, StoryAsset, ...v }, index) => ({
    ...v,
    order: v.order || index,
    postAssetId: PostAsset?.id,
    storyAssetId: StoryAsset?.id,
  }));
}

export function generateInputFromActivityAdTemplate(
  input: CreativeCreateTypesV2 | EditCreativeTypes,
) {
  const type: MetaAdTypeEnum =
    input.meta.type === "EXISTING_POST"
      ? "EXISTING_POST"
      : input.meta.type === "CATALOGUE_AD"
      ? "CATALOG"
      : "CUSTOM_CREATIVE";

  const baseValues = {
    ...("id" in input && {
      id: input.meta.id,
    }),
    primaryText: input.meta.primaryText,
    name: input.name,
    adType: type,
    MetaProductSetId: input.meta.productSetId,
    catalogueId: input.meta.catalogueId,
    link: input.meta.link,
    existingPostId: input.meta.existingPostId,
    LineItems: generateInputFromLineItems(input.meta.LineItems),
  };

  return baseValues;
}
