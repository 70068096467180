import ArrowDown from "@material-symbols/svg-600/rounded/keyboard_arrow_down.svg?react";
import LanguageIcon from "@material-symbols/svg-600/rounded/language.svg?react";
import LogoutIcon from "@material-symbols/svg-600/rounded/logout.svg?react";
import * as Popover from "@radix-ui/react-popover";
import ExploreIcon from "@material-symbols/svg-600/rounded/explore.svg?react";
import PlusIcon from "@material-symbols/svg-600/rounded/add.svg?react";
import classNames from "classnames";
import React, { useContext, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { User, useCanCreateNewClientQuery } from "src/graphql/generated/schema";
import {
  useActiveClientGet,
  activeClientSet,
} from "src/modules/global/functions/activeClient";
import AuthContext from "src/stores/AuthContext";
import styles from "./Sidebar.module.scss";
import { CommandBar } from "src/components/CommandBar/CommandBar";
import { getOS } from "../misc/getOS";
import { ClientThumbnail } from "./ClientThumbnail";
import { Text } from "src/components/Text/Text";

interface SidebarConfig {
  logo: {
    src: string;
    alt: string;
  };
  primary: {
    label: string;
    link: string;
    icon: JSX.Element;
    children?: {
      label: string;
      link: string;
    }[];
  }[];
}

export interface ISidebar {
  config: SidebarConfig;
  user?: User;
}

export function Sidebar({ config, user }: ISidebar) {
  const [showCommandK, setShowCommandK] = useState(false);
  const { logout } = useContext(AuthContext);
  const location = useLocation();
  const [userPanelActive, setUserPanelActive] = useState(false);
  // const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const activeClient = useActiveClientGet();
  const navigate = useNavigate();
  // const { data } = useNotificationsCountQuery({
  //   pollInterval: 12 * 1000,
  //   variables: {
  //     filter: { readStatus: "UNREAD" },
  //   },
  // });
  const { data: canCreateClientData } = useCanCreateNewClientQuery();

  //??????????
  React.useEffect(() => {
    const down = (e) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setShowCommandK((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  function isActivePath(link: string) {
    // to check for / (root) links
    if (link === "/") {
      return location.pathname === "/";
    }
    const currentLinkWithSearch = location.pathname + location.search;
    return currentLinkWithSearch.includes(link);
  }

  function switchClient(clientId: string) {
    const client = user.Clients.find((c) => c.id === clientId);

    activeClientSet(client);
    window.location.href = "/";
  }

  // useEffect(() => {
  //   // block scrolling in the background of slidebar
  //   if (isNotificationOpen) {
  //     document.body.style.overflowY = "hidden";
  //   } else {
  //     document.body.style.overflowY = "scroll";
  //   }
  // }, [isNotificationOpen]);

  // const notificationsCount = data?.notificationsCount;
  const userOS = useMemo(() => getOS(), []);

  const isProduction = import.meta.env.VITE_ENV === "production";

  return (
    <aside
      className={classNames(styles.container, {
        [styles.userPanelActive]: userPanelActive,
      })}
    >
      <div>
        {/*
        <CreateClientModal
          isOpen={showClientCreateModal}
          onClose={() => {
            setShowClientCreateModal(false);
          }}
        />
      */}
      </div>
      <div className={styles.sidebarHeader}>
        <div
          className={classNames(styles.userPanel, {
            [styles.userPanelActive]: userPanelActive,
          })}
        >
          <Popover.Root>
            <Popover.Trigger asChild>
              <button
                className={styles.userPanelTrigger}
                type="button"
                onClick={() => {
                  setUserPanelActive(!userPanelActive);
                }}
              >
                {activeClient && (
                  <ClientThumbnail
                    // asset={activeClient.Thumbnail}
                    clientName={activeClient.name}
                    size="24px"
                  />
                )}
                <div className={styles.userPanelTriggerDetails}>
                  <span className={styles.clientName}>
                    <Text as={"h1"} size="lg" font="redhat" weight="medium">
                      {activeClient?.name}
                    </Text>
                  </span>
                </div>
                <span className={styles.arrowDown}>
                  <ArrowDown />
                </span>
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                className={styles.PopoverContent}
                sideOffset={10}
                align="start"
                alignOffset={5}
              >
                <div
                  className={classNames(
                    styles.clientsMenu,
                    styles.userPanelSubmenu,
                  )}
                >
                  {user.Clients.map((client) => (
                    <button
                      type="button"
                      key={client.id}
                      className={styles.listItem}
                      onClick={() => {
                        switchClient(client.id);
                      }}
                    >
                      <ClientThumbnail
                        asset={client.Thumbnail}
                        clientName={client.name}
                        size="24px"
                      />
                      <span
                        className={styles.label}
                        style={{ position: "relative", top: 1.5 }}
                        title={client.name}
                      >
                        {client.name}
                      </span>
                    </button>
                  ))}
                  {/* <div>
                    {canCreateClientData?.canCreateNewClient?.response ===
                      "OK" && (
                      <a
                        className={classNames(styles.listItem, {
                          [styles.disabled]: user.role !== "ADMIN",
                        })}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (user.role === "ADMIN") {
                            navigate("/settings/clients?createClient=true");
                          }
                        }}
                      >
                        <span className={styles.icon}>
                          <PlusIcon height={16} width={16} fill="#5C5F62" />
                        </span>
                        Add New Client
                      </a>
                    )}
                  </div> */}
                </div>
                <div
                  className={classNames(
                    styles.accountMenu,
                    styles.userPanelSubmenu,
                  )}
                >
                  <Link
                    className={styles.listItem}
                    to={"/settings/integrations"}
                  >
                    <span className={styles.icon}>
                      <ExploreIcon height={16} width={16} fill="#5C5F62" />
                    </span>
                    <span className={styles.label}>Integrations</span>
                  </Link>
                  <Link
                    className={styles.listItem}
                    to={"https://www.macrostack.co/pricing"}
                    target="_blank"
                  >
                    <span className={styles.icon}>
                      <LanguageIcon height={16} width={16} fill="#5C5F62" />
                    </span>
                    <span className={styles.label}>Browse Plans</span>
                  </Link>
                  <Link
                    className={classNames(styles.listItem, styles.logout)}
                    to={"/"}
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                  >
                    <span className={styles.icon}>
                      <LogoutIcon height={16} width={16} fill="#5C5F62" />
                    </span>
                    <span className={styles.label}>Logout</span>
                  </Link>
                </div>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
          {/* <div className={styles.userPanelMenu}>

          </div> */}
        </div>
        {/* <button
          type="button"
          className={classNames(styles.notificationButton, {
            [styles.activeListItem]: isNotificationOpen,
          })}
          onMouseEnter={() => {
            setIsNotificationIconHovered(!isNotificationIconHovered);
          }}
          onMouseLeave={() => {
            setIsNotificationIconHovered(!isNotificationIconHovered);
          }}
          onClick={(e) => {
            e.preventDefault();
            setIsNotificationOpen(!isNotificationOpen);
          }}
        >
          <span className={styles.icon}>
            {isNotificationIconHovered || isNotificationOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 2C16.9706 2 21 6.04348 21 11.0314V20H3V11.0314C3 6.04348 7.02944 2 12 2ZM9.5 21H14.5C14.5 22.3807 13.3807 23.5 12 23.5C10.6193 23.5 9.5 22.3807 9.5 21Z"
                  fill="#312CCB"
                ></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 18H19V11.0314C19 7.14806 15.866 4 12 4C8.13401 4 5 7.14806 5 11.0314V18ZM12 2C16.9706 2 21 6.04348 21 11.0314V20H3V11.0314C3 6.04348 7.02944 2 12 2ZM9.5 21H14.5C14.5 22.3807 13.3807 23.5 12 23.5C10.6193 23.5 9.5 22.3807 9.5 21Z"
                  fill="#5C5F62"
                ></path>
              </svg>
            )}
          </span>
          {!!notificationsCount && (
            <span className={styles.notificationBadge}>
              {Math.min(notificationsCount, 99)}
            </span>
          )}
        </button> */}
      </div>

      <button
        type="button"
        className={styles.sidebarCommandKButton}
        onClick={() => {
          setShowCommandK(!showCommandK);
        }}
      >
        Quick Actions
        <span className={styles.sidebarCommandKShortcut}>
          {userOS === "Mac" ? "⌘ K" : "Ctrl+K"}
        </span>
      </button>

      <CommandBar
        setShowCommandK={setShowCommandK}
        showCommandK={showCommandK}
      />

      <div className={styles.primaryList}>
        {config.primary.map((item, index) => {
          return (
            <div key={index}>
              <Link
                to={item.link}
                className={classNames(styles.listItem, {
                  [styles.activeListItem]: isActivePath(item.link),
                })}
                key={index}
              >
                <span className={styles.icon}>{item.icon}</span>
                <span className={styles.label}>{item.label}</span>
              </Link>
            </div>
          );
        })}
      </div>

      <div className={styles.secondaryList}>
        {/* <a
          className={classNames(styles.listItem, {
            [styles.activeListItem]: isNotificationOpen,
          })}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsNotificationOpen(!isNotificationOpen);
          }}
        >
          <span className={styles.icon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M3.33333 11.6002C3.33333 11.8211 3.51242 12.0002 3.73333 12.0002H12.2667C12.4876 12.0002 12.6667 11.8211 12.6667 11.6002V7.35443C12.6667 4.76554 10.5773 2.66683 8 2.66683C5.42267 2.66683 3.33333 4.76554 3.33333 7.35443V11.6002ZM8 1.3335C11.3137 1.3335 14 4.02915 14 7.35443V12.9335C14 13.1544 13.8209 13.3335 13.6 13.3335H2.4C2.17909 13.3335 2 13.1544 2 12.9335V7.35443C2 4.02915 4.68629 1.3335 8 1.3335ZM6.33333 14.0002H9.66667C9.66667 14.9206 8.92047 15.6668 8 15.6668C7.07953 15.6668 6.33333 14.9206 6.33333 14.0002Z"
                fill="#44474A"
              />
            </svg>
          </span>
          <span className={styles.label}>Notifications</span>
        </a> */}
        <Link
          to={"/settings/organization"}
          className={classNames(styles.listItem, {
            [styles.activeListItem]: isActivePath("/settings"),
          })}
        >
          <span className={styles.icon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_4244_23164)">
                <path
                  d="M6.26369 12.9142L6.65332 13.7905C6.76914 14.0514 6.95817 14.273 7.19747 14.4286C7.43677 14.5841 7.71606 14.6669 8.00146 14.6668C8.28687 14.6669 8.56616 14.5841 8.80546 14.4286C9.04476 14.273 9.23379 14.0514 9.34961 13.7905L9.73924 12.9142C9.87794 12.6033 10.1112 12.3441 10.4059 12.1735C10.7024 12.0025 11.0455 11.9296 11.3859 11.9653L12.3392 12.0668C12.623 12.0968 12.9094 12.0439 13.1637 11.9144C13.418 11.7849 13.6292 11.5844 13.7718 11.3372C13.9146 11.0902 13.9826 10.807 13.9677 10.5221C13.9527 10.2372 13.8553 9.9627 13.6874 9.73201L13.1229 8.95646C12.922 8.67825 12.8146 8.34337 12.8163 8.00016C12.8162 7.65789 12.9246 7.3244 13.1259 7.04757L13.6904 6.27201C13.8583 6.04133 13.9556 5.76688 13.9706 5.48194C13.9856 5.19701 13.9176 4.91386 13.7748 4.66683C13.6322 4.41965 13.4209 4.21916 13.1667 4.08965C12.9124 3.96014 12.626 3.90718 12.3422 3.9372L11.3889 4.03868C11.0484 4.07444 10.7054 4.00158 10.4089 3.83053C10.1136 3.659 9.88025 3.3984 9.74221 3.08609L9.34961 2.20979C9.23379 1.94894 9.04476 1.72731 8.80546 1.57176C8.56616 1.41622 8.28687 1.33345 8.00146 1.3335C7.71606 1.33345 7.43677 1.41622 7.19747 1.57176C6.95817 1.72731 6.76914 1.94894 6.65332 2.20979L6.26369 3.08609C6.12564 3.3984 5.89227 3.659 5.59702 3.83053C5.3005 4.00158 4.95747 4.07444 4.61702 4.03868L3.66072 3.9372C3.37694 3.90718 3.09055 3.96014 2.83627 4.08965C2.58198 4.21916 2.37073 4.41965 2.22813 4.66683C2.08535 4.91386 2.01732 5.19701 2.03231 5.48194C2.0473 5.76688 2.14466 6.04133 2.31258 6.27201L2.87702 7.04757C3.07831 7.3244 3.18671 7.65789 3.18665 8.00016C3.18671 8.34244 3.07831 8.67593 2.87702 8.95276L2.31258 9.72831C2.14466 9.95899 2.0473 10.2334 2.03231 10.5184C2.01732 10.8033 2.08535 11.0865 2.22813 11.3335C2.37088 11.5805 2.58215 11.7809 2.8364 11.9104C3.09064 12.0399 3.37697 12.093 3.66072 12.0631L4.61406 11.9616C4.9545 11.9259 5.29753 11.9987 5.59406 12.1698C5.89041 12.3408 6.12486 12.6015 6.26369 12.9142Z"
                  stroke="#44474A"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.00027 10.0002C9.10484 10.0002 10.0003 9.10473 10.0003 8.00016C10.0003 6.89559 9.10484 6.00016 8.00027 6.00016C6.8957 6.00016 6.00027 6.89559 6.00027 8.00016C6.00027 9.10473 6.8957 10.0002 8.00027 10.0002Z"
                  stroke="#44474A"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4244_23164">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span className={styles.label}>Settings</span>
        </Link>
      </div>
    </aside>
  );
}
