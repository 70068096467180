import { useFormikContext } from "formik";
import styles from "./ClientFieldsForm.module.scss";
import { currencyList } from "src/currency";
import { FormikError } from "../../../components/FormikError/FormikError";
import { OrganizationUserSelector } from "./OrganizationUserSelector";
import { MultiValue } from "react-select";
import { ClientCreateInput } from "../misc/ClientCreateInput";
import { timeZoneOptions } from "src/modules/global/misc/timeZoneOptions";
import clientIndustryOptions from "src/modules/global/misc/clientIndustryOptions";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import SelectV2 from "src/components/SelectV2/SelectV2";

export function ClientFieldsForm({
  showMembers = true,
  disableCurrencySelector = false,
  disableTimeZoneSelector = false,
}) {
  const {
    values,
    setFieldValue,
    submitCount,
    errors,
    touched,
    setFieldTouched,
  } = useFormikContext<ClientCreateInput>();
  return (
    <div className={styles.layout}>
      <InputFormikV2
        label="Add client's name"
        name="name"
        placeholder="Your Client Name"
      />
      <SelectV2
        options={clientIndustryOptions}
        label={"Which industry your client belongs?"}
        value={values.industry}
        onChange={(v) => {
          setFieldValue("industry", v);
        }}
        placeholder="Select Industry"
      />

      <div>
        <SelectV2
          label="Currency"
          placeholder="Currency"
          disabled={disableCurrencySelector}
          options={currencyList.map((v) => ({
            label: v.currencyName,
            value: v.code,
          }))}
          value={values.currencyCode}
          onChange={function (i: any): void {
            setFieldValue("currencyCode", i);
          }}
        />
        {errors && (
          <FormikError
            submitCount={submitCount}
            formikError={errors}
            touched={touched}
            fieldName={`currencyCode`}
          />
        )}
      </div>
      <div>
        <SelectV2
          label="Timezone"
          placeholder="Timezone"
          disabled={disableTimeZoneSelector}
          options={timeZoneOptions}
          value={values.timezoneCode}
          onChange={(i) => {
            setFieldValue("timezoneCode", i);
          }}
        />
        {errors && (
          <FormikError
            submitCount={submitCount}
            formikError={errors}
            touched={touched}
            fieldName={`timezoneCode`}
          />
        )}
      </div>

      {showMembers && (
        <div className={styles.fullColumn}>
          <OrganizationUserSelector
            onChange={(newValues: MultiValue<any>) => {
              const invitedUsers = newValues
                .filter((v) => v.__isNew__)
                .map((v) => v.value);
              const existingUsers = newValues
                .filter((v) => !v.__isNew__)
                .map((v) => v.value);

              setFieldValue("invitedMembers", invitedUsers);
              setFieldValue("members", existingUsers);
              setFieldTouched("members", true);
              setFieldTouched("invitedMembers", true);
            }}
          />

          {errors?.invitedMembers &&
            Array.isArray(errors?.invitedMembers) &&
            errors.invitedMembers.map((_, i) => (
              <FormikError
                formikError={errors}
                fieldName={`invitedMembers[${i}]`}
              />
            ))}
        </div>
      )}
    </div>
  );
}
