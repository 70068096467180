import React, { useState } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import styles from "./AdSelector.module.scss";
import DescriptionIcon from "@material-symbols/svg-600/rounded/description.svg?react";
import { AdSelectorModal } from "./AdSelectorModal";
import { SelectedAdSwitcher } from "./SelectedAdSwitcher";
import { AdPreviewSection } from "./AdPreviewSection";
import { AdEmptyScreen } from "./AdEmptyScreen";
import { AdFieldsSection } from "./AdFieldsSection";

interface AdSelectorProps {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
  values: any;
  setFieldValue: (field: string, value: any) => void;
}

export function AdSelector({
  sectionRef,
  values,
  setFieldValue,
}: AdSelectorProps) {
  const [isAdSelectorModalOpen, setIsAdSelectorModalOpen] = useState(false);

  return (
    <section ref={sectionRef} className={styles.section}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.heading}>
            Choose Your Campaign's Marketing Assets
          </h2>
          <h3 className={styles.subheading}>
            Select from your library of pre-created marketing assets including
            Ads, Emails, SMS and WhatsApp templates
          </h3>
        </div>

        {values.Ads?.length > 0 && (
          <div>
            <ButtonV2
              onClick={() => setIsAdSelectorModalOpen(true)}
              variant="outline"
              leftIcon={DescriptionIcon}
            >
              Select Ad
            </ButtonV2>
          </div>
        )}
      </div>

      <AdSelectorModal
        onSelect={(adTemplates) => {
          if (values.Ads?.length) {
            setFieldValue("Ads", [...values.Ads, ...adTemplates]);
          } else {
            setFieldValue("Ads", adTemplates);
          }
          setIsAdSelectorModalOpen(false);
        }}
        isOpen={isAdSelectorModalOpen}
        onClose={() => setIsAdSelectorModalOpen(false)}
      />

      <div className={styles.adSection}>
        {values.Ads?.length ? (
          <>
            <SelectedAdSwitcher
              onCreateAd={() => setIsAdSelectorModalOpen(true)}
            />
            <AdFieldsSection />
          </>
        ) : (
          <AdEmptyScreen onSelectAd={() => setIsAdSelectorModalOpen(true)} />
        )}

        <AdPreviewSection />
      </div>
    </section>
  );
}
