import { gql } from "@apollo/client";

const CUSTOMER = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      createdAt
      updatedAt
      metaLeadId
      shopifyId
      sourceActivityId
      name
      firstName
      lastName
      email
      phone
      EmailConsent {
        id
        state
        status
      }
      TextMessageConsent {
        id
        state
        status
      }
      WhatsappConsent {
        id
        state
        status
      }
      Notes {
        id
        content
        createdAt
        updatedAt
        userId
      }
      Tags {
        name
        id
        clientId
      }
      ordersCount
      lifetimeValue
      isEmailVerified
      MetaLeadFormSubmissions {
        id
        createdAt
        updatedAt
        label
        value
        isCustomQuestion
        prefillQuestionType
        customQuestionType
        options
      }
      Address {
        id
        country
        countryCode
        city
      }
      Outreaches {
        id
        name
        createdAt
        updatedAt
      }
      Insights {
        averageOrderValue
        deliveryRate
        clickRate
      }
      Segments {
        id
        name
        createdAt
        filter
        updatedAt
        customersCount
      }
    }
  }
`;
// ts-prune-ignore-next
export default CUSTOMER;
