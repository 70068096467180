import { useRef } from "react";
import { Button, IButton } from "../../../../components/Button/Button";
import { useCreateAsset } from "../../../campaign-wizard/functions/useCreateAsset";
import { imageAndVideoMimeType } from "../../../campaign-wizard/misc/supportedAssetTypes";
import Backup from "@material-symbols/svg-600/rounded/backup.svg?react";
import styles from "./FileUploadButton.module.scss";
import toast from "react-hot-toast";

type ButtonWithoutChildren = Omit<IButton, "children">;
interface IFileUploadButton extends ButtonWithoutChildren {
  onUploaded: () => void;
  maxVideoFileSizeInBytes: number;
  maxImageFileSizeInBytes: number;
  minimumHeight?: number;
  minimumWidth?: number;
  loading?: boolean;
}

export function FileUploadButton({
  onUploaded,
  maxVideoFileSizeInBytes,
  maxImageFileSizeInBytes,
  minimumWidth,
  minimumHeight,
  loading,
  color = "primary",
  size = "default",
  style = "solid",
  ...props
}: IFileUploadButton) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleFileUpload, loading: createAssetLoading } = useCreateAsset({
    validMimeType: imageAndVideoMimeType,
    maxVideoFileSizeInBytes: maxVideoFileSizeInBytes,
    maxImageFileSizeInBytes: maxImageFileSizeInBytes,
    minimumHeight: minimumHeight,
    minimumWidth: minimumWidth,
  });

  function handleTrigger() {
    if (!inputRef.current) return;
    inputRef.current.click();
  }

  return (
    <div>
      <input
        ref={inputRef}
        hidden
        multiple={true}
        type="file"
        onChange={async (e) => {
          try {
            if (!e.target.files) {
              return;
            }

            const files = e.target.files;
            const fileLength = e.target.files.length;
            console.log(files, fileLength);

            for (let i = 0; i < fileLength; i++) {
              const file = files[i];
              console.log({ file, i });
              const uploadedFile = await handleFileUpload(file);
              console.log({ uploadedFile });
            }

            onUploaded();
          } catch (err) {
            toast.error(err.message);
          }
        }}
        accept={imageAndVideoMimeType.join(",")}
      />
      <Button
        onClick={handleTrigger}
        loading={loading || createAssetLoading}
        disabled={loading || createAssetLoading}
        color={color}
        style={style}
        size={size}
        {...props}
      >
        <span className={styles.uploadButtonContent}>
          <Backup fill="var(--color-text-disabled)" width={20} height={20} />
          Upload File
        </span>
      </Button>
    </div>
  );
}
