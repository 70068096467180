import { useState, useEffect, useMemo } from "react";
import { SearchInput } from "src/components/SearchInput/SearchInput";
import {
  MetaInterest,
  useMetaInterestsLazyQuery,
} from "src/graphql/generated/schema";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";

interface IInterestSearchInput {
  adAccountId: string;
  onSelect: (interest: MetaInterest) => void;
}

export function InterestSearchInput({
  adAccountId,
  onSelect,
}: IInterestSearchInput) {
  const [searchQuery, setSearchQuery] = useState("");
  const [interestFn, { loading: interestLoading, data: interestData }] =
    useMetaInterestsLazyQuery();
  const searchQueryDebounced = useDebouncedVariable(searchQuery);

  useEffect(() => {
    if (!adAccountId || !searchQueryDebounced) return;

    interestFn({
      variables: {
        adAccountId,
        query: searchQueryDebounced,
        limit: 5,
      },
    });
  }, [searchQueryDebounced, adAccountId, interestFn]);

  let searchedInterests = [];
  if (interestData) {
    searchedInterests = interestData?.metaInterests.map((i) => ({
      label: i.name,
      onClick: () => {
        onSelect(i);
      },
    }));
  }

  return (
    <SearchInput
      value={searchQuery}
      onChange={(i) => setSearchQuery(i)}
      searchResults={searchedInterests}
      loading={interestLoading}
      placeholder="Search for interests"
    />
  );
}
