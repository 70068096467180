import classNames from "classnames";
import styles from "./InputV2.module.scss";
import EyeIcon from "@untitled-ui/icons-react/build/esm/Eye";
import { useState } from "react";
import { Label } from "../Label/Label";
import { CharacterCounter } from "src/modules/global/components/CharacterCounter";

export interface IInputV2 {
  value: string;
  disabled?: boolean;
  onChange?: (i: string) => void;
  onBlur?: (e: any) => void;
  type?: "text" | "password" | "email";
  label?: React.ReactNode;
  showCharacterCount?: boolean;
  maxCharacterCount?: number;
  isFixedWidth?: boolean;
  icon?: React.FC<React.SVGAttributes<any>>;
  placeholder?: string;
  name?: string;
  autoFocus?: boolean;
  prefix?: string;
  helpText?: string;
  action?: React.ReactNode;
  error?: React.ReactNode;
  size?: "small" | "large" | "medium";
  postfix?: string;
}

/**
 * Don't use icon and prefix both
 */
export function InputV2({
  value,
  disabled,
  onChange,
  onBlur,
  type = "text",
  label,
  showCharacterCount = false,
  maxCharacterCount,
  isFixedWidth = false,
  icon: Icon,
  placeholder,
  name,
  autoFocus,
  prefix,
  postfix,
  size = "medium",
  action,
  helpText,
  error: _error,
}: IInputV2) {
  const [showPassword, setShowPassword] = useState(false);

  const hasExceededCharacterCount = showCharacterCount
    ? maxCharacterCount < value?.length
    : false;

  let error = _error
    ? _error
    : hasExceededCharacterCount
    ? "Exceeded maximum character limit"
    : null;

  const hasActionOrFeedback =
    showCharacterCount || type === "password" || !!action;

  return (
    <div
      className={classNames({
        [styles.inputWithLabel]: !isFixedWidth,
      })}
    >
      {label && (
        <div className={styles.labelWrapper}>
          <Label
            label={label}
            htmlFor={name}
            status={error ? "error" : "default"}
            helpText={helpText}
          />
        </div>
      )}
      <div
        className={classNames(styles.fieldWrapper, {
          [styles.inputFixedWidth]: isFixedWidth,
          [styles.inputFullWidth]: !isFixedWidth,
          [styles.inputLarge]: size === "large",
          [styles.inputMedium]: size === "medium",
          [styles.inputSmall]: size === "small",
          [styles.inputError]: error,
          [styles.inputDisabled]: disabled,
          [styles.inputHasActionOrFeedback]: hasActionOrFeedback,
          [styles.inputHasIconOrPrefix]: !!Icon || prefix,
          [styles.inputHasPostfix]: !!postfix,
        })}
      >
        {Boolean(Icon) ? (
          <Icon className={styles.icon} width={18} height={18} />
        ) : prefix ? (
          <span className={styles.prefix}>{prefix}</span>
        ) : null}

        <input
          className={classNames(styles.input)}
          name={name}
          type={showPassword ? "text" : type}
          value={value}
          disabled={disabled}
          autoFocus={autoFocus}
          autoComplete="off"
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
        />

        {/* if you pass postfix you can't have action or feedback */}
        {postfix ? (
          <span className={styles.postfix}>{postfix}</span>
        ) : hasActionOrFeedback ? (
          <div className={styles.actionsOrFeedback}>
            {type === "password" ? (
              <button
                onClick={() => setShowPassword(!showPassword)}
                type="button"
                className={styles.showPasswordButton}
              >
                <EyeIcon width={16} height={16} className={styles.eyeIcon} />
              </button>
            ) : null}

            <div>
              {showCharacterCount && (
                <CharacterCounter
                  iconOnly
                  maxCharacterCount={maxCharacterCount}
                  characterCount={value.length}
                />
              )}
            </div>
            <div>{!!action && action}</div>
          </div>
        ) : null}
      </div>

      {error && (
        <div
          className={classNames({
            [styles.errorText]: error,
          })}
        >
          {error}
        </div>
      )}
    </div>
  );
}
