import styles from "./InstagramFeedAdPreview.module.scss";
import InstagramLogo from "../assets/instagramLogo.svg?react";
import InstagramAdd from "../../../icons/instagram_add.svg?react";
import InstagramComment from "../../../icons/instagram_comment.svg?react";
import InstagramShare from "../../../icons/instagram_share.svg?react";
import InstagramBookmark from "../../../icons/instagram_bookmark.svg?react";
import InstagramHeart from "../../../icons/instagram_heart.svg?react";
import DotsVertical from "@untitled-ui/icons-react/build/esm/DotsVertical";
import { PhoneNotificationBar } from "./PhoneNotificationBar";
import { sentenceCase } from "src/lib/sentenceCase";
import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle";
import classNames from "classnames";
import { useKeenSlider } from "keen-slider/react";
import { IAdPreview } from "../misc/creativePreviewTypes";
import { LineItem } from "src/lib/types";
import { useEffect } from "react";

export function InstagramFeedAdPreview({
  lineItems,
  ctaLabel,
  userName: username,
  userAvatar,
  activeAssetIndex,
  setActiveAssetIndex,
  caption,
}: IAdPreview) {
  const [sliderRef, sliderInstance] = useKeenSlider({
    initial: activeAssetIndex,
    slideChanged(change) {
      setActiveAssetIndex(change.track.details.abs);
    },
  });

  useEffect(() => {
    if (
      sliderInstance.current &&
      // if not already on the active asset index,
      sliderInstance.current.track.details.abs !== activeAssetIndex
    ) {
      // move to the active asset index
      sliderInstance.current.moveToIdx(activeAssetIndex);
    }
  }, [activeAssetIndex, sliderInstance]);

  return (
    <div className={styles.instagramHomeScreen}>
      <PhoneNotificationBar />
      <header className={styles.header}>
        <InstagramLogo className={styles.logo} />
        <div className={styles.headerIcons}>
          <InstagramAdd width={13} height={13} />
          <InstagramHeart width={13} height={13} />
          <InstagramShare width={13} height={13} />
        </div>
      </header>

      <div className={styles.post}>
        <div className={styles.postHeader}>
          <div className={styles.avatarAndUsername}>
            <img
              width={17.12}
              height={17.12}
              src={userAvatar}
              alt={username}
              className={styles.avatar}
            />
            <span className={styles.username}>{username}</span>
          </div>
          <DotsVertical width={13} height={13} />
        </div>

        <InstagramAsset keenSliderRef={sliderRef} lineItems={lineItems} />

        <button type="button" className={styles.ctaButton}>
          {sentenceCase(ctaLabel, "_")}

          <ChevronRight width={13} height={13} />
        </button>

        <div className={styles.postActions}>
          <div className={styles.postLeftActions}>
            <InstagramHeart width={13} height={13} />
            <InstagramComment width={13} height={13} />
            <InstagramShare width={13} height={13} />
          </div>

          {/* carousel dots */}
          {lineItems.length > 1 && (
            <div className={styles.carouselDots}>
              {lineItems.map((_asset, index) => (
                <div
                  key={index}
                  className={classNames(styles.carouselDot, {
                    [styles.activeCarouselDot]: index === activeAssetIndex,
                  })}
                />
              ))}
            </div>
          )}

          <div className={styles.postRightActions}>
            <InstagramBookmark width={13} height={13} />
          </div>
        </div>

        <span className={styles.caption}>
          <strong className={styles.usernameInCaption}>{username}</strong>{" "}
          {caption}
        </span>
      </div>
    </div>
  );
}

interface IInstagramAsset {
  lineItems: LineItem[];
  keenSliderRef: (node: HTMLElement) => void;
}

function InstagramAsset({ lineItems, keenSliderRef }: IInstagramAsset) {
  const assets = lineItems?.map((lineItem) => lineItem.PostAsset);

  if (assets.length === 0) {
    return (
      <div className={styles.emptyImageSection}>
        <AlertCircle color="var(--color-disabled)" width={14} height={14} />
        <span className={styles.emptyImageSectionText}>
          Select Ad to see the preview
        </span>
      </div>
    );
  }

  if (assets.length > 1) {
    return (
      <div className="keen-slider" ref={keenSliderRef}>
        {assets.map((asset, index) => (
          <div className="keen-slider__slide" key={index}>
            {asset.contentType === "IMAGE" ? (
              <img src={asset.uri} alt="Post" className={styles.postImage} />
            ) : (
              <video
                src={asset.uri}
                className={styles.postImage}
                autoPlay
                muted
              >
                <source src={asset.uri} />
              </video>
            )}
          </div>
        ))}
      </div>
    );
  }

  if (assets[0].contentType === "IMAGE") {
    return <img src={assets[0].uri} alt="Post" className={styles.postImage} />;
  }

  if (assets[0].contentType === "VIDEO") {
    return (
      <video src={assets[0].uri} className={styles.postImage} autoPlay muted>
        <source src={assets[0].uri} />
      </video>
    );
  }

  return null;
}
