import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Spinner } from "src/components/Spinner/Spinner";
import { useActivityQuery } from "src/graphql/generated/schema";

export function RedirectToActivityPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading, error } = useActivityQuery({
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (data && data.activity) {
      navigate(`/campaigns/${data.activity.Campaign.id}/activities/${id}`, {
        replace: true,
      });
    }
  }, [data, id, navigate]);

  if (loading) {
    return <Spinner height={"screen"} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return <p>Redirecting...</p>;
}
