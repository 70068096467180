import { LineHeading } from "src/modules/global/components/LineHeading";
import AdsClick from "@material-symbols/svg-600/rounded/ads_click.svg?react";
import PhoneIcon from "@material-symbols/svg-600/rounded/call.svg?react";
import styles from "./WhatsappSenderDetails.module.scss";
import { useWhatsappBusinessAccountAndPhoneNumberQuery } from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { ErrorHandler } from "src/components/ErrorHandler";
import blankProfilePhoto from "../../global/assets/blankProfilePhoto.png";

export function WhatsappSenderDetails() {
  const { data, loading, error } =
    useWhatsappBusinessAccountAndPhoneNumberQuery();

  if (loading) {
    return <Spinner height={100} />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div className={styles.wrapper}>
      <LineHeading
        content="Sender Details"
        icon={<AdsClick width={15} height={15} fill="var(--color-disabled)" />}
      />
      <div className={styles.senderDetails}>
        <div>
          <img
            className={styles.avatar}
            src={
              data.whatsappBusinessProfile?.profilePictureUrl ??
              blankProfilePhoto
            }
            width={40}
            height={40}
            alt="brand logo"
          />
        </div>
        <div className={styles.content}>
          <span className={styles.name}>
            {data.whatsappPhoneNumber.verifiedName}
          </span>
          <span className={styles.detail}>
            <PhoneIcon width={14} height={14} fill="var(--color-disabled)" />
            {data.whatsappPhoneNumber.displayPhoneNumber}
          </span>
        </div>
        <div>
          <span className={styles.badge}>Active</span>
        </div>
      </div>
    </div>
  );
}
