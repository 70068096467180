import React from "react";
import styles from "./Text.module.scss";
import classNames from "classnames";

type Font = "euclid" | "redhat";
type Tone =
  | "caution"
  | "subdued"
  | "success"
  | "critical"
  | "default"
  | "neutral-100"
  | "neutral-200"
  | "neutral-300"
  | "neutral-400"
  | "neutral-500"
  | "neutral-600"
  | "neutral-700"
  | "neutral-800"
  | "neutral-900"
  | "gray-100"
  | "gray-200"
  | "gray-300"
  | "gray-400"
  | "gray-500"
  | "gray-600"
  | "gray-700"
  | "gray-800"
  | "gray-900";

type Align = "left" | "center" | "right";
type Size = "3xs" | "2xs" | "xs" | "sm" | "md" | "lg" | "xl";
type Weight = "regular" | "medium" | "semibold" | "bold";

interface TextProps {
  children: React.ReactNode;
  size?: Size;
  isNumeric?: boolean;
  font?: Font;
  tone?: Tone;
  as?: any;
  align?: Align;
  noWrap?: boolean;
  weight?: Weight;
}

/**
 * Text Component
 *
 * A versatile React component for rendering text with customizable styles, fonts, tones, and alignments.
 *
 * @component
 *
 * @param {Object} props - The properties for the Text component.
 * @param {React.ReactNode} props.children - The content to be displayed within the text component.
 * @param {Size} [props.size="md"] - The size of the text. Options:
 *   - "3xs" (10px)
 *   - "2xs" (11px)
 *   - "xs" (12px)
 *   - "sm" (13px)
 *   - "md" (14px)
 *   - "lg" (16px)
 *   - "xl" (18px)
 * @param {boolean} [props.isNumeric=false] - If true, applies numeric-specific styling.
 * @param {Font} [props.font="euclid"] - The font family to use. Options: "euclid" | "redhat".
 * @param {Tone} [props.tone] - The color tone of the text. Options: "caution" | "subdued" | "success" | "critical" | "default".
 * @param {keyof JSX.IntrinsicElements} [props.as="span"] - The HTML element to render the text as.
 * @param {Align} [props.align="left"] - The text alignment. Options: "left" | "center" | "right".
 * @param {boolean} [props.noWrap=false] - If true, prevents text wrapping and adds ellipsis for overflow.
 * @param {Weight} [props.weight="regular"] - The font weight. Options: "regular" | "medium" | "semibold" | "bold".
 *
 * @example
 * <Text size="lg" font="redhat" tone="success" align="center" weight="semibold">
 *   This is a large, centered success message
 * </Text>
 */
export function Text({
  isNumeric = false,
  children,
  size = "md",
  font = "euclid",
  tone,
  as: Component = "span",
  align = "left",
  noWrap = false,
  weight = "regular",
  ...rest
}: TextProps) {
  const classes = classNames(styles.text, {
    [styles.fontEuclid]: font === "euclid",
    [styles.fontRedhat]: font === "redhat",
    [styles.toneCaution]: tone === "caution",
    [styles.toneSubdued]: tone === "subdued",
    [styles.toneSuccess]: tone === "success",
    [styles.toneCritical]: tone === "critical",
    [styles.toneDefault]: tone === "default",
    [styles.toneGray100]: tone === "gray-100",
    [styles.toneGray200]: tone === "gray-200",
    [styles.toneGray300]: tone === "gray-300",
    [styles.toneGray400]: tone === "gray-400",
    [styles.toneGray500]: tone === "gray-500",
    [styles.toneGray600]: tone === "gray-600",
    [styles.toneGray700]: tone === "gray-700",
    [styles.toneGray800]: tone === "gray-800",
    [styles.toneGray900]: tone === "gray-900",
    [styles.toneNeutral100]: tone === "neutral-100",
    [styles.toneNeutral200]: tone === "neutral-200",
    [styles.toneNeutral300]: tone === "neutral-300",
    [styles.toneNeutral400]: tone === "neutral-400",
    [styles.toneNeutral500]: tone === "neutral-500",
    [styles.toneNeutral600]: tone === "neutral-600",
    [styles.toneNeutral700]: tone === "neutral-700",
    [styles.toneNeutral800]: tone === "neutral-800",
    [styles.toneNeutral900]: tone === "neutral-900",
    [styles.alignLeft]: align === "left",
    [styles.alignCenter]: align === "center",
    [styles.alignRight]: align === "right",
    [styles.size3xs]: size === "3xs",
    [styles.size2xs]: size === "2xs",
    [styles.sizeXs]: size === "xs",
    [styles.sizeSm]: size === "sm",
    [styles.sizeMd]: size === "md",
    [styles.sizeLg]: size === "lg",
    [styles.sizeXl]: size === "xl",
    [styles.numeric]: isNumeric,
    [styles.noWrap]: noWrap,
    [styles.weightRegular]: weight === "regular",
    [styles.weightMedium]: weight === "medium",
    [styles.weightSemibold]: weight === "semibold",
    [styles.weightBold]: weight === "bold",
  });

  return (
    <Component className={classes} {...rest}>
      {children}
    </Component>
  );
}
