import { useSearchParams } from "react-router-dom";
import { Wizard } from "src/components/Wizard/Wizard";
import { StoreConnectForm } from "./StoreConnectForm";
import { StoreManageIntegration } from "./StoreManageIntegration";
import { FormData } from "../misc/shopifyIntegrationTypes";

export function ShopifyIntegrationWizard() {
  const [searchParams] = useSearchParams();
  const shopifyShopName = searchParams.get("shopifyShopName");

  const initialData: FormData = {
    shopifyShopName: shopifyShopName ?? "",
    contactData: {
      enabled: true,
      contacts: true,
      marketingCommunications: true,
      updateBack: false,
    },
    shopifyPixel: {
      enabled: true,
      captureUTM: true,
      anonymousProfile: false,
    },
  };

  return (
    <div>
      <Wizard
        initialActiveStepIndex={shopifyShopName ? 1 : 0}
        initialData={initialData}
        onResetField={() => {
          return initialData;
        }}
        onStepComplete={() => {}}
      >
        <StoreConnectForm />
        <StoreManageIntegration />
      </Wizard>
    </div>
  );
}
