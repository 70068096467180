import { Star01 } from "@untitled-ui/icons-react";
import { Grid } from "src/components/Grid/Grid";
import { InteractiveInputCard } from "src/components/InteractiveInputCard/InteractiveInputCard";
import { MetaInterest } from "src/graphql/generated/schema";
import { formatNumber } from "src/lib/formatNumber";
import { InterestInlineSuggestions } from "./InterestInlineSuggestions";
import { upsertItemInObject } from "../functions/upsertItemInObject";
import { getInterestIcon } from "../functions/getInterestIcon";

interface IInterestLoader {
  adAccountId: string;
  selectedInterests: Record<
    string,
    MetaInterest & { value: "INCLUDE" | "EXCLUDE" | "NONE" }
  >;
  setSelectedInterests: (
    interests: Record<
      string,
      MetaInterest & { value: "INCLUDE" | "EXCLUDE" | "NONE" }
    >,
  ) => void;
}

export function InterestSelectorLoader({
  adAccountId,
  selectedInterests,
  setSelectedInterests,
}: IInterestLoader) {
  function handleAddInterest(
    interest: MetaInterest,
    value: "INCLUDE" | "EXCLUDE" | "NONE",
  ) {
    setSelectedInterests(
      upsertItemInObject(selectedInterests, interest.id, {
        ...interest,
        value,
      }),
    );
  }

  return (
    <div>
      <InterestInlineSuggestions
        adAccountId={adAccountId}
        selectedInterests={selectedInterests}
        onSelect={(interest) => handleAddInterest(interest, "INCLUDE")}
      />

      <div className="pt-4">
        <Grid column={{ xs: 4 }} columnGap={{ xs: 8 }} rowGap={{ xs: 12 }}>
          {Object.values(selectedInterests).map((interest) => (
            <InteractiveInputCard
              variant="card"
              icon={getInterestIcon(interest)}
              value={interest.value ?? "NONE"}
              onChange={(value) => {
                handleAddInterest(interest, value as any);
              }}
              title={interest.name}
              inputType="SWITCH"
              hasDelete={false}
              description={`Estimated size: ${formatNumber(
                interest.audienceSizeLowerBound,
              )} - ${formatNumber(interest.audienceSizeUpperBound)}`}
            />
          ))}
        </Grid>
      </div>
    </div>
  );
}
