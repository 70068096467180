import classNames from "classnames";
import { Text } from "src/components/Text/Text";
import { Icon } from "src/components/Icon/Icon";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";

export interface IAudienceAttributeRow {
  isIncluded: boolean;
  name: string;
  subtitle?: string;
  icon?:
    | React.FC<React.SVGAttributes<SVGElement>>
    | React.FC<React.SVGAttributes<SVGElement>>[];
  hasNoBorder?: boolean;
  type?: string;
}

export function AudienceAttributeRow({
  subtitle,
  name,
  isIncluded,
  icon,
  hasNoBorder = false,
  type,
}: IAudienceAttributeRow) {
  return (
    <div
      className={classNames("flex justify-between items-center px-8 py-16 ", {
        "border-b": !hasNoBorder,
      })}
    >
      <div className="flex gap-12">
        <div className="flex gap-8">
          {icon &&
            (Array.isArray(icon) ? (
              icon.map((item, index) => (
                <div className="flex gap-4">
                  <Icon icon={item} key={index} />
                </div>
              ))
            ) : (
              <Icon icon={icon} />
            ))}
          <Text size="sm" weight="medium">
            {name}
          </Text>
        </div>
        {subtitle && (
          <>
            <VerticalSeparator totalWidth={1} separatorHeight={12} />
            <Text tone="subdued" size="xs">
              {subtitle}
            </Text>
          </>
        )}
      </div>

      <div className="flex gap-48 items-center">
        <BadgeV2
          label={isIncluded ? "Included" : "Excluded"}
          color={isIncluded ? "green" : "red"}
        />
        {type && (
          <Text size="xs" tone="subdued">
            {type}
          </Text>
        )}
      </div>
    </div>
  );
}
