import React, { useState } from "react";
import styles from "./UpgradePlanModal.module.scss";
import { Dialog } from "../Dialog/Dialog";
import DataUsage from "@material-symbols/svg-600/rounded/data_usage.svg?react";
import { TabsV2 } from "../TabsV2/TabsV2";
import { Text } from "../Text/Text";
import { Switch } from "src/modules/global/components/Switch";
import { SliderInput } from "../SliderInput/SliderInput";
import {
  Announcement03,
  CreditCardUp,
  GitPullRequest,
} from "@untitled-ui/icons-react";
import { Icon } from "../Icon/Icon";
import { adSpendOptions } from "src/modules/global/misc/pricingPlans";
import { growPlanBenefits } from "src/modules/global/misc/growPlanBenefits";
import StandardPlanLogo from "../../modules/global/assets/standard-plan-logo.svg";
import PremiumPlanLogo from "../../modules/global/assets/premium-plan-logo.svg";
import { IconWithWrapper } from "../IconWithWrapper/IconWithWrapper";
import tick from "../../icons/check.svg?react";
import cross from "../../icons/cross.svg?react";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import EnterprisePlanLogo from "../../modules/global/assets/enterprise-plan-logo.svg";
import {
  BillingGrowPlanTierEnum,
  BillingPlanTypeEnum,
  MonthlyAdSpendRange,
} from "src/graphql/generated/schema";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import engageIllustration from "src/modules/global/assets/engage-illustration.png";

interface IUpgradePlanModal {
  trigger: React.ReactNode;
  defaultTab: "GROW" | "ENGAGE";
  onUpgrade: (
    planType: "STANDARD" | "PREMIUM",
    monthlyAdSpend: MonthlyAdSpendRange,
  ) => void;
  onUpgradeLoading: boolean;
  onEnterprisePlanInquiry: () => void;
  growPlanType: BillingPlanTypeEnum;
  growPlanTier: BillingGrowPlanTierEnum;
}

export function UpgradePlanModal({
  growPlanType,
  growPlanTier,
  trigger,
  onEnterprisePlanInquiry,
  onUpgrade,
  onUpgradeLoading,
  defaultTab = "GROW",
}: IUpgradePlanModal) {
  const [upgradingType, setUpgradingType] = useState<"STANDARD" | "PREMIUM">(
    "STANDARD",
  );
  const [open, setOpen] = useState(false);
  const [monthlyAdSpendIndex, setMonthlyAdSpendIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState<"GROW" | "ENGAGE">(defaultTab);
  const [isBilledAnnually, setIsBilledAnnually] = useState(false);
  const tabOptions = [
    {
      label: "Grow",
      icon: Announcement03,
      value: "GROW" as const,
    },
    {
      label: "Engage",
      icon: GitPullRequest,
      value: "ENGAGE" as const,
    },
  ];

  const planOptions = [
    {
      id: "STANDARD" as const,
      image: StandardPlanLogo,
      label: "Standard Plan",
      price: adSpendOptions[monthlyAdSpendIndex].price.standardMonthlyAmount,
      annuallyPrice:
        adSpendOptions[monthlyAdSpendIndex].price.standardAnnuallyAmount,
      benefits: growPlanBenefits,
    },
    {
      id: "PREMIUM" as const,
      image: PremiumPlanLogo,
      label: "Premium Plan",
      price: adSpendOptions[monthlyAdSpendIndex].price.premiumMonthlyAmount,
      annuallyPrice:
        adSpendOptions[monthlyAdSpendIndex].price.premiumAnnuallyAmount,
      benefits: growPlanBenefits,
    },
  ];

  function isCurrentPlan(planType: string) {
    return (
      growPlanType === planType &&
      growPlanTier === adSpendOptions[monthlyAdSpendIndex].value
    );
  }

  return (
    <div className={styles.wrapper}>
      <Dialog
        maxWidth="624px"
        trigger={trigger}
        open={open}
        onOpenChange={setOpen}
      >
        <Dialog.Header title="Upgrade Plan" icon={DataUsage} />

        <div className="flex flex-col gap-16">
          <div className="flex justify-between items-center">
            <TabsV2<"GROW" | "ENGAGE">
              tabs={tabOptions}
              size="small"
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
            />

            <div className="flex items-center gap-4">
              <Text size="2xs" weight="medium" tone="subdued">
                Monthly
              </Text>
              <Switch
                checked={isBilledAnnually}
                onChange={setIsBilledAnnually}
              />
              <Text size="2xs" weight="medium" tone="subdued">
                Annually
              </Text>
            </div>
          </div>

          {selectedTab === "GROW" ? (
            <>
              <div className="flex flex-col gap-8 border p-16 rounded-8">
                <div className="flex justify-between gap-4">
                  <Text size="2xs" weight="medium" tone="subdued">
                    What's your monthly ad spend (USD)?
                  </Text>

                  <Icon icon={CreditCardUp} />
                </div>

                <div className="grid grid-cols-[auto_1fr_auto] items-center gap-8">
                  <Text size="xs" weight="medium" tone="subdued">
                    $1K
                  </Text>
                  <SliderInput
                    value={monthlyAdSpendIndex}
                    min={0}
                    max={2}
                    step={1}
                    onValueChange={(value) => {
                      setMonthlyAdSpendIndex(value);
                    }}
                    ariaLabel="Monthly ad spend"
                  />
                  <Text size="xs" weight="medium" tone="subdued">
                    $10K
                  </Text>
                </div>
              </div>
              <div className="pt-8 flex gap-16">
                {planOptions.map((plan) => (
                  <div
                    className="flex-1 border rounded-8 border-neutral-50 p-16"
                    key={plan.label}
                  >
                    <div className="flex flex-col gap-8">
                      <img
                        width={40}
                        height={40}
                        className="w-10 h-10"
                        src={plan.image}
                        alt={plan.label}
                      />
                      <Text weight="semibold" font="redhat">
                        {plan.label}
                      </Text>
                    </div>

                    <div className="flex items-center gap-2 pt-12 pb-20">
                      <span className="text-neutral-600 text-[10px] leading-[1] self-start">
                        $
                      </span>
                      <span className="text-[18px] leading-[1] text-neutral-800 font-medium ">
                        {isBilledAnnually
                          ? plan.annuallyPrice / 100
                          : plan.price / 100}
                      </span>
                      <span className="text-[11px] leading-[1] text-neutral-300 ">
                        {isBilledAnnually ? "/year" : "/month"}
                      </span>
                    </div>

                    <ul className="list-none p-0 m-0 flex flex-col gap-12">
                      {plan.benefits.map((benefit) => (
                        <li
                          className="flex items-center gap-4"
                          key={benefit.label}
                        >
                          {
                            <IconWithWrapper
                              size="sm"
                              icon={
                                benefit.includedIn.includes(plan.id)
                                  ? tick
                                  : cross
                              }
                            />
                          }

                          <Text size="xs">{benefit.label}</Text>
                        </li>
                      ))}
                    </ul>

                    <div className="pt-24">
                      <ButtonV2
                        loading={onUpgradeLoading && upgradingType === plan.id}
                        disabled={
                          // current plan or downgrade
                          // are not allowed to upgrade
                          // insane JS logic I know
                          isCurrentPlan(plan.id) ||
                          adSpendOptions[monthlyAdSpendIndex].value <
                            growPlanTier
                        }
                        onClick={() => {
                          setUpgradingType(plan.id);
                          onUpgrade(
                            plan.id,
                            adSpendOptions[monthlyAdSpendIndex].value,
                          );
                        }}
                        isFullWidth
                      >
                        {isCurrentPlan(plan.id)
                          ? "Current Plan"
                          : `Upgrade to ${plan.label}`}
                      </ButtonV2>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex p-16 gap-8 rounded-8 border border-neutral-50">
                <img src={EnterprisePlanLogo} width={32} height={32} />
                <div className="flex-1 flex flex-col gap-4">
                  <Text size="xs" weight="medium">
                    Enterprise Plan
                  </Text>
                  <Text size="xs" tone="subdued">
                    Get a dedicated account manager and more.
                  </Text>
                </div>
                <ButtonV2
                  variant="outline"
                  size="small"
                  onClick={onEnterprisePlanInquiry}
                >
                  Contact Us
                </ButtonV2>
              </div>
            </>
          ) : (
            <div className={styles.engageAnnouncement}>
              <div className={styles.engageIllustrationContainer}>
                <img
                  src={engageIllustration}
                  alt="engage illustration"
                  className={styles.engageIllustration}
                />
                <div>
                  <BadgeV2 label="Coming Soon" color="gray" size="medium" />
                </div>
              </div>
              <div>
                <h2 className={styles.engageTitle}>Macro Engage Beta</h2>
                <p className={styles.engageDescription}>
                  Coming soon our *Engage Programme* beta! Real-time analytics,
                  seamless integration, and AI insights. Subscribe for future
                  updates and features!
                </p>
              </div>
              <ButtonV2 isFullWidth={true}>Contact support for access</ButtonV2>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}
