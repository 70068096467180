import styles from "./AdFieldsSection.module.scss";
import { getAdTemplateType } from "../functions/getAdTemplateType";
import { AdFieldsShowcase } from "./AdFieldsShowcase";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import RefreshCcw01 from "@untitled-ui/icons-react/build/esm/RefreshCcw01";
import Trash from "@untitled-ui/icons-react/build/esm/Trash03";
import { AdSelectorModal } from "./AdSelectorModal";
import { useState } from "react";

export function AdFieldsSection() {
  const { values, setFieldValue, setValues } =
    useFormikContext<ActivityInputV2>();
  const [isAdSelectorModalOpen, setIsAdSelectorModalOpen] = useState(false);
  const selectedAdIndex = values.selectedAdIndex ?? 0;

  return (
    <div className={styles.currentAdEditor}>
      <div className={styles.currentAdEditorHeader}>
        <div>
          <h3 className={styles.currentAdHeaderTitle}>
            Ad {selectedAdIndex + 1}
          </h3>
          <p className={styles.currentAdEditorSubtitle}>
            {getAdTemplateType(values.Ads?.[selectedAdIndex])} Ad
          </p>
        </div>
        <div className={styles.currentAdEditorHeaderActions}>
          <AdSelectorModal
            multiple={false}
            onSelect={(adTemplates) => {
              setFieldValue(
                "Ads",
                /* only for replacing the current ad */
                values.Ads.map((ad, index) =>
                  index === selectedAdIndex ? adTemplates[0] : ad
                )
              );

              setIsAdSelectorModalOpen(false);
            }}
            isOpen={isAdSelectorModalOpen}
            onClose={() => setIsAdSelectorModalOpen(false)}
          />

          <ButtonV2
            onClick={() => setIsAdSelectorModalOpen(true)}
            size="small"
            variant="outline"
            leftIcon={RefreshCcw01}
          />
          <ButtonV2
            size="small"
            variant="outline"
            isDestructive
            onClick={() => {
              setValues({
                ...values,
                Ads: values.Ads.filter((_, index) => index !== selectedAdIndex),
                selectedAdIndex: selectedAdIndex > 0 ? selectedAdIndex - 1 : 0,
              });
            }}
            leftIcon={Trash}
          />
        </div>
      </div>

      <AdFieldsShowcase />
    </div>
  );
}
