import { MetaLineItemFields } from "../misc/creativeCreateTypesV2";
import styles from "./LineItemInput.module.scss";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import RefreshCcw01 from "@untitled-ui/icons-react/build/esm/RefreshCcw01";
import Trash03 from "@untitled-ui/icons-react/build/esm/Trash03";
import { AddMedia } from "./AdTemplateCreateSlideOver/AddMedia";
import { InputV2 } from "src/components/InputV2/InputV2";
import { Asset } from "src/graphql/generated/schema";
import classNames from "classnames";

interface ILineItemInput {
  lineItem: MetaLineItemFields;
  baseKey: string;
  onRemove: () => void;
  shouldShowAdvancedFields: boolean;
  setFieldValue: (i: string, value: any) => void;
  onReplace: (asset: Asset) => void;
  activeAssetIndex: number;
  setActiveAssetIndex: (i: number) => void;
  lineItemIndex: number;
  // not named meta because they can be platform independent
  placement?: "STORY" | "POST";
}

export function LineItemInput({
  lineItem,
  lineItemIndex,
  onReplace,
  baseKey,
  setFieldValue,
  onRemove,
  placement = "POST",
  shouldShowAdvancedFields,
  activeAssetIndex,
  setActiveAssetIndex,
}: ILineItemInput) {
  const isStory = placement === "STORY";
  const asset = isStory
    ? // use post asset as fallback because story asset is optional
      lineItem.StoryAsset ?? lineItem.PostAsset
    : lineItem.PostAsset;

  return (
    <div className={styles.lineItemInput}>
      <div
        onMouseEnter={() => setActiveAssetIndex(lineItemIndex)}
        className={styles.imageWrapper}
      >
        <img
          key={asset.thumbnailUri || asset.uri}
          src={asset.thumbnailUri || asset.uri}
          alt=""
          className={classNames(styles.adImage, {
            [styles.storyImage]: isStory,
          })}
        />
        <div className={styles.imageOverlay}>
          <ButtonV2
            variant="outline"
            onClick={onRemove}
            isDestructive
            leftIcon={Trash03}
          />

          <AddMedia
            triggerElementFn={({ onClick }) => (
              <ButtonV2
                isFullWidth
                variant="outline"
                onClick={onClick}
                rightIcon={RefreshCcw01}
              >
                Replace
              </ButtonV2>
            )}
            onSelect={(assets) => {
              onReplace(assets[0]);
            }}
            multiple={false}
          />
        </div>
      </div>
      {shouldShowAdvancedFields && (
        <>
          <InputV2
            value={lineItem.headline}
            onChange={(value) => setFieldValue(`${baseKey}.headline`, value)}
            placeholder="Ad Title"
            name={`${baseKey}.headline`}
          />
          <InputV2
            value={lineItem.description}
            onChange={(value) => setFieldValue(`${baseKey}.description`, value)}
            placeholder="Ad Description"
            name={`${baseKey}.description`}
          />
        </>
      )}
    </div>
  );
}
