import format from "date-fns/format";
import {
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import { CampaignsQuery } from "src/graphql/generated/schema";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { useCampaignAggregatedStats } from "src/modules/global/functions/useCampaignAggregatedStats";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";

export function CampaignsOverviewTable({
  campaigns,
}: {
  campaigns: CampaignsQuery["campaigns"]["edges"][0]["node"][];
}) {
  return (
    <>
      {campaigns.map((campaign, i) => (
        <TableRow id={campaign.id}>
          <TablePrimaryCell
            title={campaign.name}
            subtitle={format(new Date(campaign.createdAt), "dd MMM yyyy")}
          />
          <TableCell>{campaign.activitiesCount}</TableCell>
          <CampaignStats campaignId={campaign.id} />
        </TableRow>
      ))}
    </>
  );
}

function CampaignStats({ campaignId }: { campaignId: string }) {
  const { data } = useCampaignAggregatedStats(campaignId, {});
  const selectedCurrencyCode = useGetActiveCurrencySymbol();

  const clicks = data?.clicks ? formatNumberByType(data.clicks, "NUMBER") : "-";
  const orderCount = data?.countPurchase
    ? formatNumberByType(data.countPurchase, "NUMBER")
    : "-";

  const orderValue = data?.totalValuePurchase
    ? formatNumberByType(data.totalValuePurchase, "CURRENCY", {
        showDecimal: false,
        selectedCurrencyCode,
      })
    : "-";

  return (
    <>
      <TableCell width="112px">{orderCount}</TableCell>
      <TableCell width="112px">{orderValue}</TableCell>
      <TableCell width="112px">{clicks}</TableCell>
    </>
  );
}
