import { FormikHelpers, FormikValues, getIn } from "formik";

interface setSelectedArrayObectsArgs<T> {
  values: FormikValues;
  item: T;
  itemKey: any;
  identifierKey: keyof T;
  setFieldValue: FormikHelpers<FormikValues>["setFieldValue"];
}

export function setArrayObjectsFormik<T = Record<string, any>>({
  values,
  item,
  itemKey,
  identifierKey,
  setFieldValue,
}: setSelectedArrayObectsArgs<T>) {
  const selectedItems = getIn(values, itemKey) || [];

  const selectedValue = selectedItems.find(
    (v: any) => v[identifierKey] === item[identifierKey]
  );

  console.log({ selectedValue });
  if (selectedValue) {
    setFieldValue(
      itemKey,
      selectedItems.map((v) =>
        v.id === selectedValue.id
          ? {
              ...v,
              enabled: !v.enabled,
            }
          : v
      )
    );
  } else {
    setFieldValue(itemKey, [...selectedItems, { ...item, enabled: true }]);
  }
}
