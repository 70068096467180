import { SVGAttributes } from "react";
import { CursorClick01 } from "@untitled-ui/icons-react";
import FacebookIcon from "../../../icons/facebook_in_circle.svg?react";
import InstagramIcon from "../../../icons/instagram.svg?react";
import InterestIcon from "../../../icons/person_play.svg?react";
import BehaviourIcon from "../../../icons/behaviour.svg?react";
import MetaIcon from "../../../icons/meta.svg?react";
import DemographicIcon from "../../../icons/demography.svg?react";

export function getAudienceIconList({ audiencePlatforms, categories }) {
  const icons: React.FC<SVGAttributes<any>>[] = [];

  const platformsMapping = {
    FACEBOOK: FacebookIcon,
    INSTAGRAM: InstagramIcon,
    META: MetaIcon,
    INTEREST: InterestIcon,
    BEHAVIOUR: BehaviourIcon,
    DEMOGRAPHIC: DemographicIcon,
    PIXEL_BASED: CursorClick01,
  };

  const interestCategoriesMapping = {
    interests: "AudienceCategory_10",
    behaviors: "AudienceCategory_11",
    work_employers: "AudienceCategory_12",
  };

  if (audiencePlatforms.includes("META")) {
    icons.push(platformsMapping.FACEBOOK);
  }

  if (audiencePlatforms.includes("INSTAGRAM")) {
    icons.push(platformsMapping.INSTAGRAM);
  }

  if (audiencePlatforms.includes("WEBSITE")) {
    icons.push(platformsMapping.PIXEL_BASED);
  }

  if (categories.find((a) => a.id === interestCategoriesMapping.interests)) {
    icons.push(platformsMapping.INTEREST);
  }

  if (categories.find((a) => a.id === interestCategoriesMapping.behaviors)) {
    icons.push(platformsMapping.BEHAVIOUR);
  }

  if (
    categories.find((a) => a.id === interestCategoriesMapping.work_employers)
  ) {
    icons.push(platformsMapping.DEMOGRAPHIC);
  }

  return icons.slice(0, 3);
}
