import EmptyState from "src/components/EmptyState/EmptyState";
import noResultsFound from "../assets/noResultsFound.png";
import styles from "./NoFilteredResultsFound.module.scss";

export function NoFilteredResultsFound() {
  return (
    <div className={styles.wrapper}>
      <EmptyState
        title={"Oops! It seems we're on a wild goose chase"}
        description={
          "Double-check your spelling or try another search - the information might be playing hide and seek!"
        }
        illustration={noResultsFound}
      />
    </div>
  );
}
