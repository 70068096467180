import styles from "./AdPreviewSection.module.scss";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { useState } from "react";
import LayoutBottom from "@untitled-ui/icons-react/build/esm/LayoutBottom";
import Reel from "../../../icons/reel.svg?react";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import InstagramStory from "../../../icons/slow_motion_video.svg?react";
import classNames from "classnames";
import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import ChevronLeft from "@untitled-ui/icons-react/build/esm/ChevronLeft";
import { CreativePreview } from "src/modules/global/components/CreativePreview";

const formatOptions = [
  { icon: LayoutBottom, value: "FEED" as const, label: "Post" },
  { icon: Reel, value: "REEL" as const, label: "Reels" },
  { icon: InstagramStory, value: "STORY" as const, label: "Stories" },
];

export function AdPreviewSection() {
  const { values } = useFormikContext<ActivityInputV2>();
  const selectedAdIndex = values.selectedAdIndex ?? 0;
  const selectedAd = values.Ads ? values.Ads[selectedAdIndex] : null;
  const [selectedPlacement, setSelectedPlacement] = useState(
    formatOptions[0].value
  );
  const slidesLength = 3;

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabWrapper}>
        <TabsV2
          size="small"
          tabs={formatOptions}
          selectedTab={selectedPlacement}
          onTabChange={setSelectedPlacement}
        />
      </div>

      <div className={styles.previewSlider}>
        <button
          className={classNames(styles.changeSlideButton, {
            [styles.navigationButtonDisabled]: true,
          })}
          disabled={false}
          type="button"
        >
          <ChevronLeft width={14} height={14} />
        </button>
        <div className={styles.preview}>
          <CreativePreview
            activeAssetIndex={0}
            // we don't let user update asset index in the preview
            setActiveAssetIndex={() => {}}
            ad={selectedAd}
            accountName={values.metaPageName}
            profilePicture={values.metaPagePicture}
            platform="INSTAGRAM"
            metaFormat={selectedPlacement}
          />
        </div>
        <button
          className={classNames(styles.changeSlideButton, {
            [styles.navigationButtonDisabled]: false,
          })}
          type="button"
        >
          <ChevronRight width={14} height={14} />
        </button>
      </div>

      <div className={styles.footer}>
        {/* active slide */}
        <div className={styles.activeSlideName}>Instagram Reels</div>
        <div className={styles.navigationDotList}>
          {[...Array(slidesLength)].map((_, index) => (
            <div
              key={index}
              className={classNames(styles.navigationDot, {
                [styles.activeNavigationDot]: index === 0,
              })}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
