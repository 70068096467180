import { Users02 } from "@untitled-ui/icons-react";
import Globe01 from "@untitled-ui/icons-react/build/esm/Globe01";
import { useMemo, useState } from "react";
import Flex from "src/components/Flex/Flex";
import { Table } from "src/components/Table/Table";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { Text } from "src/components/Text/Text";
import {
  AudienceAudienceSetIndexesQuery,
  CampaignsQuery,
  CreativeTemplatesQuery,
  useAudienceAudienceSetIndexesQuery,
  useCampaignsQuery,
  useCreativeTemplatesQuery,
  useMetaActivityAdTemplatesQuery,
} from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { CampaignsOverviewTable } from "./CampaignOverviewTable";
import { AudienceOverviewTable } from "./AudienceOverviewTable";
import { CreativesOverviewTable } from "./CreativesOverviewTable";

const creativesTableHeading = [
  {
    title: "Creative Templates",
  },
  {
    title: "Status",
  },
  {
    title: "Used In",
  },
  {
    title: "CTR",
  },
];

const campaignsTableHeading = [
  {
    title: "Campaigns",
  },
  {
    title: "Activity Count",
  },
  {
    title: "Order Count",
  },
  {
    title: "Order Value",
  },
  {
    title: "Click Rate",
  },
];

const audiencesTableHeading = [
  {
    title: "Audiences",
  },
  {
    title: "Category",
  },
  {
    title: "Est. Size",
  },
  {
    title: "ROAS",
  },
  {
    title: "Click Rate",
  },
];

export function MacroOverview() {
  const [selectedTab, setSelectedTab] = useState<
    "CREATIVES" | "AUDIENCES" | "CAMPAIGNS"
  >("CREATIVES");

  const {
    data: audienceAudienceSetIndexData,
    loading: audienceAudienceSetIndexesLoading,
  } = useAudienceAudienceSetIndexesQuery({
    variables: {
      filter: {
        take: 5,
      },
      sortBy: {
        direction: "DESC",
        field: "CREATED_AT",
      },
    },
  });

  const {
    data: metaActivityAdTemplatesData,
    loading: metaActivityAdTemplatesLoading,
  } = useCreativeTemplatesQuery({
    variables: {
      filter: {
        take: 5,
      },
      sortBy: {
        direction: "DESC",
        field: "CREATED_AT",
      },
    },
  });

  const { data: campaignsData, loading: campaignsLoading } = useCampaignsQuery({
    variables: {
      filters: {
        limit: 5,
      },
      sortBy: {
        direction: "DESC",
        field: "CREATED_AT",
      },
    },
  });

  if (
    audienceAudienceSetIndexesLoading &&
    metaActivityAdTemplatesLoading &&
    campaignsLoading
  ) {
    return <Spinner />;
  }

  const adTemplates =
    metaActivityAdTemplatesData && metaActivityAdTemplatesData.creativeTemplates
      ? metaActivityAdTemplatesData.creativeTemplates.edges.map((v) => v.node)
      : [];

  const campaigns =
    campaignsData && campaignsData.campaigns
      ? campaignsData.campaigns.edges.map((v) => v.node)
      : [];

  const audiences =
    audienceAudienceSetIndexData &&
    audienceAudienceSetIndexData.audienceAudienceSetIndexes
      ? audienceAudienceSetIndexData.audienceAudienceSetIndexes.edges.map(
          (v) => v.node,
        )
      : [];

  const selectedTableHeadings =
    selectedTab === "CREATIVES"
      ? creativesTableHeading
      : selectedTab === "AUDIENCES"
      ? audiencesTableHeading
      : campaignsTableHeading;

  return (
    <Flex
      gap="16px"
      flexDirection="column"
      alignItems="flex-start"
      className="w-full"
    >
      <Text size="xl" font="redhat" weight="medium">
        What's happening in Macro
      </Text>
      <Flex gap="12px" flexDirection="column" className="w-full">
        <TabsV2
          tabs={[
            {
              label: "Creatives",
              value: "CREATIVES" as const,
              icon: Globe01,
            },
            {
              label: "Campaigns",
              value: "CAMPAIGNS" as const,
              icon: Globe01,
            },
            {
              label: "Audiences",
              value: "AUDIENCES" as const,
              icon: Users02,
            },
          ]}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
        />
        <Table
          headings={selectedTableHeadings}
          isSelectable={false}
          variant="card"
          data={
            selectedTab === "CREATIVES"
              ? (adTemplates as any)
              : selectedTab === "AUDIENCES"
              ? (audiences as any)
              : (campaigns as any)
          }
          idExtractor={(item: any) => item.id}
          renderItem={({ item }) => (
            <TableRowsFromSelectedTab item={item} selectedTab={selectedTab} />
          )}
        />
      </Flex>
    </Flex>
  );
}

function TableRowsFromSelectedTab({
  selectedTab,
  item,
}: {
  selectedTab: "CREATIVES" | "AUDIENCES" | "CAMPAIGNS";
  item: any;
}) {
  if (selectedTab === "CREATIVES") {
    return <CreativesOverviewTable adTemplates={[item]} />;
  }

  if (selectedTab === "CAMPAIGNS") {
    return <CampaignsOverviewTable campaigns={[item]} />;
  }

  return <AudienceOverviewTable audiences={[item]} />;
}
