import { GeoLocationTargetingFormState } from "./audienceCreateTypes";

type Location = GeoLocationTargetingFormState["locations"][number];

export function removeOverlapLocations(locations: Location[]) {
  const newLocations: Location[] = [];

  for (let location of locations) {
    if (location.type === "place") {
      const overlappingCity = findOverlappingCity(
        locations,
        location.primaryCity,
      );
      const overlappingRegion = findOverlappingRegion(locations, location);
      const overlappingCountry = findOverlappingCountry(locations, location);
      if (overlappingRegion || overlappingCountry || overlappingCity) {
        // ignore
        continue;
      }
    }

    if (location.type === "city" || location.type === "neighborhood") {
      const overlappingRegion = findOverlappingRegion(locations, location);
      const overlappingCountry = findOverlappingCountry(locations, location);

      if (overlappingRegion || overlappingCountry) {
        // ignore
        continue;
      }
    }

    if (location.type === "region") {
      const overlappingCountry = findOverlappingCountry(locations, location);

      if (overlappingCountry) {
        // ignore
        continue;
      }
    }

    newLocations.push(location);
  }

  return newLocations;
}

function findOverlappingCity(locations: Location[], city: String) {
  return locations.find((l) => {
    if (l.type === "city" && l.name === city) {
      return true;
    }

    return false;
  });
}

function findOverlappingRegion(locations: Location[], location: Location) {
  return locations.find((l) => {
    if (
      // l.type === "region" &&
      l.name === location.region &&
      l.countryCode === location.countryCode
    ) {
      return true;
    }

    return false;
  });
}

function findOverlappingCountry(locations: Location[], location: Location) {
  return locations.find((l) => {
    if (l.type === "country" && l.id === location.countryCode) {
      return true;
    }

    return false;
  });
}
