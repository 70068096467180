import { Form, Formik } from "formik";
import { useContext } from "react";
import toast from "react-hot-toast";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  MetaAdAccount,
  useMetaAllAdAccountsQuery,
  useMetaEnableAdAccountMutation,
  useMetaRemoveAdAccountMutation,
} from "src/graphql/generated/schema";
import META_AD_ACCOUNTS from "src/graphql/metaAdAccounts";
import metaEnabledAdAccounts from "src/graphql/metaEnabledAdAccounts";
import { META_INTEGRATION_DEFAULT_SETTING } from "src/graphql/metaIntegrationDefaultSetting";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { WizardContext } from "src/components/Wizard/WizardContext";
import styles from "./EnableAdAccount.module.scss";
import { MetaAdAccountsList } from "./MetaAdAccountsList";

interface FormValues {
  adAccounts: MetaAdAccount[];
}

export function EnableAdAccount({ loading }) {
  const { markStepAsDone, activeStepIndex, resetAlert, setResetAlert, data } =
    useContext(WizardContext);
  const currentStepIndex = 1;
  // const isCurrentStepCompleted = activeStepIndex > currentStepIndex;
  const { data: metaAllAdAccountsData, loading: metaAllAdAccountsLoading } =
    useMetaAllAdAccountsQuery();

  // handle toggle
  const metaAdAccounts = metaAllAdAccountsData
    ? metaAllAdAccountsData.metaAllAdAccounts
    : [];

  const initialValues =
    data && data.adAccounts.length > 0
      ? data
      : ({
          adAccounts: [],
        } as FormValues);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        // enable all the selected accounts
        if (activeStepIndex > currentStepIndex) {
          return;
        }
        await markStepAsDone(activeStepIndex + 1, values);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <StepWizardStep
            isCompleted={activeStepIndex > currentStepIndex}
            isStepNotActive={activeStepIndex < currentStepIndex || loading}
          >
            <div className={styles.container}>
              <div>
                <LegacySectionHeading
                  heading={"Choose Account to get started"}
                  description={
                    "Select ad account which are connected to your business account"
                  }
                />
              </div>
              {metaAllAdAccountsLoading ? (
                <div className={styles.adAccountsListing}>
                  <Spinner height={400} />
                </div>
              ) : (
                <div className={styles.adAccountsListing}>
                  <MetaAdAccountsList metaAdAccounts={metaAdAccounts} />
                </div>
              )}
              <div>
                <ButtonV2
                  size="small"
                  type="submit"
                  loading={isSubmitting || loading}
                >
                  Continue
                </ButtonV2>
              </div>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
