import { ActiveClient } from "src/lib/types";
import {
  Client,
  useActiveClientQuery,
} from "../../../graphql/generated/schema";

export const activeClientStorageKey = "active-client";

export function useActiveClientGet(): Client | null {
  const { data } = useActiveClientQuery();

  if (data && data.activeClient) {
    return data.activeClient;
  } else {
    return null;
  }
}

export function activeClientSet(data: ActiveClient) {
  localStorage.setItem(activeClientStorageKey, JSON.stringify(data));
}

export function activeClientRemove() {
  localStorage.removeItem(activeClientStorageKey);
}
