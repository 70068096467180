import * as Yup from "yup";
export const locationValueSchema = Yup.object().shape({
  value: Yup.string().oneOf(["INCLUDE", "EXCLUDE", "NONE"]).required(),
  id: Yup.string().required(),
  name: Yup.string().required(),
  type: Yup.string().required(),
  country_code: Yup.string(),
  country_name: Yup.string(),
  radius: Yup.number().when("type", {
    is: "city",
    then: Yup.number()
      .positive("Radius must be positive")
      .min(0, "Radius must be above 0")
      .max(80, "Radius must be below 50")
      .required("Radius is required for cities"),
  }),
});

export const geoLocationTargetingSchema = Yup.object({
  locations: Yup.array().of(locationValueSchema),
});
