import { MutableRefObject, useContext } from "react";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { AdSelectorWizardForm } from "./AdSelectorWizardForm";
import { WhatsappTemplateSelectorWizardForm } from "./WhatsappTemplateSelectorWizardForm";

export function CreativeSelector({
  sectionRef,
}: {
  sectionRef: MutableRefObject<HTMLDivElement>;
}) {
  const { data } = useContext(WizardContext);

  if (data.platform === "WHATSAPP") {
    return <WhatsappTemplateSelectorWizardForm sectionRef={sectionRef} />;
  }

  return <AdSelectorWizardForm sectionRef={sectionRef} />;
}
