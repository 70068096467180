import { Form, Formik, FormikValues } from "formik";
import { StepWizardStep } from "../../global/components/StepWizardStep";
import { LegacySectionHeading } from "../../global/components/LegacySectionHeading";
import { useContext, useState } from "react";
import { WizardContext } from "../../../components/Wizard/WizardContext";
import styles from "./ChooseReportType.module.scss";
import { InputV2 } from "../../../components/InputV2/InputV2";
import SearchMd from "@untitled-ui/icons-react/build/esm/SearchMd";
import { reportTypes } from "../misc/reportTypesList";
import { ReportTypeEnum } from "../../../graphql/generated/schema";
import classNames from "classnames";
import { BadgeV2 } from "../../global/components/BadgeV2";
import { ButtonV2 } from "../../../components/ButtonV2/ButtonV2";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  reportType: Yup.string().required("Report Type is required"),
});

export function ChooseReportType({ currentStepIndex }) {
  const { data, markStepAsDone, activeStepIndex } = useContext(WizardContext);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={async (values: FormikValues) => {
        await markStepAsDone(currentStepIndex + 1, values);
      }}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <StepWizardStep
            isCompleted={activeStepIndex > currentStepIndex}
            isStepNotActive={activeStepIndex !== currentStepIndex}
          >
            <div className={styles.container}>
              <LegacySectionHeading
                heading={"Choose report type"}
                description={
                  "Templates for quickly selecting reports tailored to specific marketing needs"
                }
              />
              <ReportTypeListSearch
                options={reportTypes}
                selectedReportType={values.reportType}
                setSelectedReportType={(i) => {
                  setFieldValue("reportType", i);
                }}
              />

              <div className={styles.buttonContainer}>
                <ButtonV2>Continue</ButtonV2>
              </div>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
function ReportTypeListSearch({
  options,
  selectedReportType,
  setSelectedReportType,
}: {
  options: typeof reportTypes;
  selectedReportType: ReportTypeEnum;
  setSelectedReportType: (i: ReportTypeEnum) => void;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const selectedReportTypeItem = options.find(
    (v) => v.id === selectedReportType,
  );

  const SelectedReportTypeItemIcon = selectedReportTypeItem?.icon || null;

  return (
    <div className={styles.reportTypeContainer}>
      <div>
        <div className={styles.reportTypeListSearch}>
          <InputV2
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder={"Search report type"}
            icon={SearchMd}
          />
        </div>
        <div className={styles.reportTypeListSearchResults}>
          {reportTypes
            .filter((reportType) =>
              reportType.name.toLowerCase().includes(searchTerm.toLowerCase()),
            )
            .map((v) => {
              const Icon = v.icon;
              return (
                <button
                  type="button"
                  key={v.id}
                  onClick={() => setSelectedReportType(v.id)}
                  className={classNames(styles.reportTypeListSearchItem, {
                    [styles.isSelected]: selectedReportType === v.id,
                  })}
                >
                  <div className={styles.reportTypeListSearchResultIcon}>
                    <Icon width={16} height={16} />
                  </div>
                  <div className={styles.reportTypeListSearchResultName}>
                    {v.name}
                  </div>
                </button>
              );
            })}
        </div>
      </div>
      {/* Display info*/}

      {selectedReportTypeItem && (
        <div className={styles.reportTypeInfo}>
          <div className={styles.reportTypeInfoHeader}>
            <div className={styles.reportTypeInfoItemIcon}>
              <SelectedReportTypeItemIcon width={24} height={24} />
            </div>
            <div className={styles.reportTypeInfoItemName}>
              {selectedReportTypeItem.name}
            </div>
            <div className={styles.reportTypeInfoItemDescription}>
              {selectedReportTypeItem.description}
            </div>
          </div>
          <div>
            <div className={styles.reportTypeSectionHeader}>
              Significant Tags
            </div>
            <div className={styles.reportTypeInfoItemSignificanceTags}>
              {selectedReportTypeItem.significanceTags.map((tag) => (
                <div className={styles.tagContainer}>
                  <BadgeV2 color="purple" size="small" label={tag} key={tag} />
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className={styles.reportTypeSectionHeader}>Ideal for</div>
            <ul className={styles.reportTypeInfoItemIdealForPointers}>
              {selectedReportTypeItem.idealForPointers.map((pointer) => (
                <li className={styles.reportTypeInfoItemIdealForPointer}>
                  {pointer}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
