import { gql } from "@apollo/client";
import pagination from "src/graphql/pagination";
// ts-prune-ignore-next
export const CUSTOMERS = gql`
  ${pagination}
  query customers($input: CustomersInput!) {
    customers(input: $input) {
      pageInfo {
        ...pageInfo
      }
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          shopifyId
          metaLeadId
          name
          firstName
          lastName
          email
          phone
          ordersCount
          lifetimeValue
          isEmailVerified
          EmailConsent {
            id
            state
          }
          WhatsappConsent {
            id
            state
          }
          TextMessageConsent {
            id
            state
          }
          Address {
            id
            city
            country
            countryCode
          }
          # avatar
          # firstName
          # lastName
          # Segments {
          #   id
          #   name
          # }
        }
      }
    }
  }
`;
