import { Edit02, Mail01, PhoneCall01 } from "@untitled-ui/icons-react";
import { Form, Formik } from "formik";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Dialog } from "src/components/Dialog/Dialog";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { CustomerQuery } from "src/graphql/generated/schema";
import { CountrySelector } from "src/modules/global/components/CountrySelector";
import { indianStates } from "src/modules/global/misc/indianStatesList";

interface ICustomerEditModal {
  isOpen: boolean;
  onIsOpenChange: (value: boolean) => void;
  customer: CustomerQuery["customer"];
}

export function CustomerEditModal({
  isOpen,
  onIsOpenChange,
  customer,
}: ICustomerEditModal) {
  console.log({ customer });

  return (
    <Dialog
      trigger={<></>}
      open={isOpen}
      onOpenChange={(value) => {
        onIsOpenChange(value);
      }}
      maxWidth="615px"
    >
      <Dialog.Header
        title="Edit Customer"
        subtitle="Details already sent to meta will not be updated."
        icon={Edit02}
        iconColor="default"
      />
      <Formik
        initialValues={{
          firstName: customer.firstName || "",
          lastName: customer.lastName || "",
          email: customer.email || "",
          phone: customer.phone || "",
          address: {
            country: customer.Address?.country || "",
            countryCode: customer.Address?.countryCode || "",
            city: customer.Address?.city || "",
          },
        }}
        onSubmit={console.log}
      >
        {({ values, setFieldValue }) => (
          <Form className="flex flex-col gap-16">
            <div className="grid grid-cols-2 gap-8">
              <InputFormikV2
                name="firstName"
                placeholder="John"
                label="First Name"
              />
              <InputFormikV2
                name="lastName"
                placeholder="Doe"
                label="Last Name"
              />
            </div>
            <div className="">
              <InputFormikV2
                icon={Mail01}
                name="email"
                placeholder="hello@gmail.com"
                label="Email"
              />
            </div>
            <div>
              <InputFormikV2
                icon={PhoneCall01}
                name="phone"
                placeholder="919876543210"
                label="Phone Number"
              />
            </div>
            <div>
              {/* <CountrySelector
                placeholder="Select a country"
                value={values.address?.countryCode}
                onChange={(value) => {
                  setFieldValue("address.countryCode", value);
                }}
                label="Country"
              />
              <SelectV2
                name="address.city"
                label="State or City"
                onChange={(value) => {
                  setFieldValue("address.city", value);
                }}
                options={indianStates.map((state) => ({
                  label: state.name,
                  value: state.code,
                }))}
                value={values.address.city}
                placeholder="Select a city"
              /> */}
            </div>
            <div className="pt-56 flex justify-end gap-8">
              <ButtonV2 variant="outline">Cancel</ButtonV2>
              <ButtonV2>Save Changes</ButtonV2>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
