import styles from "./ShopifySettingsCard.module.scss";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import HelpCircleIcon from "@untitled-ui/icons-react/build/esm/HelpCircle";
import Tooltip from "src/components/Tooltip/Tooltip";

interface IShopifySettingsCard {
  title: string;
  subtitle: string;
  imageSrc: string;
  options: {
    value: string;
    title: string;
    helpText?: string;
    onChange: (newValue: boolean) => void;
    checked: boolean;
    disabled?: boolean;
  }[];
}

export function ShopifySettingsCard({
  title,
  subtitle,
  imageSrc,
  options,
}: IShopifySettingsCard) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerLeftSection}>
          <img
            className={styles.headerIcon}
            alt="shopify ingress settings"
            src={imageSrc}
            width={32}
            height={32}
          />
          <div className={styles.headerCopy}>
            <h3 className={styles.headerTitle}>{title}</h3>
            <h4 className={styles.headerSubtitle}>{subtitle}</h4>
          </div>
        </div>
      </div>

      <div className={styles.optionList}>
        {options.map((option) => (
          <div className={styles.optionItem}>
            <span className={styles.optionTitle}>
              {option.title}
              {option.helpText && (
                <Tooltip content={option.helpText}>
                  <HelpCircleIcon width={16} height={16} />
                </Tooltip>
              )}
            </span>
            <Checkbox
              disabled={option.disabled}
              checked={option.checked}
              onChange={option.onChange}
              value={option.value}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
