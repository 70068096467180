import { DotsVertical } from "@untitled-ui/icons-react";
import format from "date-fns/format";
import {
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import { CreativeTemplatesQuery } from "src/graphql/generated/schema";
import { DropdownV2 } from "src/modules/campaign/components/DropdownV2";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { Sidebar } from "src/modules/global/components/Sidebar";
import {
  SideDrawer,
  SideDrawerTrigger,
} from "src/modules/global/components/SideDrawer";
import emptyImage from "../assets/emptyImage.png";

interface ICreativeListingItem {
  creative: CreativeTemplatesQuery["creativeTemplates"]["edges"][0]["node"];
  setExpandedTemplateId: (i: string) => void;
  duplicateAdTemplate: (i: string) => void;
  editAdTemplate: (i: string) => void;
  deleteAdTemplate: (i: string) => void;
}

export function CreativeListingItem({
  creative,
  setExpandedTemplateId,
  duplicateAdTemplate,
  editAdTemplate,
  deleteAdTemplate,
}: ICreativeListingItem) {
  const formattedDate = format(new Date(creative.createdAt), "dd MMM, yyyy");
  const usageCount = creative.MetaActivityAdTemplate.adsCount;
  const isUsed = usageCount > 0;
  const items =
    creative.MetaActivityAdTemplate || creative.DefaultTemplateField;
  const lineItems = items?.LineItems ?? [];
  const mediaSrc =
    lineItems?.[0]?.PostAsset?.thumbnailUri || lineItems?.[0]?.PostAsset?.uri;

  return (
    <TableRow
      onClick={() => {
        setExpandedTemplateId(creative.id);
      }}
      id={creative.id}
    >
      <TablePrimaryCell
        title={creative.name}
        imageSrc={mediaSrc ?? emptyImage}
        as={SideDrawerTrigger}
        onClick={() => {
          setExpandedTemplateId(creative.id);
        }}
        subtitle={formattedDate}
      />
      <TableCell width={160}>
        <BadgeV2
          label={isUsed ? "In use" : "Not in use"}
          color={isUsed ? "green" : "gray"}
        />
      </TableCell>
      <TableCell width={160}>{usageCount}</TableCell>
      <TableCell width={160}>
        {lineItems.length > 1 ? "Carousel" : "Single Image & Video"}
      </TableCell>
      <TableCell width={48}>
        <DropdownV2
          buttonVariant="plain"
          size="small"
          hideArrow
          options={[
            {
              label: "Edit Template",
              onClick() {
                editAdTemplate(creative.id);
              },
            },
            {
              label: "Delete Template",
              onClick() {
                deleteAdTemplate(creative.id);
              },
            },
            {
              label: "Duplicate Template",
              onClick() {
                duplicateAdTemplate(creative.id);
              },
            },
          ]}
          icon={DotsVertical}
        />
      </TableCell>
    </TableRow>
  );
}
