import { useCallback } from "react";
import { useGrowAggregateQuery } from "src/graphql/generated/schema";

export type TComparativePerformance =
  | "BELOW_AVERAGE"
  | "AVERAGE"
  | "ABOVE_AVERAGE"
  | "NO_DATA";

export function useAccountComparison() {
  const { data } = useGrowAggregateQuery({
    variables: {
      filter: {
        insightType: "ACTIVITY_INSIGHT",
      },
    },
  });

  const calculateComparison = useCallback(
    function calculateComparison(
      statValue: number,
      statKey: string,
      performanceDirection: "HIGHER_IS_BETTER" | "LOWER_IS_BETTER",
    ): { comparison: TComparativePerformance; accountAverage: number } {
      if (!data) return { comparison: "NO_DATA", accountAverage: 0 };

      const currentStatValue = data.growAggregate[statKey];

      if (!currentStatValue)
        return { comparison: "NO_DATA", accountAverage: 0 };

      const percentage = calcPercentage(statValue, currentStatValue);

      if (percentage === null)
        return { comparison: "NO_DATA", accountAverage: 0 };

      if (performanceDirection === "HIGHER_IS_BETTER") {
        if (percentage < 90) {
          return {
            comparison: "BELOW_AVERAGE",
            accountAverage: currentStatValue,
          };
        } else if (percentage > 110) {
          return {
            comparison: "ABOVE_AVERAGE",
            accountAverage: currentStatValue,
          };
        }

        return { comparison: "AVERAGE", accountAverage: currentStatValue };
      }

      if (percentage > 120) {
        return {
          comparison: "BELOW_AVERAGE",
          accountAverage: currentStatValue,
        };
      } else if (percentage < 80) {
        return {
          comparison: "ABOVE_AVERAGE",
          accountAverage: currentStatValue,
        };
      }

      return { comparison: "AVERAGE", accountAverage: currentStatValue };
    },
    [data],
  );

  return calculateComparison;
}

function calcPercentage(valueA: number, valueB: number) {
  const percentage = (valueA / valueB) * 100;

  console.log({
    valueA,
    valueB,
    percentage,
  });

  if (isNaN(percentage)) {
    return null;
  }

  if (percentage === Infinity || percentage === -Infinity) {
    return null;
  }

  return percentage;
}
