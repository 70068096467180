import React, { useState } from "react";
import styles from "./ActivityObjectiveAndOthers.module.scss";
import InfoIcon from "@material-symbols/svg-600/rounded/info.svg?react";
import Tooltip from "src/components/Tooltip/Tooltip";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ActivityFacebookSettingsSelectorModal } from "./ActivityFacebookSettingsSelectorModal";
import { ActivityMetaPlacementSelector } from "./ActivityMetaPlacementSelector";
import { ActivityMetaObjectiveSelector } from "./ActivityMetaObjectiveSelector";
import { ActivityInputV2 } from "../misc/createCampaignTypes";

interface ActivityObjectiveAndOthersProps {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
  values: ActivityInputV2;
  setValues: (values: ActivityInputV2) => void;

  showMetaIntegrationSettingsButton: boolean;
  isObjectiveSelectorDisabled?: boolean;
}

export function ActivityObjectiveAndOthers({
  sectionRef,
  values,
  setValues,
  showMetaIntegrationSettingsButton,
  isObjectiveSelectorDisabled = false,
}: ActivityObjectiveAndOthersProps) {
  const [isAccountSelectorOpened, setIsAccountSelectorOpened] = useState(false);

  if (values.platform === "WHATSAPP") {
    return <div />;
  }

  return (
    <div ref={sectionRef} className={styles.section}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.heading}>Objectives and other settings</h2>
          <h3 className={styles.subheading}>
            Choose your campaign objectives and customize the settings to match
            your goals
          </h3>
        </div>
        <div>
          {showMetaIntegrationSettingsButton && (
            <span className={styles.globalSettingInfo}>
              {values.adAccountId && values.useDefaultSettings && (
                <>
                  We are using your global settings{" "}
                  <Tooltip content="">
                    <InfoIcon width={16} height={16} />
                  </Tooltip>
                </>
              )}
              <ButtonV2
                onClick={() => {
                  setIsAccountSelectorOpened(true);
                }}
                variant={values.adAccountId ? "plain" : "outline"}
              >
                {values.adAccountId
                  ? "Change Settings"
                  : "Select Account Settings"}
              </ButtonV2>
            </span>
          )}
        </div>
      </div>

      <ActivityFacebookSettingsSelectorModal
        activeStepIndex={1} // You might need to pass this as a prop
        isOpen={isAccountSelectorOpened}
        onClose={() => setIsAccountSelectorOpened(false)}
        initialFormValues={values as any}
        handleSubmit={(newValues) => {
          setValues({ ...values, ...newValues });
          setIsAccountSelectorOpened(false);
        }}
      />

      {isObjectiveSelectorDisabled ? (
        <div
          title="Cannot change already selected objective. Create new campaign to select objective"
          className="opacity-[0.5]"
        >
          <ActivityMetaObjectiveSelector disabled={true} />
        </div>
      ) : (
        <ActivityMetaObjectiveSelector />
      )}

      <ActivityMetaPlacementSelector />
    </div>
  );
}
