import { AssetsQuery } from "src/graphql/generated/schema";
import { Table } from "src/components/Table/Table";
import { MediaListingItem } from "./MediaListingItem";

interface IMediaListingTable {
  mediaList: AssetsQuery["assets"]["edges"];
  setSelectedItems: (i: string[]) => void;
  selectedItems: string[];
}

export function MediaListingTable({
  mediaList,
  setSelectedItems,
  selectedItems,
}: IMediaListingTable) {
  return (
    <Table
      headings={[
        { title: "Media" },
        { title: "Status" },
        { title: "Times Used" },
        { title: "Resolution" },
        { title: "Size" },
        { title: "" },
      ]}
      isSelectable={true}
      onSelectedItemsChange={setSelectedItems}
      selectedItems={selectedItems}
      data={mediaList}
      idExtractor={(item) => item.node.id}
      renderItem={({ item }) => <MediaListingItem media={item.node} />}
    />
  );
}
