import { Form, Formik } from "formik";
import { WizardContext } from "../../../components/Wizard/WizardContext";
import { useContext } from "react";
import { StepWizardStep } from "../../global/components/StepWizardStep";
import { LegacySectionHeading } from "../../global/components/LegacySectionHeading";
import styles from "./CreateCustomReport.module.scss";
import { InputFormikV2 } from "../../../components/InputFormikV2/InputFormikV2";
import { ButtonV2 } from "../../../components/ButtonV2/ButtonV2";
import { MetaAdAccountSelector } from "../../campaign-wizard/components/MetaAdAccountSelector";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  adAccountId: Yup.string().required("Ad Account is required"),
});

export function CreateCustomReport({ currentStepIndex }) {
  const { data, activeStepIndex, markStepAsDone } = useContext(WizardContext);
  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={async (values) => {
        await markStepAsDone(currentStepIndex + 1, values);
        return;
      }}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        values,
        setFieldValue,
        errors,
      }) => (
        <Form>
          <StepWizardStep
            isCompleted={activeStepIndex > currentStepIndex}
            isStepNotActive={activeStepIndex !== currentStepIndex}
          >
            <div className={styles.container}>
              <LegacySectionHeading
                heading={"Create Custom Report"}
                description={
                  "Generate tailored reports with specific metrics and insights"
                }
              />

              <div className={styles.formContainer}>
                <InputFormikV2 name="name" label="Report Name" isFixedWidth />
                <div className={styles.adAccountSelectorContainer}>
                  <MetaAdAccountSelector
                    selectedAdAccountId={values.adAccountId}
                    setSelectedAdAccountId={function (
                      i: string,
                      platformId?: string,
                      metaName?: string,
                    ): void {
                      setFieldValue("adAccountId", i);
                    }}
                    formKey={"adAccountId"}
                  />
                </div>
              </div>

              <div className={styles.buttonContainer}>
                <ButtonV2
                  disabled={isSubmitting || !isValid}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Continue
                </ButtonV2>
              </div>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
