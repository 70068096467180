import { addZeroIfNeeded } from "./addZeroIfNeeded";

export function highPerformanceHourFormatting(value: string) {
  const hour = Number(value);

  if (hour === 0) {
    return "12 AM";
  }

  if (hour > 12) {
    return `${hour - 12} PM`;
  }

  return `${addZeroIfNeeded(hour)} AM`;
}
