import * as Accordion from "@radix-ui/react-accordion";
import {
  AssetContentType,
  MetaActivity,
  MetaActivityAd,
} from "src/graphql/generated/schema";
import styles from "./ActivityAdAccordion.module.scss";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import ArrowDownIcon from "@material-symbols/svg-600/rounded/keyboard_arrow_down.svg?react";
import ClickIcon from "@material-symbols/svg-600/rounded/web_traffic.svg?react";
import ConversionIcon from "@material-symbols/svg-600/rounded/cycle.svg?react";
import ConversionAmountIcon from "@material-symbols/svg-600/rounded/currency_exchange.svg?react";
import MonetizationIcon from "@untitled-ui/icons-react/build/esm/CurrencyDollarCircle";
import ExpandContent from "@material-symbols/svg-600/rounded/expand_content.svg?react";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { Smartphone } from "src/components/Smartphone/Smartphone";
import { formatNumber } from "src/lib/formatNumber";
import Badge from "src/components/Badge/Badge";
import { Asset } from "src/components/Smartphone/Asset";
import { GrowAggregateType } from "../../global/misc/GrowAggregateType";
import { LineItem } from "src/lib/types";
import { Button } from "src/components/Button/Button";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";
import { Text } from "src/components/Text/Text";
import { Icon } from "src/components/Icon/Icon";
import {
  BarChart01,
  BarChart02,
  Eye,
  LayoutGrid01,
  Repeat04,
  CreditCard02,
  Wallet02,
  Cursor02,
} from "@untitled-ui/icons-react";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import classNames from "classnames";

interface IActivityAdAccordion {
  ad: MetaActivityAd;
  activity: Pick<
    MetaActivity,
    "productCatalogueId" | "activityType" | "MetaAdAccount"
  >;
  thumbnail: {
    contentType: AssetContentType;
    uri: string;
  };
  lineItems: LineItem[];
  adInsight?: GrowAggregateType;
  compareAdInsight?: GrowAggregateType;
  setSelectedAd: any;
}

function ActivityAdAccordion({
  ad,
  activity,
  adInsight,
  compareAdInsight,
  thumbnail,
  lineItems,
  setSelectedAd,
}: IActivityAdAccordion) {
  const selectedCurrencyCode = useGetActiveCurrencySymbol();

  const adStats = [
    {
      icon: BarChart01,
      label: "Reach",
      value: adInsight?.reach ? formatNumber(adInsight.reach) : "-",
      description: "number of unique people exposed to your content",
    },
    {
      icon: Eye,
      label: "Impression",
      value: adInsight?.impressions ? formatNumber(adInsight.impressions) : "-",
      description: "number of times your content is displayed",
    },
    {
      icon: Cursor02,
      label: "Clicks",
      value: adInsight?.clicks ? formatNumber(adInsight.clicks) : "-",
      description:
        "number of times users interact with your content by clicking on a link",
    },
    {
      icon: Repeat04,
      label: "Conversions",
      value: adInsight?.countPurchase
        ? formatNumber(adInsight.countPurchase)
        : "-",
      description: "number of unique people exposed to your content",
    },
    {
      icon: Wallet02,
      label: "Amount Spend",
      value: adInsight?.spend
        ? formatNumberByType(adInsight.spend, "CURRENCY", {
            showDecimal: true,
            selectedCurrencyCode,
          })
        : "-",
      description:
        "money invested in a marketing campaign over a specific period",
    },
    {
      icon: CreditCard02,
      label: "Cost per click",
      value: adInsight?.cpc
        ? formatNumberByType(adInsight.cpc, "CURRENCY", {
            showDecimal: true,
            selectedCurrencyCode,
          })
        : "-",
      description:
        "average amount spent to achieve a specific action or outcome",
    },
  ];
  return (
    <Accordion.Item className={styles.accordionItem} value={ad.id}>
      <Accordion.Trigger className={styles.adHeader}>
        <div className={"flex gap-8 w-full items-center"}>
          <Asset
            type={thumbnail.contentType}
            preview={thumbnail.uri}
            name={thumbnail.uri}
            className={"h-[32px] w-[32px] rounded-8"}
          />
          <div className="flex flex-col gap-4">
            <Text size="xs" weight="medium">
              {ad.name}
            </Text>
            <div className="flex gap-4">
              <Icon icon={LayoutGrid01} size="sm" />
              <Text size="2xs" tone="subdued">
                <span>{snakeCaseToTitleCase(ad.adType)}</span>
              </Text>
            </div>
          </div>
        </div>
        <div className={"flex items-center gap-8"}>
          <BadgeV2
            leftIcon={Eye}
            label={`${adInsight?.impressions || 0}`}
            color="purple"
          />
          <ArrowDownIcon
            className={styles.arrowDownIcon}
            height={20}
            width={20}
          />
        </div>
      </Accordion.Trigger>
      <Accordion.Content className={styles.adContent}>
        <section>
          <div>
            <div>
              {/* Stats */}
              <div className="flex flex-col gap-12 w-full">
                {adStats.map((adStat) => (
                  <div
                    className={classNames(
                      "flex justify-between py-6 items-center w-full relative px-24",
                      styles.adStat,
                    )}
                  >
                    <div className={"flex gap-6"}>
                      <div className="flex gap-4">
                        <Icon icon={adStat.icon} />
                        <Text size="sm" weight="medium">
                          {adStat.label}
                        </Text>
                      </div>
                      <VerticalSeparator totalWidth={1} separatorHeight={10} />
                      <Text size="xs" tone="subdued">
                        {adStat.description}
                      </Text>
                    </div>
                    {adStat.value === "-" ? (
                      <Text size="xs">-</Text>
                    ) : (
                      <BadgeV2 label={adStat.value} size="small" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Accordion.Content>
    </Accordion.Item>
  );
}
// <div className={styles.previewSection}>
//   <div className={styles.previewSmartphones}>
//     <div className={styles.smartphoneWrapper}>
//       <Smartphone
//         screenSize="16rem"
//         accountName={activity?.MetaAdAccount?.metaName}
//         adType={ad.adType}
//         callToActionLabel={ad.CTAText}
//         preview={lineItems}
//         primaryText={ad.primaryText}
//         profilePicture={activity?.MetaAdAccount?.Business?.picture}
//         type="facebook"
//         variant="feed"
//       />
//     </div>

//     <div className={styles.smartphoneWrapper}>
//       <Smartphone
//         screenSize="16rem"
//         accountName={activity?.MetaAdAccount?.metaName}
//         adType={ad.adType}
//         callToActionLabel={ad.CTAText}
//         preview={lineItems}
//         primaryText={ad.primaryText}
//         profilePicture={activity?.MetaAdAccount?.Business?.picture}
//         type="instagram"
//         variant="stories"
//       />
//     </div>

//     <div className={styles.smartphoneWrapper}>
//       <Smartphone
//         screenSize="16rem"
//         accountName={activity?.MetaAdAccount?.metaName}
//         adType={ad.adType}
//         callToActionLabel={ad.CTAText}
//         preview={lineItems}
//         primaryText={ad.primaryText}
//         profilePicture={activity?.MetaAdAccount?.Business?.picture}
//         type="instagram"
//         variant="feed"
//       />
//     </div>
//   </div>
// </div>

export default ActivityAdAccordion;
