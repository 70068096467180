import { useState } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { CalloutCard } from "src/components/CalloutCard/CalloutCard";
import {
  SideDrawer,
  SideDrawerTrigger,
} from "src/modules/global/components/SideDrawer";
import { MetaLeadCreateTypes } from "../misc/metaLeadCreateTypes";
import { MetaLeadForm } from "./MetaLeadForm";
import styles from "./MetaLeadSideDrawer.module.scss";

interface IMetaLeadSideDrawer {
  onSubmit: (i: MetaLeadCreateTypes) => void;
  selectedValue?: MetaLeadCreateTypes;
}

export function MetaLeadSideDrawer({
  onSubmit,
  selectedValue,
}: IMetaLeadSideDrawer) {
  const [isOpen, setIsOpen] = useState(false);

  const trigger = (
    <CalloutCard
      variant="inline"
      title={selectedValue ? selectedValue?.name : "Create Lead Form"}
      description={
        selectedValue
          ? `Pre-filled questions : ${selectedValue?.prefillQuestions?.length}`
          : "Instant form help you to capture leads directly on Instagram or Facebook"
      }
      primaryAction={{
        children: selectedValue ? "Edit Form" : "Add Form",
        as: SideDrawerTrigger,
        variant: "outline",
      }}
    />
  );

  function handleCancel() {
    setIsOpen(false);
  }

  function handleSubmit(values: MetaLeadCreateTypes) {
    onSubmit(values);
    setIsOpen(false);
  }

  return (
    <div>
      <SideDrawer
        width="90vw"
        maxWidth={1240}
        isOpen={isOpen}
        onIsOpenChange={setIsOpen}
        closeOnEscapeButton={false}
        closeOnOutsideClick={false}
        trigger={trigger}
      >
        <MetaLeadForm
          initialValues={selectedValue}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </SideDrawer>
    </div>
  );
}
