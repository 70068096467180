import Avatar from "react-avatar";
import { Asset } from "../../../components/Smartphone/Asset";
import styles from "./ClientThumbnail.module.scss";
import { Asset as IAsset } from "src/lib/types";

export function ClientThumbnail({
  asset,
  clientName,
  size = "24px",
}: {
  asset?: IAsset;
  clientName: string;
  size?: string;
}) {
  if (asset) {
    return (
      <div style={{ flexShrink: 0, width: size, height: size }}>
        <Asset
          type={asset?.contentType}
          name={asset?.name}
          preview={asset?.uri}
          className={styles.avatar}
        />
      </div>
    );
  }

  return (
    <div style={{ flexShrink: 0, width: size, height: size }}>
      <Avatar
        round
        name={clientName}
        size={size}
        maxInitials={1}
        style={{ width: size, height: size, flexShrink: 0 }}
        alt="User Avatar"
        className={styles.avatar}
      />
    </div>
  );
}
