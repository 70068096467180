import {
  AnnotationQuestion,
  Check,
  Colors,
  Disc01,
  LinkExternal02,
  Type01,
} from "@untitled-ui/icons-react";
import React, { SVGAttributes } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Icon } from "src/components/Icon/Icon";
import { Text } from "src/components/Text/Text";
import {
  CustomerQuery,
  MetaLeadFormSubmission,
} from "src/graphql/generated/schema";
import noSubmissionsImage from "../assets/noSubmissions.png";
import { prefilledQuestionOptions } from "src/modules/creative/misc/prefilledQuestionOptions";

export function CustomerSubmissions({
  customer,
}: {
  customer: CustomerQuery["customer"];
}) {
  const { MetaLeadFormSubmissions: metaLeadFormSubmissions, sourceActivityId } =
    customer;

  const prefilledFields = metaLeadFormSubmissions
    ?.filter((submission) => {
      return !submission.isCustomQuestion;
    })
    ?.map((submission) => {
      return {
        label: submission.label,
        value: submission.value,
        icon: prefilledQuestionOptions.find(
          (option) => option.value === submission.prefillQuestionType,
        )?.icon,
      };
    });

  const yesOrNoQuestions = metaLeadFormSubmissions?.filter((submission) => {
    return (
      submission.isCustomQuestion &&
      submission.customQuestionType === "YES_OR_NO"
    );
  });

  const selectOneQuestions = metaLeadFormSubmissions?.filter((submission) => {
    return (
      submission.isCustomQuestion &&
      submission.customQuestionType === "SELECT_ONE"
    );
  });

  const textQuestions = metaLeadFormSubmissions?.filter((submission) => {
    return (
      submission.isCustomQuestion && submission.customQuestionType === "TEXT"
    );
  });

  const hasNoSubmissions = !metaLeadFormSubmissions?.length;

  if (hasNoSubmissions) {
    return (
      <div className="h-full relative">
        <div className="flex items-center absolute top-[calc(50%-58px)] left-1/2 -translate-x-1/2 -translate-y-1/2 flex-col">
          <img
            src={noSubmissionsImage}
            alt="empty page"
            width={190}
            height={111}
          />
          <div className="flex justify-center w-full max-w-[316px] mx-auto">
            <Text size="xs" align="center" tone="gray-700">
              It looks like there's nothing here right now. Start exploring or
              add something new to get started!
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-16 flex flex-col gap-16">
      {!!prefilledFields.length && (
        <Box icon={AnnotationQuestion} label="Information you asked">
          {prefilledFields.map((prefillField) => (
            <div className="flex items-center gap-8">
              <span className="min-w-24 flex items-center gap-8">
                {prefillField.icon && (
                  <Icon
                    icon={prefillField.icon}
                    iconColor="inherit"
                    size="sm"
                  />
                )}
                <Text tone="neutral-400" weight="medium">
                  {prefillField.label}
                </Text>
              </span>
              <span>
                <Text weight="medium">{prefillField.value}</Text>
              </span>
            </div>
          ))}
        </Box>
      )}

      {!!yesOrNoQuestions.length && (
        <Box label="Yes / No only" icon={Disc01}>
          {yesOrNoQuestions.map((yesOrNoQuestion, index) => (
            <div className="flex flex-col gap-8">
              <Text tone="neutral-500" weight="medium">
                Q{index + 1}. {yesOrNoQuestion.label}
              </Text>

              <Text weight="medium">Ans. {yesOrNoQuestion.value}</Text>
            </div>
          ))}
        </Box>
      )}

      {!!selectOneQuestions.length && (
        <Box label="Select One" icon={Colors}>
          {selectOneQuestions.map((selectOneQuestion, index) => (
            <div className="flex flex-col gap-8">
              <Text tone="neutral-500" weight="medium">
                Q{index + 1}. {selectOneQuestion.label}
              </Text>

              <div className="flex flex-col gap-8">
                <span className="flex items-center gap-4">
                  <Icon icon={Check} size="sm" />
                  <Text weight="medium"> {selectOneQuestion.value}</Text>
                </span>
                {selectOneQuestion.options?.map((option, index) => (
                  <div key={index} className="flex pl-16">
                    <Text tone="neutral-400" weight="medium">
                      {option}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Box>
      )}

      {!!textQuestions.length && (
        <Box label="Text" icon={Type01}>
          {textQuestions.map((textQuestion, index) => (
            <div className="flex flex-col gap-8">
              <Text tone="neutral-500" weight="medium">
                Q{index + 1}. {textQuestion.label}
              </Text>

              <Text weight="medium">Ans. {textQuestion.value}</Text>
            </div>
          ))}
        </Box>
      )}

      <div className="py-24 flex items-center gap-8">
        {sourceActivityId && (
          <ButtonV2
            to={`/activities/${sourceActivityId}`}
            variant="outline"
            size="small"
            leftIcon={LinkExternal02}
          >
            View activity
          </ButtonV2>
        )}
        {/* <ButtonV2 variant="outline" size="small" leftIcon={LinkExternal02}>
          View lead form
        </ButtonV2> */}
      </div>
    </div>
  );
}

function Box({
  label,
  icon,
  children,
}: {
  label: string;
  icon: React.FC<SVGAttributes<SVGElement>>;
  children: React.ReactNode;
}) {
  return (
    <div className="border border-neutral-50 rounded-8">
      <div className="flex items-center gap-8 py-8 pl-12">
        <Icon icon={icon} iconColor="inherit" size="sm" />
        <Text weight="medium" size="xs">
          {label}
        </Text>
      </div>

      <div className="pt-16 border-t border-neutral-50 pl-12 pb-24 flex flex-col gap-24">
        {children}
      </div>
    </div>
  );
}
