import { useState } from "react";
import {
  useAssetCreateMutation,
  useUploadAssetToFacebookMutation,
} from "src/graphql/generated/schema";
import getHeightAndWidthFromDataUrl from "src/lib/getHeightAndWidthFromDataUrl";
import { useUploadFile } from "src/lib/useUploadFile";
import { AssetContentType } from "../misc/createCampaignTypes";
import {
  imageAndVideoMimeType,
  videoMimeTypes as validVideoMimeTypes,
  imageMimeTypes as validImageMimeTypes,
} from "../misc/supportedAssetTypes";
import { resizeImage } from "src/modules/global/functions/resizeImage";
import { validateFile } from "src/lib/validateFile";

interface IUseCreateAsset {
  validMimeType?: string[];
  maxVideoFileSizeInBytes: number;
  maxImageFileSizeInBytes: number;
  adAccountId?: string;
  minimumHeight?: number;
  minimumWidth?: number;
}

export function useCreateAsset({
  validMimeType = imageAndVideoMimeType,
  maxVideoFileSizeInBytes,
  maxImageFileSizeInBytes,
  adAccountId,
  minimumHeight = 120,
  minimumWidth = 120,
}: IUseCreateAsset) {
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadAssetToFacebook] = useUploadAssetToFacebookMutation();
  const [createAsset] = useAssetCreateMutation();

  const uploadFile = useUploadFile({
    maxVideoFileSizeInBytes: maxVideoFileSizeInBytes,
    maxImageFileSizeInBytes: maxImageFileSizeInBytes,
    supportedMimeTypes: validMimeType,
    minimumHeight,
    minimumWidth,
  });

  async function handleFileUpload(file: File) {
    try {
      setLoading(true);

      await validateFile({
        file,
        maxVideoFileSizeInBytes,
        maxImageFileSizeInBytes,
        supportedMimeTypes: validMimeType,
        minimumHeight,
        minimumWidth,
      });

      file = await resizeImage({ file, maxWidthOrHeight: 2048 });

      const data = await uploadFile(file);
      let metaVideoId: string | undefined;
      let metaImageHash: string | undefined;

      const { width, height } = await getHeightAndWidthFromDataUrl(
        data.uri,
        data.contentType as "IMAGE" | "VIDEO",
      );

      // upload asset
      const payload = await createAsset({
        variables: {
          input: {
            name: data.name,
            uri: data.uri,
            ...(metaVideoId && { metaVideoId }),
            ...(metaImageHash && { metaImageHash }),
            mediaKey: data.mediaUploadKey,
            contentType: data.contentType as AssetContentType,
            fileSize: data.fileSize,
            height,
            width,
          },
        },
        refetchQueries: ["assets"],
        awaitRefetchQueries: true,
      });

      // upload to facebook it adAccountId is given
      if (adAccountId) {
        await uploadAssetToFacebook({
          variables: {
            input: {
              assetId: payload.data.assetCreate.id,
              metaAccountId: adAccountId,
            },
          },
        });
      }

      return payload.data.assetCreate;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }

  return { handleFileUpload, loading };
}
