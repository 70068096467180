import EmptyState from "src/components/EmptyState/EmptyState";
import { useActivitiesLazyQuery } from "src/graphql/generated/schema";
import { Spinner } from "../../../components/Spinner/Spinner";
import emptyCampaign from "../assets/empty-state-campaigns.png";
import { campaignFilters, campaignSortBy } from "../misc/campaignFilter";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";
import { useContext, useLayoutEffect, useState } from "react";
import { PaginationInput } from "../misc/paginationInput";
import { Table } from "src/components/Table/Table";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Announcement03 } from "@untitled-ui/icons-react";
import { ActivityItem } from "./ActivityItem";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import InfiniteScroll from "react-infinite-scroll-component";
import AuthContext from "src/stores/AuthContext";
import { campaignListingStatsMapping } from "../../global/misc/campaignListingStatsMapping";
import { growStatOptions } from "../misc/growStatOptions";

interface ICampaignListingStatusGrouped {
  filters: campaignFilters;
  sortBy: campaignSortBy;
}

export function ActivitiesListing({
  filters,
  sortBy,
}: ICampaignListingStatusGrouped) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { activeClient } = useContext(AuthContext);
  const [pagination, setPagination] = useState<PaginationInput>({
    limit: itemsPerPage,
    cursor: null,
  });
  const [activitiesFn, { called, error, data, loading, fetchMore }] =
    useActivitiesLazyQuery();

  async function triggerFetch({ filters, pagination, sortBy }) {
    const query = filters.query.trim();
    await activitiesFn({
      variables: {
        filters: {
          name: query,
          ...(pagination.cursor && {
            cursor: pagination.cursor,
          }),
          limit: pagination.limit,
          createdAt: filters.createdAt,
        },
        sortBy,
      },
    });
  }

  const debouncedQuery = useDebouncedVariable(filters.query);
  const analyticsCategory = activeClient?.analyticsCategory;
  const campaignAnalytics = campaignListingStatsMapping[analyticsCategory];

  useLayoutEffect(() => {
    triggerFetch({
      filters: {
        ...filters,
        query: debouncedQuery,
      },
      pagination,
      sortBy,
    });
  }, [
    sortBy,
    pagination,
    filters.createdAt,
    filters.filterStatus,
    filters.platform,
    filters.status,
    debouncedQuery,
    // triggerFetch and filters are ignore because they are functions
  ]);

  async function fetchMoreActivities() {
    await fetchMore({
      variables: {
        filters: {
          cursor: data?.activities?.pageInfo.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.activities?.edges ?? [];
        const oldEdges = previousResult?.activities?.edges ?? [];
        return {
          activities: {
            ...fetchMoreResult.activities,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  if (!called || loading) {
    return <Spinner height="screen" />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (data && !data.activities.edges.length) {
    return (
      <EmptyState
        title={"Get started with your first campaign"}
        description="Get started by creating your first campaign. Campaigns are a powerful way to reach your target audience and achieve your marketing goals."
        illustration={emptyCampaign}
        buttonText="Create Campaign"
        to="/campaigns/new"
      />
    );
  }

  const statOptions = growStatOptions[activeClient.analyticsCategory];

  return (
    <InfiniteScroll
      next={fetchMoreActivities}
      hasMore={data?.activities?.pageInfo?.hasNextPage || false}
      loader={<Spinner height={100} />}
      dataLength={data?.activities?.edges?.length || 0}
    >
      <Table
        isScrollable
        selectedItems={selectedItems}
        onSelectedItemsChange={setSelectedItems}
        headings={[
          {
            title: "Activities",
          },
          {
            title: "Status",
          },
          {
            icon: Announcement03,
            title: "Campaign",
          },
          ...statOptions.map((o) => ({
            title: o.label,
          })),
          {
            title: "",
          },
        ]}
        idExtractor={(item) => item.node.id}
        data={data.activities.edges}
        renderItem={({ item }) => (
          <ActivityItem
            campaignAnalytics={campaignAnalytics}
            activity={item.node}
            key={item.node.id}
          />
        )}
      ></Table>
    </InfiniteScroll>
  );
}
