import classNames from "classnames";
import Avatar from "react-avatar";
import styles from "./BadgeV2.module.scss";

export interface IBadgeV2 {
  color?:
    | "gray"
    | "red"
    | "yellow"
    | "green"
    | "blue"
    | "purple"
    | "sapphire"
    | "sienna"
    | "viridian"
    | "crimson"
    | "slate"
    | "amethyst"
    | "wine"
    | "cerulean"
    | "pine"
    | "khaki"
    | "jade"
    | "royal-blue";
  size?: "small" | "medium" | "large";
  label: React.ReactNode;
  avatar?: {
    name?: string;
    email?: string;
    src?: string;
  };
  leftIcon?: React.FC<React.SVGAttributes<any>>;
  rightIcon?: React.FC<React.SVGAttributes<any>>;
  helpText?: string;
}

export function BadgeV2({
  label,
  avatar,
  rightIcon,
  leftIcon,
  size = "medium",
  color = "gray",
  helpText,
}: IBadgeV2) {
  const LeftIcon = leftIcon;
  const RightIcon = rightIcon;

  const iconSize = 12;
  const avatarSize = 16;

  if (avatar && leftIcon) {
    throw new Error("can not pass both avatar and left icon");
  }

  return (
    <div
      className={classNames(styles.badge, {
        [styles.small]: size === "small",
        [styles.medium]: size === "medium",
        [styles.large]: size === "large",
        [styles.gray]: color === "gray",
        [styles.red]: color === "red",
        [styles.yellow]: color === "yellow",
        [styles.green]: color === "green",
        [styles.blue]: color === "blue",
        [styles.purple]: color === "purple",
        [styles.sapphire]: color === "sapphire",
        [styles.sienna]: color === "sienna",
        [styles.viridian]: color === "viridian",
        [styles.crimson]: color === "crimson",
        [styles.slate]: color === "slate",
        [styles.amethyst]: color === "amethyst",
        [styles.wine]: color === "wine",
        [styles.cerulean]: color === "cerulean",
        [styles.pine]: color === "pine",
        [styles.khaki]: color === "khaki",
        [styles.jade]: color === "jade",
        [styles.royalBlue]: color === "royal-blue",
        [styles.hasAvatar]: avatar,
        [styles.hasLeftIcon]: leftIcon,
        [styles.hasRightIcon]: rightIcon,
        [styles.hasNoContent]: !label,
      })}
      title={helpText}
    >
      {LeftIcon && (
        <LeftIcon className={styles.icon} height={iconSize} width={iconSize} />
      )}

      {avatar && (
        <Avatar
          className={styles.avatar}
          size={`${avatarSize}px`}
          {...avatar}
        />
      )}

      <div className={styles.label}>{label}</div>
      {rightIcon && (
        <RightIcon height={iconSize} width={iconSize} className={styles.icon} />
      )}
    </div>
  );
}
