import styles from "./CreativePreviewPanel.module.scss";
import { CreativePreview } from "src/modules/global/components/CreativePreview";
import { CreativeCreateTypesV2 } from "../misc/creativeCreateTypesV2";
import instagramUserProfile from "../../global/assets/instagramUserProfile.svg";
import { useFormikContext } from "formik";
import { MetaPlacementOptionValue } from "../misc/metaPlacementOptions";

interface ICreativePreviewPanel {
  setActiveAssetIndex: (i: number) => void;
  activeAssetIndex: number;
  metaSelectedPlacement: MetaPlacementOptionValue;
  values: CreativeCreateTypesV2;
}

export function CreativePreviewPanel({
  setActiveAssetIndex,
  activeAssetIndex,
  metaSelectedPlacement,
  values,
}: ICreativePreviewPanel) {
  return (
    <div className={styles.previewWrapper}>
      <h2 className={styles.previewHeading}>Creative Preview</h2>

      <CreativePreview
        setActiveAssetIndex={setActiveAssetIndex}
        activeAssetIndex={activeAssetIndex}
        ad={
          values.meta.edited
            ? (values.meta as any)
            : ({
                ...values.global,
                adType: "SINGLE_IMAGE_OR_VIDEO",
              } as any)
        }
        metaFormat={metaSelectedPlacement === "META_STORY" ? "STORY" : "FEED"}
        platform="INSTAGRAM"
        accountName={"Brand Name"}
        profilePicture={instagramUserProfile}
      />
    </div>
  );
}
