import Divider from "@untitled-ui/icons-react/build/esm/Divider";

export type MetaPlacementOptionValue = "META_FEED" | "META_STORY";

export const metaPlacementOptions = [
  {
    label: "Meta Feed",
    value: "META_FEED" as const,
    icon: Divider,
  },
  {
    label: "Meta Story",
    value: "META_STORY" as const,
    icon: Divider,
  },
];
