import { Spinner } from "../../../components/Spinner/Spinner";
import {
  Client,
  OrganizationClientsQuery,
} from "../../../graphql/generated/schema";
import format from "date-fns/format";
import {
  Table,
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import { DropdownV2 } from "src/modules/campaign/components/DropdownV2";
import { DotsVertical } from "@untitled-ui/icons-react";
import Avatar from "react-avatar";
import { useState } from "react";
import { EditClientModal } from "./EditClientModal";

interface ClientsTableArgs {
  clients: OrganizationClientsQuery["organizationClients"];
  loading?: boolean;
}

export function ClientsTable({ clients, loading }: ClientsTableArgs) {
  const [selectedClientId, setSelectedClientId] = useState<string[]>([]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Table
      variant="card"
      isSelectable={true}
      selectedItems={selectedClientId}
      onSelectedItemsChange={setSelectedClientId}
      headings={[
        { title: "" },
        { title: "Name and date of creation" },
        { title: "Users" },
      ]}
      idExtractor={(item) => item.id}
      data={clients}
      renderItem={({ item, index }) => <ClientItem client={item} />}
    />
  );
}

interface IClientItem {
  client: OrganizationClientsQuery["organizationClients"][0];
}

function ClientItem({ client }: IClientItem) {
  const [showEditClientModal, setShowEditClientModal] = useState(false);

  return (
    <TableRow id={client.id}>
      <TableCell width="28px">
        <Avatar name={client.name.split(" ")[0]} size="24px" round />
      </TableCell>
      <TablePrimaryCell
        title={client.name}
        subtitle={`Added on ${format(
          new Date(client.createdAt),
          "dd MMM, yyyy",
        )}`}
      />

      <TableCell width="120px">
        {client.UserRoles.filter((role) => role.Users.length > 0).map(
          (role) => (
            <div className="inline-flex items-center ml-[-10px]" key={role.id}>
              <Avatar name={role.Users[0].firstName} size="24px" round />
            </div>
          ),
        )}
      </TableCell>

      <TableCell width="32px">
        <EditClientModal
          isOpen={showEditClientModal}
          onClose={() => setShowEditClientModal(false)}
          id={client.id}
        />

        <DropdownV2
          size="small"
          buttonVariant="plain"
          hideArrow
          icon={DotsVertical}
          options={[
            {
              label: "Edit Client",
              onClick: () => setShowEditClientModal(true),
            },
          ]}
        ></DropdownV2>
      </TableCell>
    </TableRow>
  );
}
