import { ErrorHandler } from "src/components/ErrorHandler";
import Header from "src/components/Header/Header";
import { useOrganizationQuery } from "src/graphql/generated/schema";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { OrganizationGeneralSettings } from "../components/OrganizationGeneralSettings";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { OrganizationCriticalActions } from "../components/OrganizationCriticalActions";

export function OrganizationGeneralSettingsPage() {
  const { data, loading, error } = useOrganizationQuery();

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <>
      <Header title="General" />
      <div className="max-w-[820px] mx-auto pt-24 w-full">
        <div className="pb-16">
          <SectionHeader
            size="large"
            title="Account information"
            description="Access and manage your account settings, profile details, and other essential account information."
          />
        </div>
        <OrganizationGeneralSettings organization={data.organization} />
        <OrganizationCriticalActions organization={data.organization} />
      </div>
    </>
  );
}
