import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import DatePicker from "src/components/Datepicker/Datepicker";
import { Grid } from "src/components/Grid/Grid";
import { WidgetHeader } from "src/components/WidgetHeader/WidgetHeader";
import { camelCaseToTitleCase } from "src/lib/camelCaseToTitleCase";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { getGrowAggregateStatsByType } from "src/modules/global/functions/getGrowAggregateStatsByType";
import { GrowthInsightCategoryTabs } from "./GrowthInsightCategoryTabs";
import metaIcon from "src/icons/meta.svg?react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import { Text } from "src/components/Text/Text";
import { getDateFilterFromFilterEnum } from "src/modules/global/misc/dateFilterUtils";
import { MetricCard } from "src/components/MetricCard/MetricCard";
import { useOverallPerTickStats } from "../../functions/useOverallPerTickStats";
import { getMonthFromNumber } from "src/modules/campaign/misc/getMonthFromNumber";
import { useGrowAggregatedStats } from "src/modules/global/functions/useGrowAggregatedStats";
import differenceInDays from "date-fns/differenceInDays";
import sub from "date-fns/sub";
import { percentageChange } from "../../misc/percentageChange";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { highPerformanceDateFormatter } from "src/lib/highPerformanceDateFormatter";
import { LineGraphV3 } from "src/modules/global/components/LineGraphV3";
import subDays from "date-fns/subDays";
import format from "date-fns/format";
import { highPerformanceMonthFormatter } from "src/lib/highPerformanceMonthFormatter";
import { highPerformanceHourFormatting } from "src/lib/highPerformanceHourFormatting";

interface IGrowthOverviewGraphWidget {
  selectedCurrencyCode: string;
}

const defaultValue = "THIS_MONTH";

const metricsArray = [
  {
    label: "Awareness",
    description:
      "Creating visibility and recognition of your brand across channels",
    value: "AWARENESS",
  },
  {
    label: "Engagement",
    description: "Encouraging user interaction with your brand's content",
    value: "ENGAGEMENT",
  },
  {
    label: "Traffic",
    description: "Directing potential customers to your website via channels",
    value: "TRAFFIC",
  },
  {
    label: "Leads",
    description:
      "Capturing and qualifying customer information to build a sales pipeline",
    value: "LEADS",
  },
  {
    label: "Transactional",
    description: "Converting visitors into paying customers",
    value: "TRANSACTIONAL",
  },
];

type Tabs = "TRANSACTIONAL" | "ENGAGEMENT" | "AWARENESS" | "TRAFFIC" | "LEADS";

export function GrowthOverviewGraphWidget({
  selectedCurrencyCode,
}: IGrowthOverviewGraphWidget) {
  const [selectedTab, setSelectedTab] = useState<Tabs>("AWARENESS");
  const [showCompareAt, setShowCompareAt] = useState(true);
  const [selectedGraphKey, setSelectedGraphKey] =
    useState("totalValuePurchase");

  const { gte: from, lte: to } = useMemo(
    () => getDateFilterFromFilterEnum(defaultValue),
    [defaultValue],
  );
  const [selectedDate, setSelectedDate] = useState<DateRange>({
    from,
    to,
  });

  const {
    loading: loadingAggregatedStats,
    data: growAggregatedStats,
    compareData: aggregatedComparisonStats,
  } = useGrowAggregatedStats({
    dateFrom: selectedDate.from,
    dateTo: selectedDate.to,
    others: {
      insightType: "ACTIVITY_INSIGHT",
    },
  });

  const dayDifference = differenceInDays(selectedDate.to, selectedDate.from);
  const comparisonTo = subDays(
    selectedDate.to,
    dayDifference > 0 ? dayDifference - 1 : 1,
  );
  // start calculating from a day before
  const comparisonFrom = subDays(
    selectedDate.from,
    dayDifference > 0 ? dayDifference - 1 : 1,
  );
  const { data: comparePerTickStatsData } = useOverallPerTickStats({
    dateFrom: comparisonFrom,
    dateTo: comparisonTo,
  });

  const {
    loading: loadingPerTickStats,
    data: perTickStats,
    interval,
  } = useOverallPerTickStats({
    dateFrom: selectedDate.from,
    dateTo: selectedDate.to,
    loadingOnRefetch: true,
  });

  const statTabs = getGrowAggregateStatsByType({
    type: selectedTab,
  });

  const stats = statTabs.map((v) => {
    const value = growAggregatedStats?.[v.valueKey] || 0;
    const comparisonValue = aggregatedComparisonStats?.[v.valueKey] || 0;
    const formattedValue = formatNumberByType(value, v.valueType, {
      showDecimal: true,
      selectedCurrencyCode,
    });

    let performanceBadge:
      | { value: string; direction: "up" | "down" }
      | undefined;

    if (v.valueKey !== "not_tracked" && comparisonValue && value) {
      performanceBadge = {
        value: percentageChange(value, comparisonValue),
        direction: comparisonValue >= value ? "down" : "up",
      };
    }

    return {
      ...v,
      value: formattedValue,
      performanceBadge,
    };
  });

  useEffect(() => {
    setSelectedGraphKey(stats[0]?.valueKey);
  }, [selectedTab]);

  const statValues =
    perTickStats?.map((v) => {
      return {
        xAxisKey:
          interval === "HOUR"
            ? v.tickHour
            : interval === "DAY"
            ? (v as any).tickDate
            : `${v.tickMonth}-${v.tickYear}`,
        value: v[selectedGraphKey] || 0,
        ...v,
      };
    }) || [];

  const statComparisonValues =
    comparePerTickStatsData?.map((v) => ({
      xAxisKey:
        interval === "HOUR"
          ? v.tickHour
          : interval === "DAY"
          ? (v as any).tickDate
          : `${getMonthFromNumber((v as any).tickMonth)}`,
      value: v[selectedGraphKey] || 0,
      ...v,
    })) || [];

  const values = statValues.map((v: any) => {
    const difference = dayDifference + 2;
    const comparisonDate =
      interval === "DAY"
        ? format(subDays(new Date(v.xAxisKey), difference), "yyyy-MM-dd")
        : v.xAxisKey;

    const comparisonValueItem = statComparisonValues.find((c: any) =>
      interval == "HOUR"
        ? c.tickHour === v.tickHour
        : interval == "DAY"
        ? c.xAxisKey === comparisonDate
        : c.tickMonth === v.tickMonth,
    );

    return {
      ...v,
      comparisonValue: comparisonValueItem?.value,
      comparisonXAxisKey: comparisonValueItem?.xAxisKey,
    };
  });

  return (
    <div className="border-[1px] rounded-8">
      <div className="py-[8.5px] px-[20px]">
        <WidgetHeader
          title={"Growth Overview"}
          action={
            <DatePicker
              onChange={setSelectedDate}
              defaultValue={defaultValue}
            />
          }
        />
      </div>

      <div className="h-[1px] w-full bg-neutral-50" />

      <div className="p-[12px] pb-20px flex flex-col gap-8">
        <GrowthInsightCategoryTabs
          tabs={metricsArray}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className={"flex flex-col gap-16"}>
          {loadingPerTickStats ? (
            <Skeleton height={172} width={"100%"} />
          ) : (
            <LineGraphV3
              height={175}
              xAxis={{
                label: "",
                dataKey: "xAxisKey",
                tickFormatter: (value) =>
                  interval === "MONTH"
                    ? highPerformanceMonthFormatter(value)
                    : interval === "HOUR"
                    ? highPerformanceHourFormatting(value)
                    : highPerformanceDateFormatter(value),
              }}
              statComparisonLabel={
                showCompareAt
                  ? {
                      dataKey: selectedGraphKey,
                      name: camelCaseToTitleCase(selectedGraphKey),
                      color: "var(--color-vivid-indigo-100)",
                    }
                  : null
              }
              tooltip={{
                comparisonXAxisFormatter: (value) =>
                  interval === "MONTH"
                    ? highPerformanceMonthFormatter(value)
                    : interval === "HOUR"
                    ? highPerformanceHourFormatting(value)
                    : highPerformanceDateFormatter(value),
                valueFormatter: (v) =>
                  formatNumberByType(Number(v), "NUMBER", {
                    showDecimal: false,
                  }),
                xAxisFormatter: (value) =>
                  interval === "MONTH"
                    ? highPerformanceMonthFormatter(value)
                    : interval === "HOUR"
                    ? highPerformanceHourFormatting(value)
                    : highPerformanceDateFormatter(value),
                comparisonValueFormatter: (v) =>
                  formatNumberByType(Number(v), "NUMBER", {
                    showDecimal: false,
                  }),
              }}
              yAxis={{
                dataKey: selectedGraphKey,
                label: selectedGraphKey,
                tickFormatter: (v) =>
                  formatNumberByType(Number(v), "NUMBER", {
                    showDecimal: false,
                  }),
              }}
              statLabel={{
                color: "var(--color-primary)",
                dataKey: selectedGraphKey,
                name: camelCaseToTitleCase(selectedGraphKey),
              }}
              values={values}
              width={"100%"}
            />
          )}
          <div className="px-14">
            <Grid column={{ xs: 5 }} columnGap={{ xs: 12 }}>
              {stats.map((v, i) => (
                <MetricCard
                  loading={loadingAggregatedStats}
                  valueIcon={v.valueIcon}
                  key={i}
                  sourceIcon={metaIcon}
                  selected={v.valueKey === selectedGraphKey}
                  title={v.title}
                  value={v.value}
                  performanceBadge={
                    showCompareAt && v.value ? v.performanceBadge : null
                  }
                  onClick={() => {
                    setSelectedGraphKey(v.valueKey);
                  }}
                />
              ))}
            </Grid>
          </div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-neutral-50" />
      <div className="flex justify-between py-8 px-12 ">
        <div className="flex gap-16">
          <ButtonV2 disabled variant="outline">
            View Full Insights
          </ButtonV2>
          <div className="h-full w-[1px] bg-neutral-50" />
          <Checkbox
            value={""}
            disabled={statComparisonValues.length === 0}
            checked={showCompareAt}
            onChange={(newChecked) => {
              setShowCompareAt(!showCompareAt);
            }}
            label={
              <Text size="xs" weight="medium">
                View Comparison
              </Text>
            }
          />
        </div>
        {/* {showCompareAt && statComparisonValues.length > 0 && <div></div>} */}
      </div>
    </div>
  );
}
