import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { DeleteOrganization } from "./DeleteOrganization";
import { Organization } from "src/graphql/generated/schema";

interface IOrganizationCriticalActions {
  organization: Organization;
}

export function OrganizationCriticalActions({
  organization,
}: IOrganizationCriticalActions) {
  return (
    <div className="pt-32">
      <hr className="border-neutral-50 pb-32" />
      <div className="pb-16">
        <SectionHeader
          size="large"
          title="Critical actions"
          description="Actions below are irreversible and can result in permanent data loss."
        />
      </div>

      <DeleteOrganization organization={organization} />
    </div>
  );
}
