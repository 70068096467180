import { Icon } from "src/components/Icon/Icon";
import { Text } from "src/components/Text/Text";

export function CampaignActivityCardStatItem({
  label,
  icon,
  value,
}: {
  label: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  value: string | number;
}) {
  return (
    <div className="flex justify-between items-center px-4 py-12 border-b last:border-0">
      <div className="flex gap-8 items-center">
        <Icon icon={icon} />
        <Text size="sm" weight="medium">
          {value}
        </Text>
      </div>
      <Text tone="subdued" size="xs" isNumeric={typeof value === "number"}>
        {label}
      </Text>
    </div>
  );
}
