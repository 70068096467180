import { Formik, FormikHelpers, Form } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { MetaAdAccount } from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { WizardContext } from "src/components/Wizard/WizardContext";
import styles from "./DefaultAdAccountSettings.module.scss";
import { MetaAdAccountDefaultSettingsSelectorModal } from "./MetaAdAccountDefaultSettingsSelectorModal";
import * as Yup from "yup";
import { MetaAdAccountDefaultSettingsForm } from "../misc/MetaIntegrationFormTypes";

const validationSchema = Yup.object({
  pixelId: Yup.string(),
  instagramAccountId: Yup.string(),
  pageId: Yup.string().required(),
  adAccountId: Yup.string().required(),
});

export function DefaultAdAccountSettings({ loading }) {
  const { activeStepIndex, markStepAsDone, data } = useContext(WizardContext);
  const [showSelectorModal, setShowSelectorModal] = useState(false);
  const enabledAdAccounts = data.adAccounts.filter((v) => v.enabled);
  let initialValues = {
    pixelId: "",
    instagramAccountId: "",
    pageId: "",
    adAccountId: "",
  };

  if (data.adAccounts.length === 1) {
    const adAccount = data.adAccounts[0];
    const pages = adAccount?.MetaPages || [];
    const igAccounts = adAccount?.MetaInstagramAccounts || [];
    const pixels = adAccount?.MetaPixels || [];

    if (pages.length === 1 && igAccounts.length === 1 && pixels.length === 1) {
      initialValues = {
        pixelId: pixels[0]?.id,
        pageId: pages[0]?.id,
        instagramAccountId: igAccounts[0]?.id,
        adAccountId: adAccount.id,
      };
    }
  }

  async function formSubmit(
    values: MetaAdAccountDefaultSettingsForm,
    formikHelpers: FormikHelpers<MetaAdAccountDefaultSettingsForm>,
  ) {
    markStepAsDone(activeStepIndex + 1, {
      ...data,
      defaultSettings: {
        ...values,
      },
    });
    return;
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={formSubmit}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
        <Form>
          <StepWizardStep
            isCompleted={false}
            isStepNotActive={activeStepIndex < 3 || loading}
            isLastStep
          >
            <div className={styles.container}>
              <LegacySectionHeading
                heading={"Set Account as default"}
                description={
                  "Select ad account which are connected to your business account"
                }
              />

              <div className={styles.adAccountList}>
                {enabledAdAccounts.map((v) => (
                  <MetaEnabledAdAccountItem
                    metaAdAccount={v}
                    defaultSettings={values}
                    setSelectedAdAccountId={(id: string) => {
                      setFieldValue("adAccountId", id);
                      setShowSelectorModal(true);
                    }}
                  />
                ))}
              </div>
            </div>
            {values.adAccountId && (
              <MetaAdAccountDefaultSettingsSelectorModal
                isOpen={showSelectorModal}
                onClose={() => setShowSelectorModal(false)}
                handleSubmit={() => setShowSelectorModal(false)}
                metaInstagramAccounts={
                  enabledAdAccounts.find((v) => v.id === values.adAccountId)
                    ?.MetaInstagramAccounts || []
                }
                metaPages={
                  enabledAdAccounts.find((v) => v.id === values.adAccountId)
                    ?.MetaPages || []
                }
                metaPixels={
                  enabledAdAccounts.find((v) => v.id === values.adAccountId)
                    ?.MetaPixels || []
                }
              />
            )}
            <div className={styles.buttonWrapper}>
              <ButtonV2
                type="submit"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={!isValid}
              >
                Finish Setup
              </ButtonV2>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}

function MetaEnabledAdAccountItem({
  metaAdAccount,
  defaultSettings,
  setSelectedAdAccountId,
}: {
  metaAdAccount: MetaAdAccount;
  defaultSettings: MetaAdAccountDefaultSettingsForm;
  setSelectedAdAccountId: (i: string) => void;
}) {
  const isDefault = metaAdAccount.MetaPages?.some(
    (v) => v.metaPageId === defaultSettings.pageId,
  );
  return (
    <div className={styles.containerCard}>
      <div className={styles.adAccountInfo}>
        <img
          src={
            metaAdAccount?.Business?.picture ??
            `https://api.dicebear.com/7.x/shapes/svg?seed=${metaAdAccount.metaName}`
          }
          alt={metaAdAccount?.Business?.picture || metaAdAccount?.metaName}
          className={styles.profile}
        />
        <div className={styles.info}>
          <div className={styles.connectionStatus}>
            <span className={styles.name}>
              {snakeCaseToTitleCase(metaAdAccount.metaName)}
            </span>
          </div>
          <span className={styles.description}>
            Ad spent in last 28 days:{" "}
            {metaAdAccount.amountSpent
              ? formatNumberByType(metaAdAccount.amountSpent, "CURRENCY")
              : "-"}
          </span>
        </div>
      </div>
      <div className={styles.badgeContainer}>
        {isDefault && <BadgeV2 label={"Default"} color="gray" size="small" />}
      </div>
      <div className={styles.actionButton}>
        <ButtonV2
          size="small"
          variant="outline"
          onClick={() => setSelectedAdAccountId(metaAdAccount.id)}
        >
          {isDefault ? "Change defaults" : "Set As Default"}
        </ButtonV2>
      </div>
    </div>
  );
}
