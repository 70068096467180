import { useEffect, useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  SortByInput,
  useCreativeTemplateDeleteMutation,
  useCreativeTemplatesLazyQuery,
} from "../../../graphql/generated/schema";
import { PaginationInput } from "../../global/misc/PaginationInput";
import { CreativeItemSlideOver } from "./AdTemplateSingleSlideOver/CreativeItemSlideOver";
import { CreativeListingTable } from "./CreativeListingTable";
import { CreativeFilters } from "../misc/creativeFilters";
import { Spinner } from "src/components/Spinner/Spinner";
import { NoFilteredResultsFound } from "./NoFilteredResultsFound";
import EmptyState from "src/components/EmptyState/EmptyState";
import emptyStateIllustration from "src/modules/global/assets/empty-state-reports.png";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import InfiniteScroll from "react-infinite-scroll-component";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";

interface ICreativeListingLoader {
  filters: CreativeFilters;
  setFilters: (i: CreativeFilters) => void;
  sortBy: SortByInput;
  setSortBy: (i: SortByInput) => void;
}

export function CreativeListingLoader({
  filters,
  setFilters,
  sortBy,
  setSortBy,
}: ICreativeListingLoader) {
  const navigate = useNavigate();
  const [expandedTemplateId, setExpandedTemplateId] = useState<string | null>(
    null,
  );
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const [pagination, setPagination] = useState<PaginationInput>({
    take: itemsPerPage,
    cursor: null,
  });

  const [createTemplateDeleteFn, { loading: deleteLoading }] =
    useCreativeTemplateDeleteMutation();

  const [creativeTemplatesFn, { data, loading, called, fetchMore }] =
    useCreativeTemplatesLazyQuery({
      variables: {
        sortBy,
        filter: pagination,
      },
    });

  async function handleDeleteAdTemplate(id: string) {
    try {
      const { data } = await createTemplateDeleteFn({
        variables: {
          id: id,
        },
        refetchQueries: ["creativeTemplates"],
        awaitRefetchQueries: true,
      });

      if (
        data &&
        data.creativeTemplateDelete &&
        data.creativeTemplateDelete.userError
      ) {
        const errorCode = data.creativeTemplateDelete.userError.code;
        if (errorCode === "ALREADY_IN_USE") {
          toast.error("Ad template is already in use");
          return;
        } else if (errorCode) {
          toast.error(data.creativeTemplateDelete.userError.message);
          return;
        }
      }

      toast.success("Ad template deleted successfully");
    } catch (err) {
      toast.error(err.message);
    }
  }

  async function fetchMoreListing() {
    await fetchMore({
      variables: {
        filter: {
          cursor: data.creativeTemplates?.pageInfo?.endCursor,
        },
      },

      // concatenate old and new entries
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges = fetchMoreResult?.creativetemplates?.edges ?? [];
        const oldEdges = previousResult?.creativetemplates?.edges ?? [];

        return {
          creativeTemplates: {
            ...fetchMoreResult.creativeTemplates,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  async function fetchListing({
    filters,
    pagination,
    sortBy,
  }: {
    filters: CreativeFilters;
    pagination: PaginationInput;
    sortBy: SortByInput;
  }) {
    const query = filters.query.trim();
    const createdAt = filters.createdAt;
    await creativeTemplatesFn({
      variables: {
        sortBy,
        filter: {
          take: pagination.take,
          ...(pagination.cursor && {
            cursor: pagination.cursor,
          }),
          name: query,
          ...(createdAt && {
            createdAt: {
              gte: createdAt.from,
              lte: createdAt.to,
            },
          }),
        },
      },
    });
  }

  const debouncedQuery = useDebouncedVariable(filters.query);

  // Fetch listing when filters change
  // using useLayoutEffect to avoid double render
  // first render is usually with a loader and then the data loads
  useLayoutEffect(() => {
    fetchListing({ filters, pagination, sortBy });
  }, [filters.createdAt, sortBy, pagination, debouncedQuery, called]);

  const creativeTemplates = data ? data.creativeTemplates.edges : [];

  const areFiltersApplied = filters.query !== "";

  if (
    !loading &&
    called &&
    !data?.creativeTemplates?.edges?.length &&
    !areFiltersApplied
  ) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <EmptyState
          title={"Create your Ad Library"}
          description={
            "Create your first ad template to get started. Ad templates help you create consistent, reusable ads for your campaigns. Save time and stay on brand."
          }
          illustration={emptyStateIllustration}
          buttonText="Let's Get Started"
          buttonVariant="solid"
          to="/library/ad-library/create"
        />
      </div>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  if (!creativeTemplates.length) {
    return <NoFilteredResultsFound />;
  }

  return (
    <div>
      <CreativeItemSlideOver
        adTemplateId={expandedTemplateId}
        isOpen={Boolean(expandedTemplateId)}
        onClose={() => {
          setExpandedTemplateId(null);
        }}
        trigger={
          <InfiniteScroll
            next={fetchMoreListing}
            hasMore={data.creativeTemplates?.pageInfo?.hasNextPage || false}
            loader={<Spinner height={200} />}
            dataLength={data.creativeTemplates?.edges.length || 0}
          >
            <CreativeListingTable
              creativeTemplates={creativeTemplates}
              setExpandedTemplateId={(id) => {
                setExpandedTemplateId(id);
              }}
              setSelectedTemplateId={setSelectedItems}
              selectedItems={selectedItems}
              editAdTemplate={async (id) => {
                setExpandedTemplateId(null);
                navigate(`/library/ad-library/${id}/edit`);
              }}
              deleteAdTemplate={async (id) => {
                setExpandedTemplateId(null);
                handleDeleteAdTemplate(id);
              }}
              duplicateAdTemplate={(id) => {
                setExpandedTemplateId(null);
                navigate(`/library/ad-library/create?id=${id}`);
              }}
            />
          </InfiniteScroll>
        }
      />
    </div>
  );
}
