import { MetaAdAccount } from "src/graphql/generated/schema";
import { MetaAccountItem } from "./MetaAccountItem";
import { useContext } from "react";
import { getIn, useFormikContext } from "formik";
import { WizardContext } from "src/components/Wizard/WizardContext";

interface IMetaAdAccountsList {
  metaAdAccounts: MetaAdAccount[];
}

export function MetaAdAccountsList({ metaAdAccounts }: IMetaAdAccountsList) {
  const { values, setFieldValue } = useFormikContext();
  const selectedAdAccounts = getIn(values, "adAccounts");
  const { activeStepIndex, resetAlert, setResetAlert } =
    useContext(WizardContext);
  const currentStepIndex = 1;
  const isCurrentStepCompleted = activeStepIndex > currentStepIndex;

  const setSelectedAdAccounts = (adAccount: MetaAdAccount) => {
    if (isCurrentStepCompleted) {
      setResetAlert({
        ...resetAlert,
        isShowing: true,
        data: values,
        fieldKey: "adAccounts",
        fieldValue: adAccount,
      });
      return;
    }

    const selectedAdAccounts = getIn(values, "adAccounts");
    const adAccountInValue = selectedAdAccounts.find(
      (v) => v.id === adAccount.id
    );

    if (adAccountInValue) {
      const updateAdAccounts = selectedAdAccounts.map((v) =>
        v.id === adAccount.id
          ? { ...v, enabled: !adAccountInValue?.enabled }
          : v
      );
      setFieldValue("adAccounts", updateAdAccounts);
    } else {
      console.log([...selectedAdAccounts, { ...adAccount, enabled: true }]);
      setFieldValue("adAccounts", [
        ...selectedAdAccounts,
        { ...adAccount, enabled: true },
      ]);
    }
  };

  return (
    <>
      {metaAdAccounts.map((adAccount) => (
        <MetaAccountItem
          onToggle={async () => {
            setSelectedAdAccounts(adAccount);
          }}
          onViewDetails={() => {
            // setShowAdAccountDetails({
            //   isOpen: true,
            //   id: adAccount.id,
            // });
          }}
          amountSpent={adAccount?.amountSpent || 0}
          isAmountExceeded={adAccount?.isAmountSpentExceeded}
          enabled={
            selectedAdAccounts.find((v) => v.id === adAccount.id)?.enabled ||
            false
          }
          status={adAccount.status}
          name={adAccount.metaName}
          imageUrl={adAccount?.Business?.picture}
        />
      ))}
    </>
  );
}

/*
        <MetaDetailItem
          enableFunc={async () => {
            await metaEnableAdAccountFunc({
              variables: {
                metaAdAccountId: v.id,
              },
              refetchQueries: [
                "metaAllAdAccounts",
                "metaPagesForEnabledAdAccounts",
              ],
              awaitRefetchQueries: true,
            });
          }}
          removeFunc={async () => {
            await metaRemoveAdAccountFunc({
              variables: {
                metaAdAccountId: v.id,
              },
              refetchQueries: [
                "metaAllAdAccounts",
                "metaPagesForEnabledAdAccounts",
              ],
              awaitRefetchQueries: true,
            });
          }}
          title={v.metaName}
          description={`Total Spent Amount: ${
            formatNumberByType(v?.amountSpent, "CURRENCY") || 0
          } `}
          isEnabled={v.enabled}
          loading={enableAdAccountLoading || removeAdAccountLoading}
        />
          */
