import classNames from "classnames";
import Avatar from "react-avatar";
import { Asset } from "../../../components/Smartphone/Asset";
import { AssetContentType } from "../../../graphql/generated/schema";
import styles from "./TitleWithThumbnail.module.scss";
import { Text } from "../../../components/Text/Text";
import { Icon, IconProps } from "src/components/Icon/Icon";

interface ITitleWithThumbnail {
  thumbnailIcon?: IconProps;
  thumbnail?: {
    uri: string;
    contentType: AssetContentType;
  };
  name: string;
  descriptionItems: string[];
  width?: "full" | "auto";
  size?: "large" | "medium" | "small";
}

export function TitleWithThumbnail({
  thumbnailIcon,
  thumbnail,
  name,
  descriptionItems,
  width = "full",
  size = "large",
}: ITitleWithThumbnail) {
  return (
    <div
      className={classNames(styles.mediaTitleContainer, {
        [styles.full]: width === "full",
        [styles.small]: size === "small",
        [styles.medium]: size === "medium",
      })}
    >
      {thumbnail ? (
        <Asset
          type={thumbnail.contentType}
          name={name}
          preview={thumbnail.uri}
          className={styles.asset}
        />
      ) : thumbnailIcon ? (
        <Icon {...thumbnailIcon} />
      ) : (
        <Avatar
          name={name}
          maxInitials={2}
          textSizeRatio={3}
          className={styles.asset}
          size={"32px"}
        />
      )}
      <div className={"flex flex-col gap-4"}>
        <Text size="md" weight="medium">
          {name}
        </Text>
        <div className={styles.description}>
          {descriptionItems.map((v) => (
            <Text size="xs" weight={"medium"} tone="subdued">
              {v}
            </Text>
          ))}
        </div>
      </div>
    </div>
  );
}
