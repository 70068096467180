import React from "react";
import styles from "./UnlockFeaturesModal.module.scss";
import { Dialog } from "../Dialog/Dialog";
import { UnlockFeatures } from "../UnlockFeatures/UnlockFeatures";
import paywallModalBg from "../../modules/global/assets/paywallModalBg.png";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { X } from "@untitled-ui/icons-react";

interface IUnlockFeaturesModal {
  type: "UPGRADE_ENGAGE" | "UPGRADE_GROW" | "UNLOCK_ENGAGE" | "UNLOCK_GROW";
  title: string;
  description: string;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export function UnlockFeaturesModal({
  type = "UNLOCK_ENGAGE" as const,
  title,
  description,
  isOpen,
  onOpenChange,
}: IUnlockFeaturesModal) {
  return (
    <Dialog
      trigger={<div></div>}
      maxWidth="750px"
      noPadding
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      <div className="relative flex gap-32 justify-between bg-white overflow-hidden rounded-12">
        <div className="py-20 pl-20">
          <UnlockFeatures
            unlockType={type}
            title={title}
            description={description}
          />
        </div>

        <img
          src={paywallModalBg}
          alt="Subscribe to Engage"
          width={316}
          height={430}
        />

        <div className="absolute top-4 right-4">
          <ButtonV2
            onClick={() => {
              onOpenChange(false);
            }}
            variant="plain"
            leftIcon={X}
            size="small"
          />
        </div>
      </div>
    </Dialog>
  );
}
