import React, { useState } from "react";
import { Label } from "src/components/Label/Label";
import { RadioGroup } from "@headlessui/react";
import styles from "./ActivityGetStarted.module.scss";
import { FormikError } from "src/components/FormikError/FormikError";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { SelectableItemCard } from "src/components/SelectableItemCard/SelectableItemCard";
import { ActivityCampaignSelector } from "./ActivityCampaignSelector";
import { UnlockFeaturesModal } from "src/components/UnlockFeaturesModal/UnlockFeaturesModal";
import { platformOptions } from "../misc/platformOptions";

interface ActivityGetStartedProps {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
  values: any;
  setFieldValue: (name: string, value: any) => void;
  setValues: (values: any) => void;
  errors: any;
  submitCount: number;
  touched: any;
}

export function ActivityGetStarted({
  sectionRef,
  values,
  setFieldValue,
  setValues,
  errors,
  submitCount,
  touched,
}: ActivityGetStartedProps) {
  const [isWhatsappUpgradeOpen, setIsWhatsappUpgradeOpen] = useState(false);
  const [isEmailUpgradeOpen, setIsEmailUpgradeOpen] = useState(false);
  const [isGoogleUpgradeOpen, setIsGoogleUpgradeOpen] = useState(false);

  function handleChange(value: string) {
    if (value === "WHATSAPP") {
      setIsWhatsappUpgradeOpen(true);
      return;
    }
    if (value === "EMAIL") {
      setIsEmailUpgradeOpen(true);
      return;
    }
    if (value === "GOOGLE") {
      setIsGoogleUpgradeOpen(true);
      return;
    }

    setFieldValue("platform", value);
  }

  return (
    <div ref={sectionRef} className={styles.section}>
      <h2 className={styles.heading}>Get Started</h2>
      <h3 className={styles.subheading}>
        Create an activity and choose your marketing platform
      </h3>

      <div className={styles.activityAndCampaignName}>
        <InputFormikV2
          isFixedWidth
          size="large"
          name="activityName"
          label="Activity Name"
          placeholder="Eg: Festival sale campaign, product launch..."
          helpText="Give a name to your marketing activity"
        />
        <ActivityCampaignSelector
          onCreateNew={(value) => {
            setValues({
              ...values,
              campaignName: value,
              shouldCreateCampaign: true,
              campaignId: null,
            });
          }}
          onChange={(value) => {
            setValues({
              ...values,
              campaignId: value,
              campaignName: null,
              shouldCreateCampaign: false,
            });
          }}
          formKey={values.shouldCreateCampaign ? "campaignName" : "campaignId"}
          campaignName={values.campaignName}
          value={values.campaignId}
          defaultValue={
            values.campaignId
              ? {
                  value: values.campaignId,
                  label: values.Campaign?.name,
                }
              : null
          }
        />
      </div>

      <div className={styles.platformInput}>
        <RadioGroup value={values.platform} onChange={handleChange}>
          <Label label="Choose a platform to run your marketing campaign" />
          <div className={styles.platforms}>
            {platformOptions.map((option) => (
              <div key={option.value} className={styles.platformItem}>
                <SelectableItemCard
                  value={option.value}
                  selectorType="radio"
                  icon={option.image}
                  label={option.title}
                />
              </div>
            ))}
          </div>
          <FormikError
            fieldName="platform"
            formikError={errors}
            submitCount={submitCount}
            touched={touched}
          />
        </RadioGroup>
      </div>

      <UnlockFeaturesModal
        isOpen={isWhatsappUpgradeOpen}
        onOpenChange={setIsWhatsappUpgradeOpen}
        type="UNLOCK_ENGAGE"
        title="Unlock Whatsapp in Engage Plan"
        description="Unlock Whatsapp in Engage Plan to send multimedia content to your customers"
      />
      <UnlockFeaturesModal
        isOpen={isEmailUpgradeOpen}
        onOpenChange={setIsEmailUpgradeOpen}
        type="UNLOCK_ENGAGE"
        title="Unlock Email in Engage Plan"
        description="Unlock Email in Engage Plan to send multimedia content to your customers"
      />
      <UnlockFeaturesModal
        isOpen={isGoogleUpgradeOpen}
        onOpenChange={setIsGoogleUpgradeOpen}
        type="UPGRADE_GROW"
        title="Upgrade your plan to unlock Google Ads"
        description="Upgrade your plan to unlock Google Ads to target your ideal audience"
      />
    </div>
  );
}
