export const metaLanguages = [
  {
    label: "English (All)",
    value: 1001,
  },
  {
    label: "Catalan",
    value: 1,
  },
  {
    label: "Czech",
    value: 2,
  },
  {
    label: "Welsh",
    value: 3,
  },
  {
    label: "Danish",
    value: 4,
  },
  {
    label: "German",
    value: 5,
  },
  {
    label: "English (US)",
    value: 6,
  },
  {
    label: "Spanish (Spain)",
    value: 7,
  },
  {
    label: "Finnish",
    value: 8,
  },
  {
    label: "French (France)",
    value: 9,
  },
  {
    label: "Italian",
    value: 10,
  },
  {
    label: "Japanese",
    value: 11,
  },
  {
    label: "Korean",
    value: 12,
  },
  {
    label: "Norwegian (bokmal)",
    value: 13,
  },
  {
    label: "Dutch",
    value: 14,
  },
  {
    label: "Polish",
    value: 15,
  },
  {
    label: "Portuguese (Brazil)",
    value: 16,
  },
  {
    label: "Russian",
    value: 17,
  },
  {
    label: "Swedish",
    value: 18,
  },
  {
    label: "Turkish",
    value: 19,
  },
  {
    label: "Simplified Chinese (China)",
    value: 20,
  },
  {
    label: "Traditional Chinese (Hong Kong)",
    value: 21,
  },
  {
    label: "Traditional Chinese (Taiwan)",
    value: 22,
  },
  {
    label: "Spanish",
    value: 23,
  },
  {
    label: "English (UK)",
    value: 24,
  },
  {
    label: "Indonesian",
    value: 25,
  },
  {
    label: "Filipino",
    value: 26,
  },
  {
    label: "Vietnamese",
    value: 27,
  },
  {
    label: "Arabic",
    value: 28,
  },
  {
    label: "Hebrew",
    value: 29,
  },
  {
    label: "Hungarian",
    value: 30,
  },
  {
    label: "Portuguese (Portugal)",
    value: 31,
  },
  {
    label: "Romanian",
    value: 32,
  },
  {
    label: "Slovak",
    value: 33,
  },
  {
    label: "Slovenian",
    value: 34,
  },
  {
    label: "Thai",
    value: 35,
  },
  {
    label: "Afrikaans",
    value: 36,
  },
  {
    label: "Bulgarian",
    value: 37,
  },
  {
    label: "Croatian",
    value: 38,
  },
  {
    label: "Greek",
    value: 39,
  },
  {
    label: "Lithuanian",
    value: 40,
  },
  {
    label: "Malay",
    value: 41,
  },
  {
    label: "Serbian",
    value: 42,
  },
  {
    label: "Bengali",
    value: 45,
  },
  {
    label: "Hindi",
    value: 46,
  },
  {
    label: "Punjabi",
    value: 47,
  },
  {
    label: "Tamil",
    value: 48,
  },
  {
    label: "Telugu",
    value: 49,
  },
  {
    label: "Malayalam",
    value: 50,
  },
  {
    label: "Azerbaijani",
    value: 53,
  },
  {
    label: "Belarusian",
    value: 54,
  },
  {
    label: "Bosnian",
    value: 55,
  },
  {
    label: "Cebuano",
    value: 56,
  },
  {
    label: "Esperanto",
    value: 57,
  },
  {
    label: "Estonian",
    value: 58,
  },
  {
    label: "Basque",
    value: 59,
  },
  {
    label: "Persian",
    value: 60,
  },
  {
    label: "Faroese",
    value: 62,
  },
  {
    label: "Frisian",
    value: 63,
  },
  {
    label: "Irish",
    value: 64,
  },
  {
    label: "Galician",
    value: 65,
  },
  {
    label: "Guarani",
    value: 66,
  },
  {
    label: "Gujarati",
    value: 67,
  },
  {
    label: "Armenian",
    value: 68,
  },
  {
    label: "Icelandic",
    value: 69,
  },
  {
    label: "Japanese (Kansai)",
    value: 70,
  },
  {
    label: "Javanese",
    value: 71,
  },
  {
    label: "Georgian",
    value: 72,
  },
  {
    label: "Kazakh",
    value: 73,
  },
  {
    label: "Khmer",
    value: 74,
  },
  {
    label: "Kannada",
    value: 75,
  },
  {
    label: "Northern Kurdish (Kurmanji)",
    value: 76,
  },
  {
    label: "Latvian",
    value: 78,
  },
  {
    label: "Macedonian",
    value: 79,
  },
  {
    label: "Mongolian",
    value: 80,
  },
  {
    label: "Marathi",
    value: 81,
  },
  {
    label: "Nepali",
    value: 82,
  },
  {
    label: "Flemish",
    value: 83,
  },
  {
    label: "Norwegian (nynorsk)",
    value: 84,
  },
  {
    label: "Pashto",
    value: 85,
  },
  {
    label: "Sinhala",
    value: 86,
  },
  {
    label: "Albanian",
    value: 87,
  },
  {
    label: "Swahili",
    value: 88,
  },
  {
    label: "Tajik",
    value: 89,
  },
  {
    label: "Urdu",
    value: 90,
  },
  {
    label: "Uzbek",
    value: 91,
  },
  {
    label: "Vietnamese",
    value: 27,
  },
  {
    label: "Spanish (All)",
    value: 1002,
  },
  {
    label: "French (All)",
    value: 1003,
  },
  {
    label: "Chinese (All)",
    value: 1004,
  },
  {
    label: "Portuguese (All)",
    value: 1005,
  },
];
