import classNames from "classnames";
import styles from "./LineGraphV3.module.scss";
import {
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  AreaChart,
  YAxisProps,
} from "recharts";

type StatLabel = {
  color: string;
  name: string;
  dataKey: string;
};

export interface ILineGraphV3 {
  values: {
    value: number;
    xAxisKey: string;
    comparisonValue: number;
    comparisonXAxisKey: string;
  }[];
  statLabel: StatLabel;
  statComparisonLabel?: StatLabel;
  xAxis: {
    tickFormatter?: (i: string, index?: number) => string;
    label?: string;
    dataKey: string;
  };
  tooltip: {
    valueFormatter?: (i: string) => string;
    comparisonValueFormatter?: (i: string) => string;
    xAxisFormatter?: (i: string) => string;
    comparisonXAxisFormatter?: (i: string) => string;
  };
  width: number | string;
  height: number | string;
  yAxis: {
    tickFormatter?: (i: string, index?: number) => string;
    label: string;
    dataKey?: string;
    hide?: boolean;
  };
}

export function LineGraphV3({
  statLabel,
  statComparisonLabel,
  tooltip,
  values,
  xAxis,
  width,
  height,
  yAxis,
}: ILineGraphV3) {
  function CustomTooltip(args) {
    const { active, payload, label } = args;

    if (active && payload && payload.length) {
      const valueItem = values?.find((c) => c.xAxisKey === label);

      const xAxisValue = tooltip.xAxisFormatter
        ? tooltip.xAxisFormatter(label)
        : label;

      const value = tooltip.valueFormatter
        ? tooltip.valueFormatter(payload[0].value)
        : payload[0].value;

      const comparisonValue =
        tooltip.comparisonValueFormatter && payload[1] // if both value and formatter are available send value to formatter
          ? tooltip.comparisonValueFormatter(payload[1].value)
          : payload[1] // if only value is here show the value
          ? payload[1].value
          : null;

      const comparisonXAxisValue =
        tooltip.comparisonXAxisFormatter && valueItem?.comparisonXAxisKey
          ? tooltip.comparisonXAxisFormatter(valueItem?.comparisonXAxisKey)
          : valueItem?.comparisonXAxisKey
          ? valueItem?.comparisonXAxisKey
          : label;

      return (
        <div className={styles.tooltip}>
          <h3 className={styles.tooltipHeading}>{xAxisValue}</h3>
          <div className={styles.tooltipLine}>
            <div className={styles.tooltipLabel}>
              <span className={styles.tooltipPrimaryLegend}></span>
              <div className={styles.tooltipLabelText}>{statLabel.name}</div>
            </div>
            <span className={styles.tooltipValue}>
              {/* remove trailing decimals if zero */}
              {value}
            </span>
          </div>
          {comparisonValue && (
            <>
              <h3 className={styles.tooltipHeading}>{comparisonXAxisValue}</h3>

              <div className={styles.tooltipLine}>
                <div className={styles.tooltipLabel}>
                  <span className={styles.tooltipComparisonLegend}></span>
                  <div className={styles.tooltipLabelText}>
                    {statComparisonLabel.name}
                  </div>
                </div>
                <span className={styles.tooltipValue}>
                  {/* remove trailing decimals if zero */}
                  {comparisonValue}
                </span>
              </div>
            </>
          )}
        </div>
      );
    }

    return null;
  }

  const tickSettings: YAxisProps["tick"] = {
    fill: "rgba(var(--color-subdued-code), 0.6)",
    fontSize: 10,
    fontWeight: 600,
  };

  const mergedValues = values.map((c, index) => ({
    value: c.value,
    [xAxis.dataKey]: c.xAxisKey,
    comparisonValue: c.comparisonValue,
    comparisonXAxisKey: c.comparisonXAxisKey,
  }));

  const data = values.length > 0 ? mergedValues : Array(10).fill({ value: 0 });

  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart
        margin={{ bottom: 10, top: 10, left: -20, right: 0 }}
        data={data}
      >
        <CartesianGrid stroke="var(--color-gray-100)" vertical={false} />
        <XAxis
          stroke="var(--color-neutral-50)"
          tickLine={false}
          tickMargin={12}
          tick={tickSettings}
          dataKey={xAxis.dataKey}
          interval="preserveStartEnd"
          tickFormatter={xAxis.tickFormatter}
        >
          {xAxis.label && (
            <Label value={xAxis.label} position="bottom" offset={0} />
          )}
        </XAxis>
        <YAxis
          axisLine={false}
          stroke="var(--color-gray-100)"
          tickLine={false}
          tickMargin={8}
          tick={tickSettings}
          hide={yAxis.hide}
          tickFormatter={yAxis.tickFormatter}
          label={{
            label: yAxis.label,
            position: "insideLeft",
            angle: -90,
          }}
        />
        {/* only the primary line is has gradient fill */}
        <defs>
          <linearGradient id="gradientPrimary" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={statLabel.color} stopOpacity={0.14} />
            <stop offset="95%" stopColor={statLabel.color} stopOpacity={0} />
          </linearGradient>
        </defs>

        {/* TODO: use a formatter for format the tooltip */}
        {values.length > 0 && <Tooltip content={CustomTooltip} />}

        <Area
          dot={false}
          strokeWidth={1.2}
          type="monotone"
          label={statLabel.name}
          dataKey={"value"}
          stroke={statLabel.color}
          fillOpacity={1}
          fill="url(#gradientPrimary)"
        />
        {statComparisonLabel && (
          <Area
            dot={false}
            strokeWidth={2}
            strokeLinecap="round"
            type="monotone"
            label={statComparisonLabel.name}
            dataKey={"comparisonValue"}
            stroke={statComparisonLabel.color}
            strokeDasharray="4 4"
            fill="transparent"
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
}
