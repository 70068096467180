import React from "react";
import classNames from "classnames";
import styles from "./Chip.module.scss";
import { VerticalSeparator } from "../VerticalSeparator/VerticalSeparator";

interface ChipProps {
  onClick?: () => void;
  leftIcon?: React.FC<React.SVGAttributes<SVGElement>>;
  rightIcon?: React.FC<React.SVGAttributes<SVGElement>>;
  disabled?: boolean;
  title: string;
  prefix?: string;
}

export const Chip: React.FC<ChipProps> = ({
  onClick,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  disabled,
  title,
  prefix,
}) => {
  const chipClassName = classNames(styles.chip, {
    [styles.disabled]: disabled,
    [styles.clickable]: onClick && !disabled,
    [styles.withLeftIcon]: LeftIcon,
    [styles.withRightIcon]: RightIcon,
  });

  return (
    <button
      disabled={disabled}
      type="button"
      className={chipClassName}
      onClick={onClick}
    >
      {LeftIcon && <LeftIcon width={14} height={14} className={styles.icon} />}

      <div className={styles.content}>
        {prefix && (
          <>
            <span className={styles.prefix}>{prefix}</span>
            <VerticalSeparator totalWidth={8} separatorHeight={9} />
          </>
        )}
        <span className={styles.title}>{title}</span>
      </div>

      {RightIcon && (
        <RightIcon width={14} height={14} className={styles.icon} />
      )}
    </button>
  );
};
