import { useEffect, useState } from "react";
import {
  MetaMedia,
  useMetaIntegrationDefaultSettingsQuery,
  useMetaMediaLazyQuery,
} from "src/graphql/generated/schema";
import styles from "./ExistingPostSelector.module.scss";
import { Spinner } from "src/components/Spinner/Spinner";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { ErrorHandler } from "src/components/ErrorHandler";
import { MetaAllPageSelector } from "./MetaAllPageSelector";
import { ExistingPostSelectableItem } from "./ExistingPostSelectableItem";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import Image04 from "@untitled-ui/icons-react/build/esm/Image04";
import FilterLines from "@untitled-ui/icons-react/build/esm/FilterLines";
import InfiniteScroll from "react-infinite-scroll-component";
import { Dialog } from "src/components/Dialog/Dialog";

interface IExistingPostSelector {
  selectedPost: MetaMedia | null;
  setSelectedPost: (i: MetaMedia) => void;
  trigger: any;
  assetType?: "IMAGE_AND_VIDEO" | "VIDEO" | "IMAGE";
  isOpen: boolean;
  onClose: () => void;
}

export function ExistingPostSelector({
  setSelectedPost,
  selectedPost,
  assetType = "IMAGE_AND_VIDEO",
  isOpen,
  onClose,
  trigger,
}: IExistingPostSelector) {
  const [temporarySelectedItem, setTemporarySelectedItem] =
    useState(selectedPost);
  const [selectedPageId, setSelectedPageId] = useState("");
  const { data: metaDefaultSettingsData } =
    useMetaIntegrationDefaultSettingsQuery();
  const [metaMediaFn, { called, data, loading, error, fetchMore }] =
    useMetaMediaLazyQuery();

  useEffect(() => {
    if (metaDefaultSettingsData) {
      const defaultPageId =
        metaDefaultSettingsData.metaIntegrationDefaultSettings?.metaPageId;

      if (defaultPageId) {
        setSelectedPageId(defaultPageId);
      }
    }
  }, [metaDefaultSettingsData, setSelectedPageId]);

  useEffect(() => {
    if (selectedPageId) {
      metaMediaFn({
        variables: {
          limit: 30,
          // cursor: pagination.cursor,
          pageId: selectedPageId,
        },
      });
    }
  }, [metaMediaFn, selectedPageId]);

  if (error) {
    return (
      <Dialog
        maxWidth="1200px"
        open={isOpen}
        onOpenChange={onClose}
        trigger={trigger}
      >
        <Dialog.Header
          icon={Image04}
          title={"Select Existing Post"}
          subtitle={"Select posts from Instagram to promote and run as ads"}
        />
        <ErrorHandler error={error} />
      </Dialog>
    );
  }

  function handleSelect(item: MetaMedia) {
    if (item.type === "VIDEO" && assetType === "IMAGE") {
      return;
    }

    if (item.type === "IMAGE" && assetType === "VIDEO") {
      return;
    }

    if (
      item.copyrightStatus === "ERROR" ||
      item.copyrightStatus === "IN_PROGRESS" ||
      item.copyrightStatus === "NOT_STARTED"
    ) {
      return;
    }

    setTemporarySelectedItem(item);
  }

  function handleSubmit() {
    setSelectedPost(temporarySelectedItem);
    onClose();
  }

  let validTypes = ["IMAGE", "VIDEO", "CAROUSEL_ALBUM"];

  if (assetType === "IMAGE") {
    validTypes = ["IMAGE", "CAROUSEL_ALBUM"];
  }

  if (assetType === "VIDEO") {
    validTypes = ["VIDEO"];
  }

  const posts = data?.metaMedia?.edges.map((media) => media.node) ?? [];

  async function fetchMorePosts() {
    if (!called) return;

    await fetchMore({
      variables: {
        filter: {
          cursor: data.metaMedia.pageInfo.endCursor,
        },
      },

      // concatenate old and new entries
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges = fetchMoreResult?.metaMedia?.edges ?? [];
        const oldEdges = previousResult?.metaMedia?.edges ?? [];

        return {
          metaMedia: {
            ...fetchMoreResult.metaMedia,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  return (
    <Dialog
      maxWidth="1200px"
      open={isOpen}
      onOpenChange={onClose}
      trigger={trigger}
    >
      <Dialog.Header
        icon={Image04}
        title={"Select Existing Post"}
        subtitle={"Select posts from Instagram to promote and run as ads"}
      />
      <div className={styles.container}>
        <div className={styles.tabContainer}>
          {/* <TabsV2 onTabChange={} selectedTab="" tabs={} /> */}

          <MetaAllPageSelector
            label="Select Page"
            value={selectedPageId}
            onChange={(value) => {
              setSelectedPageId(value.id);
            }}
          />

          <ButtonV2 variant="outline" size="small" leftIcon={FilterLines}>
            Filter
          </ButtonV2>
        </div>

        <div className={styles.table}>
          {loading ? (
            <Spinner height={600} />
          ) : (
            <div>
              <InfiniteScroll
                dataLength={posts.length}
                scrollableTarget="scrollTargetForInfiniteScroll"
                next={fetchMorePosts}
                hasMore={data?.metaMedia?.pageInfo?.hasNextPage}
                loader={<Spinner height={200} />}
              >
                {posts.map((post, index) => {
                  const nextPost = posts[index + 1];

                  return (
                    <ExistingPostSelectableItem
                      isNextItemSelected={
                        nextPost?.id === temporarySelectedItem?.id
                      }
                      validTypes={validTypes}
                      // validTypes={["VIDEO"]}
                      post={post}
                      value={temporarySelectedItem}
                      onChange={handleSelect}
                    />
                  );
                })}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>

      <StickyFooter
        actions={
          <>
            <ButtonV2 onClick={onClose} variant="outline">
              Back
            </ButtonV2>
            <ButtonV2 onClick={handleSubmit}>Save and Continue</ButtonV2>
          </>
        }
      />
    </Dialog>
  );
}
