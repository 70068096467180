import styles from "./NotificationSettingItem.module.scss";
import ArrowRight from "@material-symbols/svg-600/rounded/keyboard_arrow_right.svg?react";

export default function NotificationSettingItem({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <div className={styles.item}>
      <div>
        <span className={styles.title}>{title}</span>
        <p className={styles.description}>{description}</p>
      </div>
      <ArrowRight className={styles.ico} />
    </div>
  );
}
