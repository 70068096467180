import { useEffect, useState } from "react";
import { SearchInput } from "src/components/SearchInput/SearchInput";
import {
  MetaGeolocationItem,
  useMetaGeolocationSearchLazyQuery,
} from "src/graphql/generated/schema";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import { formatLocationName } from "../functions/formatLocationName";

interface IGeoLocationSearchInput {
  supportedTypes: string[];
  onSelect: (location: MetaGeolocationItem) => void;
}

export function GeoLocationSearchInput({
  supportedTypes,
  onSelect,
}: IGeoLocationSearchInput) {
  const [searchQuery, setSearchQuery] = useState("");
  const [metaGeolocationSearch, { called, data, loading }] =
    useMetaGeolocationSearchLazyQuery();
  const searchQueryDebounced = useDebouncedVariable(searchQuery);

  useEffect(() => {
    if (searchQueryDebounced) {
      metaGeolocationSearch({
        variables: {
          query: searchQueryDebounced,
        },
      });
    }
  }, [searchQueryDebounced]);

  const searchResults = data
    ? data.metaGeolocationSearch
        .filter((item) => supportedTypes.includes(item.type))
        .map((item) => ({
          label: formatLocationName(item),
          onClick: () => onSelect(item),
        }))
    : [];

  return (
    <div>
      <SearchInput
        value={searchQuery}
        onChange={setSearchQuery}
        loading={loading || !called}
        placeholder="Search for a location"
        searchResults={searchResults}
      />
    </div>
  );
}
