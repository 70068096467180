import classNames from "classnames";
import { WidgetHeader } from "../WidgetHeader/WidgetHeader";

export interface WidgetLayoutProps {
  title: React.ReactNode;
  headerContainerClassName?: string;
  action?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export function WidgetLayout({
  title,
  headerContainerClassName,
  action,
  children,
  footer,
}: WidgetLayoutProps) {
  return (
    <div className="border rounded-8 border-neutral-50">
      <div className={headerContainerClassName ?? "px-20 py-8"}>
        <WidgetHeader title={title} action={action} />
      </div>
      <div className="border-t border-neutral-50">{children}</div>
      {footer && <div className="border-t border-neutral-50">{footer}</div>}
    </div>
  );
}
