import * as Yup from "yup";

export const metaLeadFormSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .max(120, "Name can be a max of 120 characters"),
  prefillQuestionsDescription: Yup.string().required("Description is required"),
  prefillQuestions: Yup.array(
    Yup.string()
      .oneOf([
        "EMAIL",
        "FULL_NAME",
        "PHONE",
        "DOB",
        "GENDER",
        "STATE",
        "COUNTRY",
        "CITY",
        "ZIP_CODE",
        "COMPANY_NAME",
        "JOB_TITLE",
        "WORK_EMAIL",
      ])
      .min(1)
  ),
  customQuestion: Yup.object({
    isEnabled: Yup.bool().required("isEnabled is required"),
    questions: Yup.array(
      Yup.object({
        type: Yup.string()
          .required("Question type is required")
          .oneOf(["YES_OR_NO", "TEXT", "SELECT_ONE"]),
        label: Yup.string().required("Question is required"),
        options: Yup.array(Yup.string().required("Option can't be empty")),
      })
    ),
  }),
  privacyPolicy: Yup.object({
    link: Yup.string().required("Privacy link is required"),
    label: Yup.string(),
  }),
  customDisclaimer: Yup.object({
    isEnabled: Yup.bool().required("isEnabled is required"),
    headline: Yup.string(),
    description: Yup.string(),
  }),
  thankYouMessage: Yup.object({
    headline: Yup.string().required("Thank you headline is required"),
    description: Yup.string().required("Thank you description is required"),
    additionalAction: Yup.object({
      type: Yup.string().oneOf(["VIEW_WEBSITE", "CALL_BUSINESS"]),
      label: Yup.string().required("Button text is required"),
      link: Yup.string(),
      phoneNumber: Yup.string(),
      phoneCountryCode: Yup.string(),
    }),
  }),
});
