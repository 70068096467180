import styles from "./StoriesVariant.module.scss";
import Close from "@material-symbols/svg-600/rounded/close.svg?react";
import ActionLink from "@material-symbols/svg-600/rounded/link.svg?react";
// import Send  from "@material-symbols/svg-600/rounded/send.svg?react";
// import Send  from "@material-symbols/svg-600/rounded/send.svg?react";
// import ExpandLess  from "@material-symbols/svg-600/rounded/expand_less.svg?react";
// import MoreHoriz  from "@material-symbols/svg-600/rounded/more_horiz.svg?react";
import { Asset } from "../Asset";
import classNames from "classnames";
import { Link } from "react-router-dom";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";

export function CatalogueStoryPreview({
  profilePicture,
  accountName,
  preview,
  callToActionLabel,
  primaryText,
}) {
  return (
    <div className={styles.storyVariant}>
      <div className={styles.storyHeader}>
        <ul className={styles.storyItems}></ul>
        <div className={styles.storyProfile}>
          <div className={styles.profile}>
            <img
              className={styles.avatar}
              src={profilePicture}
              // alt={"Profile picture"}
            />
            <div className={styles.userDetails}>
              <div className={styles.username}>{accountName}</div>
              <div className={styles.time}>Sponsored</div>
            </div>
          </div>
          <Close className={styles.storyClose} />
        </div>
      </div>
      <div className={styles.catalogueStoryPreview}>
        {Array.isArray(preview) && preview.length > 0 ? (
          preview.slice(0, 4).map((v: any, idx) => (
            <div key={idx} className={styles.catalogueStoryPreviewCards}>
              {/* In this case story asset and post asset are both the same */}
              <Asset
                className={styles.catalogueStoryPreviewCardsAsset}
                type={v?.PostAsset?.contentType}
                name={v?.PostAsset?.name}
                preview={v?.PostAsset?.uri}
              />
              <div className={classNames(styles.overlay)}>
                <div className={styles.overlayContainer}>
                  <p className={styles.overlayUsername}>{accountName}</p>
                  <p className={styles.overlayLink}>
                    <Link to={"/"}>{v.link}</Link>
                  </p>
                  <span className={styles.overlayAction}>
                    {callToActionLabel
                      ? snakeCaseToTitleCase(callToActionLabel)
                      : "Learn More"}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <Asset
              className={styles.asset}
              type={
                preview.StoryAsset
                  ? preview.StoryAsset.contentType
                  : preview?.PostAsset?.contentType
              }
              preview={
                preview?.StoryAsset
                  ? preview?.StoryAsset?.uri
                  : preview?.PostAsset?.uri
              }
              name={
                preview.StoryAsset
                  ? preview.StoryAsset.name
                  : preview?.PostAsset?.name
              }
            />
            <div className={classNames(styles.overlay)}>
              <div className={styles.overlayContainer}>
                <p className={styles.overlayUsername}>{accountName}</p>
                <p className={styles.overlayLink}>
                  <Link to={"/"}>{preview.link}</Link>
                </p>
                <span className={styles.overlayAction}>
                  {callToActionLabel
                    ? snakeCaseToTitleCase(callToActionLabel)
                    : "Learn More"}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {primaryText && <p className={styles.primaryText}>{primaryText}</p>}
      <div className={styles.storyFooter}>
        {/* <div className={styles.storyFooterSection}>
          <div className={styles.swipeSection}>
            <div className={styles.swipeUp}>
              <ExpandLess className={styles.swipeUpIco} />
            </div>
            <p className={styles.swipeText}>Shop Now</p>
          </div>
          <Send className={styles.storySendIco} />
          <MoreHoriz className={styles.storyMoreIco} />
        </div> */}
        {Array.isArray(preview) && preview.length > 0 ? (
          <span className={styles.learnMore}>
            <ActionLink className={styles.actionIco} />
            {callToActionLabel
              ? snakeCaseToTitleCase(callToActionLabel)
              : "Learn More"}
          </span>
        ) : null}
      </div>
    </div>
  );
}
