import { gql } from "@apollo/client";

const META_AD_ACCOUNTS = gql`
  query metaAllAdAccounts {
    metaAllAdAccounts {
      id
      metaAccountId
      metaName
      enabled
      amountSpent
      status
      isAmountSpentExceeded
      Business {
        id
        name
        picture
      }
    }
  }
`;

export default META_AD_ACCOUNTS;
