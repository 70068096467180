import { SmartphoneShell } from "./SmartphoneShell";
import { InstagramPageWelcomeMessagePreview } from "./InstagramPageWelcomeMessagePreview";
import { WhatsappPageWelcomeMessagePreview } from "./WhatsappPageWelcomeMessagePreview";
import { MetaPageWelcomeMessageInput } from "src/graphql/generated/schema";

export function PageWelcomeMessagePreview({
  platform,
  pageWelcomeMessage,
  userName,
  userAvatar,
}: {
  userName: string;
  platform: "INSTAGRAM" | "WHATSAPP";
  pageWelcomeMessage: MetaPageWelcomeMessageInput;
  userAvatar: string;
}) {
  return (
    <div>
      <SmartphoneShell>
        {platform === "INSTAGRAM" ? (
          <InstagramPageWelcomeMessagePreview
            userName={userName}
            pageWelcomeMessage={pageWelcomeMessage}
            userAvatar={userAvatar}
          />
        ) : (
          <WhatsappPageWelcomeMessagePreview
            userName={userName}
            pageWelcomeMessage={pageWelcomeMessage}
            userAvatar={userAvatar}
          />
        )}
      </SmartphoneShell>
    </div>
  );
}
