import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { WizardContext } from "../../../components/Wizard/WizardContext";
import { useContext } from "react";
import { StepWizardStep } from "../../global/components/StepWizardStep";
import { LegacySectionHeading } from "../../global/components/LegacySectionHeading";
import styles from "./AdditionalSetting.module.scss";
import * as RadioGroup from "@radix-ui/react-radio-group";
import CalendarCheck02 from "@untitled-ui/icons-react/build/esm/CalendarCheck02";
import FileDownload03 from "@untitled-ui/icons-react/build/esm/FileDownload03";
import DatePicker from "../../../components/Datepicker/Datepicker";
import { DateRange } from "react-day-picker";
import { AddRecipientModal } from "./AddRecipientModal";
import { ScheduledReportFields } from "./ScheduledReportFields";
import * as Yup from "yup";
import { ButtonV2 } from "../../../components/ButtonV2/ButtonV2";

const validationSchema = Yup.object().shape({
  reportFrequency: Yup.string().required("Report Frequency is required"),
  deliveryAt: Yup.string().when("reportFrequency", {
    is: "RECURRING",
    then: Yup.string().required("Delivery time is required"),
  }),
  duration: Yup.string().when("reportFrequency", {
    is: "RECURRING",
    then: Yup.string().required("Duration is required"),
  }),
  startedAt: Yup.date().when("reportFrequency", {
    is: "ONE_TIME",
    then: Yup.date().required("Start date is required"),
  }),
  endedAt: Yup.date().when("reportFrequency", {
    is: "ONE_TIME",
    then: Yup.date().required("End date is required"),
  }),
  recipientEmails: Yup.array(Yup.string().email("Invalid Email Address")).max(
    10,
    "Maximum 10 emails",
  ),
});

export function AdditionalSetting({
  currentStepIndex,
}: {
  currentStepIndex: number;
}) {
  const { activeStepIndex, data, markStepAsDone } = useContext(WizardContext);
  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={async function (
        values: FormikValues,
        formikHelpers: FormikHelpers<FormikValues>,
      ) {
        console.log({ values });
        await markStepAsDone(currentStepIndex + 1, values);
      }}
    >
      {({ values, setFieldValue, setValues, handleSubmit, isSubmitting }) => (
        <Form>
          <StepWizardStep
            isCompleted={activeStepIndex > currentStepIndex}
            isStepNotActive={activeStepIndex !== currentStepIndex}
            isLastStep
          >
            <div className={styles.container}>
              <div>
                <LegacySectionHeading
                  heading={"Additional setting"}
                  description={
                    "Here are the details about your report, you’ll get to know more about your report"
                  }
                />
              </div>
              <div>
                <ReportFrequencySelector
                  selectedReportFrequency={values.reportFrequency}
                  setSelectedReportFrequency={(
                    reportFrequency: "ONE_TIME" | "RECURRING",
                  ) => {
                    setFieldValue("reportFrequency", reportFrequency);
                  }}
                />
              </div>
              {values.reportFrequency === "RECURRING" && (
                <ScheduledReportFields
                  values={values}
                  setFieldValue={setFieldValue}
                />
              )}
              {values.reportFrequency === "ONE_TIME" && (
                <div className={styles.contentSection}>
                  <div className={styles.contentHeader}>Timeframe</div>
                  <div>
                    <DatePicker
                      label="Select start and end date"
                      onChange={function (i: DateRange): void {
                        setValues({
                          ...values,
                          startedAt: i.from,
                          endedAt: i.to,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className={styles.contentSection}>
                <div className={styles.contentHeader}>
                  Share this report to other people?
                </div>
                <div className={styles.contentDescription}>
                  You'll automatically receive the report via your shared email
                  and the default email linked to your Macro account
                </div>
                {/*
                <div className={styles.additionalEmailsContainer}>
                  {values.recipientEmails?.map((email, i) => (
                    <BadgeV2
                      label={email}
                      color="gray"
                      key={i}
                      size="small"
                      title={email}
                    />
                  ))}
                </div>
                 */}
                <AddRecipientModal />
              </div>

              <div className={styles.actionContainer}>
                <ButtonV2 variant="outline">Cancel</ButtonV2>
                <ButtonV2 loading={isSubmitting} onClick={handleSubmit}>
                  Save Report
                </ButtonV2>
              </div>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}

function ReportFrequencySelector({
  selectedReportFrequency,
  setSelectedReportFrequency,
}) {
  const radioGroupOptions = [
    {
      value: "RECURRING",
      icon: CalendarCheck02,
      title: "Stay updated with scheduled reports",
      description:
        "Choose how often you'd like to receive reports, and we’ll deliver them straight to your inbox or phone on your schedule.",
    },
    {
      value: "ONE_TIME",
      icon: FileDownload03,
      title: "Get this report in Real-Time",
      description:
        "Get your report instantly by downloading it with your selected metrics—no hassle, just the insights you need.",
    },
  ];
  return (
    <RadioGroup.Root
      className={styles.radioGroup}
      value={selectedReportFrequency}
      onValueChange={(value) => {
        setSelectedReportFrequency(value);
      }}
    >
      {radioGroupOptions.map((v) => (
        <ReportFrequencySelectorCard
          Icon={v.icon}
          title={v.title}
          description={v.description}
          value={v.value}
        />
      ))}
    </RadioGroup.Root>
  );
}

function ReportFrequencySelectorCard({ Icon, title, description, value }) {
  return (
    <RadioGroup.Item
      value={value}
      className={styles.reportFrequencySelectorCard}
    >
      <div className={styles.reportFrequencySelectorHeader}>
        <div className={styles.reportFrequencySelectorCardIcon}>
          <Icon width={20} height={20} />
        </div>
        <div>
          <RadioGroup.Indicator className={styles.indicator} />
        </div>
      </div>
      <div className={styles.reportFrequencySelectorCardContent}>
        <div className={styles.reportFrequencySelectorCardTitle}>{title}</div>
        <div className={styles.reportFrequencySelectorCardDescription}>
          {description}
        </div>
      </div>
    </RadioGroup.Item>
  );
}
