import * as Toast from "@radix-ui/react-toast";
import { createContext, useContext, useState } from "react";

const ToastContext = createContext({
  addToast: (message: string) => {},
  removeToast: (id: string) => {},
});

export const ToasterNew = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = (message, type = "info") => {
    const id = Math.random().toString(36).substr(2, 9); // Generate unique ID
    setToasts((prevToasts) => [...prevToasts, { id, message, type }]);
    console.log(toasts);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <Toast.Provider swipeDirection="down">
        {children}
        {toasts.map((toast) => (
          <Toast.Root
            className="ToastRoot"
            key={toast.id}
            onOpenChange={(open) => !open && removeToast(toast.id)}
          >
            {toast.message}
          </Toast.Root>
        ))}
        <Toast.Viewport className="ToastViewport" />
      </Toast.Provider>
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
