import { Announcement01 } from "@untitled-ui/icons-react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Icon } from "src/components/Icon/Icon";
import { IconWithWrapper } from "src/components/IconWithWrapper/IconWithWrapper";
import { Text } from "src/components/Text/Text";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import Tick from "src/icons/check.svg?react";
import { CreateClientModalProps } from "../misc/CreateClientModalProps";
import { ClientCreateWizardInput } from "../misc/ClientCreateWizardInput";

export function CreateClientModal3({
  initialValues,
  onBack,
  onSubmit,
}: CreateClientModalProps<ClientCreateWizardInput>) {
  const features = [
    "Assist in generating reports and insights",
    "Audience recommendation",
    "Generate descriptions and captions",
  ];

  return (
    <div className="h-full grid grid-rows-[1fr_auto] gap-40">
      <div className="flex justify-end flex-col gap-16">
        <div className="flex flex-col gap-8">
          <IconWithWrapper
            icon={Announcement01}
            isBordered
            iconColor="default"
            size="lg"
          />
          <div className="flex flex-col gap-4">
            <Text as={"h2"} font="redhat" size="lg" weight="medium">
              Brand Persona Builder
            </Text>
            <Text as="p" size="xs" tone="subdued" weight="medium">
              Sign up has been completed and now you're ready for onboarding.
              Macro is a SaaS product that provides data-driven growth
              solutions, optimizing business operations, analytics etc
            </Text>
          </div>
        </div>

        <div className="flex flex-col gap-8">
          <Text as="p" size="xs" weight="medium" tone="subdued">
            What can our AI do for you?
          </Text>
          <ul>
            {features.map((feature) => (
              <li className="flex gap-6 items-center">
                <Icon iconColor="subdued" icon={Tick} />
                <Text as="p" size="xs" tone="neutral-600" weight="medium">
                  {feature}
                </Text>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex gap-8 items-center">
        <ButtonV2 onClick={() => onSubmit(initialValues)} size="small">
          Continue
          <VerticalSeparator
            color="white"
            separatorHeight={14}
            totalWidth={10}
          />
          1/4
        </ButtonV2>
        <ButtonV2 onClick={onBack} size="small" variant="outline">
          Skip for now
        </ButtonV2>
      </div>
    </div>
  );
}
