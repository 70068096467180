import { Text } from "../Text/Text";
import styles from "./LineAroundHeading.module.scss";

export function LineAroundHeading({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.wrapper}>
      <hr className={styles.leftLine} />
      <span className={styles.content}>
        <Text size="2xs" weight="medium" tone="neutral-400">
          {children}
        </Text>
      </span>
      <hr className={styles.rightLine} />
    </div>
  );
}
