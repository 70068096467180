import styles from "./MetaIntegrationDetailsEmptyState.module.scss";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import FileSearch01 from "@untitled-ui/icons-react/build/esm/FileSearch01";

export function MetaIntegrationDetailsEmptyState({ link }: { link: string }) {
  return (
    <div className={styles.emptyState}>
      <div className={styles.iconContainer}>
        <FileSearch01 height={16} width={16} className={styles.icon} />
      </div>
      <p className={styles.emptyText}>
        Verify Facebook account is active and connected to avoid 'Account not
        found' errors.
      </p>
      <ButtonV2 to={link} variant="link">
        Learn More
      </ButtonV2>
    </div>
  );
}
