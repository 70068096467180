import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import styles from "./MetaLeadPrivacyPolicyFields.module.scss";
import ShieldTick from "@untitled-ui/icons-react/build/esm/ShieldTick";
import { FormGroupHeaderWithSwitch } from "./FormGroupHeaderWithSwitch";
import Link01 from "@untitled-ui/icons-react/build/esm/Link01";

export function MetaLeadPrivacyPolicyFields() {
  return (
    <div className={styles.section}>
      <FormGroupHeaderWithSwitch
        icon={ShieldTick}
        title="Privacy policy"
        description="Because you will be collecting customer information, you need to include a link to your company's privacy policy. Your link will appear with Facebook's default privacy disclaimer."
        hasSwitch={false}
      />

      <div className={styles.fields}>
        <InputFormikV2
          icon={Link01}
          helpText="Shown after the form data is entered"
          placeholder="Business privacy policy URL."
          name="privacyPolicy.link"
          label="Privacy policy link"
        />
        <InputFormikV2
          helpText="Shown after the form data is entered"
          placeholder="Add link text for your policy url"
          label="Link text (Optional)"
          name="privacyPolicy.label"
        />
      </div>
    </div>
  );
}
