import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  useBillingInvoicesQuery,
  useGetUserQuery,
  useMetaIntegrationDefaultSettingsQuery,
} from "src/graphql/generated/schema";
import AuthContext from "src/stores/AuthContext";
import { ErrorHandler } from "../ErrorHandler";
import Layout from "../Layout/Layout";
import { BillingAndUsageBanner } from "./BillingAndUsageBanner";
import { BillingOverlay } from "src/modules/global/components/BillingOverlay";
import { ShopifyBillingOverlay } from "../../modules/global/components/ShopifyBillingOverlay";
import { useDeviceType } from "src/modules/global/hooks/useDeviceType";
import { ScreenNotSupported } from "src/modules/global/components/ScreenNotSupported";
import { IntegrationErrorBanner } from "src/modules/global/components/IntegrationErrorBanner";

interface IAppLayout {
  layoutType?:
    | "centeredWithSidebar"
    | "centeredNoSidebar"
    | "fullWidthWithSidebar"
    | "fullWidthNoSidebar";
}

const engagePlanRequiredPages = ["automations", "templates"];

const growPlanRequiredPage = ["audiences"];

export function AppLayout({ layoutType = "centeredWithSidebar" }: IAppLayout) {
  const deviceType = useDeviceType();

  const { isAuthenticated, user, setUser, billing } = useContext(AuthContext);
  const [isBillingOverlayActive, setIsBillingOverlayActive] = useState(false);
  const [isShopifyBillingOverlayActive, setShopifyBillingOverlayActive] =
    useState(false);
  const location = window.location;
  const navigate = useNavigate();
  const { data: getUserData, loading, error } = useGetUserQuery();
  const {
    data: openInvoiceData,
    loading: openInvoiceLoading,
    error: openInvoiceDataError,
  } = useBillingInvoicesQuery({
    variables: {
      status: "OPEN",
    },
  });

  useEffect(() => {
    if (getUserData && getUserData.me) {
      const user = getUserData.me;

      if (!user.Organization) {
        navigate("/onboarding/pricing");
        return;
      }

      setUser(getUserData.me);

      // if billing is empty then it means the billing is not finished
      if (!billing) {
        navigate("/onboarding/pricing");
        return;
      }

      if (billing) {
        const billingStatus = billing.status;

        const engagePlan = billing?.BillingEngagePlan;
        const growPlan = billing?.BillingGrowPlan;
        const primaryPath = location.pathname
          .split("/")
          .filter((v) => v.length > 0)[0];

        switch (billingStatus) {
          case "CANCELED":
            navigate("/onboarding/pricing");
            break;
          case "INCOMPLETE":
            navigate("/onboarding/pricing");
            break;
          case "INCOMPLETE_EXPIRED":
            navigate("/onboarding/pricing");
            break;
          case "PAST_DUE":
            break;
          case "UNPAID":
            if (billing.paymentProvider === "STRIPE") {
              setIsBillingOverlayActive(true);
            } else {
              setShopifyBillingOverlayActive(true);
            }
            break;
          case "TRIALING":
            break;
          case "ACTIVE":
            break;
        }

        if (
          openInvoiceData &&
          openInvoiceData.billingInvoices &&
          openInvoiceData.billingInvoices.length > 1
        ) {
          setIsBillingOverlayActive(true);
          return;
        }

        if (!engagePlan && engagePlanRequiredPages.includes(primaryPath)) {
          navigate(`/${primaryPath}/paywall`);
          return;
        }

        if (!growPlan && growPlanRequiredPage.includes(primaryPath)) {
          navigate(`/${primaryPath}/paywall`);
          return;
        }
      }

      if (user.Clients.length === 0) {
        navigate("/onboarding/success");
      }
    }
  }, [
    user,
    getUserData,
    isAuthenticated,
    navigate,
    setUser,
    billing,
    openInvoiceData,
  ]);

  if (loading) {
    return;
  }

  if (deviceType !== "DESKTOP") {
    return <ScreenNotSupported />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (isBillingOverlayActive) {
    return <BillingOverlay />;
  }

  if (isShopifyBillingOverlayActive) {
    return <ShopifyBillingOverlay />;
  }

  const hasSidebar = layoutType.includes("WithSidebar");

  return (
    <>
      <IntegrationErrorBanner hasSidebar={hasSidebar} />
      {billing && (
        <BillingAndUsageBanner
          showSidebar={hasSidebar}
          billing={billing}
          openInvoices={openInvoiceData ? openInvoiceData.billingInvoices : []}
        />
      )}
      <Layout layoutType={layoutType} user={user}>
        <Outlet />
      </Layout>
    </>
  );
}
