import { useMemo } from "react";
import { generateRangeString } from "../../../lib/generateRangeString";
import { IAudienceAudienceSetIndexNode } from "../misc/audienceListingTypes";
import { getAudienceAudienceSetSourcePlatform } from "../misc/getAudienceAudienceSetSourcePlatforms";
import { formatNumberByType } from "../../global/functions/formatNumberByType";
import { useGrowAggregatedStats } from "../../global/functions/useGrowAggregatedStats";
import { AudienceCategoryBadges } from "../../global/components/AudienceCategoryBadges";
import { useNavigate } from "react-router-dom";
import { DropdownV2 } from "src/modules/campaign/components/DropdownV2";
import {
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import format from "date-fns/format";
import { DotsVertical } from "@untitled-ui/icons-react";
import { getAudienceIconList } from "src/modules/global/functions/getAudienceIconList";
import {
  useAudienceDeleteMutation,
  useMetaAudienceSetArchiveMutation,
  useMetaAudienceSetDeleteMutation,
} from "src/graphql/generated/schema";
import toast from "react-hot-toast";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { IListingMappingValue } from "src/modules/global/misc/IListingMappingValue";

type DateFilters = {
  dateFrom: Date | null;
  dateTo: Date | null;
};

interface IAudienceItem {
  item: IAudienceAudienceSetIndexNode;
  dateFilters: DateFilters;
  selectedAnalytics: IListingMappingValue;
}

export function AudienceItem({
  item,
  selectedAnalytics,
  dateFilters: { dateTo, dateFrom },
}: IAudienceItem) {
  const [deleteMetaAudienceSet] = useMetaAudienceSetDeleteMutation();
  const [archiveMetaAudienceSet] = useMetaAudienceSetArchiveMutation();
  const [deleteAudience] = useAudienceDeleteMutation();
  const audiencePlatforms = getAudienceAudienceSetSourcePlatform(item);
  const metaElement = item.Audience ? item.Audience : item.MetaAudienceSet;
  const categories =
    metaElement.__typename === "Audience" ? [] : metaElement.AudienceCategories;
  const insights = metaElement.MetaInsights;
  const navigate = useNavigate();
  const formattedDate = useMemo(
    () => format(new Date(item.createdAt), "dd MMM, yyyy"),
    [item],
  );

  const link =
    metaElement.__typename === "Audience"
      ? `/audiences/${metaElement.id}`
      : `/audience-sets/${metaElement.id}`;

  return (
    <TableRow id={item.id} link={link}>
      {/* TITLE SECTION */}
      <TablePrimaryCell
        link={link}
        title={item.name}
        icon={getAudienceIconList({
          audiencePlatforms,
          categories,
        })}
        subtitle={formattedDate}
      />

      {/* TAGS */}
      <TableCell width={328}>
        <AudienceCategoryBadges categories={categories} />
      </TableCell>

      <TableCell width={128}>
        {generateRangeString(
          insights?.lowerBoundCount,
          insights?.upperBoundCount,
        )}
      </TableCell>

      <AudienceAudienceSetStats
        selectedAnalytics={selectedAnalytics}
        id={metaElement.id}
        typename={metaElement.__typename}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />

      <TableCell width={48}>
        <DropdownV2
          icon={DotsVertical}
          buttonVariant="plain"
          size="small"
          hideArrow
          options={[
            ...(metaElement.__typename === "MetaAudienceSet"
              ? [
                  {
                    label: "Archive Audience",
                    disabled: metaElement.activeActivitiesCount > 0,
                    async onClick() {
                      try {
                        const { data } = await archiveMetaAudienceSet({
                          variables: {
                            id: metaElement.id,
                          },
                          refetchQueries: ["audienceAudienceSetIndexes"],
                        });
                        if (data?.metaAudienceSetArchive?.userError) {
                          throw new Error(
                            data.metaAudienceSetArchive.userError.message,
                          );
                        }
                      } catch (err) {
                        toast.error(err.message);
                      }
                    },
                  },
                ]
              : []),
            {
              label: "Delete Audience",
              disabled: metaElement.activitiesCount > 0,
              async onClick() {
                try {
                  if (metaElement.__typename === "Audience") {
                    await deleteAudience({
                      variables: {
                        id: metaElement.id,
                      },
                      refetchQueries: ["audienceAudienceSetIndexes"],
                    });
                  } else {
                    await deleteMetaAudienceSet({
                      variables: {
                        id: metaElement.id,
                      },
                      refetchQueries: ["audienceAudienceSetIndexes"],
                    });
                  }
                } catch (err) {
                  toast.error(err.message);
                }
              },
            },
            {
              label: "Duplicate Audience",
              onClick() {
                navigate(`/audiences/duplicate?audienceId=${metaElement.id}`);
              },
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
}

function AudienceAudienceSetStats({
  id,
  typename,
  dateFrom,
  selectedAnalytics,
  dateTo,
}: {
  id: string;
  typename: "Audience" | "MetaAudienceSet";
  dateFrom: Date | null;
  dateTo: Date | null;
  selectedAnalytics: IListingMappingValue;
}) {
  const { loading, data } = useGrowAggregatedStats({
    others: {
      ...(typename === "Audience" && {
        audienceId: id,
      }),
      ...(typename === "MetaAudienceSet" && {
        audienceSetId: id,
      }),
      insightType: "ADSET_INSIGHT",
    },
    dateTo,
    dateFrom,
  });

  const [firstValueMapping, secondValueMapping] = selectedAnalytics.values;

  const firstValue = loading ? (
    <Skeleton width={40} height={20} />
  ) : data?.[firstValueMapping.valueKey] ? (
    formatNumberByType(
      data[firstValueMapping.valueKey],
      firstValueMapping.valueType,
      {
        showDecimal: true,
      },
    )
  ) : (
    "-"
  );

  const secondValue = loading ? (
    <Skeleton width={40} height={20} />
  ) : data?.[secondValueMapping.valueKey] ? (
    formatNumberByType(
      data[secondValueMapping.valueKey],
      secondValueMapping.valueType,
      {
        showDecimal: true,
      },
    )
  ) : (
    "-"
  );

  return (
    <>
      <TableCell width={120}>{firstValue}</TableCell>
      <TableCell width={100}>{secondValue}</TableCell>
    </>
  );
}
