import { Label } from "src/components/Label/Label";
import { MetaUser, useMetaUserQuery } from "src/graphql/generated/schema";
import { TitleWithThumbnail } from "src/modules/global/components/TitleWithThumbnail";
import styles from "./MetaIntegrationSidePanel.module.scss";
import UserCheck01 from "@untitled-ui/icons-react/build/esm/UserCheck01";
import BarLineChart from "@untitled-ui/icons-react/build/esm/BarLineChart";
import ShoppingBag03 from "@untitled-ui/icons-react/build/esm/ShoppingBag03";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import BookClosed from "@untitled-ui/icons-react/build/esm/BookClosed";
import File02 from "@untitled-ui/icons-react/build/esm/File02";

export function MetaIntegrationSidePanel() {
  const { data: metaUserData } = useMetaUserQuery();
  return (
    <div className={"border-l border-[--color-border] px-6 h-fit"}>
      <div className={"text-sm font-medium pb-5"}>About this integration</div>
      <div className="flex flex-col gap-8">
        {metaUserData && (
          <>
            <MetaIntegrationConnectedUser user={metaUserData.metaUser} />
            <FeatureUnlocked />
          </>
        )}
        <HelpAndSupport />
      </div>
    </div>
  );
}

function MetaIntegrationConnectedUser({ user }: { user: MetaUser }) {
  return (
    <div className={styles.valueWithLabel}>
      <Label label={"Connected user"} />
      <TitleWithThumbnail
        thumbnail={{
          contentType: "IMAGE",
          uri: user.picture,
        }}
        name={user.name}
        size="medium"
        descriptionItems={[]}
      />
    </div>
  );
}

function FeatureUnlocked() {
  const featureList = [
    {
      label: "Manage contacts and segment",
      icon: <UserCheck01 />,
    },
    {
      label: "Metrics & Analytics",
      icon: <BarLineChart />,
    },
    {
      label: "Product catalogue",
      icon: <ShoppingBag03 />,
    },
  ];
  return (
    <div className={styles.valueWithLabel}>
      <Label label={"Feature unlocked"} />
      <div className={styles.featureList}>
        {featureList.map((v) => (
          <div className={styles.itemWithIcon}>
            {v.icon}
            <span>{v.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function HelpAndSupport() {
  const itemList = [
    {
      label: "Documentation",
      icon: BookClosed,
      link: "#",
    },
    {
      label: "Terms of service",
      icon: File02,
      link: "#",
    },
  ];
  return (
    <div className={styles.valueWithLabel}>
      <Label label={"Help & support"} />
      <div className={styles.featureList}>
        {itemList.map((v) => (
          <div>
            <ButtonV2
              variant="link"
              leftIcon={v.icon}
              children={v.label}
              to={v.link}
              target="_blank"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
