import styles from "./CreativeCreateForm.module.scss";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { SelectAdPlatforms } from "./SelectAdPlatforms";
import { CreativeGlobalFields } from "./CreativeGlobalFields";
import { Switch } from "src/modules/global/components/Switch";
import Tooltip from "src/components/Tooltip/Tooltip";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";
import { useFormikContext } from "formik";
import { CreativeCreateTypesV2 } from "../misc/creativeCreateTypesV2";
import { CreativeMetaFields } from "./CreativeMetaFields";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import Globe01 from "@untitled-ui/icons-react/build/esm/Globe01";
import MetaOutline from "../../../icons/meta.svg?react";
import WhatsappOutline from "../../../icons/whatsapp.svg?react";
import { useEffect } from "react";
import { MetaPlacementOptionValue } from "../misc/metaPlacementOptions";

const allPlatformTabOptions = [
  {
    label: "Meta",
    value: "META",
    icon: MetaOutline,
  },
  {
    label: "Whatsapp",
    value: "WHATSAPP",
    icon: WhatsappOutline,
  },
];

interface ICreativeGlobalFields {
  isCustomizeByPlatformEnabled: boolean;
  setIsCustomizeByPlatformEnabled: (i: boolean) => void;
  selectedPlatformTab: string;
  setSelectedPlatformTab: (i: string) => void;
  setActiveAssetIndex: (i: number) => void;
  activeAssetIndex: number;
  metaSelectedPlacement: MetaPlacementOptionValue;
  setMetaSelectedPlacement: (i: MetaPlacementOptionValue) => void;
}

export function CreativeCreateForm({
  isCustomizeByPlatformEnabled,
  setIsCustomizeByPlatformEnabled,
  selectedPlatformTab,
  setSelectedPlatformTab,
  activeAssetIndex,
  setActiveAssetIndex,
  metaSelectedPlacement,
  setMetaSelectedPlacement,
}: ICreativeGlobalFields) {
  const { values, isSubmitting } = useFormikContext<CreativeCreateTypesV2>();
  let tabOptions = [];

  if (values.platforms.length) {
    const selectedPlatforms = values.platforms.map((pl) =>
      allPlatformTabOptions.find((ap) => ap.value === pl),
    );

    tabOptions = [
      {
        label: "Global",
        value: "GLOBAL",
        icon: Globe01,
      },
      ...selectedPlatforms,
    ];
  }

  useEffect(() => {
    // if only on platform is selected
    // select that tab
    if (values.platforms.length === 1) {
      const platform = values.platforms[0];

      if (selectedPlatformTab !== platform) {
        return setSelectedPlatformTab(platform);
      }
    }

    // if the current selected platform is removed
    // select global platform
    // or if customize by platform is disabled
    // reselect the global option
    if (
      selectedPlatformTab !== "GLOBAL" ||
      isCustomizeByPlatformEnabled === false
    ) {
      const isExists = values.platforms.includes(selectedPlatformTab as any);

      if (!isExists) {
        return setSelectedPlatformTab("GLOBAL");
      }
    }
  }, [
    values.platforms,
    setSelectedPlatformTab,
    selectedPlatformTab,
    isCustomizeByPlatformEnabled,
  ]);

  return (
    <div className={styles.formWrapper}>
      <InputFormikV2
        autoFocus
        placeholder="Eg. Sale offer ad template, discount template"
        label="Creative Template Name"
        name="name"
      />

      <SelectAdPlatforms />

      {values.platforms.length > 1 && (
        <div className={styles.customizePlatformWrapper}>
          <div className={styles.customizePlatform}>
            <span className={styles.customizePlatformTitle}>
              Customize by platform{" "}
              <Tooltip content="Choose different creatives to based on the platform">
                <HelpCircle
                  color="var(--color-subdued)"
                  width={14}
                  height={14}
                />
              </Tooltip>
            </span>
            <Switch
              checked={isCustomizeByPlatformEnabled}
              onChange={() => {
                setIsCustomizeByPlatformEnabled(!isCustomizeByPlatformEnabled);
              }}
            />
          </div>
          {isCustomizeByPlatformEnabled && (
            <TabsV2
              onTabChange={setSelectedPlatformTab}
              selectedTab={selectedPlatformTab}
              tabs={tabOptions}
            />
          )}
        </div>
      )}

      {selectedPlatformTab === "META" && (
        <CreativeMetaFields
          metaSelectedPlacement={metaSelectedPlacement}
          setMetaSelectedPlacement={setMetaSelectedPlacement}
          activeAssetIndex={activeAssetIndex}
          setActiveAssetIndex={setActiveAssetIndex}
        />
      )}
      {selectedPlatformTab === "GLOBAL" && (
        <CreativeGlobalFields
          activeAssetIndex={activeAssetIndex}
          setActiveAssetIndex={setActiveAssetIndex}
        />
      )}

      <div className={styles.buttonGroup}>
        <ButtonV2 variant="outline">Cancel</ButtonV2>
        <ButtonV2 loading={isSubmitting} type="submit">
          Save template
        </ButtonV2>
      </div>
    </div>
  );
}
