import { Billing, GetUserQuery } from "src/graphql/generated/schema";
import { ActiveClient } from "src/lib/types";
import { createContext } from "react";

interface ILogin {
  email: string;
  password: string;
}

interface ISignup {
  firstName?: string;
  lastName?: string;
  organizationName: string;
  email: string;
  password: string;
  inviteCode?: string;
}

export interface AuthContextProps {
  isAuthenticated: boolean;
  user: GetUserQuery["me"];
  userToken: string | null;
  activeClient: ActiveClient | null;
  billing: Billing | null;
  /** @param location: where you want to redirect after client is set. default is /dashboard */
  setActiveClient: (activeClient: ActiveClient, location?: string) => void;
  setUser: (i: GetUserQuery["me"]) => void;
  login: (i: ILogin) => Promise<void>;
  logout: () => void;
  signup: (i: ISignup) => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export default AuthContext;
