import styles from "./FacebookFeedAdPreview.module.scss";
import FacebookPlusIcon from "../../../icons/facebook_plus.svg?react";
import FacebookSearchIcon from "../../../icons/facebook_search.svg?react";
import FacebookMessengerIcon from "../../../icons/facebook_messenger.svg?react";
import { FacebookShareIcon } from "src/modules/campaign-wizard/components/FacebookShareIcon";
import { FacebookCommentIcon } from "src/modules/campaign-wizard/components/FacebookCommentIcon";
import { FacebookLikeIcon } from "src/modules/campaign-wizard/components/FacebookLikeIcon";
import FacebookWorld from "../../../icons/facebook_world.png";
import FacebookLikeColored from "../../../icons/facebook_like_colored.png";
import HorizontalDots from "@untitled-ui/icons-react/build/esm/DotsHorizontal";
import facebook from "../../../icons/facebook_logo.png";
import { PhoneNotificationBar } from "./PhoneNotificationBar";
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle";
import { useKeenSlider } from "keen-slider/react";
import X from "@untitled-ui/icons-react/build/esm/X";
import { titleCase } from "src/lib/titleCase";
import { IAdPreview } from "../misc/creativePreviewTypes";
import { LineItem } from "src/lib/types";
import classNames from "classnames";
import { extractDomainFromUrl } from "../functions/extractDomainFromUrl";
import { useEffect } from "react";

export function FacebookFeedAdPreview({
  lineItems,
  caption,
  ctaLabel,
  userName: username,
  activeAssetIndex,
  setActiveAssetIndex,
  userAvatar,
}: IAdPreview) {
  const [sliderRef, sliderInstance] = useKeenSlider({
    initial: activeAssetIndex,
    slides: {
      perView: 1.6,
      spacing: 8,
    },
    slideChanged(change) {
      setActiveAssetIndex(change.track.details.abs);
    },
  });

  useEffect(() => {
    if (
      sliderInstance.current &&
      // if not already on the active asset index,
      sliderInstance.current.track.details.abs !== activeAssetIndex
    ) {
      // move to the active asset index
      sliderInstance.current.moveToIdx(activeAssetIndex);
    }
  }, [activeAssetIndex, sliderInstance]);

  return (
    <div className={styles.wrapper}>
      <PhoneNotificationBar />
      <div className={styles.previewScreen}>
        <div className={styles.appHeader}>
          <div className={styles.appHeaderLeft}>
            <img
              className={styles.facebookLogo}
              src={facebook}
              alt="facebook logo"
              width={59.26}
              height={33.8}
            />
          </div>
          <div className={styles.appHeaderRight}>
            <span className={styles.appHeaderIcon}>
              <FacebookPlusIcon />
            </span>
            <span className={styles.appHeaderIcon}>
              <FacebookSearchIcon />
            </span>
            <span className={styles.appHeaderIcon}>
              <FacebookMessengerIcon />
            </span>
          </div>
        </div>

        {/* <div className={styles.appMainNavigation}>
          <div
            className={classNames(
              styles.appNavigationItem,
              styles.activeAppNavigationItem
            )}
          >
            <FacebookHomeIcon />
          </div>
          <div className={styles.appNavigationItem}>
            <FacebookFriendsIcon />
          </div>
          <div className={styles.appNavigationItem}>
            <FacebookVideoIcon />
          </div>
          <div className={styles.appNavigationItem}>
            <img
              src={FacebookBellIcon}
              role="none"
              alt="bell"
              width={16}
              height={16}
            />
          </div>
          <div className={styles.appNavigationItem}>
            <Hamburger width={16} height={16} />
          </div>
        </div> */}

        <div className={styles.postHeader}>
          <div className={styles.userInfo}>
            <img
              src={userAvatar}
              alt="user avatar"
              width={18}
              height={18}
              className={styles.userAvatar}
            />
            <div className={styles.aboutPoster}>
              <span className={styles.userName}>{username}</span>
              <span className={styles.sponsored}>
                <span className={styles.sponsoredText}>Sponsored</span>
                <span className={styles.worldIcon}>
                  <img
                    src={FacebookWorld}
                    width={7.5}
                    height={7.5}
                    role="none"
                    alt="facebook world icon"
                  />
                </span>
              </span>
            </div>
          </div>
          <div className={styles.postHeaderActions}>
            <HorizontalDots width={14} height={14} />
            <X width={14} height={14} />
          </div>
        </div>

        <div className={styles.primaryText}>{caption}</div>

        <div className={styles.photos}>
          <FacebookAsset
            ctaLabel={ctaLabel}
            keenSliderRef={sliderRef}
            lineItems={lineItems}
          />
        </div>

        <div className={styles.engagementDetails}>
          <div className={styles.likeEngagement}>
            <img
              src={FacebookLikeColored}
              width={12}
              height={12}
              alt="like icon"
            />
            <span className={styles.likeCount}>223</span>
          </div>
          <div className={styles.shareAndCommentEngagement}>
            <span className={styles.commentCount}>12 Comments</span>
            <span className={styles.shareCount}>6 Shares</span>
          </div>
        </div>

        <div className={styles.postActions}>
          <div className={styles.postActionItem}>
            <FacebookLikeIcon
              fill="rgba(97, 103, 111, 1)"
              width={12}
              height={12}
            />
            <span className={styles.postActionLabel}>Like</span>
          </div>
          <div className={styles.postActionItem}>
            <FacebookCommentIcon
              fill="rgba(97, 103, 111, 1)"
              width={12}
              height={12}
            />
            <span className={styles.postActionLabel}>Comment</span>
          </div>
          <div className={styles.postActionItem}>
            <FacebookShareIcon
              stroke="rgba(97, 103, 111, 1)"
              strokeWidth={4}
              width={12}
              height={12}
            />
            <span className={styles.postActionLabel}>Share</span>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IFacebookAsset {
  lineItems: LineItem[];
  ctaLabel: string;
  keenSliderRef: (node: HTMLElement) => void;
}

function FacebookAsset({ lineItems, ctaLabel, keenSliderRef }: IFacebookAsset) {
  const assets = lineItems.map((lineItem) => lineItem.PostAsset);
  const domains = lineItems.map((lineItem) =>
    lineItem.link ? extractDomainFromUrl(lineItem.link) : ""
  );
  const adTitles = lineItems.map((lineItem) => lineItem.headline);
  const adDescription = lineItems.map((lineItem) => lineItem.description);

  if (assets.length === 0) {
    return (
      <div className={styles.post}>
        <div className={styles.emptyImageSection}>
          <AlertCircle color="var(--color-disabled)" width={14} height={14} />
          <span className={styles.emptyImageSectionText}>
            Select Ad to see the preview
          </span>
        </div>

        <div className={styles.postMeta}>
          <div className={styles.postMoreContent}>
            <span className={styles.siteName}>{domains[0] ?? ""}</span>
            <span className={styles.postTitle}>{adTitles[0] ?? "-"}</span>
            <span className={styles.postDescription}>
              {adDescription[0] ?? ""}
            </span>
          </div>
          <button type="button" className={styles.postCta}>
            {titleCase(ctaLabel, "_")}
          </button>
        </div>
      </div>
    );
  }

  if (assets.length > 1) {
    return (
      <div className={styles.postSlider}>
        <div className="keen-slider" ref={keenSliderRef}>
          {assets.map((asset, index) => (
            <div
              className={classNames("keen-slider__slide", styles.slideItem)}
              key={index}
            >
              {asset.contentType === "IMAGE" ? (
                <img
                  src={asset.uri}
                  alt="Post"
                  className={classNames(styles.slideItemImage)}
                />
              ) : (
                <video
                  src={asset.uri}
                  className={classNames(styles.slideItemImage)}
                  autoPlay
                  muted
                >
                  <source src={asset.uri} />
                </video>
              )}

              <div className={styles.postMeta}>
                <div className={styles.postMoreContent}>
                  <span className={styles.siteName}>
                    {domains[index] ?? ""}
                  </span>
                  <span className={styles.postTitle}>
                    {adTitles[index] ?? "-"}
                  </span>
                  <span className={styles.postDescription}>
                    {adDescription[index] ?? ""}
                  </span>
                </div>
                <button type="button" className={styles.postCta}>
                  {titleCase(ctaLabel, "_")}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (assets[0].contentType === "IMAGE") {
    return (
      <div className={styles.post}>
        <img src={assets[0].uri} alt="Post" className={styles.postImage} />
        <div className={styles.postMeta}>
          <div className={styles.postMoreContent}>
            <span className={styles.siteName}>{domains[0] ?? ""}</span>
            <span className={styles.postTitle}>{adTitles[0] ?? "-"}</span>
            <span className={styles.postDescription}>
              {adDescription[0] ?? ""}
            </span>
          </div>
          <button type="button" className={styles.postCta}>
            {titleCase(ctaLabel, "_")}
          </button>
        </div>
      </div>
    );
  }

  if (assets[0].contentType === "VIDEO") {
    return (
      <div className={styles.post}>
        <video src={assets[0].uri} className={styles.postImage} autoPlay muted>
          <source src={assets[0].uri} />
        </video>
        <div className={styles.postMeta}>
          <div className={styles.postMoreContent}>
            <span className={styles.siteName}>{domains[0] ?? ""}</span>
            <span className={styles.postTitle}>{adTitles[0] ?? "-"}</span>
            <span className={styles.postDescription}>
              {adDescription[0] ?? ""}
            </span>
          </div>
          <button type="button" className={styles.postCta}>
            {titleCase(ctaLabel, "_")}
          </button>
        </div>
      </div>
    );
  }

  return null;
}
