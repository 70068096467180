import { titleCase } from "src/lib/titleCase";
import { metaLanguages } from "../misc/metaLanguages";
import { Text } from "src/components/Text/Text";
import { User01 } from "@untitled-ui/icons-react";
import { Icon } from "src/components/Icon/Icon";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import Users01 from "@untitled-ui/icons-react/build/esm/Users01";
import { AudienceSetQuery } from "src/graphql/generated/schema";

export function AudienceDemographicsSection({
  audienceSetData,
}: {
  audienceSetData: AudienceSetQuery["audienceSet"];
}) {
  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-40 px-20 py-14 border-b">
        <AudienceDemographicField
          title="Age Group"
          icon={Users01}
          value={
            <>
              {audienceSetData.minAge} - {audienceSetData.maxAge}
            </>
          }
        />
        <AudienceDemographicField
          title="Gender"
          icon={User01}
          value={titleCase(audienceSetData.gender)}
        />
      </div>
      <div className="flex gap-40 px-20 py-14 border-b">
        <AudienceDemographicField
          title="Location"
          value={
            <div className="flex gap-4 items-center">
              {audienceSetData.Locations.length > 0
                ? audienceSetData.Locations.map((v) => (
                    <BadgeV2 label={titleCase(v.name)} />
                  ))
                : "-"}
            </div>
          }
        />
      </div>
      <div className="flex gap-40 px-20 py-14">
        <AudienceDemographicField
          title="Languages"
          value={
            <div className="flex gap-4 items-center">
              {audienceSetData.languages.length > 0
                ? audienceSetData.languages.map((id) => {
                    const language = metaLanguages.find(
                      (ml) => ml.value === Number(id),
                    )?.label;
                    return <BadgeV2 label={titleCase(language)} />;
                  })
                : "-"}
            </div>
          }
        />
      </div>
    </div>
  );
}

function AudienceDemographicField({
  title,
  value,
  icon,
}: {
  title: string;
  value: string | React.ReactNode;
  icon?: React.FC<React.SVGAttributes<SVGElement>>;
}) {
  return (
    <div className="flex flex-col gap-8">
      <Text weight="medium" size="2xs" tone="subdued">
        {title}
      </Text>
      <div className="flex gap-4">
        {icon && <Icon icon={icon} />}
        <Text size="sm" tone="neutral-600">
          {value}
        </Text>
      </div>
    </div>
  );
}
