import {
  CampaignsQuery,
  useCampaignDeleteMutation,
  useCampaignStatusUpdateMutation,
} from "src/graphql/generated/schema";
import {
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import format from "date-fns/format";
import { DotsVertical } from "@untitled-ui/icons-react";
import { useCampaignAggregatedStats } from "src/modules/global/functions/useCampaignAggregatedStats";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { DropdownV2 } from "./DropdownV2";
import { PlatformIconList } from "src/modules/campaign/components/PlatformIconList";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";
import { toast } from "sonner";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { IListingMappingValue } from "src/modules/global/misc/IListingMappingValue";
import React from "react";
import MetaIcon from "../../global/assets/meta.svg?react";
import MetaIconBW from "../../global/assets/meta_bw.svg?react";
import GoogleAdsIcon from "../../global/assets/Google-Ads.svg?react";
import GoogleAdsIconBW from "../../global/assets/google-ads_bw.svg?react";
import WhatsappIcon from "../../global/assets/whatsapp.svg?react";
import WhatsappIconBW from "../../global/assets/whatsapp_bw.svg?react";
import EmailIcon from "../../global/assets/email.svg?react";
import EmailIconBW from "../../global/assets/email_bw.svg?react";

interface ICampaignItem {
  campaign: CampaignsQuery["campaigns"]["edges"][0]["node"];
  campaignAnalytics: IListingMappingValue;
}

export function CampaignItem({ campaign, campaignAnalytics }: ICampaignItem) {
  const [campaignDeleteFunc, { loading: campaignDeleteLoading }] =
    useCampaignDeleteMutation();
  const [campaignStatusUpdate, { loading: campaignStatusUpdateLoading }] =
    useCampaignStatusUpdateMutation();
  const link = `/campaigns/${campaign.id}`;

  const hasMetaActivities = !!campaign.Activities.find(
    (activity) => activity.platform === "META",
  );

  const hasGoogleAdsActivities = !!campaign.Activities.find(
    (activity) => activity.platform === "GOOGLE",
  );

  const hasWhatsappOutreaches = !!campaign.Outreaches.find(
    (outreach) => outreach.outreachType === "WHATSAPP",
  );

  const hasEmailOutreaches = false;

  let platforms: {
    icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    coloredIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    isActive: boolean;
  }[] = [
    {
      icon: MetaIconBW,
      coloredIcon: MetaIcon,
      isActive: hasMetaActivities,
    },
    {
      icon: GoogleAdsIconBW,
      coloredIcon: GoogleAdsIcon,
      isActive: hasGoogleAdsActivities,
    },
    {
      icon: WhatsappIconBW,
      coloredIcon: WhatsappIcon,
      isActive: hasWhatsappOutreaches,
    },
    {
      icon: EmailIconBW,
      coloredIcon: EmailIcon,
      isActive: hasEmailOutreaches,
    },
  ];

  if (campaignDeleteLoading || campaignStatusUpdateLoading) {
    return (
      <TableRow link={link} id={campaign.id}>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton width={"100%"} height={20} />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow link={link} id={campaign.id}>
      <TablePrimaryCell
        link={link}
        title={campaign.name}
        subtitle={format(new Date(campaign.createdAt), "dd MMM yyyy")}
      />

      <CampaignStats
        campaignAnalytics={campaignAnalytics}
        campaignId={campaign.id}
      />
      <TableCell width="172px">
        <PlatformIconList platforms={platforms} />
      </TableCell>
      <TableCell width="48px">
        <DropdownV2
          options={[
            {
              label: "Archive Campaign",
              async onClick() {
                try {
                  await campaignStatusUpdate({
                    variables: {
                      input: {
                        id: campaign.id,
                        status: "ARCHIVED",
                      },
                    },
                    refetchQueries: ["campaigns"],
                    awaitRefetchQueries: true,
                  });
                } catch (err) {
                  console.log(err);
                  toast.error(err.message);
                }
              },
            },
            {
              label: "Delete Campaign",
              async onClick() {
                try {
                  await campaignDeleteFunc({
                    variables: {
                      id: campaign.id,
                    },
                    refetchQueries: ["campaigns"],
                    awaitRefetchQueries: true,
                  });
                  toast.success("Campaign deleted successfully");
                } catch (e) {
                  toast.error(e.message);
                }
              },
            },
          ]}
          icon={DotsVertical}
          buttonVariant="plain"
          size="small"
          hideArrow
        />
      </TableCell>
    </TableRow>
  );
}

function CampaignStats({
  campaignId,
  campaignAnalytics,
}: {
  campaignId: string;
  campaignAnalytics: IListingMappingValue;
}) {
  const { loading, data } = useCampaignAggregatedStats(campaignId, {});
  const selectedCurrencyCode = useGetActiveCurrencySymbol();

  const valueMappings = campaignAnalytics.values;
  const [firstValueMapping, secondValueMapping, thirdValueMapping] =
    valueMappings;

  const firstValue = loading ? (
    <Skeleton width={40} height={20} />
  ) : data?.[firstValueMapping.valueKey] ? (
    formatNumberByType(
      data[firstValueMapping.valueKey],
      firstValueMapping.valueType,
    )
  ) : (
    "-"
  );

  const secondValue = loading ? (
    <Skeleton width={40} height={20} />
  ) : data?.[secondValueMapping.valueKey] ? (
    formatNumberByType(
      data[secondValueMapping.valueKey],
      firstValueMapping.valueType,
    )
  ) : (
    "-"
  );

  const thirdValue = loading ? (
    <Skeleton width={40} height={20} />
  ) : data?.[thirdValueMapping.valueKey] ? (
    formatNumberByType(
      data[thirdValueMapping.valueKey],
      thirdValueMapping.valueType,
      {
        showDecimal: false,
        selectedCurrencyCode,
      },
    )
  ) : (
    "-"
  );

  return (
    <>
      <TableCell width="120px">{firstValue}</TableCell>
      <TableCell width="120px">{secondValue}</TableCell>
      <TableCell width="120px">{thirdValue}</TableCell>
    </>
  );
}
