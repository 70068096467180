export function extractDomainFromUrl(url: string) {
  const domain = new URL(url).hostname;

  if (domain) {
    // take only the last two parts of the domain
    return domain.split(".").slice(-2).join(".");
  }

  return "";
}
