import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDown } from "@untitled-ui/icons-react";
import classNames from "classnames";
import styles from "./AccordionItem.module.scss";
import { Icon } from "src/components/Icon/Icon";

export interface IAccordionItem {
  trigger: React.ReactNode;
  value: string;
  children: React.ReactNode;
  triggerClassName?: string;
  contentClassName?: string;
  className?: string;
}

export function AccordionItem({
  trigger,
  value,
  children,
  contentClassName,
  triggerClassName,
  className,
}: IAccordionItem) {
  return (
    <Accordion.Item
      className={classNames(styles.accordionItem, className)}
      value={value}
    >
      <Accordion.Trigger
        className={classNames(
          "flex justify-between items-center w-full px-14 py-12",
          triggerClassName,
        )}
      >
        <div className="w-full">{trigger}</div>
        <div className={styles.chevronContainer}>
          <Icon icon={ChevronDown} />
        </div>
      </Accordion.Trigger>
      <Accordion.Content className={classNames(contentClassName, "py-6 px-14")}>
        {children}
      </Accordion.Content>
    </Accordion.Item>
  );
}
