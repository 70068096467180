import { MutableRefObject, useContext } from "react";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { AudienceSelectorWizardForm } from "./AudienceSelectorWizardForm";
import { SegmentSelectorWizardForm } from "./SegmentSelectorWizardForm";

export function AudienceOrSegmentSelectorWizardForm({
  sectionRef,
}: {
  sectionRef: MutableRefObject<HTMLDivElement>;
}) {
  const { data } = useContext(WizardContext);

  if (data.platform === "WHATSAPP") {
    return <SegmentSelectorWizardForm sectionRef={sectionRef} />;
  }

  return <AudienceSelectorWizardForm sectionRef={sectionRef} />;
}
