import styles from "./MetaIntegrationSingle.module.scss";
import MetaIntegrate from "../assets/metaIntegrateFooter.png";
import {
  MetaAdAccount,
  MetaCatalogue,
  MetaInstagramAccount,
  MetaPage,
  MetaPixel,
  useMetaEnableCatalogueMutation,
  useMetaEnabledAdAccountsQuery,
  useMetaEnableInstagramAccountMutation,
  useMetaEnablePageMutation,
  useMetaEnablePixelMutation,
  useMetaIntegrationDefaultSettingsUpsertMutation,
  useMetaIntegrationDefaultSettingsQuery,
  useMetaRemoveCatalogueMutation,
  useMetaRemoveInstagramAccountMutation,
  useMetaRemovePageMutation,
  useMetaRemovePixelMutation,
  MetaPagesFromPlatformDocument,
  MetaEnabledAdAccountsDocument,
  MetaIntegrationDefaultSettingsDocument,
  MetaInstagramAccountsFromPlatformDocument,
  MetaPixelsFromPlatformDocument,
  MetaCataloguesFromPlatformDocument,
} from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { MetaAdAccountSingleCardAccordion } from "./MetaAdAccountSingleAccordion";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { runToggleFunc } from "../functions/runToggleFuncForMultipleItems";
import { useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import toast from "react-hot-toast";
import { MetaAdAccountDefaultSettingsSelectorModal } from "./MetaAdAccountDefaultSettingsSelectorModal";
import { MetaAdAccountDefaultSettingsForm } from "../misc/MetaIntegrationFormTypes";

export function MetaIntegrationSingle() {
  const {
    data: metaEnabledAdAccountsData,
    loading: metaEnabledAdaccountsLoading,
  } = useMetaEnabledAdAccountsQuery({
    variables: {
      filter: {
        hasEnabledMetaPages: true,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: metaIntegrationDefaultSettings,
    loading: metaIntegrationDefaultSettingsLoading,
  } = useMetaIntegrationDefaultSettingsQuery();
  const [selectedAdAccountId, setSelectedAdAccountId] = useState<string | null>(
    null,
  );
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [metaIntegrationDefaultSettingsUpsertFunc] =
    useMetaIntegrationDefaultSettingsUpsertMutation();
  const [enablePixelFunc] = useMetaEnablePixelMutation();
  const [enablePageFunc] = useMetaEnablePageMutation();
  const [enableInstagramAccountFunc] = useMetaEnableInstagramAccountMutation();
  const [enableCatalogueFunc] = useMetaEnableCatalogueMutation();
  const [removePixelFunc] = useMetaRemovePixelMutation();
  const [removePageFunc] = useMetaRemovePageMutation();
  const [removeInstagramAccountFunc] = useMetaRemoveInstagramAccountMutation();
  const [removeCatalogueFunc] = useMetaRemoveCatalogueMutation();

  if (metaEnabledAdaccountsLoading || metaIntegrationDefaultSettingsLoading) {
    return <Spinner height="screen" />;
  }

  const metaAdAccounts = metaEnabledAdAccountsData
    ? metaEnabledAdAccountsData.metaEnabledAdAccounts
    : [];

  const defaultSettings =
    metaIntegrationDefaultSettings.metaIntegrationDefaultSettings;

  function generateInitialValues(
    selectedAdAccountId: string,
    metaAdAccounts: MetaAdAccount[],
  ) {
    const selectedAdAccount = metaAdAccounts.find(
      (v) => v.id === selectedAdAccountId,
    );

    const initialValues: MetaAdAccountDefaultSettingsForm = {
      pixelId: selectedAdAccount?.MetaPixels?.[0]?.id || "",
      instagramAccountId:
        selectedAdAccount?.MetaInstagramAccounts?.[0]?.id || "",
      pageId: selectedAdAccount?.MetaPages?.[0]?.id || "",
      adAccountId: selectedAdAccountId,
    };

    return initialValues;
  }

  async function defaultSettingsUpsertFunc(
    values: MetaAdAccountDefaultSettingsForm,
  ) {
    await metaIntegrationDefaultSettingsUpsertFunc({
      variables: {
        input: {
          adAccountId: values.adAccountId,
          instagramAccountId: values.instagramAccountId,
          pageId: values.pageId,
          pixelId: values.pixelId,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: "active",
    });
    toast.success("Updated default settings successfully");
    setSelectedAdAccountId(null);
    return;
  }

  async function metaPageToggle(
    metaPage: Pick<MetaPage, "id" | "metaPageId" | "enabled" | "isDefault">,
    adAccountId: string,
  ) {
    const refetchQueries = [
      {
        query: MetaEnabledAdAccountsDocument,
        variables: { filter: { hasEnabledMetaPages: true } },
      },
      {
        query: MetaPagesFromPlatformDocument,
        variables: { adAccountId },
      },
      {
        query: MetaIntegrationDefaultSettingsDocument,
      },
    ];
    await runToggleFunc(
      { ...metaPage, enabled: !metaPage.enabled },
      (item) =>
        enablePageFunc({
          variables: {
            input: { adAccountId, metaPageId: item.metaPageId },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        }),
      (item) =>
        removePageFunc({
          variables: {
            input: {
              adAccountId,
              metaPageId: item.metaPageId,
            },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        }),
    );
  }

  async function metaInstagramAccountToggle(
    metaInstagramAccount: Pick<
      MetaInstagramAccount,
      "id" | "metaInstagramId" | "enabled" | "isDefault"
    >,
    adAccountId: string,
  ) {
    const refetchQueries = [
      {
        query: MetaEnabledAdAccountsDocument,
        variables: { filter: { hasEnabledMetaPages: true } },
      },
      {
        query: MetaInstagramAccountsFromPlatformDocument,
        variables: { adAccountId },
      },
      {
        query: MetaIntegrationDefaultSettingsDocument,
      },
    ];
    await runToggleFunc(
      { ...metaInstagramAccount, enabled: !metaInstagramAccount.enabled },
      (item) =>
        enableInstagramAccountFunc({
          variables: {
            input: { adAccountId, metaInstagramId: item.metaInstagramId },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        }),
      (item) =>
        removeInstagramAccountFunc({
          variables: {
            input: {
              adAccountId,
              metaInstagramId: item.metaInstagramId,
            },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        }),
    );
  }

  async function metaCatalogueToggle(
    metaCatalogue: Pick<
      MetaCatalogue,
      "id" | "metaCatalogueId" | "enabled" | "isDefault"
    >,
    adAccountId: string,
  ) {
    const refetchQueries = [
      {
        query: MetaEnabledAdAccountsDocument,
        variables: { filter: { hasEnabledMetaPages: true } },
      },
      {
        query: MetaCataloguesFromPlatformDocument,
        variables: { adAccountId },
      },
      {
        query: MetaIntegrationDefaultSettingsDocument,
      },
    ];
    await runToggleFunc(
      { ...metaCatalogue, enabled: !metaCatalogue.enabled },
      (item) =>
        enableCatalogueFunc({
          variables: {
            input: { adAccountId, metaCatalogueId: item.metaCatalogueId },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        }),
      (item) =>
        removeCatalogueFunc({
          variables: {
            input: {
              adAccountId,
              metaCatalogueId: item.metaCatalogueId,
            },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        }),
    );
  }

  async function metaPixelToggle(
    metaPixel: Pick<MetaPixel, "id" | "metaPixelId" | "enabled" | "isDefault">,
    adAccountId: string,
  ) {
    const refetchQueries = [
      {
        query: MetaEnabledAdAccountsDocument,
        variables: { filter: { hasEnabledMetaPages: true } },
      },
      {
        query: MetaPixelsFromPlatformDocument,
        variables: { adAccountId },
      },
      {
        query: MetaIntegrationDefaultSettingsDocument,
      },
    ];
    await runToggleFunc(
      { ...metaPixel, enabled: !metaPixel.enabled },
      (item) =>
        enablePixelFunc({
          variables: {
            input: { adAccountId, metaPixelId: item.metaPixelId },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        }),
      (item) =>
        removePixelFunc({
          variables: {
            input: { adAccountId, metaPixelId: item.metaPixelId },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        }),
    );
  }

  return (
    <div>
      <div className={styles.content}>
        <LegacySectionHeading
          heading={"Connected accounts"}
          description="Manage your meta business connected accounts"
        />

        <div className={styles.wrapper}>
          {metaAdAccounts.map((v, adAccountIndex) => (
            <MetaAdAccountSingleCardAccordion
              adAccountIndex={adAccountIndex}
              metaAdAccount={v}
              selectedInstagramAccounts={v.MetaInstagramAccounts}
              selectedMetaPages={v.MetaPages}
              selectedMetaCatalogues={v.MetaCatalogues}
              selectedMetaPixels={v.MetaPixels}
              defaultSettings={defaultSettings as any}
              defaultSettingsUpsertFunc={defaultSettingsUpsertFunc}
              setSelectedInstagramAccounts={(metaInstagramAccount: any) =>
                metaInstagramAccountToggle(
                  metaInstagramAccount,
                  v.metaAccountId,
                )
              }
              setSelectedMetaPages={async (
                metaPage: any,
                totalItems: number,
              ) => {
                if (totalItems === 0) return;
                if (metaPage.isDefault) return;

                await metaPageToggle(metaPage, v.metaAccountId);
              }}
              setSelectedMetaCatalogues={(metaCatalogue: any) =>
                metaCatalogueToggle(metaCatalogue, v.metaAccountId)
              }
              setSelectedMetaPixels={(metaPixel: any) =>
                metaPixelToggle(metaPixel, v.metaAccountId)
              }
              openByDefault={v.isDefault}
              setShowDefaultSettingsModal={(id) => {
                setSelectedAdAccountId(id);
                setShowSettingsModal(true);
              }}
            />
          ))}
          {/* <Tabs
            tabs={metaIntegrationTabs}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
          />
          <div className={styles.wrapper}>
            {selectedTab === "AD_ACCOUNT" ? (
              <MetaAdAccountsList
                // setShowAdAccountDetails={setShowAccountDetailsSlider}
                metaAdAccounts={[]}
              />
            ) : (
              <MetaIntegrationDefaultSettingForm
                metaDefaultSettings={data.metaIntegrationDefaultSettings}
              />
            )}
          </div> */}
        </div>
        <div className={styles.learnContainer}>
          <div className={styles.learnMoreText}>
            <h3 className={styles.spendsHeading}>
              Learn how your spends affects your bills?
            </h3>
            <p className={styles.infoDescription}>
              Your monthly bill is calculated by the combined ad spend of active
              ad accounts if your ad spend exceeds the limit of your plan, we
              put your account in hold that means, you can see your existing
              campaigns or ads but you will be not able to launch new campaigns
              unless you clear your dues.
            </p>
            <p className={styles.boldText}>
              Here's how we calculate your bills.
            </p>
            <p className={styles.infoDescription}>
              If Account A and B spends 110K & 120K respectively but you choose
              plan up to 150K ad spends limit, you will see additional charge in
              your next bill.
            </p>
          </div>
          <img
            src={MetaIntegrate}
            alt="meta integrate"
            className={styles.footerImage}
          />
        </div>
      </div>

      {selectedAdAccountId && (
        <Formik
          enableReinitialize
          initialValues={generateInitialValues(
            selectedAdAccountId,
            metaAdAccounts,
          )}
          onSubmit={defaultSettingsUpsertFunc}
        >
          <Form>
            <DefaultSettingSelectorModalLoader
              enabledAdAccounts={metaAdAccounts}
              selectedAdAccountId={selectedAdAccountId}
              showSettingsModal={showSettingsModal}
              setShowSettingsModal={setShowSettingsModal}
            />
          </Form>
        </Formik>
      )}
    </div>
  );
}

function DefaultSettingSelectorModalLoader({
  enabledAdAccounts,
  selectedAdAccountId,
  showSettingsModal,
  setShowSettingsModal,
}: {
  enabledAdAccounts: MetaAdAccount[];
  selectedAdAccountId: string;
  showSettingsModal: boolean;
  setShowSettingsModal: (i: boolean) => void;
}) {
  const { handleSubmit } = useFormikContext();
  const enabledAdAccount = enabledAdAccounts.find(
    (v) => v.id === selectedAdAccountId,
  );
  return (
    <MetaAdAccountDefaultSettingsSelectorModal
      isOpen={showSettingsModal}
      onClose={() => setShowSettingsModal(false)}
      handleSubmit={() => handleSubmit()}
      // selectedAdAccountId={selectedAdAccountId}
      metaInstagramAccounts={enabledAdAccount?.MetaInstagramAccounts || []}
      metaPages={enabledAdAccount?.MetaPages || []}
      metaPixels={enabledAdAccount?.MetaPixels || []}
    />
  );
}
