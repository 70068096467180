import { MetaActivityLeadGenFormTemplateInput } from "src/graphql/generated/schema";
import { MetaLeadCreateTypes } from "./metaLeadCreateTypes";

export function generateLeadGenTemplate(
  input: MetaLeadCreateTypes,
): MetaActivityLeadGenFormTemplateInput {
  return {
    name: input.name,
    prefilledQuestions: input.prefillQuestions,
    prefilledQuestionsDescription: input.prefillQuestionsDescription,
    privacyPolicyLink: input.privacyPolicy.link,
    privacyPolicyText: input.privacyPolicy.label,
    ...(!!input.customQuestion.isEnabled && {
      Questions: input.customQuestion.questions.map((q) => ({
        label: q.label,
        options: q.options,
        type: q.type,
      })),
    }),
    ...(!!input.customDisclaimer.isEnabled && {
      CustomDisclaimer: {
        title: input.customDisclaimer.headline,
        text: input.customDisclaimer.description,
      },
    }),
    ThankYouPage: {
      websiteUrl: input.thankYouMessage.additionalAction.link,
      buttonText: input.thankYouMessage.additionalAction.label,
      businessPhoneNumberCountryCode:
        input.thankYouMessage.additionalAction.phoneCountryCode,
      businessPhoneNumber: input.thankYouMessage.additionalAction.phoneNumber,
      body: input.thankYouMessage.description,
      title: input.thankYouMessage.headline,
      buttonType: input.thankYouMessage.additionalAction.type,
    },
  };
}
