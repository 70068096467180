import { Activity, useCampaignQuery } from "src/graphql/generated/schema";
import { useParams } from "react-router-dom";
import { ErrorHandler } from "src/components/ErrorHandler";
import styles from "./CampaignSinglePage.module.scss";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { useState } from "react";
import Today from "@material-symbols/svg-600/rounded/today.svg?react";
import Error from "@material-symbols/svg-600/rounded/error.svg?react";
import Banner from "src/components/Banner/Banner";
import { CampaignActivityCard } from "../components/CampaignActivityCard";
import { CampaignSingleOverview } from "../components/CampaignSingleOverview";
import { getTinybirdDateFiltersFromDateFilter } from "../misc/campaignSingleFilters";
import Header from "src/components/Header/Header";
import { DateRange } from "react-day-picker";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

const defaultDateFilter = "THIS_YEAR";

export function CampaignSinglePage() {
  const { id } = useParams<"id">();
  const { dateFrom, dateTo } =
    getTinybirdDateFiltersFromDateFilter(defaultDateFilter);
  const [selectedDate, setSelectedDate] = useState<DateRange>({
    from: dateFrom,
    to: dateTo,
  });
  const { data, loading, error } = useCampaignQuery({
    variables: {
      campaignId: id,
    },
  });

  // const [campaignPresetDuplicate] = useCampaignPresetDuplicateMutation();
  // const navigate = useNavigate();

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const campaign = data.campaign;
  const hasScheduled = campaign.Activities.some((item) => item.scheduledAt);
  const bannerCopy = campaign.Activities.every((item) => item.scheduledAt)
    ? "This campaign is scheduled to be published"
    : "Some of your activities are still in schedule which implies these metrics may be change later";

  return (
    <div className={styles.page}>
      <Header
        title={campaign.name}
        showBack
        showNotifications={false}
        actions={
          <>
            <ButtonV2
              size="small"
              children="Add New Acitvity"
              to="/campaigns/new"
            />
          </>
        }
      />
      {campaign.status.includes("ERROR") && (
        <Banner
          type="error"
          icon={<Error height={17} width={17} />}
          content={
            "There are some critical issues in your activities, please check and fix them"
          }
          action={
            <a
              href={"#campaign_activities"}
              className={styles.viewActivityError}
            >
              View Activity
            </a>
          }
          className={styles.bannerWrapper}
        />
      )}
      {hasScheduled && (
        <Banner
          icon={<Today width={20} height={20} style={{ fill: "#007F5F" }} />}
          content={bannerCopy}
          type="success"
          action={
            <a href={"#campaign_activities"} className={styles.viewActivity}>
              View Activity
            </a>
          }
          className={styles.notify}
        />
      )}
      <div className={styles.main}>
        <CampaignSingleOverview
          dateFrom={selectedDate.from}
          dateTo={selectedDate.to}
          campaignId={campaign.id}
          campaign={campaign}
        />
        <CampaignSingleActivitiesList
          dateFrom={selectedDate.from}
          dateTo={selectedDate.to}
          activities={data.campaign.Activities}
        />
      </div>
    </div>
  );
}

function CampaignSingleActivitiesList({
  activities,
  dateFrom,
  dateTo,
}: {
  activities: Pick<
    Activity,
    "id" | "name" | "status" | "platform" | "createdAt" | "publishedAt"
  >[];
  dateFrom?: Date;
  dateTo?: Date;
}) {
  const { id: campaignId } = useParams<"id">();
  return (
    <div className={styles.activitiesList} id="campaign_activities">
      {activities.map((v) => (
        <CampaignActivityCard
          dateFrom={dateFrom}
          dateTo={dateTo}
          activity={v}
          campaignId={campaignId}
        />
      ))}
    </div>
  );
}
