import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import {
  CreativeCreateTypesV2,
  EditCreativeTypes,
  GlobalCreativeEditFields,
  GlobalCreativeFields,
  MetaCreativeEditFields,
  MetaCreativeFields,
} from "../misc/creativeCreateTypesV2";
import {
  MetaActivityAdEditInput,
  MetaActivityAdLineItemEditInput,
  useCreativeTemplateCreateMutation,
  useCreativeTemplateEditMutation,
  useCreativeTemplateQuery,
  useLeadGenFormTemplateCreateMutation,
  useMetaPageWelcomeMessageCreateMutation,
} from "src/graphql/generated/schema";
import { useState } from "react";
import { generateLeadGenTemplate } from "../misc/generateLeadGenTemplateInput";
import { useNavigate, useParams } from "react-router-dom";
import Header from "src/components/Header/Header";
import LibraryIcon from "../../../icons/library.svg?react";
import { LogOut01 } from "@untitled-ui/icons-react";
import { Spinner } from "src/components/Spinner/Spinner";
import { generateLeadCreateType } from "../misc/generateLeadCreateType";
import { generatePageWelcomeMessageInput } from "../misc/generatePageWelcomeMessageInput";
import {
  metaPlacementOptions,
  MetaPlacementOptionValue,
} from "../misc/metaPlacementOptions";
import { CreativeForm } from "../components/CreativeForm";
import { ErrorHandler } from "src/components/ErrorHandler";
import {
  generateInputFromActivityAdTemplate,
  generateInputFromLineItems,
} from "../misc/generateInputFromActivityAdTemplate";
import toast from "react-hot-toast";

export function EditCreativePage() {
  const navigate = useNavigate();
  useState<MetaPlacementOptionValue>(metaPlacementOptions[0].value);
  const [leadGenFormTemplateCreateFn] = useLeadGenFormTemplateCreateMutation();
  useState(false);
  const [creativeTemplateEditFunc] = useCreativeTemplateEditMutation({
    awaitRefetchQueries: true,
    refetchQueries: ["creativeTemplate"],
  });
  const [metaPageWelcomeMessageCreateFunc] =
    useMetaPageWelcomeMessageCreateMutation();
  const { id } = useParams();
  const {
    data: creativeTemplateData,
    loading: creativeTemplateLoading,
    error: creativeTemplateError,
  } = useCreativeTemplateQuery({
    variables: {
      id,
    },
  });

  async function handleSubmit(values: EditCreativeTypes) {
    const {
      global,
      meta: metaAd,
      whatsapp: whatsappAd,
      ...otherValues
    } = values;

    let leadGenId: string;
    let pageWelcomeMessageId: string;

    if (metaAd.leadGen) {
      const { data } = await leadGenFormTemplateCreateFn({
        variables: {
          input: generateLeadGenTemplate(metaAd.leadGen),
        },
      });

      leadGenId = data.leadGenFormTemplateCreate.id;
    }

    if (metaAd.pageWelcomeMessage) {
      const pageWelcomeMessage = metaAd.pageWelcomeMessage;
      const { data } = await metaPageWelcomeMessageCreateFunc({
        variables: {
          input: {
            name: pageWelcomeMessage.name,
            greeting: pageWelcomeMessage.greeting,
            MessageOptions: pageWelcomeMessage.MessageOptions?.map((v) => ({
              text: v.text,
              automatedReply: v.automatedReply,
            })),
            prefilledText: pageWelcomeMessage?.prefilledText,
          },
        },
        refetchQueries: ["metaPageWelcomeMessages"],
        awaitRefetchQueries: true,
      });

      if (data && data.metaPageWelcomeMessageCreate) {
        // perform other actions
        pageWelcomeMessageId = data.metaPageWelcomeMessageCreate.id;
      }
    }

    try {
      await creativeTemplateEditFunc({
        variables: {
          input: {
            ...otherValues,
            ...(values.meta.edited && {
              MetaActivityAdTemplate: {
                ...generateInputFromActivityAdTemplate(values),
                ...(leadGenId && { metaActivityLeadGenFormId: leadGenId }),
                ...(pageWelcomeMessageId && {
                  pageWelcomeMessageId,
                }),
              } as MetaActivityAdEditInput,
            }),
            DefaultTemplateField: {
              LineItems: generateInputFromLineItems(
                global.LineItems,
              ) as MetaActivityAdLineItemEditInput[],
              primaryText: global.primaryText,
            },
          },
        },
      });
      navigate("/library/ad-library");
    } catch (err) {
      toast.error(err.message);
    }
  }

  let initialValues: EditCreativeTypes = {
    id: "",
    name: "",
    platforms: ["META"],
    global: {
      id: "",
      primaryText: "",
      LineItems: [],
    },
    meta: {
      id: "",
      type: "SINGLE_IMAGE_OR_VIDEO",
      edited: false,
      primaryText: "",
      LineItems: [],
      crop: "DEFAULT",
    },
    whatsapp: {
      edited: false,
    },
  };

  if (!id) {
    return <div>Cannot edit this item</div>;
  }

  if (creativeTemplateError) {
    return <ErrorHandler error={creativeTemplateError} />;
  }

  if (creativeTemplateLoading) {
    return <Spinner height={"screen"} />;
  }

  if (creativeTemplateData && creativeTemplateData.creativeTemplate) {
    if (
      creativeTemplateData.creativeTemplate.MetaActivityAdTemplate.adsCount > 0
    ) {
      return <div> This template has ads and cannot be edited</div>;
    }
    const olderValues = initialValues;
    const template = creativeTemplateData.creativeTemplate;
    const global: GlobalCreativeEditFields = template.DefaultTemplateField
      ? {
          id: template.DefaultTemplateField.id,
          LineItems: template.DefaultTemplateField.LineItems as any[],
          primaryText: template.DefaultTemplateField.primaryText,
        }
      : null;
    const meta: MetaCreativeEditFields = template.MetaActivityAdTemplate
      ? {
          id: template.MetaActivityAdTemplate.id,
          LineItems: template.MetaActivityAdTemplate.LineItems.map((v) => ({
            id: v.id,
            PostAsset: v.PostAsset,
            order: v.order,
            StoryAsset: v.StoryAsset,
            description: v.description,
            headline: v.headline,
            link: v.link,
          })),
          edited: true,
          primaryText: template.MetaActivityAdTemplate.primaryText,
          type:
            template.MetaActivityAdTemplate.adType === "CUSTOM_CREATIVE"
              ? template.MetaActivityAdTemplate?.LineItems.length > 1
                ? "CAROUSEL"
                : "SINGLE_IMAGE_OR_VIDEO"
              : template.MetaActivityAdTemplate.adType === "CATALOG"
              ? "CATALOGUE_AD"
              : "EXISTING_POST",
          ...(template.MetaActivityAdTemplate.Catalogue?.id && {
            catalogueId: template.MetaActivityAdTemplate.Catalogue.id,
          }),
          ...(template.MetaActivityAdTemplate.metaProductSetId && {
            productSetId: template.MetaActivityAdTemplate.metaProductSetId,
          }),
          ...(template.MetaActivityAdTemplate.existingPostId && {
            existingPostId: template.MetaActivityAdTemplate.existingPostId,
          }),
          ...(template.MetaActivityAdTemplate
            .MetaActivityLeadGenFormTemplate && {
            leadGen: generateLeadCreateType(
              template.MetaActivityAdTemplate.MetaActivityLeadGenFormTemplate,
            ),
          }),
          ...(template.MetaActivityAdTemplate.PageWelcomeMessage && {
            pageWelcomeMessage: generatePageWelcomeMessageInput(
              template.MetaActivityAdTemplate.PageWelcomeMessage,
            ),
          }),
        }
      : null;

    initialValues = {
      ...olderValues,
      id: template.id,
      name: `${template.name}`,
      platforms: template.platforms,
      ...(global && { global }),
      ...(meta && { meta }),
    };

    console.log({ initialValues });
  }

  return (
    <div className="w-full">
      <Header
        showNotifications={false}
        icon={LibraryIcon}
        iconColor="danger"
        title="Create Creative Template"
        actions={
          <ButtonV2
            size="small"
            to="/library/ad-library"
            variant="outline"
            rightIcon={LogOut01}
          >
            Exit
          </ButtonV2>
        }
      />
      <CreativeForm initialValues={initialValues} handleSubmit={handleSubmit} />
    </div>
  );
}
