import classNames from "classnames";
import { SVGAttributes } from "react";
import styles from "./ToggleButton.module.scss";

export interface IToggleButton {
  options: [
    { icon: React.FC<SVGAttributes<any>>; value: string },
    { icon: React.FC<SVGAttributes<any>>; value: string },
  ];
  value: string;
  onChange: (i: string) => void;
}

export function ToggleButton({ options, value, onChange }: IToggleButton) {
  const firstOption = options[0];
  const secondOption = options[1];

  function handleSelect(value: string) {
    onChange(value);
  }

  const FirstIcon = firstOption.icon;
  const SecondIcon = secondOption.icon;

  return (
    <div className={styles.wrapper}>
      <button
        onClick={() => handleSelect(firstOption.value)}
        className={classNames(styles.toggleItem, styles.firstOption, {
          [styles.selectedToggleItem]: value === firstOption.value,
        })}
        type="button"
      >
        <FirstIcon width={14} height={14} />
      </button>
      <button
        onClick={() => handleSelect(secondOption.value)}
        className={classNames(styles.toggleItem, styles.secondOption, {
          [styles.selectedToggleItem]: value === secondOption.value,
        })}
        type="button"
      >
        <SecondIcon width={14} height={14} />
      </button>
    </div>
  );
}
