import { Zap, Star01 } from "@untitled-ui/icons-react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Dialog } from "src/components/Dialog/Dialog";
import { InteractiveInputCard } from "src/components/InteractiveInputCard/InteractiveInputCard";
import { AudiencePresetSearchInput } from "./AudiencePresetSearchInput";
import { InterestSearchInput } from "./InterestSearchInput";
import { InterestInlineSuggestions } from "./InterestInlineSuggestions";
import {
  AudiencePresetAndInterestSelectorFormState,
  AudiencePresetValue,
  InterestValue,
  SelectionType,
} from "../misc/audienceCreateTypes";
import { MetaInterest } from "src/graphql/generated/schema";
import { IAudiencePreset } from "../misc/IAudiencePreset";
import audiencePresets from "../misc/audiencePresets";
import { getInterestIcon } from "../functions/getInterestIcon";

interface IReviewCard {
  type: "presets" | "interests";
  values: AudiencePresetAndInterestSelectorFormState;
  title: string;
  adAccountId: string;
  description: string;
  icon: React.FC<React.SVGAttributes<SVGSVGElement>>;
  onAddMore: () => void;
  onSelectSearch: (value: MetaInterest | IAudiencePreset) => void;
  onDelete: (id: string) => void;
  onSelectSuggestion: (interest: MetaInterest) => void;
  onChange: (
    audiencePreset: IAudiencePreset | MetaInterest,
    value: SelectionType,
  ) => void;
}

export function ReviewCard({
  type,
  adAccountId,
  title,
  description,
  icon,
  onAddMore,
  onSelectSearch,
  onDelete,
  onSelectSuggestion,
  onChange,
  values,
}: IReviewCard) {
  const isPresets = type === "presets";
  const items = isPresets ? values.audiencePresets : values.interests;

  function getAudiencePresetIcon(preset: AudiencePresetValue) {
    const audiencePreset = audiencePresets.find((a) => a.id === preset.id);
    if (!audiencePreset) {
      return;
    }

    return audiencePreset.icon;
  }

  return (
    <div>
      <div className="flex items-center justify-between pb-16">
        <SectionHeader
          hasIconWrapper={true}
          icon={icon}
          iconColor="default"
          title={title}
          description={description}
        />

        <ButtonV2
          as={Dialog.Trigger}
          size="small"
          variant="outline"
          onClick={onAddMore}
        >
          Add more
        </ButtonV2>
      </div>

      <div className="px-16 py-16 border border-neutral-100 rounded-12">
        <div className="grid grid-cols-[1fr_auto] gap-4 pb-8">
          {isPresets ? (
            <AudiencePresetSearchInput onSelect={onSelectSearch} />
          ) : (
            <InterestSearchInput
              adAccountId={adAccountId}
              onSelect={onSelectSearch}
            />
          )}
          {/* <ButtonV2
            variant="outline"
            leftIcon={Zap}
            size="small"
            onClick={onAddMore}
          /> */}
        </div>

        {!isPresets && (
          <InterestInlineSuggestions
            adAccountId={adAccountId}
            selectedInterests={values.interests}
            onSelect={onSelectSuggestion}
          />
        )}

        <div className="flex flex-col pt-16">
          {Object.values(items).map((item) => (
            <InteractiveInputCard
              key={item.id}
              variant="minimal-inline"
              description=""
              hasDelete={true}
              onDelete={() => onDelete(item.id)}
              icon={
                isPresets ? getAudiencePresetIcon(item) : getInterestIcon(item)
              }
              inputType="SWITCH"
              onChange={(value) => {
                onChange(item, value as SelectionType);
              }}
              title={item.name}
              value={item.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
