import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import * as Yup from "yup";
import { ActivityObjectiveAndOthers } from "./ActivityObjectiveAndOthers";
import { useMetaIntegrationDefaultSettingsQuery } from "src/graphql/generated/schema";
import { activityMetaPurposeOptions } from "../misc/activityMetaPurposeOptions";
import { MetaIntegrationDefaultSettingsQueryResult } from "src/graphql/generated/schema";
import {
  facebookPlacements,
  instagramPlacements,
} from "../misc/placementOptions";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

const objectiveAndOthersValidationSchema = Yup.object({
  metaPurpose: Yup.string()
    .oneOf(activityMetaPurposeOptions.map((o) => o.value))
    .required("Objective is required"),
  adPlacementOptions: Yup.array(
    Yup.string().oneOf([...facebookPlacements, ...instagramPlacements]),
  )
    .required()
    .min(1, "Ad placement options are required"),
});

export function getAdPlacementOptions(
  metaIntegrationDefaultSettings: MetaIntegrationDefaultSettingsQueryResult["data"],
) {
  if (
    metaIntegrationDefaultSettings.metaIntegrationDefaultSettings
      .metaInstagramAccountId &&
    metaIntegrationDefaultSettings.metaIntegrationDefaultSettings.metaPageId
  ) {
    return [...facebookPlacements, ...instagramPlacements];
  }

  return facebookPlacements;
}

export function getInitialValues(data, metaIntegrationData) {
  if (
    !data.adAccountId &&
    metaIntegrationData &&
    metaIntegrationData.metaIntegrationDefaultSettings
  ) {
    const adPlacementOptions = getAdPlacementOptions(metaIntegrationData);
    return {
      ...data,
      adAccountId:
        metaIntegrationData.metaIntegrationDefaultSettings.metaAdAccountId,
      metaAdAccountId:
        metaIntegrationData.metaIntegrationDefaultSettings.MetaAdAccount
          .metaAccountId,
      metaPixelId:
        metaIntegrationData.metaIntegrationDefaultSettings.MetaPixel
          ?.metaPixelId,
      pixelId: metaIntegrationData.metaIntegrationDefaultSettings.metaPixelId,
      metaInstagramPageId:
        metaIntegrationData.metaIntegrationDefaultSettings.MetaInstagramAccount
          ?.metaInstagramId,
      instagramPageId:
        metaIntegrationData.metaIntegrationDefaultSettings
          .metaInstagramAccountId,
      metaPageId:
        metaIntegrationData.metaIntegrationDefaultSettings.MetaPage.metaPageId,
      pageId: metaIntegrationData.metaIntegrationDefaultSettings.metaPageId,
      metaPagePicture:
        metaIntegrationData.metaIntegrationDefaultSettings.MetaPage.Business
          ?.profilePicture,
      metaPageName:
        metaIntegrationData.metaIntegrationDefaultSettings.MetaPage.name,
      metaPageConnectedInstagramBusinessAccountId:
        metaIntegrationData.metaIntegrationDefaultSettings.MetaPage
          .metaPageConnectedInstagramBusinessId,
      conversionDomain:
        metaIntegrationData.metaIntegrationDefaultSettings.conversionDomain,
      adPlacementOptions,
    };
  }
  return data;
}

export function ActivityObjectiveAndOthersWizardForm({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { activeStepIndex, data, markStepAsDone } = useContext(WizardContext);
  const { data: metaIntegrationData } =
    useMetaIntegrationDefaultSettingsQuery();

  const initialValues = getInitialValues(data, metaIntegrationData);
  const showMetaIntegrationSettingsButton =
    metaIntegrationData?.hasMultipleIntegrationSettingsOptions;

  return (
    <Formik
      enableReinitialize
      validationSchema={objectiveAndOthersValidationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        markStepAsDone(2, values);
      }}
    >
      {({ values, setValues, handleSubmit }) => (
        <Form>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 1}
            isCompleted={activeStepIndex > 1}
          >
            <div className="pb-[5.5rem]">
              <ActivityObjectiveAndOthers
                sectionRef={sectionRef}
                values={values}
                setValues={setValues}
                showMetaIntegrationSettingsButton={
                  showMetaIntegrationSettingsButton
                }
              />
              <div className="flex pt-32">
                <ButtonV2
                  disabled={!values.adAccountId || !values.metaPurpose}
                  onClick={handleSubmit}
                >
                  Save & Continue
                </ButtonV2>
              </div>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
