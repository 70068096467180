import Shield01 from "@untitled-ui/icons-react/build/esm/Shield01";
import { useFormikContext } from "formik";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { MetaLeadCreateTypes } from "../misc/metaLeadCreateTypes";
import { FormGroupHeaderWithSwitch } from "./FormGroupHeaderWithSwitch";
import styles from "./MetaLeadCustomDisclaimer.module.scss";

export function MetaLeadCustomDisclaimer() {
  const { values, setFieldValue } = useFormikContext<MetaLeadCreateTypes>();

  return (
    <div className={styles.wrapper}>
      <FormGroupHeaderWithSwitch
        switchChecked={values.customDisclaimer.isEnabled}
        onSwitchChange={(value) =>
          setFieldValue("customDisclaimer.isEnabled", value)
        }
        title="Custom disclaimer"
        isRecommended={false}
        icon={Shield01}
        hasSwitch={true}
        description={
          "You can add additional notices such as marketing opt-ins and legal disclaimers to the default Facebook privacy disclaimer."
        }
      />

      {values.customDisclaimer.isEnabled && (
        <div className={styles.fields}>
          <InputFormikV2
            helpText="Disclaimer is shown after the form data is entered along with privacy policy link"
            placeholder="We never sell your data..."
            name="customDisclaimer.headline"
            label="Title"
          />
          <InputFormikV2
            helpText="Shown below the title"
            placeholder="We never sell your data..."
            name="customDisclaimer.description"
            label="Description"
          />
        </div>
      )}
    </div>
  );
}
