import React, { useContext } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import * as Yup from "yup";
import { adsetBudgetSchema } from "../misc/adsetBudgetSchema";
import AuthContext from "src/stores/AuthContext";
import { AudienceSelector } from "./AudienceSelector";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

const audienceSelectorSchema = Yup.object({
  Adsets: Yup.array(
    Yup.object({
      budget: adsetBudgetSchema,
      type: Yup.string()
        .oneOf(["AUDIENCE", "AUDIENCE_SET", "PRODUCT_RETARGET"])
        .required("type is required"),
      audienceId: Yup.string().nullable(),
      audienceSetId: Yup.string().nullable(),
      ProductSetSpecs: Yup.array().nullable(),
      promotedProductSetId: Yup.string().nullable(),
      templateId: Yup.string().required("template id is required"),
    }),
  )
    .required("Select atleast one audience")
    .min(1, "Select atleast one adset")
    .max(10, "Can't select more than 10 adsets"),
});

export function AudienceSelectorWizardForm({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { activeStepIndex, data, markStepAsDone } = useContext(WizardContext);
  const { activeClient } = useContext(AuthContext);

  return (
    <Formik
      validationSchema={audienceSelectorSchema}
      enableReinitialize
      initialValues={data as ActivityInputV2}
      onSubmit={(
        values: ActivityInputV2,
        formikHelpers: FormikHelpers<ActivityInputV2>,
      ) => {
        // check the minimum ad budget
        for (let [index, adset] of values.Adsets.entries()) {
          if (activeClient.currencyCode === "USD" && Number(adset.budget) < 1) {
            formikHelpers.setFieldError(
              `Adsets[${index}].budget`,
              "Daily budget should be more than or equal to $1",
            );
            return;
          }

          if (
            activeClient.currencyCode !== "USD" &&
            Number(adset.budget) < 100
          ) {
            formikHelpers.setFieldError(
              `Adsets[${index}].budget`,
              "Daily budget should be more than or equal to ₹100",
            );
            return;
          }
        }

        markStepAsDone(3, values);
      }}
    >
      {({ values, errors, submitCount, touched, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 2}
            isCompleted={activeStepIndex > 2}
          >
            <div className="pb-[5.5rem]">
              <AudienceSelector
                sectionRef={sectionRef}
                values={values}
                errors={errors}
                submitCount={submitCount}
                touched={touched}
              />

              <div className="pt-32">
                <ButtonV2 onClick={handleSubmit}>Save & Continue</ButtonV2>
              </div>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
