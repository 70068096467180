import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { MultiValue } from "react-select";
import { ComboBoxV2 } from "src/components/ComboBoxV2/ComboBoxV2";
import * as Yup from "yup";
import { FormikError } from "../../../components/FormikError/FormikError";
import { Label } from "../../../components/Label/Label";
import {
  RoleEnum,
  useConnectClientToUserMutation,
  useInviteUsersToClientMutation,
  useOrganizationClientsQuery,
} from "../../../graphql/generated/schema";
import styles from "./AddMembersModal.module.scss";
import { OrganizationUserSelector } from "./OrganizationUserSelector";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { Dialog } from "src/components/Dialog/Dialog";
import { useState } from "react";

interface FormValues {
  userIds: string[];
  invitedEmails: string[];
  clientIds: string[];
  role: RoleEnum;
}

const addMembersFormSchema = Yup.object().shape(
  {
    userIds: Yup.array(Yup.string())
      .required("Please select a user to invite")
      .when(
        "invitedEmails",
        (invitedEmails: any, schema: Yup.ArraySchema<any, any, any, any>) => {
          if (invitedEmails?.length === 0) {
            return schema.min(1, "Select atleast single user to continue");
          } else {
            return schema;
          }
        },
      ),
    invitedEmails: Yup.array(Yup.string().email())
      .required("Please provide as user to invite")
      .when(
        "userIds",
        (userIds: any, schema: Yup.ArraySchema<any, any, any, any>) => {
          if (userIds?.length === 0) {
            return schema.min(1, "Select atleast one item to continue");
          } else {
            return schema;
          }
        },
      ),
    clientIds: Yup.array(Yup.string())
      .required("Client is required to continue")
      .min(1, "select atleast one client to proceed"),
  },
  [["invitedEmails", "userIds"]],
);

export function AddMembersModal({ trigger }) {
  const [isOpen, setIsOpen] = useState(false);
  const { data, loading } = useOrganizationClientsQuery({
    variables: {
      filter: {
        take: 20,
      },
    },
  });

  const [connectClientToUserFunc] = useConnectClientToUserMutation();
  const [inviteUsersToClientFunc] = useInviteUsersToClientMutation();

  async function handleSubmit(values: FormValues) {
    for (const clientId of values.clientIds) {
      try {
        for (const userId of values.userIds) {
          await connectClientToUserFunc({
            variables: {
              input: {
                userId: userId,
                role: values.role,
                clientId: clientId,
              },
            },
          });
        }

        if (values.invitedEmails) {
          await inviteUsersToClientFunc({
            variables: {
              input: {
                clientId,
                emails: values.invitedEmails,
              },
            },
          });
        }
      } catch (err) {
        toast.error(err?.message);
        return;
      }
    }

    toast.success("Successfully added users to client");
    setIsOpen(false);
  }

  const clientOptions =
    data && data.organizationClients
      ? data.organizationClients.map((v) => ({
          label: v.name,
          value: v.id,
        }))
      : [];

  return (
    <Formik
      initialValues={
        {
          invitedEmails: [],
          clientIds: [],
          userIds: [],
          role: "MEMBER",
        } as FormValues
      }
      validationSchema={addMembersFormSchema}
      onSubmit={handleSubmit}
    >
      {({
        setFieldValue,
        setFieldTouched,
        values,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form>
          <Dialog
            trigger={trigger}
            open={isOpen}
            onOpenChange={setIsOpen}
            preventCloseOnOutsideClick={false}
            maxWidth={"600px"}
          >
            <Dialog.Header
              title="Add Members"
              subtitle="Add members and assign roles for client access."
            />
            <div className={styles.wrapper}>
              <div>
                <div className={styles.userAndRoleSelector}>
                  <OrganizationUserSelector
                    onChange={(newValues: MultiValue<any>) => {
                      const invitedUsers = newValues
                        .filter((v) => v.__isNew__)
                        .map((v) => v.value);
                      const existingUsers = newValues
                        .filter((v) => !v.__isNew__)
                        .map((v) => v.value);

                      setFieldValue("invitedEmails", invitedUsers);
                      setFieldValue("userIds", existingUsers);
                      setFieldTouched("invitedEmails", true);
                      setFieldTouched("userIds", true);
                    }}
                  />
                  <SelectV2
                    label={"Role"}
                    options={[
                      {
                        value: "ADMIN" as const,
                        label: "Admin",
                      },
                      {
                        value: "MEMBER" as const,
                        label: "Member",
                      },
                    ]}
                    value={values.role}
                    onChange={function (i: RoleEnum): void {
                      setFieldValue("role", i);
                    }}
                  />
                </div>
                <div>
                  <FormikError
                    formikError={errors}
                    touched={touched}
                    fieldName={
                      errors && errors.userIds ? "userIds" : "invitedEmails"
                    }
                  />
                </div>
              </div>
              <div>
                <Label label={"Which client you want to assign to invitee?"}>
                  <ComboBoxV2
                    multiple={true}
                    value={values.clientIds}
                    onChange={(values: Array<string>) => {
                      setFieldValue("clientIds", values);
                    }}
                    // loading={loading}
                    options={clientOptions}
                    placeholder="Enter client name to search"
                    renderSelectedValue={(i) => {
                      const client = clientOptions.find((v) => v.value === i);
                      if (client) {
                        return client.label;
                      }

                      return "";
                    }}
                  />
                  <FormikError
                    formikError={errors}
                    touched={touched}
                    fieldName={"clientIds"}
                  />
                </Label>
              </div>
            </div>
            <Dialog.Footer
              actionButtonText="Send Invite"
              onAction={handleSubmit}
              dismissButtonText="Cancel"
              actionButtonLoading={isSubmitting}
              actionButtonType="submit"
            />
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
