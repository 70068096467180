import classNames from "classnames";
import styles from "./AudienceCategoryBadges.module.scss";
import { AUDIENCE_CATEGORY } from "../misc/audienceCategories";
import Tooltip from "src/components/Tooltip/Tooltip";
import { BadgeV2, IBadgeV2 } from "./BadgeV2";

interface IAudienceCategoryBadge {
  categories?: Array<{ name: string; description: string }>;
}

export function AudienceCategoryBadges({ categories }: IAudienceCategoryBadge) {
  const hasMoreThanTwoCategories = categories?.length > 2;
  const topTwoCategories = categories?.slice(0, 2);
  const totalCategories = categories?.length;
  const remainingCategories = totalCategories - 2;

  return (
    <div className={styles.flexList}>
      {topTwoCategories?.map((category) => {
        return (
          <BadgeV2
            label={category.name}
            color={getColorBasedOnCategoryName(category.name)}
            size="medium"
          />
        );
      })}

      {hasMoreThanTwoCategories && (
        <Tooltip content={`${remainingCategories} more`}>
          <div className={classNames(styles.badge, styles.moreCategoriesBadge)}>
            +{remainingCategories}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

function getColorBasedOnCategoryName(categoryName: string): IBadgeV2["color"] {
  switch (categoryName) {
    case "Prospecting":
      return "sapphire";
    case "Retargeting":
      return "sienna";
    case "Retentions":
      return "viridian";
    case "Winback":
      return "crimson";
    case "Website":
      return "slate";
    case "Segment":
      return "amethyst";
    case "Instagram":
      return "wine";
    case "Facebook":
      return "cerulean";
    case "Lookalike":
      return "pine";
    case "Interest":
      return "khaki";
    case "Behaviour":
      return "jade";
    case "Demographic":
      return "royal-blue";
  }
}
