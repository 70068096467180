import classNames from "classnames";
import styles from "./NotificationItem.module.scss";
import NotificationMsgIcon from "./NotificationMsgIcon";
import { INotification } from "../misc/notification";
import { formatTime } from "src/lib/formatTime";
import { useMemo, useState } from "react";
import { NotificationMarkAsReadMutationFn } from "src/graphql/generated/schema";
import { Text } from "src/components/Text/Text";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Grid } from "src/components/Grid/Grid";
import { Icon } from "src/components/Icon/Icon";
import {
  Announcement03,
  BarLineChart,
  Check,
  RefreshCw05,
  CheckCircle,
  Colors,
  CreditCard01,
  InfoCircle,
  Sale01,
  Settings02,
  User01,
} from "@untitled-ui/icons-react";
import { IconWithWrapper } from "src/components/IconWithWrapper/IconWithWrapper";
import LogoBlackIcon from "src/components/Logo/macro-logo-black-icon.svg?react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { titleCase } from "src/lib/titleCase";

interface INotificationItem {
  notification: INotification;
  markAsRead?: NotificationMarkAsReadMutationFn;
  setIsActive?: (id: string) => void;
  isActive: boolean;
}

export default function NotificationItem({
  notification,
  isActive,
  setIsActive,
  markAsRead,
}: INotificationItem) {
  const badge = useMemo(() => {
    switch (notification.state) {
      case "NONE":
        return null;
      case "SUCCESS":
        return {
          icon: CheckCircle,
          color: "success" as const,
        };
      case "FAILURE":
        return { icon: InfoCircle, color: "danger" as const };
      case "IN_PROGRESS":
        return { icon: RefreshCw05, color: "warning" as const };
      default:
        return null;
    }
  }, [notification]);
  const notificationIcon = useMemo(() => {
    switch (notification.resouceType) {
      case "AUDIENCE":
        return Colors;
      case "CAMPAIGN":
        return Announcement03;
      case "SETTINGS":
        return Settings02;
      case "BUSINESS_HEALTH":
        return BarLineChart;
      case "BILLING":
        return CreditCard01;
      case "PROMOTIONAL":
        return Sale01;
      case "CUSTOMER":
        return User01;
      default:
        return LogoBlackIcon;
    }
  }, [notification]);

  console.log(notification);
  return (
    <button
      onClick={async () => {
        setIsActive(notification.id);
        await markAsRead({
          variables: {
            id: notification.id,
          },
        });
      }}
      className={classNames(
        "block w-full hover:bg-gray-200 p-12 rounded-8 text-left ",
        {
          "bg-gray-200": isActive,
        },
      )}
    >
      <Grid
        column={{
          xs: "46px 3fr 1fr",
        }}
      >
        <div>
          <IconWithWrapper
            icon={notificationIcon}
            size="lg"
            badge={badge ? badge.icon : null}
            badgeColor={badge ? badge.color : null}
          />
        </div>{" "}
        <div className="flex flex-col gap-4">
          {" "}
          <Text
            tone={notification.isRead ? "subdued" : "default"}
            size="sm"
            weight="medium"
          >
            {notification.title}
          </Text>
          <div className="max-w-[300px]">
            <Text
              as="p"
              tone={notification.isRead ? "subdued" : "default"}
              size="xs"
              noWrap={isActive ? false : true}
            >
              {notification.description}
            </Text>
          </div>
        </div>
        <div className="place-self-end pl-8">
          {!isActive && (
            <Text tone={"subdued"} size="2xs">
              {formatDistanceToNow(new Date(notification.createdAt))}
            </Text>
          )}
          {isActive && notification.actionLink && (
            <ButtonV2
              to={notification.actionLink}
              variant="outline"
              size="small"
            >
              {notification.actionLabel}
            </ButtonV2>
          )}
        </div>
      </Grid>
    </button>
  );
}
