import Cookies from "js-cookie";

const key = `macro_global_user_id`;

export function userIdSet(data: string) {
  // ignore if not production
  if (import.meta.env.VITE_ENV !== "production") return;

  const url = new URL(import.meta.env.VITE_FRONTEND_URL);

  Cookies.set(key, data, {
    // make this cookie cross domain
    domain: `.macrostack.co`,
  });
}

export function userIdGet(): string | null {
  const data = Cookies.get(key);

  if (data) {
    return data;
  } else {
    return null;
  }
}
