import { MetaLeadCreateTypes } from "src/modules/creative/misc/metaLeadCreateTypes";
import { PhoneNotificationBar } from "src/modules/global/components/PhoneNotificationBar";
import styles from "./InstagramLeadFormPreview.module.scss";

interface IInstagramMetaLeadFormPreview {
  userName: string;
  leadForm: MetaLeadCreateTypes;
  userAvatar: string;
  screenIndex: number;
}

export function InstagramMetaLeadFormPreview({
  userName,
  leadForm,
  screenIndex,
  userAvatar,
}: IInstagramMetaLeadFormPreview) {
  const { prefillQuestionsDescription } = leadForm;

  return (
    <div className={styles.instagramPreview}>
      <PhoneNotificationBar />

      <div className={styles.instagramPreviewContent}>
        {/* {audienceGreeting.isEnabled && (
          <div className={styles.heading}>{audienceGreeting.headline}</div>
        )} */}

        <div className={styles.instagramUserInfo}>
          <img
            src={userAvatar}
            alt={userName}
            className={styles.instagramHeaderAvatar}
          />
          <div className={styles.instagramHeaderUserName}>{userName}</div>
        </div>

        {prefillQuestionsDescription && (
          <div className={styles.instagramDescription}>
            {prefillQuestionsDescription}
          </div>
        )}
      </div>
      <div className={styles.instagramFooter}>
        <button type="button" className={styles.instagramFooterButton}>
          Continue
        </button>
      </div>
    </div>
  );
}
