import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import {
  MetaAllAdAccountsDocument,
  MetaEnabledAdAccountsDocument,
  MetaIntegrationStatusDocument,
  MetaIntegrationStatusEnum,
  useExchangeMetaCodeForAccessTokenMutation,
  useMetaIntegrationRevokeMutation,
  useMetaIntegrationStatusQuery,
} from "src/graphql/generated/schema";
import MetaIntegrationWizard from "../components/MetaIntegrationForm";
import styles from "./MetaIntegration.module.scss";
// import AuthContext from "src/stores/AuthContext";
import { PageLoader } from "src/modules/global/components/PageLoader";
import { MetaIntegrationSingle } from "../components/MetaIntegrationSingle";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { IntegrationHeader } from "src/modules/global/components/IntegrationHeader";
import metaIntegrationIcon from "../assets/metaIntegrationIcon.svg";
import { MetaIntegrationSidePanel } from "../components/MetaIntegrationSidePanel";

export default function MetaIntegration() {
  // Check if this page is redirected back with the code so we exchange it for access token
  const [searchParams, setSearchParams] = useSearchParams();
  const metaCode = searchParams.get("code");
  const metaState = searchParams.get("state");

  // const { user } = useContext(AuthContext);
  // Data loading and mutations
  const {
    data: statusData,
    error,
    loading: statusLoading,
  } = useMetaIntegrationStatusQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [onRevokeIntegration, { loading: revokeLoading }] =
    useMetaIntegrationRevokeMutation({
      refetchQueries: [
        { query: MetaIntegrationStatusDocument },
        { query: MetaAllAdAccountsDocument },
        { query: MetaEnabledAdAccountsDocument },
      ],
      awaitRefetchQueries: true,
    });

  const [exchangeMetaCodeForAccessToken, { loading: accessTokenLoading }] =
    useExchangeMetaCodeForAccessTokenMutation();

  // Exchange meta code for access token
  const exchangeMetaCode = useCallback(
    async (metaCode, metaState) => {
      await exchangeMetaCodeForAccessToken({
        variables: {
          input: {
            accessTokenCode: metaCode,
            accessTokenState: metaState,
          },
        },
        refetchQueries: [
          { query: MetaIntegrationStatusDocument },
          { query: MetaAllAdAccountsDocument },
        ],
        awaitRefetchQueries: true,
      });
      // remove query params from url
      setSearchParams({});
    },
    [exchangeMetaCodeForAccessToken, setSearchParams]
  );

  useEffect(() => {
    if (metaCode && metaState) {
      exchangeMetaCode(metaCode, metaState);
    }
  }, [metaCode, metaState, exchangeMetaCode]);

  if (statusLoading || accessTokenLoading) {
    return <PageLoader />;
  }

  const integrationStatus: MetaIntegrationStatusEnum = statusData
    ? statusData.metaIntegrationStatus
    : "NOT_CONNECTED";

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div>
      <PageHeader
        title={"Meta Integration"}
        breadcrumbs={[
          {
            label: "Settings",
            link: "/settings/integrations",
          },
          {
            label: "Integrations",
            link: "/settings/integrations",
          },
          {
            label: "Meta",
            link: "/settings/integrations/meta",
          },
        ]}
        back={"/settings/integrations"}
      />
      <IntegrationHeader
        isConnected={integrationStatus !== "NOT_CONNECTED"}
        revokeIntegrationFn={onRevokeIntegration}
        revokeLoading={revokeLoading}
        title={"Meta"}
        description={
          "Connect with Meta and create facebook ads and target your audience"
        }
        imageSrc={metaIntegrationIcon}
        isBeta={false}
      />
      <div className={styles.page}>
        <div>
          {integrationStatus === "ACTIVE" ? (
            <MetaIntegrationSingle />
          ) : (
            <MetaIntegrationWizard status={integrationStatus} />
          )}
        </div>
        <MetaIntegrationSidePanel />
      </div>
    </div>
  );
}
