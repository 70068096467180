import styles from "./Integration.module.scss";
import { IntegrationCard } from "./IntegrationCard";
import emailIntegration from "./assets/Email.svg";
import shopifyIntegration from "./assets/Shopify.svg";
import smsIntegration from "./assets/Message.svg";
import metaIntegration from "./assets/Meta.svg";
import whatsappIntegration from "./assets/Whatsapp.svg";
import pushNotificationIntegration from "./assets/Notification.svg";
import snapchatIntegration from "./assets/Snapchat.svg";
import googleIntegration from "./assets/Google.svg";
import tiktokIntegration from "./assets/Tiktok.svg";
// import footerImg from "./assets/footerImg.png";
import Tune from "@material-symbols/svg-600/rounded/tune.svg?react";
import { LegacySectionHeading } from "../../../modules/global/components/LegacySectionHeading";
import classNames from "classnames";
import { useIntegrationsStatusQuery } from "../../../graphql/generated/schema";
import { titleCase } from "../../../lib/titleCase";
import AuthContext from "src/stores/AuthContext";
import { useContext } from "react";
import Header from "src/components/Header/Header";
import integrationStatus from "src/graphql/integrationStatus";

const isProduction = import.meta.env.NODE_ENV === "production";
export function Integration() {
  const { user } = useContext(AuthContext);
  const { data } = useIntegrationsStatusQuery();

  const socialSitesDetail = [
    {
      name: "Meta Ads",
      value: "meta",
      description:
        "Connect with Meta and create facebook ads and target your audience",
      label: "Connect Meta",
      image: metaIntegration,
      link: "/settings/integrations/meta",
    },
    {
      name: "Whatsapp",
      value: "whatsapp",
      description:
        "Integration whatsapp and get the powerful insight about your business and sales",
      label: "Connect your Whatsapp",
      image: whatsappIntegration,
      link: "/settings/integrations/whatsapp/setup",
      disabled: isProduction
        ? user.id === "clnecrl900001qe0f2f3vm90z"
          ? false
          : true
        : false,
    },
    {
      name: "Shopify Store",
      value: "shopify",
      description:
        "Connect your store to Shopify for insights into customer behavior and sales trends",
      label: "Connect Shopify Store",
      image: shopifyIntegration,
      link: "/settings/integrations/shopify",
      disabled: false,
    },
    {
      name: "Google Ads",
      value: "google",
      description:
        "Link with Google Ads to craft targeted campaigns and drive traffic with precision.",
      label: "Learn More",
      image: googleIntegration,
      link: "/settings/integrations/google",
      disabled: true,
    },
    {
      name: "Email",
      value: "email",
      description:
        "Sync with email to design targeted campaigns and enhance communication with your audience.",
      label: "Add Email Account",
      image: emailIntegration,
      link: "/settings/integrations/email",
      disabled: true,
    },
    {
      name: "Text Message",
      value: "sms",
      description:
        "For easy and effective way to reach to your customer is SMS Marketing",
      label: "Learn More",
      image: smsIntegration,
      link: "/settings/integrations/message",
      disabled: true,
    },
    {
      name: "Push Notification",
      value: "pushNotification",
      description:
        "Integrate push notifications to engage users with timely updates and tailored messages.",
      label: "Learn More",
      image: pushNotificationIntegration,
      link: "/settings/integrations/message",
      disabled: true,
    },
    {
      name: "Tiktok Ads",
      value: "tiktok",
      description:
        "Integrate with TikTok Ads to reach your audience through captivating, viral content.",
      label: "Learn More",
      image: tiktokIntegration,
      link: "/settings/integrations/message",
      disabled: true,
    },
    {
      name: "Snapchat Ads",
      value: "snapchat",
      description:
        "Connect with Snapchat Ads to deliver dynamic, interactive ads and boost user engagement.",
      label: "Learn More",
      image: snapchatIntegration,
      link: "/settings/integrations/message",
      disabled: true,
    },
    // {
    //   name: "Connect your Website",
    //   description:
    //     "Connect macro to your website and get the powerful insight about your business and sales",
    //   label: "Connect your website",
    //   image: websiteIntegration,
    //   link: "/settings/integrations/website",
    // },
  ];

  const integrationStatusWithPlatform = data && data.integrationsStatus;

  return (
    <div>
      <Header title={"Integration"} />
      <div className={styles.container}>
        <div className={classNames(styles.platforms, styles.intro)}>
          {socialSitesDetail.map((item, index) => (
            <IntegrationCard
              key={index}
              imageHeight={32}
              imageWidth={32}
              action={
                item.disabled
                  ? {
                      children: "Coming soon",
                    }
                  : {
                      children:
                        integrationStatusWithPlatform?.[item.value] === "ACTIVE"
                          ? "Configure"
                          : "Connect",
                      rightIcon:
                        integrationStatusWithPlatform?.[item.value] === "ACTIVE"
                          ? Tune
                          : null,
                      to: item.link,
                    }
              }
              badge={
                !item.disabled && integrationStatusWithPlatform
                  ? {
                      label: integrationStatusWithPlatform?.[item.value]
                        ? titleCase(
                            integrationStatusWithPlatform?.[item.value],
                            "_",
                          )
                        : "Beta",
                      color:
                        integrationStatusWithPlatform[item.value] === "ACTIVE"
                          ? "green"
                          : "purple",
                    }
                  : null
              }
              {...item}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
