import classNames from "classnames";
import { Grid } from "src/components/Grid/Grid";
import { Text } from "src/components/Text/Text";
import styles from "./GrowthCategoryCard.module.scss";

interface GrowthCategoryCardProps {
  title: string;
  description: string;
  onClick?: () => void;
  isSelected?: boolean;
}

export function GrowthCategoryCard({
  title,
  description,
  onClick,
  isSelected,
}: GrowthCategoryCardProps) {
  return (
    <div
      className={classNames(styles.card, {
        [styles.selected]: isSelected,
      })}
      onClick={onClick}
      role={onClick ? "button" : undefined}
      tabIndex={onClick ? 0 : undefined}
    >
      <Text weight="medium">{title}</Text>
      <Text size="xs" tone="subdued">
        {description}
      </Text>
    </div>
  );
}
