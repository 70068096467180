import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Radio } from "src/components/Radio/Radio";
import { TitleWithThumbnail } from "src/modules/global/components/TitleWithThumbnail";
import Modal from "../../../components/Modal/Modal";
import styles from "./GoogleAdAccountDefaultSettingsSelectorModal.module.scss";
import { useFormikContext } from "formik";
import { LineHeading } from "src/modules/global/components/LineHeading";
import { GooglePlatformAccounts } from "src/graphql/generated/schema";
import Building01 from "@untitled-ui/icons-react/build/esm/Building01";

export function GoogleAdAccountDefaultSettingsSelectorModal({
  isOpen,
  onClose,
  clientAccounts,
  handleSubmit,
}: {
  isOpen: boolean;
  onClose: () => void;
  clientAccounts: GooglePlatformAccounts[];
  handleSubmit: () => void;
}) {
  const { values, setFieldValue, isSubmitting } = useFormikContext<{
    managerId: string;
    clientAccountId: string;
  }>();

  const formListItems: Array<{
    label: string;
    icon: React.ReactNode;
    formValueKey: "clientAccountId";
    data: { id: string; name: string }[];
  }> = [
    {
      label: "Client Accounts",
      formValueKey: "clientAccountId",
      icon: <Building01 height={14} width={14} />,
      data: clientAccounts.map((v) => ({
        name: v.name,
        id: v.id,
      })),
    },
  ];

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      maxWidth="500px"
      title="Default account setting"
      footer={{
        footerActions: (
          <>
            <ButtonV2
              size="small"
              variant="outline"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Back
            </ButtonV2>
            <ButtonV2
              size="small"
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Set as default
            </ButtonV2>
          </>
        ),
        footerContent: "",
        hideBorder: true,
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.description}>
          By selecting default setting, you are telling macro in future
          campaigns and audience use these default setting, so you don't have to
          add them each time. Don't worry you can change these setting in future
          anytime you want.
        </div>

        <div className={styles.formContainer}>
          {formListItems
            .filter((section) => section.data.length > 0)
            .map((section) => (
              <div key={section.label} className={styles.formList}>
                <LineHeading
                  content={section.label}
                  icon={section.icon}
                  className={styles.sectionHeading}
                />
                {section.data.map((v) => (
                  <ListItemRadioButton
                    item={v}
                    checked={values[section.formValueKey] === v.id}
                    key={v.id}
                    onChange={(newChecked) => {
                      setFieldValue(section.formValueKey, v.id);
                    }}
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
}

function ListItemRadioButton({
  item,
  onChange,
  checked,
}: {
  item: {
    id: string;
    name: string;
    thumbnail?: string;
  };
  checked: boolean;
  onChange: (i: boolean) => void;
}) {
  return (
    <div className={styles.listItemRadio}>
      <TitleWithThumbnail
        size="small"
        thumbnail={{
          uri: `https://api.dicebear.com/9.x/shapes/svg?seed=${item.name}`,
          contentType: "IMAGE",
        }}
        name={item.name}
        descriptionItems={[]}
      />
      <Radio
        value={item.id}
        checked={checked}
        size={12}
        onChange={(newChecked) => onChange(newChecked)}
      />
    </div>
  );
}
