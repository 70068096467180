import { SmartphoneShell } from "./SmartphoneShell";
import { IAdPreview } from "../misc/creativePreviewTypes";
import { transformAdTemplateToLineItems } from "../functions/transformAdTemplateToLineItems";
import { InstagramFeedAdPreview } from "./InstagramFeedAdPreview";
import { InstagramReelAdPreview } from "./InstagramReelAdPreview";
import { InstagramStoryAdPreview } from "./InstagramStoryAdPreview";
import { FacebookFeedAdPreview } from "./FacebookFeedAdPreview";
import { FacebookReelAdPreview } from "./FacebookReelAdPreview";
import { FacebookStoryAdPreview } from "./FacebookStoryAdPreview";

interface ICreativePreview {
  platform: "FACEBOOK" | "INSTAGRAM" | "WHATSAPP";
  metaFormat: "FEED" | "STORY" | "REEL";
  ad: any;
  accountName?: string;
  profilePicture?: string;
  setActiveAssetIndex: (i: number) => void;
  activeAssetIndex: number;
  screenSize?: string;
}

export function CreativePreview({
  ad,
  platform,
  metaFormat,
  accountName,
  profilePicture,
  activeAssetIndex,
  setActiveAssetIndex,
  screenSize,
}: ICreativePreview) {
  let RenderingPreview: (i: IAdPreview) => JSX.Element;

  if (platform === "FACEBOOK" && metaFormat === "FEED") {
    RenderingPreview = FacebookFeedAdPreview;
  }

  if (platform === "FACEBOOK" && metaFormat === "STORY") {
    RenderingPreview = FacebookStoryAdPreview;
  }

  if (platform === "FACEBOOK" && metaFormat === "REEL") {
    RenderingPreview = FacebookReelAdPreview;
  }

  if (platform === "INSTAGRAM" && metaFormat === "FEED") {
    RenderingPreview = InstagramFeedAdPreview;
  }

  if (platform === "INSTAGRAM" && metaFormat === "REEL") {
    RenderingPreview = InstagramReelAdPreview;
  }

  if (platform === "INSTAGRAM" && metaFormat === "STORY") {
    RenderingPreview = InstagramStoryAdPreview;
  }

  const lineItems = transformAdTemplateToLineItems(ad);

  const primaryText = ad?.primaryText ?? "";
  const callToActionLabel = ad?.callToActionLabel ?? "LEARN_MORE";
  const userName = accountName ?? "-";

  return (
    <SmartphoneShell screenSize={screenSize}>
      <RenderingPreview
        setActiveAssetIndex={setActiveAssetIndex}
        activeAssetIndex={activeAssetIndex}
        caption={primaryText}
        ctaLabel={callToActionLabel}
        isProductCatalogue={ad?.type === "CATALOGUE_AD"}
        lineItems={lineItems}
        userAvatar={profilePicture}
        userName={userName}
      />
    </SmartphoneShell>
  );
}
