import { NotificationReadStatus } from "src/graphql/generated/schema";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Check, CheckCircleBroken } from "@untitled-ui/icons-react";

export function NotificationTabs({
  selectedReadStatus,
  setSelectedReadStatus,
  markAllAsRead,
}: {
  selectedReadStatus: NotificationReadStatus;
  setSelectedReadStatus: (i: NotificationReadStatus) => void;
  markAllAsRead?: () => void;
}) {
  return (
    <div className="flex justify-between items-center">
      <TabsV2
        tabs={[
          { label: "All", value: "ALL" },
          {
            label: "Unread",
            value: "UNREAD",
          },
          { label: "Read", value: "READ" },
        ]}
        selectedTab={selectedReadStatus}
        onTabChange={setSelectedReadStatus}
        size="small"
      />
      <ButtonV2
        onClick={markAllAsRead}
        leftIcon={CheckCircleBroken}
        size="small"
        variant="outline"
      >
        Mark all as read
      </ButtonV2>
    </div>
  );
}
