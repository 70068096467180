import React, { useContext, useState } from "react";
import Tool02 from "@untitled-ui/icons-react/build/esm/Tool02";
import File05 from "@untitled-ui/icons-react/build/esm/File05";
import { Formik, Form, FormikHelpers } from "formik";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { WizardStep } from "src/components/WizardStep/WizardStep";
import { CalloutCard } from "src/components/CalloutCard/CalloutCard";
import { Grid } from "src/components/Grid/Grid";
import { AudiencePresetAndInterestSelectorModal } from "./AudiencePresetAndInterestSelectorModal";
import { Dialog } from "src/components/Dialog/Dialog";
import { audienceAndPresetSchema } from "../misc/audienceAndPresetSchema";
import { AudiencePresetAndInterestSelectorFormState } from "../misc/audienceCreateTypes";
import { FormikError } from "src/components/FormikError/FormikError";
import { UnlockFeaturesModal } from "src/components/UnlockFeaturesModal/UnlockFeaturesModal";

interface IAudienceTemplateTypeSelector {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}

export function AudienceTemplateTypeSelector({
  sectionRef,
}: IAudienceTemplateTypeSelector) {
  const [
    isAudiencePresetAndInterestSelectorOpen,
    setIsAudiencePresetAndInterestSelectorOpen,
  ] = useState(false);
  const { activeStepIndex, markStepAsDone, data } = useContext(WizardContext);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  function handleSubmit(
    values: AudiencePresetAndInterestSelectorFormState,
    {
      setFieldError,
    }: FormikHelpers<AudiencePresetAndInterestSelectorFormState>,
  ) {
    if (
      Object.keys(values.interests).length === 0 &&
      Object.keys(values.audiencePresets).length === 0
    ) {
      return setFieldError(
        "interests",
        "Please select at least one audience preset or interest",
      );
    }
    markStepAsDone(2, { ...data, ...values });
  }

  return (
    <Formik
      validationSchema={audienceAndPresetSchema}
      enableReinitialize
      initialValues={data}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        submitCount,
        touched,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <WizardStep
            ref={sectionRef}
            title="How would you like to build your audience?"
            description="Choose how you want to build your audience - either use our presets and interests or start from a template"
            isStepNotActive={activeStepIndex !== 1}
            isCompleted={activeStepIndex > 1}
          >
            <Grid column={{ xs: "400px 400px" }} columnGap={{ xs: 8 }}>
              <AudiencePresetAndInterestSelectorModal
                defaultSelectedTab="AUDIENCE_PRESET"
                onSubmit={({ interests, presets }) => {
                  setFieldValue("interests", interests);
                  setFieldValue("audiencePresets", presets);

                  setIsAudiencePresetAndInterestSelectorOpen(false);
                  handleSubmit();
                }}
                adAccountId={data.adAccountId}
                trigger={
                  <CalloutCard
                    icon={Tool02}
                    title="Build audience using presets or interests"
                    primaryAction={{
                      variant: "outline",
                      as: Dialog.Trigger,
                      children: "Build Custom Audience",
                    }}
                    description="Want to target a specific audience? Build your custom audience by
                    choosing targeting options including interests, demographics,
                    location and other criteria"
                  />
                }
                isOpen={isAudiencePresetAndInterestSelectorOpen}
                onIsOpenChange={setIsAudiencePresetAndInterestSelectorOpen}
              />

              <CalloutCard
                icon={File05}
                title="Browse templates for your audience"
                primaryAction={{
                  // as: Dialog.Trigger,
                  variant: "outline",
                  children: "Create Using Template",
                  onClick: () => {
                    setIsUpgradeModalOpen(true);
                  },
                }}
                description="Get started quickly with ready-made audience templates tailored by 
                behavior, interests and demographics. Templates are fully customizable to match 
                your specific needs"
              />
            </Grid>

            <UnlockFeaturesModal
              type="UNLOCK_ENGAGE"
              title="Unlock Audience Templates With Engage"
              description="Access to audience templates will be available to all Engage subscribers."
              isOpen={isUpgradeModalOpen}
              onOpenChange={setIsUpgradeModalOpen}
            />

            {errors.interests && (
              <FormikError
                formikError={errors}
                submitCount={submitCount}
                touched={touched}
                fieldName="interests"
              />
            )}
          </WizardStep>
        </Form>
      )}
    </Formik>
  );
}
