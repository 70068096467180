export type AUDIENCE_CATEGORY =
  | "FACEBOOK"
  | "INSTAGRAM"
  | "WEBSITE"
  | "PROSPECTING"
  | "RETENTIONS"
  | "RETARGETING"
  | "WINBACK"
  | "LOOKALIKE"
  | "INTEREST"
  | "BEHAVIOUR"
  | "DEMOGRAPHIC"
  | "SEGMENT";

export const audienceCategories = [
  { value: "AudienceCategory_1", label: "Prospecting" },
  { value: "AudienceCategory_2", label: "Retargeting" },
  { value: "AudienceCategory_3", label: "Retentions" },
  { value: "AudienceCategory_4", label: "Winback" },
  { value: "AudienceCategory_5", label: "Website" },
  { value: "AudienceCategory_6", label: "Segment" },
  { value: "AudienceCategory_7", label: "Instagram" },
  { value: "AudienceCategory_8", label: "Facebook" },
  { value: "AudienceCategory_9", label: "Lookalike" },
  { value: "AudienceCategory_10", label: "Interest" },
  { value: "AudienceCategory_11", label: "Behaviour" },
  { value: "AudienceCategory_12", label: "Demographic" },
];
