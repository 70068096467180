import { X as Close } from "@untitled-ui/icons-react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import React from "react";
import styles from "./Dialog.module.scss";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { SectionHeader } from "../SectionHeader/SectionHeader";

export interface IDialog {
  trigger: React.ReactNode;
  maxWidth?: string;
  children: React.ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  noPadding?: boolean;
  preventCloseOnEscape?: boolean;
  preventCloseOnOutsideClick?: boolean;
}

/**
 * the trigger should be a DialogTrigger element which is defined below
 */
export function Dialog({
  trigger,
  maxWidth,
  preventCloseOnEscape = false,
  preventCloseOnOutsideClick = false,
  children,
  open,
  onOpenChange,
  noPadding = false,
}: IDialog) {
  return (
    <DialogPrimitive.Root open={open} onOpenChange={onOpenChange}>
      {trigger}

      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className={styles.overlay}>
          <DialogPrimitive.Content
            onPointerDownOutside={(e) => {
              if (preventCloseOnOutsideClick) {
                e.preventDefault();
              }
            }}
            onEscapeKeyDown={(e) => {
              if (preventCloseOnEscape) {
                e.preventDefault();
              }
            }}
            className={`${styles.content} ${noPadding ? styles.noPadding : ""}`}
            style={{ maxWidth }}
          >
            {children}
          </DialogPrimitive.Content>
        </DialogPrimitive.Overlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}

interface IDialogHeader {
  title: string;
  noCloseButton?: boolean;
  size?: "large" | "default";
  subtitle?: string;
  icon?: React.FC<React.SVGAttributes<SVGElement>>;
  iconColor?: "default" | "danger" | "info" | "warning" | "success";
}

function DialogHeader({
  size = "default",
  icon,
  title,
  subtitle,
  iconColor,
  noCloseButton = false,
}: IDialogHeader) {
  return (
    <div className={styles.header}>
      <div className={styles.titleContainer}>
        <div className={styles.titleWrapper}>
          <SectionHeader
            size={size}
            title={title}
            titleAs={DialogPrimitive.Title}
            description={subtitle}
            icon={icon}
            iconColor={iconColor}
            hasIconWrapper
          />
        </div>
      </div>
      {!noCloseButton && (
        <DialogPrimitive.Close className={styles.closeButton}>
          <Close width={24} height={24} />
        </DialogPrimitive.Close>
      )}
    </div>
  );
}

interface IDialogFooter {
  actionButtonLoading?: boolean;
  dismissButtonText: string;
  actionButtonText: string;
  actionButtonType?: "button" | "submit" | "reset";
  onAction?: () => void;
}

function DialogFooter({
  dismissButtonText,
  actionButtonText,
  actionButtonType,
  onAction,
  actionButtonLoading,
}: IDialogFooter) {
  return (
    <div className={styles.footer}>
      <ButtonV2
        as={DialogPrimitive.Close}
        variant="outline"
        size="small"
        type="button"
      >
        {dismissButtonText}
      </ButtonV2>
      {actionButtonText && onAction && (
        <ButtonV2
          type={actionButtonType}
          loading={actionButtonLoading}
          variant="solid"
          onClick={onAction}
          size="small"
        >
          {actionButtonText}
        </ButtonV2>
      )}
    </div>
  );
}

function DialogStickyFooter({
  dismissButtonText,
  actionButtonLoading,
  actionButtonType,
  actionButtonText,
  onAction,
}: IDialogFooter) {
  return (
    <div className={styles.stickyFooter}>
      <ButtonV2 as={DialogPrimitive.Close} variant="outline" size="small">
        {dismissButtonText}
      </ButtonV2>
      {actionButtonText && onAction && (
        <ButtonV2
          loading={actionButtonLoading}
          type={actionButtonType}
          variant="solid"
          onClick={onAction}
          size="small"
        >
          {actionButtonText}
        </ButtonV2>
      )}
    </div>
  );
}

Dialog.Header = DialogHeader;
Dialog.Footer = DialogFooter;
Dialog.StickyFooter = DialogStickyFooter;
Dialog.Trigger = DialogPrimitive.Trigger;
Dialog.Close = DialogPrimitive.Close;
Dialog.Title = DialogPrimitive.Title;
