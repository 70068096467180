import { createContext } from "react";

export type ITableContext = {
  isSelectable: boolean;
  isSelectModeEnabled: boolean;
  selectedItems: string[];
  onSelectedItemsChange: (i: string[]) => unknown;
  variant: "page" | "card";
  isScrollable: boolean;
  hasHorizontallyScrolled: boolean;
  data: any[];
};

export const TableContext = createContext<ITableContext>({
  isSelectable: true,
  isSelectModeEnabled: true,
  isScrollable: false,
  selectedItems: [],
  hasHorizontallyScrolled: false,
  variant: "page",
  data: [],
  onSelectedItemsChange: () => {},
});
