import { useMemo } from "react";
import { MetaActivity } from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { GrowAggregateType } from "src/modules/global/misc/GrowAggregateType";
import styles from "./ActivityOverviewStatCards.module.scss";
import AudienceCount from "@untitled-ui/icons-react/build/esm/Users01";
import DailyBudget from "@untitled-ui/icons-react/build/esm/Wallet02";
import Facebook from "../../../icons/facebook.svg?react";
import Instagram from "../../../icons/instagram.svg?react";
import MetaActivityType from "../../../icons/meta-activity-type.svg?react";
import TotalSpends from "@untitled-ui/icons-react/build/esm/CoinsStacked01";
import classNames from "classnames";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";
import { StatsCardWidget } from "src/components/StatsCardWidget/StatsCardWidget";
import { MetricCardList } from "src/components/MetricCardList/MetricCardList";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";

interface IActivityOverviewStatCards {
  statData: GrowAggregateType;
  metaActivity: Pick<
    MetaActivity,
    "activityType" | "MetaActivityAdsets" | "adPlacements" | "dailyBudget"
  >;
}

export function ActivityOverviewStatCards({
  statData,
  metaActivity,
}: IActivityOverviewStatCards) {
  const currencyCode = useGetActiveCurrencySymbol();
  const facebookPlacementsCount = metaActivity.adPlacements.filter((v) =>
    v.includes("FACEBOOK"),
  ).length;
  const instagramPlacementsCount = metaActivity.adPlacements.filter((v) =>
    v.includes("INSTAGRAM"),
  ).length;

  const stats = useMemo(
    () => [
      {
        title: "Meta Activity Type",
        value: snakeCaseToTitleCase(metaActivity.activityType),
        valueIcon: MetaActivityType,
      },
      {
        title: "Audiences Count",
        value: formatNumberByType(
          metaActivity?.MetaActivityAdsets?.length || 0,
          "NUMBER",
        ),
        valueIcon: AudienceCount,
      },
      {
        title: "Total Spends",
        value: statData?.spend
          ? formatNumberByType(statData?.spend ?? 0, "CURRENCY", {
              selectedCurrencyCode: currencyCode,
              showDecimal: false,
            })
          : "-",
        valueIcon: TotalSpends,
      },
      {
        title: "Daily Budget",
        value: formatNumberByType(metaActivity?.dailyBudget / 100, "CURRENCY", {
          selectedCurrencyCode: currencyCode,
          showDecimal: false,
        }),
        valueIcon: DailyBudget,
      },
      {
        title: "Placement",
        value: (
          <div className="flex gap-12 items-center">
            <div className="flex gap-4 items-center">
              <Facebook height={20} width={20} />
              {formatNumberByType(facebookPlacementsCount, "NUMBER", {
                showDecimal: false,
              })}
            </div>
            <VerticalSeparator totalWidth={1} separatorHeight={16} />
            <div className="flex gap-4 items-center">
              <Instagram height={20} width={20} />
              {formatNumberByType(instagramPlacementsCount, "NUMBER", {
                showDecimal: false,
              })}
            </div>
          </div>
        ),
        // value: [
        //   {
        //     icon: <Facebook />,
        //     value: formatNumberByType(facebookPlacementsCount, "NUMBER", {
        //       showDecimal: false,
        //     }),
        //   },
        //   {
        //     icon: <Instagram />,
        //     value: formatNumberByType(instagramPlacementsCount, "NUMBER", {
        //       showDecimal: false,
        //     }),
        //   },
        // ],
      },
    ],
    [statData, metaActivity],
  );
  return (
    <div className="border rounded-8 p-8">
      <MetricCardList
        columns={{
          xs: 5,
        }}
        stats={stats}
      />
    </div>
  );
}
