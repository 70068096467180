import { Text } from "src/components/Text/Text";
import { CheckCircle, Placeholder } from "@untitled-ui/icons-react";
import * as Accordion from "@radix-ui/react-accordion";
import Flex from "src/components/Flex/Flex";
import { Icon } from "src/components/Icon/Icon";

export function OnboardingStepper({ activeStepId, steps, setActiveStepId }) {
  const activeStep = steps.find((s) => s.id === activeStepId);

  return (
    <div className="flex border rounded-12 gap-12 p-16">
      <div className="py-16 px-24">
        <Accordion.Root
          type="single"
          collapsible={false}
          defaultValue={steps[0]?.id}
          onValueChange={(step) => setActiveStepId(step)}
          className="max-w-[536px]"
        >
          <Flex flexDirection="column" gap="20px" alignItems="flex-start">
            {steps.map((step, i) => (
              <Accordion.Item
                value={step.id}
                key={i}
                className="pb-20 border-b w-full"
              >
                <Accordion.Trigger className="flex justify-between">
                  <div className="flex items-center gap-12">
                    <Icon
                      icon={step.isChecked ? CheckCircle : Placeholder}
                      iconColor={step.isChecked ? "success" : "default"}
                      size="sm"
                    />
                    <Text
                      size="md"
                      weight="medium"
                      tone={step.isChecked ? "success" : "default"}
                    >
                      <div className={step.isChecked ? "text-green-800" : ""}>
                        {step.title}
                      </div>
                    </Text>
                  </div>
                </Accordion.Trigger>
                <Accordion.Content className="pt-12 px-[26px]">
                  {step.content}
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Flex>
        </Accordion.Root>
      </div>
      <div className="rounded-12 bg-gray-50 w-[478px] h-[468px]">
        <img
          src={activeStep.imageSrc}
          alt={activeStep.imageSrc}
          className="w-full h-full"
        />
      </div>
    </div>
  );
}
