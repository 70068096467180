import styles from "./CreativeForm.module.scss";
import { Form, Formik } from "formik";
import { createCreativeSchema } from "../misc/adTemplateCreateSchema";
import { CreativeCreateForm } from "./CreativeCreateForm";
import { useState } from "react";
import {
  metaPlacementOptions,
  MetaPlacementOptionValue,
} from "../misc/metaPlacementOptions";
import { CreativePreviewPanel } from "./CreativePreviewPanel";

export function CreativeForm({ initialValues, handleSubmit }) {
  const [isCustomizeByPlatformEnabled, setIsCustomizeByPlatformEnabled] =
    useState(false);
  const [selectedPlatformTab, setSelectedPlatformTab] = useState("GLOBAL");
  const [activeAssetIndex, setActiveAssetIndex] = useState(0);
  const [metaSelectedPlacement, setMetaSelectedPlacement] =
    useState<MetaPlacementOptionValue>(metaPlacementOptions[0].value);

  return (
    <div className="w-full max-w-[1170px] mx-auto">
      <Formik
        validationSchema={createCreativeSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <div className="flex justify-center items-center w-full">
              <div className={styles.pageWrapper}>
                <CreativeCreateForm
                  isCustomizeByPlatformEnabled={isCustomizeByPlatformEnabled}
                  setIsCustomizeByPlatformEnabled={
                    setIsCustomizeByPlatformEnabled
                  }
                  metaSelectedPlacement={metaSelectedPlacement}
                  setMetaSelectedPlacement={setMetaSelectedPlacement}
                  selectedPlatformTab={selectedPlatformTab}
                  setSelectedPlatformTab={setSelectedPlatformTab}
                  activeAssetIndex={activeAssetIndex}
                  setActiveAssetIndex={setActiveAssetIndex}
                />
                <CreativePreviewPanel
                  metaSelectedPlacement={metaSelectedPlacement}
                  activeAssetIndex={activeAssetIndex}
                  setActiveAssetIndex={setActiveAssetIndex}
                  values={values}
                />
                {/* <AIChatPanel /> */}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
