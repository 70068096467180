import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export function useSearchState<T = string>(key: string, initialValue: T) {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = (searchParams.get(key) as T) || initialValue;

  const setValue = (newValue: T) => {
    if (newValue) {
      searchParams.set(key, newValue as string);
    } else {
      searchParams.delete(key);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setValue(value as any);
  }, [value]);

  return [value, setValue] as const;
}
