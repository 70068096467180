import * as Accordion from "@radix-ui/react-accordion";
import {
  GoogleManagerAccount,
  useGoogleClientAccountsQuery,
  useGoogleManagerAccountsQuery,
} from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "../../../lib/snakeCaseToTitleCase";
import { Text } from "../../../components/Text/Text";
import { LegacySectionHeading } from "../../global/components/LegacySectionHeading";
import { Spinner } from "src/components/Spinner/Spinner";
import { GoogleClientAccountItemToggle } from "./GoogleClientAccountItemToggle";
import { LineHeading } from "src/modules/global/components/LineHeading";

export function GoogleIntegrationSingle() {
  const { data, loading, error } = useGoogleManagerAccountsQuery();
  return (
    <div className="py-0 px-24">
      <LegacySectionHeading
        heading={"Connected accounts"}
        description="Manage your google connected accounts"
      />

      <div className="flex flex-col gap-16">
        {data &&
          data?.googleManagerAccounts?.map((v) => (
            <GoogleManagerAccountAccordion googleManagerAccount={v} />
          ))}
      </div>
    </div>
  );
}

export function GoogleManagerAccountAccordion({
  googleManagerAccount,
}: {
  googleManagerAccount: GoogleManagerAccount;
}) {
  return (
    <>
      <Accordion.Root
        type="single"
        defaultValue={googleManagerAccount.id}
        collapsible
      >
        <Accordion.Item
          value={googleManagerAccount.id}
          className={"p-16 border rounded-8"}
        >
          <Accordion.Trigger className="w-full flex items-center justify-between">
            <div className="flex items-center gap-8">
              <img
                className="w-[32px] h-[32px] rounded-[50%]"
                src={`https://api.dicebear.com/7.x/shapes/svg?seed=${googleManagerAccount.name}`}
              />
              <div className="flex flex-col items-start">
                <div className="flex items-center gap-8">
                  <Text size="md" weight="medium">
                    {snakeCaseToTitleCase(googleManagerAccount.name)}
                  </Text>
                  <span
                    className={"border-l-2 pl-8 text-color font-medium text-xs"}
                  >
                    <Text size="xs" tone="success">
                      {googleManagerAccount.isDefault ? "Default" : ""}
                    </Text>
                  </span>
                </div>
              </div>
            </div>
          </Accordion.Trigger>
          <Accordion.Content className="pt-16">
            <div className="flex flex-col gap-16">
              <GoogleClientAccountsList managerAccount={googleManagerAccount} />
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </>
  );
}

function GoogleClientAccountsList({
  managerAccount,
}: {
  managerAccount: GoogleManagerAccount;
}) {
  const { data, loading, error } = useGoogleClientAccountsQuery({
    variables: {
      managerAccountId: managerAccount.id,
    },
  });

  if (loading) {
    return (
      <div>
        <Spinner height={"full"} />
      </div>
    );
  }

  const selectedClientAccounts =
    data && data.googleClientAccounts
      ? data.googleClientAccounts.map((v) => ({ ...v, status: "ENABLED" }))
      : [];
  return (
    <div className="flex flex-col gap-14">
      <LineHeading content={"Client accounts"} />
      <div className="flex flex-col gap-16">
        {selectedClientAccounts.map((clientAccount) => (
          <GoogleClientAccountItemToggle
            key={clientAccount.id}
            account={clientAccount}
            showDescription={false}
            selectedClientAccounts={selectedClientAccounts as any}
            setSelectedClientAccounts={(accounts) => {
              console.log(accounts);
            }}
          />
        ))}
      </div>
    </div>
  );
}
