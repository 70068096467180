import { ReactNode, useState } from "react";
import styles from "./AddMedia.module.scss";
import { MediaLoader } from "./MediaLoader";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import { Asset, AssetsQuery } from "src/graphql/generated/schema";
import { FileUploadButton } from "../../../global/components/FileUpload/FileUploadButton";
import toast from "react-hot-toast";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import sortOptions from "../../misc/assetsSortBy";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import { dateFilterOptions } from "src/modules/global/misc/dateFilterOptions";
import {
  MAX_FILE_SIZE_IMAGE,
  MAX_FILE_SIZE_VIDEO,
} from "src/modules/global/misc/maxSizes";
import { FileInputV2 } from "src/modules/global/components/FileInputV2";
import { InputV2 } from "src/components/InputV2/InputV2";
import SearchIcon from "@untitled-ui/icons-react/build/esm/SearchLg";
import { Dialog } from "src/components/Dialog/Dialog";
import { Image01 } from "@untitled-ui/icons-react";

interface IAddMedia {
  multiple: boolean;
  onSelect: (i: Asset[]) => void;
  triggerElementFn?: (props: { onClick: () => void }) => ReactNode;
}

export function AddMedia({ triggerElementFn, multiple, onSelect }: IAddMedia) {
  const [isMediaPickerOpen, setIsMediaPickerOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    dateFilter: "ALL_TIME" as DateFilter,
    take: 25,
  });
  const [selectedSortBy, setSelectedSortBy] = useState({
    direction: "DESC" as "DESC" | "ASC",
    field: "CREATED_AT" as const,
  });
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);

  function handleSubmit() {
    onSelect(selectedAssets);
    setIsMediaPickerOpen(false);
    // reset selected items
    setSelectedAssets([]);
  }

  function onClickTrigger() {
    setIsMediaPickerOpen(true);
  }

  const trigger = triggerElementFn ? (
    triggerElementFn({ onClick: onClickTrigger })
  ) : (
    <FileInputV2 onClick={onClickTrigger} title="Upload an Image or Video" />
  );

  return (
    <div className={styles.addMediaWrapper}>
      <Dialog
        maxWidth="1200px"
        open={isMediaPickerOpen}
        onOpenChange={setIsMediaPickerOpen}
        trigger={trigger}
      >
        <Dialog.Header
          icon={Image01}
          title="Select Media"
          subtitle="You can select media either from media library or upload your own media for the Ad"
        />

        <div className={styles.filters}>
          <div className={styles.filterSectionLeft}>
            <InputV2
              value={search}
              onChange={(value) => setSearch(value)}
              icon={SearchIcon}
            />
            <SortByDropdown
              setSortBy={setSelectedSortBy}
              sortBy={selectedSortBy}
              sortOptions={sortOptions}
            />
            <DateFilterDropdown
              dateFilter={selectedFilters.dateFilter}
              setDateFilter={(dateFilterValue) => {
                setSelectedFilters({
                  ...selectedFilters,
                  dateFilter: dateFilterValue,
                });
              }}
              filterOptions={dateFilterOptions}
            />
          </div>

          {/*
              <Button color="subdued" size="micro" style="outline">
              <div className={styles.uploadButtonContent}>
              <BackupIcon width={20} height={20} />
              Upload Media
              </div>
              </Button>
          */}

          <FileUploadButton
            onUploaded={() => {
              toast.success("Created Asset successfully");
            }}
            maxVideoFileSizeInBytes={MAX_FILE_SIZE_VIDEO}
            maxImageFileSizeInBytes={MAX_FILE_SIZE_IMAGE}
            color="subdued"
            style="outline"
            size="micro"
          />
        </div>
        <div className={styles.mediaContent}>
          <MediaLoader
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
            multiple={multiple}
            filters={selectedFilters}
            search={search}
            sortBy={selectedSortBy}
            minWidth={500}
            minHeight={500}
          />
        </div>

        <Dialog.Footer
          actionButtonText={"Select Media"}
          onAction={handleSubmit}
          dismissButtonText={"Cancel"}
          // loading={false}
        />
      </Dialog>
    </div>
  );
}
