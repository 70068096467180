import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import styles from "./ExistingPostCard.module.scss";
import RefreshCcw01 from "@untitled-ui/icons-react/build/esm/RefreshCcw01";
import Trash03 from "@untitled-ui/icons-react/build/esm/Trash03";
import { useFormikContext } from "formik";
import { CreativeCreateTypesV2 } from "../misc/creativeCreateTypesV2";
import Heart from "@untitled-ui/icons-react/build/esm/Heart";
import MessageAlertCircle from "@untitled-ui/icons-react/build/esm/MessageCircle02";

export function ExistingPostCard({ onReplace, onDelete }) {
  const { values } = useFormikContext<CreativeCreateTypesV2>();
  const existingPost = values.meta.existingPost;

  return (
    <div className={styles.existingPostCard}>
      <div className={styles.postPreview}>
        {existingPost.type === "VIDEO" ? (
          <video autoPlay muted={true} loop={true} className={styles.thumbnail}>
            <source src={existingPost.url} />
          </video>
        ) : (
          <img
            className={styles.thumbnail}
            alt={existingPost.caption}
            src={existingPost.url}
          />
        )}
      </div>

      <div className={styles.moreInfo}>
        <span className={styles.copy}>{existingPost.caption}</span>
        <span className={styles.likesAndShares}>
          <span className={styles.likes}>
            <Heart width={13} height={13} />
            {existingPost.likeCount}
          </span>
          <span className={styles.comment}>
            <MessageAlertCircle width={13} height={13} />
            {existingPost.commentsCount}
          </span>
        </span>
      </div>

      <div className={styles.actions}>
        <div className={styles.replaceButtonWrapper}>
          <ButtonV2
            variant="plain"
            leftIcon={RefreshCcw01}
            onClick={onReplace}
          />
        </div>
        <ButtonV2 variant="plain" leftIcon={Trash03} onClick={onDelete} />
      </div>
    </div>
  );
}
