import { useState } from "react";
import { Wizard } from "src/components/Wizard/Wizard";
import {
  GoogleIntegrationStatus,
  useGoogleClientAccountEnableMutation,
  useGoogleIntegrationDefaultSettingsUpsertMutation,
  useGoogleManagerAccountEnableMutation,
} from "src/graphql/generated/schema";
import { ConnectWithGoogle } from "./ConnectWithGoogle";
import { DefaultAdAccountSettings } from "./DefaultAdAccountSettings";
import { GoogleAdAccountSelector } from "./GoogleAdAccountSelector";
import { GoogleClientAccountSelector } from "./GoogleClientAccountSelector";

export interface FormData {
  status: GoogleIntegrationStatus;
  accounts?: AccountsEntity[] | null;
  defaultSettings: DefaultSettings;
}

export interface AccountsEntity {
  id: string;
  name: string;
  enabled: boolean;
  clientAccounts?: ClientAccountsEntity[] | null;
}

export interface ClientAccountsEntity {
  id: string;
  name: string;
  enabled: boolean;
}

export interface DefaultSettings {
  managerId: string;
  clientAccountId: string;
}

export function GoogleIntegrationWizard({
  status,
}: {
  status: GoogleIntegrationStatus;
}) {
  const [activeStep, setActiveStep] = useState(
    status === "NOT_CONNECTED" ? 0 : 1,
  );
  const [clientAccountEnableFunc, { loading: clientAccountEnableLoading }] =
    useGoogleClientAccountEnableMutation();
  const [managerAccountEnableFunc, { loading: managerAccountEnableLoading }] =
    useGoogleManagerAccountEnableMutation();
  const [defaultSettingsUpsertFunc, { loading: defaultSettingsUpsertLoading }] =
    useGoogleIntegrationDefaultSettingsUpsertMutation();
  return (
    <div className="max-w-[660px]">
      <Wizard
        initialData={
          {
            status: status,
          } as FormData
        }
        onStepComplete={async (nextStep: number, data: FormData) => {
          if (nextStep === 4) {
            console.log({
              data,
            });

            for (const account of data.accounts) {
              await managerAccountEnableFunc({
                variables: {
                  googleResourceId: account.id,
                },
              });

              for (const clientAccount of account.clientAccounts) {
                await clientAccountEnableFunc({
                  variables: {
                    googleManagerAccountId: account.id,
                    googleResourceId: clientAccount.id,
                  },
                });
              }
            }

            await defaultSettingsUpsertFunc({
              variables: {
                googleClientAccountId: data.defaultSettings.clientAccountId,
                googleManagerAccountId: data.defaultSettings.managerId,
              },
            });

            window.location.reload();
            return;
          }
          setActiveStep(nextStep);
        }}
        initialActiveStepIndex={status === "NOT_CONNECTED" ? 0 : 1}
        onResetField={() => ({})}
      >
        <ConnectWithGoogle />
        {activeStep > 0 && <GoogleAdAccountSelector />}
        {activeStep > 1 && <GoogleClientAccountSelector />}
        {activeStep > 2 && (
          <DefaultAdAccountSettings
            loading={
              clientAccountEnableLoading ||
              managerAccountEnableLoading ||
              defaultSettingsUpsertLoading
            }
          />
        )}
      </Wizard>
    </div>
  );
}
