import { Formik } from "formik";
import { useContext } from "react";
import { Form, useSearchParams } from "react-router-dom";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { Logo } from "src/components/Logo/Logo";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Text } from "src/components/Text/Text";
import AuthContext from "src/stores/AuthContext";
import * as Yup from "yup";
import styles from "./OnboardingPage.module.scss";
import onboardingGrid from "../assets/onboarding-grid.png";

const signupFormSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  organizationName: Yup.string().required("Organization is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(72, "Password must be at most 72 characters"),
});

export function OnboardingPage() {
  const { signup, user, logout } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("code");
  const shopifyShopName = searchParams.get("shopifyShopName");
  const tokenId = searchParams.get("tokenId");

  return (
    <div className="h-screen grid grid-rows-[auto_1fr_auto] py-20">
      <div className="p-4 flex items-center justify-center">
        <Logo type="full" style="black" size="sm" />
      </div>
      <div className="flex items-center justify-center">
        <div className="flex w-full max-w-[1100px] h-[590px]">
          <div className="w-full p-12 md:w-1/2 md:px-[82px] md:py-[52px] flex flex-col justify-center border rounded-16 md:border-r-0 md:rounded-r-[0] space-y-24">
            <SectionHeader
              title="Signup for Macro"
              description="Get started by creating your free account"
              size="large"
            />
            <Formik
              enableReinitialize
              initialValues={{
                email: "",
                password: "",
                // confirmPassword: "",
                inviteCode,
                organizationName: "",
                firstName: "",
                lastName: "",
              }}
              validationSchema={signupFormSchema}
              onSubmit={async (values, { setSubmitting, setFieldError }) => {
                try {
                  // save shopify integration credentials in local storage
                  if (shopifyShopName && tokenId) {
                    localStorage.setItem(
                      "shopifyIntegration",
                      JSON.stringify({ shopifyShopName, tokenId }),
                    );
                  }
                  await signup({
                    email: values.email,
                    password: values.password,
                    organizationName: values.organizationName,
                    inviteCode,
                    firstName: values.firstName,
                    lastName: values.lastName,
                  });

                  // navigate("/onboarding/about-you");
                } catch (err) {
                  if (err.code) {
                    setFieldError(err.field.at(-1), err.message);
                  }
                  console.log(err);
                }
              }}
            >
              {({ isSubmitting, handleSubmit }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className={styles.formFields}>
                      <div className={styles.inputs}>
                        <div className={styles.fieldRow}>
                          <InputFormikV2
                            size="small"
                            type="text"
                            label="First Name"
                            placeholder="Alan"
                            name="firstName"
                          />
                          <InputFormikV2
                            size="small"
                            type="text"
                            label="Last Name"
                            placeholder="Turing"
                            name="lastName"
                          />
                        </div>

                        <InputFormikV2
                          size="small"
                          type="text"
                          label="Organization"
                          placeholder="Acme Inc."
                          name="organizationName"
                        />
                        <InputFormikV2
                          size="small"
                          type="email"
                          label="Email"
                          placeholder="alan.turing@gmail.com"
                          name="email"
                        />
                        <InputFormikV2
                          size="small"
                          name="password"
                          label="Password"
                          placeholder="••••••••••••"
                          type="password"
                        />
                        {/* <InputFormikV2
                      size="small"
                      name="confirmPassword"
                      label="Confirm Password"
                      placeholder="••••••••••••"
                      type="password"
                    /> */}
                      </div>

                      <ButtonV2 type="submit" loading={isSubmitting}>
                        Create Account
                      </ButtonV2>
                    </div>

                    <div className="flex flex-col items-center gap-2 text-[0.6875rem] text-[#a09f9f]">
                      <span>
                        By inserting your email you confirm you agree to Macro
                        contacting you about our product and services. Find out
                        more about how we use data in our{" "}
                        <a href="#">privacy policy</a>.
                      </span>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>

          <div
            // style={{ backgroundImage: `url('${onboardingGrid}')` }}
            className="hidden w-1/2 bg-gray-100 gap-14 p-56 md:flex flex-col justify-center border rounded-r-16 bg-center bg-no-repeat bg-cover"
          >
            <Text as="h1" size="xl" weight="medium">
              Welcome to Macro
            </Text>
            <div className="space-y-12">
              <Text as="p" size="sm" tone="subdued">
                <span className="leading-6">
                  Macro is a powerful cross-channel marketing platform. It
                  enables you to create, manage, and optimize campaigns across
                  multiple channels seamlessly.
                </span>
              </Text>
              <Text as="p" size="sm" tone="subdued">
                <span className="leading-6">
                  With Macro, you can reach your audience through email, social
                  media, SMS, and more, all from a single, intuitive interface.
                </span>
              </Text>
              <Text as="p" size="sm" tone="subdued">
                <span className="leading-6">Let's begin.</span>
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 flex items-center justify-center gap-16">
        <Text as="span" size="md" tone="subdued">
          © 2024 Codeword Pvt. Ltd.
        </Text>
        <ButtonV2 variant="link">Support</ButtonV2>
        <ButtonV2
          variant="link"
          {...(user ? { onClick: logout } : { to: "/login" })}
        >
          {user ? "Logout" : "Login"}
        </ButtonV2>
      </div>
    </div>
  );
}
