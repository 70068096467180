import React, { Fragment, SVGAttributes } from "react";
import { Listbox, Transition } from "@headlessui/react";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import { Label } from "../Label/Label";
import classNames from "classnames";
import styles from "./SelectV2.module.scss";
import { Link } from "react-router-dom";

interface Option<T extends string | number> {
  label: string;
  value: T;
  icon?: React.FC<SVGAttributes<any>>;
  disabled?: boolean;
}

interface OptionWithAction {
  label: string;
  onClick: () => void;
  icon?: React.ReactNode;
}

export interface ISelect<T extends string | number> {
  options: Option<T>[];
  stickyAction?: OptionWithAction;
  emptyState?: {
    title: string;
    description: string;
    action?: React.ReactNode;
    to?: string;
  };
  size?: "small" | "medium" | "large";
  label?: React.ReactNode;
  icon?: React.FC<SVGAttributes<any>>;
  value: string | number;
  onChange: (value: any) => void;
  onBlur?: () => void;
  disabled?: boolean;
  error?: React.ReactNode;
  placeholder?: string;
  name?: string;
  isFixedWidth?: boolean;
}

export default function SelectV2<T extends string | number>(props: ISelect<T>) {
  let {
    options,
    label,
    value,
    stickyAction,
    size = "medium",
    icon: Icon,
    onChange,
    isFixedWidth = false,
    disabled,
    error,
    onBlur,
    name,
    placeholder,
    emptyState = {
      title: "Items not found",
      description: "No items available to select",
    },
  } = props;
  const selectedOption = value ? options.find((o) => o.value === value) : null;
  const isEmptyList = options.length === 0;
  const iconSize = size === "large" ? 20 : size === "medium" ? 18 : 16;

  const LabelIcon = selectedOption?.icon ? selectedOption?.icon : Icon;

  // override the icon of select if there is a selected option with icon
  if (selectedOption?.icon) {
    Icon = selectedOption?.icon;
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.fullWidth]: !isFixedWidth,
      })}
    >
      {label && <Label label={label} />}
      <Listbox
        value={value}
        onChange={onChange}
        name={name}
        disabled={disabled}
      >
        <div className={styles.select}>
          <Listbox.Button
            className={({ open }) =>
              classNames(styles.selectInput, {
                [styles.selectInputDisabled]: disabled,
                [styles.selectInputExpanded]: open,
                [styles.selectInputError]: error,
                [styles.selectInputSmall]: size === "small",
                [styles.selectInputMedium]: size === "medium",
                [styles.selectInputLarge]: size === "large",
              })
            }
          >
            {({ open }) => (
              <>
                <span className={styles.selectInputContent}>
                  {!!LabelIcon && (
                    <span
                      className={classNames(styles.icon, {
                        [styles.iconDisabled]: disabled,
                        [styles.iconWhenSelected]: selectedOption,
                      })}
                    >
                      <Icon width={iconSize} height={iconSize} />
                    </span>
                  )}
                  {selectedOption?.label ? (
                    <span
                      className={classNames(styles.selectedLabelInButton, {
                        [styles.selectedLabelInButtonDisabled]: disabled,
                      })}
                    >
                      {selectedOption?.label}
                    </span>
                  ) : (
                    <span className={styles.placeholder}>{placeholder}</span>
                  )}
                </span>
                <span
                  className={classNames(styles.chevronDown, {
                    [styles.chevronDownExpanded]: open,
                    [styles.chevronDownDisabled]: disabled,
                  })}
                >
                  <ChevronDown width={iconSize} height={iconSize} />
                </span>
              </>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave={styles.leave}
            leaveFrom={styles.leaveFrom}
            leaveTo={styles.leaveTo}
          >
            <Listbox.Options
              onBlur={onBlur}
              className={classNames(styles.selectOptions, {
                [styles.fullWidth]: !isFixedWidth,
              })}
            >
              {isEmptyList ? (
                <EmptyOptions
                  title={emptyState.title}
                  description={emptyState.description}
                  action={emptyState.action}
                  to={emptyState.to}
                />
              ) : (
                options.map((item, idx) => (
                  <SelectOption key={idx} item={item} />
                ))
              )}

              {stickyAction && <StickyAction item={stickyAction} />}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {error && (
        <p className={classNames(styles.statusText, styles.statusTextError)}>
          {error}
        </p>
      )}
    </div>
  );
}

function EmptyOptions({
  title,
  description,
  action,
  to = "",
}: {
  title: string;
  description: string;
  to?: string;
  action?: React.ReactNode;
}) {
  return (
    <Link className={styles.emptyStateContainer} to={to}>
      <div className={styles.emptyStateContent}>
        <div className={styles.emptyStateTitle}>{title}</div>
        <div className={styles.emptyStateDescription} title={description}>
          {description}
        </div>
      </div>
      {action && <div className={styles.emptyStateAction}>{action}</div>}
    </Link>
  );
}

function StickyAction({ item }: { item: OptionWithAction }) {
  return (
    <button
      type="button"
      className={styles.stickyAction}
      onClick={item.onClick}
    >
      <div>{item.label}</div>
      {item.icon}
    </button>
  );
}

function SelectOption<T extends string | number>({
  item,
}: {
  item: Option<T>;
}) {
  const Icon = item.icon;

  return (
    <Listbox.Option
      className={({ selected, active }) =>
        classNames(styles.selectOption, {
          [styles.activeSelectOption]: selected,
          [styles.hoveredSelectOption]: active,
          [styles.selectOptionWithIcon]: item.icon,
        })
      }
      disabled={item.disabled}
      value={item.value}
    >
      {Icon && <Icon width={16} height={16} />}
      <span className={styles.selectOptionText}>{item.label}</span>
    </Listbox.Option>
  );
}
