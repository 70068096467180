import { ErrorHandler } from "src/components/ErrorHandler";
import { useCampaignsLazyQuery } from "src/graphql/generated/schema";
import CreatableSelect from "react-select/async-creatable";
import { Label } from "src/components/Label/Label";
import { getIn, useFormikContext } from "formik";
import { FormikError } from "src/components/FormikError/FormikError";

interface IActivityCampaignSelector {
  campaignName: string;
  value: string;
  defaultValue?: { value: string; label: string };
  onChange: (i: string) => void;
  onCreateNew: (i: string) => void;
  formKey?: string;
}

export function ActivityCampaignSelector({
  campaignName,
  value,
  defaultValue,
  onChange,
  onCreateNew,
  formKey,
}: IActivityCampaignSelector) {
  const [fetchCampaigns, { error }] = useCampaignsLazyQuery();
  const { errors, touched } = useFormikContext();

  async function handleSearch(searchQuery: string) {
    const payload = await fetchCampaigns({
      variables: {
        filters: {
          ...(searchQuery && { name: searchQuery }),
          // we need all campaigns here
          // FIXME: refetch more if the selected Id is not shown here
          limit: 20,
        },
        sortBy: {
          direction: "DESC",
          field: "CREATED_AT",
        },
      },
    });

    return payload.data.campaigns.edges.map((a) => ({
      label: a.node.name,
      value: a.node.id,
    }));
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <Label label="Select campaign">
      <CreatableSelect
        placeholder="Select or type to create campaign"
        // idk why but it showing type working yet still working
        // goes away if you use options instead of value but docs say use value
        // and we don't even need nested options here
        // @ts-ignore
        loadOptions={handleSearch}
        isClearable
        defaultValue={defaultValue}
        onChange={(option: any) => {
          if (option?.__isNew__) {
            onCreateNew(option.value);
            return;
          }

          onChange(option?.value);
        }}
        defaultOptions
      />
      {formKey && (
        <FormikError formikError={errors} fieldName={formKey}></FormikError>
      )}
    </Label>
  );
}
