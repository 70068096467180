import styles from "./CheckboxOptionGroup.module.scss";
import { Disclosure } from "@headlessui/react";
import { Checkbox } from "../Checkbox/Checkbox";
import { ChevronDown } from "@untitled-ui/icons-react";
import { HelpCircle } from "@untitled-ui/icons-react";
import Tooltip from "../Tooltip/Tooltip";
import classNames from "classnames";

interface ICheckboxOptionGroup {
  title: string;
  variant: "md" | "lg";
  subtitle?: string;
  icon?: React.FC<React.SVGAttributes<any>>;
  isChecked?: boolean;
  onChange?: (newValue: boolean) => void;
  options: {
    required?: boolean;
    value: string;
    title: string;
    helpText?: string;
    onChange: (newValue: boolean) => void;
    onHover?: () => void;
    checked: boolean;
    disabled?: boolean;
  }[];
}

export function CheckboxOptionGroup({
  title,
  subtitle,
  options,
  variant = "md",
  isChecked,
  onChange,
  icon,
}: ICheckboxOptionGroup) {
  const Icon = icon;
  const iconSize = variant === "md" ? 16 : 32;
  return (
    <div className={styles.wrapper}>
      <Disclosure>
        <div className={classNames(styles.header, styles[variant])}>
          <div className={styles.headerLeftSection}>
            <Icon height={iconSize} width={iconSize} />
            <div className={styles.headerCopy}>
              <h3 className={styles.headerTitle}>{title}</h3>
              {subtitle && variant === "lg" && (
                <h4 className={styles.headerSubtitle}>{subtitle}</h4>
              )}
            </div>
          </div>

          <div className={styles.headerRightSection}>
            {onChange && (
              <div className={styles.checkboxWrapper}>
                <Checkbox checked={isChecked} onChange={onChange} value="" />
              </div>
            )}
            <Disclosure.Button>
              <ChevronDown width={14} height={14} />
            </Disclosure.Button>
          </div>
        </div>
        <Disclosure.Panel>
          <div className={styles.optionListWrapper}>
            <div className={styles.sectionSeperator}></div>
            <div className={styles.optionList}>
              {options.map((option) => (
                <div className={styles.optionItem} onMouseOver={option.onHover}>
                  <span className={styles.optionTitle}>
                    {option.title}
                    {option.required && (
                      <span className={styles.optionIsRequired}>
                        (Required)
                      </span>
                    )}
                    {option.helpText && (
                      <Tooltip content={option.helpText}>
                        <HelpCircle width={16} height={16} />
                      </Tooltip>
                    )}
                  </span>
                  <Checkbox
                    disabled={option.disabled}
                    checked={option.checked}
                    onChange={option.onChange}
                    value={option.value}
                  />
                </div>
              ))}
            </div>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
}
