import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import {
  Coins03,
  CreditCardRefresh,
  Settings02,
  Wallet02,
} from "@untitled-ui/icons-react";
import MetaIcon from "src/icons/meta.svg?react";
import Flex from "src/components/Flex/Flex";
import DatePicker, {
  DateFilterEnum,
} from "src/components/Datepicker/Datepicker";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { StatsCardWidget } from "src/components/StatsCardWidget/StatsCardWidget";
import { DateRange } from "react-day-picker";
import { useMemo, useState } from "react";
import { getDateFilterFromFilterEnum } from "src/modules/global/misc/dateFilterUtils";
import { useGrowAggregatedStats } from "src/modules/global/functions/useGrowAggregatedStats";
import { useClientQuery } from "src/graphql/generated/schema";
import { useActiveClientGet } from "src/modules/global/functions/activeClient";
import { getWidgetPropsBasedAnalyticsCategory } from "src/modules/global/misc/analyticsCategoryMapping";
import { Spinner } from "src/components/Spinner/Spinner";

interface IBusinessOverviewWidget {
  selectedCurrencyCode: string;
}

const defaultValue = "THIS_MONTH" as const;

export function BusinessOverviewWidget({
  selectedCurrencyCode,
}: IBusinessOverviewWidget) {
  const { gte: from, lte: to } = getDateFilterFromFilterEnum(defaultValue);
  const activeClient = useActiveClientGet();
  const [selectedDate, setSelectedDate] = useState<DateRange>({
    from,
    to,
  });

  const { data: growAggregatedStats, loading } = useGrowAggregatedStats({
    dateFrom: selectedDate.from,
    dateTo: selectedDate.to,
    others: {
      insightType: "ACTIVITY_INSIGHT",
    },
  });

  console.log({ selectedDate });

  const widgetProps = getWidgetPropsBasedAnalyticsCategory(
    activeClient.analyticsCategory,
  );

  const stats = widgetProps.map((v) => ({
    title: v.title,
    valueIcon: v.icon,
    value: formatNumberByType(growAggregatedStats?.[v.valueKey], v.valueType, {
      showDecimal: true,
      ...(v.valueType === "CURRENCY" && {
        selectedCurrencyCode: selectedCurrencyCode,
      }),
    }),
  }));

  return (
    <StatsCardWidget
      title={"Business Overview"}
      stats={stats}
      loading={loading}
      action={
        <DatePicker onChange={setSelectedDate} defaultValue={defaultValue} />
      }
    />
  );
}
