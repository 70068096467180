import EmailIcon from "../../../icons/email.svg?react";
import MetaIcon from "../../../icons/meta.svg?react";
import WhatsappIcon from "../../../icons/whatsapp.svg?react";
import GoogleAdsIcon from "../../../icons/google-ads.svg?react";

type PlatformID = "META" | "GOOGLE" | "EMAIL" | "WHATSAPP";

interface IPlatformIcon {
  platformId: PlatformID;
}

export function getPlatformIcon({ platformId }: IPlatformIcon) {
  switch (platformId) {
    case "EMAIL":
      return EmailIcon;
    case "GOOGLE":
      return GoogleAdsIcon;
    case "META":
      return MetaIcon;
    case "WHATSAPP":
      return WhatsappIcon;
  }
}
