import { gql } from "@apollo/client";

export const META_INTEGRATION_DEFAULT_SETTING = gql`
  query metaIntegrationDefaultSettings {
    hasMultipleIntegrationSettingsOptions
    metaIntegrationDefaultSettings {
      id
      metaAdAccountId
      MetaAdAccount {
        id
        metaName
        status
        metaAccountId
        enabled
      }
      metaPixelId
      MetaPixel {
        id
        name
        enabled
        metaPixelId
      }
      metaPageId
      MetaPage {
        id
        name
        metaPageConnectedInstagramBusinessId
        enabled
        metaPageId
        Business {
          name
          profilePicture
        }
      }
      metaInstagramAccountId
      MetaInstagramAccount {
        id
        name
        enabled
        metaInstagramId
      }
      conversionDomain
    }
  }
`;
