import { ErrorHandler } from "src/components/ErrorHandler";
import {
  SideDrawer,
  SideDrawerTitle,
} from "src/modules/global/components/SideDrawer";
import { Spinner } from "../../../../components/Spinner/Spinner";
import {
  useCreativeTemplateQuery,
  CreativeTemplateQuery,
} from "../../../../graphql/generated/schema";
import { Text } from "src/components/Text/Text";
import { ApolloError } from "@apollo/client";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ChevronLeft } from "@untitled-ui/icons-react";
import { ISmartphone } from "src/components/Smartphone/Smartphone";
import { useState } from "react";
import { AdTemplateSingleDetails } from "./AdTemplateSingleDetails";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { transformAdTemplateToLineItems } from "src/modules/global/functions/transformAdTemplateToLineItems";
import Tooltip from "src/components/Tooltip/Tooltip";
import { CreativePreviewPanel } from "../CreativePreviewPanel";
import { generateCreativeInputFromCreativeTemplateQuery } from "../../misc/generateCreativeInputFromCreativeTemplateQuery";
import {
  AdTemplateAggregateType,
  useAdTemplateAggregatedStats,
} from "src/modules/global/functions/useAdTemplateAggregatedStats";

interface ICreateItemSlideOver {
  adTemplateId: string;
  isOpen: boolean;
  onClose: () => void;
  trigger: React.ReactNode;
}

export function CreativeItemSlideOver({
  adTemplateId,
  isOpen,
  onClose,
  trigger,
}: ICreateItemSlideOver) {
  const { data, loading, error } = useCreativeTemplateQuery({
    variables: {
      id: adTemplateId,
    },
  });
  const {
    data: adTemplateAggregatedStats,
    loading: adTemplateAggregatedStatsLoading,
  } = useAdTemplateAggregatedStats(adTemplateId, {
    dateFrom: null,
    dateTo: null,
  });

  return (
    <SideDrawer
      isOpen={isOpen}
      onIsOpenChange={onClose}
      trigger={trigger}
      width={"80%"}
    >
      <div className="flex flex-col h-full overflow-auto">
        <SideDrawerTitle
          className="p-16 flex justify-between items-center border-b"
          autoFocus={false}
        >
          <div className="flex gap-4 items-center">
            <ButtonV2
              leftIcon={ChevronLeft}
              onClick={onClose}
              variant="plain"
            />
            <Text font="redhat" size="xl" weight="medium">
              {loading ? "" : data?.creativeTemplate?.name}
            </Text>
          </div>
          {data &&
          data.creativeTemplate &&
          data.creativeTemplate.MetaActivityAdTemplate.adsCount === 0 ? (
            <ButtonV2
              variant="outline"
              size="small"
              to={`/library/ad-library/${adTemplateId}/edit`}
            >
              Edit Template
            </ButtonV2>
          ) : (
            <Tooltip
              content={"Cannot edit a template with existing ads"}
              align="end"
            >
              <ButtonV2 variant="outline" size="small" disabled={true}>
                Edit Template
              </ButtonV2>
            </Tooltip>
          )}
        </SideDrawerTitle>
        <AdTemplateSingleContent
          adTemplateAggregatedStatsLoading={adTemplateAggregatedStatsLoading}
          adTemplateAggregatedStats={adTemplateAggregatedStats}
          data={data}
          loading={loading}
          error={error}
        />
      </div>
    </SideDrawer>
  );
}

function AdTemplateSingleContent({
  loading,
  adTemplateAggregatedStatsLoading,
  adTemplateAggregatedStats,
  error,
  data,
}: {
  data: CreativeTemplateQuery | undefined;
  loading: boolean;
  adTemplateAggregatedStatsLoading: boolean;
  adTemplateAggregatedStats: AdTemplateAggregateType;
  error: ApolloError | undefined;
}) {
  const [previewType, setPreviewType] = useState<"STORY" | "FEED">("STORY");
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);

  if (loading || adTemplateAggregatedStatsLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const adTemplate = data?.creativeTemplate;

  const metaAdTemplate = {
    ...adTemplate.MetaActivityAdTemplate,
    stats: null,
    // stats: adTemplateAggregatedStats,
  };

  const smartphonePreview = transformAdTemplateToLineItems(
    metaAdTemplate as any,
  );

  const previewList: ISmartphone[] = [
    {
      variant: "stories",
      preview: smartphonePreview,
      type: "instagram",
      adType: metaAdTemplate.adType,
      accountName: "macrostack",
      primaryText: metaAdTemplate.primaryText,
    },
    {
      variant: "feed",
      preview: smartphonePreview,
      type: "instagram",
      adType: metaAdTemplate.adType,
      accountName: "macrostack",
      primaryText: metaAdTemplate.primaryText,
    },
  ];

  const selectedPreview = previewList.find(
    (p) => p.variant === previewType.toLowerCase(),
  );

  const creativeInput = generateCreativeInputFromCreativeTemplateQuery({
    creativeTemplateData: data,
  });

  return (
    <div className="flex h-full">
      <div className="w-[60%] h-full border-r px-16 py-16">
        <AdTemplateSingleDetails adTemplate={metaAdTemplate as any} />
      </div>
      <div className="flex-1 flex flex-col justify-center items-center gap-32 sticky top-0">
        <div>
          <CreativePreviewPanel
            metaSelectedPlacement={
              previewType === "FEED" ? "META_FEED" : "META_STORY"
            }
            activeAssetIndex={selectedAssetIndex}
            setActiveAssetIndex={setSelectedAssetIndex}
            values={creativeInput}
          />
        </div>

        <div>
          <TabsV2
            onTabChange={(v: any) => setPreviewType(v)}
            tabs={[
              {
                label: "Story",
                value: "STORY",
              },
              {
                label: "Feed",
                value: "FEED",
              },
            ]}
            selectedTab={previewType}
          />
        </div>
      </div>
    </div>
  );
}
