import format from "date-fns/format";
import {
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import { CreativeTemplatesQuery } from "src/graphql/generated/schema";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";

export function CreativesOverviewTable({
  adTemplates,
}: {
  adTemplates: CreativeTemplatesQuery["creativeTemplates"]["edges"][0]["node"][];
}) {
  return (
    <>
      {adTemplates.map((adTemplate, i) => {
        const firstLineItem = adTemplate.MetaActivityAdTemplate?.LineItems?.[0];

        return (
          <TableRow id={adTemplate.id}>
            <TablePrimaryCell
              imageSrc={
                firstLineItem?.PostAsset?.thumbnailUri ||
                firstLineItem?.PostAsset.uri ||
                "https://placehold.co/100/100"
              }
              title={adTemplate.name}
              subtitle={format(new Date(adTemplate.createdAt), "dd MMM yyyy")}
            />
            <TableCell>
              <BadgeV2
                label={
                  adTemplate.MetaActivityAdTemplate?.adsCount > 0
                    ? "In use"
                    : "Not in use"
                }
                color={
                  adTemplate.MetaActivityAdTemplate?.adsCount > 0
                    ? "green"
                    : "gray"
                }
              />
            </TableCell>
            <TableCell>{adTemplate.MetaActivityAdTemplate?.adsCount}</TableCell>
            <TableCell>-</TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
