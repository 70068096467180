import React from "react";
import styles from "./ColorInput.module.scss";
import { Label } from "../Label/Label";
import classNames from "classnames";

export interface IColorInput {
  value: string;
  disabled?: boolean;
  label?: React.ReactNode;
  isFixedWidth?: boolean;
  placeholder?: string;
  name?: string;
  autoFocus?: boolean;
  helpText?: string;
  error?: React.ReactNode;
  size?: "small" | "large" | "medium";
  onChange: (value: string) => void;
  onBlur?: (e: any) => void;
}

export function ColorInput({
  value,
  onChange,
  disabled,
  label,
  isFixedWidth = false,
  placeholder,
  name,
  autoFocus,
  helpText,
  error,
  size = "medium",
  onBlur,
}: IColorInput) {
  const isValidHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value);

  return (
    <div className={classNames(styles.inputWithLabel)}>
      {label && <Label label={label} helpText={helpText} />}
      <div
        className={classNames(styles.inputWrapper, {
          [styles.inputFixedWidth]: isFixedWidth,
          [styles.inputFullWidth]: !isFixedWidth,
          [styles.inputSmall]: size === "small",
          [styles.inputMedium]: size === "medium",
          [styles.inputLarge]: size === "large",
          [styles.inputError]: error,
          [styles.inputDisabled]: disabled,
        })}
      >
        <div
          style={{ backgroundColor: isValidHexColor ? value : "#000" }}
          className={styles.colorPreview}
        />

        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type="text"
          className={styles.input}
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          autoFocus={autoFocus}
          onBlur={onBlur}
        />
      </div>

      {error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
}
