import { Label } from "src/components/Label/Label";
import { TitleWithThumbnail } from "src/modules/global/components/TitleWithThumbnail";
import UserCheck01 from "@untitled-ui/icons-react/build/esm/UserCheck01";
import BarLineChart from "@untitled-ui/icons-react/build/esm/BarLineChart";
import ShoppingBag03 from "@untitled-ui/icons-react/build/esm/ShoppingBag03";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import BookClosed from "@untitled-ui/icons-react/build/esm/BookClosed";
import File02 from "@untitled-ui/icons-react/build/esm/File02";
import { Text } from "src/components/Text/Text";
import { Icon } from "src/components/Icon/Icon";
import { Flash, HelpCircle } from "@untitled-ui/icons-react";

export function GoogleIntegrationSidePanel() {
  return (
    <div className={"border-l border-[--color-border] px-12 h-fit"}>
      <div className={"text-sm font-medium pb-20"}>About this integration</div>
      <div className="flex flex-col gap-32">
        <FeatureUnlocked />
        <HelpAndSupport />
      </div>
    </div>
  );
}

function FeatureUnlocked() {
  const featureList = [
    {
      label: "Manage contacts and segment",
      icon: UserCheck01,
    },
    {
      label: "Metrics & Analytics",
      icon: BarLineChart,
    },
    {
      label: "Product catalogue",
      icon: ShoppingBag03,
    },
  ];
  return (
    <div className={"flex flex-col gap-16"}>
      <div className="flex gap-8 justify-center align-items">
        <Icon size="sm" icon={Flash} />
        <Label label={"Feature unlocked"} />
      </div>
      <div className={"flex flex-col gap-12"}>
        {featureList.map((v) => (
          <div className={"flex gap-8"}>
            <Icon size="sm" icon={v.icon} />
            <Text size="sm">{v.label}</Text>
          </div>
        ))}
      </div>
    </div>
  );
}

function HelpAndSupport() {
  const itemList = [
    {
      label: "Documentation",
      icon: BookClosed,
      link: "#",
    },
    {
      label: "Terms of service",
      icon: File02,
      link: "#",
    },
  ];
  return (
    <div className="flex flex-col gap-16">
      <div className="flex gap-8 justify-center align-items">
        <Icon size="sm" icon={HelpCircle} />
        <Label label="Help & support" />
      </div>
      <div className="flex flex-col gap-12">
        {itemList.map((v) => (
          <div>
            <ButtonV2
              variant="link"
              leftIcon={v.icon}
              children={v.label}
              to={v.link}
              target="_blank"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
