import { MetaMediaQuery } from "src/graphql/generated/schema";
import styles from "./ExistingPostSelectableItem.module.scss";
import classNames from "classnames";
import ExclamationIcon from "@untitled-ui/icons-react/build/esm/AlertCircle";
import LikeIcon from "@untitled-ui/icons-react/build/esm/Heart";
import CommentIcon from "@untitled-ui/icons-react/build/esm/MessageCircle02";
import ShareIcon from "@untitled-ui/icons-react/build/esm/Send01";
import SavedIcon from "@untitled-ui/icons-react/build/esm/Bookmark";
import format from "date-fns/format";
import { titleCase } from "src/lib/titleCase";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";

type MetaMedia = MetaMediaQuery["metaMedia"]["edges"][0]["node"];

interface IExistingPostSelectableItem {
  validTypes: string[];
  onChange: (i: MetaMedia) => void;
  value: MetaMedia | null;
  post: MetaMedia;
  isNextItemSelected: boolean;
}

export function ExistingPostSelectableItem({
  onChange,
  value,
  post,
  validTypes,
  isNextItemSelected,
}: IExistingPostSelectableItem) {
  const hasCopyrightIssues =
    post.copyrightStatus === "ERROR" ||
    post.copyrightStatus === "NOT_STARTED" ||
    post.copyrightStatus === "IN_PROGRESS";
  const hasAssetCompatibilityIssues = !validTypes.includes(post.type);

  const hasCompatibilityIssues =
    hasCopyrightIssues || hasAssetCompatibilityIssues;

  return (
    <button
      type="button"
      onClick={() => {
        onChange(post);
      }}
      className={classNames(styles.item, {
        [styles.isNextItemSelected]: isNextItemSelected,
        [styles.selectedItem]: value?.id === post.id,
        [styles.disabledItem]: hasCompatibilityIssues,
      })}
    >
      <div className={styles.nameColumn}>
        {post.type === "VIDEO" ? (
          <video autoPlay muted={true} loop={true} className={styles.thumbnail}>
            <source src={post.url} />
          </video>
        ) : (
          <img alt={post.caption} className={styles.thumbnail} src={post.url} />
        )}

        <div className={styles.captionAndDate}>
          <p title={post.caption} className={styles.caption}>
            {post.caption}
          </p>
          <span className={styles.dateText}>
            {format(new Date(post.createdAt), "dd MMM, yyyy")}
          </span>
        </div>
      </div>

      <div className={styles.mediaTypeColumn}>
        {hasCompatibilityIssues ? (
          <span className={styles.notCompatibleBadge}>
            <ExclamationIcon
              className={styles.notCompatibleIcon}
              width={12}
              height={12}
            />
            Not Compatible
          </span>
        ) : (
          titleCase(post.mediaProductType)
        )}
      </div>

      <span className={styles.insightsColumn}>
        <span className={styles.insightItem}>
          <LikeIcon width={14} height={14} />
          {formatNumberByType(post.insights?.likes, "NUMBER")}
        </span>
        <span className={styles.insightItem}>
          <CommentIcon width={14} height={14} />
          {formatNumberByType(post.insights?.comments, "NUMBER")}
        </span>
        <span className={styles.insightItem}>
          <ShareIcon width={14} height={14} />
          {formatNumberByType(post.insights?.shares, "NUMBER")}
        </span>
        <span className={styles.insightItem}>
          <SavedIcon width={14} height={14} />
          {formatNumberByType(post.insights?.saved, "NUMBER")}
        </span>
      </span>
    </button>
  );
}
