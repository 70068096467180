import { useFormikContext } from "formik";
import { Label } from "src/components/Label/Label";
import styles from "./SelectAdPlatforms.module.scss";
import { CheckboxGroup } from "src/components/CheckboxGroup/CheckboxGroup";
import { CreativeCreateTypesV2 } from "../misc/creativeCreateTypesV2";
import { SelectableItemCard } from "../../../components/SelectableItemCard/SelectableItemCard";
import { FormikError } from "src/components/FormikError/FormikError";
import { useState } from "react";
import { UnlockFeaturesModal } from "src/components/UnlockFeaturesModal/UnlockFeaturesModal";
import metaImage from "../../../icons/meta.svg?react";
import emailImage from "../../../icons/email.svg?react";
import whatsappImage from "../../../icons/whatsapp.svg?react";
import googleAdsImage from "../../../icons/google-ads.svg?react";

export function SelectAdPlatforms() {
  const [isWhatsappPaywallOpen, setIsWhatsappPaywallOpen] = useState(false);
  const [isGooglePaywallOpen, setIsGooglePaywallOpen] = useState(false);
  const [isEmailPaywallOpen, setIsEmailPaywallOpen] = useState(false);
  const { values, errors, submitCount, touched, setFieldValue } =
    useFormikContext<CreativeCreateTypesV2>();
  const selectedPlatforms = values.platforms;

  const platformOptions = [
    {
      value: "META",
      icon: metaImage,
      label: "Meta",
      subtitle: "Targeting based on behaviour",
    },
    {
      value: "GOOGLE",
      icon: googleAdsImage,
      label: "Google Ads",
      subtitle: "Targeted ads to reach your ideal audience",
    },
    {
      value: "WHATSAPP",
      icon: whatsappImage,
      label: "Whatsapp",
      subtitle: "Multimedia content supported",
    },
    {
      value: "EMAIL",
      icon: emailImage,
      label: "Emails",
      subtitle: "Direct engage with your customers",
    },
  ];

  function handleChange(values: string[]) {
    if (values.includes("WHATSAPP")) {
      setIsWhatsappPaywallOpen(true);
      return;
    }

    if (values.includes("GOOGLE")) {
      setIsGooglePaywallOpen(true);
      return;
    }

    if (values.includes("EMAIL")) {
      setIsEmailPaywallOpen(true);
      return;
    }

    setFieldValue("platforms", values);
  }

  return (
    <div className={styles.wrapper}>
      <Label
        label="Select Platforms"
        helpText="Choose the platforms you want to create the ad for"
      />
      <CheckboxGroup
        value={selectedPlatforms}
        onChange={(values) => {
          handleChange(values as string[]);
        }}
      >
        <div className={styles.platformList}>
          {platformOptions.map((option) => (
            <SelectableItemCard
              selectorType="checkbox"
              icon={option.icon}
              label={option.label}
              value={option.value}
            />
          ))}
        </div>

        <FormikError
          formikError={errors}
          submitCount={submitCount}
          touched={touched}
          fieldName="platforms"
        />
      </CheckboxGroup>

      <UnlockFeaturesModal
        type="UNLOCK_ENGAGE"
        title="Unlock WhatsApp Messages With Engage"
        description="Upgrade to get WhatsApp Business automatic messages."
        isOpen={isWhatsappPaywallOpen}
        onOpenChange={setIsWhatsappPaywallOpen}
      />
      <UnlockFeaturesModal
        type="UPGRADE_GROW"
        title="Unlock Google Ads With Grow Premium"
        description="Upgrade your grow plan to get Google Ads."
        isOpen={isGooglePaywallOpen}
        onOpenChange={setIsGooglePaywallOpen}
      />
      <UnlockFeaturesModal
        type="UNLOCK_ENGAGE"
        title="Unlock Emails With Engage"
        description="Upgrade to get Emails."
        isOpen={isEmailPaywallOpen}
        onOpenChange={setIsEmailPaywallOpen}
      />
    </div>
  );
}
