import { titleCase } from "src/lib/titleCase";

export const facebookFeedPlacements = [
  "FACEBOOK_FEED",
  "FACEBOOK_RIGHT_HAND_COLUMN",
  "FACEBOOK_MARKETPLACE",
  "FACEBOOK_SEARCH",
  "FACEBOOK_PROFILE_FEED",
  "FACEBOOK_VIDEO_FEEDS",
];

export const facebookStoryAndReelPlacements = [
  "FACEBOOK_STORY",
  "FACEBOOK_REELS",
];

export const facebookInstreamPlacements = ["FACEBOOK_INSTREAM_VIDEO"];

export const facebookPlacements = [
  ...facebookFeedPlacements,
  ...facebookStoryAndReelPlacements,
  ...facebookInstreamPlacements,
];

export const instagramFeedPlacements = [
  "INSTAGRAM_STREAM",
  "INSTAGRAM_EXPLORE",
  "INSTAGRAM_EXPLORE_HOME",
  "INSTAGRAM_PROFILE_FEED",
  "INSTAGRAM_IG_SEARCH",
];

export const instagramStoryAndReelPlacements = [
  "INSTAGRAM_STORY",
  "INSTAGRAM_REELS",
];

export const instagramPlacements = [
  ...instagramFeedPlacements,
  ...instagramStoryAndReelPlacements,
];

export const placements = [...facebookPlacements, ...instagramPlacements];

const facebookFeedPlacementOptions = facebookFeedPlacements.map(
  (placement) => ({
    value: placement,
    label: titleCase(placement, "_"),
    category: "FEED",
    platform: "FACEBOOK",
  })
);

const facebookStoryAndReelPlacementOptions = facebookStoryAndReelPlacements.map(
  (placement) => ({
    value: placement,
    label: titleCase(placement, "_"),
    category: "STORY_AND_REEL",
    platform: "FACEBOOK",
  })
);

const facebookInstreamPlacementOptions = facebookInstreamPlacements.map(
  (placement) => ({
    value: placement,
    label: titleCase(placement, "_"),
    category: "INSTREAM_VIDEO",
    platform: "FACEBOOK",
  })
);

const instagramFeedPlacementOptions = instagramFeedPlacements.map(
  (placement) => ({
    value: placement,
    label: titleCase(placement, "_"),
    category: "FEED",
    platform: "INSTAGRAM",
  })
);

const instagramStoryAndReelPlacementOptions =
  instagramStoryAndReelPlacements.map((placement) => ({
    value: placement,
    label: titleCase(placement, "_"),
    category: "STORY_AND_REEL",
    platform: "INSTAGRAM",
  }));

export const placementOptions = [
  ...facebookFeedPlacementOptions,
  ...facebookStoryAndReelPlacementOptions,
  ...facebookInstreamPlacementOptions,
  ...instagramFeedPlacementOptions,
  ...instagramStoryAndReelPlacementOptions,
];
