import { Link } from "react-router-dom";
import styles from "./ButtonV2.module.scss";
import classNames from "classnames";
import LoadingSVG from "../../modules/global/assets/loading.svg?react";

export interface IButtonV2 {
  children?: React.ReactNode;
  leftIcon?: React.FC<React.SVGAttributes<any>>;
  as?: React.FC<any> | "button" | "a";
  to?: string;
  // if `to` is passed
  target?: string;
  rel?: string;
  onClick?: () => void;
  variant?: "solid" | "outline" | "plain" | "link";
  size?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
  isFullWidth?: boolean;
  isDestructive?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean;
  loading?: boolean;
  stopPropagation?: boolean;
  rightIcon?: React.FC<React.SVGAttributes<any>>;
}

export function ButtonV2({
  children,
  to,
  as: AsElement,
  target,
  rel,
  onClick: _onClick,
  variant = "solid",
  size = "medium",
  isFullWidth,
  isDestructive,
  type = "button",
  disabled,
  loading,
  stopPropagation = false,
  leftIcon: LeftIconComponent,
  rightIcon: RightIconComponent,
}: IButtonV2) {
  const BaseElement = (props) =>
    props.to ? (
      <Link {...props} />
    ) : AsElement ? (
      <AsElement {...props} />
    ) : (
      <button {...props} />
    );

  const hasIcon = Boolean(LeftIconComponent) || Boolean(RightIconComponent);
  const iconSize =
    size === "extraSmall"
      ? 14
      : size === "extraLarge" || size === "large"
      ? 18
      : 16;

  const leftIcon = LeftIconComponent ? (
    <LeftIconComponent width={iconSize} height={iconSize} />
  ) : null;

  const rightIcon = RightIconComponent ? (
    <RightIconComponent width={iconSize} height={iconSize} />
  ) : null;

  const onClick = (e: MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (_onClick) {
      _onClick();
    }
  };

  return (
    <BaseElement
      type={type}
      onClick={onClick}
      to={to}
      rel={rel}
      target={target}
      disabled={disabled || loading}
      className={classNames(styles.button, {
        [styles.buttonExtraSmall]: size === "extraSmall",
        [styles.buttonSmall]: size === "small",
        [styles.buttonMedium]: size === "medium",
        [styles.buttonLarge]: size === "large",
        [styles.buttonExtraLarge]: size === "extraLarge",
        [styles.buttonSolid]: variant === "solid",
        [styles.buttonOutline]: variant === "outline",
        [styles.buttonPlain]: variant === "plain",
        [styles.buttonLink]: variant === "link",
        [styles.buttonDestructiveSolid]: isDestructive && variant === "solid",
        [styles.buttonDestructiveOutline]:
          isDestructive && variant === "outline",
        [styles.buttonDestructivePlain]: isDestructive && variant === "plain",
        [styles.buttonDestructiveLink]: isDestructive && variant === "link",
        [styles.buttonFullWidth]: isFullWidth,
        [styles.buttonNoContentOnlyIcon]:
          !children && (Boolean(leftIcon) || Boolean(rightIcon)),
        [styles.disabled]: disabled || loading,
      })}
    >
      {loading && (
        <span className={styles.loadingOverlay}>
          <LoadingSVG
            className={styles.loadingSvg}
            width={iconSize}
            height={iconSize}
          />
        </span>
      )}

      <span
        className={classNames(styles.buttonContent, {
          [styles.buttonContentHasIcon]: hasIcon,
          [styles.buttonContentInvisible]: loading,
        })}
      >
        {Boolean(leftIcon) && leftIcon}
        {Boolean(children) && children}
        {Boolean(rightIcon) && rightIcon}
      </span>
    </BaseElement>
  );
}
