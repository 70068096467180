import AndroidBattery from "../../../icons/android_battery.svg?react";
import AndroidNetwork from "../../../icons/android_network.svg?react";
import AndroidWifi from "../../../icons/android_wifi.svg?react";
import styles from "./PhoneNotificationBar.module.scss";

export function PhoneNotificationBar() {
  return (
    <div className={styles.container}>
      <span className={styles.time}>12:34</span>

      <div className={styles.icons}>
        <AndroidNetwork className={styles.networkIcon} />
        <AndroidWifi className={styles.wifiIcon} />
        <AndroidBattery className={styles.batteryIcon} />
      </div>
    </div>
  );
}
