import { Form, Formik } from "formik";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import Fieldset from "src/components/Fieldset/Fieldset";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { Logo } from "src/components/Logo/Logo";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { usePasswordUpdateInitiateMutation } from "src/graphql/generated/schema";
import * as Yup from "yup";
import { Text } from "src/components/Text/Text";
import passwordResetInitiate from "../assets/password-reset-initiate.png";
import passwordResetSuccessful from "../assets/password-reset-successful.png";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { AuthLayout } from "../components/AuthLayout";

export function PasswordResetInitiate() {
  const [isPasswordResetInitiated, setIsPasswordResetInitiated] =
    useState(false);
  const [initiatePasswordReset] = usePasswordUpdateInitiateMutation();
  const initialValues = {
    email: "",
  };

  const loginFormSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  async function handleSubmit(values: any) {
    try {
      await initiatePasswordReset({
        variables: {
          input: {
            email: values.email,
          },
        },
      });
      setIsPasswordResetInitiated(true);
      toast.success("Password recovery email sent.");
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <AuthLayout
      title="Password Recovery"
      image={
        isPasswordResetInitiated
          ? passwordResetSuccessful
          : passwordResetInitiate
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={loginFormSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <div className="pb-24">
              <SectionHeader
                title={
                  isPasswordResetInitiated
                    ? "Email confirmation"
                    : "Password Recovery"
                }
                description={
                  isPasswordResetInitiated ? (
                    <>
                      We have sent you a email confirmation link to your email{" "}
                      <Text weight="medium" tone="default" size="xs">
                        {values.email}
                      </Text>
                      . Please make sure to check your spam
                    </>
                  ) : (
                    "To reset your password. please enter the your email address"
                  )
                }
              />
            </div>
            <Fieldset>
              {!isPasswordResetInitiated && (
                <InputFormikV2
                  name="email"
                  label="Email"
                  placeholder="alan.turing@example.com"
                  type="email"
                  size="small"
                />
              )}
              <div className="mt-6">
                <ButtonV2
                  type="submit"
                  isFullWidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {isPasswordResetInitiated ? "Resend Email" : "Confirm Email"}
                </ButtonV2>
              </div>
            </Fieldset>
            <div className="flex flex-col gap-6 items-center mt-20">
              <Text as="span" size="sm" tone="subdued">
                Already have an account?{" "}
                <ButtonV2 type="button" variant="link" to="/login">
                  Login
                </ButtonV2>
              </Text>
            </div>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
}
