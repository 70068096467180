import classNames from "classnames";
import styles from "./WizardStep.module.scss";
import { forwardRef } from "react";

export interface IWizardStep {
  children: React.ReactNode;
  isCompleted: boolean;
  isLastStep?: boolean;
  title: string;
  description: string;
  isStepNotActive?: boolean;
}

export const WizardStep = forwardRef(function WizardStep(
  {
    title,
    description,
    isLastStep,
    isCompleted,
    isStepNotActive,
    children,
  }: IWizardStep,
  ref: React.MutableRefObject<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      className={classNames(styles.sectionWrapper, {
        [styles.sectionWrapperCompleted]: isCompleted,
        [styles.sectionWrapperLastItem]: isLastStep,
      })}
    >
      {isStepNotActive && <div className={styles.isStepNotActiveYetOverlay} />}

      <div className={styles.content}>
        <div className={styles.header}>
          <div>
            <h2 className={styles.heading}>{title}</h2>
            <h3 className={styles.subheading}>{description}</h3>
          </div>
        </div>

        {children}
      </div>
    </div>
  );
});
