import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import {
  createFacebookDynamicActivitySchema,
  createFacebookEngagementActivitySchema,
  createFacebookLeadActivitySchema,
  createFacebookPromotePostSchema,
  createFacebookStaticActivitySchema,
  createFacebookVideoActivitySchema,
} from "../misc/adSchema";
import { AdSelector } from "./AdSelector";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { useNavigate } from "react-router-dom";

export function AdSelectorWizardForm({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { activeStepIndex, data, markStepAsDone } = useContext(WizardContext);
  const navigate = useNavigate();

  const activity = data;
  const isCatalogueActivity = activity.metaPurpose === "SALES_CATALOG";
  const isVideoEngagementActivity =
    activity.metaPurpose === "VIDEO_THRUPLAY" ||
    activity.metaPurpose === "VIDEO_2_SEC_VIEW";
  const isPromotePostActivity = activity.metaPurpose === "PROMOTE_POST";
  const isEngagementActivity =
    activity.metaPurpose !== "SALES_CATALOG" &&
    activity.metaPurpose !== "SALES_STANDARD" &&
    activity.metaPurpose !== "LEADS" &&
    activity.metaPurpose !== "WEBSITE_VIEWS";

  const isLeadActivity = activity.metaPurpose === "LEADS";

  const campaignCreateSchema = isPromotePostActivity
    ? createFacebookPromotePostSchema
    : isVideoEngagementActivity
    ? createFacebookVideoActivitySchema
    : isEngagementActivity
    ? createFacebookEngagementActivitySchema
    : isCatalogueActivity
    ? createFacebookDynamicActivitySchema
    : isLeadActivity
    ? createFacebookLeadActivitySchema
    : createFacebookStaticActivitySchema;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...(data as ActivityInputV2),
        selectedAdIndex: 0,
      }}
      validationSchema={campaignCreateSchema}
      onSubmit={async (values) => {
        await markStepAsDone(4, values);
      }}
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <Form>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 3}
            isCompleted={activeStepIndex > 3}
            isLastStep={true}
          >
            <AdSelector
              sectionRef={sectionRef}
              values={values}
              setFieldValue={setFieldValue}
            />
            <div className="pt-32 flex gap-8 justify-end">
              <ButtonV2
                onClick={() => navigate("/campaigns")}
                variant="outline"
              >
                Cancel
              </ButtonV2>
              <ButtonV2
                type="submit"
                disabled={!values.Ads?.length}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Publish Activity
              </ButtonV2>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
