import CalendarIcon from "@untitled-ui/icons-react/build/esm/Calendar";
import KeyboardArrowDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";
import styles from "./Datepicker.module.scss";
import classNames from "classnames";
import { Calendar } from "../../modules/global/components/Calendar";
import { DateRange } from "react-day-picker";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import startOfYear from "date-fns/startOfYear";
import startOfMonth from "date-fns/startOfMonth";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import format from "date-fns/format";
import { Label } from "../Label/Label";
import { convertDatetimeToDate } from "src/modules/global/functions/convertDatetimeToDate";
import subMonths from "date-fns/subMonths";

export type DateFilterEnum =
  | "TODAY"
  | "YESTERDAY"
  | "THIS_WEEK"
  | "THIS_MONTH"
  | "THIS_YEAR"
  | "LAST_3_MONTHS"
  | "ALL_TIME";

interface DateFilter {
  name: string;
  id: DateFilterEnum;
  value: DateRange | null;
}

const today = new Date();

const dateFilters: DateFilter[] = [
  {
    id: "TODAY",
    name: "Today",
    value: {
      from: today,
      to: today,
    },
  },
  {
    id: "YESTERDAY",
    name: "Yesterday",
    value: {
      from: subDays(today, 1),
      to: subDays(today, 1),
    },
  },
  {
    id: "THIS_WEEK",
    name: "This Week",
    value: {
      from: startOfWeek(today),
      to: today,
    },
  },
  {
    id: "THIS_MONTH",
    name: "Month to Date",
    value: {
      from: startOfMonth(today),
      to: today,
    },
  },
  {
    id: "LAST_3_MONTHS",
    name: "Last 3 Months",
    value: {
      from: subMonths(today, 3),
      to: today,
    },
  },
  {
    id: "THIS_YEAR",
    name: "Year to Date",
    value: {
      from: startOfYear(today),
      to: today,
    },
  },
  {
    id: "ALL_TIME",
    name: "All Time",
    value: null,
  },
];

export interface IDatePicker {
  onChange: (i: DateRange) => void;
  label?: string;
  showValueInTrigger?: boolean;
  defaultValue?: DateFilterEnum;
}

export default function DatePicker({
  onChange,
  defaultValue,
  label,
  showValueInTrigger = true,
}: IDatePicker) {
  const [isPopupShowing, setIsPopupShowing] = useState(false);

  const initialValue = defaultValue
    ? dateFilters.find((v) => v.id === defaultValue).value
    : dateFilters[0].value;

  const [selectedValue, setSelectedValue] = useState<
    DateRange | null | undefined
  >(initialValue);

  // initial value is selected by default
  const [submittedValue, setSubmittedValue] = useState(selectedValue);

  let selectedPreset = getSelectedPreset(selectedValue);
  let formattedValue = getFormattedValue(submittedValue, selectedPreset);

  return (
    <Popover.Root open={isPopupShowing} onOpenChange={setIsPopupShowing}>
      <Popover.Trigger>
        <div className={styles.triggerContainer}>
          {label && <Label label={label} />}
          <button type="button" className={styles.button}>
            <CalendarIcon width={16} height={16} />
            {showValueInTrigger && <span>{formattedValue}</span>}
            <KeyboardArrowDown width={16} height={16} />
          </button>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={styles.content}
          align="start"
          alignOffset={20}
          collisionPadding={20}
          sideOffset={6}
          side="bottom"
        >
          <div>
            <div className={styles.calendarWithFilter}>
              <div className={styles.list}>
                {dateFilters.map((filter, i) => (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedValue(filter.value);
                    }}
                    className={classNames(styles.listItem, {
                      [styles.listItemSelected]: selectedPreset === filter,
                    })}
                  >
                    <div>{filter.name}</div>
                    <span>
                      <ChevronRight width={16} height={16} />
                    </span>
                  </button>
                ))}
              </div>
              <div className={styles.calendar}>
                <Calendar
                  required={false}
                  mode={"range"}
                  numberOfMonths={2}
                  max={366}
                  selected={selectedValue}
                  onSelect={setSelectedValue}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.actionSection}>
                <ButtonV2
                  size="small"
                  onClick={() => {
                    // if there is no value selected ignore apply
                    if (selectedValue) {
                      onChange(selectedValue);
                      setSubmittedValue(selectedValue);
                      setIsPopupShowing(false);
                    }
                  }}
                >
                  Apply
                </ButtonV2>
                <ButtonV2 variant="plain" size="small" as={Popover.Close}>
                  Cancel
                </ButtonV2>
              </div>
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function getSelectedPreset(selectedValue: DateRange | null | undefined) {
  if (!selectedValue) {
    return;
  }

  const selectedFromDate = convertDatetimeToDate(selectedValue.from);
  const selectedToDate = convertDatetimeToDate(selectedValue.to);

  return dateFilters.find((v) => {
    if (!v.value) {
      return false;
    }

    const dateFrom = convertDatetimeToDate(v.value.from);
    const dateTo = convertDatetimeToDate(v.value.to);

    return dateFrom === selectedFromDate && dateTo === selectedToDate;
  });
}

function getFormattedValue(
  value: DateRange | null | undefined,
  selectedPreset: DateFilter | undefined,
) {
  if (!value) {
    return "";
  }

  // check if they are matching a preset
  if (selectedPreset) {
    return selectedPreset.name;
  }

  const { from, to } = value;
  let formattingTemplate = "MMM dd";

  // only show year if needed
  if (to.getFullYear() !== from.getFullYear()) {
    formattingTemplate = "MMM dd, yyyy";
  }

  const formattedMinValue = format(value.from, formattingTemplate);

  const isSameDay =
    convertDatetimeToDate(value.from) === convertDatetimeToDate(value.to);

  if (isSameDay) {
    return formattedMinValue;
  }

  const formattedMaxValue = format(value.to, formattingTemplate);

  let formattedValue = `${formattedMinValue} - ${formattedMaxValue}`;

  return formattedValue;
}
