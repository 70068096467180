import Tool02 from "@untitled-ui/icons-react/build/esm/Tool02";
import { MetaLeadSideDrawer } from "./MetaLeadSideDrawer";
import { toast } from "sonner";
import { MetaPageWelcomeMessageSlideDrawer } from "./MetaPageWelcomeMessageSlideDrawer";
import { CreativeCreateTypesV2 } from "../misc/creativeCreateTypesV2";
import instagramUser from "../../global/assets/instagramUserProfile.svg";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Grid } from "src/components/Grid/Grid";

interface ICreateMetaAdvanceOptions {
  values: CreativeCreateTypesV2;
  setField: (id: string | Record<string, any>, value: any) => void;
}

export function CreativeMetaAdvanceOptions({
  values,
  setField,
}: ICreateMetaAdvanceOptions) {
  return (
    <div className="flex flex-col gap-16 pt-16">
      <SectionHeader title={"Advanced options"} icon={Tool02} />
      <Grid
        column={{
          xs: "1fr",
        }}
        row={{ xs: "auto" }}
        rowGap={{
          xs: 8,
        }}
      >
        <MetaLeadSideDrawer
          selectedValue={values?.meta?.leadGen}
          onSubmit={(value) => {
            setField("leadGen", value);
            toast.success("Added Instant form");
          }}
        />
        <MetaPageWelcomeMessageSlideDrawer
          userAvatar={instagramUser}
          userName={"Brand Name"}
          selectedValue={values?.meta?.pageWelcomeMessage}
          onSubmit={(value) => {
            setField("pageWelcomeMessage", value);
          }}
        />
      </Grid>
    </div>
  );
}
