import { MetaActivityLeadGenFormTemplate } from "src/graphql/generated/schema";
import { MetaLeadCreateTypes } from "./metaLeadCreateTypes";

export function generateLeadCreateType(
  input: MetaActivityLeadGenFormTemplate,
): MetaLeadCreateTypes {
  return {
    name: input.name,
    customDisclaimer: {
      description: input.CustomDisclaimer?.title,
      headline: input.CustomDisclaimer?.text,
      isEnabled: input.CustomDisclaimer ? true : false,
    },
    customQuestion: {
      isEnabled: input.Questions && input.Questions?.length > 0 ? true : false,
      questions:
        input.Questions?.map((v) => ({
          label: v.label,
          type: v.type ? v.type : v.options.length ? "SELECT_ONE" : "TEXT",
          options: v.options,
        })) || [],
    },
    prefillQuestions:
      input.prefilledQuestions?.length > 0
        ? (input.prefilledQuestions as any[])
        : ["EMAIL"],
    prefillQuestionsDescription: input.prefilledQuestionsDescription,
    privacyPolicy: {
      label: input.privacyPolicyText,
      link: input.privacyPolicyLink,
    },
    backgroundImage: {
      isEnabled: false,
    },
    audienceGreeting: {
      isEnabled: false,
      type: "PARAGRAPH",
      headline: "",
      description: "",
      list: [""],
    },
    thankYouMessage: {
      headline: input.ThankYouPage?.title,
      description: input.ThankYouPage?.body,
      additionalAction: {
        label: input.ThankYouPage?.buttonText,
        link: input.ThankYouPage?.websiteUrl,
        phoneCountryCode: input.ThankYouPage?.businessPhoneNumberCountryCode,
        phoneNumber: input.ThankYouPage?.businessPhoneNumber,
        type: input.ThankYouPage?.buttonType as any,
      },
    },
  };
}
