import { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Alert from "src/components/Alert/Alert";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Wizard } from "src/components/Wizard/Wizard";
import {
  Activity,
  useActivityCreateMutation,
  useActivityPublishMutation,
  useActivityQuery,
  useOutreachCreateMutation,
  useOutreachPublishMutation,
} from "src/graphql/generated/schema";
import { CreativeSelector } from "../components/CreativeSelector";
import {
  transformActivity,
  transformOutreach,
} from "../functions/transformCampaignInput";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import {
  facebookPlacements,
  instagramPlacements,
} from "../misc/placementOptions";
import styles from "./CampaignWorkshopPage.module.scss";
import Header from "src/components/Header/Header";
import { LogOut01 } from "@untitled-ui/icons-react";
import CAMPAIGNS from "src/graphql/campaigns";
import { Spinner } from "src/components/Spinner/Spinner";
import transformActivityToActivityInput from "../functions/transformActivityToActivityInputV2";
import { ActivityObjectiveAndOthersWizardForm } from "../components/ActivityObjectiveAndOthersWizardForm";
import { ActivityGetStartedWizardForm } from "../components/ActivityGetStartedWizardForm";
import { AudienceOrSegmentSelectorWizardForm } from "../components/AudienceOrSegmentSelectorWizardForm";
import ACTIVITIES from "src/graphql/activities";

export function CampaignWorkshopPage() {
  const [activityCreateFn] = useActivityCreateMutation();
  const [outreachCreateFn] = useOutreachCreateMutation();
  const [publishActivity] = useActivityPublishMutation();
  const [publishOutreach] = useOutreachPublishMutation();

  const navigate = useNavigate();
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const getStartedRef = useRef<HTMLDivElement>();
  const objectiveAndOthersRef = useRef<HTMLDivElement>();
  const audienceSelectorRef = useRef<HTMLDivElement>();
  const adSelectorRef = useRef<HTMLDivElement>();
  const id = searchParams.get("id");
  const { data, loading, error } = useActivityQuery({
    variables: {
      id,
    },
  });
  let initialData = {
    activityName: "",
    platform: "META",
    adPlacementOptions: [...facebookPlacements, ...instagramPlacements],
    shouldCreateCampaign: false,
  } as ActivityInputV2;
  // const moreOptionsRef = useRef<HTMLDivElement>();

  if (loading) {
    return <Spinner height={"full"} />;
  }

  if (data && data.activity) {
    const olderValues = initialData;

    const transformedValues = transformActivityToActivityInput(
      data.activity as Activity,
    );

    // console.log({ transformedValues });

    initialData = {
      ...olderValues,
      ...transformedValues,
    };
  }

  async function handleCampaignCreate(values: ActivityInputV2) {
    if (values.platform === "META") {
      const transformedValues = transformActivity(values);

      const { data } = await activityCreateFn({
        variables: {
          input: transformedValues,
        },
      });

      const { data: publishDataPayload } = await publishActivity({
        variables: {
          activityId: data.activityCreate.id,
        },
        refetchQueries: [
          {
            query: CAMPAIGNS,
            variables: {
              sortBy: {
                direction: "DESC",
                field: "CREATED_AT",
              },
              filters: {
                limit: 10,
              },
            },
          },
          {
            query: ACTIVITIES,
            variables: {
              sortBy: {
                direction: "DESC",
                field: "CREATED_AT",
              },
              filters: {
                limit: 30,
                name: "",
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      // if (publishDataPayload.campaignPublish.userError) {
      //   throw publishDataPayload.campaignPublish.userError;
      // }

      navigate(
        `/campaigns/${data.activityCreate.Campaign.id}/activities/${data.activityCreate.id}`,
      );
    } else if (values.platform === "WHATSAPP") {
      const transformedValues = transformOutreach(values);

      const { data } = await outreachCreateFn({
        variables: {
          input: transformedValues,
        },
      });

      console.log({ data });

      const { data: publishDataPayload } = await publishOutreach({
        variables: {
          outreachId: data.outreachCreate.id,
        },
        refetchQueries: [
          {
            query: CAMPAIGNS,
            variables: {
              sortBy: {
                direction: "DESC",
                field: "CREATED_AT",
              },
              filters: {
                limit: 10,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      // if (publishDataPayload.campaignPublish.userError) {
      //   throw publishDataPayload.campaignPublish.userError;
      // }

      navigate(`/campaigns/${data.outreachCreate.Campaign.id}`);
    }
  }

  function discardChanges() {
    setIsDiscardAlertShowing(false);
    navigate("/campaigns");
  }

  function stay() {
    setIsDiscardAlertShowing(false);
  }

  return (
    <div>
      <Header
        showNotifications={false}
        showBack
        actions={
          <ButtonV2
            onClick={() => {
              setIsDiscardAlertShowing(true);
            }}
            size="small"
            variant="outline"
            rightIcon={LogOut01}
          >
            Exit
          </ButtonV2>
        }
        title="Activity Builder"
      />

      <Alert
        trigger={<></>}
        isOpen={isDiscardAlertShowing}
        onClose={() => setIsDiscardAlertShowing(false)}
        tone="danger"
        secondaryAction={{
          label: "Cancel",
          onClick: stay,
        }}
        primaryAction={{
          label: "Reset Progress",
          onClick: discardChanges,
        }}
        title="Are you sure you want to discard changes?"
        description="Your unsaved changes will be lost if you exit. All settings from this session will be discarded. Do you want to continue?"
      />
      <Wizard
        onResetField={(fieldKey, value, data) => {
          return data;
        }}
        initialData={initialData}
        onStepComplete={async (stepIndex, data) => {
          if (stepIndex === 0) {
            // getStartedRef.current?.scrollIntoView({ behavior: "smooth" });
          } else if (stepIndex === 1) {
            objectiveAndOthersRef.current?.scrollIntoView({
              behavior: "smooth",
            });
          } else if (stepIndex === 2) {
            audienceSelectorRef.current?.scrollIntoView({ behavior: "smooth" });
          } else if (stepIndex === 3) {
            adSelectorRef.current?.scrollIntoView({ behavior: "smooth" });
          } else if (stepIndex === 4) {
            await handleCampaignCreate(data);
          }
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.steps}>
            <ActivityGetStartedWizardForm sectionRef={getStartedRef} />
            <ActivityObjectiveAndOthersWizardForm
              sectionRef={objectiveAndOthersRef}
            />
            <AudienceOrSegmentSelectorWizardForm
              sectionRef={audienceSelectorRef}
            />
            <CreativeSelector sectionRef={adSelectorRef} />
          </div>
        </div>
      </Wizard>
    </div>
  );
}
