import { Edit03, File06, Mail01 } from "@untitled-ui/icons-react";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "sonner";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Dialog } from "src/components/Dialog/Dialog";
import { ErrorHandler } from "src/components/ErrorHandler";
import Fieldset from "src/components/Fieldset/Fieldset";
import { FormikError } from "src/components/FormikError/FormikError";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { MultilineInputFormik } from "src/components/MultilineInputFormik/MultilineFormik";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { Text } from "src/components/Text/Text";
import {
  Organization,
  OrganizationAddress,
  useCountriesQuery,
  useOrganizationAddressUpdateMutation,
} from "src/graphql/generated/schema";
import { customerSupportEmail } from "src/modules/global/misc/customerSupportEmail";
import { indianStates } from "src/modules/global/misc/indianStatesList";
import * as yup from "yup";

interface IEditBillingAddressModal {
  organization: Organization;
  billingAddress: OrganizationAddress;
}

export function EditBillingAddressModal({
  organization,
  billingAddress,
}: IEditBillingAddressModal) {
  const [isOpen, setIsOpen] = useState(false);
  const [updateBillingAddress] = useOrganizationAddressUpdateMutation();

  const {
    data: countriesData,
    loading: countriesLoading,
    error: countriesError,
  } = useCountriesQuery();

  if (countriesError) {
    return <ErrorHandler error={countriesError} />;
  }

  if (countriesLoading) {
    return;
  }

  const formattedCountries = countriesData?.countries.map((country) => ({
    label: country.name,
    value: country.alpha2,
  }));

  return (
    <Dialog
      maxWidth="530px"
      open={isOpen}
      onOpenChange={setIsOpen}
      trigger={
        <ButtonV2
          as={Dialog.Trigger}
          rightIcon={Edit03}
          variant="outline"
          size="small"
        >
          Edit
        </ButtonV2>
      }
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          country: billingAddress?.country || "",
          countryCode: billingAddress?.countryCode || "",
          state: billingAddress?.state || "",
          name: billingAddress?.billingName || "",
          email: billingAddress?.email || "",
          addressLine1: billingAddress?.addressLine1 || "",
          gstNumber: organization?.gstNumber || "",
        }}
        onSubmit={async (values) => {
          try {
            await updateBillingAddress({
              variables: {
                input: {
                  addressLine1: values.addressLine1,
                  billingName: values.name,
                  country: values.country,
                  countryCode: values.countryCode,
                  email: values.email,
                  state: values.state,
                },
              },
            });

            toast.success("Billing address updated successfully");
            setIsOpen(false);
          } catch (error) {
            toast.error("Failed to update billing address");
          }
        }}
      >
        {({
          values,
          setValues,
          setFieldValue,
          errors,
          isSubmitting,
          submitCount,
          touched,
        }) => (
          <Form>
            <Dialog.Header
              icon={File06}
              title="Edit Billing Address"
              subtitle="Update your billing address here"
            />

            <div className="flex flex-col gap-16 py-20">
              <InputFormikV2
                name="name"
                label="Billing Name"
                placeholder="Acme Inc."
              />
              <InputFormikV2
                type="email"
                icon={Mail01}
                name="email"
                placeholder="example@gmail.com"
                label="Billing Email"
              />
              <MultilineInputFormik
                name="addressLine1"
                label="Billing Address"
                rows={3}
                placeholder="Enter your billing address here"
              />

              <div>
                <SelectV2
                  label="Country"
                  options={formattedCountries}
                  value={values.country}
                  placeholder="Country"
                  onChange={(value) => {
                    const country = formattedCountries?.find(
                      (country) => country.value === value,
                    );
                    setValues({
                      ...values,
                      country: country?.label || "",
                      countryCode: value,
                    });
                  }}
                />
                <FormikError
                  fieldName="country"
                  formikError={errors}
                  submitCount={submitCount}
                  touched={touched}
                />
              </div>

              {values.countryCode === "IN" && (
                <Fieldset horizontal>
                  <div className="w-full">
                    <SelectV2
                      options={indianStates.map((state) => ({
                        label: state.name,
                        value: state.code,
                      }))}
                      label="State"
                      value={values.state}
                      placeholder="State"
                      onChange={(value) => setFieldValue("state", value)}
                    />
                    <FormikError
                      fieldName="state"
                      formikError={errors}
                      submitCount={submitCount}
                      touched={touched}
                    />
                  </div>
                  <div className="w-full">
                    <InputFormikV2
                      disabled
                      name="gstNumber"
                      helpText="The Goods and Services Tax is a successor to VAT used in India on the supply of goods and service"
                      label="GST Number"
                      placeholder="1xxxx1212"
                    />
                  </div>
                </Fieldset>
              )}
              {values.countryCode === "IN" && (
                <Text size="xs" tone="neutral-600">
                  To update GST Number contact customer support at{" "}
                  <a href={`mailto:${customerSupportEmail}`}>
                    {customerSupportEmail}
                  </a>
                </Text>
              )}
            </div>

            <Dialog.Footer
              actionButtonType="submit"
              actionButtonLoading={isSubmitting}
              actionButtonText="Save Address"
              dismissButtonText="Cancel"
              onAction={() => {}}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

const validationSchema = yup.object({
  name: yup.string().required("Billing name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  addressLine1: yup.string().required("Billing address is required"),
  country: yup.string().required("Country is required"),
  countryCode: yup.string().required("Country code is required"),
  state: yup.string().when("countryCode", {
    is: "IN",
    then: yup.string().required("State is required"),
    otherwise: yup.string().notRequired(),
  }),
  gstNumber: yup.string().when("countryCode", {
    is: "IN",
    then: yup.string().required("GST number is required"),
    otherwise: yup.string().notRequired(),
  }),
});
