import React, { Fragment, useEffect, useState } from "react";
import styles from "./SearchInput.module.scss";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {
  SearchLg as SearchIcon,
  X as CancelIcon,
} from "@untitled-ui/icons-react";

interface ISearchFieldProps {
  autoFocus: boolean;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  isFixedWidth?: boolean;
  onCancel?: () => void;
}

function SearchField({
  autoFocus,
  value,
  placeholder,
  isFixedWidth,
  onChange,
  onCancel,
  onBlur,
}: ISearchFieldProps) {
  return (
    <div
      className={classNames(styles.inputWrapper, {
        [styles.isFixedWidth]: isFixedWidth,
      })}
    >
      <SearchIcon color="var(--color-neutral-400)" width={16} height={16} />
      <input
        type="text"
        autoFocus={autoFocus}
        className={styles.input}
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
      />
      {value && (
        <CancelButton value={value} onCancel={onCancel} onChange={onChange} />
      )}
    </div>
  );
}

interface ICancelButtonProps {
  value: string;
  onCancel?: () => void;
  onChange: (value: string) => void;
}

function CancelButton({ value, onCancel, onChange }: ICancelButtonProps) {
  return (
    <button
      className={classNames(styles.cancelButton, {
        [styles.buttonHidden]: !value,
      })}
      onClick={() => {
        onChange("");
        if (onCancel) {
          onCancel();
        }
      }}
      type="button"
    >
      <CancelIcon color="var(--color-disabled)" width={16} height={16} />
    </button>
  );
}

interface ISearchResultsProps {
  loading: boolean;
  results: { label: string; onClick?: () => unknown; link?: string }[];
  isPopupOpen: boolean;
  setIsPopupOpen: (value: boolean) => void;
  onChange: (value: string) => void;
}

function SearchResults({
  onChange,
  loading,
  results,
  isPopupOpen,
  setIsPopupOpen,
}: ISearchResultsProps) {
  const navigate = useNavigate();

  return (
    <Transition
      show={isPopupOpen}
      className={styles.searchResults}
      leave={styles.searchResultsLeave}
      leaveFrom={styles.searchResultsLeaveFrom}
      leaveTo={styles.searchResultsLeaveTo}
    >
      {loading ? (
        <div className={styles.loading}>Loading</div>
      ) : results.length === 0 ? (
        <div className={styles.notFound}>No results found.</div>
      ) : (
        <div className={styles.resultList}>
          {results.map((result) => (
            <div
              className={classNames(styles.resultItem)}
              key={result.label}
              onClick={() => {
                if (result.onClick) {
                  result.onClick();
                } else if (result.link) {
                  navigate(result.link);
                }

                // clear search input
                onChange("");
                setIsPopupOpen(false);
              }}
            >
              <span className={styles.resultItemText}>{result.label}</span>
            </div>
          ))}
        </div>
      )}
    </Transition>
  );
}

interface ISearchInputProps {
  loading?: boolean;
  autoFocus?: boolean;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  searchResults?: { label: string; onClick?: () => unknown; link?: string }[];
}

export function SearchInput({
  autoFocus,
  value,
  placeholder,
  onChange,
  loading,
  onBlur,
  searchResults,
}: ISearchInputProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (value) {
      setIsPopupOpen(true);
    }
  }, [value]);

  // close on outside click
  // not extracting this because we have way a place to store reusable hook of components
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isPopupOpen &&
        // @ts-ignore
        !event.target.contains(
          document.querySelector(`.${styles.searchResults}`),
        )
      ) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isPopupOpen]);

  return (
    <div className={styles.wrapper}>
      <SearchField
        autoFocus={autoFocus}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />

      {searchResults && (
        <SearchResults
          onChange={onChange}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          loading={loading}
          results={searchResults}
        />
      )}
    </div>
  );
}
