import { AdInputV2 } from "src/modules/campaign-wizard/misc/createCampaignTypes";
import { MetaActivityAdTemplate } from "../../../graphql/generated/schema";
import { LineItem } from "../../../lib/types";
import { generateLineItemsFromCatalogueProducts } from "./generateLineItemsFromCatalogueProducts";
import { generateLineItemsFromExistingPost } from "./generateLineItemsFromExistingPost";

export function transformAdTemplateToLineItems(
  adTemplate: AdInputV2 | MetaActivityAdTemplate
): LineItem[] {
  if (!adTemplate) {
    return [];
  }

  if (adTemplate.adType === "CATALOG" || adTemplate.catalogueId) {
    return generateLineItemsFromCatalogueProducts(
      adTemplate.Catalogue ? adTemplate.Catalogue?.Products : []
    );
  }

  if (adTemplate.adType === "EXISTING_POST") {
    return generateLineItemsFromExistingPost(adTemplate?.ExistingPost, "");
  }

  if (
    adTemplate.LineItems.length === 1 &&
    adTemplate.adType === "CUSTOM_CREATIVE"
  ) {
    return [
      {
        ...adTemplate.LineItems[0],
        link: "",
      },
    ];
  }

  return adTemplate?.LineItems.map((v) => ({ link: "", ...v }));
}
