import { gql } from "@apollo/client";

const metaEnabledAdAccounts = gql`
  query metaEnabledAdAccounts($filter: MetaEnabledAdAccountsFilter) {
    metaEnabledAdAccounts(filter: $filter) {
      id
      metaName
      metaAccountId
      amountSpent
      enabled
      isDefault
      MetaPixels {
        id
        createdAt
        updatedAt
        clientId
        metaPixelId
        name
        enabled
        isDefault
      }
      MetaPages {
        id
        createdAt
        updatedAt
        clientId
        name
        metaPageId
        enabled
        isDefault
      }
      MetaInstagramAccounts {
        id
        createdAt
        updatedAt
        clientId
        name
        metaInstagramId
        enabled
        isDefault
      }
      MetaCatalogues {
        id
        createdAt
        updatedAt
        clientId
        name
        metaCatalogueId
        enabled
      }
    }
  }
`;

export default metaEnabledAdAccounts;
