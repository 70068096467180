import { AnnotationAlert, CoinsStacked03 } from "@untitled-ui/icons-react";
import classNames from "classnames";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { useState } from "react";
import { toast } from "sonner";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Dialog } from "src/components/Dialog/Dialog";
import { Icon } from "src/components/Icon/Icon";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { Text } from "src/components/Text/Text";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { currencyList } from "src/currency";
import {
  MetaActivityAdset,
  useActivityAdsetsBudgetUpdateMutation,
} from "src/graphql/generated/schema";
import { generateRangeString } from "src/lib/generateRangeString";
import { getAudienceAudienceSetSourcePlatform } from "src/modules/audience/misc/getAudienceAudienceSetSourcePlatforms";
import { adsetBudgetSchema } from "src/modules/campaign-wizard/misc/adsetBudgetSchema";
import PlatformIcons from "src/modules/global/components/PlatformIcons";
import { getAudienceIconList } from "src/modules/global/functions/getAudienceIconList";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";
import * as Yup from "yup";

const adsetValidationSchema = Yup.object({
  adsets: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      budget: adsetBudgetSchema,
    }),
  ).min(1),
});

export function EditBudgetModal({
  adsets,
  activityId,
}: {
  adsets: MetaActivityAdset[];
  activityId: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [activityAdsetsBudgetUpdateFunc] =
    useActivityAdsetsBudgetUpdateMutation();

  return (
    <Formik
      enableReinitialize
      isInitialValid
      initialValues={{
        adsets: adsets.map((v) => ({
          ...v,
          budget: v.budget / 100,
        })),
      }}
      validationSchema={adsetValidationSchema}
      onSubmit={async function (values: FormikValues, helpers) {
        try {
          await activityAdsetsBudgetUpdateFunc({
            variables: {
              input: {
                activityId,
                adsets: values.adsets.map((v) => ({
                  id: v.id,
                  budget: v.budget * 100,
                })),
              },
            },
          });

          toast.success(
            "Budget update started. We will notify you when it's done.",
          );
          setIsOpen(false);
        } catch (err) {
          toast.error(err.message);
        }
        return;
      }}
    >
      {({ values, isSubmitting, handleSubmit }) => (
        <Form>
          <Dialog
            onOpenChange={(v) => setIsOpen(v)}
            open={isOpen}
            maxWidth="840px"
            trigger={
              <ButtonV2 size="small" onClick={() => setIsOpen(true)}>
                Edit Budget
              </ButtonV2>
            }
          >
            <Dialog.Header
              title="Manage allocation of Budget or Biding"
              subtitle="Your ad set budget is the daily or lifetime amount that you want to spend on this ad set."
              icon={CoinsStacked03}
            />
            <div className="flex flex-col gap-16">
              <div className="bg-yellow-100 flex gap-8 p-12 rounded-8 items-center">
                <div className="text-neutral-800">
                  <Icon icon={AnnotationAlert} size="sm" iconColor="inherit" />
                </div>
                <Text size="sm" weight="medium" tone="neutral-800">
                  Often change in budgets may cause poor performance or even
                  cost extra.
                </Text>
              </div>

              <div className="flex-col gap-8">
                {values.adsets.map((v, i) => (
                  <AudienceBudgetCard
                    index={i}
                    audience={v.Audience ?? v.MetaAudienceSet}
                  />
                ))}
              </div>
            </div>

            <Dialog.Footer
              actionButtonText="Save Budget"
              actionButtonLoading={isSubmitting}
              actionButtonType="submit"
              onAction={handleSubmit}
              dismissButtonText="Cancel"
            />
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}

function AudienceBudgetCard({ audience, index }) {
  const selectedCurrencySymbol = useGetActiveCurrencySymbol();
  const currencyCode = currencyList.find(
    (a) => a.symbol === selectedCurrencySymbol,
  )?.code;

  const categories =
    audience.__typename === "Audience" ? [] : audience.AudienceCategories;

  const audiencePlatforms = getAudienceAudienceSetSourcePlatform(audience);
  const IconList = getAudienceIconList({
    audiencePlatforms,
    categories,
  });

  return (
    <div className="flex px-20 py-12 border rounded-8">
      <div className="flex gap-16 w-full">
        <div className="inline-flex gap-4 items-center">
          {IconList.map((IconSingle) => (
            <IconSingle width={16} height={16} color="var(--color-disabled)" />
          ))}
        </div>
        <div className="flex flex-col gap-2">
          <Text size="sm" weight="medium">
            {audience.name}
          </Text>
          <Text size="xs" weight="regular" tone="subdued">
            Est.{" "}
            {generateRangeString(
              audience.MetaInsights?.lowerBoundCount,
              audience.MetaInsights?.upperBoundCount,
            )}
          </Text>
        </div>
      </div>
      <div className="w-1/3">
        <InputFormikV2
          postfix={currencyCode}
          placeholder="Add daily budget"
          prefix={selectedCurrencySymbol}
          name={`adsets[${index}].budget`}
        />
      </div>
    </div>
  );
}
