import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import {
  CreativeCreateTypesV2,
  GlobalCreativeFields,
  MetaCreativeFields,
} from "../misc/creativeCreateTypesV2";
import {
  CreativeTemplatesDocument,
  useCreativeTemplateCreateMutation,
  useCreativeTemplateQuery,
  useLeadGenFormTemplateCreateMutation,
  useMetaPageWelcomeMessageCreateMutation,
} from "src/graphql/generated/schema";
import { useState } from "react";
import { generateLeadGenTemplate } from "../misc/generateLeadGenTemplateInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "src/components/Header/Header";
import LibraryIcon from "../../../icons/library.svg?react";
import { LogOut01 } from "@untitled-ui/icons-react";
import { Spinner } from "src/components/Spinner/Spinner";
import { generateLeadCreateType } from "../misc/generateLeadCreateType";
import { generatePageWelcomeMessageInput } from "../misc/generatePageWelcomeMessageInput";
import {
  metaPlacementOptions,
  MetaPlacementOptionValue,
} from "../misc/metaPlacementOptions";
import { CreativeForm } from "../components/CreativeForm";
import {
  generateInputFromActivityAdTemplate,
  generateInputFromLineItems,
} from "../misc/generateInputFromActivityAdTemplate";
import Alert from "src/components/Alert/Alert";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";

export function CreativeCreatePage() {
  const navigate = useNavigate();
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);
  useState<MetaPlacementOptionValue>(metaPlacementOptions[0].value);
  const [leadGenFormTemplateCreateFn] = useLeadGenFormTemplateCreateMutation();
  useState(false);
  const [creativeTemplateCreateFn] = useCreativeTemplateCreateMutation();
  const [metaPageWelcomeMessageCreateFunc] =
    useMetaPageWelcomeMessageCreateMutation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { data: creativeTemplateData, loading: creativeTemplateLoading } =
    useCreativeTemplateQuery({
      variables: {
        id,
      },
    });

  async function handleSubmit(values: CreativeCreateTypesV2) {
    const {
      global,
      meta: metaAd,
      whatsapp: whatsappAd,
      ...otherValues
    } = values;

    let leadGenId: string;
    let pageWelcomeMessageId: string;

    if (metaAd.leadGen) {
      const { data } = await leadGenFormTemplateCreateFn({
        variables: {
          input: generateLeadGenTemplate(metaAd.leadGen),
        },
      });

      leadGenId = data.leadGenFormTemplateCreate.id;
    }

    if (metaAd.pageWelcomeMessage) {
      const pageWelcomeMessage = metaAd.pageWelcomeMessage;
      const { data } = await metaPageWelcomeMessageCreateFunc({
        variables: {
          input: {
            name: pageWelcomeMessage.name,
            greeting: pageWelcomeMessage.greeting,
            MessageOptions: pageWelcomeMessage.MessageOptions?.map((v) => ({
              text: v.text,
              automatedReply: v.automatedReply,
            })),
            prefilledText: pageWelcomeMessage?.prefilledText,
          },
        },
        refetchQueries: ["metaPageWelcomeMessages"],
        awaitRefetchQueries: true,
      });

      if (data && data.metaPageWelcomeMessageCreate) {
        // perform other actions
        pageWelcomeMessageId = data.metaPageWelcomeMessageCreate.id;
      }
    }

    await creativeTemplateCreateFn({
      variables: {
        input: {
          ...otherValues,
          ...(values.meta.edited && {
            MetaActivityAdTemplate: {
              ...generateInputFromActivityAdTemplate(values),
              ...(leadGenId && { metaActivityLeadGenFormId: leadGenId }),
              ...(pageWelcomeMessageId && {
                pageWelcomeMessageId,
              }),
            },
          }),
          DefaultTemplateField: {
            LineItems: generateInputFromLineItems(global.LineItems),
            primaryText: global.primaryText,
          },
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CreativeTemplatesDocument,
          variables: {
            sortBy: {
              direction: "DESC",
              field: "CREATED_AT",
            },
            filter: {
              take: itemsPerPage,
              name: "",
            },
          },
        },
      ],
      // update: (cache, { data: { creativeTemplateCreate } }) => {
      //   try {
      //     cache.evict({ id: "creativeTemplates" });
      //   } catch (err) {
      //     console.log(err);
      //   }
      // },
    });

    navigate("/library/ad-library");
  }

  let initialValues: CreativeCreateTypesV2 = {
    name: "",
    platforms: ["META"],
    global: {
      primaryText: "",
      LineItems: [],
    },
    meta: {
      type: "SINGLE_IMAGE_OR_VIDEO",
      edited: false,
      primaryText: "",
      LineItems: [],
      crop: "DEFAULT",
    },
    whatsapp: {
      edited: false,
    },
  };

  if (creativeTemplateLoading) {
    return <Spinner height={"screen"} />;
  }

  if (creativeTemplateData && creativeTemplateData.creativeTemplate) {
    const olderValues = initialValues;
    const template = creativeTemplateData.creativeTemplate;
    const global: GlobalCreativeFields = template.DefaultTemplateField
      ? {
          LineItems: template.DefaultTemplateField.LineItems as any[],
          primaryText: template.DefaultTemplateField.primaryText,
        }
      : null;
    const meta: MetaCreativeFields = template.MetaActivityAdTemplate
      ? {
          LineItems: template.MetaActivityAdTemplate.LineItems.map((v) => ({
            PostAsset: v.PostAsset,
            order: v.order,
            StoryAsset: v.StoryAsset,
            description: v.description,
            headline: v.headline,
            link: v.link,
          })),
          edited: true,
          primaryText: template.MetaActivityAdTemplate.primaryText,
          type:
            template.MetaActivityAdTemplate.adType === "CUSTOM_CREATIVE"
              ? template.MetaActivityAdTemplate?.LineItems.length > 1
                ? "CAROUSEL"
                : "SINGLE_IMAGE_OR_VIDEO"
              : template.MetaActivityAdTemplate.adType === "CATALOG"
              ? "CATALOGUE_AD"
              : "EXISTING_POST",
          ...(template.MetaActivityAdTemplate.Catalogue?.id && {
            catalogueId: template.MetaActivityAdTemplate.Catalogue.id,
          }),
          ...(template.MetaActivityAdTemplate.metaProductSetId && {
            productSetId: template.MetaActivityAdTemplate.metaProductSetId,
          }),
          ...(template.MetaActivityAdTemplate.existingPostId && {
            existingPostId: template.MetaActivityAdTemplate.existingPostId,
          }),
          ...(template.MetaActivityAdTemplate
            .MetaActivityLeadGenFormTemplate && {
            leadGen: generateLeadCreateType(
              template.MetaActivityAdTemplate.MetaActivityLeadGenFormTemplate,
            ),
          }),
          ...(template.MetaActivityAdTemplate.PageWelcomeMessage && {
            pageWelcomeMessage: generatePageWelcomeMessageInput(
              template.MetaActivityAdTemplate.PageWelcomeMessage,
            ),
          }),
        }
      : null;

    initialValues = {
      ...olderValues,
      name: `${template.name} - Duplicate`,
      platforms: template.platforms,
      ...(global && { global }),
      ...(meta && { meta }),
    };

    console.log({ initialValues });
  }

  return (
    <div className="w-full">
      <Header
        showNotifications={false}
        icon={LibraryIcon}
        iconColor="danger"
        title="Create Creative Template"
        actions={
          <ButtonV2
            size="small"
            variant="outline"
            rightIcon={LogOut01}
            onClick={() => setIsDiscardAlertShowing(true)}
          >
            Exit
          </ButtonV2>
        }
      />
      <Alert
        trigger={<></>}
        isOpen={isDiscardAlertShowing}
        onClose={() => setIsDiscardAlertShowing(false)}
        title="Are you sure you want to exit?"
        description="You've made some changes that haven't been saved yet. Would you like to save your work before continuing, or discard the changes and proceed?"
        tone="danger"
        primaryAction={{
          label: "Discard Changes",
          onClick: () => {
            navigate("/library/ad-library");
          },
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: () => setIsDiscardAlertShowing(false),
        }}
      />
      <CreativeForm initialValues={initialValues} handleSubmit={handleSubmit} />
    </div>
  );
}
