import { AnnotationInfo } from "@untitled-ui/icons-react";
import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useState } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { WizardStep } from "src/components/WizardStep/WizardStep";
import { Text } from "src/components/Text/Text";
import { AudiencePresetReviewCard } from "./AudiencePresetReviewCard";
import { InterestsReviewCard } from "./InterestsReviewCard";
import { AudiencePresetAndInterestSelectorFormState } from "../misc/audienceCreateTypes";
import { audienceAndPresetSchema } from "../misc/audienceAndPresetSchema";
import { FormikError } from "src/components/FormikError/FormikError";

export function ReviewSelectedAudienceAndInterests({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { data, activeStepIndex, markStepAsDone } = useContext(WizardContext);
  const [isPresetsModalOpen, setIsPresetsModalOpen] = useState(false);
  const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);

  const initialFormValues: AudiencePresetAndInterestSelectorFormState = {
    audiencePresets: {},
    interests: {},
    ...data,
  };

  const handleFormSubmit = (
    values: AudiencePresetAndInterestSelectorFormState,
    {
      setFieldError,
    }: FormikHelpers<AudiencePresetAndInterestSelectorFormState>,
  ) => {
    if (
      Object.keys(values.interests).length === 0 &&
      Object.keys(values.audiencePresets).length === 0
    ) {
      return setFieldError(
        "interests",
        "Please select at least one audience preset or interest",
      );
    }

    markStepAsDone(3, values);
  };

  return (
    <WizardStep
      title="Review your selection"
      description="Review and confirm your selected audience presets and interests before proceeding"
      ref={sectionRef}
      isStepNotActive={activeStepIndex !== 2}
      isLastStep={false}
      isCompleted={activeStepIndex > 2}
    >
      <Formik
        enableReinitialize
        validationSchema={audienceAndPresetSchema}
        initialValues={initialFormValues}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, submitCount, touched, setFieldValue }) => (
          <Form>
            <div className="flex flex-col gap-40">
              <AudiencePresetReviewCard
                values={values}
                setFieldValue={setFieldValue}
                isModalOpen={isPresetsModalOpen}
                setIsModalOpen={setIsPresetsModalOpen}
                adAccountId={data?.adAccountId}
              />

              <InterestsReviewCard
                values={values}
                setFieldValue={setFieldValue}
                isModalOpen={isInterestsModalOpen}
                setIsModalOpen={setIsInterestsModalOpen}
                adAccountId={data?.adAccountId}
              />
            </div>

            {errors.interests && (
              <FormikError
                formikError={errors}
                submitCount={submitCount}
                touched={touched}
                fieldName="interests"
              />
            )}

            <div className="flex gap-8 pt-16">
              <ButtonV2 type="submit">Continue</ButtonV2>
            </div>

            <NoteSection />
          </Form>
        )}
      </Formik>
    </WizardStep>
  );
}

function NoteSection() {
  return (
    <div className="pt-48">
      <div className="flex items-center gap-8 max-w-[760px]">
        <AnnotationInfo
          color="var(--color-text-disabled)"
          width={20}
          height={20}
        />
        <Text size="xs" weight="medium" tone="subdued">
          Note: Settings below are optional, Meta will by default consider age
          between (18yr - 65yr+), all genders, and all countries.
        </Text>
      </div>
    </div>
  );
}
