import { LineItem } from "src/lib/types";
import { FacebookFeed } from "./FacebookFeed";
import { InstagramFeed } from "./InstagramFeed";

export function FeedVariant({
  preview,
  type,
  primaryText,
  callToActionLabel,
  accountName,
  profilePicture,
  onSlideChange,
  defaultActiveSlide,
}: {
  preview?: LineItem | LineItem[];
  type?: "facebook" | "instagram";
  primaryText?: string;
  callToActionLabel?: string;
  accountName?: string;
  profilePicture?: string;
  onSlideChange?: (activeSlide: number) => void;
  defaultActiveSlide?: number;
}) {
  return (
    <>
      {type === "facebook" ? (
        <FacebookFeed
          preview={preview}
          primaryText={primaryText}
          callToActionLabel={callToActionLabel}
          accountName={accountName}
          profilePicture={profilePicture}
          onSlideChange={onSlideChange}
          defaultActiveSlide={defaultActiveSlide}
        />
      ) : (
        <InstagramFeed
          preview={preview}
          primaryText={primaryText}
          callToActionLabel={callToActionLabel}
          accountName={accountName}
          profilePicture={profilePicture}
          onSlideChange={onSlideChange}
          defaultActiveSlide={defaultActiveSlide}
        />
      )}
    </>
  );
}
