export async function runFuncForMultipleItems<T = any>(
  items: T[],
  callback: (i: T) => Promise<void>
) {
  for (const i of items) {
    await callback(i);
  }
}

export async function runToggleFunc<
  T extends {
    enabled: boolean;
  }
>(
  item: T,
  enableFunc: (i: T) => Promise<any>,
  disableFunc: (i: T) => Promise<any>
) {
  if (!item.enabled) {
    await disableFunc(item);
  } else {
    await enableFunc(item);
  }
}
