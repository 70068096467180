/**  add to the top if the value doesn't exist
 * otherwise, update the value
 **/
export function upsertItemInObject<T extends Record<string, any>>(
  object: T,
  key: keyof T,
  value: T[keyof T],
) {
  const isExists = object[key];

  if (isExists) {
    const clone = { ...object };

    clone[key] = value;
    return clone;
  }

  return {
    [key]: value,
    ...object,
  };
}
