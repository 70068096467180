import { Form, Formik } from "formik";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ColorInput } from "src/components/ColorInput/ColorInput";
import Fieldset from "src/components/Fieldset/Fieldset";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { UploadProfilePictureField } from "src/modules/global/components/UploadProfilePicture";
import { CreateClientModalProps } from "../misc/CreateClientModalProps";
import { ClientCreateWizardInput } from "../misc/ClientCreateWizardInput";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import SelectV2 from "src/components/SelectV2/SelectV2";
import clientIndustryOptions from "src/modules/global/misc/clientIndustryOptions";

type ICreateClientModal4 = CreateClientModalProps<ClientCreateWizardInput> & {
  submitLabel?: string;
};

export function CreateClientModal4({
  initialValues,
  submitLabel,
  onBack,
  onSubmit,
}: ICreateClientModal4) {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="grid grid-rows-[auto_1fr_auto] gap-16 h-full">
          <SectionHeader
            title="Brand Details"
            description="Capture essential brand information to set the foundation"
          />

          <div className="pt-16">
            <Fieldset>
              <UploadProfilePictureField
                value={values.clientProfile.brandLogoId}
                onChange={(value) =>
                  setFieldValue("clientProfile.brandLogoId", value)
                }
              />
              <Fieldset horizontal>
                <InputFormikV2
                  name="clientProfile.brandName"
                  label="Brand Name"
                  placeholder="Enter brand name"
                />
                <ColorInput
                  value={values.clientProfile.brandColor}
                  onChange={(value) =>
                    setFieldValue("clientProfile.brandColor", value)
                  }
                  name="clientProfile.brandColor"
                  label="Brand Color"
                  placeholder="Select brand color"
                />
              </Fieldset>
              <SelectV2
                options={clientIndustryOptions}
                value={values.clientProfile.industry}
                onChange={(value) =>
                  setFieldValue("clientProfile.industry", value)
                }
                label="Industry"
                placeholder="Select industry"
              />

              <MultilineInput
                value={values.clientProfile.about}
                onChange={(value) =>
                  setFieldValue("clientProfile.about", value)
                }
                name="about"
                placeholder="We are an carbon neutral shoe company for marathon runners"
                label="About Brand"
                showCharacterCount
                maxCharacterCount={124}
              />
            </Fieldset>
          </div>

          <div className="flex gap-8">
            <ButtonV2 size="small" type="submit">
              {submitLabel ?? (
                <>
                  Continue
                  <VerticalSeparator
                    color="white"
                    separatorHeight={14}
                    totalWidth={10}
                  />
                  2/4
                </>
              )}
            </ButtonV2>
            <ButtonV2 size="small" variant="outline" onClick={onBack}>
              Back
            </ButtonV2>
          </div>
        </Form>
      )}
    </Formik>
  );
}
