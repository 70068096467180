import { Spinner } from "src/components/Spinner/Spinner";
import { useMetaPagesFromPlatformQuery } from "src/graphql/generated/schema";
import { MetaDetailItem } from "./MetaDetailItem";
import { MetaIntegrationDetailsEmptyState } from "./MetaIntegrationDetailsEmptyState";
import { useState } from "react";

export function MetaPagesList({
  selectedAccountId,
  selectedMetaPages,
  setSelectedMetaPages,
}) {
  const [loading, setLoading] = useState(false);
  const { data: metaPagesData, loading: metaPagesLoading } =
    useMetaPagesFromPlatformQuery({
      variables: {
        adAccountId: selectedAccountId,
      },
      notifyOnNetworkStatusChange: true,
    });

  if (metaPagesLoading) {
    return <Spinner height="auto" />;
  }

  const metaPages =
    metaPagesData && metaPagesData.metaPagesFromPlatform
      ? metaPagesData?.metaPagesFromPlatform
      : [];

  if (metaPages.length === 0) {
    return <MetaIntegrationDetailsEmptyState link={""} />;
  }

  console.log({ selectedMetaPages });

  return (
    <>
      {metaPages.map((page, index) => (
        <MetaDetailItem
          isDefault={page?.isDefault}
          // TODO: handle error
          // error={selectedPage === page.id && metaEnablePageError}
          enableFunc={async () => {
            setLoading(true);
            await setSelectedMetaPages(page, metaPages.length);
            setLoading(false);
          }}
          removeFunc={async () => {
            setLoading(true);
            await setSelectedMetaPages(page, metaPages.length);
            setLoading(false);
          }}
          loading={loading}
          profilePicture={page?.Business?.profilePicture}
          title={page?.name}
          isEnabled={
            selectedMetaPages.find((v) => v.metaPageId === page.metaPageId)
              ?.enabled || false
          }
          key={index}
        />
      ))}
    </>
  );
}
