import { Form, Formik, useFormikContext } from "formik";
import { AudienceUseDefaultSettingsCheckbox } from "./AudienceUseDefaultSettingsCheckbox";
import { useContext, useEffect } from "react";
import { WizardContext } from "../../../components/Wizard/WizardContext";
import * as Yup from "yup";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { useMetaIntegrationDefaultSettingsQuery } from "src/graphql/generated/schema";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { WizardStep } from "src/components/WizardStep/WizardStep";
import { Text } from "src/components/Text/Text";
import {
  Check,
  CheckCircle,
  LinkExternal01,
  LinkExternal02,
  Loading02,
} from "@untitled-ui/icons-react";
import { Link } from "react-router-dom";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required").max(50),
  useDefaultMetaAccounts: Yup.bool().required(),
  pageId: Yup.string().required("Meta page id is required"),
  adAccountId: Yup.string().required("ad account id is required"),
  instagramAccountId: Yup.string().nullable(),
  // .required("instagram account id is required"),
  pixelId: Yup.string().nullable(),
});

export function AudienceNameSection() {
  const { activeStepIndex, markStepAsDone, data } = useContext(WizardContext);
  let initialValues = data;

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        markStepAsDone(1, values);
      }}
    >
      {({ values }) => (
        <Form>
          <WizardStep
            isStepNotActive={activeStepIndex !== 0}
            isCompleted={activeStepIndex > 0}
            title="Create New Audience"
            description="Give your audience a descriptive name for easy identification and management"
          >
            <div>
              <div className="pb-24">
                <InputFormikV2
                  autoFocus
                  placeholder="Competitors High ticket buyers"
                  helpText="Enter a unique name to easily identify and manage your audience. This helps with organization and optimizing ad campaigns"
                  isFixedWidth
                  name="name"
                  label="Audience Name"
                />

                <div className="pt-8">
                  <MetaAccountsSelector />
                </div>
              </div>

              <div className="flex">
                <ButtonV2 disabled={!values.adAccountId} type="submit">
                  Continue
                </ButtonV2>
              </div>
            </div>
          </WizardStep>
        </Form>
      )}
    </Formik>
  );
}

function MetaAccountsSelector() {
  const { values, setValues } = useFormikContext<{ adAccountId: string }>();
  const { loading, error, data } = useMetaIntegrationDefaultSettingsQuery();

  useEffect(() => {
    if (
      // if the value isn't already set
      !values.adAccountId &&
      // and data has been loading
      data &&
      // and it contains default account values
      data.metaIntegrationDefaultSettings
    ) {
      let newValues = {
        ...values,
        adAccountId: data.metaIntegrationDefaultSettings.metaAdAccountId,
        pageId: data.metaIntegrationDefaultSettings.metaPageId,
        instagramAccountId:
          data.metaIntegrationDefaultSettings.metaInstagramAccountId,
        pixelId: data.metaIntegrationDefaultSettings.metaPixelId,
        useDefaultMetaAccounts: true,
      };

      setValues(newValues);
    }
  }, [values, data]);

  if (loading) {
    return (
      <div className="flex items-center gap-4">
        <Loading02
          className="animate-spin duration-300 ease-in-out"
          width={16}
          height={16}
        />
        <Text size="xs" weight="medium" tone="subdued">
          Loading your ad accounts
        </Text>
      </div>
    );
  }

  if (error) {
    return null;
  }

  if (data.hasMultipleIntegrationSettingsOptions) {
    return <AudienceUseDefaultSettingsCheckbox />;
  }

  // should be false not null
  if (data.hasMultipleIntegrationSettingsOptions === false) {
    return (
      <span className="flex items-center gap-4">
        <CheckCircle width={14} height={14} />
        <Text tone="subdued" size="xs" weight="medium">
          Using Default Ad Accounts
        </Text>
      </span>
    );
  }

  return (
    <div>
      <Text size="xs" weight="medium" tone="subdued">
        <Link
          to={`/settings/integrations/meta`}
          className="flex items-center gap-4 underline"
        >
          Connect your ad accounts to continue
          <LinkExternal01 width={12} height={12} />
        </Link>
      </Text>
    </div>
  );
}
