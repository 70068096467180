import { LayoutGrid01, UserPlus02 } from "@untitled-ui/icons-react";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";

export function CustomerSourceBadge({
  customer,
}: {
  customer: { metaLeadId?: string; shopifyId?: string };
}) {
  return (
    <BadgeV2
      leftIcon={
        customer.metaLeadId || customer.shopifyId ? LayoutGrid01 : UserPlus02
      }
      label={
        customer.metaLeadId ? "Lead" : customer.shopifyId ? "Shopify" : "Manual"
      }
      color={customer.metaLeadId || customer.shopifyId ? "royal-blue" : "gray"}
    />
  );
}
