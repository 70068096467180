export function enableWhatsappIntegration(callback) {
  // Conversion tracking code
  // @ts-ignore
  window.fbq &&
    // @ts-ignore
    window.fbq("trackCustom", "WhatsAppOnboardingStart", {
      appId: import.meta.env.VITE_WHATSAPP_APP_CLIENT_ID,
      feature: "whatsapp_embedded_signup",
    });

  // Launch Facebook login
  // @ts-ignore
  window.FB.login(
    function (response) {
      if (response.authResponse) {
        const code = response.authResponse.code;
        callback(code, response.authResponse.state);
        // The returned code must be transmitted to your backend,
        // which will perform a server-to-server call from there to our servers for an access token
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    },
    {
      scope:
        "business_management, whatsapp_business_management, whatsapp_business_messaging",
      config_id: import.meta.env.VITE_WHATSAPP_BUSINESS_LOGIN_CONFIG_ID, // configuration ID goes here
      response_type: "code", // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        feature: "whatsapp_embedded_signup",
        sessionInfoVersion: 3, //  Receive Session Logging Info
      },
    }
  );
}
