// import Logo from "src/components/Logo/Logo";
import styles from "./OnboardingLayout2.module.scss";
import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Logo } from "src/components/Logo/Logo";

export function OnboardingLayout2({ children }: { children: React.ReactNode }) {
  const { user, logout } = useContext(AuthContext);
  const email = user?.email;

  return (
    <div className="h-screen grid grid-rows-[auto_1fr_auto] py-20">
      <div className="p-4 flex items-center justify-center">
        <Logo type="full" style="black" size="sm" />
      </div>
      <div className="flex items-center justify-center">
        <section className="flex w-full max-w-[1100px] h-[590px]">
          {children}
        </section>
      </div>
      <div className={styles.loginFooter}>
        <span>© 2024 Codeword Pvt. Ltd.</span>
        <div className={styles.footerLinks}>
          <ButtonV2 variant="link">Support</ButtonV2>
          <ButtonV2
            variant="link"
            {...(user ? { onClick: logout } : { to: "/login" })}
          >
            {user ? "Logout" : "Login"}
          </ButtonV2>
        </div>
      </div>
    </div>
  );
}
