import Flex from "../Flex/Flex";
import { Grid, IGrid } from "../Grid/Grid";
import { IMetricCard, MetricCard } from "../MetricCard/MetricCard";
import { VerticalSeparator } from "../VerticalSeparator/VerticalSeparator";

interface IMetricCardList {
  stats: IMetricCard[];
  columns: IGrid["column"];
  variant?: "small" | "large";
}

export function MetricCardList({
  stats,
  columns,
  variant = "large",
}: IMetricCardList) {
  return (
    <Grid column={columns}>
      {stats.map((stat, index) => (
        <Flex>
          <MetricCard
            title={stat.title}
            value={stat.value}
            valueIcon={stat.valueIcon}
            sourceIcon={stat.sourceIcon}
            performanceBadge={stat.performanceBadge}
            border={false}
            key={index}
            variant={variant}
          />
          {index !== stats.length - 1 && (
            <VerticalSeparator totalWidth={1} separatorHeight={32} />
          )}
        </Flex>
      ))}
    </Grid>
  );
}
