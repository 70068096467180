import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import * as Yup from "yup";
import { ActivityGetStarted } from "./ActivityGetStarted";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

export const getStartedSchema = Yup.object({
  activityName: Yup.string().required("Name is required"),
  platform: Yup.string()
    .oneOf(["META", "WHATSAPP"])
    .required("Platform is required"),
  shouldCreateCampaign: Yup.boolean().required(),
  campaignName: Yup.string().when("shouldCreateCampaign", {
    is: true,
    then: Yup.string().required("Please select a campaign or create a new one"),
    otherwise: Yup.string().nullable(),
  }),
  campaignId: Yup.string().when("shouldCreateCampaign", {
    is: false,
    then: Yup.string().required("Please select a campaign or create a new one"),
    otherwise: Yup.string().nullable(),
  }),
});

export function ActivityGetStartedWizardForm({
  sectionRef,
}: {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { activeStepIndex, data, markStepAsDone } = useContext(WizardContext);

  return (
    <Formik
      validationSchema={getStartedSchema}
      initialValues={data}
      enableReinitialize
      onSubmit={(values) => {
        console.log({ values });
        markStepAsDone(1, values);
      }}
    >
      {({
        values,
        setFieldValue,
        setValues,
        handleSubmit,
        errors,
        submitCount,
        touched,
      }) => (
        <Form>
          <StepWizardStep
            isStepNotActive={activeStepIndex !== 0}
            isCompleted={activeStepIndex > 0}
          >
            <div className="pb-[5.5rem]">
              <ActivityGetStarted
                sectionRef={sectionRef}
                values={values}
                setFieldValue={setFieldValue}
                setValues={setValues}
                errors={errors}
                submitCount={submitCount}
                touched={touched}
              />
              <ButtonV2 onClick={handleSubmit}>Continue</ButtonV2>
            </div>
          </StepWizardStep>
        </Form>
      )}
    </Formik>
  );
}
