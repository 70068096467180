import styles from "./LineGraphV2.module.scss";
import { useMemo } from "react";
import {
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  AreaChart,
  YAxisProps,
} from "recharts";

interface StatValue {
  value: number;
  comparisonValue?: number;
  [x: string]: any;
}

type StatLabel = {
  color: string;
  name: string;
  dataKey: string;
};

export interface ILineGraphV2 {
  values: StatValue[];
  statLabel: StatLabel;
  statComparisonLabel?: StatLabel;
  xAxis: {
    tooltipFormatter?: (i: string) => string;
    tickFormatter?: (i: string, index?: number) => string;
    label?: string;
    dataKey: string;
  };
  width: number | string;
  height: number | string;
  yAxis: {
    tooltipFormatter?: (i: string) => string;
    tickFormatter?: (i: string, index?: number) => string;
    label: string;
    dataKey?: string;
    hide?: boolean;
  };
}

export function LineGraphV2({
  statLabel,
  statComparisonLabel,
  values,
  xAxis,
  width,
  height,
  yAxis,
}: ILineGraphV2) {
  function CustomTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
      const xAxisValue = xAxis.tooltipFormatter
        ? xAxis.tooltipFormatter(label)
        : label;

      const value = yAxis.tooltipFormatter
        ? yAxis.tooltipFormatter(payload[0].value)
        : payload[0].value;

      const comparisonValue =
        yAxis.tooltipFormatter && payload[1] // if both value and formatter are available send value to formatter
          ? yAxis.tooltipFormatter(payload[1].value)
          : payload[1] // if only value is here show the value
          ? payload[1].value
          : null;

      return (
        <div className={styles.tooltip}>
          <h3 className={styles.tooltipHeading}>{xAxisValue}</h3>
          <div className={styles.tooltipLinesWrapper}>
            <div className={styles.tooltipLine}>
              <div className={styles.tooltipLabel}>
                <span className={styles.tooltipPrimaryLegend}></span>
                <div className={styles.tooltipLabelText}>{statLabel.name}</div>
              </div>
              <span className={styles.tooltipValue}>
                {/* remove trailing decimals if zero */}
                {value}
              </span>
            </div>
            {statComparisonLabel && (
              <div className={styles.tooltipLine}>
                <div className={styles.tooltipLabel}>
                  <span className={styles.tooltipComparisonLegend}></span>
                  <div className={styles.tooltipLabelText}>
                    {statComparisonLabel.name}
                  </div>
                </div>
                <span className={styles.tooltipValue}>
                  {/* remove trailing decimals if zero */}
                  {comparisonValue}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  }

  // if (statValues.length === 0) {
  //   return (
  //     <div
  //       className={styles.container}
  //       style={{
  //         width: width,
  //         height: height,
  //       }}
  //     >
  //       <div className={styles.emptyState}>
  //         <img
  //           src={emptyStateInsightsGraph}
  //           alt={emptyStateInsightsGraph}
  //           className={styles.emptyStateIllustration}
  //         />
  //         <div className={styles.emptyStateLabel}>
  //           <span>No Insights Found</span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  const tickSettings: YAxisProps["tick"] = {
    fill: "rgba(var(--color-subdued-code), 0.6)",
    fontSize: 10,
    fontWeight: 600,
  };

  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart
        margin={{ bottom: 10, top: 10, left: -20, right: 0 }}
        data={values.length > 0 ? values : Array(10).fill({ value: 0 })}
      >
        <CartesianGrid stroke="var(--color-gray-100)" vertical={false} />
        <XAxis
          stroke="var(--color-neutral-50)"
          tickLine={false}
          tickMargin={12}
          tick={tickSettings}
          dataKey={xAxis.dataKey}
          interval="preserveStartEnd"
          tickFormatter={xAxis.tickFormatter}
        >
          {xAxis.label && (
            <Label value={xAxis.label} position="bottom" offset={0} />
          )}
        </XAxis>
        <YAxis
          axisLine={false}
          stroke="var(--color-gray-100)"
          tickLine={false}
          tickMargin={8}
          tick={tickSettings}
          hide={yAxis.hide}
          tickFormatter={yAxis.tickFormatter}
          label={{
            label: yAxis.label,
            position: "insideLeft",
            angle: -90,
          }}
        />
        {/* only the primary line is has gradient fill */}
        <defs>
          <linearGradient id="gradientPrimary" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={statLabel.color} stopOpacity={0.14} />
            <stop offset="95%" stopColor={statLabel.color} stopOpacity={0} />
          </linearGradient>
        </defs>

        {/* TODO: use a formatter for format the tooltip */}
        {values.length > 0 && <Tooltip content={CustomTooltip} />}

        <Area
          dot={false}
          strokeWidth={1.2}
          type="monotone"
          label={statLabel.name}
          dataKey={"value"}
          stroke={statLabel.color}
          fillOpacity={1}
          fill="url(#gradientPrimary)"
        />
        {statComparisonLabel && (
          <Area
            dot={false}
            strokeWidth={2}
            strokeLinecap="round"
            type="monotone"
            label={statComparisonLabel.name}
            dataKey={"comparisonValue"}
            stroke={statComparisonLabel.color}
            strokeDasharray="4 4"
            fill="transparent"
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
}
