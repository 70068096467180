import React, { useState, useContext } from "react";
import styles from "./AudienceSelector.module.scss";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import defaultSortOption from "src/modules/global/misc/defaultSortOptions";
import FilterIcon from "@material-symbols/svg-600/rounded/filter_list.svg?react";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import { AudienceBudget } from "./AudienceBudget";
import { audienceCategories } from "src/modules/global/misc/audienceCategories";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import { FormikError } from "src/components/FormikError/FormikError";
import { InputV2 } from "src/components/InputV2/InputV2";
import { SelectButton } from "src/components/SelectButton/SelectButton";
import AuthContext from "src/stores/AuthContext";
import SearchIcon from "@untitled-ui/icons-react/build/esm/SearchLg";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { AudienceSelectorLoader } from "./AudienceSelectorLoader";
import { RetargetingAudienceList } from "./RetargetingAudienceList";

const tabOptions = [
  { label: "Static Audiences", value: "STATIC_AUDIENCES" },
  { label: "Retargeting Audiences", value: "RETARGETING_AUDIENCES" },
];

interface AudienceSelectorProps {
  sectionRef: React.MutableRefObject<HTMLDivElement>;
  values: any;
  errors: any;
  submitCount: number;
  touched: any;
}

export function AudienceSelector({
  sectionRef,
  values,
  errors,
  submitCount,
  touched,
}: AudienceSelectorProps) {
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value);
  const [selectedFilters, setSelectedFilters] = useState({
    searchQuery: "",
    date: "ALL_TIME" as DateFilter,
    platform: "META" as const,
    audienceCategoryId: "ALL",
    take: 25,
  });
  const [selectedSortBy, setSelectedSortBy] = useState({
    direction: "DESC" as "DESC" | "ASC",
    field: "CREATED_AT" as const,
  });
  const { activeClient } = useContext(AuthContext);

  return (
    <section ref={sectionRef} className={styles.section}>
      <div>
        <h2 className={styles.heading}>
          Choose Target Audiences for Your Campaign
        </h2>
        <h3 className={styles.subheading}>
          Select your target audience to reach the right customers for your
          campaign
        </h3>
      </div>

      <div className={styles.filters}>
        <InputV2
          value={selectedFilters.searchQuery}
          onChange={(value) => {
            setSelectedFilters({
              ...selectedFilters,
              searchQuery: value,
            });
          }}
          icon={SearchIcon}
          placeholder="Search your audience here"
        />
        <SortByDropdown
          setSortBy={setSelectedSortBy}
          sortBy={selectedSortBy}
          sortOptions={defaultSortOption}
        />
        <SelectButton
          size="small"
          icon={FilterIcon}
          label="Filter"
          value={selectedFilters.audienceCategoryId}
          onChange={(value) => {
            setSelectedFilters({
              ...selectedFilters,
              audienceCategoryId: value === "ALL" ? null : value,
            });
          }}
          options={[{ label: "All", value: "ALL" }, ...audienceCategories]}
        />
        <DateFilterDropdown
          dateFilter={selectedFilters.date}
          setDateFilter={(value) => {
            setSelectedFilters({
              ...selectedFilters,
              date: value,
            });
          }}
        />
      </div>

      {values.metaPurpose === "SALES_CATALOG" && (
        <div className={styles.tabWrapper}>
          <TabsV2
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            tabs={tabOptions}
          />
        </div>
      )}

      <div className={styles.audienceBox}>
        <div id="scrollTargetForInfiniteScroll" className={styles.audienceList}>
          {selectedTab === "STATIC_AUDIENCES" ? (
            <AudienceSelectorLoader
              adAccountId={values.adAccountId}
              selectedFilters={selectedFilters}
              selectedSortBy={selectedSortBy}
            />
          ) : (
            <RetargetingAudienceList selectedFilters={selectedFilters} />
          )}
        </div>
      </div>

      <FormikError
        fieldName="Adsets"
        formikError={errors}
        submitCount={submitCount}
        touched={touched}
      />

      <AudienceBudget />
    </section>
  );
}
