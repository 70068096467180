import {
  CustomerEdge,
  CustomersDocument,
  CustomersSortBy,
  useCustomersDeleteMutation,
  useSegmentCreateMutation,
} from "src/graphql/generated/schema";
import { AddTagToCustomersModal } from "./AddTagToCustomersModal";
import Alert from "src/components/Alert/Alert";
import { SegmentCreateModal } from "./SegmentCreateModal";
import styles from "./CustomerTable.module.scss";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { itemsPerPage } from "../../global/misc/itemsPerPage";
import { CustomerListItem } from "./CustomerListItem";
import { Table } from "src/components/Table/Table";

interface ICustomersTable {
  customers: CustomerEdge[];
  filters: any;
  selectedCustomerIds: string[];
  setSelectedCustomerIds: (i: string[]) => void;
  sortBy: CustomersSortBy;
}

export function CustomersTable({
  customers,
  selectedCustomerIds,
  setSelectedCustomerIds,
  filters,
  sortBy,
}: ICustomersTable) {
  const [showAddTagsModal, setShowAddTagsModal] = useState(false);
  const [showCreateSegmentModal, setShowCreateSegmentModal] = useState(false);
  const [showDeleteCustomersConfirmation, setShowDeleteCustomersConfirmation] =
    useState(false);
  const [segmentCreate, { loading: segmentCreateLoading }] =
    useSegmentCreateMutation();
  const [customersDelete, { loading: customersDeleteLoading }] =
    useCustomersDeleteMutation();
  const [segmentName, setSegmentName] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  // this creates segment from all the selected customers
  async function handleCreateSegment() {
    // Selected Items are just indexes
    const getSelectedItems = customers
      .filter((_, index) => selectedItems.includes(index))
      .map((v) => v.node.id);

    const payload = await segmentCreate({
      variables: {
        input: {
          customerIds: getSelectedItems,
          name: segmentName,
          filter: "",
        },
      },
    });
    if (payload.errors) {
      toast.error("An error occurred");
      return null;
    }
    return payload.data.segmentCreate;
  }

  async function handleDeleteSelectedItems() {
    // Selected Items are just indexes
    const getSelectedItems = customers
      .filter((_, index) => selectedItems.includes(index))
      .map((v) => v.node.id);

    await customersDelete({
      variables: {
        ids: getSelectedItems,
      },
      refetchQueries: [
        {
          query: CustomersDocument,
          variables: {
            input: {
              filters: filters,
              sortBy: sortBy,
              take: itemsPerPage,
              cursor: null,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    });

    // Remove all the selection after deleting
    setSelectedItems([]);
    // Close the confirmation
    setShowDeleteCustomersConfirmation(false);
  }

  return (
    <div className={styles.customerTable}>
      <SegmentCreateModal
        {...{
          showCreateSegmentModal,
          setShowCreateSegmentModal,
          segmentName,
          setSegmentName,
          segmentCreateLoading,
          handleCreateSegment,
          setSelectedItems,
        }}
      />
      <AddTagToCustomersModal
        {...{
          showAddTagsModal,
          setShowAddTagsModal,
          customers,
          selectedItems,
          setSelectedItems,
        }}
      />
      <Alert
        trigger={<></>}
        isOpen={showDeleteCustomersConfirmation}
        title="Delete Customers"
        onClose={() => {
          if (customersDeleteLoading) return;
          setShowDeleteCustomersConfirmation(false);
        }}
        primaryAction={{
          label: "Delete",
          onClick: handleDeleteSelectedItems,
          loading: customersDeleteLoading,
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: () => setShowDeleteCustomersConfirmation(false),
          loading: customersDeleteLoading,
        }}
        description="Are you sure that you want to delete the selected customers"
      />

      <div className={styles.customerList}>
        <Table
          headings={[
            {
              title: "People & Leads",
            },
            {
              title: "Source",
            },
            {
              title: "Email",
            },
            {
              title: "Phone",
            },
            {
              title: "Opt-in",
            },
            {
              title: "",
            },
          ]}
          isSelectable={true}
          onSelectedItemsChange={setSelectedCustomerIds}
          selectedItems={selectedCustomerIds}
          data={customers}
          idExtractor={(item) => item.node.id}
          renderItem={({ item }) => <CustomerListItem customer={item.node} />}
        />
      </div>
    </div>
  );
}
