import { Grid } from "src/components/Grid/Grid";
import { Image04, VideoRecorder, Phone02 } from "@untitled-ui/icons-react";
import StoriesAndReels from "../../../icons/StoriesAndReels.svg?react";
import { ScrollArea } from "src/components/ScrollArea/ScrollArea";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import Flex from "src/components/Flex/Flex";
import AdvancedPlacementModalCreativePreview from "./AdvancedPlacementModalCreativePreview";
import { Dialog } from "src/components/Dialog/Dialog";
import { useState } from "react";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { PlacementSelectorCheckboxGroup } from "./PlacementSelectorCheckboxGroup";

export function AdvancedPlacementModal({
  platform,
}: {
  platform: "FACEBOOK" | "INSTAGRAM";
}) {
  const placements = [
    {
      label: "Feed",
      value: "FEED",
      icon: Image04,
    },
    {
      label: "Stories And Reels",
      value: "STORY_AND_REEL",
      icon: StoriesAndReels,
    },
  ];

  if (platform === "FACEBOOK") {
    placements.push({
      label: "In-stream ads for videos and reels",
      value: "INSTREAM_VIDEO",
      icon: VideoRecorder,
    });
  }
  const [open, setOpen] = useState(false);
  const [format, setFormat] = useState<string>("FEED");
  return (
    <Dialog
      trigger={
        <ButtonV2
          as={Dialog.Trigger}
          variant="link"
          size="small"
          onClick={() => setOpen(true)}
        >
          Advanced Placement
        </ButtonV2>
      }
      open={open}
      onOpenChange={setOpen}
      maxWidth="800px"
    >
      <Dialog.Header
        icon={Phone02}
        title="Advanced Placement"
        subtitle="Create ad from post which you've already posted on platform"
      />

      <Grid
        column={{ xs: "4fr 3fr" }}
        columnGap={{
          xs: 24,
        }}
      >
        <Flex
          alignItems="flex-end"
          justifyContent="center"
          flexDirection="column"
          gap="24px"
        >
          <ScrollArea height={"100%"} maxHeight={"420px"}>
            <Grid column={{ xs: "1fr" }} rowGap={{ xs: 8 }}>
              {placements.map((v) => (
                <PlacementSelectorCheckboxGroup
                  placement={v}
                  platform={platform}
                  setFormat={setFormat}
                />
              ))}
            </Grid>
          </ScrollArea>

          <Flex
            flexDirection="row"
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={"8px"}
          >
            <ButtonV2 variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </ButtonV2>
            <ButtonV2 onClick={() => setOpen(false)}>Save</ButtonV2>
          </Flex>
        </Flex>
        <div>
          <AdvancedPlacementModalCreativePreview
            platform={platform}
            metaFormat={format}
          />
        </div>
      </Grid>
    </Dialog>
  );
}
