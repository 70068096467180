import { useFormikContext } from "formik";
import styles from "./CreativeGlobalsFields.module.scss";
import { CreativeCreateTypesV2 } from "../misc/creativeCreateTypesV2";
import { LineItemInput } from "./LineItemInput";
import { AddMedia } from "./AdTemplateCreateSlideOver/AddMedia";
import { MultilineInputFormik } from "src/components/MultilineInputFormik/MultilineFormik";

const adConstrainByPlatforms = {
  WHATSAPP: {
    hasPrimaryText: true,
    hasImage: true,
    hasImageCarousel: false,
  },
  META: {
    hasPrimaryText: true,
    hasImage: true,
    hasImageCarousel: true,
  },
};

interface ICreativeGlobalFields {
  activeAssetIndex: number;
  setActiveAssetIndex: (i: number) => void;
}

export function CreativeGlobalFields({
  activeAssetIndex,
  setActiveAssetIndex,
}: ICreativeGlobalFields) {
  const { values, setFieldValue } = useFormikContext<CreativeCreateTypesV2>();

  const constrains = values.platforms.map((p) => adConstrainByPlatforms[p]);

  return (
    <>
      <AddMedia
        onSelect={(assets) => {
          if (assets.length > 1) {
            for (let i = 0; i < assets.length; i++) {
              const asset = assets[i];
              setFieldValue(`global.LineItems[${i}].PostAsset`, asset);
            }
          } else {
            setFieldValue(`global.LineItems[0].PostAsset`, assets[0]);
          }
        }}
        multiple={constrains.every((a) => a.hasImageCarousel)}
      />

      <div className={styles.lineItems}>
        {values.global.LineItems.map((lineItem, lineItemIndex) => (
          <LineItemInput
            lineItemIndex={lineItemIndex}
            activeAssetIndex={activeAssetIndex}
            setActiveAssetIndex={setActiveAssetIndex}
            setFieldValue={setFieldValue}
            shouldShowAdvancedFields={false}
            onReplace={(value) => {
              setFieldValue(
                `global.LineItems[${lineItemIndex}].PostAsset`,
                value,
              );
            }}
            onRemove={() => {
              setFieldValue(
                `global.LineItems`,
                values.meta.LineItems.filter(
                  (a, index) => index !== lineItemIndex,
                ),
              );
            }}
            baseKey={`global.LineItems[${lineItemIndex}]`}
            lineItem={lineItem}
          />
        ))}
      </div>

      <MultilineInputFormik
        showCharacterCount
        maxCharacterCount={500}
        onEmojiPick={(value) => {
          setFieldValue(
            "global.primaryText",
            values.global.primaryText + value.emoji,
          );
        }}
        placeholder="Type your content here...."
        showEmojiPicker
        name="global.primaryText"
        label="Add Primary Text"
        rows={3}
      />
    </>
  );
}
