import * as Accordion from "@radix-ui/react-accordion";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import {
  MetaInstagramAccount,
  MetaPage,
  MetaCatalogue,
  MetaPixel,
  MetaAdAccount,
  MetaIntegrationDefaultSettings,
} from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { LineHeading } from "src/modules/global/components/LineHeading";
import { MetaCataloguesList } from "./MetaCataloguesList";
import { MetaInstagramAccountsList } from "./MetaInstagramAccountsList";
import { MetaPagesList } from "./MetaPagesList";
import { MetaPixelsList } from "./MetaPixelsList";
import Meta from "../../../icons/facebook.svg?react";
import Instagram from "../../../icons/instagram.svg?react";
import styles from "./MetaAdAccountSingleAccordion.module.scss";
import { MetaAdAccountDefaultSettingsForm } from "../misc/MetaIntegrationFormTypes";

type CommonFields = "id" | "isDefault" | "enabled" | "clientId";
type SelectedInstagramAccount = Pick<
  MetaInstagramAccount,
  CommonFields | "metaInstagramId"
>;
type SelectedMetaPage = Pick<MetaPage, CommonFields | "metaPageId">;
type SelectedMetaCatalogue = Pick<
  MetaCatalogue,
  CommonFields | "metaCatalogueId"
>;
type SelectedMetaPixel = Pick<MetaPixel, CommonFields | "metaPixelId">;

interface IMetaAdAccountSingleCardAccordion {
  adAccountIndex: number;
  metaAdAccount: MetaAdAccount;
  selectedInstagramAccounts: SelectedInstagramAccount[];
  selectedMetaPages: SelectedMetaPage[];
  selectedMetaCatalogues: SelectedMetaCatalogue[];
  selectedMetaPixels: SelectedMetaPixel[];
  setSelectedInstagramAccounts: (
    i: SelectedInstagramAccount,
    totalItems: number
  ) => Promise<any>;
  setSelectedMetaPages: (
    i: SelectedMetaPage,
    totalItems: number
  ) => Promise<any>;
  setSelectedMetaCatalogues: (
    i: SelectedMetaCatalogue,
    totalItems: number
  ) => Promise<any>;
  setSelectedMetaPixels: (
    i: SelectedMetaPixel,
    totalItems: number
  ) => Promise<any>;
  setShowDefaultSettingsModal: (id: string) => void;
  defaultSettings: MetaIntegrationDefaultSettings;
  defaultSettingsUpsertFunc: (
    values: MetaAdAccountDefaultSettingsForm
  ) => Promise<void>;
  openByDefault?: boolean;
}

export function MetaAdAccountSingleCardAccordion({
  adAccountIndex,
  metaAdAccount,
  selectedInstagramAccounts,
  selectedMetaPages,
  selectedMetaCatalogues,
  selectedMetaPixels,
  setSelectedInstagramAccounts,
  setSelectedMetaPages,
  setSelectedMetaCatalogues,
  setSelectedMetaPixels,
  openByDefault = false,
  setShowDefaultSettingsModal,
  defaultSettings,
  defaultSettingsUpsertFunc,
}: IMetaAdAccountSingleCardAccordion) {
  const hasMultipleSelectedItems =
    selectedMetaPages.length > 1 &&
    selectedInstagramAccounts.length > 1 &&
    selectedMetaPixels.length > 1;
  const showDefaultSettingsButton =
    hasMultipleSelectedItems ||
    !Boolean(defaultSettings.metaPixelId) ||
    !Boolean(defaultSettings.metaPageId) ||
    !Boolean(defaultSettings.metaInstagramAccountId);
  const adAccountSettings = [
    {
      heading: "Instagram Account",
      icon: <Instagram height={14} width={14} />,
      component: (
        <MetaInstagramAccountsList
          selectedInstagramAccounts={selectedInstagramAccounts}
          setSelectedInstagramAccounts={setSelectedInstagramAccounts}
          selectedAccountId={metaAdAccount.metaAccountId}
        />
      ),
    },
    {
      heading: "Facebook Page (Required)",
      icon: <Meta height={14} width={14} />,
      component: (
        <MetaPagesList
          selectedAccountId={metaAdAccount.metaAccountId}
          selectedMetaPages={selectedMetaPages}
          setSelectedMetaPages={setSelectedMetaPages}
        />
      ),
    },
    {
      heading: "Product Catalog",
      icon: <Meta height={14} width={14} />,
      component: (
        <MetaCataloguesList
          selectedAccountId={metaAdAccount.metaAccountId}
          setSelectedMetaCatalogues={setSelectedMetaCatalogues}
          selectedMetaCatalogues={selectedMetaCatalogues}
        />
      ),
    },
    {
      heading: "Meta Pixel",
      icon: <Meta height={14} width={14} />,
      component: (
        <MetaPixelsList
          selectedAccountId={metaAdAccount.metaAccountId}
          setSelectedMetaPixels={setSelectedMetaPixels}
          selectedMetaPixels={selectedMetaPixels}
        />
      ),
    },
  ];

  return (
    <>
      <Accordion.Root
        type="single"
        defaultValue={openByDefault ? metaAdAccount.id : ""}
        collapsible
      >
        <Accordion.Item
          value={metaAdAccount.id}
          className={styles.containerCard}
        >
          <Accordion.Trigger className={styles.accordionTrigger}>
            <div className={styles.adAccountInfo}>
              <img
                src={
                  metaAdAccount?.Business?.picture ??
                  `https://api.dicebear.com/7.x/shapes/svg?seed=${metaAdAccount.metaName}`
                }
                alt={
                  metaAdAccount?.Business?.picture || metaAdAccount?.metaName
                }
                className={styles.profile}
              />
              <div className={styles.info}>
                <div className={styles.connectionStatus}>
                  <span className={styles.name}>
                    {snakeCaseToTitleCase(metaAdAccount.metaName)}
                  </span>
                  <span className={styles.status}>
                    {metaAdAccount.isDefault ? "Default" : ""}
                  </span>
                </div>
                <span className={styles.description}>Business Account</span>
              </div>
            </div>
            {showDefaultSettingsButton && (
              <ButtonV2
                variant="outline"
                size="small"
                stopPropagation
                onClick={async () => {
                  if (hasMultipleSelectedItems) {
                    setShowDefaultSettingsModal(metaAdAccount.id);
                    return;
                  }
                  const values = {
                    adAccountId: metaAdAccount.id,
                    pageId: selectedMetaPages?.[0]?.id,
                    instagramAccountId: selectedInstagramAccounts?.[0]?.id,
                    pixelId: selectedMetaPixels?.[0]?.id,
                  };
                  console.log(values);
                  await defaultSettingsUpsertFunc(values);
                }}
              >
                {metaAdAccount.isDefault ? "Change Default" : "Set as default"}
              </ButtonV2>
            )}
          </Accordion.Trigger>
          <Accordion.Content>
            <div className={styles.adAccountSettings}>
              {adAccountSettings.map((v) => (
                <div className={styles.section}>
                  <LineHeading
                    content={v.heading}
                    icon={v.icon}
                    className={styles.sectionHeading}
                  />
                  <div className={styles.sectionListing}>{v.component}</div>
                </div>
              ))}
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </>
  );
}
