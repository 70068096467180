import React, { ReactNode } from "react";
import styles from "./Grid.module.scss";
import classNames from "classnames";

export interface IGrid {
  children: ReactNode;
  isHeightFull?: boolean;
  verticalAlignment?: "center" | "start" | "end" | "stretch";
  horizontalAlignment?: "center" | "start" | "end";
  columnGap?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  rowGap?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  column?: {
    xs?: number | string;
    sm?: number | string;
    md?: number | string;
    lg?: number | string;
    xl?: number | string;
  };
  row?: {
    xs?: number | string;
    sm?: number | string;
    md?: number | string;
    lg?: number | string;
    xl?: number | string;
  };
}

export function Grid({
  verticalAlignment,
  isHeightFull,
  horizontalAlignment,
  children,
  columnGap,
  rowGap,
  column,
  row,
}: IGrid) {
  let { xs: colXs, sm: colSm, md: colMd, lg: colLg, xl: colXl } = column || {};
  let { xs: rowXs, sm: rowSm, md: rowMd, lg: rowLg, xl: rowXl } = row || {};
  let {
    xs: columnGapXs,
    sm: columnGapSm,
    md: columnGapMd,
    lg: columnGapLg,
    xl: columnGapXl,
  } = columnGap || {};
  let {
    xs: rowGapXs,
    sm: rowGapSm,
    md: rowGapMd,
    lg: rowGapLg,
    xl: rowGapXl,
  } = rowGap || {};

  // Cascade down the lower size grid if
  // the grid for current size isn't defined
  if (!colXs) colXs = 1;
  if (!colSm) colSm = colXs;
  if (!colMd) colMd = colSm;
  if (!colLg) colLg = colMd;
  if (!colXl) colXl = colLg;

  if (!rowXs) rowXs = 1;
  if (!rowSm) rowSm = rowXs;
  if (!rowMd) rowMd = rowSm;
  if (!rowLg) rowLg = rowMd;
  if (!rowXl) rowXl = rowLg;

  if (!columnGapXs) columnGapXs = 0;
  if (!columnGapSm) columnGapSm = columnGapXs;
  if (!columnGapMd) columnGapMd = columnGapSm;
  if (!columnGapLg) columnGapLg = columnGapMd;
  if (!columnGapXl) columnGapXl = columnGapLg;

  if (!rowGapXs) rowGapXs = 0;
  if (!rowGapSm) rowGapSm = rowGapXs;
  if (!rowGapMd) rowGapMd = rowGapSm;
  if (!rowGapLg) rowGapLg = rowGapMd;
  if (!rowGapXl) rowGapXl = rowGapLg;

  const style = {
    "--grid-vertical-alignment": verticalAlignment,
    "--grid-horizontal-alignment": horizontalAlignment,
    "--grid-cols-xs":
      typeof colXs === "number" ? `repeat(${colXs}, 1fr)` : colXs,
    "--grid-cols-sm":
      typeof colSm === "number" ? `repeat(${colSm}, 1fr)` : colSm,
    "--grid-cols-md":
      typeof colMd === "number" ? `repeat(${colMd}, 1fr)` : colMd,
    "--grid-cols-lg":
      typeof colLg === "number" ? `repeat(${colLg}, 1fr)` : colLg,
    "--grid-cols-xl":
      typeof colXl === "number" ? `repeat(${colXl}, 1fr)` : colXl,
    "--grid-rows-xs":
      typeof rowXs === "number" ? `repeat(${rowXs}, 1fr)` : rowXs,
    "--grid-rows-sm":
      typeof rowSm === "number" ? `repeat(${rowSm}, 1fr)` : rowSm,
    "--grid-rows-md":
      typeof rowMd === "number" ? `repeat(${rowMd}, 1fr)` : rowMd,
    "--grid-rows-lg":
      typeof rowLg === "number" ? `repeat(${rowLg}, 1fr)` : rowLg,
    "--grid-rows-xl":
      typeof rowXl === "number" ? `repeat(${rowXl}, 1fr)` : rowXl,
    "--grid-column-gap-xs": `${columnGapXs}px`,
    "--grid-column-gap-sm": `${columnGapSm}px`,
    "--grid-column-gap-md": `${columnGapMd}px`,
    "--grid-column-gap-lg": `${columnGapLg}px`,
    "--grid-column-gap-xl": `${columnGapXl}px`,
    "--grid-row-gap-xs": `${rowGapXs}px`,
    "--grid-row-gap-sm": `${rowGapSm}px`,
    "--grid-row-gap-md": `${rowGapMd}px`,
    "--grid-row-gap-lg": `${rowGapLg}px`,
    "--grid-row-gap-xl": `${rowGapXl}px`,
  } as React.CSSProperties;

  return (
    <div
      className={classNames(styles.grid, {
        [styles.heightFull]: isHeightFull,
      })}
      style={style}
    >
      {children}
    </div>
  );
}
