import classNames from "classnames";
import { SVGAttributes } from "react";
import { CheckboxOption } from "src/components/CheckboxGroup/CheckboxGroup";
import styles from "./SelectableItemCard.module.scss";
import { Checkbox } from "src/components/Checkbox/Checkbox";
import { RadioGroup } from "@headlessui/react";
import { Radio } from "src/components/Radio/Radio";
import { ButtonV2 } from "../ButtonV2/ButtonV2";

interface ISelectableItemCard {
  icon?: React.FC<SVGAttributes<any>>;
  imageSrc?: string;
  value: string;
  iconPlacement?: "onTop" | "withTitle";
  selectorAlignment?: "center" | "top";
  label: string;
  description?: string;
  disabled?: boolean;
  action?:
    | {
        label: string;
        onClick: () => void;
      }
    | React.ReactNode;
  selectorType?: "radio" | "checkbox";
}

export function SelectableItemCard({
  icon: Icon,
  imageSrc,
  iconPlacement = "onTop",
  selectorAlignment = "top",
  value,
  label,
  description,
  selectorType: variant = "checkbox",
  action,
  disabled,
}: ISelectableItemCard) {
  const Selector = variant === "checkbox" ? CheckboxOption : RadioGroup.Option;
  const Input = variant === "checkbox" ? Checkbox : Radio;
  const hasDescription = !!description;
  const hasIcon = !!Icon;
  const iconSize =
    iconPlacement === "withTitle" ? 16 : hasDescription ? 20 : 14;

  if (imageSrc && !description) {
    throw new Error("description is mandatory with image");
  }

  if (Icon && imageSrc) {
    throw new Error("Can not use both image and icon");
  }

  const hasTopIconOrImage = (iconPlacement === "onTop" && hasIcon) || imageSrc;

  return (
    <Selector className={styles.wrapper} disabled={disabled} value={value}>
      {({ checked, onChange }) => (
        <div
          className={classNames(styles.platformItem, {
            [styles.selectedPlatformItem]: checked,
            [styles.disabledPlatformItem]: disabled,
            [styles.hasDescription]: hasDescription,
            [styles.hasImage]: imageSrc,
            [styles.iconWithTitle]: iconPlacement === "withTitle",
          })}
        >
          <div className={classNames(styles.platformContent)}>
            {hasTopIconOrImage && (
              <div className={styles.iconOrImageWrapper}>
                {/* platform image or icon */}
                {imageSrc && (
                  <img
                    className={styles.image}
                    src={imageSrc}
                    width={80}
                    height={80}
                  />
                )}

                {hasIcon && iconPlacement === "onTop" && (
                  <Icon width={iconSize} height={iconSize} />
                )}
              </div>
            )}
            <div className={styles.titleAndDescriptionWrapper}>
              <div className={styles.titleWrapper}>
                {iconPlacement === "withTitle" && hasIcon && (
                  <Icon width={iconSize} height={iconSize} />
                )}
                <span className={styles.platformTitle}>{label}</span>
              </div>
              {description && (
                <span className={styles.platformDescription}>
                  {description}
                </span>
              )}
              {action && typeof action === "object" && "label" in action ? (
                <span className={styles.platformAction}>
                  <ButtonV2 onClick={action.onClick} variant="link">
                    {action.label}
                  </ButtonV2>
                </span>
              ) : (
                action
              )}
            </div>
          </div>
          <div
            className={classNames(styles.inputWrapper, {
              [styles.selectorAlignmentCenter]: selectorAlignment === "center",
            })}
          >
            <Input
              disabled={disabled}
              value={value}
              checked={checked}
              onChange={() => onChange(value)}
            />
          </div>
        </div>
      )}
    </Selector>
  );
}
