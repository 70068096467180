import { gql } from "@apollo/client";

const ACTIVITIES = gql`
  query activities(
    $filters: ActivitiesFilterInput
    $sortBy: ActivitiesSortByInput
  ) {
    activities(filters: $filters, sortBy: $sortBy) {
      edges {
        cursor
        node {
          id
          name
          status
          createdAt
          updatedAt
          Campaign {
            id
            name
          }
          platform
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemsPerPage
        totalItems
        startCursor
        endCursor
      }
    }
  }
`;

export default ACTIVITIES;
