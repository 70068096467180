import React, { ReactNode } from "react";

interface FieldsetProps {
  children: ReactNode;
  horizontal?: boolean;
}

const Fieldset: React.FC<FieldsetProps> = ({
  children,
  horizontal = false,
}) => {
  return (
    <fieldset className={`flex ${horizontal ? "flex-row" : "flex-col"} gap-16`}>
      {children}
    </fieldset>
  );
};

export default Fieldset;
