import { useEffect, useRef, useState } from "react";
import EmojiPickerReact, { EmojiClickData } from "emoji-picker-react";
import styles from "./EmojiPicker.module.scss";
import FaceHappy from "@untitled-ui/icons-react/build/esm/FaceHappy";

export function EmojiPicker({
  onSelect,
}: {
  onSelect: (i: EmojiClickData) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const emojiRef = useRef<HTMLDivElement | null>(null);

  function handleEmojiClick(event: EmojiClickData) {
    onSelect(event);
    setIsOpen(false);
  }

  // close on outside click
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!emojiRef.current) return;

      // clicked element is outside of the emoji ref
      if (!emojiRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [emojiRef]);

  return (
    <div ref={emojiRef}>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className={styles.emojiPickerButton}
        type="button"
      >
        <FaceHappy width={16} height={16} />
      </button>
      <div className={styles.picker}>
        <EmojiPickerReact open={isOpen} onEmojiClick={handleEmojiClick} />
      </div>
    </div>
  );
}
