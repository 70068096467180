import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorHandler } from "src/components/ErrorHandler";
import Header from "src/components/Header/Header";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  useCustomerQuery,
  useCustomersCountQuery,
  useCustomersDeleteMutation,
} from "src/graphql/generated/schema";
import { DropdownV2 } from "src/modules/campaign/components/DropdownV2";
import { CustomerSubmissions } from "../components/CustomerSubmissions";
import { CustomerSidebar } from "../components/CustomerSidebar";
import { CustomerSourceBadge } from "../components/CustomerSourceBadge";
import Alert from "src/components/Alert/Alert";
import { CUSTOMERS } from "../api/customers";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";
import CUSTOMER from "../api/customer";
import { getDateFilterFromFilterEnum } from "src/modules/global/misc/dateFilterUtils";
import { CustomerEditModal } from "../components/CustomerEditModal";

// type TabOption = {
//   label: string;
//   value: "HISTORY" | "INSIGHTS" | "SUBMISSIONS";
// };

export function CustomerSinglePage() {
  // const tabOptions: TabOption[] = [
  //   {
  //     label: "History",
  //     value: "HISTORY" as const,
  //   },
  //   {
  //     label: "Insights",
  //     value: "INSIGHTS" as const,
  //   },
  // ];

  // const [selectedTab, setSelectedTab] =
  //   useState<TabOption["value"]>("SUBMISSIONS");
  const { id } = useParams<"id">();
  const navigate = useNavigate();
  const { data, loading, error } = useCustomerQuery({
    variables: {
      id,
    },
  });
  const [deleteCustomerFn, { loading: deletingCustomer }] =
    useCustomersDeleteMutation({
      variables: {
        ids: [id],
      },
      refetchQueries: [
        {
          query: CUSTOMERS,
          variables: {
            input: {
              take: itemsPerPage,
              sortBy: {
                direction: "DESC",
                field: "CREATED_AT",
              },
              filters: {
                createdAt: getDateFilterFromFilterEnum("ALL_TIME"),
                name: "",
              },
            },
          },
        },
        {
          query: CUSTOMER,
          variables: {
            id,
          },
        },
      ],
    });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false);

  if (loading) {
    return <Spinner height="full" />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const customer = data.customer;

  // if (customer.MetaLeadFormSubmissions?.length) {
  //   tabOptions.push({
  //     label: "Submissions",
  //     value: "SUBMISSIONS" as const,
  //   });
  // }

  return (
    <div>
      <Header
        showBack
        title={customer.name}
        badges={<CustomerSourceBadge customer={customer} />}
        actions={
          <DropdownV2
            options={[
              {
                label: "Edit customer",
                onClick: () => {
                  setIsEditCustomerModalOpen(true);
                },
              },
              {
                label: "Delete customer",
                onClick: () => {
                  setIsDeleteModalOpen(true);
                },
              },
            ]}
            buttonVariant="outline"
            children="Actions"
          />
        }
      />
      <div className="grid grid-cols-[1fr_328px] h-[calc(100vh-58px)]">
        <div className="px-24 pt-16 overflow-y-scroll">
          {/* <div className="flex justify-between">
            <TabsV2
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              tabs={tabOptions}
            />
            <div className="flex gap-8">
              <SortByDropdown
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOptions={defaultSortOptions}
              />
              <DatePicker
                showValueInTrigger
                defaultValue="ALL_TIME"
                onChange={() => {
                  console.log("Date changed");
                }}
              />
            </div> 
          </div> */}

          {/* {selectedTab === "SUBMISSIONS" && ( */}
          <CustomerSubmissions customer={customer} />
          {/* )}
          {selectedTab === "HISTORY" && <CustomerHistory />}
          {selectedTab === "INSIGHTS" && <CustomerInsights />} */}
        </div>

        <Alert
          trigger={<></>}
          isOpen={isDeleteModalOpen}
          tone="danger"
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          description="Are you sure you want to delete this customer?"
          title="Delete Customer"
          secondaryAction={{
            label: "Cancel",
            onClick: () => {
              setIsDeleteModalOpen(false);
            },
          }}
          primaryAction={{
            loading: deletingCustomer,
            label: "Delete Customer",
            onClick: async () => {
              await deleteCustomerFn();
              navigate("/contacts");
              setIsDeleteModalOpen(false);
            },
          }}
        ></Alert>

        <CustomerEditModal
          customer={customer}
          isOpen={isEditCustomerModalOpen}
          onIsOpenChange={(value) => {
            setIsEditCustomerModalOpen(value);
          }}
        />

        <CustomerSidebar customer={customer} />
      </div>
    </div>
  );
}
