import { Logo } from "src/components/Logo/Logo";
import { Text } from "src/components/Text/Text";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import onboardingGrid from "../assets/onboarding-grid.png";

export function AuthLayout({
  children,
  title,
  image,
}: {
  children: React.ReactNode;
  image: string;
  title: React.ReactNode;
}) {
  return (
    <div className="h-screen grid grid-rows-[auto_1fr_auto] py-20">
      <div className="p-4 flex gap-8 items-center justify-center">
        <Logo type="icon" style="black" size="sm" />
        <VerticalSeparator totalWidth={1} separatorHeight={14} />
        <Text as="h3" size="sm" tone="subdued">
          {title}
        </Text>
      </div>
      <div className="flex items-center justify-center">
        <div className="flex w-full max-w-[1100px] h-[590px]">
          <div className="w-full p-12 md:w-1/2 md:p-[82px] flex flex-col justify-center border rounded-16 md:border-r-0 md:rounded-r-[0] space-y-24">
            <Logo type="icon" style="black" embrossed={true} />
            {children}
          </div>
          <div
            style={{
              backgroundImage: `url('${onboardingGrid}')`,
            }}
            className="hidden md:flex w-1/2 bg-gray-100 justify-center items-center border rounded-r-16 bg-center bg-no-repeat bg-cover "
          >
            <img
              src={image}
              alt="Login Image"
              className="w-4/5 animate-fade-up animate-once animate-duration-500 animate-ease-in-out"
            />
          </div>
        </div>
      </div>
      <div className="p-4 flex items-center justify-center gap-16">
        <Text as="span" size="md" tone="subdued">
          © 2024 Codeword Pvt. Ltd.
        </Text>
        <ButtonV2 variant="link">Support</ButtonV2>
        <ButtonV2 variant="link">Help Center</ButtonV2>
      </div>
    </div>
  );
}
