import { Button } from "src/components/Button/Button";
import Modal from "src/components/Modal/Modal";
import DateFilterDropdown from "src/modules/global/components/DateFilterDropdown";
import SortByDropdown from "src/modules/global/components/SortByDropdown";
import FilterListIcon from "@material-symbols/svg-600/rounded/filter_list.svg?react";
import SearchIcon from "@material-symbols/svg-600/rounded/search.svg?react";
import CloseIcon from "@material-symbols/svg-600/rounded/close.svg?react";
import { dateFilterOptions } from "src/modules/global/misc/dateFilterOptions";
import defaultSortOption from "src/modules/global/misc/defaultSortOptions";
import { AdTemplatesLoader } from "./AdTemplatesLoader";
import { useState } from "react";
import { StickyFooter } from "src/modules/global/components/StickyFooter";
import InfoIcon from "@material-symbols/svg-600/rounded/info.svg?react";
import styles from "./AdSelectorModal.module.scss";
import { MetaActivityAdTemplatesQuery } from "src/graphql/generated/schema";
import { DateFilter } from "src/modules/global/misc/dateFilterUtils";
import { InputV2 } from "src/components/InputV2/InputV2";
import { SelectButton } from "src/components/SelectButton/SelectButton";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

type AdTemplate =
  MetaActivityAdTemplatesQuery["metaActivityAdTemplates"]["edges"][0];

interface IAdSelectorModal {
  isOpen: boolean;
  onClose: (i: boolean) => void;
  onSelect: (i: AdTemplate[]) => void;
  multiple?: boolean;
}

export function AdSelectorModal({
  isOpen,
  onClose,
  onSelect,
  multiple = true,
}: IAdSelectorModal) {
  const [selectedAdTemplates, setSelectedAdTemplates] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    searchQuery: "",
    dateFilter: "ALL_TIME" as DateFilter,
    adType: null,
  });
  const [selectedSortBy, setSelectedSortBy] = useState({
    direction: "DESC" as "ASC" | "DESC",
    field: "CREATED_AT" as const,
  });

  function handleSelect() {
    onSelect(selectedAdTemplates.map((s) => ({ ...s, adTemplateId: s.id })));
    // reset on select
    setSelectedAdTemplates([]);
  }

  return (
    <Modal
      maxWidth="1200px"
      onClose={onClose}
      isOpen={isOpen}
      footer={{
        footerContent: (
          <span className={styles.label}>
            <InfoIcon width={20} height={20} />
            Marketing creative templates from library, you can select upto 10
            Ads
          </span>
        ),
        footerActions: (
          <div className={styles.buttonGroup}>
            <ButtonV2 onClick={() => onClose(false)} variant="outline">
              Cancel
            </ButtonV2>
            <ButtonV2 onClick={handleSelect}>Insert Ads</ButtonV2>
          </div>
        ),
        hideBorder: true,
      }}
    >
      <div className={styles.modalHeader}>
        <h2 className={styles.modalHeading}>Select Marketing Creative</h2>
        <button
          onClick={() => onClose(!isOpen)}
          className={styles.closeBtn}
          type="button"
        >
          <CloseIcon fill="var(--color-disabled)" width={24} height={24} />
        </button>
      </div>

      <div className={styles.filters}>
        <InputV2
          isFixedWidth
          icon={SearchIcon}
          placeholder="Search your creatives here"
          label=""
          value={selectedFilters.searchQuery}
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, searchQuery: value });
          }}
        />
        <SortByDropdown
          sortBy={selectedSortBy}
          setSortBy={setSelectedSortBy}
          sortOptions={defaultSortOption}
        />
        <SelectButton
          icon={FilterListIcon}
          label={"Filter"}
          size="small"
          value={selectedFilters.adType}
          onChange={(value) => {
            if (value === "ALL") {
              setSelectedFilters({ ...selectedFilters, adType: null });
              return;
            }

            setSelectedFilters({
              ...selectedFilters,
              adType: value,
            });
          }}
          options={[
            {
              label: "All",
              value: "ALL",
            },
            {
              label: "Custom Creative",
              value: "CUSTOM_CREATIVE",
            },
            {
              label: "Catalogue",
              value: "CATALOG",
            },
            {
              label: "Existing Post",
              value: "EXISTING_POST",
            },
          ]}
        />
        <DateFilterDropdown
          dateFilter={selectedFilters.dateFilter}
          setDateFilter={(value) => {
            setSelectedFilters({
              ...selectedFilters,
              dateFilter: value,
            });
          }}
          filterOptions={dateFilterOptions}
        />
      </div>

      <div className={styles.templateBodyWrapper}>
        <div id="scrollTargetForInfiniteScroll" className={styles.templateBody}>
          <AdTemplatesLoader
            multiple={multiple}
            selectedFilters={selectedFilters}
            selectedSortBy={selectedSortBy}
            selectedAdTemplates={selectedAdTemplates}
            setSelectedAdTemplates={setSelectedAdTemplates}
          />
        </div>
      </div>

      {/* <StickyFooter
        label={
          <span className={styles.label}>
            <InfoIcon width={20} height={20} />
            Marketing creative templates from library, you can select upto 10
            Ads
          </span>
        }
        actions={
          <div className={styles.buttonGroup}>
            <ButtonV2 onClick={() => onClose(false)} variant="outline">
              Cancel
            </ButtonV2>
            <ButtonV2 onClick={handleSelect}>Insert Ads</ButtonV2>
          </div>
        }
      /> */}
    </Modal>
  );
}
