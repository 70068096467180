import { ReactNode } from "react";
import styles from "./Label.module.scss";
import classNames from "classnames";
import Tooltip from "../Tooltip/Tooltip";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";

export interface ILabel {
  children?: ReactNode;
  label: ReactNode | string;
  srOnly?: boolean;
  helpText?: string;
  htmlFor?: string;
  className?: string;
  status?: "default" | "error";
}

export function Label({
  children,
  label,
  htmlFor,
  className,
  status = "default",
  srOnly,
  helpText,
}: ILabel) {
  return (
    <label className={classNames(styles.label)} htmlFor={htmlFor}>
      <span
        className={classNames(
          styles.labelText,
          {
            [styles.srOnly]: srOnly,
            [styles.error]: status === "error",
            [styles.default]: status === "default",
          },
          className,
        )}
      >
        {label}
        {helpText && (
          <Tooltip
            children={
              <HelpCircle
                stroke="rgba(177, 177, 177, 1)"
                width={12}
                height={12}
              />
            }
            content={helpText}
          />
        )}
      </span>

      {children}
    </label>
  );
}
