import { useEffect } from "react";
import { convertDatetimeToDate } from "./convertDatetimeToDate";
import differenceInDays from "date-fns/differenceInDays";
import subDays from "date-fns/subDays";
import { checkIfAllValuesAreNullOrUndefined } from "src/lib/checkIfAllValuesAreNullOrUndefined";
import { GrowAggregateType } from "../misc/GrowAggregateType";
import {
  useGrowAggregateLazyQuery,
  useGrowAggregateQuery,
} from "src/graphql/generated/schema";
import isSameDay from "date-fns/isSameDay";

type GrowFilterOptions = {
  dateFrom?: Date;
  others: Record<string, any>;
  dateTo?: Date;
  shouldShowComparisonValues?: boolean;
};

type GrowAggregatedStats = {
  loading: boolean;
  error: { message: string } | null;
  data: GrowAggregateType | null;
  compareData: GrowAggregateType | null;
};

/**
 * returns aggregated stats for campaign with comparison values
 */
export function useGrowAggregatedStats<T = GrowAggregatedStats>({
  dateFrom,
  others,
  dateTo,
  shouldShowComparisonValues: _shouldShowComparisonValues = true,
}: GrowFilterOptions): T {
  const shouldShowComparisonValues =
    _shouldShowComparisonValues && dateFrom && dateTo;

  const dayGap = shouldShowComparisonValues
    ? differenceInDays(dateTo, dateFrom)
    : null;

  const comparisonTo = shouldShowComparisonValues ? subDays(dateFrom, 1) : null;
  const comparisonFrom = shouldShowComparisonValues
    ? subDays(comparisonTo, dayGap)
    : null;

  const { data, loading, error } = useGrowAggregateQuery({
    variables: {
      filter: {
        insightType: others.insightType,
        ...others,
        ...(dateFrom && { dateFrom: convertDatetimeToDate(dateFrom) }),
        ...(dateTo && { dateTo: convertDatetimeToDate(dateTo) }),
      },
    },
  });

  const [
    fetchComparisonData,
    {
      data: comparisonData,
      loading: comparisonLoading,
      error: comparisonError,
    },
  ] = useGrowAggregateLazyQuery();

  useEffect(() => {
    if (shouldShowComparisonValues) {
      fetchComparisonData({
        variables: {
          filter: {
            insightType: others.insightType,
            ...others,
            dateFrom: convertDatetimeToDate(comparisonFrom),
            dateTo: convertDatetimeToDate(comparisonTo),
          },
        },
      });
    }
  }, [
    fetchComparisonData,
    convertDatetimeToDate(comparisonFrom),
    convertDatetimeToDate(comparisonTo),
    shouldShowComparisonValues,
  ]);

  // if all values inside the data is null there is no value in sending that
  let transformedData = null;

  if (
    data?.growAggregate &&
    !checkIfAllValuesAreNullOrUndefined(data.growAggregate)
  ) {
    transformedData = data.growAggregate;
  } else {
    transformedData = null;
  }

  let transformedComparisonData = null;

  if (
    comparisonData?.growAggregate &&
    !checkIfAllValuesAreNullOrUndefined(comparisonData.growAggregate)
  ) {
    transformedComparisonData = comparisonData.growAggregate;
  }

  const resp = {
    loading: loading || comparisonLoading,
    error: error || comparisonError,
    data: transformedData,
    compareData: transformedComparisonData,
  } as T;

  return resp;
}
