import {
  Activity,
  MetaActivityAd,
  MetaActivityAdset,
} from "../../../graphql/generated/schema";
import {
  ActivityInputV2,
  AdInputV2,
  AdsetInputV2,
  MetaActivityPurpose,
} from "../misc/createCampaignTypes";

export default function transformActivityToActivityInput(
  activity: Activity,
): ActivityInputV2 {
  const metaActivity = activity.MetaActivity;

  let metaPurpose: MetaActivityPurpose = "SALES_STANDARD";
  if (metaActivity) {
    console.log(metaActivity);
    switch (metaActivity.objective) {
      case "LEADS":
        metaPurpose = "LEADS";
        break;
      case "ENGAGEMENT":
        if (metaActivity.engagementConversionLocation === "MESSAGE") {
          if (metaActivity.engagementDestinations?.[0] === "INSTAGRAM_DIRECT") {
            metaPurpose = "MESSAGE_INSTAGRAM";
          } else {
            metaPurpose = `MESSAGE_${metaActivity.engagementDestinations?.[0]}`;
          }
        } else {
          metaPurpose =
            (metaActivity.engagementConversionLocation as MetaActivityPurpose) ||
            "PROMOTE_POST";
        }
        break;
      case "TRAFFIC":
        metaPurpose = "WEBSITE_VIEWS";
        break;
      default:
        // Handle any other cases or leave it undefined
        break;
    }
  }

  const result: ActivityInputV2 = {
    activityName: `${activity.name} - Duplicate`,
    activityDescription: activity.description || "",
    campaignId: activity.campaignId,
    Campaign: activity.Campaign,
    shouldCreateCampaign: false,
    metaPurpose,
    platform: activity.platform,
    adPlacementOptions: metaActivity?.adPlacements ?? [],
    adAccountId: metaActivity?.MetaAdAccount?.id ?? "",
    metaPixelId: metaActivity?.metaPixelId ?? "",
    metaInstagramPageId: metaActivity?.metaInstagramId ?? "",
    metaPageId: metaActivity?.metaPageId ?? "",
    metaPageConnectedInstagramBusinessAccountId:
      metaActivity?.MetaPage?.metaPageConnectedInstagramBusinessId ?? "",
    conversionDomain: metaActivity?.conversionDomain ?? "",
    facebookActivityType: metaActivity?.activityType as
      | "META_STANDARD"
      | "META_DYNAMIC",
    productCatalogueId: metaActivity?.productCatalogueId ?? "",
    Adsets:
      metaActivity?.MetaActivityAdsets?.map((v) =>
        transformMetaActivityAdsetToAdsetInput(v as MetaActivityAdset),
      ) || [],
    Ads:
      metaActivity?.MetaActivityAds?.map((v) =>
        transformMetaActivityAdToAdInput(v as MetaActivityAd),
      ) || [],
  };

  return result;
}

function transformMetaActivityAdsetToAdsetInput(
  adset: MetaActivityAdset,
): AdsetInputV2 {
  const audienceId = adset.Audience?.id || null;
  const audienceSetId = adset.MetaAudienceSet?.id || null;

  return {
    audienceId: audienceId,
    audienceSetId: audienceSetId,
    type: audienceId
      ? "AUDIENCE"
      : audienceSetId
      ? "AUDIENCE_SET"
      : "PRODUCT_RETARGET",
    budget: (adset.budget / 100).toString(),
    ProductSetSpecs: [],
    // adset.ProductSetSpecs?.map((spec) => ({
    //   Rules: spec.Rules,
    //   productSetId: spec.productSetId,
    // })) || [],
    templateId: audienceId
      ? audienceId
      : adset.MetaAudienceSet?.AudienceAudienceSetIndex?.id,
  };
}

function transformMetaActivityAdToAdInput(ad: MetaActivityAd): AdInputV2 {
  return {
    primaryText: ad.primaryText ?? "",
    adType: ad.adType,
    callToActionLabel: ad.CTAText,
    existingPostId: ad.existingPostId ?? "",
    phoneNumber: ad.phoneNumber ?? "",
    metaActivityLeadGenFormTemplateId:
      ad.metaActivityLeadGenFormTemplateId ?? "",
    phoneCountryCode: ad.phoneCountryCode ?? "IN",
    existingPostCTALink: ad.existingPostCTALink ?? "",
    // pageWelcomeMessageId: ad.pageWelcomeMessageId,
    adTemplateId: ad.adTemplateId || "",
    LineItems:
      ad?.LineItems?.map((item: any) => ({
        id: item.id || "",
        order: item.order,
        headline: item.headline || "",
        description: item.description || "",
        link: item.link,
        PostAsset: item.postAssetId ? item.PostAsset : undefined,
        StoryAsset: item.storyAssetId ? item.StoryAsset : undefined,
      })) || [],
  };
}
