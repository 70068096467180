import { gql } from "@apollo/client";

const pagination = gql`
  fragment pageInfo on Pagination {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    itemsPerPage
    totalItems
  }
`;

export default pagination;
