import classNames from "classnames";
import styles from "./Icon.module.scss";

type ColorTones =
  | "neutral-100"
  | "neutral-200"
  | "neutral-300"
  | "neutral-400"
  | "neutral-500"
  | "neutral-600"
  | "neutral-700"
  | "neutral-800"
  | "neutral-900"
  | "gray-100"
  | "gray-200"
  | "gray-300"
  | "gray-400"
  | "gray-500"
  | "gray-600"
  | "gray-700"
  | "gray-800"
  | "gray-900";

export interface IconProps {
  icon: React.FC<React.SVGAttributes<SVGElement>>;
  size?: "sm" | "md" | "lg" | "xl" | "2xl";
  iconColor?:
    | "danger"
    | "subdued"
    | "info"
    | "warning"
    | "success"
    | "default"
    | "primary"
    | "inherit"
    | "transparent"
    | ColorTones;

  // fillColor?:
  //   | "danger"
  //   | "info"
  //   | "warning"
  //   | "success"
  //   | "default"
  //   | "primary"
  //   | "transparent";
}

export function Icon({
  icon,
  iconColor = "default",
  // fillColor = "transparent",
  size = "md",
}: IconProps) {
  const Component = icon;

  return (
    <Component
      className={classNames({
        [styles.sizeSm]: size === "sm",
        [styles.sizeMd]: size === "md",
        [styles.sizeLg]: size === "lg",
        [styles.sizeXl]: size === "xl",
        [styles.size2xl]: size === "2xl",
        [styles.colorDanger]: iconColor === "danger",
        [styles.colorInfo]: iconColor === "info",
        [styles.colorSubdued]: iconColor === "subdued",
        [styles.colorWarning]: iconColor === "warning",
        [styles.colorSuccess]: iconColor === "success",
        [styles.colorDefault]: iconColor === "default",
        [styles.colorPrimary]: iconColor === "primary",
        [styles.colorInherit]: iconColor === "inherit",
        [styles.toneGray100]: iconColor === "gray-100",
        [styles.toneGray200]: iconColor === "gray-200",
        [styles.toneGray300]: iconColor === "gray-300",
        [styles.toneGray400]: iconColor === "gray-400",
        [styles.toneGray500]: iconColor === "gray-500",
        [styles.toneGray600]: iconColor === "gray-600",
        [styles.toneGray700]: iconColor === "gray-700",
        [styles.toneGray800]: iconColor === "gray-800",
        [styles.toneGray900]: iconColor === "gray-900",
        [styles.toneNeutral100]: iconColor === "neutral-100",
        [styles.toneNeutral200]: iconColor === "neutral-200",
        [styles.toneNeutral300]: iconColor === "neutral-300",
        [styles.toneNeutral400]: iconColor === "neutral-400",
        [styles.toneNeutral500]: iconColor === "neutral-500",
        [styles.toneNeutral600]: iconColor === "neutral-600",
        [styles.toneNeutral700]: iconColor === "neutral-700",
        [styles.toneNeutral800]: iconColor === "neutral-800",
        [styles.toneNeutral900]: iconColor === "neutral-900",
        [styles.colorTransparent]: iconColor === "transparent",
      })}
    />
  );
}
