import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import {
  PasswordUpdateInput,
  useIsResetCodeExpiredQuery,
  usePasswordUpdateMutation,
} from "src/graphql/generated/schema";
import { useContext, useEffect, useState } from "react";
import AuthContext from "src/stores/AuthContext";
import { PasswordResetLinkExpired } from "../components/PasswordResetLinkExpired";
import passwordResetSuccessful from "../assets/password-reset-successful.png";
import macroLogo from "src/modules/global/assets/macroLogo.svg";
import passwordReset from "../assets/password-reset-initiate.png";
import LogOut01 from "@untitled-ui/icons-react/build/esm/LogOut01";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { AuthLayout } from "../components/AuthLayout";
import Fieldset from "src/components/Fieldset/Fieldset";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Text } from "src/components/Text/Text";

const resetSchema = Yup.object().shape({
  password: Yup.string().required("Enter New Password"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match!")
    .required("Enter Confirm Password"),
});

export function PasswordReset() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  // const { login } = useContext(AuthContext);
  const [passwordUpdateFunc] = usePasswordUpdateMutation();
  const {
    data,
    loading: isResetCodeExpiredLoading,
    error,
  } = useIsResetCodeExpiredQuery({
    variables: {
      code: urlSearchParams.get("code"),
    },
  });
  const [loading, setLoading] = useState(false);
  const initialValues: PasswordUpdateInput = {
    password: "",
    resetPasswordCode: urlSearchParams.get("code"),
  };

  if ((data && data.resetPasswordCodeExpiredCheck) || error) {
    return <PasswordResetLinkExpired />;
  }

  async function handleSubmit(values) {
    setLoading(true);
    try {
      const { data } = await passwordUpdateFunc({
        variables: {
          input: {
            password: values.password,
            resetPasswordCode: values.resetPasswordCode,
          },
        },
      });
      const { userError, authPayload } = data.passwordUpdate;
      if (userError) {
        console.log(userError);
        setLoading(false);
        throw new Error(userError.message);
      }
      setLoading(false);
      setIsPasswordReset(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      throw new Error("Something went wrong");
    }
  }

  return (
    <>
      {isPasswordReset ? (
        <PasswordResetSuccessful />
      ) : (
        <AuthLayout image={passwordReset} title={"Password Recovery"}>
          <Formik
            initialValues={initialValues}
            validationSchema={resetSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="pb-16">
                <SectionHeader
                  title={"Request New Password"}
                  description={
                    "To reset your password. please enter your new password"
                  }
                />
              </div>
              <Fieldset>
                <InputFormikV2
                  name="password"
                  placeholder="New Password"
                  type="password"
                />
                <div>
                  <InputFormikV2
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                  />
                </div>
                <ButtonV2
                  type="submit"
                  variant="solid"
                  loading={loading}
                  disabled={loading || isResetCodeExpiredLoading}
                >
                  {isResetCodeExpiredLoading
                    ? "Checking Code"
                    : "Change my password"}
                </ButtonV2>
              </Fieldset>
              <div className="flex justify-center items-center mt-24 py-24 border-t">
                <Text size="sm" tone="subdued" weight="medium">
                  Already have an account?{" "}
                  <ButtonV2 type="button" variant="link" to="/login">
                    Login
                  </ButtonV2>
                </Text>
              </div>
            </Form>
          </Formik>
        </AuthLayout>
      )}
    </>
  );
}

function PasswordResetSuccessful() {
  return (
    <AuthLayout image={passwordResetSuccessful} title={"Password Recovery"}>
      <div className="flex flex-col gap-24">
        <SectionHeader
          title={"Password Successfully Reset"}
          description="Your password has been successfully reset. You’re all set to log in!"
        />
        <ButtonV2 to="/login">Login</ButtonV2>
      </div>
    </AuthLayout>
  );
}
