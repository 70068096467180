import styles from "./AudienceTitleWithIcon.module.scss";
import { Audience, MetaAudienceSet } from "src/graphql/generated/schema";
import { AudienceCategoryBadges } from "./AudienceCategoryBadges";
import { Text } from "src/components/Text/Text";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { generateRangeString } from "src/lib/generateRangeString";
import { getAudienceIconList } from "../functions/getAudienceIconList";
import { getAudienceAudienceSetSourcePlatform } from "src/modules/audience/misc/getAudienceAudienceSetSourcePlatforms";

// TODO: make this a generic component which can be also reused as /src/modules/campaign/components/CampaignTitleWithIcon.tsx
export function AudienceTitleWithCategories({
  audience,
  // iconColor = "subdued",
  isActive = false,
}: {
  audience:
    | Pick<
        Audience,
        "createdAt" | "name" | "type" | "__typename" | "MetaInsights"
      >
    | Pick<
        MetaAudienceSet,
        | "createdAt"
        | "name"
        | "__typename"
        | "AudienceCategories"
        | "MetaInsights"
      >;
  // iconColor?: "primary" | "warn" | "success" | "subdued";
  isActive?: boolean;
}) {
  const categories =
    audience.__typename === "Audience" ? [] : audience.AudienceCategories;
  const audiencePlatforms = getAudienceAudienceSetSourcePlatform({
    ...((audience.__typename === "Audience"
      ? {
          Audience: audience,
        }
      : {
          MetaAudienceSet: audience,
        }) as any),
  });

  const IconList = getAudienceIconList({
    audiencePlatforms,
    categories,
  });
  return (
    <div className={styles.container}>
      <div className="inline-flex gap-4 items-center">
        {IconList.map((IconSingle) => (
          <IconSingle width={16} height={16} color="var(--color-disabled)" />
        ))}
      </div>
      <div className="flex items-center gap-12">
        <Text size="sm" weight="medium">
          {audience.name}
        </Text>
        <VerticalSeparator separatorHeight={12} totalWidth={1} />
        <Text size="xs" weight="regular" tone="subdued">
          Est.{" "}
          {generateRangeString(
            audience.MetaInsights?.lowerBoundCount,
            audience.MetaInsights?.upperBoundCount,
          )}
        </Text>
      </div>
    </div>
  );
}
