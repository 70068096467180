import Info from "@material-symbols/svg-600/rounded/info.svg?react";
import styles from "./Asset.module.scss";

interface Props {
  type: string;
  preview?: string;
  name: string;
  loop?: boolean;
  className?: string;
}

export function Asset({ type, preview, name, loop = true, className }: Props) {
  return (
    <>
      {preview ? (
        <>
          {type === "IMAGE" ? (
            <img src={preview} alt={name} className={className} />
          ) : (
            <video
              key={preview}
              autoPlay
              muted={true}
              loop={loop}
              className={className}
            >
              <source src={preview} />
            </video>
          )}
        </>
      ) : (
        <div className={styles.emptyPreview}>
          <Info className={styles.infoIco} />
          <p className={styles.emptyText}>
            To see the preview please add details & assets to your ads.
          </p>
        </div>
      )}
    </>
  );
}
