import { MetaPageWelcomeMessage } from "src/graphql/generated/schema";
import { MetaPageWelcomeMessageCreateTypes } from "./creativeCreateTypesV2";

export function generatePageWelcomeMessageInput(
  input: MetaPageWelcomeMessage,
): MetaPageWelcomeMessageCreateTypes {
  return {
    name: input.name,
    greeting: input.greeting,
    hasPrefilledText: Boolean(input.prefilledText),
    prefilledText: input.prefilledText,
    MessageOptions: input.MessageOptions.map((v) => ({
      automatedReply: v.automatedReply,
      text: v.text,
    })),
  };
}
