import Avatar from "react-avatar";
import { ErrorHandler } from "../../../components/ErrorHandler";
import { Spinner } from "../../../components/Spinner/Spinner";
import { UserInviteCode } from "../../../graphql/generated/schema";
import styles from "./OrganizationInvitesTable.module.scss";
import isAfter from "date-fns/isAfter";
import classNames from "classnames";
import {
  Table,
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { titleCase } from "src/lib/titleCase";

interface IOrganizationInvitesTable {
  isCurrentUserAdmin?: boolean;
  error?: any;
  loading?: boolean;
  organizationInvites: UserInviteCode[];
}

export function OrganizationInvitesTable({
  isCurrentUserAdmin = false,
  error,
  loading,
  organizationInvites,
}: IOrganizationInvitesTable) {
  if (error) {
    return (
      <div>
        <ErrorHandler error={error} />
      </div>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Table
      variant="card"
      isSelectable={false}
      headings={[
        {
          title: "",
        },
        {
          title: "Name and email",
        },
        {
          title: "Clients",
        },
        {
          title: "Role",
        },
        {
          title: "",
        },
      ]}
      data={organizationInvites}
      idExtractor={(item) => item.id}
      renderItem={({ item }) => <InviteItem userInviteCode={item} />}
    />
  );
}

function InviteItem({ userInviteCode }: { userInviteCode: UserInviteCode }) {
  const isExpired = isAfter(new Date(), new Date(userInviteCode.expiresAt));

  return (
    <TableRow id={userInviteCode.id}>
      <TableCell width="32px">
        <Avatar
          round
          name={userInviteCode.email}
          className={styles.avatar}
          size="24"
        />
      </TableCell>
      <TablePrimaryCell title={userInviteCode.email} />

      <TableCell width="376px">
        <div className={styles.clientsList}>
          <BadgeV2
            avatar={{
              name: userInviteCode.Client.name,
            }}
            label={userInviteCode.Client.name}
          />
        </div>
      </TableCell>
      <TableCell width="120px">
        {titleCase(userInviteCode.isRedeemed ? "Redeemed" : "Pending")}
      </TableCell>
      {/* <TableCell width="32px">
        <DropdownV2
          icon={DotsVertical}
          buttonVariant="plain"
          hideArrow
          size="small"
          options={[
            {
              label: "Manage permissions",
              onClick: onManagePermissions,
            },
          ]}
        />
      </TableCell> */}
    </TableRow>
  );
}
