import { useLayoutEffect, useState } from "react";
import {
  useActivitiesCountQuery,
  useAudienceSetsCountQuery,
  useMetaActivityAdTemplatesQuery,
  useMetaIntegrationStatusQuery,
} from "src/graphql/generated/schema";
import CodewordResourcesList from "../components/CodewordResourcesList/CodewordResourcesList";
import { OnboardingDashboard } from "../components/OnboardingDashboard/OnboardingDashboard";
import { GrowthOverviewGraphWidget } from "../components/GrowthOverviewGraphWidget/GrowthOverviewGraphWidget";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";
import Header from "src/components/Header/Header";
import { BusinessOverviewWidget } from "../components/BusinessOverviewWidget";
import { Grid } from "src/components/Grid/Grid";
import { CalloutCard } from "src/components/CalloutCard/CalloutCard";
import { Announcement03, LayoutAlt01 } from "@untitled-ui/icons-react";
import LibraryIcon from "src/icons/library.svg?react";
import { MacroOverview } from "../components/MacroOverview";
import { Text } from "src/components/Text/Text";
import { Spinner } from "src/components/Spinner/Spinner";

export function Dashboard() {
  const [isOnboarded, setIsOnboarded] = useState(false);
  const selectedCurrencyCode = useGetActiveCurrencySymbol();
  const { data: audienceData, loading: audienceCountLoading } =
    useAudienceSetsCountQuery();
  const { data: integrationData, loading: metaIntegrationStatusLoading } =
    useMetaIntegrationStatusQuery();
  const { data: adData, loading: adTemplateLoading } =
    useMetaActivityAdTemplatesQuery({
      variables: {
        filter: {
          createdAt: null,
        },
      },
    });
  const { data: activitiesCount, loading: activitiesLoading } =
    useActivitiesCountQuery({
      variables: {
        filters: {
          statuses: ["ACTIVE", "PAUSED"],
        },
      },
    });

  function onboardingCheck() {
    const activitiesTotalCount =
      activitiesCount?.activitiesCountByStatus?.reduce(
        (acc, item) => acc + item.value,
        0,
      );
    const hasIntegration =
      integrationData?.metaIntegrationStatus === "ACTIVE" ||
      integrationData?.metaIntegrationStatus === "EXPIRED"
        ? true
        : false;
    const hasCreative =
      adData?.metaActivityAdTemplates.edges.length > 0 ? true : false;
    const hasAudience =
      audienceData?.audienceSetsCount?.count > 0 ? true : false;
    const hasCampaign = activitiesTotalCount ? true : false;

    if (
      [hasIntegration, hasCreative, hasAudience, hasCampaign].every(Boolean)
    ) {
      setIsOnboarded(true);
    }
  }

  useLayoutEffect(() => {
    onboardingCheck();
  }, [audienceData, integrationData, adData, activitiesCount]);

  if (
    metaIntegrationStatusLoading ||
    activitiesLoading ||
    audienceCountLoading ||
    adTemplateLoading
  ) {
    return <Spinner height={"screen"} />;
  }

  if (!isOnboarded) {
    return (
      <div>
        <Header title="Dashboard" icon={LayoutAlt01} iconColor="default" />
        <OnboardingDashboard
          setIsOnboarded={setIsOnboarded}
          activitiesCount={activitiesCount}
          integrationData={integrationData}
          adData={adData}
          audienceData={audienceData}
        />
      </div>
    );
  }

  return (
    <div>
      <Header title="Dashboard" icon={LayoutAlt01} iconColor="default" />
      <div className={"flex flex-col gap-24 py-16"}>
        <div className={"flex flex-col gap-48 px-24"}>
          <GrowthOverviewGraphWidget
            selectedCurrencyCode={selectedCurrencyCode}
          />

          <div>
            <BusinessOverviewWidget
              selectedCurrencyCode={selectedCurrencyCode}
            />
          </div>

          <div className="w-full flex flex-col gap-16">
            <Text size="lg" font="redhat" weight="medium">
              Quick Actions
            </Text>
            <Grid column={{ xs: 2 }} columnGap={{ xs: 8 }}>
              <CalloutCard
                title={"Create New Campaign"}
                icon={Announcement03}
                description={
                  "Create and launch campaigns to reach your target audience"
                }
                primaryAction={{
                  children: "Add Campaign",
                  to: "/campaigns/new",
                }}
                variant="inline"
              />
              <CalloutCard
                title={"Add New Creative"}
                icon={LibraryIcon}
                description={
                  "Add new creatives to your library to use in your campaigns"
                }
                primaryAction={{
                  children: "Add Creative",
                  to: "/library/ad-library/create",
                }}
                variant="inline"
              />
            </Grid>
          </div>

          <MacroOverview />
        </div>
        <div className="px-24">
          <CodewordResourcesList />
        </div>
      </div>
    </div>
  );
}
