import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyState from "src/components/EmptyState/EmptyState";
import { useDebouncedVariable } from "src/modules/global/hooks/useDebouncedVariable";
import { Spinner } from "../../../components/Spinner/Spinner";
import {
  AudiencesSortBy,
  useAudienceAudienceSetIndexesLazyQuery,
} from "../../../graphql/generated/schema";
import emptyAudience from "../../global/assets/empty-state-audiences.png";
import { PaginationInput } from "../../global/misc/PaginationInput";
import { TableFilter } from "src/components/TableFilter/TableFilter";
import sortOptions from "../misc/audienceSortBy";
import { Table } from "src/components/Table/Table";
import { AudienceItem } from "./AudienceItem";
import { DateRange } from "react-day-picker";
import { getDateFilterFromFilterEnum } from "src/modules/global/misc/dateFilterUtils";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";
import AuthContext from "src/stores/AuthContext";
import { audienceListingStatsMapping } from "../misc/audienceListingStatsMapping";

export const defaultDateFilter = "THIS_YEAR";

export function AudienceListLoader() {
  const [selectedItems, onSelectedChange] = useState([]);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState<AudiencesSortBy>({
    direction: "DESC",
    field: "CREATED_AT",
  });

  const { gte: from, lte: to } = getDateFilterFromFilterEnum(defaultDateFilter);

  const [selectedDate, setSelectedDate] = useState<DateRange>({
    from,
    to,
  });

  const debouncedQuery = useDebouncedVariable(query, 500);
  const [pagination] = useState<PaginationInput>({
    cursor: null,
    take: itemsPerPage,
  });

  const [audienceAudienceSetFunc, { data, loading, error, fetchMore, called }] =
    useAudienceAudienceSetIndexesLazyQuery({
      variables: {
        filter: {
          name: "",
          take: pagination.take,
          ...(pagination.cursor && {
            cursor: pagination?.cursor,
          }),
          ...(selectedDate && {
            createdAt: {
              gte: selectedDate.from,
              lte: selectedDate.to,
            },
          }),
        },
        sortBy: sortBy,
      },
    });

  useLayoutEffect(() => {
    audienceAudienceSetFunc({
      variables: {
        filter: {
          name: debouncedQuery,
          take: pagination.take,
          ...(pagination.cursor && {
            cursor: pagination?.cursor,
          }),
          ...(selectedDate && {
            createdAt: {
              gte: selectedDate.from,
              lte: selectedDate.to,
            },
          }),
        },
        sortBy: sortBy,
      },
    });
  }, [
    audienceAudienceSetFunc,
    pagination,
    sortBy,
    selectedDate,
    debouncedQuery,
  ]);

  const { activeClient } = useContext(AuthContext);
  const selectedAnalyticsCategory = activeClient?.analyticsCategory;
  const selectedAnalytics =
    audienceListingStatsMapping?.[selectedAnalyticsCategory];

  if (loading || !called) {
    return <Spinner height="screen" />;
  }

  if (error) {
    return <div> {error.message}</div>;
  }

  const audienceAudienceSetIndexes =
    data && data.audienceAudienceSetIndexes
      ? data.audienceAudienceSetIndexes.edges.map((v) => v.node)
      : [];

  async function fetchMoreAudiences() {
    await fetchMore({
      variables: {
        filter: {
          cursor: data.audienceAudienceSetIndexes.pageInfo.endCursor,
        },
      },

      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges =
          fetchMoreResult?.audienceAudienceSetIndexes?.edges ?? [];
        const oldEdges =
          previousResult?.audienceAudienceSetIndexes?.edges ?? [];

        return {
          audienceAudienceSetIndexes: {
            ...fetchMoreResult.audienceAudienceSetIndexes,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  return (
    <div>
      <TableFilter
        searchQuery={query}
        setSearchQuery={setQuery}
        dateFilter={selectedDate}
        setDateFilter={setSelectedDate}
        defaultValue={defaultDateFilter}
        setSortBy={(sortBy) => {
          setSortBy({
            direction: sortBy.direction,
            field: sortBy.field === "CREATED_AT" ? "CREATED_AT" : "NAME",
          });
        }}
        sortBy={sortBy}
        sortOptions={sortOptions}
        hasTabs={false}
        searchInputPlaceholder="Search Audiences"
      />

      {audienceAudienceSetIndexes.length === 0 ? (
        <EmptyState
          title={"Get started with audiences"}
          description="Get started by creating your first audience. Audiences are a powerful way to reach your target audience and achieve your marketing goals."
          illustration={emptyAudience}
          buttonText="Create New Audience"
          to="/audiences/new"
        />
      ) : (
        <InfiniteScroll
          next={fetchMoreAudiences}
          hasMore={data?.audienceAudienceSetIndexes?.pageInfo?.hasNextPage}
          loader={<Spinner height={200} />}
          dataLength={data?.audienceAudienceSetIndexes?.edges?.length}
        >
          <Table
            headings={[
              { title: "Audiences" },
              {
                title: "Category",
              },
              {
                title: "Est. Size",
              },
              selectedAnalytics.headers[0],
              selectedAnalytics.headers[1],
            ]}
            selectedItems={selectedItems}
            onSelectedItemsChange={onSelectedChange}
            data={audienceAudienceSetIndexes}
            idExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <AudienceItem
                selectedAnalytics={selectedAnalytics}
                item={item}
                dateFilters={{
                  dateFrom: selectedDate?.from,
                  dateTo: selectedDate?.to,
                }}
              />
            )}
          />
        </InfiniteScroll>
      )}
    </div>
  );
}
