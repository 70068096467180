import { RadioGroup } from "@headlessui/react";
import { CreditCardUp } from "@untitled-ui/icons-react";
import classNames from "classnames";
import { useContext, useState } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Logo } from "src/components/Logo/Logo";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { Text } from "src/components/Text/Text";
import {
  BillingPlanCycleEnum,
  CustomerCountRange,
  useCreateBillingMutation,
} from "src/graphql/generated/schema";
import { formatPrice } from "src/lib/formatPrice";
import GET_BILLING from "src/modules/billing/api/getBilling";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { LineHeading } from "src/modules/global/components/LineHeading";
import { Switch } from "src/modules/global/components/Switch";
import { adSpendOptions } from "src/modules/global/misc/pricingPlans";
import engageIllustration from "../assets/engage-illustration.png";
import onboarding2Image from "../assets/onboarding2-asset.png";
import styles from "./OnboardingPage2.module.scss";
import { useNavigate } from "react-router-dom";
import { SliderInput } from "src/components/SliderInput/SliderInput";
import onboardingGrid from "../assets/onboarding-grid.png";
import AuthContext from "src/stores/AuthContext";

export function OnboardingPage2() {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [onboardingState, setOnboardingState] = useState({
    selectedTab: "GROW" as "GROW" | "ENGAGE",
    selectedBillingCycle: "ANNUAL" as BillingPlanCycleEnum,
    selectedCustomerCount: "CUSTOMER_COUNT_1_TO_2500" as CustomerCountRange,
    selectedAdSpend: adSpendOptions[0],
  });
  const [billingCreate, { loading }] = useCreateBillingMutation({
    refetchQueries: [{ query: GET_BILLING }],
  });
  const [processingPlanType, setProcessingPlanType] = useState<
    "STANDARD" | "PREMIUM"
  >("STANDARD");
  const isGrowTabSelected = onboardingState.selectedTab === "GROW";

  async function handlePlanPurchase(planType: "STANDARD" | "PREMIUM") {
    setProcessingPlanType(planType);
    const shopifyIntegration = localStorage.getItem("shopifyIntegration");
    const { tokenId, shopifyShopName } = shopifyIntegration
      ? JSON.parse(shopifyIntegration)
      : { tokenId: "", shopifyShopName: "" };
    const isEngageTabSelected = !isGrowTabSelected;

    // only create billing here if it is via shopify
    if (shopifyIntegration) {
      const payload = await billingCreate({
        variables: {
          input: {
            paymentProvider: "SHOPIFY",
            shopifyTokenId: tokenId,
            cycle: onboardingState.selectedBillingCycle,
            ...(isEngageTabSelected && {
              customerCountRange: onboardingState.selectedCustomerCount,
            }),
            ...(!isEngageTabSelected && {
              monthlyAdSpendRange: onboardingState.selectedAdSpend.value,
            }),
            [isEngageTabSelected ? "engagePlanType" : "growPlanType"]: planType,
          },
        },
      });
      window.location.href = payload.data.billingCreate.paymentIntent;
    }

    // if there is no shopify intent in localStorage then send them to the macro billing page to process with stripe
    const price = onboardingState.selectedAdSpend.price;
    const params = new URLSearchParams({
      plantype: planType.toLowerCase(),
      billingcycle: onboardingState.selectedBillingCycle.toLowerCase(),
      customercount: onboardingState.selectedCustomerCount.toLowerCase(),
      adspend: onboardingState.selectedAdSpend.value.toLowerCase(),
      isengage: isEngageTabSelected.toString().toLowerCase(),
      isgrow: (!isEngageTabSelected).toString().toLowerCase(),
      price: formatPrice(
        onboardingState.selectedBillingCycle === "ANNUAL"
          ? planType === "PREMIUM"
            ? price.premiumAnnuallyAmount / 12
            : price.standardAnnuallyAmount / 12
          : planType === "PREMIUM"
          ? price.premiumMonthlyAmount
          : price.standardMonthlyAmount,
        "USD",
        0,
        true,
      ),
    });

    navigate(`/onboarding/billing?${params.toString()}`);
  }

  return (
    <div className="h-screen grid grid-rows-[auto_1fr_auto] py-20">
      <div className="p-4 flex items-center justify-center">
        <Logo type="full" style="black" size="sm" />
      </div>
      <div className="flex items-center justify-center">
        <div className="flex w-full max-w-[1100px] h-[590px]">
          <div
            className="w-full p-12 md:w-1/2 md:px-[82px] md:py-[32px] flex flex-col justify-center border rounded-16 md:border-r-0 md:rounded-r-[0] space-y-24"
            // className="w-1/2 p-10 justify-center border border-r-0 rounded-l-16 px-[82px] py-[52px] space-y-20"
          >
            <SectionHeader
              title="Start your 14 day trial"
              description="Get started in less than 2 minutes"
              size="large"
            />
            <div className={styles.switchersArea}>
              <TabsV2
                size="small"
                tabs={tabOptions}
                selectedTab={onboardingState.selectedTab}
                onTabChange={(tab) =>
                  setOnboardingState({
                    ...onboardingState,
                    selectedTab: tab,
                  })
                }
              />
              {onboardingState.selectedTab === "GROW" && (
                <div className={styles.durationSwitcher}>
                  <span className={styles.switcherLabel}>Monthly</span>
                  <Switch
                    size="medium"
                    checked={onboardingState.selectedBillingCycle === "ANNUAL"}
                    // title="selected billing cycle"
                    onChange={() =>
                      setOnboardingState({
                        ...onboardingState,
                        selectedBillingCycle:
                          onboardingState.selectedBillingCycle === "ANNUAL"
                            ? "MONTHLY"
                            : "ANNUAL",
                      })
                    }
                  />
                  <span className={styles.switcherLabel}>Yearly</span>
                </div>
              )}
            </div>
            {onboardingState.selectedTab === "GROW" && (
              <>
                <div className={styles.rangePickerContainer}>
                  <div className={styles.rangePickerTitle}>
                    <h2>
                      My monthly ad spend is upto{" "}
                      <span className={styles.rangePickerLabel}>
                        {onboardingState.selectedAdSpend.label}
                      </span>
                    </h2>
                    <div className={styles.rangePickerIcon}>
                      <CreditCardUp width={16} height={16} />
                    </div>
                  </div>

                  <div className={styles.rangeSlider}>
                    <SliderInput
                      value={0}
                      max={2}
                      step={1}
                      onValueChange={(value) => {
                        setOnboardingState({
                          ...onboardingState,
                          selectedAdSpend: adSpendOptions[value],
                        });
                      }}
                      ariaLabel="Monthly ad spend"
                    />
                  </div>
                </div>

                <LineHeading
                  content="Select Plan"
                  className={styles.plansTitle}
                />
                <RadioGroup
                  as="div"
                  className={styles.planPicker}
                  value={processingPlanType}
                  // onBlur={onBlur}
                  onChange={setProcessingPlanType}
                >
                  <RadioGroup.Option
                    className={({ checked }) =>
                      classNames(styles.planOption, {
                        [styles.planActive]: checked,
                      })
                    }
                    value={"STANDARD"}
                  >
                    <div className={styles.planContent}>
                      <div className={styles.planTitle}>Standard</div>
                      <div className={styles.planSubtitle}>
                        For Individuals and Small businesses
                      </div>
                    </div>
                    <div className={styles.planPrice}>
                      {formatPrice(
                        onboardingState.selectedBillingCycle === "ANNUAL"
                          ? onboardingState.selectedAdSpend.price
                              .standardAnnuallyAmount / 12
                          : onboardingState.selectedAdSpend.price
                              .standardMonthlyAmount,
                        "USD",
                        0,
                        true,
                      )}{" "}
                      /mo
                    </div>
                  </RadioGroup.Option>
                  <RadioGroup.Option
                    className={({ checked }) =>
                      classNames(styles.planOption, {
                        [styles.planActive]: checked,
                      })
                    }
                    value={"PREMIUM"}
                  >
                    <div className={styles.planContent}>
                      <div className={styles.planTitle}>Premium</div>
                      <div className={styles.planSubtitle}>
                        For larger teams and agencies
                      </div>
                    </div>
                    <div className={styles.planPrice}>
                      {formatPrice(
                        onboardingState.selectedBillingCycle === "ANNUAL"
                          ? onboardingState.selectedAdSpend.price
                              .premiumAnnuallyAmount / 12
                          : onboardingState.selectedAdSpend.price
                              .premiumMonthlyAmount,
                        "USD",
                        0,
                        true,
                      )}{" "}
                      /mo
                    </div>
                  </RadioGroup.Option>
                </RadioGroup>
                <ButtonV2
                  isFullWidth={true}
                  variant="solid"
                  onClick={() => handlePlanPurchase(processingPlanType)}
                >
                  Start your 14-day trial
                </ButtonV2>
                <div className={styles.freeTrialDisclaimer}>
                  100% Free for 14 days. Cancel Anytime
                </div>
              </>
            )}
            {onboardingState.selectedTab === "ENGAGE" && (
              <>
                <LineHeading content="Announcement" />
                <div className={styles.engageAnnouncement}>
                  <div className={styles.engageIllustrationContainer}>
                    <img
                      src={engageIllustration}
                      alt="engage illustration"
                      className={styles.engageIllustration}
                    />
                    <div>
                      <BadgeV2 label="Coming Soon" color="gray" size="medium" />
                    </div>
                  </div>
                  <div>
                    <h2 className={styles.engageTitle}>Macro Engage Beta</h2>
                    <p className={styles.engageDescription}>
                      Coming soon our *Engage Programme* beta! Real-time
                      analytics, seamless integration, and AI insights.
                      Subscribe for future updates and features!
                    </p>
                  </div>
                  <ButtonV2 isFullWidth={true}>
                    Contact support for access
                  </ButtonV2>
                </div>
              </>
            )}
          </div>
          <div
            style={{ backgroundImage: `url('${onboardingGrid}')` }}
            className="hidden w-1/2 bg-gray-100 gap-14 p-56 md:flex flex-col justify-center border rounded-r-16 bg-center bg-no-repeat bg-cover"
          >
            <img src={onboarding2Image} alt="Onboarding Image" />
          </div>
        </div>
      </div>
      <div className="p-4 flex items-center justify-center gap-16">
        <Text as="span" size="md" tone="subdued">
          © 2024 Codeword Pvt. Ltd.
        </Text>
        <ButtonV2 variant="link">Support</ButtonV2>
        <ButtonV2
          variant="link"
          {...(user ? { onClick: logout } : { to: "/login" })}
        >
          {user ? "Logout" : "Login"}
        </ButtonV2>
      </div>
    </div>
  );
}

const tabOptions = [
  {
    label: "Macro Grow",
    value: "GROW" as const,
    icon: () => null, // Add a placeholder icon function
  },
  {
    label: "Macro Engage",
    value: "ENGAGE" as const,
    icon: () => null, // Add a placeholder icon function
  },
];
