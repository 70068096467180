import classNames from "classnames";
import styles from "./TabsV2.module.scss";
import { SVGAttributes } from "react";

export interface ITabsV2<T> {
  tabs: {
    badge?: number;
    icon?: React.FC<SVGAttributes<SVGElement>>;
    label: React.ReactNode;
    value: T;
  }[];
  selectedTab: T;
  onTabChange: (i: T) => void;
  size?: "small" | "medium";
}

export function TabsV2<T = string>({
  tabs,
  size = "medium",
  selectedTab,
  onTabChange,
}: ITabsV2<T>) {
  return (
    <div className={styles.tab}>
      {tabs.map((tab, index) => {
        const Icon = tab.icon;

        return (
          <button
            key={index}
            onClick={() => {
              onTabChange(tab.value);
            }}
            className={classNames(styles.tabItem, {
              [styles.activeTabItem]: selectedTab === tab.value,
              [styles.small]: size === "small",
              [styles.medium]: size === "medium",
            })}
            type="button"
          >
            {Icon && <Icon width={14} height={14} />}
            <span className={styles.tabContent}>{tab.label}</span>
            {typeof tab.badge === "number" && (
              <span className={styles.badge}>{tab.badge}</span>
            )}
          </button>
        );
      })}
    </div>
  );
}
