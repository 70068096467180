import { useState } from "react";
import toast from "react-hot-toast";
import { CalloutCard } from "src/components/CalloutCard/CalloutCard";
import {
  SideDrawer,
  SideDrawerTrigger,
} from "src/modules/global/components/SideDrawer";
import { MetaPageWelcomeMessageCreateTypes } from "../misc/creativeCreateTypesV2";
import { MetaPageWelcomeMessageForm } from "./MetaPageWelcomeMessageForm";

interface MetaPageWelcomeMessageSlideDrawerProps {
  onSubmit: (i: MetaPageWelcomeMessageCreateTypes) => void;
  selectedValue?: MetaPageWelcomeMessageCreateTypes;
  userAvatar: string;
  userName: string;
}

export function MetaPageWelcomeMessageSlideDrawer({
  userAvatar,
  userName,
  selectedValue,
  onSubmit,
}: MetaPageWelcomeMessageSlideDrawerProps) {
  const [isOpen, setIsOpen] = useState(false);

  const trigger = (
    <CalloutCard
      variant="inline"
      title={selectedValue ? selectedValue.name : "Create Automated Replies"}
      description={
        selectedValue
          ? `Reply count: ${selectedValue.greeting.length}`
          : "Create automated response templates for messages received"
      }
      primaryAction={{
        children: selectedValue ? "Edit Template" : "Add Responses",
        as: SideDrawerTrigger,
        variant: "outline",
      }}
    />
  );

  function handleSubmit(values: MetaPageWelcomeMessageCreateTypes) {
    onSubmit(values);
    toast.success("Successfully created message template");
    setIsOpen(false);
  }

  return (
    <div>
      <SideDrawer
        width="90vw"
        maxWidth={1240}
        isOpen={isOpen}
        onIsOpenChange={setIsOpen}
        closeOnEscapeButton={false}
        closeOnOutsideClick={false}
        trigger={trigger}
      >
        <MetaPageWelcomeMessageForm
          userAvatar={userAvatar}
          userName={userName}
          onClose={() => setIsOpen(false)}
          onSubmit={handleSubmit}
          initialValues={selectedValue}
        />
      </SideDrawer>
    </div>
  );
}
