import styles from "./WhatsappPageWelcomeMessagePreview.module.scss";
import { PhoneNotificationBar } from "./PhoneNotificationBar";
import AttachmentIcon from "@material-symbols/svg-600/rounded/attach_file.svg?react";
import CloseIcon from "@material-symbols/svg-600/rounded/close.svg?react";
import EmojiIcon from "@material-symbols/svg-600/rounded/mood.svg?react";
import SendIcon from "@material-symbols/svg-600/rounded/send.svg?react";
import BackArrow from "@material-symbols/svg-600/rounded/arrow_back.svg?react";
import VideoCamIcon from "@material-symbols/svg-600/rounded/videocam.svg?react";
import CallIcon from "@material-symbols/svg-600/rounded/call.svg?react";
import ThreeDotIcon from "@material-symbols/svg-600/rounded/more_vert.svg?react";
import whatsappChatBackground from "../assets/whatsappChatBackground.png";
import WhatsappMic from "../../../icons/whatsapp_mic.svg?react";
import WhatsappCamera from "../../../icons/whatsapp_camera.svg?react";
import classNames from "classnames";
import { useRef } from "react";
import whatsappLinkPreview from "../assets/whatsappLinkPreview.png";
import { MetaPageWelcomeMessageInput } from "src/graphql/generated/schema";

interface IWhatsappPageWelcomeMessagePreview {
  userName: string;
  userAvatar: string;
  pageWelcomeMessage: MetaPageWelcomeMessageInput;
}

export function WhatsappPageWelcomeMessagePreview({
  pageWelcomeMessage,
  userAvatar,
  userName,
}: IWhatsappPageWelcomeMessagePreview) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const isMessageOptions = !pageWelcomeMessage?.prefilledText;
  const isPrefillText = !!pageWelcomeMessage?.prefilledText;

  function handleChange() {
    if (!textareaRef.current) {
      return;
    }

    const height = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = `${height}px`;
  }

  return (
    <div className={styles.wrapper}>
      <PhoneNotificationBar />
      <div className={styles.header}>
        <span className={styles.backIcon}>
          <BackArrow width={12} height={12} />
        </span>
        <div className={styles.userInfo}>
          <img
            className={styles.userAvatar}
            src={userAvatar}
            alt="user avatar"
            width={20}
            height={20}
          />
          <div className={styles.userNameAndType}>
            <div className={styles.userName}>{userName}</div>
            <div className={styles.userType}>Business Account</div>
          </div>
        </div>
        <div className={styles.headerActions}>
          <VideoCamIcon width={12} height={12} />
          <CallIcon width={12} height={12} />
          <ThreeDotIcon width={12} height={12} />
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${whatsappChatBackground})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className={styles.chatPanel}
      >
        <div className={styles.messageForm}>
          <div className={styles.inputAndPreview}>
            <div className={styles.adLinkPreview}>
              <div>
                <img
                  className={styles.adLinkImage}
                  alt="link preview"
                  src={whatsappLinkPreview}
                  width={49}
                  height={49}
                />
              </div>
              <div className={styles.adLinkBody}>
                <p className={styles.adLinkTitle}>{userName}</p>
                <p className={styles.adLinkSubtitle}>facebook.com/post</p>
              </div>
              <button
                className={styles.closeLinkPreview}
                type="button"
                disabled
              >
                <CloseIcon
                  color="var(--color-disabled)"
                  width={12}
                  height={12}
                />
              </button>
            </div>
            <div className={styles.greetingText}>
              {pageWelcomeMessage?.greeting}
            </div>
            {/* multi options */}

            {isMessageOptions && (
              <div className={styles.messageOptionList}>
                {pageWelcomeMessage?.MessageOptions?.map((option) => {
                  return (
                    <div
                      className={classNames(styles.messageOption, {
                        [styles.messageOptionPlaceholder]: !option.text,
                      })}
                    >
                      {option.text ? option.text : "Write a message"}
                      <button
                        className={styles.messageOptionSendBtn}
                        type="button"
                      >
                        <SendIcon fill="currentColor" width={12} height={12} />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}

            <div className={styles.inputWrapper}>
              <span
                className={classNames(
                  styles.inputActionGroup,
                  styles.inputLeftActionGroup
                )}
              >
                <button className={styles.inputAction} disabled type="button">
                  <EmojiIcon
                    width={14}
                    height={14}
                    fill="var(--color-text-disabled)"
                  />
                </button>
              </span>
              <textarea
                ref={textareaRef}
                onChange={handleChange}
                placeholder="Message"
                value={isPrefillText ? pageWelcomeMessage?.prefilledText : ""}
                readOnly
                className={classNames(styles.input, {
                  [styles.singleLine]:
                    pageWelcomeMessage?.prefilledText?.length <= 28,
                })}
              />
              <span
                className={classNames(
                  styles.inputActionGroup,
                  styles.inputRightActionGroup
                )}
              >
                {isMessageOptions && (
                  <button className={styles.inputAction} disabled type="button">
                    <AttachmentIcon
                      width={14}
                      height={14}
                      fill="rgba(157, 163, 167, 1)"
                    />
                  </button>
                )}
                <button
                  className={classNames(styles.inputAction)}
                  disabled
                  type="button"
                >
                  <WhatsappCamera
                    color="rgba(157, 163, 167, 1)"
                    width={14}
                    height={14}
                  />
                </button>
              </span>
            </div>
          </div>
          <button type="button" className={styles.sendBtn}>
            {isMessageOptions ? (
              <WhatsappMic fill="currentColor" width={12} height={12} />
            ) : (
              <SendIcon fill="currentColor" width={12} height={12} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
