import {
  Announcement03,
  CreditCard01,
  CursorClick01,
  TrendUp01,
  Users01,
} from "@untitled-ui/icons-react";

export const clientAnalyticsCategoryOptions = [
  {
    icon: CreditCard01,
    value: "TRANSACTIONAL" as const,
    title: "Transactional",
    subtitle: "Converting visitors into paying customers",
  },
  {
    icon: TrendUp01,
    value: "LEADS" as const,
    title: "Leads",
    subtitle:
      "Capturing and qualifying customer information to build a sales pipeline",
  },
  {
    icon: CursorClick01,
    value: "ENGAGEMENT" as const,
    title: "Engagement",
    subtitle: "Encouraging user interaction with your brand's content",
  },
  {
    icon: Users01,
    value: "TRAFFIC" as const,
    title: "Traffic",
    subtitle: "Directing potential customers to your website via channels",
  },
  {
    icon: Announcement03,
    value: "AWARENESS" as const,
    title: "Awareness",
    subtitle:
      "Creating visibility and recognition of your brand across channels",
  },
];
