function range(start: number, end: number, step = 1) {
  let arr = [];

  for (let i = start; i <= end; i += step) {
    arr.push(i);
  }

  return arr;
}

export const minAgeOptions = range(18, 64).map((age) => ({
  label: age.toString(),
  value: age,
}));

export const maxAgeOptions = range(19, 65).map((age) => ({
  label: age == 65 ? "65+" : age.toString(),
  value: age,
}));
