import { Form, Formik } from "formik";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./MetaLeadForm.module.scss";
import { MetaLeadFormFields } from "./MetaLeadFormFields";
import { MetaLeadCreateTypes } from "../misc/metaLeadCreateTypes";
import { SideDrawerTitle } from "src/modules/global/components/SideDrawer";
import { metaLeadFormSchema } from "../misc/metaLeadFormSchema";
import { MetaLeadFormPreviewSlider } from "./MetaLeadFormPreviewSlider";

const defaultInitialValues: MetaLeadCreateTypes = {
  name: "",
  backgroundImage: {
    isEnabled: false,
  },
  audienceGreeting: {
    isEnabled: false,
    type: "PARAGRAPH",
    headline: "",
    description: "",
    list: [""],
  },
  customDisclaimer: {
    isEnabled: false,
    description: "",
    headline: "",
  },
  customQuestion: {
    isEnabled: false,
    questions: [],
  },
  prefillQuestions: ["EMAIL"],
  prefillQuestionsDescription: "",
  privacyPolicy: {
    label: "",
    link: "",
  },
  thankYouMessage: {
    headline: "Thank you",
    description: "We have received your information.",
    additionalAction: {
      type: "VIEW_WEBSITE",
      label: "",
      link: "",
      phoneCountryCode: "91",
      phoneNumber: "",
    },
  },
};

interface IMetaLeadForm {
  onSubmit: (i: MetaLeadCreateTypes) => void;
  initialValues?: MetaLeadCreateTypes;
  onCancel: () => void;
}

export function MetaLeadForm({
  onCancel,
  onSubmit,
  initialValues = defaultInitialValues,
}: IMetaLeadForm) {
  return (
    <Formik
      validationSchema={metaLeadFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form className={styles.pageWrapper}>
            <PageHeader
              title={<SideDrawerTitle>Create Lead form</SideDrawerTitle>}
              back={onCancel}
              actions={
                <ButtonV2 onClick={onCancel} variant="outline">
                  Cancel
                </ButtonV2>
              }
            />
            <div className={styles.contentWrapper}>
              <div className={styles.formWrapper}>
                <MetaLeadFormFields onCancel={onCancel} />
              </div>
              <div className={styles.previewWrapper}>
                <h2 className={styles.previewHeading}>Form Preview</h2>

                <MetaLeadFormPreviewSlider />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
