import { useInitiateMediaUploadMutation } from "src/graphql/generated/schema";
import { validateFile } from "./validateFile";

interface Args {
  maxVideoFileSizeInBytes: number;
  maxImageFileSizeInBytes: number;
  // only valid in case of image or video
  minimumHeight?: number;
  minimumWidth?: number;
  supportedMimeTypes: string[];
}

export function useUploadFile(args: Args) {
  const [initiateMediaUpload] = useInitiateMediaUploadMutation();

  async function upload(file: File) {
    await validateFile({ ...args, file });

    let sanitizedFileName = file.name;
    sanitizedFileName = file.name.replace(/[^a-zA-Z0-9.]/g, "");

    const payload = await initiateMediaUpload({
      variables: {
        input: {
          file: {
            fileName: sanitizedFileName,
            mimeType: file.type,
          },
        },
      },
    });

    let uploadURL = payload.data.initiateMediaUpload.uploadURL;

    const url = uploadURL;
    const upload = await fetch(url, {
      method: "PUT",
      body: file,
      // headers: {
      //   "x-amz-acl": "public-read", // To make uploaded file publicly accessible
      // },
    });

    if (upload.status !== 200) {
      throw new Error("Error in uploading file");
    }

    const contentType: "IMAGE" | "VIDEO" | "CSV" | "OTHER" = file.type.includes(
      "image/",
    )
      ? "IMAGE"
      : file.type.includes("video/")
      ? "VIDEO"
      : file.type.includes("text/csv")
      ? "CSV"
      : "OTHER";

    return {
      uri: `${import.meta.env.VITE_DIGITAL_OCEAN_SPACES_CDN_URI}/${
        payload.data.initiateMediaUpload.Key
      }`,
      mediaUploadKey: payload.data.initiateMediaUpload.Key,
      name: file.name,
      type: file.type,
      contentType,
      fileSize: file.size,
    };
  }

  return upload;
}
