import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import styles from "./MetaPageWelcomeMessageForm.module.scss";
import { FormGroupHeaderWithSwitch } from "./FormGroupHeaderWithSwitch";
import FaceHappy from "@untitled-ui/icons-react/build/esm/FaceHappy";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { MultilineInputFormik } from "src/components/MultilineInputFormik/MultilineFormik";
import AnnotationQuestion from "@untitled-ui/icons-react/build/esm/AnnotationQuestion";
import { PageWelcomeMessageFaqQuestionCard } from "./PageWelcomeMessageFaqQuestionCard";
import Plus from "@untitled-ui/icons-react/build/esm/Plus";
import * as Yup from "yup";
import WhatsappOutline from "../../../icons/whatsapp.svg?react";
import { PageWelcomeMessagePreview } from "src/modules/global/components/PageWelcomeMessagePreview";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { useState } from "react";
import Instagram from "../../../icons/instagram.svg?react";
import { MetaPageWelcomeMessageCreateTypes } from "../misc/creativeCreateTypesV2";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .trim()
    .min(3, "Name should be more than 3 characters"),
  MessageOptions: Yup.array(
    Yup.object({
      text: Yup.string()
        .required("Text is required")
        .trim()
        .max(80, "Max limit is 80 characters"),
      automatedReply: Yup.string().when("hasPrefilledText", {
        is: "META",
        then: Yup.string()
          .required("Automated Reply is required")
          .max(300, "Max limit is 300 characters"),
      }),
    }),
  )
    .min(1, "At least one FAQ is required")
    // source: https://www.facebook.com/business/help/395965998733706
    .max(5, "Facebook only allows adding up to 5 questions"),
});

interface MetaPageWelcomeMessageFormProps {
  userName: string;
  userAvatar: string;
  onClose: () => void;
  onSubmit: (i: MetaPageWelcomeMessageCreateTypes) => void;
  initialValues?: MetaPageWelcomeMessageCreateTypes;
}

const defaultValue = {
  name: "",
  greeting: "Hi, Please let us know how we can help you.",
  phoneNumber: "",
  prefilledText: "",
  phoneNumberCountryCode: "91",
  hasPrefilledText: false,
  MessageOptions: [
    {
      text: "Can I make a purchase?",
      automatedReply: "",
    },
    {
      text: "I'm interested. Can you tell me more?",
      automatedReply: "",
    },
    {
      text: "Is anyone available to chat?",
      automatedReply: "",
    },
  ],
};

export function MetaPageWelcomeMessageForm({
  userName,
  userAvatar,
  onClose,
  onSubmit,
  initialValues = defaultValue,
}: MetaPageWelcomeMessageFormProps) {
  const [selectedPreviewPlatform, setSelectedPreviewPlatform] = useState(
    previewPlatformOptions[0].value,
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values: typeof initialValues) => {
        onSubmit(values);
      }}
    >
      {({ values, isSubmitting, handleSubmit }) => (
        <Form className={styles.pageWrapper}>
          <PageHeader
            title="Create Message Template"
            back={onClose}
            actions={
              <ButtonV2 variant="outline" onClick={onClose}>
                Cancel
              </ButtonV2>
            }
          />
          <div className={styles.contentWrapper}>
            <div className={styles.formWrapper}>
              <MetaPageWelcomeMessageField />
              <div className={styles.buttonGroup}>
                <ButtonV2 variant="outline" onClick={onClose}>
                  Cancel
                </ButtonV2>
                <ButtonV2
                  type="submit"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save Template
                </ButtonV2>
              </div>
            </div>
            <div className={styles.previewWrapper}>
              <h2 className={styles.previewHeading}>Form Preview</h2>
              <div className={styles.previewTabs}>
                <TabsV2
                  onTabChange={setSelectedPreviewPlatform}
                  selectedTab={selectedPreviewPlatform}
                  tabs={previewPlatformOptions}
                />
              </div>
              <PageWelcomeMessagePreview
                pageWelcomeMessage={{
                  greeting: values.greeting,
                  MessageOptions: values.MessageOptions,
                  prefilledText: values.prefilledText,
                }}
                platform={
                  selectedPreviewPlatform === "INSTAGRAM"
                    ? "INSTAGRAM"
                    : "WHATSAPP"
                }
                userAvatar={userAvatar}
                userName={userName}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

const previewPlatformOptions = [
  {
    label: "Instagram",
    value: "INSTAGRAM",
    icon: WhatsappOutline,
  },
  {
    label: "WhatsApp",
    value: "WHATSAPP",
    icon: Instagram,
  },
];

function MetaPageWelcomeMessageField() {
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <div className={styles.container}>
      <div className={styles.fieldsContainer}>
        <FormGroupHeaderWithSwitch
          title={"Greeting"}
          description={
            "Welcome people to the conversation after they've tapped on your ad"
          }
          icon={FaceHappy}
          hasSwitch={false}
        />
        <InputFormikV2
          name={"name"}
          label={"Template Name"}
          placeholder={"Eg. Sale offer ad template, discount template"}
        />
        <MultilineInputFormik
          name="greeting"
          label="Add greeting message"
          placeholder={"Add greeting message here"}
        />
      </div>

      {/* values.selectedPlatform === "META" && (
        <>
          <div className={styles.fieldsContainer}>
            <FormGroupHeaderWithSwitch
              title="Include phone number · Messenger only"
              description="Show your business phone number so that people can call you."
              icon={FaceHappy}
              hasSwitch={true}
              onSwitchChange={(value) => setFieldValue("isMessenger", value)}
              switchChecked={values?.isMessenger}
            />

            {values.isMessenger && (
              <PhoneNumberInput
                onChange={function (value: string): void {
                  setFieldValue("phoneNumber", value);
                }}
                value={values?.phoneNumber}
                defaultPhoneCode={"91"}
                countryCodeOnChange={function (value: string): void {
                  setFieldValue("phoneNumberCountryCode", value);
                }}
                countryCodeValue={values.phoneNumberCountryCode}
                label={"Phone Number (required)"}
              />
            )}
          </div>
        </>
      ) */}

      <div className={styles.fieldsContainer}>
        <FormGroupHeaderWithSwitch
          title="Frequently asked questions"
          description="Suggest questions for people to ask. Then set up automated responses to those questions."
          icon={AnnotationQuestion}
          hasSwitch={false}
        />

        <FieldArray
          name={"MessageOptions"}
          render={(fieldProps) => (
            <div className={styles.fieldsContainer}>
              {values.MessageOptions.map((v, index) => (
                <PageWelcomeMessageFaqQuestionCard
                  index={index}
                  handleDelete={() => fieldProps.remove(index)}
                  handleMove={(i) => {
                    fieldProps.move(index, i);
                  }}
                  key={index}
                />
              ))}
              <div>
                <ButtonV2
                  rightIcon={Plus}
                  variant="outline"
                  onClick={() =>
                    fieldProps.push({
                      label: "",
                      reply: "",
                    })
                  }
                >
                  Add more question
                </ButtonV2>
              </div>
            </div>
          )}
        />
      </div>
      <div className={styles.fieldsContainer}>
        <FormGroupHeaderWithSwitch
          title={"Pre-filled Message"}
          description={"Create a customizable first message for WhatsApp DMs"}
          icon={WhatsappOutline}
          hasSwitch={true}
          switchChecked={values?.hasPrefilledMessage}
          onSwitchChange={(v) => {
            setFieldValue("hasPrefilledMessage", v);
          }}
        />
        {values.hasPrefilledMessage && (
          <InputFormikV2
            name={"prefilledText"}
            label={"Prefilled Message"}
            placeholder={"Eg: How may I help you?"}
            showCharacterCount={true}
            maxCharacterCount={80}
          />
        )}
      </div>
    </div>
  );
}
