import Tooltip from "src/components/Tooltip/Tooltip";
import styles from "./AdjustPrefilledQuestionsOrder.module.scss";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";
import { useFormikContext } from "formik";
import { MetaLeadCreateTypes } from "../misc/metaLeadCreateTypes";
import { prefilledQuestionOptions } from "../misc/prefilledQuestionOptions";
import ChevronUp from "@untitled-ui/icons-react/build/esm/ChevronUp";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Trash03 from "@untitled-ui/icons-react/build/esm/Trash03";
import * as Collapsible from "@radix-ui/react-collapsible";
import { useState } from "react";
import classNames from "classnames";

export function AdjustPrefilledQuestionsOrder() {
  const { values, setFieldValue } = useFormikContext<MetaLeadCreateTypes>();
  const questions = values.prefillQuestions;
  const [isOpen, setIsOpen] = useState(false);

  function handleQuestionDelete(questionIndex: number) {
    const valueAtIndex = questions[questionIndex];

    // Email is non-removeable
    if (valueAtIndex === "EMAIL") {
      return;
    }

    const questionWithoutThisIndex = questions.filter(
      (q, i) => i !== questionIndex,
    );

    setFieldValue("prefillQuestions", questionWithoutThisIndex);
  }

  function handleQuestionMove(questionIndex: number, direction: "UP" | "DOWN") {
    if (questionIndex === 0 && direction === "UP") {
      return;
    }

    if (questionIndex === questions.length - 1 && direction === "DOWN") {
      return;
    }

    // shallow copy we don't need deep copy we are only modifying order
    const questionWithNewOrder = [...questions];
    const elToBeMoved = questionWithNewOrder[questionIndex];
    const indexToMoveAt =
      direction === "UP" ? questionIndex - 1 : questionIndex + 1;

    const elementAtThatIndex = questionWithNewOrder[indexToMoveAt];
    questionWithNewOrder[questionIndex] = elementAtThatIndex;
    questionWithNewOrder[indexToMoveAt] = elToBeMoved;

    setFieldValue("prefillQuestions", questionWithNewOrder);
  }

  return (
    <Collapsible.Root open={isOpen} onOpenChange={setIsOpen}>
      <div className={styles.wrapper}>
        <Collapsible.Trigger className={styles.heading}>
          <div className={styles.headingCopy}>
            Adjust form field order
            <Tooltip content="Reorder fields based on their importance and grouping">
              <HelpCircle
                stroke="var(--color-subdued)"
                width={14}
                height={14}
              />
            </Tooltip>
          </div>
          <div>
            {isOpen ? (
              <ChevronUp width={16} height={16} />
            ) : (
              <ChevronDown width={16} height={16} />
            )}
          </div>
        </Collapsible.Trigger>

        <Collapsible.Content>
          <div className={styles.list}>
            {values.prefillQuestions.map((prefillQuestion, questionIndex) => {
              const option = prefilledQuestionOptions.find(
                (p) => p.value === prefillQuestion,
              );

              const Icon = option.icon;

              return (
                <div className={styles.questionOption}>
                  <div className={styles.questionOptionLeftSection}>
                    <Icon width={14} height={14} />
                    {option.label}
                  </div>

                  <div className={styles.questionOptionRightSection}>
                    <div className={styles.navigationButtons}>
                      <button
                        type="button"
                        onClick={() => {
                          handleQuestionMove(questionIndex, "UP");
                        }}
                      >
                        <ChevronUp width={16} height={16} />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          handleQuestionMove(questionIndex, "DOWN");
                        }}
                      >
                        <ChevronDown width={16} height={16} />
                      </button>
                    </div>
                    <button
                      type="button"
                      className={classNames({
                        [styles.deleteDisabled]: option.value === "EMAIL",
                      })}
                      onClick={() => {
                        handleQuestionDelete(questionIndex);
                      }}
                    >
                      <Trash03 width={16} height={16} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </Collapsible.Content>
      </div>
    </Collapsible.Root>
  );
}
