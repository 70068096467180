import { Button } from "src/components/Button/Button";
import styles from "./WhatsappTemplateEmptyScreen.module.scss";
import DescriptionIcon from "@material-symbols/svg-600/rounded/description.svg?react";

interface IWhatsappTemplateEmptyScreen {
  onSelectTemplate: () => void;
}

export function WhatsappTemplateEmptyScreen({
  onSelectTemplate,
}: IWhatsappTemplateEmptyScreen) {
  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.content}>
        <span className={styles.iconWrapper}>
          <DescriptionIcon fill="currentColor" width={24} height={24} />
        </span>
        <h3 className={styles.heading}>
          Select Ad from Library and use them in this activity
        </h3>
        <h4 className={styles.subheading}>
          You need to select Ad from the library in order to launch this
          activity, if you don't first create the Ad in the library and later
          comeback to Activity Builder
        </h4>

        <div className={styles.buttonWrapper}>
          <Button onClick={onSelectTemplate} size="micro">
            <div className={styles.iconButton}>
              <DescriptionIcon width={16} height={16} fill="currentColor" />
              Select Ad
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
