import LogOut01 from "@untitled-ui/icons-react/build/esm/LogOut01";
import { ButtonV2 } from "../../../components/ButtonV2/ButtonV2";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { AIChatPanel } from "../../global/components/AIChatPanel";
import { Wizard } from "../../../components/Wizard/Wizard";
import { ChooseReportType } from "../components/ChooseReportType";
import { CreateCustomReport } from "../components/CreateCustomReport";
import styles from "./ReportBuilder.module.scss";
import { AdditionalSetting } from "../components/AdditionalSetting";
import {
  ReportDurationsEnum,
  ReportTypeEnum,
  useRecurringReportCycleCreateMutation,
  useReportCreateMutation,
} from "../../../graphql/generated/schema";
import Header from "src/components/Header/Header";

export interface FormValues {
  name: string;
  adAccountId: string;
  reportType: string;
  isRecurring: boolean;
  reportFrequency: string;
  recipientEmails?: string[];
  startedAt?: Date;
  endedAt?: Date;
  deliveryAt?: number;
  duration?: ReportDurationsEnum;
}

export function ReportBuilder() {
  const [reportCreateFunc, { loading: reportLoading }] =
    useReportCreateMutation();
  const [recurringReportCycleCreateFunc, { loading: recurringReportLoading }] =
    useRecurringReportCycleCreateMutation();
  const initialValues: FormValues = {
    name: "",
    // TODO: load default ad account id
    adAccountId: "",
    reportType: "CAMPAIGN_PERFORMANCE",
    isRecurring: false,
    reportFrequency: "ONE_TIME",
    recipientEmails: [],
  };

  return (
    <>
      <div className={styles.pageHeaderContainer}>
        <Header
          showNotifications={false}
          title="Report Builder"
          actions={
            <>
              <ButtonV2
                to="/reports"
                rightIcon={LogOut01}
                variant="outline"
                size="small"
              >
                Exit
              </ButtonV2>
            </>
          }
        />
      </div>
      <div className={styles.page}>
        <Wizard
          onResetField={(fieldKey, value, data) => {
            return data;
          }}
          onStepComplete={async (stepNumber, data) => {
            if (stepNumber === 3) {
              if (data.reportFrequency === "RECURRING") {
                const formattedDeliveryAtDate = data.deliveryAt
                  ? new Date()
                  : undefined;
                if (formattedDeliveryAtDate) {
                  formattedDeliveryAtDate.setHours(data.deliveryAt);
                }

                await recurringReportCycleCreateFunc({
                  variables: {
                    input: {
                      name: data.name,
                      dataSource: "META",
                      reportType: data.reportType as ReportTypeEnum,
                      recepientEmails: data.recipientEmails,
                      ...(data.adAccountId && {
                        metaAdAccountId: data.adAccountId,
                      }),
                      ...(formattedDeliveryAtDate && {
                        deliveryAt: formattedDeliveryAtDate,
                      }),
                      ...(data.duration && { duration: data.duration }),
                    },
                  },
                });
              } else {
                await reportCreateFunc({
                  variables: {
                    input: {
                      name: data.name,
                      dataSource: "META",
                      reportType: data.reportType as ReportTypeEnum,
                      recepientEmails: data.recipientEmails,
                      ...(data.adAccountId && {
                        metaAdAccountId: data.adAccountId,
                      }),
                      ...(data.duration && { duration: data.duration }),
                      ...(data.startedAt && { startedAt: data.startedAt }),
                      ...(data.endedAt && { endedAt: data.endedAt }),
                    },
                  },
                });
              }
              window.location.href = "/reports";
            }
          }}
          initialData={initialValues}
          // initialActiveStepIndex={initialActiveStepIndex}
        >
          <div className={styles.container}>
            <div className={styles.stepsWrapper}>
              <CreateCustomReport currentStepIndex={0} />
              <ChooseReportType currentStepIndex={1} />
              <AdditionalSetting currentStepIndex={2} />
            </div>
            {/* <div>
              <AIChatPanel />
            </div> */}
          </div>
        </Wizard>
      </div>
    </>
  );
}
