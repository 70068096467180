import { ReactNode } from "react";
import styles from "./Alert.module.scss";
import { ButtonV2, IButtonV2 } from "../ButtonV2/ButtonV2";
import { Dialog } from "../Dialog/Dialog";
import { InfoCircle } from "@untitled-ui/icons-react";
import { IconWithWrapper } from "../IconWithWrapper/IconWithWrapper";

interface Action {
  label: ReactNode;
  loading?: boolean;
  onClick?: () => void;
  link?: string;
}

export interface IAlert {
  trigger: ReactNode;
  title: string;
  description: ReactNode;
  tone?: "info" | "warning" | "danger";
  isOpen: boolean;
  onClose: () => void;
  primaryAction: Action;
  secondaryAction?: Action;
}

export default function Alert({
  trigger,
  title,
  description,
  tone = "info",
  isOpen,
  onClose,
  primaryAction,
  secondaryAction,
}: IAlert) {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={onClose}
      maxWidth={"424px"}
      noPadding
      trigger={trigger}
    >
      <div className={styles.headerContainer}>
        <IconWithWrapper icon={InfoCircle} iconColor={tone} />
        <h3 className={styles.title}>{title}</h3>
      </div>
      <p className={styles.description}>{description}</p>
      <div className={styles.actions}>
        {secondaryAction && (
          <ButtonV2
            variant="outline"
            size="small"
            onClick={secondaryAction.onClick}
            to={secondaryAction.link}
            loading={secondaryAction.loading}
          >
            {secondaryAction.label}
          </ButtonV2>
        )}
        <ButtonV2
          size="small"
          onClick={primaryAction.onClick}
          to={primaryAction.link}
          loading={primaryAction.loading}
        >
          {primaryAction.label}
        </ButtonV2>
      </div>
    </Dialog>
  );
}
