import { useState } from "react";
import {
  Organization,
  useGetUserQuery,
} from "../../../graphql/generated/schema";
import styles from "./DeleteOrganization.module.scss";
import { DeleteOrganizationAlert } from "./DeleteOrganizationAlert";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Building03 } from "@untitled-ui/icons-react";
import { IconWithWrapper } from "src/components/IconWithWrapper/IconWithWrapper";
import { Text } from "src/components/Text/Text";
import { Spinner } from "src/components/Spinner/Spinner";
import { ErrorHandler } from "src/components/ErrorHandler";
import { Dialog } from "src/components/Dialog/Dialog";
import { customerSupportEmail } from "src/modules/global/misc/customerSupportEmail";

export function DeleteOrganization({
  organization,
}: {
  organization: Organization;
}) {
  const { data, loading, error } = useGetUserQuery();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  if (loading) return <Spinner height={500} />;

  if (error) return <ErrorHandler error={error} />;

  // Only the creator of the organisation can delete it
  if (organization.Creator.id !== data.me.id) return null;

  return (
    <div className="border rounded-12 border-neutral-50 px-12 py-16 flex flex-col">
      <div className="flex flex-col gap-12">
        <IconWithWrapper
          isBordered
          icon={Building03}
          iconColor="default"
          size="lg"
        />

        <div className="flex flex-col gap-8">
          <Text size="md" weight="medium">
            Delete Organisation
          </Text>
          <Text size="xs" tone="subdued">
            If you want to delete your organisation and all of it's information,
            you can do so, just you need to verify yourself.
          </Text>
        </div>
      </div>
      <DeleteOrganizationAlert
        organization={organization}
        trigger={
          <div className={styles.wrapper}>
            <ButtonV2
              as={Dialog.Trigger}
              variant="outline"
              isDestructive
              onClick={() => {
                setShowDeleteConfirmationModal(true);
              }}
            >
              Delete Organisation
            </ButtonV2>
          </div>
        }
        onDelete={() => {
          // email to us with the organisation name and the user email
          const queryParams = new URLSearchParams();
          queryParams.set("subject", "Delete Organisation request");
          queryParams.set("body", `Organisation Name: ${organization.name}\n`);
          const email = customerSupportEmail;

          window.open(`mailto:${email}?${queryParams.toString()}`, "_blank");
        }}
      />
    </div>
  );
}
