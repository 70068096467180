import { ThumbsUp } from "@untitled-ui/icons-react";
import { AudiencePresetAndInterestSelectorModal } from "./AudiencePresetAndInterestSelectorModal";
import { ReviewCard } from "./ReviewCard";
import { removeFromObject } from "src/lib/removeFromObject";
import { upsertItemInObject } from "../functions/upsertItemInObject";
import { MetaInterest } from "src/graphql/generated/schema";
import { AudiencePresetAndInterestSelectorFormState } from "../misc/audienceCreateTypes";

interface InterestsReviewCardProps {
  values: AudiencePresetAndInterestSelectorFormState;
  setFieldValue: (field: string, value: any) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  adAccountId: string;
}

export function InterestsReviewCard({
  values,
  setFieldValue,
  isModalOpen,
  setIsModalOpen,
  adAccountId,
}: InterestsReviewCardProps) {
  return (
    <AudiencePresetAndInterestSelectorModal
      defaultSelectedTab="INTERESTS"
      preSelectedAudiencesPresets={values.audiencePresets}
      preSelectedInterests={values.interests}
      adAccountId={adAccountId}
      onIsOpenChange={setIsModalOpen}
      isOpen={isModalOpen}
      onSubmit={(data) => {
        setFieldValue("audiencePresets", data.presets);
        setFieldValue("interests", data.interests);
        setIsModalOpen(false);
      }}
      trigger={
        <ReviewCard
          title="Review interests and behaviors"
          description="Verify and check your chosen interests and behaviors to continue"
          icon={ThumbsUp}
          adAccountId={adAccountId}
          onSelectSuggestion={(interest) => {
            setFieldValue(
              "interests",
              upsertItemInObject(values.interests, interest.id, {
                ...(interest as MetaInterest),
                value: "INCLUDE",
              }),
            );
          }}
          onSelectSearch={(interest) => {
            setFieldValue(
              "interests",
              upsertItemInObject(values.interests, interest.id, {
                ...(interest as MetaInterest),
                value: "INCLUDE",
              }),
            );
          }}
          onAddMore={() => setIsModalOpen(true)}
          onDelete={(id) => {
            setFieldValue("interests", removeFromObject(values.interests, id));
          }}
          values={values}
          type="interests"
          onChange={(interest, value) => {
            setFieldValue(
              "interests",
              upsertItemInObject(values.interests, interest.id, {
                ...(interest as MetaInterest),
                value,
              }),
            );
          }}
        />
      }
    />
  );
}
