import ChevronLeft from "@untitled-ui/icons-react/build/esm/ChevronLeft";
import { IAdPreview } from "../misc/creativePreviewTypes";
import styles from "./FacebookReelAdPreview.module.scss";
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle";
import FacebookCameraIcon from "../../../icons/facebook_camera.svg?react";
import { FacebookLikeIcon } from "src/modules/campaign-wizard/components/FacebookLikeIcon";
import { FacebookCommentIcon } from "src/modules/campaign-wizard/components/FacebookCommentIcon";
import { FacebookShareIcon } from "src/modules/campaign-wizard/components/FacebookShareIcon";
import { useKeenSlider } from "keen-slider/react";
import { titleCase } from "src/lib/titleCase";
import DotsHorizontal from "@untitled-ui/icons-react/build/esm/DotsHorizontal";
import { LineItem } from "src/lib/types";
import { useEffect } from "react";

export function FacebookReelAdPreview({
  activeAssetIndex,
  lineItems,
  caption,
  ctaLabel,
  setActiveAssetIndex,
  userName: username,
  userAvatar,
}: IAdPreview) {
  const [sliderRef, sliderInstance] = useKeenSlider({
    slides: {
      perView: 1,
    },
    initial: activeAssetIndex,
    slideChanged(change) {
      setActiveAssetIndex(change.track.details.abs);
    },
  });

  useEffect(() => {
    if (
      sliderInstance.current &&
      // if not already on the active asset index,
      sliderInstance.current.track.details.abs !== activeAssetIndex
    ) {
      // move to the active asset index
      sliderInstance.current.moveToIdx(activeAssetIndex);
    }
  }, [activeAssetIndex, sliderInstance]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.previewScreen}>
        <div className={styles.topOverlayBackground} />

        <div className={styles.appHeader}>
          <div className={styles.appHeaderLeft}>
            <ChevronLeft width={12} height={12} />
            <span className={styles.appHeaderTitle}>Reels</span>
          </div>
          <div className={styles.appHeaderRight}>
            <button className={styles.createButton} type="button">
              <FacebookCameraIcon width={12} height={12} />
              <span className={styles.createButtonCopy}>Create</span>
            </button>
            <img
              className={styles.userAvatar}
              src={userAvatar}
              alt={username}
              width={18}
              height={18}
            />
          </div>
        </div>

        <div className={styles.creativeContainer}>
          <FacebookAsset keenSliderRef={sliderRef} lineItems={lineItems} />
        </div>

        <div className={styles.bottomOverlayBackground} />

        <div className={styles.details}>
          <div className={styles.aboutPost}>
            <div className={styles.userInfo}>
              <img
                width={18}
                height={18}
                className={styles.userAvatar}
                src={userAvatar}
                alt="user avatar"
              />
              <div className={styles.userNameWrapper}>
                <span className={styles.username}>{username}</span>
                <span className={styles.sponsored}>Sponsored</span>
              </div>
            </div>
            <div className={styles.reelBodyCaption}>{caption}</div>
            <div className={styles.ctaWrapper}>
              <button type="button" disabled className={styles.cta}>
                {titleCase(ctaLabel, "_")}
              </button>
            </div>
          </div>

          <div className={styles.postActions}>
            <div className={styles.postActionItem}>
              <span className={styles.postActionIcon}>
                <FacebookLikeIcon
                  width={16}
                  height={16}
                  fill="var(--color-white)"
                />
              </span>
              <span className={styles.postActionCount}>5000</span>
            </div>

            <div className={styles.postActionItem}>
              <span className={styles.postActionIcon}>
                <FacebookCommentIcon
                  width={16}
                  height={16}
                  fill="var(--color-white)"
                />
              </span>
              <span className={styles.postActionCount}>300</span>
            </div>

            <div className={styles.postActionItem}>
              <span className={styles.postActionIcon}>
                <FacebookShareIcon
                  stroke="var(--color-white)"
                  strokeWidth={4}
                  width={16}
                  height={16}
                />
              </span>
              <span className={styles.postActionCount}>22k</span>
            </div>

            <div className={styles.postActionItem}>
              <span className={styles.postActionIcon}>
                <DotsHorizontal strokeWidth={4} width={16} height={16} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.addCommentSection}>
        <input
          readOnly
          className={styles.addCommentInput}
          disabled
          placeholder="Add Comment"
        />
      </div>
    </div>
  );
}

interface IFacebookAsset {
  lineItems: LineItem[];
  keenSliderRef: (node: HTMLElement) => void;
}

function FacebookAsset({ lineItems, keenSliderRef }: IFacebookAsset) {
  const assets = lineItems.map(
    (lineItem) => lineItem.StoryAsset ?? lineItem.PostAsset
  );

  if (assets.length === 0) {
    return (
      <div className={styles.emptyImageSection}>
        <AlertCircle color="var(--color-disabled)" width={14} height={14} />
        <span className={styles.emptyImageSectionText}>
          Select Ad to see the preview
        </span>
      </div>
    );
  }

  if (assets.length > 1) {
    return (
      <div className={styles.post}>
        <div className="keen-slider" ref={keenSliderRef}>
          {assets.map((asset, index) => (
            <div className="keen-slider__slide" key={index}>
              {asset.contentType === "IMAGE" ? (
                <img src={asset.uri} alt="Post" className={styles.postImage} />
              ) : (
                <video
                  src={asset.uri}
                  className={styles.postImage}
                  autoPlay
                  muted
                >
                  <source src={asset.uri} />
                </video>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (assets[0].contentType === "IMAGE") {
    return (
      <div className={styles.post}>
        <img src={assets[0].uri} alt="Post" className={styles.postImage} />
      </div>
    );
  }

  if (assets[0].contentType === "VIDEO") {
    return (
      <div className={styles.post}>
        <video src={assets[0].uri} className={styles.postImage} autoPlay muted>
          <source src={assets[0].uri} />
        </video>
      </div>
    );
  }

  return null;
}
