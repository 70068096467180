import toFixed from "src/lib/toFixed";
import { IValueType } from "../../../components/StatCard/StatCard";
import { formatNumberOrDefault } from "src/lib/formatNumberOrDefault";

export function formatNumberByType(
  value: number | undefined,
  valueType: IValueType,
  options?: {
    showDecimal: boolean;
    selectedCurrencyCode?: string;
  },
) {
  if (!value) {
    return "-";
  }

  switch (valueType) {
    case "CURRENCY":
      return `${options?.selectedCurrencyCode ?? "₹"}${formatNumberOrDefault(
        value,
        "-",
        options,
      )}`;
    case "MULTIPLIER":
      // chop off the 0 if the value after decimal is 0
      return `${toFixed(value, 2)}x`;
    case "NUMBER":
      return `${formatNumberOrDefault(value, "-", options)}`;
    case "PERCENTAGE":
      // chop off the 0 if the value after decimal is 0
      return `${toFixed(value, 2)}%`;
  }
}
