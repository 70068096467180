import { FileSearch02 } from "@untitled-ui/icons-react";
import { AudiencePresetAndInterestSelectorModal } from "./AudiencePresetAndInterestSelectorModal";
import { ReviewCard } from "./ReviewCard";
import { removeFromObject } from "src/lib/removeFromObject";
import { upsertItemInObject } from "../functions/upsertItemInObject";
import { IAudiencePreset } from "../misc/IAudiencePreset";
import { AudiencePresetAndInterestSelectorFormState } from "../misc/audienceCreateTypes";

interface AudiencePresetReviewCardProps {
  values: AudiencePresetAndInterestSelectorFormState;
  setFieldValue: (field: string, value: any) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  adAccountId: string;
}

export function AudiencePresetReviewCard({
  values,
  setFieldValue,
  isModalOpen,
  setIsModalOpen,
  adAccountId,
}: AudiencePresetReviewCardProps) {
  return (
    <AudiencePresetAndInterestSelectorModal
      preSelectedAudiencesPresets={values.audiencePresets}
      preSelectedInterests={values.interests}
      defaultSelectedTab="AUDIENCE_PRESET"
      adAccountId={adAccountId}
      onIsOpenChange={setIsModalOpen}
      isOpen={isModalOpen}
      onSubmit={(data) => {
        setFieldValue("audiencePresets", data.presets);
        setFieldValue("interests", data.interests);
        setIsModalOpen(false);
      }}
      trigger={
        <ReviewCard
          title="Review presets"
          description="Verify and check your chosen audience presets to continue"
          icon={FileSearch02}
          onAddMore={() => setIsModalOpen(true)}
          onDelete={(id) => {
            setFieldValue(
              "audiencePresets",
              removeFromObject(values.audiencePresets, id),
            );
          }}
          adAccountId={adAccountId}
          onSelectSuggestion={() => {
            // Not needed for audience presets
          }}
          onSelectSearch={(audiencePreset) => {
            setFieldValue(
              "audiencePresets",
              upsertItemInObject(values.audiencePresets, audiencePreset.id, {
                ...(audiencePreset as IAudiencePreset),
                value: "INCLUDE",
              }),
            );
          }}
          values={values}
          type="presets"
          onChange={(audiencePreset, value) => {
            setFieldValue(
              "audiencePresets",
              upsertItemInObject(values.audiencePresets, audiencePreset.id, {
                ...(audiencePreset as IAudiencePreset),
                value,
              }),
            );
          }}
        />
      }
    />
  );
}
