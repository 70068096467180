import styles from "./CustomersEmpty.module.scss";
import emptyState from "../assets/emptyCustomers.png";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Text } from "src/components/Text/Text";

export function CustomersEmpty() {
  return (
    <>
      <div className={styles.emptyPage}>
        <div className={styles.card}>
          <img className={styles.image} src={emptyState} alt="no customers" />
          <div className={styles.cardDetails}>
            <Text size="xl" weight="medium">
              No Contacts Yet? Let's Get Started!
            </Text>
            <Text tone="gray-700">
              Your contacts will appear here once your lead generation campaign
              is live. There's no manual adding needed—just create a campaign,
              and we'll automatically show the contacts you gather.
            </Text>
            <div className={styles.actions}>
              <ButtonV2 size="small" to="/library/ad-library/create">
                Create Lead Form
              </ButtonV2>
              <ButtonV2 size="small" to="/campaigns/new" variant="outline">
                Create Campaign
              </ButtonV2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
