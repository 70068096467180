import Avatar from "react-avatar";
import { ErrorHandler } from "../../../components/ErrorHandler";
import { Spinner } from "../../../components/Spinner/Spinner";
import { User } from "../../../graphql/generated/schema";
import MoreVert from "@material-symbols/svg-600/rounded/more_vert.svg?react";
import styles from "./OrganizationMembersTable.module.scss";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import Tooltip from "src/components/Tooltip/Tooltip";
import { DropdownV2 } from "src/modules/campaign/components/DropdownV2";
import {
  Table,
  TableCell,
  TablePrimaryCell,
  TableRow,
} from "src/components/Table/Table";
import { titleCase } from "src/lib/titleCase";

interface IOrganizationMembersTable {
  error: any;
  loading: boolean;
  organizationUsers: User[];
  isCurrentUserAdmin: boolean;
  creatorId: string;
  triggerManageMemberModal: (i: string) => void;
}

export function OrganizationMembersTable({
  error,
  loading,
  isCurrentUserAdmin,
  creatorId,
  organizationUsers,
  triggerManageMemberModal,
}: IOrganizationMembersTable) {
  if (error) {
    return (
      <div>
        <ErrorHandler error={error} />
      </div>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Table
      variant="card"
      isSelectable={false}
      headings={[
        {
          title: "",
        },
        {
          title: "Name and email",
        },
        {
          title: "Clients",
        },
        {
          title: "Role",
        },
        {
          title: "",
        },
      ]}
      data={organizationUsers}
      idExtractor={(item) => item.id}
      renderItem={({ item }) => (
        <MemberItem
          user={item}
          onManagePermissions={() => triggerManageMemberModal(item.id)}
        />
      )}
    />
  );
}

interface IMemberItem {
  user: User;
  onManagePermissions: () => void;
}

function MemberItem({ user, onManagePermissions }: IMemberItem) {
  return (
    <TableRow id={user.id}>
      <TableCell width="32px">
        <Avatar round name={user.name} className={styles.avatar} size="24" />
      </TableCell>
      <TablePrimaryCell title={user.name} subtitle={user.email} />

      <TableCell width="376px">
        <div className={styles.clientsList}>
          {user.Clients?.slice(0, 2).map((v) => (
            <BadgeV2
              avatar={{
                name: v.name,
              }}
              label={v.name}
            />
          ))}
          {user.Clients?.length > 2 && (
            <Tooltip content={`${user.Clients.map((a) => a.name).join(", ")}`}>
              <BadgeV2 label={`+${user.Clients.length - 2}`} />
            </Tooltip>
          )}
        </div>
      </TableCell>
      <TableCell width="120px">{titleCase(user.role)}</TableCell>
      <TableCell width="32px">
        <DropdownV2
          icon={MoreVert}
          buttonVariant="plain"
          hideArrow
          size="small"
          options={[
            {
              label: "Manage permissions",
              onClick: onManagePermissions,
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
}
