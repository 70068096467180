import {
  MetaAdAccount,
  useMetaEnableCatalogueMutation,
  useMetaEnableInstagramAccountMutation,
  useMetaEnablePageMutation,
  useMetaEnablePixelMutation,
} from "../../../graphql/generated/schema";
import { runFuncForMultipleItems } from "../functions/runToggleFuncForMultipleItems";

export function useMetaAdAccountItemsEnable() {
  const [enablePixelFunc] = useMetaEnablePixelMutation();
  const [enablePageFunc] = useMetaEnablePageMutation();
  const [enableInstagramAccountFunc] = useMetaEnableInstagramAccountMutation();
  const [enableCatalogueFunc] = useMetaEnableCatalogueMutation();

  // NOTE: This assumes that all the items provided are not already enabled in backend
  // thus no mutation for removing existing items
  async function handleEnable(adAccount: MetaAdAccount) {
    let metaPages = [];
    let metaPixels = [];
    let metaInstagramAccounts = [];
    let metaCatalogues = [];
    await runFuncForMultipleItems(adAccount.MetaPages, async (item) => {
      if (item.enabled) {
        const { data: enablePageData } = await enablePageFunc({
          variables: {
            input: {
              adAccountId: adAccount.metaAccountId,
              metaPageId: item.metaPageId,
            },
          },
          refetchQueries: ["metaEnabledAdAccounts"],
          awaitRefetchQueries: true,
        });
        metaPages.push(enablePageData.metaPageEnable);
      }
    });

    //call for ig Accounts
    await runFuncForMultipleItems(
      adAccount?.MetaInstagramAccounts || [],
      async (item) => {
        if (item.enabled) {
          const { data: enableInstagramAccountData } =
            await enableInstagramAccountFunc({
              variables: {
                input: {
                  adAccountId: adAccount.metaAccountId,
                  metaInstagramId: item.metaInstagramId,
                },
              },
            });
          metaInstagramAccounts.push(
            enableInstagramAccountData.metaInstagramAccountEnable
          );
        }
      }
    );

    // call for pixels
    await runFuncForMultipleItems(adAccount?.MetaPixels || [], async (item) => {
      if (item.enabled) {
        const { data: enabledPixelData } = await enablePixelFunc({
          variables: {
            input: {
              adAccountId: adAccount.metaAccountId,
              metaPixelId: item.metaPixelId,
            },
          },
        });
        metaPixels.push(enabledPixelData.metaPixelEnable);
      }
    });

    // call for catalogues
    await runFuncForMultipleItems(
      adAccount?.MetaCatalogues || [],
      async (item) => {
        if (item.enabled) {
          const { data: enabledCatalogueData } = await enableCatalogueFunc({
            variables: {
              input: {
                adAccountId: adAccount.metaAccountId,
                metaCatalogueId: item.metaCatalogueId,
              },
            },
          });
          metaCatalogues.push(enabledCatalogueData.metaCatalogueEnable);
        }
      }
    );

    // TODO: if there are single items only then set the ad account as default
    return { metaPages, metaPixels, metaCatalogues, metaInstagramAccounts };
  }

  return { handleEnable };
}
