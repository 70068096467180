import Modal from "../../../components/Modal/Modal";
import {
  useClientUpdateMutation,
  useClientQuery,
} from "src/graphql/generated/schema";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { ClientFieldsForm } from "./ClientFieldsForm";
import { ClientCreateInput } from "../misc/ClientCreateInput";
import { useMemo } from "react";
import { Spinner } from "../../../components/Spinner/Spinner";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { UploadProfilePicture } from "src/components/UploadProfilePicture/UploadProfilePicture";
import { useCreateAsset } from "src/modules/campaign-wizard/functions/useCreateAsset";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Users01, X } from "@untitled-ui/icons-react";

const updateClientSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(2, "Client Name should be more than 3 characters")
    .required("Client name is required")
    .matches(
      /^[a-zA-Z0-9_\-,:\s]+$/g,
      "name should not have any special characters",
    ),
});

export function EditClientModal({ isOpen, onClose, id }) {
  const { data: clientData, loading } = useClientQuery({
    variables: {
      id,
    },
  });
  const [clientUpdateFunc] = useClientUpdateMutation();

  async function handleSubmit(values: ClientCreateInput) {
    try {
      const { data } = await clientUpdateFunc({
        variables: {
          input: {
            id: values.id,
            name: values.name,
            ...(values.industry && {
              industry: values.industry,
            }),
            ...(values.Thumbnail && {
              assetId: values.Thumbnail.id,
            }),
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: ["organizationClients", "getUser", "activeClient"],
      });

      if (data) {
        toast.success("Successfully updated client");
        onClose();
      }
    } catch (err) {
      if (err.extensions.code === "FORBIDDEN") {
        toast.error(err.message);
        return;
      }
      toast.error("An unknown error occurred");
    }
  }

  const initialValues: ClientCreateInput = useMemo(() => {
    if (clientData && clientData.client) {
      const client = clientData.client;
      return {
        timezoneCode: client.timeZone,
        currencyCode: client.currencyCode,
        id: id,
        name: client.name,
        Thumbnail: client.Thumbnail,
        members: [],
        industry: client.industry,
      };
    }

    return {
      timezoneCode: "Asia/Kolkata",
      currencyCode: "",
      id: id,
      name: "",
      Thumbnail: null,
      members: [],
    };
  }, [clientData, loading]);

  return (
    <Formik
      isInitialValid
      enableReinitialize
      validateOnMount
      validationSchema={updateClientSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, setFieldValue, isValid, isSubmitting }) => (
        <Form>
          <Modal
            isOpen={isOpen}
            closeOnBlur={false}
            onClose={onClose}
            maxWidth={"560px"}
            footer={{
              footerContent: "",
              hideBorder: true,
              footerActions: (
                <div className="flex gap-6">
                  <ButtonV2
                    size="small"
                    variant="outline"
                    onClick={onClose}
                    children="Cancel"
                  />
                  <ButtonV2
                    size="small"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    disabled={isSubmitting || !isValid}
                  >
                    Edit Client
                  </ButtonV2>
                </div>
              ),
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <ModalContent
                onClose={onClose}
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  );
}

function ModalContent({ values, setFieldValue, onClose }) {
  const { handleFileUpload, loading } = useCreateAsset({
    validMimeType: ["image/jpeg", "image/png", "image/jpg"],
    maxImageFileSizeInBytes: 10 * 1024 * 1024, // 10MB
    maxVideoFileSizeInBytes: 0,
    minimumHeight: 120,
    minimumWidth: 120,
  });

  return (
    <div className="flex flex-col gap-20 w-full">
      <div className="flex justify-between items-center pt-20">
        <SectionHeader
          size="large"
          icon={Users01}
          hasIconWrapper
          title="Edit Client"
        />
        <ButtonV2 size="small" variant="plain" onClick={onClose} leftIcon={X} />
      </div>
      <div className="flex items-center py-16 gap-12">
        <UploadProfilePicture
          updateLoading={loading}
          helpText="Upload a profile picture for your client"
          currentAsset={values.Thumbnail}
          onUpdate={async (file) => {
            const asset = await handleFileUpload(file);
            setFieldValue("Thumbnail", asset);
          }}
          onRemove={() => setFieldValue("Thumbnail", null)}
        />
      </div>
      <ClientFieldsForm
        showMembers={false}
        disableCurrencySelector={true}
        disableTimeZoneSelector={true}
      />
    </div>
  );
}
