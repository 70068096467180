import styles from "./MetaIntegrationForm.module.scss";
import enableMetaIntegration from "../functions/enableMetaIntegration";
import { Wizard } from "src/components/Wizard/Wizard";
import { ConnectWithMeta } from "./ConnectWithMeta";
import {
  MetaAdAccount,
  MetaEnabledAdAccountsDocument,
  MetaInstagramAccount,
  MetaIntegrationDefaultSettingsDocument,
  MetaIntegrationStatusEnum,
  MetaPage,
  MetaPixel,
  useMetaIntegrationDefaultSettingsUpsertMutation,
} from "src/graphql/generated/schema";
import { EnableAdAccount } from "./EnableAdAccount";
import { ManageAdAccount } from "./ManageAdAccount";
import { DefaultAdAccountSettings } from "./DefaultAdAccountSettings";
import toast from "react-hot-toast";
import { useMetaAdAccountItemsEnable } from "../hooks/useMetaAdAccountItemsEnable";
import { useState } from "react";
import { useMultipleAdAccountEnable } from "../hooks/useMultipleAdAccountEnable";

interface IMetaIntegrationForm {
  status: MetaIntegrationStatusEnum;
}

/**
FLOW
-> Take input from enabled ad accounts -> save in step wizard data
-> take input from enabled pixels and pages -> save in step wizard data
-> save default items in data

-> Submit flow
   1. Enable all selected ad accounts
   2. enable all the pixel and pages
   3. set default items


- Avoid Loader Components
- Forms will always be inside the respective step forms but will only append data to the final step on submit
- Fetching And Data loading via apis can be done inside the components itself. For example MetaPageList, MetaPixelList and MetaCatalogues
- Mutation related to submit in a different hook / function
**/
function resetAdAccountsRelatedFields({ fieldValue, data }) {
  let allAdAccounts: MetaAdAccount[] = data.adAccounts;
  const selectedItem = allAdAccounts.find(
    (a) => a.metaAccountId === fieldValue.metaAccountId
  );

  console.log({ data, fieldValue });
  if (selectedItem) {
    allAdAccounts = allAdAccounts.filter(
      (account) => account.metaAccountId !== fieldValue.metaAccountId
      // ? { ...account, enabled: !account.enabled }
      // : account
    );
  } else {
    allAdAccounts.push({
      ...fieldValue,
      enabled: true,
    });
  }

  return {
    adAccounts: allAdAccounts,
  };
}

interface WizardData {
  adAccounts: MetaAdAccount[];
  defaultSettings?: {
    pixelId: string;
    pageId: string;
    instagramAccountId: string;
    adAccountId: string;
  };
}

export default function MetaIntegrationWizard({
  status,
}: IMetaIntegrationForm) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    adAccounts: [],
  } as WizardData;
  const [metaIntegrationDefaultSettingsUpsertFunc] =
    useMetaIntegrationDefaultSettingsUpsertMutation();
  const { handleEnable: handleMetaAdAccountItemsEnable } =
    useMetaAdAccountItemsEnable();
  const { handleMultipleAdAccountEnable } = useMultipleAdAccountEnable();

  function getInitialStep() {
    return status === "NOT_CONNECTED" ? 0 : 1;
  }

  async function handleSubmit(data: WizardData, helpers: any) {
    setIsSubmitting(true);
    try {
      let metaPages: MetaPage[] = [];
      let metaInstagramAccounts: MetaInstagramAccount[] = [];
      let metaPixels: MetaPixel[] = [];
      // metaCatalogues: MetaCatalogue[],
      const enabledAccountsInput = data.adAccounts.filter((v) => v.enabled);
      const enabledAdAccounts = await handleMultipleAdAccountEnable(
        enabledAccountsInput
      );

      for (const adAccount of enabledAccountsInput) {
        const enabledElements = await handleMetaAdAccountItemsEnable(adAccount);
        metaPages.push(...enabledElements.metaPages);
        metaInstagramAccounts.push(...enabledElements.metaInstagramAccounts);
        metaPixels.push(...enabledElements.metaPixels);
        // metaCatalogues = enabledElements.metaCatalogues;
      }

      console.log({ metaPages, metaPixels, metaInstagramAccounts });

      const substitutedDefaultSettingsInput = Object.keys(
        data.defaultSettings
      ).reduce((acc, v) => {
        // this meta id of selected object
        const valueInInput = data.defaultSettings[v];
        let substitueValue: string;
        if (!valueInInput) {
          return acc;
        }
        // find selected object id in the respective array
        switch (v) {
          case "pixelId":
            substitueValue = metaPixels.find(
              (pixel) => pixel.metaPixelId === valueInInput
            )?.id;
            break;
          case "pageId":
            substitueValue = metaPages.find(
              (page) => page.metaPageId === valueInInput
            )?.id;
            break;
          case "instagramAccountId":
            substitueValue = metaInstagramAccounts.find(
              (instagramAccount) =>
                instagramAccount.metaInstagramId === valueInInput
            )?.id;
            break;
          case "adAccountId":
            substitueValue = enabledAdAccounts.find(
              (account) => account.metaAccountId === valueInInput
            )?.id;
            break;
        }

        if (!substitueValue) {
          return acc;
        }

        acc[v] = substitueValue;
        return acc;
      }, {} as typeof data.defaultSettings);

      console.log(substitutedDefaultSettingsInput);

      if (!substitutedDefaultSettingsInput?.pageId) {
        throw new Error("Unknown error occured");
      }
      await metaIntegrationDefaultSettingsUpsertFunc({
        variables: {
          input: {
            ...substitutedDefaultSettingsInput,
            adAccountId: substitutedDefaultSettingsInput.adAccountId,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: MetaEnabledAdAccountsDocument,
            variables: { filter: { hasEnabledMetaPages: true } },
          },
          { query: MetaIntegrationDefaultSettingsDocument },
        ],
      });

      window.location.href = "/settings/integrations/meta";
      toast.success("Successfully integrated");
      setIsSubmitting(false);
    } catch (err) {
      console.log(err);
      // HACK: in case of error revert back to previous step
      helpers.markStepAsDone(helpers.activeStepIndex - 1, data);
      setIsSubmitting(false);
      toast.error(err.message);
    }
  }

  return (
    <div className={styles.page}>
      <Wizard
        initialData={initialValues}
        initialActiveStepIndex={getInitialStep()}
        onStepComplete={async (nextStep, data, helpers) => {
          if (nextStep === 4) {
            await handleSubmit(data, helpers);
          }
        }}
        onResetField={async function (
          fieldKey: string,
          fieldValue: any,
          data: WizardData
        ) {
          if (fieldKey === "adAccounts") {
            return resetAdAccountsRelatedFields({ data, fieldValue });
          }

          return data;
        }}
      >
        <ConnectWithMeta connectWithMetaFunc={enableMetaIntegration} />{" "}
        {status === "INCOMPLETE" && (
          <>
            <EnableAdAccount loading={isSubmitting} />
            <ManageAdAccount loading={isSubmitting} />
            <DefaultAdAccountSettings loading={isSubmitting} />
          </>
        )}
      </Wizard>
    </div>
  );
}

// TODO: remove after this is stable
// await metaIntegrationDefaultSettingsUpsertFunc({
//   variables: {
//     input: {
//       adAccountId: selectedAdAccountId,
//       instagramAccountId: values.instagramAccountId,
//       pageId: values.pageId,
//       pixelId: values.pixelId,
//     },
//   },
//   awaitRefetchQueries: true,
//   refetchQueries: [
//     {
//       query: MetaEnabledAdAccountsDocument,
//       variables: { filter: { hasEnabledMetaPages: true } },
//     },
//     { query: MetaIntegrationDefaultSettingsDocument },
//   ],
// });
// toast.success("Updated default settings successfully");

// export default function MetaIntegrationForm({ status }: IMetaIntegrationForm) {
//   const { data: enabledAdAccountsData, loading: enabledAdAccountsLoading } =
//     useMetaEnabledAdAccountsQuery();
//   const [metaEnableAdAccountFunc] = useMetaEnableAdAccountMutation();
//   const [removePageFunc] = useMetaRemovePageMutation();
//   const [enablePageFunc] = useMetaEnablePageMutation();
//   const [metaRemoveAdAccountFunc] = useMetaRemoveAdAccountMutation();

//   async function handleAdAccountToggle(adAccount: MetaAdAccount) {
//     let enabledAdAccount: MetaAdAccount;
//     if (!adAccount.enabled) {
//       await metaRemoveAdAccountFunc({
//         variables: {
//           metaAdAccountId: adAccount.id,
//         },
//         refetchQueries: [
//           { query: META_AD_ACCOUNTS, variables: {} },
//           { query: metaEnabledAdAccounts, variables: {} },
//           { query: META_INTEGRATION_DEFAULT_SETTING, variables: {} },
//         ],
//         awaitRefetchQueries: true,
//       });
//       // toast("Ad Account Disabled");
//     } else {
//       const { data } = await metaEnableAdAccountFunc({
//         variables: {
//           metaAdAccountId: adAccount.id,
//         },
//         refetchQueries: [
//           { query: META_AD_ACCOUNTS, variables: {} },
//           { query: metaEnabledAdAccounts, variables: {} },
//           { query: META_INTEGRATION_DEFAULT_SETTING, variables: {} },
//         ],
//         awaitRefetchQueries: true,
//       });
//       if (data?.metaAdAccountEnable?.userError) {
//         const graphqlError = data?.metaAdAccountEnable?.userError?.message;
//         toast.error(graphqlError);
//         throw new Error(graphqlError);
//       }
//       enabledAdAccount = data.metaAdAccountEnable.response;
//       // toast("Ad Account Enabled");
//     }
//     return enabledAdAccount;
//   }

//   if (enabledAdAccountsLoading) {
//     return <Spinner />;
//   }

//   const enabledAdAccounts = enabledAdAccountsData
//     ? enabledAdAccountsData.metaEnabledAdAccounts
//     : [];

//   function getInitialActiveStep() {
//     if (enabledAdAccounts.length > 0) {
//       if (enabledAdAccounts.some((v) => v.MetaPages.length > 0)) {
//         return 3;
//       }
//       return 2;
//     }

//     return status !== "NOT_CONNECTED" ? 1 : 0;
//   }

//   const initialValues: MetaIntegrationFormValues = {
//     adAccounts: enabledAdAccounts.map((v) => ({
//       ...v,
//       MetaPixels: v.MetaPixels
//         ? v.MetaPixels?.map((v) => ({ ...v, enabled: true }))
//         : [],
//       MetaPages: v.MetaPages
//         ? v.MetaPages?.map((v) => ({ ...v, enabled: true }))
//         : [],
//       MetaInstagramAccounts: v.MetaInstagramAccounts
//         ? v.MetaInstagramAccounts?.map((v) => ({
//             ...v,
//             enabled: true,
//           }))
//         : [],
//       MetaCatalogues: v.MetaCatalogues
//         ? v.MetaCatalogues?.map((v) => ({ ...v, enabled: true }))
//         : [],
//     })),
//   };

//   return (
//     <div className={styles.page}>
//       <Wizard
//         initialData={initialValues}
//         initialActiveStepIndex={getInitialActiveStep()}
//         onStepComplete={async (nextStep, data) => {
//           console.log({ nextStep, data });
//           if (nextStep === 2) {
//             for (const adAccount of data.adAccounts) {
//               // await handleAdAccountToggle(adAccount);
//             }

//             return;
//           }

//           // TODO: handle
//           // on complete refresh the page
//           if (nextStep === 4) {
//             window.location.href = "/settings/integrations/meta";
//           }
//         }}
//         onResetField={async function (
//           fieldKey: string,
//           fieldValue: any,
//           data: any
//         ) {
//           console.log({ fieldKey, fieldValue, data });
//           if (fieldKey === "adAccounts") {
//             let allAdAccounts = data.adAccounts;

//             if (
//               allAdAccounts.find(
//                 (a) => a.metaAccountId === fieldValue.metaAccountId
//               )
//             ) {
//               allAdAccounts = allAdAccounts.map((adAccount) => {
//                 if (adAccount.metaAccountId === fieldValue.metaAccountId) {
//                   console.log({ fieldValue, adAccount });
//                   return {
//                     ...adAccount,
//                     enabled: !adAccount.enabled,
//                   };
//                 }

//                 return adAccount;
//               });
//             } else {
//               allAdAccounts.push({
//                 ...fieldValue,
//                 enabled: true,
//               });
//             }

//             console.log({ allAdAccounts });

//             for (const adAccount of allAdAccounts) {
//               await handleAdAccountToggle(adAccount);
//             }

//             return {
//               adAccounts: allAdAccounts,
//             };
//           }

//           // we can use the regex but we are using this to extract the variables as well
//           if (
//             fieldKey.startsWith("adAccounts[") &&
//             fieldKey.endsWith("].MetaPages")
//           ) {
//             const adAccountIndex = fieldKey
//               .replace("adAccounts[", "")
//               .replace("].MetaPages", "");
//             console.log({ adAccountIndex });
//             const adAccount = data.adAccounts[adAccountIndex];
//             const pageEnabledCurrentValue = fieldValue.enabled;
//             const pageEnabledNewValue = !pageEnabledCurrentValue;

//             console.log({
//               adAccount,
//               pageEnabledCurrentValue,
//               pageEnabledNewValue,
//             });

//             if (pageEnabledNewValue === true) {
//               await enablePageFunc({
//                 variables: {
//                   input: {
//                     adAccountId: adAccount.metaAccountId,
//                     metaPageId: fieldValue.metaPageId,
//                   },
//                 },
//                 refetchQueries: ["metaEnabledAdAccounts"],
//                 awaitRefetchQueries: true,
//               });
//             } else {
//               await removePageFunc({
//                 variables: {
//                   input: {
//                     adAccountId: adAccount.metaAccountId,
//                     metaPageId: fieldValue.metaPageId,
//                   },
//                 },
//               });
//             }

//             const clonedData = JSON.parse(JSON.stringify(data));

//             console.log({ clonedData });

//             const metaPages = clonedData.adAccounts[adAccountIndex].MetaPages;
//             const indexOfPageInArray = metaPages.findIndex(
//               (m) => m.metaPageId === fieldValue.metaPageId
//             );

//             if (indexOfPageInArray > -1) {
//               metaPages[indexOfPageInArray].enabled = pageEnabledNewValue;
//             } else {
//               metaPages.push({
//                 ...fieldValue,
//                 enabled: pageEnabledNewValue,
//               });
//             }

//             console.log({ clonedData });

//             return clonedData;
//           }

//           return data;
//         }}
//       >
//         <ConnectWithMeta connectWithMetaFunc={enableMetaIntegration} />
//         {status === "INCOMPLETE" && (
//           <>
//             <EnableAdAccount />
//             <ManageAdAccount />
//             <DefaultAdAccountSettings />
//           </>
//         )}
//       </Wizard>
//       {/*
//       <IntegrationBanner
//           title="Integrate Meta platform"
//           subtitle={
//             "Connect Meta Platform to publish facebook and Instagram, ads, add signup forms, and access your Instagram ads in Macro's Campaign builder. Manage Facebook and Audiences and Accounts"
//           }
//           action={{
//             label: "Integrate Meta",
//             onClick: enableMetaIntegration,
//           }}
//           background={`url(${metaBanner})`}
//           image={meta}
//         />

//         <IntegrationSteps
//           heading={"Before you start"}
//           steps={beforeArr}
//           image={BeforeYouStart}
//         />
//         <IntegrationSteps
//           heading={"What does it do?"}
//           steps={whatArr}
//           image={WhatDoesItDo}
//           subHeading="Here are the following"
//         />
//         <IntegrationNeedHelp />
//       */}
//     </div>
//   );
// }
