import {
  Audience_Preset_Type,
  Audience_Type,
} from "src/graphql/generated/schema";
import { IAudiencePreset } from "./IAudiencePreset";
import Facebook from "../../../icons/facebook_in_circle.svg?react";
import Instagram from "../../../icons/instagram.svg?react";
import { Globe01 } from "@untitled-ui/icons-react";

const customAudiencePresets: IAudiencePreset[] = [
  {
    name: "Facebook Fan",
    description: "People who have engaged with the facebook page",
    audienceType: "FACEBOOK_ENGAGERS",
    id: "FACEBOOK_FAN",
    group: { label: "Retargeting", value: "AudienceCategory_2" },
    platforms: ["FACEBOOK"],
    filteringSource: "FACEBOOK",
    icon: Facebook,
    filter: {
      // 180 days
      duration: 15552000,
    },
  },
  {
    name: "Instagram Fan",
    description: "People who have engaged with the instagram page",
    id: "INSTAGRAM_FAN",
    audienceType: "INSTAGRAM_ENGAGERS",
    group: { label: "Retargeting", value: "AudienceCategory_2" },
    platforms: ["INSTAGRAM"],
    filteringSource: "INSTAGRAM",
    icon: Instagram,
    filter: {
      duration: 15552000,
    },
  },
  {
    name: "All Website Visitors (30 days)",
    description: "All Website Visitors in the last 30days",
    audienceType: "FACEBOOK_PIXEL_BASED",
    id: "ALL_VISITOR_30",
    group: { label: "Retargeting", value: "AudienceCategory_2" },
    platforms: ["FACEBOOK", "INSTAGRAM"],
    filteringSource: "WEBSITE",
    icon: Globe01,
    filter: {
      pixelEventName: "visit",
      duration: 2592000,
      countries: ["IN"],
    },
  },
  {
    name: "All Website visitors (180 days)",
    description: "All Website Visitors in the last 180 days",
    audienceType: "FACEBOOK_PIXEL_BASED",
    id: "ALL_VISITOR_180",
    group: { label: "Retargeting", value: "AudienceCategory_2" },
    platforms: ["FACEBOOK", "INSTAGRAM"],
    filteringSource: "WEBSITE",
    icon: Globe01,
    filter: {
      pixelEventName: "visit",
      duration: 15552000,
      countries: ["IN"],
    },
  },
  {
    name: "All Add to cart (30 days)",
    description: "All Add to cart in the last 30 days",
    audienceType: "FACEBOOK_PIXEL_BASED",
    id: "ALL_ATC_30",
    group: { label: "Retargeting", value: "AudienceCategory_2" },
    platforms: ["FACEBOOK", "INSTAGRAM"],
    filteringSource: "WEBSITE",
    icon: Globe01,
    filter: {
      pixelEventName: "AddToCart",
      duration: 2592000,
      countries: ["IN"],
    },
  },
  {
    name: "All Add to cart (180 days)",
    description: "All Add to cart in the last 180 days",
    audienceType: "FACEBOOK_PIXEL_BASED",
    id: "ALL_ATC_180",
    group: { label: "Retargeting", value: "AudienceCategory_2" },
    filteringSource: "WEBSITE",
    platforms: ["FACEBOOK", "INSTAGRAM"],
    icon: Globe01,
    filter: {
      pixelEventName: "AddToCart",
      duration: 15552000,
      countries: ["IN"],
    },
  },
  {
    name: "All Purchasers (30 days)",
    description: "All Purchasers in the last 30 days",
    audienceType: "FACEBOOK_PIXEL_BASED",
    id: "ALL_PURCHASES_30",
    group: { label: "Retention", value: "AudienceCategory_3" },
    platforms: ["FACEBOOK", "INSTAGRAM"],
    filteringSource: "WEBSITE",
    icon: Globe01,
    filter: {
      pixelEventName: "Purchase",
      duration: 2592000,
      countries: ["IN"],
    },
  },
  {
    name: "All Purchasers (180 days)",
    description: "All Purchasers in the last 180 days",
    id: "ALL_PURCHASES_180",
    audienceType: "FACEBOOK_PIXEL_BASED",
    group: { label: "Retention", value: "AudienceCategory_3" },
    platforms: ["FACEBOOK", "INSTAGRAM"],
    filteringSource: "WEBSITE",
    icon: Globe01,
    filter: {
      pixelEventName: "Purchase",
      duration: 15552000,
      countries: ["IN"],
    },
  },
];

// const audienceSets: IAudienceSetPreset[] = [
//   {
//     name: "Ecommerce Super Lookalike (180 days)",
//     description: "Ecommerce Super Lookalike in the last 180 days",
//     id: "ECOMMERCE_SUPER_LOOKALIKE",
//     group: "SUPERLOOKALIKE" as IAudienceGroup,
//     platforms: ["FACEBOOK"],
//     filteringSource: "WEBSITE",
//     filters: [
//       {
//         type: "FACEBOOK_PIXEL_BASED_LOOKALIKE",
//         pixelEventName: "Purchase",
//         duration: 15552000,
//         percentage: 1,
//         countries: ["IN"],
//       },
//       {
//         type: "FACEBOOK_PIXEL_BASED_LOOKALIKE",
//         pixelEventName: "AddToCart",
//         duration: 15552000,
//         percentage: 1,
//         countries: ["IN"],
//       },
//       {
//         type: "FACEBOOK_PIXEL_BASED_LOOKALIKE",
//         pixelEventName: "visit",
//         duration: 15552000,
//         countries: ["IN"],
//         percentage: 1,
//       },
//     ],
//   },
// ];

const audiencePresetsWithLookalike: IAudiencePreset[] = customAudiencePresets
  .filter(
    (v) =>
      !v.audienceType.includes("SEGMENT") &&
      !v.audienceType.includes("SUPER_LOOKALIKE"),
  )
  .map((v) => ({
    ...v,
    name: v.name + " Lookalike",
    group: { label: "Prospecting", value: "AudienceCategory_1" },
    id: (v.id + "_LOOKALIKE") as Audience_Preset_Type,
    icon: v.icon,
    audienceType: (v.audienceType + "_LOOKALIKE") as Audience_Type,
    filter: {
      ...v.filter,
      percentage: 1,
      // country is compulsory in lookalike
      countries: ["IN"],
    },
  }));

const audiencePresets = [
  ...customAudiencePresets,
  ...audiencePresetsWithLookalike,
];

export default audiencePresets;
