import ArrowUp from "@material-symbols/svg-600/rounded/arrow_upward.svg?react";
import ArrowDownward from "@material-symbols/svg-600/rounded/arrow_downward.svg?react";

const defaultSortOptions = [
  {
    type: "Created At",
    value: "CREATED_AT",
    options: [
      {
        label: "Newest",
        value: "DESC",
        icon: <ArrowUp />,
      },
      {
        label: "Oldest",
        value: "ASC",
        icon: <ArrowDownward />,
      },
    ],
  },
  {
    type: "Alphabetic",
    value: "NAME",
    options: [
      {
        label: "A to Z",
        value: "ASC",
        icon: <ArrowUp />,
      },
      {
        label: "Z to A",
        value: "DESC",
        icon: <ArrowDownward />,
      },
    ],
  },
];

export default defaultSortOptions;
