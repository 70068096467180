import { IAdPreview } from "../misc/creativePreviewTypes";
import styles from "./InstagramStoryAdPreview.module.scss";
import DotsVertical from "@untitled-ui/icons-react/build/esm/DotsVertical";
import AlertCircle from "@untitled-ui/icons-react/build/esm/AlertCircle";
import ChevronUp from "@untitled-ui/icons-react/build/esm/ChevronUp";
import InstagramShare from "../../../icons/instagram_share.svg?react";
import InstagramVerified from "../../../icons/instagram_verified.svg?react";
import Link01 from "@untitled-ui/icons-react/build/esm/Link01";
import classNames from "classnames";
import { titleCase } from "src/lib/titleCase";
import { clampValue } from "src/lib/clampValue";
import { LineItem } from "src/lib/types";

export function InstagramStoryAdPreview({
  lineItems,
  activeAssetIndex,
  setActiveAssetIndex,
  caption,
  isProductCatalogue,
  ctaLabel,
  userName: username,
  userAvatar,
}: IAdPreview) {
  // product catalogue only result in 1 story item
  const storyItems = isProductCatalogue ? [lineItems[0]] : lineItems;

  return (
    <div className={styles.wrapper}>
      <div className={styles.creativeBackground}>
        <div className={styles.topOverlayBackground} />
        <div className={styles.topOverlay}>
          <div className={styles.progressBar}>
            {storyItems.map((_asset, index) => (
              <div
                key={index}
                className={classNames(styles.progressBarItem, {
                  [styles.progressBarItemActive]: activeAssetIndex >= index,
                })}
              />
            ))}
          </div>
          <div className={styles.header}>
            <img
              className={styles.userAvatar}
              src={userAvatar}
              alt={username}
              width={18}
              height={18}
            />
            <div className={styles.userInfo}>
              <span className={styles.userName}>
                {username}
                <InstagramVerified width={7} height={7} />
              </span>
              <span className={styles.userInfoSponsored}>Sponsored</span>
            </div>
            <div className={styles.headerActions}>
              <DotsVertical width={14} height={14} />
            </div>
          </div>
        </div>

        <div className={styles.creativeContent}>
          <InstagramAsset
            isProductCatalogue={isProductCatalogue}
            activeAssetIndex={activeAssetIndex}
            lineItems={lineItems}
          />
        </div>

        {/* non visible buttons that let you navigate between slides */}
        <div className={styles.navigationButtons}>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setActiveAssetIndex(
                clampValue(activeAssetIndex - 1, 0, lineItems.length - 1),
              );
            }}
            className={styles.leftNavigationButton}
          />
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setActiveAssetIndex(
                clampValue(activeAssetIndex + 1, 0, lineItems.length - 1),
              );
            }}
            className={styles.rightNavigationButton}
          />
        </div>

        <div className={styles.cta}>
          <Link01 width={18} height={18} />
          <span>{titleCase(ctaLabel, "_")}</span>
        </div>

        <div className={styles.bottomBar}>
          <div className={styles.swipeUp}>
            <ChevronUp width={20} height={20} />
          </div>
          <InstagramShare width={16} height={16} />
        </div>
      </div>
    </div>
  );
}

interface IInstagramAsset {
  lineItems: LineItem[];
  activeAssetIndex: number;
  isProductCatalogue: boolean;
}

function InstagramAsset({
  isProductCatalogue,
  activeAssetIndex,
  lineItems,
}: IInstagramAsset) {
  const assets = lineItems.map(
    (lineItem) => lineItem.StoryAsset ?? lineItem.PostAsset,
  );

  const activeAsset = assets[activeAssetIndex];

  if (!activeAsset) {
    return (
      <div className={styles.emptyImageSection}>
        <AlertCircle color="var(--color-disabled)" width={14} height={14} />
        <span className={styles.emptyImageSectionText}>
          Select Ad to see the preview
        </span>
      </div>
    );
  }

  if (isProductCatalogue) {
    const top4Assets = assets.slice(0, 4);

    return (
      <div className={styles.catalogueWrapper}>
        <div className={styles.productCatalogue}>
          {top4Assets.map((asset, index) => (
            <div className={styles.productItem}>
              <div className={styles.productTitle}>
                {lineItems[activeAssetIndex].headline}
              </div>
              <img
                key={index}
                src={asset.uri}
                alt="Product"
                className={styles.productCatalogueImage}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (activeAsset.contentType === "IMAGE") {
    return (
      <img src={activeAsset.uri} alt="Post" className={styles.postImage} />
    );
  }

  if (activeAsset.contentType === "VIDEO") {
    return (
      <video src={activeAsset.uri} className={styles.postImage} autoPlay muted>
        <source src={activeAsset.uri} />
      </video>
    );
  }

  return null;
}
