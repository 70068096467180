import { useRef, useState } from "react";
import { Form, useNavigate, useSearchParams } from "react-router-dom";
import Alert from "src/components/Alert/Alert";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import {
  Activity,
  useActivityCreateMutation,
  useActivityPublishMutation,
  useActivityQuery,
  useOutreachCreateMutation,
  useOutreachPublishMutation,
} from "src/graphql/generated/schema";
import {
  transformActivity,
  transformOutreach,
} from "../functions/transformCampaignInput";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import {
  facebookPlacements,
  instagramPlacements,
} from "../misc/placementOptions";
import Header from "src/components/Header/Header";
import { LogOut01 } from "@untitled-ui/icons-react";
import CAMPAIGNS from "src/graphql/campaigns";
import { Spinner } from "src/components/Spinner/Spinner";
import transformActivityToActivityInput from "../functions/transformActivityToActivityInputV2";
import { Formik } from "formik";
import { ActivityGetStarted } from "../components/ActivityGetStarted";
import { ActivityObjectiveAndOthers } from "../components/ActivityObjectiveAndOthers";
import { SegmentSelector } from "../components/SegmentSelector";
import { AudienceSelector } from "../components/AudienceSelector";
import { WhatsappTemplateSelector } from "../components/WhatsappTemplateSelector";
import { AdSelector } from "../components/AdSelector";

export function ActivityDuplicatePage() {
  const [activityCreateFn] = useActivityCreateMutation();
  const [outreachCreateFn] = useOutreachCreateMutation();
  const [publishActivity] = useActivityPublishMutation();
  const [publishOutreach] = useOutreachPublishMutation();

  const navigate = useNavigate();
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const getStartedRef = useRef<HTMLDivElement>();
  const objectiveAndOthersRef = useRef<HTMLDivElement>();
  const audienceSelectorRef = useRef<HTMLDivElement>();
  const adSelectorRef = useRef<HTMLDivElement>();
  const id = searchParams.get("id");
  const { data, loading, error } = useActivityQuery({
    variables: {
      id,
    },
  });
  let initialData = {
    activityName: "",
    platform: "META",
    adPlacementOptions: [...facebookPlacements, ...instagramPlacements],
    shouldCreateCampaign: false,
  } as ActivityInputV2;
  // const moreOptionsRef = useRef<HTMLDivElement>();

  if (loading) {
    return <Spinner height={"full"} />;
  }

  if (data && data.activity) {
    const olderValues = initialData;

    const transformedValues = transformActivityToActivityInput(
      data.activity as Activity,
    );

    console.log({ transformedValues });

    initialData = {
      ...olderValues,
      ...transformedValues,
    };
  }

  async function handleCampaignCreate(values: ActivityInputV2) {
    if (values.platform === "META") {
      const transformedValues = transformActivity(values);

      const { data } = await activityCreateFn({
        variables: {
          input: transformedValues,
        },
      });

      const { data: publishDataPayload } = await publishActivity({
        variables: {
          activityId: data.activityCreate.id,
        },
        refetchQueries: [
          {
            query: CAMPAIGNS,
            variables: {
              sortBy: {
                direction: "DESC",
                field: "CREATED_AT",
              },
              filters: {
                limit: 10,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      // if (publishDataPayload.campaignPublish.userError) {
      //   throw publishDataPayload.campaignPublish.userError;
      // }

      navigate(
        `/campaigns/${data.activityCreate.Campaign.id}/activities/${data.activityCreate.id}`,
      );
    } else if (values.platform === "WHATSAPP") {
      const transformedValues = transformOutreach(values);

      const { data } = await outreachCreateFn({
        variables: {
          input: transformedValues,
        },
      });

      console.log({ data });

      const { data: publishDataPayload } = await publishOutreach({
        variables: {
          outreachId: data.outreachCreate.id,
        },
        refetchQueries: [
          {
            query: CAMPAIGNS,
            variables: {
              sortBy: {
                direction: "DESC",
                field: "CREATED_AT",
              },
              filters: {
                limit: 10,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      // if (publishDataPayload.campaignPublish.userError) {
      //   throw publishDataPayload.campaignPublish.userError;
      // }

      navigate(`/campaigns/${data.outreachCreate.Campaign.id}`);
    }
  }

  function discardChanges() {
    setIsDiscardAlertShowing(false);
    navigate("/campaigns");
  }

  function stay() {
    setIsDiscardAlertShowing(false);
  }

  return (
    <div>
      <Header
        showNotifications={false}
        showBack
        actions={
          <ButtonV2
            onClick={() => {
              setIsDiscardAlertShowing(true);
            }}
            size="small"
            variant="outline"
            rightIcon={LogOut01}
          >
            Exit
          </ButtonV2>
        }
        title="Activity Builder"
      />

      <Alert
        trigger={<></>}
        isOpen={isDiscardAlertShowing}
        onClose={() => setIsDiscardAlertShowing(false)}
        secondaryAction={{
          label: "Reset Progress",
          onClick: discardChanges,
        }}
        primaryAction={{
          label: "Cancel",
          onClick: stay,
        }}
        title="All the progress will be reset"
        description=" Are you sure? This action won't be possible to revert back. All the
        progress will lost. Click “Reset Progress” to continue."
      />
      {/*
        <WorkshopBanner
          height={148}
          backgroundGradient="linear-gradient(90deg, rgba(203, 225, 251, 0.00) 13.31%, #CBE7FB 99.68%)"
          heading="Streamlined marketing activity creation for maximum impact"
          subheading="Effortlessly create Standard Ads, Dynamic Ads, and Existing Post Ads with our user-friendly interface. Enhance your marketing campaigns with impactful, clutter-free ads and engage your audience like never before!"
          image={{
            src: bannerImage,
            height: 148,
            width: 303,
            alt: "globe",
          }}
        />
      */}
      <div className="w-full max-w-[1070px] mx-auto">
        <Formik
          initialValues={initialData}
          onSubmit={async (data) => {
            await handleCampaignCreate(data);
          }}
        >
          {({
            values,
            setFieldValue,
            errors,
            setValues,
            touched,
            submitCount,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <div className="flex justify-center items-center w-full">
                <div className="flex flex-col gap-24">
                  <ActivityGetStarted
                    sectionRef={getStartedRef}
                    values={values}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    errors={errors}
                    submitCount={submitCount}
                    touched={touched}
                  />

                  <ActivityObjectiveAndOthers
                    sectionRef={objectiveAndOthersRef}
                    values={values}
                    setValues={setValues}
                    showMetaIntegrationSettingsButton={false}
                    isObjectiveSelectorDisabled={true}
                  />

                  {values.platform === "WHATSAPP" ? (
                    <SegmentSelector
                      sectionRef={audienceSelectorRef}
                      errors={errors}
                      submitCount={submitCount}
                      touched={touched}
                    />
                  ) : (
                    <AudienceSelector
                      sectionRef={audienceSelectorRef}
                      values={values}
                      errors={errors}
                      submitCount={submitCount}
                      touched={touched}
                    />
                  )}
                  {values.platform === "WHATSAPP" ? (
                    <WhatsappTemplateSelector
                      sectionRef={adSelectorRef}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ) : (
                    <AdSelector
                      sectionRef={adSelectorRef}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}

                  <div className="pt-14 pb-14 flex justify-end gap-4">
                    <ButtonV2
                      disabled={!isValid}
                      to="/campaigns"
                      variant="outline"
                    >
                      Cancel
                    </ButtonV2>
                    <ButtonV2
                      loading={isSubmitting}
                      disabled={isSubmitting || !isValid}
                      onClick={handleSubmit}
                    >
                      Publish Activity
                    </ButtonV2>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
