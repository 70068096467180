import { MetricCard } from "src/components/MetricCard/MetricCard";
import StatCard from "src/components/StatCard/StatCard";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import { GrowAggregateType } from "../../global/misc/GrowAggregateType";
import MetaIcon from "../../../icons/meta.svg?react";
import { getRelativePercentage } from "src/modules/global/functions/getRelativePercentage";

const statCardOptions: {
  title: string;
  subtitle: string;
  valueType: "NUMBER" | "CURRENCY" | "PERCENTAGE";
  valueKey: keyof GrowAggregateType;
}[] = [
  {
    title: "Orders Count",
    subtitle: "Total order counts",
    valueType: "NUMBER" as const,
    valueKey: "countPurchase",
  },
  {
    title: "Avg. Order Value",
    subtitle: "average order value",
    valueType: "CURRENCY" as const,
    valueKey: "costPerAddToCart",
  },
  {
    title: "Add to carts",
    subtitle: "No. of times items were added",
    valueType: "NUMBER" as const,
    valueKey: "countAddToCart",
  },
  {
    title: "Initiate Checkouts",
    subtitle: "No. of times checkout has started",
    valueType: "NUMBER" as const,
    valueKey: "countInitiateCheckout",
  },
  {
    title: "CPM",
    subtitle: "CPM in facebook activity",
    valueType: "CURRENCY" as const,
    valueKey: "cpm",
  },
];

interface Props {
  activityStat: GrowAggregateType;
  comparisonStat?: GrowAggregateType;
}

export function ActivitySingleStatCards({
  activityStat,
  comparisonStat,
}: Props) {
  return (
    <>
      {statCardOptions.map((statOption) => {
        const relativePercentage = getRelativePercentage(
          activityStat?.[statOption.valueKey],
          comparisonStat?.[statOption.valueKey] || 0,
        );
        return (
          <MetricCard
            {...statOption}
            sourceIcon={MetaIcon}
            performanceBadge={
              comparisonStat && comparisonStat[statOption.valueKey]
                ? {
                    direction:
                      comparisonStat?.[statOption.valueKey] >
                      activityStat?.[statOption.valueKey]
                        ? "down"
                        : "up",
                    value: `${relativePercentage}%`,
                  }
                : null
            }
            value={formatNumberByType(
              activityStat?.[statOption.valueKey],
              statOption.valueType,
            )}
          />
        );
      })}
    </>
  );
}
