import Briefcase01 from "@untitled-ui/icons-react/build/esm/Briefcase01";
import Building05 from "@untitled-ui/icons-react/build/esm/Building05";
import CalendarHeart01 from "@untitled-ui/icons-react/build/esm/CalendarHeart01";
import Globe01 from "@untitled-ui/icons-react/build/esm/Globe01";
import Globe04 from "@untitled-ui/icons-react/build/esm/Globe04";
import Hearts from "@untitled-ui/icons-react/build/esm/Hearts";
import Home02 from "@untitled-ui/icons-react/build/esm/Home02";
import Mail01 from "@untitled-ui/icons-react/build/esm/Mail01";
import Mail02 from "@untitled-ui/icons-react/build/esm/Mail02";
import MarkerPin01 from "@untitled-ui/icons-react/build/esm/MarkerPin01";
import MarkerPin06 from "@untitled-ui/icons-react/build/esm/MarkerPin06";
import Phone from "@untitled-ui/icons-react/build/esm/Phone";
import Phone01 from "@untitled-ui/icons-react/build/esm/Phone01";
import FaceHappy from "@untitled-ui/icons-react/build/esm/FaceHappy";
import User03 from "@untitled-ui/icons-react/build/esm/User03";
import { LeadGenPrefilledQuestionsTypeEnum } from "src/graphql/generated/schema";
import { SVGAttributes } from "react";

export const prefilledQuestionOptions: {
  label: string;
  disabled?: boolean;
  value: LeadGenPrefilledQuestionsTypeEnum;
  icon: React.FC<SVGAttributes<SVGElement>>;
}[] = [
  {
    label: "Email",
    disabled: true,
    value: "EMAIL",
    icon: Mail01,
  },
  {
    label: "Full Name",
    value: "FULL_NAME",
    icon: User03,
  },
  {
    label: "Phone",
    value: "PHONE",
    icon: Phone,
  },
  {
    label: "Birthday",
    value: "DOB",
    icon: CalendarHeart01,
  },
  {
    label: "Gender",
    value: "GENDER",
    icon: FaceHappy,
  },
  // {
  //   label: "Relation Status",
  //   value: "RELATION_STATUS",
  //   icon: Hearts,
  // },
  {
    label: "State/Region",
    value: "STATE",
    icon: Globe01,
  },
  {
    label: "Country",
    value: "COUNTRY",
    icon: Globe04,
  },
  {
    label: "City",
    value: "CITY",
    icon: MarkerPin01,
  },
  // {
  //   label: "Address",
  //   value: "ADDRESS",
  //   icon: Home02,
  // },
  {
    label: "Post Code",
    value: "ZIP_CODE",
    icon: MarkerPin06,
  },
  {
    label: "Company",
    value: "COMPANY_NAME",
    icon: Building05,
  },
  {
    label: "Job Title",
    value: "JOB_TITLE",
    icon: Briefcase01,
  },
  {
    label: "Work Email",
    value: "WORK_EMAIL",
    icon: Mail02,
  },
  // {
  //   label: "Work Number",
  //   value: "WORK_NUMBER",
  //   icon: Phone01,
  // },
];
