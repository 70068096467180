import CodewordResourcesList from "../CodewordResourcesList/CodewordResourcesList";
import { Text } from "src/components/Text/Text";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import {
  Announcement03,
  ArrowNarrowUpRight,
  Image03,
  Users02,
} from "@untitled-ui/icons-react";
import { OnboardingStepper } from "./OnboardingStepper";
import { useState } from "react";
import { OnboardingIntegrationStep } from "./OnboardingIntegrationStep";
import AudienceCreation from "../../assets/AudienceCreation.png";
import Integration from "../../assets/Integration.png";
import MediaLibrary from "../../assets/MediaLibrary.png";
import Campaign from "../../assets/campaign.png";
import { useEffect } from "react";
import {
  ActivitiesCountQuery,
  AudienceSetsCountQuery,
  MetaActivityAdTemplatesQuery,
  MetaIntegrationStatusQuery,
} from "src/graphql/generated/schema";

type AccordionStepIds =
  | "IntegrationCheck"
  | "CreativeCheck"
  | "AudienceCheck"
  | "CampaignCheck";

interface OnboardingDashboardProps {
  activitiesCount: ActivitiesCountQuery;
  integrationData: MetaIntegrationStatusQuery;
  adData: MetaActivityAdTemplatesQuery;
  audienceData: AudienceSetsCountQuery;
  setIsOnboarded: (isOnboarded: boolean) => void;
}

export function OnboardingDashboard({
  activitiesCount,
  integrationData,
  adData,
  audienceData,
  setIsOnboarded,
}: OnboardingDashboardProps) {
  const activitiesTotalCount = activitiesCount
    ? activitiesCount.activitiesCountByStatus?.reduce(
        (acc, item) => acc + item.value,
        0,
      )
    : 0;

  const [activeStepId, setActiveStepId] =
    useState<AccordionStepIds>("IntegrationCheck");
  const steps: Array<{
    id: AccordionStepIds;
    title: string;
    content: JSX.Element;
    isChecked?: boolean;
    imageSrc?: string;
  }> = [
    {
      id: "IntegrationCheck",
      title: "Connect one of these platforms to start creating campaigns",
      content: (
        <OnboardingIntegrationStep
          isMetaIntegrated={
            integrationData?.metaIntegrationStatus === "ACTIVE" ||
            integrationData?.metaIntegrationStatus === "EXPIRED"
              ? true
              : false
          }
        />
      ),
      isChecked:
        integrationData?.metaIntegrationStatus === "ACTIVE" ? true : false,
      imageSrc: Integration,
    },
    {
      id: "CreativeCheck",
      title: "Create your first creative asset",
      content: <OnboardingCreativeCheckStep />,
      isChecked:
        adData?.metaActivityAdTemplates.edges.length > 0 ? true : false,
      imageSrc: MediaLibrary,
    },
    {
      id: "AudienceCheck",
      title: "Create audience to choose who sees your campaigns",
      content: <OnboardingAudienceCheckStep />,
      isChecked: audienceData?.audienceSetsCount?.count > 0 ? true : false,
      imageSrc: AudienceCreation,
    },
    {
      id: "CampaignCheck",
      title: "Launch your first campaign and get real time analytics",
      content: <OnboardingCampaignCheckStep />,
      isChecked: activitiesTotalCount ? true : false,
      imageSrc: Campaign,
    },
  ];

  function markSteps() {
    const incompleteStep = steps.find((s) => !s.isChecked);

    if (!incompleteStep) {
      setIsOnboarded(true);
      return;
    } else {
      if (incompleteStep.id !== activeStepId) {
        setActiveStepId(incompleteStep.id);
      }
    }
  }

  useEffect(() => {
    markSteps();
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="max-w-[1078px]">
        <OnboardingHeader />
        <div className="flex flex-col gap-12">
          <OnboardingStepper
            setActiveStepId={setActiveStepId}
            activeStepId={activeStepId}
            steps={steps}
          />
          <CodewordResourcesList />
        </div>
      </div>
    </div>
  );
}

function OnboardingHeader() {
  return (
    <div className={"px-8 py-32 flex justify-between items-center"}>
      <div className={"flex flex-col gap-12 max-w-[383px]"}>
        <Text size="xl" font="redhat" weight="bold">
          Get ready to launch your first campaign in just a few simple steps
        </Text>
        <Text size="lg">
          Follow these steps to set up your Macro account and start launching
          successful marketing campaigns.
        </Text>
      </div>
      <div className="flex flex-col gap-12 max-w-[246px]">
        <Text size="lg">
          Complete these 4 steps to launch your first successful campaign
        </Text>
        <div>
          <ButtonV2
            isFullWidth={false}
            variant="outline"
            rightIcon={ArrowNarrowUpRight}
            children="Learn More"
            to="https://help.macrostack.co"
          />
        </div>
      </div>
    </div>
  );
}

function OnboardingCreativeCheckStep() {
  return (
    <div className="flex flex-col gap-12">
      <Text tone="subdued" size="sm">
        Creating a creative template is the first step to start running your
        campaigns. It helps in maintaining consistency and saves time.
      </Text>
      <div>
        <ButtonV2
          leftIcon={Image03}
          size="small"
          to="/library/ad-library/create"
        >
          Add Creative
        </ButtonV2>
      </div>
    </div>
  );
}

function OnboardingAudienceCheckStep() {
  return (
    <div className="flex flex-col gap-12">
      <Text tone="subdued" size="sm">
        Build your first target audience to ensure your campaign reaches the
        right people. Define your audience by selecting demographics, interests,
        and behaviours that align with your goals.
      </Text>
      <div>
        <ButtonV2 leftIcon={Users02} size="small" to="/audiences/new">
          Create Audience
        </ButtonV2>
      </div>
    </div>
  );
}

function OnboardingCampaignCheckStep() {
  return (
    <div className="flex flex-col gap-12">
      <Text tone="subdued" size="sm">
        Launch your first campaign to start reaching your target audience.
        Define your campaign settings, select your audience, and set your budget
        to start driving results.
      </Text>
      <div>
        <ButtonV2 leftIcon={Announcement03} size="small" to="/campaigns/new">
          Launch Campaign
        </ButtonV2>
      </div>
    </div>
  );
}
