import styles from "./InstagramPageWelcomeMessagePreview.module.scss";
import { PhoneNotificationBar } from "./PhoneNotificationBar";
import InstagramUserIcon from "../assets/instagramUserProfile.svg?react";
import { MetaPageWelcomeMessageInput } from "src/graphql/generated/schema";
import classNames from "classnames";

interface IInstagramPageWelcomeMessagePreview {
  userName: string;
  userAvatar: string;
  pageWelcomeMessage: MetaPageWelcomeMessageInput;
}

export function InstagramPageWelcomeMessagePreview({
  pageWelcomeMessage,
  userName,
  userAvatar,
}: IInstagramPageWelcomeMessagePreview) {
  return (
    <div className={styles.wrapper}>
      <PhoneNotificationBar />
      <div className={styles.background}></div>
      <div className={styles.floatingMessagePanel}>
        <div className={styles.header}>
          <div className={styles.resizeHandle} />
          <div className={styles.headerContent}>
            <div className={styles.headerTitle}>Message {userName}</div>
            <div className={styles.headerDescription}>
              {/* Typically replies within minutes */}
              Typically replies instantly
            </div>
          </div>
        </div>

        <div className={styles.bodyBox}>
          <div className={styles.aboutBrand}>
            <img
              className={styles.userAvatar}
              src={userAvatar}
              alt="brand logo"
              width={18}
              height={18}
            />

            <div className={styles.brandGreeting}>
              <span className={styles.brandGreetingUser}>{userName}</span>"
              {pageWelcomeMessage.greeting}"
            </div>
          </div>

          <div className={styles.messageOptionList}>
            {pageWelcomeMessage.MessageOptions.map((option) => {
              return (
                <div className={styles.messageOption}>
                  <span
                    className={classNames(styles.messageOptionText, {
                      [styles.messageOptionTextPlaceholder]: !option.text,
                    })}
                  >
                    {option.text ? `"${option.text}"` : "Write a message"}
                  </span>
                  <button type="button" className={styles.messageOptionButton}>
                    Send
                  </button>
                </div>
              );
            })}
          </div>

          <div className={styles.customMessageSection}>
            <InstagramUserIcon
              className={styles.personIcon}
              width={21}
              height={21}
            />

            <input
              className={styles.commentInput}
              readOnly
              disabled
              placeholder="Write your own message"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
