import { FilterLines, IntersectCircle, Zap } from "@untitled-ui/icons-react";
import { useEffect, useState } from "react";
import { Dialog } from "src/components/Dialog/Dialog";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { ScrollArea } from "src/components/ScrollArea/ScrollArea";
import { AudiencePresetSelectorLoader } from "./AudiencePresetSelectorLoader";
import { InterestSelectorLoader } from "./InterestSelectorLoader";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import {
  AudiencePresetAndInterestSelectorFormState,
  AudienceSelectTabOption,
} from "../misc/audienceCreateTypes";
import { InterestSearchInput } from "./InterestSearchInput";
import { AudiencePresetSearchInput } from "./AudiencePresetSearchInput";
import { upsertItemInObject } from "../functions/upsertItemInObject";
import isEqual from "lodash.isequal";

type SelectedPresets =
  AudiencePresetAndInterestSelectorFormState["audiencePresets"];
type SelectedInterests =
  AudiencePresetAndInterestSelectorFormState["interests"];

interface IAudiencePresetAndInterestSelectorModal {
  adAccountId: string;
  trigger: React.ReactNode;
  onIsOpenChange: (i: boolean) => void;
  preSelectedAudiencesPresets?: SelectedPresets;
  preSelectedInterests?: SelectedInterests;
  defaultSelectedTab: AudienceSelectTabOption;
  isOpen: boolean;
  onSubmit: (i: {
    presets: SelectedPresets;
    interests: SelectedInterests;
  }) => void;
}

export function AudiencePresetAndInterestSelectorModal({
  adAccountId,
  trigger,
  defaultSelectedTab,
  preSelectedAudiencesPresets = {},
  preSelectedInterests = {},
  onIsOpenChange,
  isOpen,
  onSubmit,
}: IAudiencePresetAndInterestSelectorModal) {
  const [hasEditedPresetOrInterests, setHasEditedPresetOrInterests] =
    useState(false);
  const [selectedPresets, setSelectedPresets] = useState<SelectedPresets>(
    preSelectedAudiencesPresets,
  );
  const [selectedInterests, setSelectedInterests] =
    useState<SelectedInterests>(preSelectedInterests);

  const tabOptions = [
    {
      label: "Audience Preset",
      value: "AUDIENCE_PRESET" as AudienceSelectTabOption,
      badge: Object.keys(selectedPresets).length
        ? Object.keys(selectedPresets).length
        : undefined,
    },
    {
      label: "Interest & Behaviours",
      value: "INTERESTS" as AudienceSelectTabOption,
      badge: Object.keys(selectedInterests).length
        ? Object.keys(selectedInterests).length
        : undefined,
    },
  ];

  const [selectedTab, setSelectedTab] =
    useState<AudienceSelectTabOption>(defaultSelectedTab);

  useEffect(() => {
    if (hasEditedPresetOrInterests) {
      return;
    }

    if (!isEqual(preSelectedAudiencesPresets, selectedPresets)) {
      setSelectedPresets(preSelectedAudiencesPresets);
    }

    if (!isEqual(preSelectedInterests, selectedInterests)) {
      setSelectedInterests(preSelectedInterests);
    }
  }, [
    hasEditedPresetOrInterests,
    preSelectedAudiencesPresets,
    preSelectedInterests,
  ]);

  return (
    <Dialog
      maxWidth="1024px"
      noPadding
      open={isOpen}
      preventCloseOnEscape={true}
      preventCloseOnOutsideClick={true}
      onOpenChange={onIsOpenChange}
      trigger={trigger}
    >
      <div className="pt-20 px-20">
        <Dialog.Header
          size="large"
          title="Create Audience Using Presets & Interests"
          // subtitle="Choose targeting options including interests, demographics, location and other criteria"
          icon={IntersectCircle}
        />

        <div className="py-12 grid grid-cols-[1fr_auto_auto] gap-8">
          {selectedTab === "AUDIENCE_PRESET" && (
            <AudiencePresetSearchInput
              onSelect={(audiencePreset) => {
                setHasEditedPresetOrInterests(true);
                setSelectedPresets(
                  upsertItemInObject(selectedPresets, audiencePreset.id, {
                    ...audiencePreset,
                    value: "INCLUDE",
                  }),
                );
              }}
            />
          )}
          {selectedTab === "INTERESTS" && (
            <InterestSearchInput
              adAccountId={adAccountId}
              onSelect={(interest) => {
                setHasEditedPresetOrInterests(true);
                setSelectedInterests(
                  upsertItemInObject(selectedInterests, interest.id, {
                    ...interest,
                    value: "INCLUDE",
                  }),
                );
              }}
            />
          )}
          {/* <ButtonV2 size="small" leftIcon={Zap} variant="outline" /> */}
          {/* <ButtonV2 size="small" leftIcon={FilterLines} variant="outline">
            Filter
          </ButtonV2> */}
        </div>

        <div className="flex flex-col gap-16">
          <TabsV2
            tabs={tabOptions}
            onTabChange={setSelectedTab}
            selectedTab={selectedTab}
          />
          <ScrollArea height={454}>
            <div className="pb-4 pr-3">
              {selectedTab === "AUDIENCE_PRESET" ? (
                <AudiencePresetSelectorLoader
                  selectedPresets={selectedPresets}
                  setSelectedPresets={(value) => {
                    setHasEditedPresetOrInterests(true);
                    setSelectedPresets(value);
                  }}
                />
              ) : (
                <InterestSelectorLoader
                  adAccountId={adAccountId}
                  selectedInterests={selectedInterests}
                  setSelectedInterests={(value) => {
                    setHasEditedPresetOrInterests(true);
                    setSelectedInterests(value);
                  }}
                />
              )}
            </div>
          </ScrollArea>
        </div>
      </div>

      <Dialog.StickyFooter
        actionButtonText="Submit"
        dismissButtonText="Cancel"
        onAction={() => {
          onSubmit({
            presets: selectedPresets,
            interests: selectedInterests,
          });
        }}
      />
    </Dialog>
  );
}
