import { ErrorHandler } from "src/components/ErrorHandler";
import { useMetaCatalogueProductsQuery } from "src/graphql/generated/schema";
import ProgressActivityIcon from "@material-symbols/svg-600/rounded/progress_activity.svg?react";
import styles from "./CatalogueProductImage.module.scss";

export function CatalogueProductImage({
  catalogueId,
}: {
  catalogueId: string;
}) {
  const { data, loading, error } = useMetaCatalogueProductsQuery({
    variables: {
      id: catalogueId,
    },
  });

  if (loading) {
    return (
      <div className={styles.loader}>
        <ProgressActivityIcon height={24} width={24} />
      </div>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (!data.metaCatalogueProducts[0]) {
    return (
      <img
        alt="product"
        className={styles.creative}
        src={"https://placehold.co/600x400/EEE/31343C"}
        width={40}
        height={40}
      />
    );
  }

  return (
    <img
      alt="product"
      className={styles.creative}
      src={data.metaCatalogueProducts[0].imageUrl}
      width={40}
      height={40}
    />
  );
}
