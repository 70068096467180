import * as React from "react";
import { DayPicker } from "react-day-picker";
import cn from "classnames";
import styles from "./Calendar.module.scss";
import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import ChevronLeft from "@untitled-ui/icons-react/build/esm/ChevronLeft";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;
// source: https://ui.shadcn.com/docs/components/calendar
function Calendar({ className, classNames, ...props }: CalendarProps) {
  return (
    <DayPicker
      className={cn(className)}
      disabled={{ after: new Date() }}
      classNames={{
        months: styles.months,
        month: styles.month,
        month_grid: styles.monthGrid,
        caption: styles.caption,
        month_caption: styles.captionLabel,
        weekdays: styles.weekdays,
        weekday: styles.weekday,
        weeks: styles.weeks,
        week: styles.week,
        nav: styles.nav,
        button_previous: cn(
          // buttonStyles.button, buttonStyles.buttonOutline,
          styles.navButton,
          styles.navButtonPrevious,
        ),
        button_next: cn(
          // buttonStyles.button, buttonStyles.buttonOutline,
          styles.navButton,
          styles.navButtonNext,
        ),
        table: styles.table,
        head_row: styles.headRow,
        head_cell: styles.headCell,
        row: styles.row,
        cell: styles.cell,
        day_button: styles.dayButton,
        day: styles.day,
        day_selected: styles.daySelected,
        day_today: styles.dayToday,
        outside: styles.dayOutside,
        hidden: styles.dayHidden,
        disabled: styles.dayDisabled,
        range_start: styles.dayRangeStart,
        range_middle: styles.dayRangeMiddle,
        range_end: styles.dayRangeEnd,
        ...classNames,
      }}
      {...props}
      components={{
        NextMonthButton: ({ ...props }) => (
          <button {...props}>
            <ChevronRight width={16} height={16} />
          </button>
        ),
        PreviousMonthButton: ({ ...props }) => (
          <button {...props}>
            <ChevronLeft width={16} height={16} />
          </button>
        ),
      }}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
