import { Spinner } from "src/components/Spinner/Spinner";
import { useMetaCataloguesFromPlatformQuery } from "src/graphql/generated/schema";
import { MetaDetailItem } from "./MetaDetailItem";
import { MetaIntegrationDetailsEmptyState } from "./MetaIntegrationDetailsEmptyState";
import { useState } from "react";

export function MetaCataloguesList({
  selectedAccountId,
  selectedMetaCatalogues,
  setSelectedMetaCatalogues,
}) {
  const [loading, setLoading] = useState(false);
  const { data: metaCataloguesData, loading: metaCataloguesLoading } =
    useMetaCataloguesFromPlatformQuery({
      variables: {
        adAccountId: selectedAccountId,
      },
    });

  if (metaCataloguesLoading) {
    return <Spinner height="auto" />;
  }

  const metaCatalogues =
    metaCataloguesData && metaCataloguesData.metaCataloguesFromPlatform
      ? metaCataloguesData?.metaCataloguesFromPlatform
      : [];

  if (metaCatalogues.length === 0) {
    return <MetaIntegrationDetailsEmptyState link={""} />;
  }

  return (
    <>
      {metaCatalogues.map((catalogue, index) => (
        <MetaDetailItem
          // TODO: handle error
          // error={selectedCatalogue === catalogue.id && metaEnableError}
          // }
          enableFunc={async () => {
            setLoading(true);
            await setSelectedMetaCatalogues(catalogue, metaCatalogues.length);
            setLoading(false);
          }}
          removeFunc={async () => {
            setLoading(true);
            await setSelectedMetaCatalogues(catalogue, metaCatalogues.length);
            setLoading(false);
          }}
          loading={loading}
          title={catalogue?.name}
          isEnabled={
            selectedMetaCatalogues.find(
              (v) => v.metaCatalogueId === catalogue.metaCatalogueId
            )?.enabled || false
          }
        />
      ))}
    </>
  );
}
