import { Form, Formik } from "formik";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import Fieldset from "src/components/Fieldset/Fieldset";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { CreateClientModalProps } from "../misc/CreateClientModalProps";
import { ClientCreateWizardInput } from "../misc/ClientCreateWizardInput";
import * as Yup from "yup";
import SelectV2 from "src/components/SelectV2/SelectV2";
import {
  maxAgeOptions,
  minAgeOptions,
} from "src/modules/global/misc/ageOptions";
import { Users01 } from "@untitled-ui/icons-react";
import { CountrySelector } from "src/modules/global/components/CountrySelector";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";

const profileSecondScreenSchema = Yup.object({
  clientProfile: Yup.object({
    brandName: Yup.string().required("Brand name is required"),
    brandColor: Yup.string().required("Brand color is required"),
    about: Yup.string().required("About is required"),
  }),
});

type ICreateClientModal5 = CreateClientModalProps<ClientCreateWizardInput> & {
  submitLabel?: string;
};

export function CreateClientModal5({
  initialValues,
  submitLabel,
  onBack,
  onSubmit,
}: ICreateClientModal5) {
  return (
    <Formik
      enableReinitialize
      validationSchema={profileSecondScreenSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="grid grid-rows-[auto_1fr_auto] gap-16 h-full">
          <SectionHeader
            title="Brand Details"
            description="Capture essential brand information to set the foundation"
          />

          <div className="pt-16">
            <Fieldset>
              <Fieldset horizontal>
                <SelectV2
                  icon={Users01}
                  options={minAgeOptions}
                  name="clientProfile.audienceMinAge"
                  value={values.clientProfile.audienceMinAge}
                  onChange={(value) =>
                    setFieldValue("clientProfile.audienceMinAge", value)
                  }
                  label="Min Age"
                />
                <SelectV2
                  icon={Users01}
                  options={maxAgeOptions}
                  name="clientProfile.audienceMaxAge"
                  value={values.clientProfile.audienceMaxAge}
                  onChange={(value) =>
                    setFieldValue("clientProfile.audienceMaxAge", value)
                  }
                  label="Max Age"
                />
              </Fieldset>
              <SelectV2
                options={[
                  { label: "All", value: "ALL" },
                  { label: "Male", value: "MALE" },
                  { label: "Female", value: "FEMALE" },
                ]}
                value={values.clientProfile.audienceGender}
                onChange={(value) =>
                  setFieldValue("clientProfile.audienceGender", value)
                }
                name="clientProfile.audienceGender"
                label="Gender"
                placeholder="Select gender"
              />

              <CountrySelector
                label="Locations"
                placeholder="Select locations"
                value={values.clientProfile.locations}
                onChange={(value) =>
                  setFieldValue("clientProfile.locations", value)
                }
              />

              <MultilineInput
                placeholder="Tell us about your audience's interests"
                value={values.clientProfile.audienceInterests}
                onChange={(value) =>
                  setFieldValue("clientProfile.audienceInterests", value)
                }
                name="clientProfile.audienceInterests"
                label="Audience Interests"
                showCharacterCount
                maxCharacterCount={240}
              />
            </Fieldset>
          </div>

          <div className="flex gap-8">
            <ButtonV2 size="small" type="submit">
              {submitLabel ?? (
                <>
                  Continue
                  <VerticalSeparator
                    color="white"
                    separatorHeight={14}
                    totalWidth={10}
                  />
                  3/4
                </>
              )}
            </ButtonV2>
            <ButtonV2 size="small" variant="outline" onClick={onBack}>
              Back
            </ButtonV2>
          </div>
        </Form>
      )}
    </Formik>
  );
}
