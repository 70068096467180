import React from "react";
import styles from "./Popover.module.scss";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import classNames from "classnames";

/**
 *
 * To use Popover.Trigger as the trigger for the Popover component:
 *
 * 1. Pass Popover.Trigger as a child to the `trigger` prop of the Popover component.
 * 2. Wrap your custom trigger element inside Popover.Trigger.
 * 3. Customize the appearance and behavior of your trigger element as needed.
 *
 * This approach allows you to create a custom trigger while maintaining the
 * Popover's built-in functionality and accessibility features.
 *
 * @example
 * See the CustomTrigger story in Popover.stories.tsx for a practical implementation.
 *
 * @tip
 * You can style your custom trigger element to match your design requirements
 * while still benefiting from the Popover's underlying behavior.
 */
export function Popover({
  children,
  trigger,
  maxWidth,
  noPadding,
}: {
  children: React.ReactNode;
  trigger: React.ReactNode;
  maxWidth?: string | number;
  noPadding?: boolean;
}) {
  return (
    <PopoverPrimitive.Root>
      <div className={styles.wrapper}>
        {trigger}
        <PopoverPrimitive.Content
          style={{ maxWidth }}
          collisionPadding={20}
          side="bottom"
          sideOffset={0}
          align="start"
          className={classNames(styles.content, {
            [styles.noPadding]: noPadding,
          })}
        >
          {children}
        </PopoverPrimitive.Content>
      </div>
    </PopoverPrimitive.Root>
  );
}

Popover.Trigger = PopoverPrimitive.Trigger;
