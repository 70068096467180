import { Announcement03, LayoutGrid01 } from "@untitled-ui/icons-react";
import { useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { DateFilterEnum } from "src/components/Datepicker/Datepicker";
import Header from "src/components/Header/Header";
import { TableFilter } from "src/components/TableFilter/TableFilter";
import { CampaignsSortByField } from "src/graphql/generated/schema";
import { useSearchState } from "src/modules/global/hooks/useSearchState";
import {
  DateFilter,
  getDateFilterFromFilterEnum,
} from "src/modules/global/misc/dateFilterUtils";
import { ActivitiesListing } from "../components/ActivityListing";
import { CampaignListing } from "../components/CampaignListing";
import sortOptions from "../misc/campaignSortBy";

const tabOptions = [
  {
    icon: Announcement03,
    label: "Campaign",
    value: "CAMPAIGN",
  },
  {
    icon: LayoutGrid01,
    label: "Activity",
    value: "ACTIVITY",
  },
];

export function CampaignListingPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get("date");
  const [query, setQuery] = useSearchState("query", "");
  const [selectedTab, setSelectedTab] = useSearchState(
    "tab",
    tabOptions[0].value,
  );
  const filterDate = getDateFilterFromFilterEnum(
    (date as DateFilterEnum) || "ALL_TIME",
  );
  const [dateFilter, setDateFilters] = useState<DateRange>(
    filterDate
      ? {
          from: filterDate.gte,
          to: filterDate.lte,
        }
      : null,
  );
  const [sortByDirection, setSortByDirection] = useSearchState<"DESC" | "ASC">(
    "sortByDirection",
    "DESC",
  );
  const [sortByField, setSortByField] = useSearchState<CampaignsSortByField>(
    "sortByField",
    "CREATED_AT",
  );

  return (
    <>
      <Header
        title={selectedTab === "CAMPAIGN" ? "Campaigns" : "Activities"}
        icon={selectedTab === "CAMPAIGN" ? Announcement03 : LayoutGrid01}
        iconColor={selectedTab === "CAMPAIGN" ? "info" : "warning"}
        actions={
          <>
            <ButtonV2 size="small" to="/campaigns/new">
              Create Activity
            </ButtonV2>
          </>
        }
      />

      <TableFilter
        dateFilter={dateFilter}
        searchInputPlaceholder="Search campaign & activity"
        searchQuery={query}
        defaultValue="THIS_YEAR"
        setDateFilter={(dateFilter) => setDateFilters(dateFilter)}
        setSearchQuery={(query) => setQuery(query)}
        setSortBy={(sortBy) => {
          setSortByDirection(sortBy.direction);
          setSortByField(sortBy.field as any);
        }}
        sortBy={{ direction: sortByDirection, field: sortByField }}
        sortOptions={sortOptions}
        hasTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabOptions={tabOptions}
      />

      {selectedTab === "CAMPAIGN" ? (
        <CampaignListing
          filters={{
            ...(dateFilter && {
              createdAt: {
                gte: dateFilter.from,
                lte: dateFilter.to,
              },
            }),
            query: query,
          }}
          sortBy={{ direction: sortByDirection, field: sortByField }}
        />
      ) : (
        <ActivitiesListing
          filters={{
            ...(dateFilter && {
              createdAt: {
                gte: dateFilter.from,
                lte: dateFilter.to,
              },
            }),
            query: query,
          }}
          sortBy={{ direction: sortByDirection, field: sortByField }}
        />
      )}
    </>
  );
}
