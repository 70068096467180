import { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "src/stores/AuthContext";

interface Props {
  children?: any;
}

export function RequiresAuth({ children }: Props) {
  const { isAuthenticated, user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated || !user) {
      navigate({
        pathname: "/login",
        search: location.search,
      });
    }
  }, [user, isAuthenticated]);

  if (!user || !isAuthenticated) {
    return <p>Hydrating from local storage</p>;
  }

  return children ? children : <Outlet />;
}
