import { ChevronDown } from "@untitled-ui/icons-react";
import { useEffect, useState } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Icon } from "src/components/Icon/Icon";
import { Popover } from "src/components/Popover/Popover";
import { Text } from "src/components/Text/Text";
import classNames from "classnames";
import {
  ContinentEnum,
  CountriesQuery,
  useCountriesLazyQuery,
} from "src/graphql/generated/schema";
import { GeoLocationTargetingFormState } from "../misc/audienceCreateTypes";
import { useFormikContext } from "formik";
import africa from "../assets/africa.png";
import asia from "../assets/asia.png";
import europe from "../assets/europe.png";
import northAmerica from "../assets/northAmerica.png";
import southAmerica from "../assets/southAmerica.png";
import oceania from "../assets/oceania.png";
import { titleCase } from "src/lib/titleCase";
import { Spinner } from "src/components/Spinner/Spinner";

export function LocationBrowserPopover({ values, setFieldValue }) {
  const [selectedContinent, setSelectedContinent] =
    useState<ContinentEnum>("ASIA");
  const [getCountries, { data: countriesData }] = useCountriesLazyQuery();

  useEffect(() => {
    if (selectedContinent) {
      getCountries({
        variables: {
          filters: {
            continent: selectedContinent,
          },
        },
      });
    }
  }, [selectedContinent, getCountries]);

  return (
    <Popover
      maxWidth={670}
      noPadding
      trigger={
        <ButtonV2 as={Popover.Trigger} variant="outline" size="small">
          Browse Location
          <Icon icon={ChevronDown} iconColor="default" size="md" />
        </ButtonV2>
      }
    >
      <div className="p-8">
        <Text size="xs" weight="medium">
          Search Location
        </Text>
        <div className="px-8 py-12 grid grid-cols-[repeat(6,6rem)] gap-12">
          {continents.map((content) => (
            <button
              type="button"
              onClick={() => setSelectedContinent(content.id)}
              key={content.id}
              className={classNames(
                "flex flex-col gap-4 justify-center items-center"
              )}
            >
              <img
                src={content.image}
                className={classNames("border rounded-6 w-24 h-16", {
                  "border-neutral-500": selectedContinent === content.id,
                })}
                width={100}
                height={68}
              />
              <Text
                noWrap
                tone={selectedContinent === content.id ? "default" : "subdued"}
                size="xs"
                weight="medium"
              >
                {content.name}
              </Text>
            </button>
          ))}
        </div>
        {countriesData ? (
          <div className="px-8 py-12">
            <div className="px-4">
              <hr />
              <Text size="2xs" weight="medium">
                Countries in {titleCase(selectedContinent)}
              </Text>
            </div>
            <div className="max-h-[150px] overflow-y-auto">
              {countriesData.countries.map((country) => (
                <button
                  type="button"
                  key={country.alpha2}
                  onClick={() => {
                    if (checkIsCountrySelected(country, values.locations)) {
                      setFieldValue(
                        "locations",
                        values.locations.filter((l) => l.id !== country.alpha2)
                      );
                    } else {
                      setFieldValue("locations", [
                        ...values.locations,
                        {
                          id: country.alpha2,
                          name: country.name,
                          type: "country",
                          value: "INCLUDE",
                        },
                      ]);
                    }
                  }}
                  className={classNames(
                    "flex w-full rounded-6 gap-1 items-center px-8 py-8",
                    {
                      "bg-neutral-50": checkIsCountrySelected(
                        country,
                        values.locations
                      ),
                    }
                  )}
                >
                  <Text size="sm" tone="subdued" weight="medium">
                    {country.name}
                  </Text>
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <Spinner height={200} />
          </div>
        )}
      </div>
    </Popover>
  );
}

const continents = [
  {
    id: "ASIA" as const,
    name: "Asia",
    image: asia,
  },
  {
    id: "AFRICA" as const,
    name: "Africa",
    image: africa,
  },
  {
    id: "EUROPE" as const,
    name: "Europe",
    image: europe,
  },
  {
    id: "NORTH_AMERICA" as const,
    name: "North America",
    image: northAmerica,
  },
  {
    id: "SOUTH_AMERICA" as const,
    name: "South America",
    image: southAmerica,
  },
  {
    id: "OCEANIA" as const,
    name: "Oceania",
    image: oceania,
  },
];

function checkIsCountrySelected(
  country: CountriesQuery["countries"][number],
  locations: GeoLocationTargetingFormState["locations"]
) {
  return locations.find((l) => l.id === country.alpha2);
}
