export function formatPrice(
  amount: number,
  currency: "INR" | "USD" = "INR",
  decimals: number = 2,
  isSmallestUnit: boolean = false
) {
  const locale = currency === "USD" ? "en-US" : "en-IN";
  const divisor = isSmallestUnit ? (currency === "USD" ? 100 : 100) : 1;

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: decimals,
  }).format(amount / divisor);
}
