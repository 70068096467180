import SelectV2 from "../../../components/SelectV2/SelectV2";
import styles from "./ScheduledReportFields.module.scss";

export function ScheduledReportFields({ values, setFieldValue }) {
  return (
    <div>
      <div className={styles.contentHeader}>Scheduled reports</div>

      <div className={styles.fieldsContainer}>
        <SelectV2
          options={new Array(20).fill(1).map((_, hour) => {
            const offset = hour + 4;
            return {
              value: offset,
              label: `${offset % 12 || 12}:00 ${offset < 12 ? "AM" : "PM"}`,
            };
          })}
          label="Delivery time"
          placeholder="9:00 AM"
          value={values.deliveryAt}
          onChange={function (value: any): void {
            setFieldValue("deliveryAt", value);
          }}
        />
        <SelectV2
          options={[
            {
              label: "Weekly",
              value: "WEEKLY",
            },
            {
              label: "Monthly",
              value: "MONTHLY",
            },
          ]}
          label="Frequency"
          value={values.duration}
          onChange={function (value: any): void {
            setFieldValue("duration", value);
          }}
        />
      </div>
    </div>
  );
}
