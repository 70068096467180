import classNames from "classnames";
import { useFormikContext } from "formik";
import PlusIcon from "@material-symbols/svg-600/rounded/add.svg?react";
import { ActivityInputV2, AdInputV2 } from "../misc/createCampaignTypes";
import styles from "./SelectAdSwitcher.module.scss";
import { CatalogueProductImage } from "../../global/components/CatalogueProductImage";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

interface ISelectedAdSwitcher {
  onCreateAd: () => void;
}

export function SelectedAdSwitcher({ onCreateAd }: ISelectedAdSwitcher) {
  const { values } = useFormikContext<ActivityInputV2>();
  const selectedAdIndex = values.selectedAdIndex ?? 0;
  const ads = values.Ads ?? [];

  return (
    <div className={styles.switcher}>
      <div className={styles.switcherList}>
        {ads.map((ad, adIndex) => (
          <AdThumbnail
            adIndex={adIndex}
            ad={ad}
            selectedAdIndex={selectedAdIndex}
          />
        ))}

        <ButtonV2 leftIcon={PlusIcon} onClick={onCreateAd} variant="outline" />
      </div>
    </div>
  );
}

interface IAdThumbnail {
  adIndex: number;
  ad: AdInputV2;
  selectedAdIndex: number;
}

function AdThumbnail({ adIndex, ad, selectedAdIndex }: IAdThumbnail) {
  const { errors, touched, setFieldValue } =
    useFormikContext<ActivityInputV2>();
  const postAsset = ad.LineItems[0]?.PostAsset;
  const isExistingPostTemplate = ad.adType === "EXISTING_POST";
  const existingPost = ad.ExistingPost;
  const isCatalogue = ad.adType === "CATALOG";
  const hasError = errors?.Ads?.[adIndex] && touched?.Ads?.[adIndex];

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        className={styles.switcherItem}
        onClick={() => {
          setFieldValue("selectedAdIndex", adIndex);
        }}
      >
        <span
          className={classNames(styles.adPreviewContainer, {
            [styles.adPreviewSelected]: selectedAdIndex === adIndex,
            [styles.adPreviewError]: hasError,
          })}
        >
          {isCatalogue ? (
            <CatalogueProductImage catalogueId={ad.catalogueId} />
          ) : isExistingPostTemplate ? (
            // TODO: handle carousel album
            existingPost?.type === "IMAGE" ? (
              <img
                className={styles.adPreview}
                src={existingPost.url}
                alt="post"
                width={40}
                height={40}
              />
            ) : (
              <video
                className={styles.adPreview}
                width={40}
                height={40}
                loop
                muted
              >
                <source src={existingPost.url} />
              </video>
            )
          ) : postAsset.contentType === "VIDEO" ? (
            <video className={styles.adPreview} muted loop autoPlay>
              <source src={postAsset.uri} />
            </video>
          ) : (
            <img
              className={styles.adPreview}
              alt="asset "
              src={postAsset.uri}
              width={40}
              height={40}
            />
          )}
        </span>
        <p
          className={classNames(styles.adName, {
            [styles.adNameSelected]: selectedAdIndex === adIndex,
            [styles.adNameError]: hasError,
          })}
        >
          Ad {adIndex + 1}
        </p>
      </button>
    </div>
  );
}
