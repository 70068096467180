import {
  LayersTwo01,
  Tag01,
  UsersEdit,
  UserCircle,
  ChevronRight,
  CurrencyDollarCircle,
  ShoppingBag01,
  Eye,
  CursorClick01,
  Plus,
} from "@untitled-ui/icons-react";
import { Link } from "react-router-dom";
import { Avatar } from "src/components/Avatar/Avatar";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { Icon } from "src/components/Icon/Icon";
import { IconWithWrapper } from "src/components/IconWithWrapper/IconWithWrapper";
import { LineAroundHeading } from "src/components/LineAroundHeading/LineAroundHeading";
import { Text } from "src/components/Text/Text";
import { VerticalSeparator } from "src/components/VerticalSeparator/VerticalSeparator";
import { CustomerQuery } from "src/graphql/generated/schema";
import { formatNumberByType } from "src/modules/global/functions/formatNumberByType";
import EmailIcon from "../../../icons/email.svg?react";
import WhatsappIcon from "../../../icons/whatsapp.svg?react";
import format from "date-fns/format";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";

interface ICustomerSidebar {
  customer: CustomerQuery["customer"];
}

export function CustomerSidebar({ customer }: ICustomerSidebar) {
  const keyInformation = [
    {
      icon: CurrencyDollarCircle,
      label: "Life time value",
      value: formatNumberByType(customer.lifetimeValue, "CURRENCY"),
    },
    {
      icon: ShoppingBag01,
      label: "Total order count",
      value: formatNumberByType(customer.ordersCount, "NUMBER"),
    },
    {
      icon: Eye,
      label: "Open rate",
      value: customer.Insights?.deliveryRate
        ? formatNumberByType(customer.Insights?.deliveryRate, "PERCENTAGE")
        : "N/A",
    },
    {
      icon: CursorClick01,
      label: "Click rate",
      value: customer.Insights?.clickRate
        ? formatNumberByType(customer.Insights?.clickRate, "PERCENTAGE")
        : "N/A",
    },
  ];

  const tags = customer.Tags;

  const isEmailSubscriber = customer.EmailConsent?.state === "SUBSCRIBED";
  const isWhatsappSubscriber = customer.WhatsappConsent?.state === "SUBSCRIBED";

  const formattedCreatedAt = format(
    new Date(customer.createdAt),
    "dd MMM yyyy",
  );

  const formattedUpdatedAt = format(
    new Date(customer.updatedAt),
    "dd MMM yyyy",
  );

  return (
    <div className="overflow-y-scroll bg-gray-50 border-l border-neutral-50 py-24 px-16">
      <div className="pt-2 pb-16">
        <Text size="xl" weight="semibold" font="redhat">
          Customer Details
        </Text>
      </div>

      {/* avatar section */}
      <div className="pt-16 pb-16 flex flex-col items-center">
        <Avatar size={48} avatarSeed={customer.name} />
        <div>
          <Text size="lg" weight="medium">
            {customer.name}
          </Text>
        </div>

        <div>
          <Text size="sm" tone="neutral-500">
            {customer.email || customer.phone}
          </Text>
        </div>
        <div className="pt-16 flex gap-8">
          <ButtonV2 variant="outline" size="small" leftIcon={Tag01}>
            Tags
          </ButtonV2>
          <ButtonV2 variant="outline" size="small" leftIcon={UsersEdit}>
            Edit
          </ButtonV2>
        </div>
      </div>
      {/* Segment section */}
      <div className="pt-16 pb-24">
        <LineAroundHeading>Included in Segments</LineAroundHeading>

        <div className="flex flex-col pt-16 gap-4">
          {customer.Segments.map((segment) => (
            <div
              // to={`/segments/${segment.id}`}
              key={segment.id}
              className="border border-neutral-50 rounded-8 bg-white p-12 flex items-center justify-between"
            >
              <div className="flex gap-8 items-center">
                <IconWithWrapper
                  fullRounded
                  icon={LayersTwo01}
                  iconColor="success"
                />
                <Text weight="medium">{segment.name}</Text>
              </div>

              <div className="flex items-center">
                <div className="flex items-center gap-6 pr-4">
                  <Icon iconColor="gray-800" icon={UserCircle} />
                  <Text weight="medium" size="xs" tone="gray-800">
                    {segment.customersCount}
                  </Text>
                </div>
                <VerticalSeparator separatorHeight={9} totalWidth={16} />
                <Icon icon={ChevronRight} iconColor="gray-800" size={"sm"} />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* key information */}
      <div className="pt-16 pb-24">
        <LineAroundHeading>Key information</LineAroundHeading>
        <div className="flex flex-col gap-24 pt-24">
          {keyInformation.map((info, index) => (
            <div className="flex justify-between">
              <div className="flex items-center gap-8">
                <Icon icon={info.icon} iconColor="neutral-400" />
                <Text tone="gray-700" weight="medium">
                  {info.label}
                </Text>
              </div>
              <Text tone="gray-700" weight="medium">
                {info.value}
              </Text>
            </div>
          ))}
        </div>
      </div>
      {/* Opted in also */}
      <div className="pt-16 pb-24">
        <LineAroundHeading>Opted in also</LineAroundHeading>
        <div className="pt-16 flex gap-16">
          {isEmailSubscriber && (
            <EmailIcon
              width={24}
              height={24}
              color="var(--color-neutral-800)"
            />
          )}
          {isWhatsappSubscriber && (
            <WhatsappIcon
              width={24}
              height={24}
              color="var(--color-neutral-800)"
            />
          )}
        </div>
      </div>
      {/* additional information */}
      <div className="pt-16 pb-24">
        <LineAroundHeading>Additional information</LineAroundHeading>
        <div className="pt-16 grid grid-cols-2 gap-20">
          {customer.email && (
            <div className="flex flex-col gap-8 col-span-2">
              <Text size="xs" weight="medium" tone="gray-700">
                EMAIL
              </Text>
              <Text size="xs" weight="medium" tone="gray-700">
                {customer.email}
              </Text>
            </div>
          )}
          <div className="flex flex-col gap-8">
            <Text size="xs" weight="medium" tone="gray-700">
              ADDRESS
            </Text>
            <Text size="xs" weight="medium" tone="gray-700">
              {customer.Address?.city}
              {customer.Address?.city ? ", " : ""}
              {customer.Address?.country}
            </Text>
          </div>
          {customer.isEmailVerified !== null && customer.email && (
            <div className="flex flex-col gap-8">
              <Text size="xs" weight="medium" tone="gray-700">
                EMAIL VERIFIED
              </Text>
              <Text size="xs" weight="medium" tone="gray-700">
                {customer.isEmailVerified ? "Yes" : "No"}
              </Text>
            </div>
          )}
          <div className="flex flex-col gap-8">
            <Text size="xs" weight="medium" tone="gray-700">
              CREATED ON
            </Text>
            <Text size="xs" weight="medium" tone="gray-700">
              {formattedCreatedAt}
            </Text>
          </div>
          <div className="flex flex-col gap-8">
            <Text size="xs" weight="medium" tone="gray-700">
              LAST UPDATED
            </Text>
            <Text size="xs" weight="medium" tone="gray-700">
              {formattedUpdatedAt}
            </Text>
          </div>
        </div>
      </div>
      {/* Tags */}
      <div className="pt-16 pb-24">
        <LineAroundHeading>Tag Used</LineAroundHeading>
        <div className="pt-16 flex gap-8">
          {tags.map((tag) => (
            <BadgeV2 label={tag.name} color="purple" />
          ))}
        </div>
        <div className="pt-16 flex justify-center">
          <ButtonV2 leftIcon={Plus} variant="plain" size="small">
            Add Tags
          </ButtonV2>
        </div>
      </div>
    </div>
  );
}
