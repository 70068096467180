import { Building04, Speedometer03, UsersPlus } from "@untitled-ui/icons-react";
import { CalloutCard } from "src/components/CalloutCard/CalloutCard";
import { Grid } from "src/components/Grid/Grid";

export default function CodewordResourcesList() {
  return (
    <Grid column={{ xs: 3 }} columnGap={{ xs: 12 }}>
      <CalloutCard
        title={"Complete Your Profile"}
        description={
          "Complete your profile to help understand your business. Based on your business type, we'll provide personalized recommendations."
        }
        icon={Building04}
        iconColor="info"
        useIconWithWrapper
        primaryAction={{
          disabled: false,
          children: "View Profile",
          variant: "outline",
          to: "/settings/clients/current",
        }}
      />
      <CalloutCard
        title={"Invite Your Team"}
        description={
          "Invite team members to collaborate and streamline workflow. Work together by sharing access and responsibilities."
        }
        icon={UsersPlus}
        iconColor="success"
        useIconWithWrapper
        primaryAction={{
          children: "Invite Team",
          variant: "outline",
          to: "/settings/members",
        }}
      />
      <CalloutCard
        title={"Learn More About Macro"}
        description={
          "Discover how Macro's powerful features can accelerate your sales and drive sustainable business growth"
        }
        icon={Speedometer03}
        iconColor="danger"
        useIconWithWrapper
        primaryAction={{
          children: "View Help Center",
          variant: "outline",
          to: "https://help.macrostack.co",
        }}
      />
    </Grid>
  );
}
