import { Checkbox } from "src/components/Checkbox/Checkbox";
import { MetaActivityAdTemplatesQuery } from "src/graphql/generated/schema";
import styles from "./AdTemplateItem.module.scss";
import { CatalogueProductImage } from "../../global/components/CatalogueProductImage";
import { useFormikContext } from "formik";
import { ActivityInputV2 } from "../misc/createCampaignTypes";
import { checkAdTemplateIsSupported } from "../functions/checkAdTemplateIsSupported";
import classNames from "classnames";
import ErrorIcon from "@material-symbols/svg-600/rounded/error-fill.svg?react";
import adsCount from "../assets/adCount.svg";
import { getAdTemplateType } from "../functions/getAdTemplateType";

type AdTemplate =
  MetaActivityAdTemplatesQuery["metaActivityAdTemplates"]["edges"][0]["node"];

interface IAdTemplateItem {
  metaActivityAdTemplate: AdTemplate;
  selectedAdTemplates: AdTemplate[];
  setSelectedAdTemplates: (i: AdTemplate[]) => void;
  multiple: boolean;
}

export function AdTemplateItem({
  selectedAdTemplates,
  setSelectedAdTemplates,
  metaActivityAdTemplate,
  multiple = false,
}: IAdTemplateItem) {
  const { values } = useFormikContext<ActivityInputV2>();
  const isAdTemplatedSupportedInActivity = checkAdTemplateIsSupported(
    values,
    metaActivityAdTemplate,
  );

  const isSelected = !!selectedAdTemplates.find(
    (s) => s.id === metaActivityAdTemplate.id,
  );

  function handleSelect() {
    if (!isAdTemplatedSupportedInActivity) {
      return;
    }
    if (!multiple) {
      setSelectedAdTemplates([metaActivityAdTemplate]);
    } else {
      if (isSelected) {
        const filteredItems = selectedAdTemplates.filter(
          (adTemplate) => adTemplate.id !== metaActivityAdTemplate.id,
        );
        return setSelectedAdTemplates(filteredItems);
      }
      setSelectedAdTemplates([...selectedAdTemplates, metaActivityAdTemplate]);
    }
  }

  return (
    <div
      onClick={handleSelect}
      className={classNames(styles.adTemplateItem, {
        [styles.disabledItem]: !isAdTemplatedSupportedInActivity,
        [styles.selectItem]: isSelected,
      })}
    >
      {multiple && (
        <Checkbox
          disabled={!isAdTemplatedSupportedInActivity}
          checked={isSelected}
          onChange={handleSelect}
          value={metaActivityAdTemplate.id}
        />
      )}
      <div className={styles.mainRow}>
        <Asset metaActivityAdTemplate={metaActivityAdTemplate} />
        <div className={styles.titleContainer}>
          <span
            className={classNames(styles.title, {
              [styles.titleDisabled]: !isAdTemplatedSupportedInActivity,
            })}
          >
            {metaActivityAdTemplate.name}
            {!isAdTemplatedSupportedInActivity && (
              <span className={styles.errorIcon}>
                <ErrorIcon fill="var(--color-warning)" width={14} height={14} />
              </span>
            )}
          </span>

          <span className={styles.activityCount}>
            <img src={adsCount} width={24} height={24} alt="ad count" />
            {metaActivityAdTemplate?.adsCount || "-"}
          </span>
        </div>
      </div>
      <div className={styles.badgeSection}>
        <span
          className={classNames(styles.badge, {
            [styles.inUseBadge]: metaActivityAdTemplate.adsCount > 0,
          })}
        >
          {metaActivityAdTemplate.adsCount > 0 ? "In use" : "Not in use"}
        </span>
      </div>
      <div className={styles.adType}>
        {getAdTemplateType(metaActivityAdTemplate)}
      </div>
    </div>
  );
}

function Asset({
  metaActivityAdTemplate,
}: {
  metaActivityAdTemplate: AdTemplate;
}) {
  const isCatalogueAd = !!metaActivityAdTemplate.metaProductSetId;
  const existingPost = metaActivityAdTemplate.ExistingPost;
  const firstLineItem = metaActivityAdTemplate.LineItems[0];
  const isExistingPostTemplate = Boolean(
    metaActivityAdTemplate.existingPostId && existingPost,
  );

  if (isCatalogueAd) {
    return (
      <CatalogueProductImage catalogueId={metaActivityAdTemplate.catalogueId} />
    );
  }

  if (isExistingPostTemplate) {
    // TODO: handle carousel album
    if (existingPost.type === "IMAGE") {
      return (
        <img
          className={styles.creativeThumbnail}
          src={existingPost.url}
          alt="post"
          width={40}
          height={40}
        />
      );
    }

    return (
      <video
        className={styles.creativeThumbnail}
        width={40}
        height={40}
        loop
        muted
      >
        <source src={existingPost.url} />
      </video>
    );
  }

  return (
    <img
      className={styles.creativeThumbnail}
      width={40}
      height={40}
      alt="post asset"
      src={
        firstLineItem?.PostAsset.thumbnailUri || firstLineItem?.PostAsset.uri
      }
    />
  );

  return null;
}
