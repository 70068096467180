import { CampaignSingleOverviewTabContent } from "./CampaignSingleOverviewTabContent";
import { useState } from "react";
import { CampaignQuery } from "src/graphql/generated/schema";
import { useCampaignAggregatedStats } from "../../global/functions/useCampaignAggregatedStats";
import { IOverviewTabs } from "../misc/campaignSingleUiTypes";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { WidgetLayout } from "src/components/WidgetLayout/WidgetLayout";
import {
  CursorClick01,
  FileCheck03,
  Globe03,
  ShoppingBag03,
  UserPlus01,
} from "@untitled-ui/icons-react";
import DatePicker from "src/components/Datepicker/Datepicker";
import { DateRange } from "react-day-picker";

interface ICampaignOverview {
  campaignId: string;
  campaign: CampaignQuery["campaign"];
  dateFrom?: Date;
  dateTo?: Date;
}

const tabOptions: Array<{
  label: string;
  value: IOverviewTabs;
  icon: React.FC<React.SVGAttributes<SVGElement>>;
}> = [
  {
    label: "Overview",
    value: "OVERVIEW",
    icon: ShoppingBag03,
  },
  {
    label: "Transactional",
    value: "TRANSACTIONAL",
    icon: UserPlus01,
  },
  // {
  //   label: "Lead",
  //   value: "LEAD",
  // },
  {
    label: "Traffic",
    value: "TRAFFIC",
    icon: Globe03,
  },
  {
    label: "Engagement",
    value: "ENGAGEMENT",
    icon: CursorClick01,
  },
  {
    label: "Awareness",
    value: "AWARENESS",
    icon: FileCheck03,
  },
];

export function CampaignSingleOverview({
  campaignId,
  dateFrom,
  dateTo,
  campaign,
}: ICampaignOverview) {
  const [selectedDate, setSelectedDate] = useState({
    dateFrom,
    dateTo,
  });
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value);
  const { data } = useCampaignAggregatedStats(campaignId, selectedDate);

  const insights = data;

  const audienceCount = campaign.Activities.reduce(
    (acc, a) => a.MetaActivity?.MetaActivityAdsets.length + acc,
    0,
  );

  const segmentCount = campaign.Outreaches.reduce(
    (acc, o) => o.Segments?.length + acc,
    0,
  );

  const adsCreativeCount = campaign.Activities.reduce(
    (acc, a) => a.MetaActivity?.MetaActivityAds?.length + acc,
    0,
  );

  // every outreach has 1 creative only
  const emailCreativeCount = campaign.Outreaches.length;
  const totalCreativeCount = adsCreativeCount + emailCreativeCount;

  // const totalAudienceAndSegmentCount = audienceCount + segmentCount;

  return (
    <section>
      <WidgetLayout
        headerContainerClassName="p-8"
        action={
          <DatePicker
            defaultValue={"THIS_YEAR"}
            onChange={(i: DateRange) => {
              setSelectedDate({
                dateFrom: i.from,
                dateTo: i.to,
              });
            }}
          />
        }
        title={
          <TabsV2
            tabs={tabOptions}
            onTabChange={(tab: IOverviewTabs) => setSelectedTab(tab)}
            selectedTab={selectedTab}
          />
        }
      >
        <CampaignSingleOverviewTabContent
          insights={{
            ...insights,
            audiencesCount: audienceCount,
            creativesCount: totalCreativeCount,
            activitiesCount: campaign.activitiesCount,
            segmentsCount: segmentCount,
          }}
          selectedTab={selectedTab}
        />
      </WidgetLayout>
    </section>
  );
}
