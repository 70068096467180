import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import Header from "src/components/Header/Header";
import { useAudienceSetsCountQuery } from "../../../graphql/generated/schema";
import { AudienceListLoader } from "../components/AudienceListLoader";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { Users02 } from "@untitled-ui/icons-react";

export function AudienceListingPage() {
  const { data } = useAudienceSetsCountQuery();

  return (
    <div>
      <Header
        title="Audiences"
        actions={
          <>
            <ButtonV2 size="small" to="/audiences/new">
              Create Audience
            </ButtonV2>
          </>
        }
        badges={
          <BadgeV2
            color="gray"
            size="small"
            label={data?.audienceSetsCount?.count || 0}
          />
        }
        icon={Users02}
        iconColor="inherit"
      />
      <AudienceListLoader />
    </div>
  );
}
