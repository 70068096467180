import { ReactNode, SVGAttributes } from "react";
import styles from "./SwitchGroup.module.scss";
import classNames from "classnames";

interface SwitchGroupProps {
  onChange: (value: string) => void;
  value: string;
  noneValue: string;
  options: [
    {
      label: ReactNode;
      value: string;
      selectedBackgroundColor: string;
      selectedColor: string;
      icon?: React.FC<SVGAttributes<HTMLOrSVGElement>>;
    },
    {
      label: ReactNode;
      value: string;
      selectedBackgroundColor: string;
      selectedColor: string;
      icon?: React.FC<SVGAttributes<HTMLOrSVGElement>>;
    },
  ];
}

export function SwitchGroup({
  noneValue,
  onChange,
  value,
  options,
}: SwitchGroupProps) {
  return (
    <span className={styles.switchGroup}>
      {options.map((option) => {
        const Icon = option.icon;

        return (
          <button
            type="button"
            style={{
              // @ts-ignore
              "--selected-background-color": option.selectedBackgroundColor,
              "--selected-color": option.selectedColor,
            }}
            className={classNames(styles.button, {
              [styles.selectedButton]: value === option.value,
            })}
            onClick={() => {
              if (value === option.value) {
                onChange(noneValue);
              } else {
                onChange(option.value);
              }
            }}
          >
            {Icon && <Icon width={12} height={12} />}
            {option.label}
          </button>
        );
      })}
    </span>
  );
}
