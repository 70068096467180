import { CSSProperties } from "react";
import styles from "./VerticalSeparator.module.scss";
import classNames from "classnames";

export interface IVerticalSeparator {
  totalWidth: number;
  separatorHeight: number;
  color?: "grey" | "white";
}

export function VerticalSeparator({
  totalWidth,
  separatorHeight,
  color = "grey",
}: IVerticalSeparator) {
  return (
    <div
      className={classNames(styles.verticalSeparator, {
        [styles.grey]: color === "grey",
        [styles.white]: color === "white",
      })}
      style={
        {
          "--separator-width": `${totalWidth}px`,
          "--separator-height": `${separatorHeight}px`,
        } as CSSProperties
      }
    />
  );
}
