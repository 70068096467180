import { useState } from "react";
import {
  ClientBrandAdditionalInfoQuery,
  useClientBrandAdditionalInfoUpsertMutation,
} from "src/graphql/generated/schema";
import { ClientCreateDialogLayout } from "./ClientCreateDialogLayout";
import createClient5Image from "../assets/createClient5.png";
import { ClientCreateWizardInput } from "../misc/ClientCreateWizardInput";
import { toast } from "sonner";
import { CreateClientModal5 } from "./CreateClientModal5";

type IClientEditModal = {
  trigger: React.ReactNode;
  initialValues: ClientBrandAdditionalInfoQuery["clientBrandAdditionalInfo"];
};

export function ClientBrandAudienceEdit({
  initialValues,
  trigger,
}: IClientEditModal) {
  const [isOpen, setIsOpen] = useState(false);
  const [clientBrandAdditionalInfoUpsert] =
    useClientBrandAdditionalInfoUpsertMutation();

  async function handleSubmit(values: ClientCreateWizardInput) {
    try {
      const { clientProfile } = values;
      await clientBrandAdditionalInfoUpsert({
        variables: {
          input: {
            // only update the fields that are being edited in this modal
            audienceInterests: clientProfile.audienceInterests,
            audienceMaxAge: clientProfile.audienceMaxAge,
            audienceMinAge: clientProfile.audienceMinAge,
            audienceGender: clientProfile.audienceGender,
            targetCountryIds: clientProfile.locations.map(
              (location) => location.id,
            ),
          },
        },
      });
      toast.success("Client brand updated successfully");
    } catch (error) {
      toast.error(error);
    }
    setIsOpen(false);
  }

  function handleBack() {
    setIsOpen(false);
  }

  return (
    <ClientCreateDialogLayout
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      isEdit
      trigger={trigger}
      image={{
        src: createClient5Image,
        alt: "image of ai assistant",
        width: 600,
        height: 400,
      }}
    >
      <CreateClientModal5
        submitLabel="Save Changes"
        initialValues={{
          clientCreate: {
            invitedMembers: [],
            name: "",
            analyticsCategory: "AWARENESS",
            assetId: null,
            currencyCode: "",
            industry: "",
            timeZone: "",
            users: [],
          },
          clientProfile: {
            about: initialValues?.brandAbout ?? "",
            brandColor: initialValues?.brandColor ?? "",
            brandLogoId: initialValues?.brandLogoId ?? null,
            brandName: initialValues?.brandName ?? "",
            industry: initialValues?.brandIndustry ?? "",
            audienceMinAge: initialValues?.audienceMinAge ?? 18,
            audienceMaxAge: initialValues?.audienceMaxAge ?? 65,
            audienceGender: initialValues?.audienceGender ?? "ALL",
            locations: initialValues?.TargetCountries ?? [],
            audienceInterests: initialValues?.audienceInterests ?? "",
          },
        }}
        onBack={handleBack}
        onSubmit={handleSubmit}
      />
    </ClientCreateDialogLayout>
  );
}
