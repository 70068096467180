import React from "react";
import styles from "./SplitCard.module.scss";
import classNames from "classnames";

interface ISplitCard {
  firstColumn: React.ReactNode;
  secondColumn: React.ReactNode;
  noBorder?: boolean;
  noPaddingOnFirstColumn?: boolean;
  noPaddingOnSecondColumn?: boolean;
}

export function SplitCard({
  noBorder = false,
  noPaddingOnFirstColumn = false,
  noPaddingOnSecondColumn = false,
  firstColumn,
  secondColumn,
}: ISplitCard) {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.noBorder]: noBorder,
      })}
    >
      <div
        className={classNames(styles.firstColumn, {
          [styles.noPadding]: noPaddingOnFirstColumn,
        })}
      >
        {firstColumn}
      </div>
      <div
        className={classNames(styles.secondColumn, {
          [styles.noPadding]: noPaddingOnSecondColumn,
        })}
      >
        {secondColumn}
      </div>
    </div>
  );
}
