import { Combobox, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

import Add from "@material-symbols/svg-600/rounded/add.svg?react";

import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import { Checkbox } from "../Checkbox/Checkbox";
import Close from "@material-symbols/svg-600/rounded/close.svg?react";
import Search from "@material-symbols/svg-600/rounded/search.svg?react";
import classNames from "classnames";
import styles from "./ComboTagMaker.module.scss";
import { Chevron } from "react-day-picker";

interface Option {
  label: string;
  value: string;
}

// TODO: selected should have same type as options
export interface IComboTagMaker {
  multiple: boolean;
  selected: Option[] | Option;
  setSelected: (i: Option[] | Option) => void;
  options: Option[];
  showTags?: boolean;
  onBlur?: () => void;
  placeholder?: string;
  onChange?: (i: string) => void;
  onAddTag?: (
    addedTag: string,
    updatedTags: IComboTagMaker["selected"]
  ) => void;
  loading?: boolean;
  tagsTitle?: string;
  tagsTitleClass?: string;
}

export default function ComboTagMaker(props: IComboTagMaker) {
  const {
    multiple,
    options,
    selected,
    setSelected,
    showTags,
    loading,
    onBlur,
    placeholder = "",
    onChange,
    onAddTag,
    tagsTitle,
    tagsTitleClass,
  } = props;
  const [query, setQuery] = useState("");

  const filteredOption = options.filter((o) =>
    o?.label?.toLowerCase()?.includes(query.toLowerCase())
  );

  function addTag() {
    if (query.length === 0 || query.trim().length === 0) return;

    if (!("label" in selected) && selected.some((v) => v.label === query))
      return;

    const sanitizedTagString = query.trim();

    const updatedTags =
      "label" in selected
        ? {
            label: sanitizedTagString,
            value: sanitizedTagString,
          }
        : [
            ...selected,
            {
              label: sanitizedTagString,
              value: sanitizedTagString,
            },
          ];
    setSelected(updatedTags);
    onAddTag(sanitizedTagString, updatedTags);
  }

  function removeTag(tag: string) {
    if (Array.isArray(selected))
      setSelected(selected.filter((item) => item.value !== tag));
  }

  return (
    <>
      <Combobox
        value={selected}
        onChange={setSelected}
        multiple={multiple as any}
      >
        <div className={styles.box}>
          <div className={styles.comboBox}>
            <div className={styles.searchContainer}>
              <Search />
            </div>
            <Combobox.Input
              className={styles.input}
              placeholder={placeholder}
              onChange={(event) => {
                setQuery(event.target.value);
                onChange(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  addTag();
                }
              }}
              onBlur={onBlur}
              autoComplete="off"
            />
            <Combobox.Button className={styles.button} type="button">
              <div className={styles.dropIcon}>
                <ChevronDown fill="#C9CCCF" />
              </div>
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave={styles.comboPanelLeave}
            leaveFrom={styles.comboPanelLeaveFrom}
            leaveTo={styles.comboPanelLeaveTo}
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className={styles.Options}>
              {filteredOption.length === 0 && query !== "" ? (
                <button
                  type="button"
                  onClick={addTag}
                  className={styles.notFound}
                >
                  {loading ? (
                    "Loading"
                  ) : (
                    <>
                      Add New Tag <Add />
                    </>
                  )}
                </button>
              ) : (
                filteredOption.map((opt, index) => (
                  <Combobox.Option
                    key={index}
                    className={({ active }) =>
                      `${styles.comboOption} ${
                        active ? styles.activeOption : styles.inactiveOption
                      } ${
                        index !== filteredOption.length - 1 &&
                        styles.borderOption
                      }`
                    }
                    value={opt}
                  >
                    {({ selected }) => (
                      <div className={styles.option}>
                        <Checkbox
                          value={opt.value}
                          onChange={() => {}}
                          checked={selected}
                        />
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {opt.label}
                        </span>
                      </div>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {multiple && showTags && (
        <>
          {tagsTitle && Array.isArray(selected) && selected.length > 0 && (
            <div className={tagsTitleClass}>{tagsTitle}</div>
          )}
          <div
            className={classNames(styles.tags, {
              [styles.tagsLoading]: loading,
            })}
          >
            {Array.isArray(selected)
              ? selected.map((item, index) => (
                  <span className={styles.badge} key={index}>
                    {item.label}
                    <button
                      type="button"
                      className={styles.closeBtn}
                      onClick={() => removeTag(item.value)}
                    >
                      <Close />
                    </button>
                  </span>
                ))
              : ""}
          </div>
        </>
      )}
    </>
  );
}
