// write a function that converts a string to sentence case
// the function should take a string and a separator
// the function should return a string

export function sentenceCase(str: string, separator = " ") {
  return str
    .split(separator)
    .map((str, index) =>
      index === 0
        ? `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`
        : str.toLowerCase()
    )
    .join(" ");
}
