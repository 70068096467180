import {
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
  useFormikContext,
} from "formik";
import { useContext } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { FormikError } from "src/components/FormikError/FormikError";
import { Spinner } from "src/components/Spinner/Spinner";
import { WizardContext } from "src/components/Wizard/WizardContext";
import {
  useGoogleListAccessibleAccountsQuery,
  GoogleListAccessibleAccountsQuery,
  GooglePlatformAccounts,
} from "src/graphql/generated/schema";
import snakeCaseToTitleCase from "src/lib/snakeCaseToTitleCase";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import { Switch } from "src/modules/global/components/Switch";
import { TitleWithThumbnail } from "src/modules/global/components/TitleWithThumbnail";
import { ToggleButton } from "src/modules/global/components/ToggleButton";
import * as Yup from "yup";

const googleAdAccountsListSchema = Yup.object().shape({
  accounts: Yup.array(
    Yup.object({
      id: Yup.string().required("Account ID is required"),
    }),
  ).min(1, "At least one account is required"),
});

export function GoogleAdAccountSelector() {
  const {
    activeStepIndex,
    markStepAsDone,
    data: wizardData,
  } = useContext(WizardContext);
  const { data, loading, error } = useGoogleListAccessibleAccountsQuery();

  return (
    <StepWizardStep isStepNotActive={activeStepIndex !== 1} isCompleted={false}>
      <div className="w-[660px] pb-32">
        {loading ? (
          <div>
            <Spinner height={"full"} />
          </div>
        ) : (
          <GoogleAdAccountsList
            data={data}
            markStepAsDone={markStepAsDone}
            activeStepIndex={activeStepIndex}
            wizardData={wizardData}
          />
        )}
      </div>
    </StepWizardStep>
  );
}

function GoogleAdAccountsList({
  data,
  markStepAsDone,
  activeStepIndex,
  wizardData,
}: {
  data: GoogleListAccessibleAccountsQuery;
  markStepAsDone: (index: number, data: any) => void | Promise<void>;
  activeStepIndex: number;
  wizardData: any;
}) {
  return (
    <Formik
      initialValues={{
        accounts: [],
      }}
      validationSchema={googleAdAccountsListSchema}
      onSubmit={async function (values: FormikValues) {
        await markStepAsDone(activeStepIndex + 1, {
          ...wizardData,
          ...values,
        });
      }}
    >
      {({ handleSubmit, isSubmitting, isValid, errors, touched }) => (
        <Form>
          <div>
            <div className="pb-24">
              <LegacySectionHeading
                heading={"Choose Manager Account to get started"}
                description={
                  "Select ad account which are connected to your business account"
                }
              />
            </div>
            <div className="flex flex-col gap-12">
              {data?.googleListAccessibleAccounts.map((v) => (
                <GoogleAccountItemToggleSelector account={v} />
              ))}

              {errors && (
                <FormikError
                  formikError={errors}
                  touched={touched}
                  fieldName={"accounts"}
                />
              )}
            </div>

            <div className="flex pt-32">
              <ButtonV2
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={!isValid}
              >
                Continue
              </ButtonV2>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

function GoogleAccountItemToggleSelector({
  account,
}: {
  account: GooglePlatformAccounts;
}) {
  const { values, setFieldValue } = useFormikContext<{
    accounts: {
      id: string;
      enabled: boolean;
    }[];
  }>();
  return (
    <div className="px-16 py-12 border rounded-8">
      <div className={"flex items-center"}>
        <div className={"flex-grow"}>
          <TitleWithThumbnail
            thumbnail={{
              uri: `https://api.dicebear.com/7.x/shapes/svg?seed=${account.name}`,
              contentType: "IMAGE",
            }}
            name={account.name}
            descriptionItems={["Ad spends: 12k"]}
          />
        </div>
        <div className={"flex items-center justify-center  w-32"}>
          <BadgeV2
            label={snakeCaseToTitleCase(account.status)}
            color={account.status === "CLOSED" ? "red" : "gray"}
          />
        </div>
        <div className={"flex items-center justify-center"}>
          <Switch
            checked={Boolean(
              values?.accounts?.find((v) => v.id === account.id),
            )}
            disabled={account.status !== "ENABLED"}
            onChange={() => {
              if (values?.accounts?.find((v) => v.id === account.id)) {
                const formattedAccounts = values.accounts.filter(
                  (v) => v.id !== account.id,
                );
                setFieldValue("accounts", formattedAccounts);
                return;
              }
              setFieldValue("accounts", [
                ...values.accounts,
                {
                  id: account.id,
                  name: account.name,
                  enabled: true,
                },
              ]);
            }}
          />
        </div>
      </div>
    </div>
  );
}
