import { MetaLeadCreateTypes } from "src/modules/creative/misc/metaLeadCreateTypes";
import styles from "./FacebookLeadFormPreview.module.scss";
import {
  ChevronDown,
  ChevronLeft,
  InfoCircle,
  X,
} from "@untitled-ui/icons-react";
import { titleCase } from "src/lib/titleCase";
import classNames from "classnames";
import { MetaLeadFormScreen } from "./MetaLeadFormScreen";
import { getTotalScreensCount } from "./getTotalScreensCount";
import { CSSProperties } from "react";

interface IFacebookMetaLeadFormPreview {
  userName: string;
  leadForm: MetaLeadCreateTypes;
  userAvatar: string;
  screenIndex: number;
}

export function FacebookMetaLeadFormPreview({
  userName,
  leadForm,
  userAvatar,
  screenIndex,
}: IFacebookMetaLeadFormPreview) {
  const { prefillQuestions, customQuestion, thankYouMessage } = leadForm;
  const screensCount = getTotalScreensCount(leadForm);
  const { screen, customQuestionIndex } = getScreenBasedOnIndex(
    screenIndex,
    leadForm,
  );
  const isFirstScreen = screenIndex === 0;
  const isLastScreen = screenIndex === screensCount - 1;
  const progressPercentage = ((screenIndex + 1) / screensCount) * 100;

  const brandInfo = (
    <div className={styles.facebookPreviewPageInfo}>
      <div className={styles.facebookPreviewPageInfoAvatar}>
        <img
          className={styles.facebookPreviewPageInfoAvatarImage}
          src={userAvatar}
          width={52}
          height={52}
          alt={userName}
        />
      </div>
      <div className={styles.facebookPreviewPageInfoName}>{userName}</div>
    </div>
  );

  const PrefillQuestionScreen = () => {
    const { prefillQuestionsDescription } = leadForm;

    return (
      <>
        {isFirstScreen && brandInfo}
        <div className={styles.facebookCard}>
          <div className={styles.prefillQuestionsHeading}>
            Contact Information
            <InfoCircle height={10} width={10} />
          </div>

          <div className={styles.prefillQuestionsDescription}>
            {prefillQuestionsDescription}
          </div>

          <div className={styles.facebookPrefillFields}>
            {prefillQuestions.map((question, index) => (
              <div key={index} className={styles.facebookPrefillField}>
                <div className={styles.facebookPrefillFieldName}>
                  {titleCase(question, "_")}
                </div>
                <div className={styles.facebookPrefillFieldValue}>
                  Enter your answer
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const selectedCustomQuestion = customQuestion.isEnabled
    ? customQuestion.questions[customQuestionIndex]
    : null;

  const CustomQuestionScreen = () => {
    const questionType = selectedCustomQuestion?.type;
    const isSelectQuestion =
      questionType === "SELECT_ONE" || questionType === "YES_OR_NO";

    return (
      <>
        {isFirstScreen && brandInfo}
        <div className={styles.facebookCard}>
          {isSelectQuestion && <CustomQuestionOption />}
          {questionType === "TEXT" && <CustomQuestionText />}
        </div>
      </>
    );
  };

  function CustomQuestionOption() {
    const yesOrNoOptions = ["Yes", "No"];
    const questionType = selectedCustomQuestion?.type;
    const questionsOptions =
      questionType === "YES_OR_NO"
        ? yesOrNoOptions
        : selectedCustomQuestion?.options;

    return (
      <div className={styles.facebookSelectQuestion}>
        <div className={styles.facebookSelectQuestionLabel}>
          {selectedCustomQuestion.label}
        </div>
        <div className={styles.facebookSelectQuestionOptions}>
          {questionsOptions.length > 5 ? (
            <div className={styles.facebookSelectQuestionSelect}>
              <span className={styles.facebookSelectQuestionSelectText}>
                {questionsOptions[0]}
              </span>
              <ChevronDown height={19} width={19} />
            </div>
          ) : (
            questionsOptions.map((option, index) => (
              <div key={index} className={styles.facebookSelectQuestionOption}>
                <span className={styles.facebookSelectQuestionOptionText}>
                  {option}
                </span>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }

  function CustomQuestionText() {
    return (
      <div className={styles.facebookTextQuestion}>
        <div className={styles.facebookTextQuestionLabel}>
          {selectedCustomQuestion.label}
        </div>
        <input
          type="text"
          disabled
          placeholder="Enter your answer"
          className={styles.facebookTextQuestionInput}
        />
      </div>
    );
  }

  const ThankYouScreen = () => {
    const { thankYouMessage } = leadForm;

    return (
      <div className={styles.thankYouScreen}>
        <img
          className={styles.thankYouPageAvatar}
          src={userAvatar}
          width={32}
          height={32}
          alt="Thank you"
        />
        <div
          className={classNames(styles.facebookCard, styles.thankYouPageCard)}
        >
          <div className={styles.thankYouPageName}>{userName}</div>
          <div className={styles.thankYouHeading}>
            {thankYouMessage?.headline}
          </div>
          <div className={styles.thankYouDescription}>
            {thankYouMessage?.description}
          </div>

          <div className={styles.additionalInfo}>
            <InfoCircle height={10} width={10} />
            Your response has been submitted.
          </div>
        </div>
      </div>
    );
  };

  const PrivacyScreen = () => {
    const { prefillQuestions, privacyPolicy } = leadForm;
    const privacyPolicyLabel =
      privacyPolicy.label || `View ${userName} Privacy Policy`;

    return (
      <div className={styles.privacyScreen}>
        <div className={styles.facebookCard}>
          <div className={styles.reviewInfoHeading}>Review your info</div>
          <div className={styles.reviewInfoDescription}>Personal info</div>
          <div className={styles.reviewInfoFields}>
            {prefillQuestions.map((question, index) => (
              <div key={index} className={styles.reviewInfoField}>
                {titleCase(question, "_")}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.facebookCard}>
          <div className={styles.privacyPolicyHeading}>Privacy policy</div>
          <div className={styles.privacyPolicyDescription}>
            By clicking Submit, you agree to send your info to {userName} who
            agrees to use it according to their privacy policy. Facebook will
            also use it subject to our Data Policy, including to auto-fill forms
            for ads.{" "}
            <span className={styles.underline}>View Facebook Data Policy.</span>{" "}
            <span className={styles.underline}>{privacyPolicyLabel}.</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.facebookPreview}>
      <div
        className={classNames(styles.facebookPreviewHeader, {
          [styles.facebookPreviewHeaderWithoutBack]:
            isFirstScreen || isLastScreen,
        })}
      >
        {!isFirstScreen && !isLastScreen && (
          <button type="button">
            <ChevronLeft height={16} width={16} />
          </button>
        )}
        <button type="button">
          <X height={16} width={16} />
        </button>
      </div>

      <div className={styles.facebookPreviewBody}>
        {screen === "PREFILL_QUESTIONS" && <PrefillQuestionScreen />}
        {screen === "CUSTOM_QUESTIONS" && <CustomQuestionScreen />}
        {screen === "THANK_YOU" && <ThankYouScreen />}
        {screen === "PRIVACY" && <PrivacyScreen />}
      </div>

      <div className={styles.facebookPreviewFooter}>
        {/* progress */}
        <div className={styles.facebookPreviewFooterProgress}>
          <div
            style={
              {
                "--fb-progress-fill-width": `${progressPercentage}%`,
              } as CSSProperties
            }
            className={styles.facebookPreviewFooterProgressFill}
          />
        </div>

        <button type="button" className={styles.facebookPreviewFooterButton}>
          {screen === "THANK_YOU"
            ? thankYouMessage.additionalAction.type === "CALL_BUSINESS"
              ? "Call Now"
              : "View Website"
            : "Continue"}
        </button>
      </div>
    </div>
  );
}

type ScreenValue = {
  screen: MetaLeadFormScreen;
  customQuestionIndex: number;
};

function getScreenBasedOnIndex(
  currentIndex: number,
  leadForm: MetaLeadCreateTypes,
): ScreenValue {
  const { customQuestion } = leadForm;
  const hasCustomQuestions =
    customQuestion.isEnabled && customQuestion.questions.length > 0;
  let mappingOfScreenToIndex = [];

  if (hasCustomQuestions) {
    mappingOfScreenToIndex = customQuestion.questions.map((_, index) => ({
      screen: "CUSTOM_QUESTIONS",
      customQuestionIndex: index,
    }));
  }

  // Add the rest of the screens
  mappingOfScreenToIndex.push({
    screen: "PREFILL_QUESTIONS",
    customQuestionIndex: 0,
  });
  mappingOfScreenToIndex.push({ screen: "PRIVACY", customQuestionIndex: 0 });
  mappingOfScreenToIndex.push({ screen: "THANK_YOU", customQuestionIndex: 0 });

  return mappingOfScreenToIndex[currentIndex];
}
