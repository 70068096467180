import { useCreateAsset } from "src/modules/campaign-wizard/functions/useCreateAsset";
import {
  Organization,
  useOrganizationUpdateMutation,
} from "../../../graphql/generated/schema";
import styles from "./OrganizationGeneralSettings.module.scss";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { UploadProfilePicture } from "src/components/UploadProfilePicture/UploadProfilePicture";
import { toast } from "sonner";
import { Text } from "src/components/Text/Text";

interface IOrganizationProfilePicture {
  organization: Organization;
}

export function OrganizationGeneralSettings({
  organization,
}: IOrganizationProfilePicture) {
  const [organizationUpdateFunc] = useOrganizationUpdateMutation();

  const { handleFileUpload, loading } = useCreateAsset({
    maxImageFileSizeInBytes: 1000 * 1000 * 10, // 10MB
    maxVideoFileSizeInBytes: 0, // video not allowed
    minimumHeight: 256,
    minimumWidth: 256,
  });

  async function handleUpdate(file: File) {
    const asset = await handleFileUpload(file);
    try {
      await organizationUpdateFunc({
        variables: {
          input: {
            thumbnailId: asset.id,
          },
        },
        refetchQueries: ["getUser"],
        awaitRefetchQueries: true,
      });

      toast.success("Updated profile picture successfully");
    } catch (err) {
      toast.error(err.message);
    }
  }

  function handleRemove() {
    // updateThumbnail(null);
  }

  return (
    <div className={styles.generalSettings}>
      <div className={styles.profilePictureSection}>
        <SectionHeader
          title="Organisation information"
          description="Edit your organisation general information here."
        />
        <UploadProfilePicture
          helpText="Pick a logo for your organisation. Recommended size is 400x400 pixels"
          currentAsset={organization.Thumbnail}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
          updateLoading={loading}
          deleteLoading={false}
        />
      </div>
      <div className="border-t border-neutral-50 py-14 px-20 flex flex-col gap-4">
        <Text tone="subdued" size="2xs" weight="medium">
          Organization Name
        </Text>
        <Text tone="neutral-600">{organization.name}</Text>
      </div>
      {/* <div className="border-t border-neutral-50 py-14 px-20 flex flex-col gap-4">
        <Text tone="subdued" size="2xs" weight="medium">
          Admin Email
        </Text>
        <Text tone="neutral-600">{organization.email}</Text>
      </div> */}
    </div>
  );
}
