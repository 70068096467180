import styles from "./InlineSuggestions.module.scss";
import { Text } from "../Text/Text";
import { Loading02, Plus } from "@untitled-ui/icons-react";
import { Chip } from "../Chip/Chip";
import { Skeleton } from "src/components/Skeleton/Skeleton";

interface IInlineSuggestions {
  name: string;
  suggestions: {
    label: string;
    onClick: () => void;
  }[];
  loading: boolean;
}

export function InlineSuggestions({
  name,
  suggestions,
  loading,
}: IInlineSuggestions) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <Text noWrap size="xs" tone="subdued" weight="medium">
          {name}:
        </Text>
      </div>

      {loading ? (
        <Skeleton
          count={6}
          baseColor="var(--color-gray-200)"
          highlightColor="var(--color-gray-400)"
          containerClassName={styles.skeletonContainer}
          className={styles.skeleton}
        />
      ) : (
        <div className={styles.suggestions}>
          {suggestions.map((a) => (
            <Chip onClick={a.onClick} leftIcon={Plus} title={a.label} />
          ))}
        </div>
      )}
    </div>
  );
}
