import React, { CSSProperties } from "react";
import classNames from "classnames";
import styles from "./Tooltip.module.scss";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { Text } from "../Text/Text";

export interface ITooltip {
  children: React.ReactNode;
  content: string | React.ReactNode;
  isInline?: boolean;
  maxWidth?: string;
  className?: string;
  align?: RadixTooltip.TooltipContentProps["align"];
  side?: RadixTooltip.TooltipContentProps["side"];
}

export default function Tooltip({
  children,
  isInline = true,
  content,
  // className,
  maxWidth = "200px",
  align = "center",
  side = "top",
}: ITooltip) {
  return (
    <RadixTooltip.Provider delayDuration={100}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger
          type="button"
          className={classNames({
            [styles.flexTrigger]: !isInline,
          })}
        >
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            style={{ "--tooltip-max-width": maxWidth } as CSSProperties}
            className={styles.content}
            align={align}
            side={side}
            sideOffset={4}
          >
            <Text font="euclid" size="xs">
              {content}
            </Text>
            <RadixTooltip.Arrow
              height={6}
              width={12}
              radius={2}
              className={classNames(styles.arrow, {
                [styles.arrowBottomShadow]: side === "bottom",
                [styles.arrowTopShadow]:
                  side === "top" || side === "right" || side === "left",
              })}
            />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
