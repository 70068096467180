import { useContext } from "react";
import AuthContext from "src/stores/AuthContext";
import { currencyList } from "../../../currency";

export function useGetActiveCurrencySymbol() {
  const { activeClient } = useContext(AuthContext);

  const usd = currencyList.find((a) => a.code === "USD");
  if (!activeClient) {
    return usd.symbol;
  }
  const selectedCurrency = currencyList.find(
    (a) => a.code === activeClient.currencyCode,
  );

  return selectedCurrency?.symbol ?? usd.symbol;
}
