import classNames from "classnames";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { Text } from "../Text/Text";
import styles from "./EmptyState.module.scss";

export interface IEmptyState {
  illustration?: string;
  title: string;
  description?: string;
  buttonText?: string;
  buttonVariant?: "link" | "outline" | "plain" | "solid";
  onClick?: () => void;
  to?: string;
  align?: "left" | "center";
}

export default function EmptyState({
  illustration,
  title,
  description,
  buttonText,
  buttonVariant = "solid",
  onClick,
  to,
  align = "left",
}: IEmptyState) {
  const containerClasses = classNames(styles.container, {
    [styles.alignCenter]: align === "center",
    [styles.alignLeft]: align === "left",
  });

  return (
    <div className={containerClasses}>
      {illustration && (
        <div className={styles.illustration}>
          <img src={illustration} alt="Placeholder" />
        </div>
      )}
      <Text as="h2" size="lg" weight="medium" align={align}>
        {title}
      </Text>
      {description && (
        <div className={styles.description}>
          <Text as="p" size="xs" tone="subdued" align={align}>
            {description}
          </Text>
        </div>
      )}
      {(onClick || to) && buttonText && (
        <div className={styles.actionWrapper}>
          <ButtonV2
            onClick={onClick}
            size="small"
            variant={buttonVariant}
            to={to}
          >
            {buttonText}
          </ButtonV2>
        </div>
      )}
    </div>
  );
}
