import styles from "./OverviewGraphCard.module.scss";
import classNames from "classnames";
import {
  ILineGraphV2,
  LineGraphV2,
} from "src/modules/global/components/LineGraphV2";
import { titleCase } from "../../../lib/titleCase";
import { useMemo } from "react";
import { Text } from "src/components/Text/Text";

export function OverviewGraphCard({
  title,
  subtitle,
  action,
  value,
  chartData,
  graphClassName,
  cardClassName,
  yAxis,
}: {
  title: string;
  subtitle: string;
  action?: React.ReactNode;
  value: string;
  chartData: { time: any; [x: string]: any }[];
  yAxis: ILineGraphV2["yAxis"];
  graphClassName?: string;
  cardClassName?: string;
}) {
  const values = useMemo(() => {
    if (!chartData) return [];
    return chartData.map((v) => ({
      ...v,
      value: v[yAxis.dataKey] || 0,
    }));
  }, [chartData, yAxis.dataKey]);

  return (
    <div className={classNames(styles.card, cardClassName)}>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderContent}>
          <Text size="sm" weight="medium">
            {title}
          </Text>
          <Text size="xs" weight="regular" tone="subdued">
            {subtitle}
          </Text>
          <Text size="xl" weight="medium">
            {value}
          </Text>
        </div>
        {action}
      </div>
      <div className={classNames(styles.cardGraph, graphClassName)}>
        <LineGraphV2
          height={"100%"}
          width={"100%"}
          values={values}
          statLabel={{
            color: "#8884d8",
            name: titleCase(yAxis.dataKey),
            dataKey: yAxis.dataKey,
          }}
          xAxis={{
            dataKey: "time",
          }}
          yAxis={yAxis}
        />
        {/* <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="2%" stopColor="#8884d8" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            {yAxis && (
              <YAxis
                dataKey={chartDataKey}
                interval="preserveStartEnd"
                strokeOpacity={0}
                {...yAxis}
              />
            )}
            <XAxis
              dataKey="time"
              interval="preserveStartEnd"
              strokeOpacity={0}
            />
            <Area
              type="linear"
              dataKey={chartDataKey}
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorValue)"
            />
            <CartesianGrid horizontal={false} stroke="#C9CCCF3D" />
          </AreaChart>
        </ResponsiveContainer> */}
      </div>
    </div>
  );
}
