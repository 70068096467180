import styles from "./ResourceOverview.module.scss";
import { RadioGroup } from "@headlessui/react";
import { ReactNode, useMemo, useState } from "react";
import classNames from "classnames";
import Tooltip from "src/components/Tooltip/Tooltip";
import { GrowPerTickType } from "../misc/GrowPerTickType";
import { formatNumberByType } from "../functions/formatNumberByType";
import { GraphNotAvailableYet } from "src/modules/global/components/GraphNotAvailableYet";
import { addCommas } from "src/lib/addCommas";
import { removeDecimalIfZeros } from "src/lib/removeDecimalIfZeros";
import { TimelineGraph } from "src/modules/global/components/TimelineGraph";
import { useGetActiveCurrencySymbol } from "../functions/useGetActiveCurrencySymbol";
import Header from "src/components/Header/Header";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { InfoCircle, Settings04 } from "@untitled-ui/icons-react";
import { Text } from "src/components/Text/Text";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";

interface IResourceOverview {
  chartData: GrowPerTickType[];
  stats: {
    label: string;
    description: string;
    value: string;
    id: string;
    statKey: string;
    statType: "CURRENCY" | "NUMBER" | "MULTIPLIER" | "PERCENTAGE";
  }[];
  source?: {
    icon: ReactNode;
    label: string;
  };
}

export function ResourceOverview({
  chartData,
  stats,
  source,
}: IResourceOverview) {
  const [selectedStatId, setSelectedStatId] = useState(stats[0].id);
  // const [showCompareAt, setShowCompareAt] = useState(false);
  const selectedCurrencyCode = useGetActiveCurrencySymbol();

  function handleSelectedStatIdChange(value: string) {
    setSelectedStatId(value);
  }

  const selectedKeyStat = useMemo(
    () => stats.find((v) => v.id === selectedStatId),
    [selectedStatId, stats],
  );

  return (
    <section className={styles.overviewSection}>
      <div className={styles.keyStatsSection}>
        <div className="flex justify-between items-center px-14 py-16">
          <SectionHeader title={"Overview"} size="large" />
          <ButtonV2 leftIcon={Settings04} variant="outline" size="small" />
        </div>
        <RadioGroup
          as="div"
          className="flex flex-col p-14 pt-0 gap-14 "
          onChange={handleSelectedStatIdChange}
          value={selectedStatId}
        >
          {stats.map((stat) => (
            <RadioGroup.Option value={stat.id}>
              {({ checked }) => (
                <div
                  className={classNames(styles.keyStatItem, {
                    [styles.selectedKeyStatItem]: checked,
                  })}
                >
                  <div className={styles.keyStatContent}>
                    <Text size="sm" weight="medium">
                      {stat.label}
                    </Text>
                    <Text size="xs" tone="subdued">
                      {stat.description}
                    </Text>
                  </div>
                  <div>
                    <Text size="sm" weight="medium">
                      {stat.value}
                    </Text>
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>

      <div>
        <div className={"flex flex-col px-16 py-14"}>
          <div className={styles.graphHeader}>
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <Text size="md">{selectedKeyStat.label}</Text>

                <Tooltip content="Hello world">
                  <InfoCircle width={14} height={14} />
                </Tooltip>
              </div>
              <Text size="lg" weight="regular">
                {selectedKeyStat.value}
              </Text>
              <Text size="xs" tone="subdued">
                Last updated 4 hours ago
              </Text>
            </div>
          </div>

          {chartData?.length === 0 ? (
            <GraphNotAvailableYet />
          ) : (
            <div className={styles.graph}>
              <TimelineGraph
                statValues={chartData}
                height={263}
                statLabel={{
                  color: "var(--color-primary)",
                  name: selectedKeyStat.label,
                  dataKey: selectedKeyStat?.statKey,
                }}
                width={"100%"}
                yAxis={{
                  tooltipFormatter: (val) =>
                    addCommas(removeDecimalIfZeros(Number(val))),
                  tickFormatter: (v) =>
                    formatNumberByType(v as any, selectedKeyStat.statType, {
                      showDecimal: true,
                      selectedCurrencyCode,
                    }),
                  dataKey: selectedKeyStat?.statKey,
                  label: selectedKeyStat?.label,
                }}
              />
            </div>
          )}
        </div>

        <div className="flex justify-between py-16 px-12 border-t">
          <div>
            {source && (
              <span className={styles.graphPlatforms}>
                {source.label}
                {source.icon}
              </span>
            )}
          </div>
          <div className={styles.legendSection}>
            <span className={classNames(styles.legend)}>
              <span
                className={classNames(
                  styles.legendIndicator,
                  styles.mainValueIndicator,
                )}
              />
              Last 7 Days
            </span>
            <span className={classNames(styles.legend)}>
              <span
                className={classNames(
                  styles.legendIndicator,
                  styles.compareValueIndicator,
                )}
              />
              Previous 7 Days
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
