import { CreativePreview } from "../../global/components/CreativePreview";
import placeholderAdItem from "../misc/placeholderAdItem";
import styles from "./AdvancedPlacementModalCreativePreview.module.scss";
import { Text } from "src/components/Text/Text";

function AdvancedPlacementModalCreativePreview({ platform, metaFormat }) {
  return (
    <div className={styles.container}>
      <Text font="redhat" align="center" size="lg" weight="medium">
        Preview Placement
      </Text>
      <CreativePreview
        platform={platform}
        metaFormat={metaFormat}
        ad={placeholderAdItem}
        screenSize={"315px"}
        setActiveAssetIndex={function (i: number): void {}}
        activeAssetIndex={0}
        accountName={"Hello"}
        profilePicture={"https://cataas.com/cat?width=100"}
      />
    </div>
  );
}

export default AdvancedPlacementModalCreativePreview;
