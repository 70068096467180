import styles from "./MetaLeadFormFields.module.scss";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { MetaLeadPrivacyPolicyFields } from "./MetaLeadPrivacyPolicyFields";
import { MetaLeadThankYouFields } from "./MetaLeadThankYouFields";
import { MetaLeadBackgroundImageFields } from "./MetaLeadBackgroundImageFields";
import { MetaLeadAudienceGreetingFields } from "./MetaLeadAudienceGrettingFields";
import { MetaLeadCustomQuestionFields } from "./MetaLeadCustomQuestionFields";
import { MetaLeadPrefillQuestionFields } from "./MetaLeadPrefillQuestionFields";
import { MetaLeadCustomDisclaimer } from "./MetaLeadCustomDisclaimer";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";

interface IMetaLeadFormFields {
  onCancel: () => void;
}

export function MetaLeadFormFields({ onCancel }: IMetaLeadFormFields) {
  return (
    <div className={styles.formFields}>
      <InputFormikV2
        autoFocus
        placeholder="Eg. Sale offer ad template, discount template"
        name="name"
        label="Form name"
      />
      <div className={styles.optionalSettings}>
        {/* <MetaLeadBackgroundImageFields />
        <MetaLeadAudienceGreetingFields /> */}
        <MetaLeadCustomQuestionFields />
      </div>

      <MetaLeadPrefillQuestionFields />
      <MetaLeadPrivacyPolicyFields />
      <MetaLeadCustomDisclaimer />
      <MetaLeadThankYouFields />

      <div className={styles.buttonGroup}>
        <ButtonV2 onClick={onCancel} variant="outline">
          Cancel
        </ButtonV2>
        <ButtonV2 type="submit">Save Template</ButtonV2>
      </div>
    </div>
  );
}
