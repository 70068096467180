import React from "react";
import { Icon } from "../Icon/Icon";
import { IconWithWrapper } from "../IconWithWrapper/IconWithWrapper";
import { Text } from "../Text/Text";
import styles from "./SectionHeader.module.scss";

interface SectionTitleProps {
  title: string;
  titleMetaData?: React.ReactNode;
  titleAs?: any;
  description?: React.ReactNode;
  icon?: React.FC<React.SVGAttributes<SVGElement>>;
  iconColor?: "default" | "danger" | "info" | "warning" | "success";
  size?: "default" | "large";
  hasIconWrapper?: boolean;
}

export function SectionHeader({
  title,
  titleMetaData,
  description,
  icon,
  titleAs,
  iconColor = "default",
  size = "default",
  hasIconWrapper = false,
}: SectionTitleProps) {
  const TitleTag = titleAs || "h3";
  const titleSize = size === "large" ? "lg" : "md";
  const descriptionSize = size === "large" ? "md" : "xs";

  // Render icon with wrapper if hasIconWrapper is true or if description and icon are present
  const RenderIcon =
    (icon && hasIconWrapper) || (description && icon) ? (
      <IconWithWrapper icon={icon} iconColor={iconColor} size={"lg"} />
    ) : icon ? (
      <Icon icon={icon} iconColor={iconColor} size={"md"} />
    ) : null;

  return (
    <div className={styles.container}>
      {RenderIcon}
      <div className={styles.textContent}>
        <div className="flex gap-8 items-center">
          <Text size={titleSize} weight="medium" as={TitleTag} font="redhat">
            {title}
          </Text>
          <div>{titleMetaData}</div>
        </div>
        {description && (
          <Text size={descriptionSize} tone="subdued">
            {description}
          </Text>
        )}
      </div>
    </div>
  );
}
