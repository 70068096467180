import { useGrowAggregatedStats } from "./useGrowAggregatedStats";

type FilterOptions = {
  dateFrom?: Date | null;
  dateTo?: Date | null;
};

/**
 * returns aggregated stats for campaign with comparison values
 */
export function useCampaignAggregatedStats(
  campaignId: string,
  opts: FilterOptions
) {
  const growAggregatedStats = useGrowAggregatedStats({
    // aggregated all the insight from activity
    // don't aggregated adset insights or ad insights
    // as this is duplicate insights
    others: { campaignId, insightType: "ACTIVITY_INSIGHT" },
    ...(opts.dateFrom && { dateFrom: opts.dateFrom }),
    ...(opts.dateTo && { dateTo: opts.dateTo }),
  });

  return growAggregatedStats;
}
