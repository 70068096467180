import Cursor03 from "@untitled-ui/icons-react/build/esm/Cursor03";
import PhoneCall01 from "@untitled-ui/icons-react/build/esm/PhoneCall01";
import HeartHand from "@untitled-ui/icons-react/build/esm/HeartHand";
import { useFormikContext } from "formik";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";
import { MetaLeadCreateTypes } from "../misc/metaLeadCreateTypes";
import { FormGroupHeaderWithSwitch } from "./FormGroupHeaderWithSwitch";
import styles from "./MetaLeadThankYouFields.module.scss";

export function MetaLeadThankYouFields() {
  const { values, setFieldValue } = useFormikContext<MetaLeadCreateTypes>();

  return (
    <div className={styles.section}>
      <FormGroupHeaderWithSwitch
        description="Let people know what happens next."
        title="Message for leads"
        icon={HeartHand}
        hasSwitch={false}
      />

      <div className={styles.fields}>
        <InputFormikV2
          placeholder="Thank you for filling this form"
          label="Headline (Required)"
          name="thankYouMessage.headline"
        />
        <InputFormikV2
          placeholder="Our customer representative will get in touch with you"
          label="Description (Required)"
          name="thankYouMessage.description"
        />
        <SelectV2
          label="Additional Action"
          options={thankYouActionOptions}
          onChange={(value) => {
            setFieldValue("thankYouMessage.additionalAction.type", value);
          }}
          value={values.thankYouMessage.additionalAction.type}
        />
        <InputFormikV2
          placeholder="View Website"
          label="Button Text"
          name="thankYouMessage.additionalAction.label"
        />
        {values.thankYouMessage.additionalAction.type === "VIEW_WEBSITE" && (
          <InputFormikV2
            placeholder="https://example.com"
            label="Link"
            name="thankYouMessage.additionalAction.link"
          />
        )}
        {values.thankYouMessage.additionalAction.type === "CALL_BUSINESS" && (
          <PhoneNumberInput
            defaultPhoneCode="91"
            placeholder="9876543210"
            countryCodeOnChange={(value) => {
              setFieldValue(
                "thankYouMessage.additionalAction.phoneCountryCode",
                value
              );
            }}
            countryCodeValue={
              values.thankYouMessage.additionalAction.phoneCountryCode
            }
            onChange={(value) => {
              setFieldValue(
                "thankYouMessage.additionalAction.phoneNumber",
                value
              );
            }}
            value={values.thankYouMessage.additionalAction.phoneNumber}
            label="Phone Number"
            name="thankYouMessage.additionalAction.link"
          />
        )}
      </div>
    </div>
  );
}

const thankYouActionOptions = [
  {
    label: "Go to website",
    value: "VIEW_WEBSITE",
    icon: Cursor03,
  },
  {
    label: "Call",
    value: "CALL_BUSINESS",
    icon: PhoneCall01,
  },
];
