import {
  FormikErrors,
  FormikTouched,
  useField,
  useFormikContext,
} from "formik";
import { FormFieldStatus } from "src/components/FormFieldStatus/FormFieldStatus";

export function FormikError({
  fieldName,
}: {
  // @deprecated
  formikError?: FormikErrors<any>;
  // @deprecated
  touched?: FormikTouched<any>;
  // @deprecated
  submitCount?: number;

  fieldName: string;
}) {
  const { submitCount } = useFormikContext();
  const [_, { touched, error }] = useField(fieldName);

  return (
    <>
      {typeof error === "string" && (touched || submitCount > 0) ? (
        <FormFieldStatus type="error" message={error} />
      ) : null}
    </>
  );
}
