import { AnalyticsCategory } from "src/graphql/generated/schema";

interface GrowStatOptions {
  label: string;
  valueType: "CURRENCY" | "NUMBER" | "MULTIPLIER" | "PERCENTAGE";
  valueKey: string;
  showAccountAverageComparison?: boolean;
  performanceDirection?: "HIGHER_IS_BETTER" | "LOWER_IS_BETTER";
}

const commonStats: GrowStatOptions[] = [
  { label: "Impressions", valueType: "NUMBER", valueKey: "impressions" },
  { label: "Reach", valueType: "NUMBER", valueKey: "reach" },
  { label: "Clicks", valueType: "NUMBER", valueKey: "clicks" },
  {
    showAccountAverageComparison: true,
    performanceDirection: "HIGHER_IS_BETTER",
    label: "CTR",
    valueType: "PERCENTAGE",
    valueKey: "ctr",
  },
  {
    showAccountAverageComparison: true,
    performanceDirection: "LOWER_IS_BETTER",
    label: "CPM",
    valueType: "CURRENCY",
    valueKey: "cpm",
  },
  { label: "Spend", valueType: "CURRENCY", valueKey: "spend" },
];

export const transactionalStats: GrowStatOptions[] = [
  ...commonStats,
  {
    label: "Cost Per Purchase",
    showAccountAverageComparison: true,
    performanceDirection: "LOWER_IS_BETTER",
    valueType: "CURRENCY",
    valueKey: "costPerPurchase",
  },
  { label: "Purchase Count", valueType: "NUMBER", valueKey: "countPurchase" },
  {
    label: "Total Purchase Value",
    valueType: "CURRENCY",
    valueKey: "totalValuePurchase",
  },
  {
    label: "Cost Per Checkout",
    valueType: "CURRENCY",
    valueKey: "costPerInitiateCheckout",
  },
  {
    label: "Checkout Count",
    valueType: "NUMBER",
    valueKey: "countInitiateCheckout",
  },
  {
    label: "Total Checkout Value",
    valueType: "CURRENCY",
    valueKey: "totalValueInitiateCheckout",
  },
  {
    label: "Cost Per Cart Add",
    valueType: "CURRENCY",
    valueKey: "costPerAddToCart",
  },
  { label: "Cart Add Count", valueType: "NUMBER", valueKey: "countAddToCart" },
  {
    label: "Total Cart Value",
    valueType: "CURRENCY",
    valueKey: "totalValueAddToCart",
  },
  {
    label: "Cost Per Payment Info",
    valueType: "CURRENCY",
    valueKey: "costPerAddPaymentInfo",
  },
  {
    label: "Payment Info Count",
    valueType: "NUMBER",
    valueKey: "countAddPaymentInfo",
  },
  {
    label: "Total Payment Value",
    valueType: "CURRENCY",
    valueKey: "totalValueAddPaymentInfo",
  },
];

export const leadsStats: GrowStatOptions[] = [
  ...commonStats,
  {
    label: "Cost Per Lead",
    valueType: "CURRENCY",
    valueKey: "costPerLead",
    showAccountAverageComparison: true,
    performanceDirection: "LOWER_IS_BETTER",
  },
  { label: "Lead Count", valueType: "NUMBER", valueKey: "countLead" },
  {
    label: "Message Count",
    valueType: "NUMBER",
    valueKey: "messagingConversationStarted7d",
  },
  {
    label: "Cost Per Message",
    valueType: "CURRENCY",
    valueKey: "costPerMessagingConversationStarted7d",
    showAccountAverageComparison: true,
    performanceDirection: "LOWER_IS_BETTER",
  },
];

export const engagementStats: GrowStatOptions[] = [
  ...commonStats,
  {
    label: "Cost Per Post Engagement",
    valueType: "CURRENCY",
    valueKey: "costPerPostEngagement",
    showAccountAverageComparison: true,
    performanceDirection: "LOWER_IS_BETTER",
  },
  {
    label: "Post Engagement Count",
    valueType: "NUMBER",
    valueKey: "countPostEngagement",
  },
  {
    label: "Message Count",
    valueType: "NUMBER",
    valueKey: "messagingConversationStarted7d",
  },
  {
    label: "Cost Per Message",
    valueType: "CURRENCY",
    valueKey: "costPerMessagingConversationStarted7d",
    showAccountAverageComparison: true,
    performanceDirection: "LOWER_IS_BETTER",
  },
  // {
  //   label: "Total Post Value",
  //   valueType: "CURRENCY",
  //   valueKey: "totalValuePostEngagement",
  // },
  // {
  //   label: "Cost Per Page Engagement",
  //   valueType: "CURRENCY",
  //   valueKey: "costPerPageEngagement",
  // },
  // {
  //   label: "Page Engagement Count",
  //   valueType: "NUMBER",
  //   valueKey: "countPageEngagement",
  // },
  // {
  //   label: "Total Page Value",
  //   valueType: "CURRENCY",
  //   valueKey: "totalValuePageEngagement",
  // },
  // {
  //   label: "Cost Per Link Click",
  //   valueType: "CURRENCY",
  //   valueKey: "costPerLinkClick",
  // },
  // {
  //   label: "Link Click Count",
  //   valueType: "NUMBER",
  //   valueKey: "countLinkClick",
  // },
  // {
  //   label: "Total Link Value",
  //   valueType: "CURRENCY",
  //   valueKey: "totalValueLinkClick",
  // },
  // {
  //   label: "Cost Per Content View",
  //   valueType: "CURRENCY",
  //   valueKey: "costPerViewContent",
  // },
  // {
  //   label: "Content View Count",
  //   valueType: "NUMBER",
  //   valueKey: "countViewContent",
  // },
  // {
  //   label: "Total Content Value",
  //   valueType: "CURRENCY",
  //   valueKey: "totalValueViewContent",
  // },
];

export const trafficStats: GrowStatOptions[] = [
  ...commonStats,
  { label: "CPC", valueType: "CURRENCY", valueKey: "cpc" },
  { label: "CPM", valueType: "CURRENCY", valueKey: "cpm" },
  {
    label: "ROAS",
    valueType: "MULTIPLIER",
    valueKey: "roas",
    showAccountAverageComparison: true,
    performanceDirection: "HIGHER_IS_BETTER",
  },
  {
    label: "Cost Per Link Click",
    valueType: "CURRENCY",
    valueKey: "costPerLinkClick",
    showAccountAverageComparison: true,
    performanceDirection: "LOWER_IS_BETTER",
  },
  {
    label: "Link Click Count",
    valueType: "NUMBER",
    valueKey: "countLinkClick",
  },
  // {
  //   label: "Total Link Value",
  //   valueType: "CURRENCY",
  //   valueKey: "totalValueLinkClick",
  // },
  // {
  //   label: "Cost Per Landing View",
  //   valueType: "CURRENCY",
  //   valueKey: "costPerLandingPageView",
  // },
  // {
  //   label: "Landing View Count",
  //   valueType: "NUMBER",
  //   valueKey: "countLandingPageView",
  // },
  // {
  //   label: "Total Landing Value",
  //   valueType: "CURRENCY",
  //   valueKey: "totalValueLandingPageView",
  // },
];

export const awarenessStats: GrowStatOptions[] = [
  ...commonStats,
  { label: "Frequency", valueType: "NUMBER", valueKey: "frequency" },
  { label: "Video Views", valueType: "NUMBER", valueKey: "videoView" },
  {
    label: "Video Completion Rate",
    valueType: "NUMBER",
    valueKey: "videoThruplayWatched",
  },
  {
    label: "Landing Page Views",
    valueType: "NUMBER",
    valueKey: "landingPageView",
  },
  {
    label: "Cost Per Video View",
    valueType: "CURRENCY",
    valueKey: "costPerVideoView",
    performanceDirection: "LOWER_IS_BETTER",
    showAccountAverageComparison: true,
  },
  {
    label: "Video View Count",
    valueType: "NUMBER",
    valueKey: "countVideoView",
  },
  // {
  //   label: "Total Video Value",
  //   valueType: "CURRENCY",
  //   valueKey: "totalValueVideoView",
  // },
  // {
  //   label: "Cost Per Content View",
  //   valueType: "CURRENCY",
  //   valueKey: "costPerViewContent",
  // },
  // {
  //   label: "Content View Count",
  //   valueType: "NUMBER",
  //   valueKey: "countViewContent",
  // },
  // {
  //   label: "Total Content Value",
  //   valueType: "CURRENCY",
  //   valueKey: "totalValueViewContent",
  // },
];

export const growStatOptions: Record<AnalyticsCategory, GrowStatOptions[]> = {
  TRANSACTIONAL: transactionalStats,
  LEADS: leadsStats,
  ENGAGEMENT: engagementStats,
  TRAFFIC: trafficStats,
  AWARENESS: awarenessStats,
};
