import classNames from "classnames";
import styles from "./Flex.module.scss";

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
  gap?: string;
}

const Flex: React.FC<FlexProps> = ({
  children,
  flexDirection,
  justifyContent,
  alignItems = "center",
  gap,
  className,
  ...props
}) => {
  const style = {
    "--flex-direction": flexDirection,
    "--justify-content": justifyContent,
    "--align-items": alignItems,
    "--gap": gap,
  } as React.CSSProperties;

  return (
    <div
      className={classNames(styles.flexContainer, className)}
      style={style}
      {...props}
    >
      {children}
    </div>
  );
};

export default Flex;
