import * as Yup from "yup";
import { Audience_Type } from "src/graphql/generated/schema";

const interestValueSchema = Yup.object({
  value: Yup.string()
    .oneOf(["INCLUDE", "EXCLUDE", "NONE"])
    .required("Value is required"),
  audienceSizeLowerBound: Yup.number().nullable(),
  audienceSizeUpperBound: Yup.number().nullable(),
  description: Yup.string().nullable(),
  id: Yup.string().required("Id is required"),
  name: Yup.string().required("Name is required"),
  path: Yup.array(Yup.string()).nullable(),
  type: Yup.string().required("Type is required"),
});

const audiencePresetValueSchema = Yup.object({
  value: Yup.string()
    .oneOf(["INCLUDE", "EXCLUDE", "NONE"])
    .required("Value is required"),
  name: Yup.string().required("Name is required"),
  description: Yup.string().nullable(),
  id: Yup.string().required("Id is required"),
  icon: Yup.mixed(),
  platforms: Yup.array(Yup.string().oneOf(["FACEBOOK", "INSTAGRAM"])),
  group: Yup.object({
    label: Yup.string().required("Group label is required"),
    value: Yup.string().required("Group value is required"),
  }),
  filteringSource: Yup.string()
    .oneOf(["INSTAGRAM", "FACEBOOK", "VIDEO", "AD", "WEBSITE"])
    .required("Filtering source is required"),
  audienceType: Yup.mixed<Audience_Type>()
    .oneOf(
      Object.values([
        "FACEBOOK_ENGAGERS",
        "FACEBOOK_ENGAGERS_LOOKALIKE",
        "FACEBOOK_LOOKALIKE",
        "FACEBOOK_PIXEL_BASED",
        "FACEBOOK_PIXEL_BASED_LOOKALIKE",
        "FACEBOOK_SEGMENT_BASED",
        "INSTAGRAM_ENGAGERS",
        "INSTAGRAM_ENGAGERS_LOOKALIKE",
      ]),
    )
    .required("Audience type is required"),
  filter: Yup.object().required("Filter is required"),
});

export const audienceInterestsAndPresetsValidation = {
  interests: Yup.lazy((obj) => {
    if (!obj || Object.keys(obj).length === 0) {
      return Yup.object({}).nullable();
    }

    const shape = Object.keys(obj).reduce((acc, key) => {
      acc[key] = interestValueSchema;
      return acc;
    }, {});

    return Yup.object(shape);
  }),

  audiencePresets: Yup.lazy((obj) => {
    if (!obj) {
      return Yup.object({}).nullable();
    }

    const shape = Object.keys(obj).reduce((acc, key) => {
      acc[key] = audiencePresetValueSchema;
      return acc;
    }, {});

    return Yup.object(shape);
  }),
};

export const audienceAndPresetSchema = Yup.object(
  audienceInterestsAndPresetsValidation,
);
