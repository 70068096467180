import { Form, Formik, FormikValues } from "formik";
import { useContext } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { FormikError } from "src/components/FormikError/FormikError";
import { WizardContext } from "src/components/Wizard/WizardContext";
import { LegacySectionHeading } from "src/modules/global/components/LegacySectionHeading";
import { StepWizardStep } from "src/modules/global/components/StepWizardStep";
import * as Yup from "yup";
import { GoogleClientAccountsList } from "./GoogleClientAccountsList";

const googleClientAccountsListSchema = Yup.object().shape({
  accounts: Yup.array(
    Yup.object({
      clientAccounts: Yup.array().min(
        1,
        "At least one client account is required",
      ),
    }),
  ).min(1, "At least one account is required"),
});

export function GoogleClientAccountSelector() {
  const {
    activeStepIndex,
    markStepAsDone,
    data: wizardData,
  } = useContext(WizardContext);

  // Assuming the previous step data is stored in wizardData.values.accounts
  const managerAccounts = wizardData?.accounts || [];

  return (
    <StepWizardStep isStepNotActive={activeStepIndex !== 2} isCompleted={false}>
      <Formik
        initialValues={{
          ...wizardData,
        }}
        validationSchema={googleClientAccountsListSchema}
        onSubmit={async function (values: FormikValues) {
          await markStepAsDone(activeStepIndex + 1, {
            ...values,
          });
        }}
      >
        {({ handleSubmit, isSubmitting, isValid, errors, touched }) => (
          <Form>
            <div className="w-[660px] pb-56">
              <div className="pb-24">
                <LegacySectionHeading
                  heading={"Choose Client Accounts"}
                  description={
                    "Select client accounts under your selected manager accounts"
                  }
                />
              </div>
              <div className="flex flex-col gap-16">
                {managerAccounts?.map((managerAccount: any) => (
                  <div key={managerAccount.id}>
                    <GoogleClientAccountsList managerAccount={managerAccount} />
                  </div>
                ))}
              </div>

              {errors && (
                <FormikError fieldName={"accounts"} formikError={errors} />
              )}

              <div className="flex pt-32">
                <ButtonV2
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Continue
                </ButtonV2>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </StepWizardStep>
  );
}
