import Modal from "src/components/Modal/Modal";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  useMetaAdQuery,
  useMetaCatalogueProductsLazyQuery,
} from "src/graphql/generated/schema";
import styles from "./ActivityAdPreviewModal.module.scss";
import { LineItem } from "src/lib/types";
import { useEffect, useState } from "react";
import { Smartphone } from "src/components/Smartphone/Smartphone";
import { generateLineItemsFromCatalogueProducts } from "../../global/functions/generateLineItemsFromCatalogueProducts";
import { generateLineItemsFromExistingPost } from "../../global/functions/generateLineItemsFromExistingPost";

interface IActivityAdPreviewModal {
  isOpen: boolean;
  onClose: (i: false) => void;
  id: string;
  catalogueId?: string;
  accountName?: string;
  profilePicture?: string;
}

export function ActivityAdPreviewModal({
  isOpen,
  onClose,
  id,
  catalogueId,
  accountName,
  profilePicture,
}: IActivityAdPreviewModal) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="80vw"
      title="Ads Preview"
      subtitle="Here are the previews of all your Ads used in this Activity."
      noContainer
    >
      <div className={styles.container}>
        <ActivityAdContent
          id={id}
          catalogueId={catalogueId}
          accountName={accountName}
          profilePicture={profilePicture}
        />
      </div>
    </Modal>
  );
}

function ActivityAdContent({ id, accountName, profilePicture, catalogueId }) {
  const [selectedLineItem, setSelectedLineItem] = useState(0);
  const { data, loading } = useMetaAdQuery({
    variables: {
      id: id,
    },
  });

  const [
    metaCatalogueFunc,
    { data: metaCatalogueProductsData, loading: metaCatalogueProductsLoading },
  ] = useMetaCatalogueProductsLazyQuery();

  useEffect(() => {
    if (!catalogueId) return;
    const getCatalogue = async () => {
      await metaCatalogueFunc({
        variables: {
          id: catalogueId,
        },
      });
    };

    getCatalogue();
  }, [catalogueId]);

  if (loading || metaCatalogueProductsLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner height="full" />
      </div>
    );
  }

  const metaAd = data?.metaAd;
  const formattedLineItems: LineItem[] =
    metaAd?.adType === "CATALOG" || catalogueId
      ? generateLineItemsFromCatalogueProducts(
          metaCatalogueProductsData
            ? metaCatalogueProductsData?.metaCatalogueProducts
            : []
        )
      : metaAd.adType === "EXISTING_POST"
      ? generateLineItemsFromExistingPost(metaAd?.ExistingPost, "")
      : metaAd?.LineItems.map((v) => ({ link: "", ...v }));

  const filteredLineItems = formattedLineItems.filter((item) =>
    Boolean(item.PostAsset)
  );

  return (
    <div className={styles.previewContainer}>
      <Smartphone
        preview={filteredLineItems}
        variant={"stories"}
        type={"instagram"}
        primaryText={metaAd?.primaryText}
        callToActionLabel={metaAd?.CTAText}
        accountName={accountName || "Hello"}
        profilePicture={profilePicture}
      />
      <Smartphone
        preview={filteredLineItems}
        variant={"feed"}
        type={"instagram"}
        primaryText={metaAd?.primaryText}
        callToActionLabel={metaAd?.CTAText}
        accountName={accountName || "Hello"}
        profilePicture={profilePicture}
      />
      <Smartphone
        preview={filteredLineItems}
        variant={"feed"}
        type={"facebook"}
        primaryText={metaAd?.primaryText}
        callToActionLabel={metaAd?.CTAText}
        accountName={accountName || "Hello"}
        profilePicture={profilePicture}
      />
    </div>
  );
}

// interface IAdsList {
//   lineItems: MetaActivityAdLineItem[];
//   setSelectedLineItem: (v: number) => void;
//   selectedLineItem: number;
// }

// function AdsList({
//   lineItems,
//   setSelectedLineItem,
//   selectedLineItem,
// }: IAdsList) {
//   return (
//     <div className={styles.flexList}>
//       <div className={styles.listSectionHeader}>Ads</div>
//       {lineItems.map((v, i) => (
//         <button
//           style={{
//             all: "unset",
//           }}
//           onClick={() => setSelectedLineItem(i)}
//         >
//           <LineItemPreview selected={i === selectedLineItem} {...v} />
//         </button>
//       ))}
//     </div>
//   );
// }

// interface ILineItemPreview extends MetaActivityAdLineItem {
//   selected: boolean;
// }

// function LineItemPreview({ headline, PostAsset, selected }: ILineItemPreview) {
//   const asset = PostAsset
//     ? PostAsset
//     : ({
//         uri: "https://placeholder.com/76/56",
//         contentType: "IMAGE",
//         name: "Placeholder",
//       } as IAsset);

//   return (
//     <div
//       className={classNames(styles.lineItem, {
//         [styles.selected]: selected,
//       })}
//     >
//       <Asset
//         type={asset.contentType}
//         name={asset.name}
//         preview={asset.uri}
//         className={styles.thumbnailAsset}
//       />
//       <p className={styles.centerAlign}>{headline}</p>
//     </div>
//   );
// }
