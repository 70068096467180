import { CheckboxGroup } from "src/components/CheckboxGroup/CheckboxGroup";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { FormGroupHeaderWithSwitch } from "./FormGroupHeaderWithSwitch";
import File05 from "@untitled-ui/icons-react/build/esm/File05";
import { SelectableItemCard } from "../../../components/SelectableItemCard/SelectableItemCard";
import { prefilledQuestionOptions } from "../misc/prefilledQuestionOptions";
import { AdjustPrefilledQuestionsOrder } from "./AdjustPrefilledQuestionsOrder";
import { useFormikContext } from "formik";
import { MetaLeadCreateTypes } from "../misc/metaLeadCreateTypes";
import styles from "./MetaLeadPrefillQuestionFields.module.scss";
import Tooltip from "src/components/Tooltip/Tooltip";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";

export function MetaLeadPrefillQuestionFields() {
  const { values, setFieldValue } = useFormikContext<MetaLeadCreateTypes>();

  return (
    <div>
      <FormGroupHeaderWithSwitch
        hasSwitch={false}
        title="Prefill Question"
        description="Ask for user information. This will be prefilled from their Facebook account."
        icon={File05}
      />
      <div className={styles.expandedSection}>
        <InputFormikV2
          label="Description (Required)"
          name="prefillQuestionsDescription"
          placeholder="Enter your message"
          helpText="Your potential leads will see this message above the form"
        />

        <div>
          <div className={styles.label}>
            Choose the type of information you need{" "}
            <Tooltip content="Prefilled based on user's Meta profile">
              <HelpCircle width={14} height={14} />
            </Tooltip>
          </div>
          <CheckboxGroup
            value={values.prefillQuestions}
            onChange={(value) => {
              setFieldValue("prefillQuestions", value);
            }}
          >
            <div className={styles.prefilledItemsList}>
              {prefilledQuestionOptions.map((option) => (
                <SelectableItemCard
                  disabled={option.disabled}
                  icon={option.icon}
                  label={option.label}
                  value={option.value}
                />
              ))}
            </div>
          </CheckboxGroup>
        </div>
      </div>

      <AdjustPrefilledQuestionsOrder />
    </div>
  );
}
