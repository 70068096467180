import { SVGAttributes } from "react";
import styles from "./CalloutCard.module.scss";
import { ButtonV2, IButtonV2 } from "../ButtonV2/ButtonV2";
import {
  BaseCalloutCard,
  BaseCalloutCardProps,
} from "../BaseCalloutCard/BaseCalloutCard";

interface ICalloutCard extends Omit<BaseCalloutCardProps, "children"> {
  primaryAction: Omit<IButtonV2, "size">;
  secondaryAction?: Omit<IButtonV2, "size">;
}

export function CalloutCard(props: ICalloutCard) {
  const { primaryAction, secondaryAction, ...rest } = props;
  return (
    <BaseCalloutCard {...rest}>
      <div className={styles.buttonsContainer}>
        <ButtonV2 {...primaryAction} size="small" />
        {secondaryAction && <ButtonV2 {...secondaryAction} size="small" />}
      </div>
    </BaseCalloutCard>
  );
}
