import { Activity, useActivityQuery } from "src/graphql/generated/schema";
import { CampaignActivityCardItem } from "./CampaignActivityCardItem";
import { useState } from "react";
import { Spinner } from "src/components/Spinner/Spinner";
import { useActivityAggregatedStats } from "../../global/functions/useActivityAggregatedStats";
import {
  CampaignActivityCardCreativesList,
  CampaignActivityCardStats,
} from "./CampaignActivityCardCreatives";
import {
  CampaignActivityCardAnalyticsStatsList,
  CampaignActivityCardAnalyticsGraphList,
} from "./CampaignActivityCardAnalytics";
import { useActivityPerTickStats } from "../../global/functions/useActivityPerTickStats";
import { TabsV2 } from "src/components/TabsV2/TabsV2";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { useGetActiveCurrencySymbol } from "src/modules/global/functions/useGetActiveCurrencySymbol";
import { useAdAggregatedStats } from "src/modules/global/functions/useAdAggregatedStats";
import { Grid } from "src/components/Grid/Grid";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { titleCase } from "src/lib/titleCase";
import { MarkerPin06 } from "@untitled-ui/icons-react";

type ActivitySummary = Pick<
  Activity,
  | "id"
  | "name"
  | "status"
  | "platform"
  | "createdAt"
  | "publishedAt"
  | "failedAt"
  | "scheduledAt"
  | "MetaActivity"
>;

export function CampaignActivityCard({
  activity,
  campaignId,
  dateFrom,
  dateTo,
}: {
  activity: ActivitySummary;
  campaignId: string;
  dateFrom?: Date;
  dateTo?: Date;
}) {
  const { data, loading } = useActivityQuery({
    variables: {
      id: activity.id,
    },
  });
  const { data: activityStatsData, loading: activityStatsLoading } =
    useActivityAggregatedStats(activity.id, {
      dateFrom,
      dateTo,
    });
  const {
    data: activityPerTickStatsData,
    loading: activityPerTickStatsLoading,
  } = useActivityPerTickStats(activity.id, {
    dateFrom,
    dateTo,
  });
  const [selectedTabs, setSelectedTabs] = useState("OVERVIEW");
  const metaActivity = data?.activity?.MetaActivity;
  const [selectedAd, setSelectedAd] = useState<string | null>(
    metaActivity?.MetaActivityAds?.[0]?.id,
  );
  const selectedCurrencyCode = useGetActiveCurrencySymbol();

  const { data: adStats, loading: adStatsLoading } = useAdAggregatedStats(
    selectedAd,
    {
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  );

  if (
    loading ||
    activityStatsLoading ||
    activityPerTickStatsLoading ||
    adStatsLoading
  ) {
    return (
      <div>
        <Spinner height={400} />
      </div>
    );
  }
  // (
  //       <CampaignActivityCardAnalytics
  //         activity={data?.activity as any}
  //         activityAggregateStats={activityStatsData}
  //         activityPerTickStats={activityPerTickStatsData}
  //       />
  //     )
  const activityCardContent = {
    OVERVIEW: {
      statsList: (
        <CampaignActivityCardAnalyticsStatsList
          activity={data?.activity as any}
          activityAggregateStats={activityStatsData}
        />
      ),
      secondaryList: (
        <CampaignActivityCardAnalyticsGraphList
          activityAggregateStats={activityStatsData}
          activityPerTickStats={activityPerTickStatsData}
        />
      ),
    },
    CREATIVES: {
      statsList: (
        <CampaignActivityCardStats
          activity={data?.activity as any}
          adStats={adStats}
          selectedCurrencyCode={selectedCurrencyCode}
        />
      ),
      secondaryList: (
        <CampaignActivityCardCreativesList
          metaActivity={metaActivity}
          selectedAd={selectedAd}
          setSelectedAd={setSelectedAd}
        />
      ),
    },
  };

  return (
    <div className="border rounded-16">
      <CampaignActivityCardHeader
        activity={data?.activity}
        campaignId={campaignId}
      />
      <Grid
        column={{
          xs: "2fr 3fr",
        }}
      >
        <div className="border-r py-16 px-12 flex flex-col gap-16">
          <TabsV2
            tabs={[
              {
                label: "Overview",
                value: "OVERVIEW",
              },
              {
                label: "Creatives",
                value: "CREATIVES",
              },
            ]}
            selectedTab={selectedTabs}
            onTabChange={setSelectedTabs}
          />
          <div className="flex flex-col gap-3 pr-5 min-w-[370px]">
            {activityCardContent[selectedTabs].statsList}
          </div>
        </div>
        <div className="p-16 flex flex-col gap-16">
          <SectionHeader
            title={titleCase(selectedTabs)}
            icon={MarkerPin06}
            hasIconWrapper
          />
          <div className="h-full">
            {activityCardContent[selectedTabs].secondaryList}
          </div>
        </div>
      </Grid>
    </div>
  );
}

function CampaignActivityCardHeader({
  activity,
  campaignId,
}: {
  activity: Pick<
    Activity,
    | "id"
    | "name"
    | "status"
    | "platform"
    | "createdAt"
    | "publishedAt"
    | "failedAt"
    | "scheduledAt"
  >;
  campaignId: string;
}) {
  return (
    <div className={"flex items-center justify-between border-b p-16"}>
      <CampaignActivityCardItem {...activity} campaignId={campaignId} />
      <div>
        <ButtonV2 to={`activities/${activity.id}`} variant="outline">
          View Activity
        </ButtonV2>
      </div>
    </div>
  );
}
