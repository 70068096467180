import { useState } from "react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { SortByInput } from "src/graphql/generated/schema";
import { CreativeListingLoader } from "../components/CreativeListingLoader";
import { CreativeFilters } from "../misc/creativeFilters";
import { Image01, Image04 } from "@untitled-ui/icons-react";
import { TableFilter } from "src/components/TableFilter/TableFilter";
import defaultSortOptions from "src/modules/global/misc/defaultSortOptions";
import { MediaListingLoader } from "../components/MediaListingLoader";
import Header from "src/components/Header/Header";
import LibraryIcon from "../../../icons/library.svg?react";
import { getDateFilterFromFilterEnum } from "src/modules/global/misc/dateFilterUtils";

const tabOptions = [
  {
    icon: Image04,
    label: "Library",
    value: "LIBRARY",
  },
  {
    icon: Image01,
    label: "Media",
    value: "MEDIA",
  },
];

const defaultDateFilter = "ALL_TIME";

export function CreativesListingPage() {
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value);

  const filterDate = getDateFilterFromFilterEnum(defaultDateFilter);
  let from, to;

  if (filterDate) {
    from = filterDate.gte;
    to = filterDate.lte;
  }

  const [filters, setFilters] = useState<CreativeFilters>({
    query: "",
    ...(filterDate && {
      createdAt: {
        from,
        to,
      },
    }),
  });

  const [sortBy, setSortBy] = useState<SortByInput>({
    direction: "DESC",
    field: "CREATED_AT",
  });

  return (
    <div>
      <Header
        title="Library"
        icon={LibraryIcon}
        iconColor="danger"
        actions={
          <>
            {/* <ButtonV2
              variant="outline"
              size="small"
              to={"/library/ad-library/create"}
            >
              Upload Media
            </ButtonV2> */}
            <ButtonV2 size="small" to={"/library/ad-library/create"}>
              Add Creative
            </ButtonV2>
          </>
        }
      />

      <div>
        <TableFilter
          tabOptions={tabOptions}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          defaultValue={defaultDateFilter}
          dateFilter={filters.createdAt}
          searchInputPlaceholder="Search Library & Media"
          searchQuery={filters.query}
          setSearchQuery={(query) => setFilters({ ...filters, query })}
          setDateFilter={(createdAt) => setFilters({ ...filters, createdAt })}
          setSortBy={(sortBy) =>
            setSortBy({
              direction: sortBy.direction,
              field: sortBy.field === "CREATED_AT" ? "CREATED_AT" : "NAME",
            })
          }
          sortBy={sortBy}
          sortOptions={defaultSortOptions}
          hasTabs={true}
        />

        {selectedTab === "LIBRARY" && (
          <CreativeListingLoader
            filters={filters}
            setFilters={setFilters}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        )}

        {selectedTab === "MEDIA" && (
          <MediaListingLoader
            filters={filters}
            setFilters={setFilters}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        )}
      </div>
    </div>
  );
}
