import { GrowAggregateType } from "../misc/GrowAggregateType";
import { StatFilterOptions } from "./StatFilterOptions";
import { useGrowAggregatedStats } from "./useGrowAggregatedStats";

export interface AdsetAggregateType extends GrowAggregateType {
  adsetId: string;
}

export interface AdsetsAggregatedStats {
  data: AdsetAggregateType;
  loading: boolean;
  error: { message: string } | null;
}

/**
 * returns aggregated stats for campaign with comparison values
 */
export function useAdsetAggregatedStats(
  adsetId: string,
  { dateFrom, dateTo }: StatFilterOptions,
  activityId?: string,
): AdsetsAggregatedStats {
  const growAggregatedStats = useGrowAggregatedStats<AdsetsAggregatedStats>({
    others: {
      ...(adsetId && { adsetId }),
      ...(activityId && { activityId }),
      insightType: "ADSET_INSIGHT",
    },
    dateFrom,
    dateTo,
  });

  return growAggregatedStats;
}
