import { ButtonV2 } from "../ButtonV2/ButtonV2";
import styles from "./Header.module.scss";
import { Text } from "../Text/Text";
import { SVGAttributes } from "react";
import { IconWithWrapper } from "../IconWithWrapper/IconWithWrapper";
import classNames from "classnames";
import { ChevronLeft } from "@untitled-ui/icons-react";
import NotificationPanel from "src/modules/global/components/NotificationPanel";
import { useNavigate } from "react-router-dom";
import { VerticalSeparator } from "../VerticalSeparator/VerticalSeparator";

export interface IHeader {
  title: string;
  actions?: React.ReactNode;
  showNotifications?: boolean;
  badges?: React.ReactNode;
  icon?: React.FC<SVGAttributes<any>>;
  hasSidebar?: boolean;
  iconColor?:
    | "danger"
    | "info"
    | "warning"
    | "success"
    | "default"
    | "primary"
    | "inherit";
  showBack?: boolean;
  borderClassName?: string;
}

export default function Header(props: IHeader) {
  const {
    title,
    actions,
    showNotifications = true,
    badges,
    icon,
    showBack,
    iconColor,
    hasSidebar = true,
    borderClassName,
  } = props;
  const navigate = useNavigate();

  return (
    <div
      className={classNames(
        styles.header,
        {
          [styles.hasSidebar]: hasSidebar,
        },
        borderClassName,
      )}
    >
      <div className={styles.titleContainer}>
        {showBack && (
          <ButtonV2
            leftIcon={ChevronLeft}
            variant="plain"
            size="small"
            onClick={() => navigate(-1)}
          />
        )}
        {icon && (
          <IconWithWrapper icon={icon} iconColor={iconColor} size="md" />
        )}
        <Text size="lg" weight="medium" as="h1" font="redhat">
          {title}
        </Text>
        {badges}
      </div>
      <div className={styles.actions}>
        {actions}
        {showNotifications && (
          <div className={styles.notifications}>
            <NotificationPanel />
          </div>
        )}
      </div>
    </div>
  );
}
