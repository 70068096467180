import { toast } from "sonner";
import { UploadProfilePicture } from "src/components/UploadProfilePicture/UploadProfilePicture";
import { useAssetQuery } from "src/graphql/generated/schema";
import { useCreateAsset } from "src/modules/campaign-wizard/functions/useCreateAsset";

export function UploadProfilePictureField({ value, onChange }) {
  const { data, loading: loadingAsset } = useAssetQuery({
    variables: {
      id: value,
    },
  });
  const { handleFileUpload, loading } = useCreateAsset({
    validMimeType: ["image/png", "image/jpeg"],
    maxImageFileSizeInBytes: 10 * 1024 * 1024,
    maxVideoFileSizeInBytes: 0,
    minimumHeight: 400,
    minimumWidth: 400,
  });

  const currentAsset = data?.asset;

  return (
    <UploadProfilePicture
      helpText="*.png, *.jpeg files up to 10MB at least 400px by 400px"
      currentAsset={currentAsset}
      updateLoading={loading}
      onUpdate={async (file) => {
        try {
          const asset = await handleFileUpload(file);
          console.log({ asset });
          onChange(asset.id);
        } catch (err) {
          toast.error(err.message);
        }
      }}
      onRemove={() => {
        onChange(null);
      }}
    />
  );
}
