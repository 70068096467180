import { useRef, useState } from "react";
import Banner from "src/components/Banner/Banner";
import Info from "@material-symbols/svg-600/rounded/info.svg?react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  MetaInterest,
  useAudienceSetQuery,
  useMetaAudienceSetCreateMutation,
  useMetaIntegrationDefaultSettingsQuery,
} from "src/graphql/generated/schema";
import { Spinner } from "src/components/Spinner/Spinner";
import { generateInterestInputFromAudienceSetInterest } from "../misc/generateInterestInputFromAudienceSetInterest";
import { generateAudienceInputFromAudienceSetAudience } from "../misc/generateAudienceInputFromAudienceSetAudience";
import styles from "./AudienceWorkshopPage.module.scss";
import { SearchMd } from "@untitled-ui/icons-react";
import {
  LocationValue,
  OtherTargetingFormState,
  ReviewAudienceAndInterestsFormState,
} from "../misc/audienceCreateTypes";
import { IAudiencePreset } from "../misc/IAudiencePreset";
import { toast } from "sonner";
import Header from "src/components/Header/Header";
import { LogOut01 } from "@untitled-ui/icons-react";
import { Form, Formik } from "formik";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { AudienceUseDefaultSettingsCheckbox } from "../components/AudienceUseDefaultSettingsCheckbox";
import { AudiencePresetReviewCard } from "../components/AudiencePresetReviewCard";
import { InterestsReviewCard } from "../components/InterestsReviewCard";
import { FormikError } from "src/components/FormikError/FormikError";
import { LocationBrowserPopover } from "../components/LocationBrowserPopover";
import { GeoLocationSearchInput } from "../components/GeoLocationSearchInput";
import { LocationInputList } from "../components/LocationInputList";
import { ComboBoxV2 } from "src/components/ComboBoxV2/ComboBoxV2";
import { metaLanguages } from "../misc/metaLanguages";
import SelectV2 from "src/components/SelectV2/SelectV2";
import { maxAgeOptions, minAgeOptions } from "../../global/misc/ageOptions";
import classNames from "classnames";
import { ErrorHandler } from "src/components/ErrorHandler";
import { audienceDuplicateSchema } from "../misc/audineceDuplicateSchema";

export function AudienceDuplicatePage() {
  const navigate = useNavigate();
  const [createAudienceSet] = useMetaAudienceSetCreateMutation();
  const [isPresetsModalOpen, setIsPresetsModalOpen] = useState(false);
  const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [existingAudience, setExistingAudience] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const audienceId = searchParams.get("audienceId");
  const {
    data: metaIntegrationDefaultSettingsData,
    loading: metaIntegrationDefaultSettingsLoading,
  } = useMetaIntegrationDefaultSettingsQuery();

  const { data, loading, error } = useAudienceSetQuery({
    variables: {
      id: audienceId,
    },
  });

  function transformInterests(interest: MetaInterest) {
    return {
      audienceSizeLowerBound: interest.audienceSizeLowerBound,
      audienceSizeUpperBound: interest.audienceSizeUpperBound,
      metaInterestId: interest.id,
      name: interest.name,
      path: interest.path,
      type: interest.type,
    };
  }

  function transformLocation(location: LocationValue) {
    return {
      metaLocationId: location.id,
      name: location.name,
      type: location.type,
      countryCode: location.countryCode,
      countryName: location.countryName,
      region: location.region,
      regionId: location.regionId,
      radius: location.radius,
    };
  }

  function transformAudience(audience: IAudiencePreset) {
    return {
      audiencePresetType: audience.id,
      category: audience.group.value,
      filter: audience.filter,
      type: audience.audienceType,
    };
  }

  async function createAudience(
    formValues: ReviewAudienceAndInterestsFormState | OtherTargetingFormState,
  ) {
    const interests = formValues.interests ?? {};
    const audiencePresets = formValues.audiencePresets ?? {};

    try {
      const res = await createAudienceSet({
        variables: {
          input: {
            name: formValues.name,
            adAccountId: formValues.adAccountId,
            instagramAccountId: formValues.instagramAccountId,
            metaPageId: formValues.pageId,
            pixelId: formValues.pixelId,
            Interests: Object.values(interests)
              .filter((v) => v.value === "INCLUDE")
              .map((interest) => transformInterests(interest)),
            ExcludeInterests: Object.values(interests)
              .filter((v) => v.value === "EXCLUDE")
              .map((interest) => transformInterests(interest)),
            Locations:
              "locations" in formValues
                ? formValues.locations
                    .filter((l) => l.value === "INCLUDE")
                    .map((l) => transformLocation(l))
                : [],
            ExcludeLocations:
              "locations" in formValues
                ? formValues.locations
                    .filter((l) => l.value === "EXCLUDE")
                    .map((l) => transformLocation(l))
                : [],
            audiencePresets: Object.values(audiencePresets)
              .filter((v) => v.value === "INCLUDE")
              .map((audience) => transformAudience(audience)),
            excludeAudiencePresets: Object.values(audiencePresets)
              .filter((v) => v.value === "EXCLUDE")
              .map((audience) => transformAudience(audience)),
            minAge: "minAge" in formValues ? formValues.minAge : 18,
            maxAge: "maxAge" in formValues ? formValues.maxAge : 65,
            gender: "gender" in formValues ? formValues.gender : "ALL",
            languages:
              "languages" in formValues
                ? formValues.languages.map((l) => l.toString())
                : [],
          },
        },
      });
      toast.success("Audience created successfully");
      navigate(`/audiences`);
    } catch (e) {
      toast.error("message" in e ? e.message : "Something went wrong");
    }
  }

  async function handleSubmit(formValues: OtherTargetingFormState) {
    await createAudience(formValues);
  }

  if (loading || metaIntegrationDefaultSettingsLoading) {
    return <Spinner height={"screen"} />;
  }

  if (!audienceId) {
    return (
      <div>
        Cannot duplicate audience.{" "}
        <ButtonV2 to="/audiences" variant="link">
          Go Back
        </ButtonV2>
      </div>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }
  let initialValues: OtherTargetingFormState = {
    adAccountId: "",
    gender: "ALL",
    instagramAccountId: "",
    languages: [],
    locations: [],
    maxAge: 65,
    minAge: 18,
    name: "",
    pageId: "",
    pixelId: "",
    useDefaultMetaAccounts: true,
  };

  if (data && data.audienceSet) {
    const olderValues = initialValues;
    const audienceSet = data && data.audienceSet;
    const includedInterests = generateInterestInputFromAudienceSetInterest(
      audienceSet.Interests,
      "INCLUDE",
    );
    const excludedInterests = generateInterestInputFromAudienceSetInterest(
      audienceSet.ExcludedInterests,
      "EXCLUDE",
    );

    const includedAudiences = generateAudienceInputFromAudienceSetAudience(
      audienceSet.Audiences,
      "INCLUDE",
    );

    const excludedAudiences = generateAudienceInputFromAudienceSetAudience(
      audienceSet.ExcludedAudiences,
      "INCLUDE",
    );

    const includedLocation =
      audienceSet.Locations?.map(({ __typename, ...v }) => ({
        ...v,
        name: v.name,
        type: v.type,
        value: "INCLUDE" as const,
        radius: 8,
      })) || [];

    const excludedLocation =
      audienceSet.ExcludeLocations?.map(({ __typename, ...v }) => ({
        ...v,
        name: v.name,
        type: v.type,
        value: "EXCLUDE" as const,
        radius: 8,
      })) || [];

    initialValues = {
      ...olderValues,
      name: audienceSet?.name ? `${audienceSet?.name} - Duplicate` : "",
      interests: { ...includedInterests, ...excludedInterests },
      audiencePresets: { ...includedAudiences, ...excludedAudiences },
      minAge: audienceSet.minAge,
      maxAge: audienceSet.maxAge,
      gender: audienceSet.gender,
      languages: audienceSet.languages,
      locations: [...includedLocation, ...excludedLocation],
      // adAccountId: data?.audienceSet?.PlatformAccount?.id,
    };
  }

  if (
    metaIntegrationDefaultSettingsData &&
    metaIntegrationDefaultSettingsData.metaIntegrationDefaultSettings
  ) {
    const olderValues = initialValues;
    initialValues = {
      ...olderValues,
      adAccountId:
        metaIntegrationDefaultSettingsData.metaIntegrationDefaultSettings
          .metaAdAccountId,
      pageId:
        metaIntegrationDefaultSettingsData.metaIntegrationDefaultSettings
          .metaPageId,
      instagramAccountId:
        metaIntegrationDefaultSettingsData.metaIntegrationDefaultSettings
          .metaInstagramAccountId,
      pixelId:
        metaIntegrationDefaultSettingsData.metaIntegrationDefaultSettings
          .metaPixelId,
      useDefaultMetaAccounts: true,
    };
  }

  return (
    <div className={styles.wrapper}>
      <Header
        hasSidebar={false}
        showNotifications={false}
        title="Audience Workshop"
        showBack
        actions={
          <>
            <ButtonV2
              to="/audiences"
              size="small"
              variant="outline"
              leftIcon={LogOut01}
            >
              Exit
            </ButtonV2>
          </>
        }
      />
      {existingAudience && (
        <Banner
          onClose={() => setExistingAudience(null)}
          content={
            <>
              Looks like same setting is also exist in your existing audience{" "}
              <ButtonV2
                variant="link"
                to={`/audience-sets/${existingAudience.id}`}
              >
                {existingAudience.name}
              </ButtonV2>
              . Please change settings to continue{" "}
            </>
          }
          icon={<Info height={14} width={14} />}
          type="error"
        />
      )}
      <div className={styles.contentWrapper}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues as OtherTargetingFormState}
          validationSchema={audienceDuplicateSchema}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            submitCount,
            isSubmitting,
            isValid,
          }) => (
            <Form className={styles.formSection}>
              <div
                className={classNames(
                  styles.formContentWrapper,
                  "flex flex-col gap-12",
                )}
              >
                <div>
                  <div className="pb-24">
                    <InputFormikV2
                      autoFocus
                      placeholder="Competitors High ticket buyers"
                      helpText="Enter a unique name to easily identify and manage your audience. This helps with organization and optimizing ad campaigns"
                      isFixedWidth
                      name="name"
                      label="Audience Name"
                    />

                    {metaIntegrationDefaultSettingsData?.hasMultipleIntegrationSettingsOptions && (
                      <AudienceUseDefaultSettingsCheckbox />
                    )}
                  </div>
                </div>

                <div>
                  <div className="flex flex-col gap-40">
                    <AudiencePresetReviewCard
                      values={values}
                      setFieldValue={setFieldValue}
                      isModalOpen={isPresetsModalOpen}
                      setIsModalOpen={setIsPresetsModalOpen}
                      adAccountId={values?.adAccountId}
                    />

                    <InterestsReviewCard
                      values={values}
                      setFieldValue={setFieldValue}
                      isModalOpen={isInterestsModalOpen}
                      setIsModalOpen={setIsInterestsModalOpen}
                      adAccountId={values?.adAccountId}
                    />
                  </div>

                  {errors.interests && (
                    <FormikError
                      formikError={errors}
                      submitCount={submitCount}
                      touched={touched}
                      fieldName="interests"
                    />
                  )}
                </div>

                <div className="p-20 bg-white rounded-12 border border-neutral-100">
                  <div className="grid grid-cols-[auto_1fr] gap-16">
                    <LocationBrowserPopover
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                    <GeoLocationSearchInput
                      supportedTypes={["city", "country", "region"]}
                      onSelect={(location) => {
                        setFieldValue("locations", [
                          ...values.locations,
                          {
                            ...location,
                            value: "INCLUDE",
                            ...(location.type === "city" && { radius: 20 }),
                          },
                        ]);
                      }}
                    />
                  </div>
                  <LocationInputList
                    locations={values.locations}
                    setFieldValue={setFieldValue}
                  />
                </div>

                <div className="flex flex-col gap-20">
                  <ComboBoxV2
                    renderSelectedValue={(value) =>
                      metaLanguages.find((m) => m.value === Number(value))
                        ?.label
                    }
                    placeholder="Search Language"
                    label="Select Languages"
                    icon={SearchMd}
                    value={values.languages}
                    options={metaLanguages.map((language: any) => ({
                      label: language.label,
                      value: language.value,
                    }))}
                    multiple
                    onChange={(value) => {
                      setFieldValue("languages", value);
                    }}
                  />
                  {errors.languages && (
                    <FormikError
                      formikError={errors}
                      submitCount={submitCount}
                      touched={touched}
                      fieldName="languages"
                    />
                  )}

                  <SelectV2
                    isFixedWidth
                    label="Select which gender you want to target"
                    value={values.gender}
                    options={[
                      { label: "All", value: "ALL" },
                      { label: "Male", value: "MALE" },
                      { label: "Female", value: "FEMALE" },
                    ]}
                    onChange={(value) => {
                      setFieldValue("gender", value);
                    }}
                  />
                  {errors.gender && (
                    <FormikError
                      formikError={errors}
                      submitCount={submitCount}
                      touched={touched}
                      fieldName="gender"
                    />
                  )}

                  <div className="grid grid-cols-[200px_200px] gap-8">
                    <SelectV2
                      label="Set minimum age"
                      options={minAgeOptions}
                      value={values.minAge}
                      error={(touched.minAge || !!submitCount) && errors.minAge}
                      onChange={(value) => {
                        setFieldValue("minAge", value);
                      }}
                    />

                    <SelectV2
                      label="Set maximum age"
                      options={maxAgeOptions}
                      value={values.maxAge}
                      error={(touched.maxAge || !!submitCount) && errors.maxAge}
                      onChange={(value) => {
                        setFieldValue("maxAge", value);
                      }}
                    />
                  </div>
                </div>

                <div className="flex gap-4 py-8">
                  <ButtonV2
                    variant="outline"
                    to="/audiences"
                    size="small"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </ButtonV2>
                  <ButtonV2
                    type="submit"
                    size="small"
                    loading={isSubmitting}
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </ButtonV2>
                </div>
              </div>
              {/* <AIChatPanel /> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
