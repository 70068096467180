import { AdInputV2 } from "./createCampaignTypes";

const placeholderAdItem: AdInputV2 = {
  primaryText:
    "This is a placeholder ad. You can see the ad and related placements here",
  adType: "CUSTOM_CREATIVE",
  adTemplateId: "1",
  callToActionLabel: "SHOP_NOW",
  LineItems: [
    {
      description: "Hello",
      headline: "Hello",
      link: "https://www.google.com",
      order: 0,
      id: "1",
      PostAsset: {
        contentType: "IMAGE",
        id: "1",
        name: "Some Asset",
        fileSize: 1024,
        type: "image/jpeg",
        uri: "https://picsum.photos/id/17/1080",
      },
      StoryAsset: {
        contentType: "IMAGE",
        id: "1",
        name: "Some Asset",
        fileSize: 1024,
        type: "image/jpeg",
        uri: "https://picsum.photos/id/17/1920/1080",
      },
    },
  ],
};

export default placeholderAdItem;
