import { useEffect } from "react";
import {
  MetaProductCatalogueQuery,
  useMetaProductCatalogueLazyQuery,
} from "../../../graphql/generated/schema";
import SelectV2 from "src/components/SelectV2/SelectV2";
import LayoutGrid01 from "@untitled-ui/icons-react/build/esm/LayoutGrid01";

interface IMetaCatalogueProductSetSelector {
  disabled?: boolean;
  catalogueId: string;
  value: string;
  onChange: (
    i: MetaProductCatalogueQuery["metaCatalogue"]["ProductSets"][0],
  ) => void;
}

export function MetaCatalogueProductSetSelector({
  disabled,
  catalogueId,
  value,
  onChange,
}: IMetaCatalogueProductSetSelector) {
  const [getMetaProductCatalogueFunc, { data, loading }] =
    useMetaProductCatalogueLazyQuery();

  useEffect(() => {
    getMetaProductCatalogueFunc({
      variables: {
        id: catalogueId,
      },
    });
  }, [catalogueId, getMetaProductCatalogueFunc]);

  const productSetsOptions =
    data && data.metaCatalogue
      ? data.metaCatalogue.ProductSets.map((v) => ({
          label: v.name,
          value: v.id,
        }))
      : [];

  return (
    <SelectV2
      icon={LayoutGrid01}
      disabled={loading || disabled}
      label="Select Product set"
      placeholder={loading ? "Loading Product Set" : "Select product set"}
      value={value}
      options={productSetsOptions}
      onChange={(id) => {
        const selectedProductSet = data.metaCatalogue.ProductSets.find(
          (p) => p.id === id,
        );
        onChange(selectedProductSet);
      }}
    />
  );
}
