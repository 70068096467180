import EmptyState from "src/components/EmptyState/EmptyState";
import { CampaignsQuery } from "src/graphql/generated/schema";
import { CampaignItem } from "./CampaignItem";
import { Table } from "src/components/Table/Table";
import { useContext, useState } from "react";
import emptyCampaign from "../assets/empty-state-campaigns.png";
import AuthContext from "src/stores/AuthContext";
import { campaignListingStatsMapping } from "../../global/misc/campaignListingStatsMapping";

interface ICampaignList {
  campaigns: CampaignsQuery["campaigns"]["edges"];
}

export function CampaignList({ campaigns }: ICampaignList) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const { activeClient } = useContext(AuthContext);
  const analyticsCategory = activeClient?.analyticsCategory;
  const campaignAnalytics = campaignListingStatsMapping[analyticsCategory];

  if (campaigns.length === 0) {
    return (
      <EmptyState
        title={"Get started with your first campaign"}
        description="Get started by creating your first campaign. Campaigns are a powerful way to reach your target audience and achieve your marketing goals."
        illustration={emptyCampaign}
        buttonText="Create Campaign"
        to="/campaigns/new"
      />
    );
  }

  return (
    <Table
      headings={[
        {
          title: "Campaigns",
        },
        campaignAnalytics["headers"][0],
        campaignAnalytics["headers"][1],
        campaignAnalytics["headers"][2],
        {
          title: "Platforms",
        },
        {
          title: "",
        },
      ]}
      selectedItems={selectedItems}
      onSelectedItemsChange={setSelectedItems}
      idExtractor={(item) => item.node.id}
      data={campaigns}
      renderItem={({ item }) => (
        <CampaignItem
          campaignAnalytics={campaignAnalytics}
          campaign={item.node}
          key={item.node.id}
        />
      )}
    />
  );
}
