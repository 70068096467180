import React, { SVGAttributes } from "react";
import { SwitchGroup } from "src/modules/global/components/SwitchGroup";
import { InputV2 } from "../InputV2/InputV2";
import { Check, Trash03, X } from "@untitled-ui/icons-react";
import styles from "./InteractiveInputCard.module.scss";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import classNames from "classnames";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";

interface IInteractiveInputCard {
  icon: React.FC<SVGAttributes<any>>;
  title: string;
  description: string;
  value: string;
  onChange: (i: string) => void;
  hasDelete: boolean;
  onDelete?: () => void;
  inputType: "TEXT" | "SWITCH";
  variant: "minimal-inline" | "inline" | "card";
  inputProps?: {
    prefix: string;
    icon: React.FC<SVGAttributes<any>>;
    postfix: string;
  };
}

export function InteractiveInputCard({
  icon,
  inputType,
  onChange,
  value,
  hasDelete,
  onDelete,
  variant,
  title,
  inputProps,
  description,
}: IInteractiveInputCard) {
  return (
    <div
      className={classNames({
        [styles.card]: variant === "card",
        [styles.inline]: variant === "inline",
        [styles.minimalInline]: variant === "minimal-inline",
      })}
    >
      <div className={styles.primarySection}>
        {icon &&
          (Array.isArray(icon) ? (
            <div className={styles.iconList}>
              {icon.map((icon) => (
                <Icon icon={icon} size={variant === "inline" ? "xl" : "md"} />
              ))}
            </div>
          ) : (
            <Icon icon={icon} size={variant === "inline" ? "xl" : "md"} />
          ))}
        <div className={styles.header}>
          <Text
            size={variant === "minimal-inline" ? "sm" : "md"}
            weight="medium"
          >
            {title}
          </Text>
          {description && (
            <Text size="2xs" tone="subdued">
              {description}
            </Text>
          )}
        </div>
      </div>

      <div className={styles.inputSection}>
        {inputType === "TEXT" ? (
          <div className={styles.inputWrapper}>
            <InputV2
              prefix={inputProps?.prefix}
              icon={inputProps?.icon}
              postfix={inputProps?.postfix}
              value={value}
              onChange={onChange}
            />
          </div>
        ) : (
          <SwitchGroup
            value={value}
            onChange={(value) => onChange(value)}
            noneValue="NONE"
            options={[
              {
                icon: Check,
                label: "Include",
                value: "INCLUDE",
                selectedColor: `var(--color-success)`,
                selectedBackgroundColor: `var(--color-success-subdued)`,
              },
              {
                icon: X,
                label: "Exclude",
                value: "EXCLUDE",
                selectedColor: `var(--color-danger)`,
                selectedBackgroundColor: `var(--color-surface-critical)`,
              },
            ]}
          />
        )}

        {hasDelete && variant !== "card" && (
          <ButtonV2
            onClick={onDelete}
            leftIcon={Trash03}
            variant="plain"
            size="small"
          />
        )}
      </div>
    </div>
  );
}
