import {
  BankNote01,
  Edit03,
  File04,
  Globe01,
  NotificationMessage,
  Pencil01,
  UserEdit,
  UsersPlus,
} from "@untitled-ui/icons-react";
import { ButtonV2 } from "src/components/ButtonV2/ButtonV2";
import { ErrorHandler } from "src/components/ErrorHandler";
import Header from "src/components/Header/Header";
import { Icon } from "src/components/Icon/Icon";
import { SectionHeader } from "src/components/SectionHeader/SectionHeader";
import { Spinner } from "src/components/Spinner/Spinner";
import { Text } from "src/components/Text/Text";
import { currencyOptions } from "src/currency";
import {
  useActiveClientQuery,
  useClientQuery,
} from "src/graphql/generated/schema";
import { BadgeV2 } from "src/modules/global/components/BadgeV2";
import { ClientThumbnail } from "src/modules/global/components/ClientThumbnail";
import { clientAnalyticsCategoryOptions } from "src/modules/global/misc/clientAnalyticsCategoryOptions";
import clientIndustryOptions from "src/modules/global/misc/clientIndustryOptions";
import { timeZoneOptions } from "src/modules/global/misc/timeZoneOptions";
import { ClientEditModal } from "../components/ClientEditModal";
import { ClientBusinessKpiEditModal } from "../components/ClientBusinessKpiEditModal";
import { Dialog } from "src/components/Dialog/Dialog";

export function ClientSinglePage() {
  const { data, loading, error } = useActiveClientQuery();

  if (loading) return <Spinner height={400} />;
  if (error) return <ErrorHandler error={error} />;

  const selectedCurrency = currencyOptions.find(
    (currency) => currency.value === data?.activeClient?.currencyCode,
  );

  const selectedTimeZone = timeZoneOptions.find(
    (timeZone) => timeZone.value === data?.activeClient?.timeZone,
  );

  const selectedIndustry = clientIndustryOptions.find(
    (industry) => industry.value === data?.activeClient?.industry,
  );

  const businessKpiOptions = clientAnalyticsCategoryOptions.find(
    (option) => option.value === data?.activeClient?.analyticsCategory,
  );

  const leadOptions = [
    {
      label: "Customer Added",
      icon: UsersPlus,
    },
    {
      label: "Signups/Registrations",
      icon: Pencil01,
    },
    {
      label: "DMs/Inbox Counts",
      icon: NotificationMessage,
    },
    {
      label: "Form Submissions",
      icon: File04,
    },
  ];

  return (
    <>
      <Header title="Client Settings" />
      <div className="flex flex-col gap-24 px-16 py-24 max-w-[820px] mx-auto w-full">
        <SectionHeader
          hasIconWrapper
          icon={UserEdit}
          title="Profile Settings"
          description="Edit brand details and manage your persona all in one place!"
        />

        <div className="flex flex-col gap-16 border border-neutral-50 rounded-8">
          <div className="flex justify-between items-start px-16 pt-16 pb-12">
            <SectionHeader
              title="Client Information"
              description="Capture essential brand information to set the foundation for the persona."
            />

            <ClientEditModal
              trigger={
                <ButtonV2
                  as={Dialog.Trigger}
                  size="small"
                  variant="outline"
                  rightIcon={Edit03}
                >
                  Edit
                </ButtonV2>
              }
              initialValues={data.activeClient}
            />
          </div>
          <div className="flex items-center gap-16 px-16 py-14 border-b border-neutral-50">
            <ClientThumbnail
              asset={data.activeClient.Thumbnail}
              clientName={data.activeClient.name}
              size="36px"
            />
            <div className="flex flex-col gap-8">
              <Text size="xs" weight="medium" tone="subdued">
                Profile Picture
              </Text>
              <Text tone="neutral-600">{data.activeClient.name}</Text>
            </div>
          </div>

          <div className="flex flex-col gap-8 px-16 py-14 border-b border-neutral-50">
            <Text size="xs" weight="medium" tone="subdued">
              Nature of Business
            </Text>
            <Text tone="neutral-600">{selectedIndustry?.label}</Text>
          </div>

          <div className="flex gap-40 p-16">
            <div className="flex flex-col gap-8">
              <Text size="xs" weight="medium" tone="subdued">
                Timezone
              </Text>
              <span className="flex items-center gap-4">
                <Icon icon={Globe01} />
                <Text tone="neutral-600">{selectedTimeZone?.label}</Text>
              </span>
            </div>

            <div className="flex flex-col gap-8">
              <Text size="xs" weight="medium" tone="subdued">
                Currency
              </Text>
              <span className="flex items-center gap-4">
                <Icon icon={BankNote01} />
                <Text tone="neutral-600">{selectedCurrency?.label}</Text>
              </span>
            </div>
          </div>
        </div>

        <div className="px-16 py-16 flex flex-col gap-16 border border-neutral-50 rounded-8">
          <div className="flex items-center justify-between">
            <SectionHeader
              title="Business KPIs"
              description="User KPIs to track and analyze your business performance."
            />

            <ClientBusinessKpiEditModal
              trigger={
                <ButtonV2
                  as={Dialog.Trigger}
                  size="small"
                  variant="outline"
                  rightIcon={Edit03}
                >
                  Edit
                </ButtonV2>
              }
              initialValues={data.activeClient}
            />
          </div>

          <div className="pt-14 flex flex-col gap-24">
            {businessKpiOptions && (
              <div className="flex flex-col gap-8">
                <div className="flex items-center gap-4">
                  <Icon icon={businessKpiOptions?.icon} />
                  <Text weight="medium" size="sm">
                    {businessKpiOptions?.title}
                  </Text>
                </div>
                <Text size="xs" tone="subdued">
                  {businessKpiOptions?.subtitle}
                </Text>
              </div>
            )}

            <div className="flex flex-col gap-8">
              <Text size="xs" weight="medium" tone="subdued">
                Leads Option
              </Text>
              <div className="flex gap-8">
                {leadOptions.map((option) => (
                  <BadgeV2 label={option.label} leftIcon={option.icon} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
