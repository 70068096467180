import styles from "./UploadProfilePicture.module.scss";
import { Asset } from "src/graphql/generated/schema";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { Trash01, UploadCloud02 } from "@untitled-ui/icons-react";
import { useRef } from "react";

type AssetBareBone = Pick<Asset, "uri" | "name" | "contentType">;

export interface IUploadProfilePicture {
  helpText: string;
  currentAsset: AssetBareBone | null | undefined;
  onUpdate: (file: File) => void;
  updateLoading?: boolean;
  deleteLoading?: boolean;
  onRemove: () => void;
}

export function UploadProfilePicture({
  currentAsset,
  helpText,
  onUpdate,
  updateLoading,
  deleteLoading,
  onRemove,
}: IUploadProfilePicture) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  function handleClick() {
    fileInputRef.current?.click();
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (file) {
      onUpdate(file);
    }
  }

  return (
    <div className={styles.uploadSection}>
      {currentAsset ? (
        <img
          width={46}
          height={46}
          src={currentAsset?.uri}
          alt={currentAsset?.name}
          className={styles.asset}
        />
      ) : (
        <div className={styles.assetBlank}>
          <UploadCloud02 width={20} height={20} />
        </div>
      )}

      <div className={styles.actionContainer}>
        <div className={styles.actionButtons}>
          <input
            ref={fileInputRef}
            accept="image/*"
            type="file"
            onChange={handleFileChange}
            className={styles.fileInput}
            hidden
          />
          <ButtonV2
            loading={updateLoading}
            size="small"
            variant="outline"
            onClick={handleClick}
          >
            Upload Image
          </ButtonV2>
          <ButtonV2
            loading={deleteLoading}
            size="small"
            variant="outline"
            onClick={onRemove}
            leftIcon={Trash01}
          />
        </div>
        <div className={styles.description}>{helpText}</div>
      </div>
    </div>
  );
}
