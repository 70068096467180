import { Dialog } from "src/components/Dialog/Dialog";
import { Organization } from "../../../graphql/generated/schema";
import { useState } from "react";
import { Text } from "src/components/Text/Text";
import pagePurgeIllustration from "../assets/pagePurger.png";

interface IDeleteOrganizationAlert {
  trigger: React.ReactNode;
  organization: Organization;
  onDelete: () => void;
}

// TODO: Extract this to alert component
// in future if this design is used in other places
export function DeleteOrganizationAlert({
  trigger,
  onDelete,
  organization,
}: IDeleteOrganizationAlert) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog
      maxWidth="540px"
      trigger={trigger}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Dialog.Header
        title="Deletion request"
        subtitle="Verify organisation deletion request"
      />

      <img src={pagePurgeIllustration} alt="page purge" />

      <div className="flex flex-col gap-20 pt-24 pb-16">
        <Text size="sm" tone="subdued">
          If you are sure you want to proceed with the deletion of the
          organisation "
          <strong className="font-medium text-gray-900">
            {organization.name}
          </strong>
          " Keep in mind this operation is irreversible and will result in a
          complete deletion of all the data associated with the organisation.
        </Text>
        <Text size="sm" tone="subdued">
          I acknowledge that all of the data will be deleted and want to
          proceed.
        </Text>
      </div>

      <Dialog.Footer
        dismissButtonText="Cancel"
        actionButtonText="Delete Organisation"
        onAction={onDelete}
      />
    </Dialog>
  );
}
