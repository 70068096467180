export const percentageChange = (a, b) => {
  const change = (Math.abs(b - a) / b) * 100;

  if (isNaN(change)) {
    return "";
  }

  if (change === Infinity) {
    return "";
  }

  if (change === -Infinity) {
    return "";
  }

  if (change === 0) {
    return "";
  }

  if (change > 100) {
    return `${Math.round(change)}%`;
  }

  return `${change.toFixed(1)}%`;
};
