import AddLink from "@material-symbols/svg-600/rounded/add_link.svg?react";
import { ActivityInputV2, AdInputV2 } from "../misc/createCampaignTypes";
import styles from "./AdSingleImageOrVideoFields.module.scss";
import { useFormikContext } from "formik";
import { PageWelcomeMessageSelector } from "./PageWelcomeMessageSelector";
import classNames from "classnames";
import { PhoneNumberInput } from "src/modules/global/components/PhoneNumberInput";
import { AdCallToActionLabelField } from "./AdCallToActionLabelField";
import { InputFormikV2 } from "src/components/InputFormikV2/InputFormikV2";
import { MultilineInput } from "src/components/MultilineInput/MultilineInput";
import Link01 from "@untitled-ui/icons-react/build/esm/Link01";

interface IAdSingleImageOrVideo {
  selectedAdIndex: number;
  selectedAd: AdInputV2;
}

export function AdSingleImageOrVideoFields({
  selectedAdIndex,
  selectedAd,
}: IAdSingleImageOrVideo) {
  const { values, setFieldValue } = useFormikContext<ActivityInputV2>();

  const shouldShowPageWelcomeMessage =
    values.metaPurpose === "MESSAGE_INSTAGRAM" ||
    values.metaPurpose === "MESSAGE_MESSENGER" ||
    values.metaPurpose === "MESSAGE_WHATSAPP";

  const shouldShowLink =
    values.metaPurpose === "SALES_STANDARD" ||
    values.metaPurpose === "PROMOTE_POST" ||
    values.metaPurpose === "WEBSITE_VIEWS";

  const shouldShowPhoneNumber = values.metaPurpose === "CALL";

  const showAdTitleAndDescriptionAndCta =
    values.metaPurpose !== "VIDEO_2_SEC_VIEW" &&
    values.metaPurpose !== "VIDEO_THRUPLAY";

  return (
    <div className={styles.adSingleFields}>
      {showAdTitleAndDescriptionAndCta && (
        <>
          <div className={classNames(styles.linksSection)}>
            <AdCallToActionLabelField
              name={`Ads[${selectedAdIndex}].callToActionLabel`}
              value={selectedAd.callToActionLabel}
              adType={selectedAd.adType}
            />

            {shouldShowPhoneNumber && (
              <PhoneNumberInput
                disabled
                defaultPhoneCode="+91"
                label="Phone Number"
                value={selectedAd.phoneNumber}
                countryCodeValue={selectedAd.phoneCountryCode}
                countryCodeOnChange={(value) => {
                  setFieldValue(
                    `Ads[${selectedAdIndex}].phoneCountryCode`,
                    value,
                  );
                }}
                onChange={(value) => {
                  setFieldValue(`Ads[${selectedAdIndex}].phoneNumber`, value);
                }}
              />
            )}

            {shouldShowLink && (
              <InputFormikV2
                name={`Ads[${selectedAdIndex}].LineItems[0].link`}
                label="Website URL"
                icon={Link01}
              />
            )}
          </div>
        </>
      )}

      {showAdTitleAndDescriptionAndCta && selectedAd.LineItems[0].headline && (
        <InputFormikV2
          disabled={true}
          name={`Ads[${selectedAdIndex}].LineItems[0].headline`}
          label="Ad Title"
          maxCharacterCount={40}
          showCharacterCount={true}
        />
      )}

      {showAdTitleAndDescriptionAndCta &&
        selectedAd.LineItems[0].description && (
          <InputFormikV2
            disabled={true}
            name={`Ads[${selectedAdIndex}].LineItems[0].description`}
            label="Ad Description"
            maxCharacterCount={40}
            showCharacterCount={true}
          />
        )}

      <MultilineInput
        disabled={true}
        showCharacterCount={true}
        maxCharacterCount={500}
        value={selectedAd.primaryText}
        onChange={(value) => {
          setFieldValue(`Ads[${selectedAdIndex}].primaryText`, value);
        }}
        helpText="This is the caption of the ad"
        label="Caption text"
        rows={3}
      />

      {shouldShowPageWelcomeMessage && (
        <div>
          <PageWelcomeMessageSelector
            value={selectedAd.pageWelcomeMessageId}
            onChange={() => {
              /** field is disabled */
            }}
          />
        </div>
      )}
    </div>
  );
}
