import { Spinner } from "src/components/Spinner/Spinner";
import { useCampaignsLazyQuery } from "src/graphql/generated/schema";
import { CampaignList } from "./CampaignList";
import { useState, useLayoutEffect } from "react";
import { ErrorHandler } from "src/components/ErrorHandler";
import { PaginationInput } from "../misc/paginationInput";
import { campaignFilters, campaignSortBy } from "../misc/campaignFilter";
import { useDebounceEffect } from "../../global/hooks/useDebounceEffect";
import InfiniteScroll from "react-infinite-scroll-component";
import { itemsPerPage } from "src/modules/global/misc/itemsPerPage";

interface ICampaignListing {
  filters: campaignFilters;
  sortBy: campaignSortBy;
}

export function CampaignListing({ filters, sortBy }: ICampaignListing) {
  const [pagination, setPagination] = useState<PaginationInput>({
    limit: itemsPerPage,
    cursor: null,
  });
  const [campaignsFn, { error, data, loading, called, fetchMore }] =
    useCampaignsLazyQuery();

  async function triggerFetch({ filters, pagination, sortBy }) {
    const query = filters.query.trim();
    await campaignsFn({
      variables: {
        filters: {
          name: query,
          ...(pagination.cursor && {
            cursor: pagination.cursor,
          }),
          limit: pagination.limit,
          createdAt: filters.createdAt,
        },
        sortBy,
      },
    });
  }

  useLayoutEffect(() => {
    triggerFetch({ filters, pagination, sortBy });
  }, [sortBy, pagination, filters]);

  useDebounceEffect(
    async () => {
      await triggerFetch({ filters, pagination, sortBy });
    },
    300,
    [filters.query],
  );

  async function fetchMoreCampaigns() {
    await fetchMore({
      variables: {
        filters: {
          cursor: data?.campaigns?.pageInfo.endCursor,
        },
      },

      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.campaigns?.edges ?? [];
        const oldEdges = previousResult?.campaigns?.edges ?? [];

        return {
          campaigns: {
            ...fetchMoreResult.campaigns,
            edges: [...oldEdges, ...newEdges],
          },
        };
      },
    });
  }

  // const showPagination = useMemo(() => {
  //   if (!data) {
  //     return false;
  //   }

  //   return showPaginationChecks({
  //     itemEdges: data.allCampaigns?.edges,
  //     pageInfo: data.allCampaigns?.pageInfo,
  //     itemsPerPage: campaignItemsPerPage,
  //   });
  // }, [data]);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (loading || !called) {
    return <Spinner height={500} />;
  }

  return (
    <InfiniteScroll
      next={fetchMoreCampaigns}
      hasMore={data?.campaigns?.pageInfo?.hasNextPage || false}
      loader={<Spinner height={200} />}
      dataLength={data?.campaigns?.edges?.length || 0}
    >
      <CampaignList campaigns={data?.campaigns?.edges || []} />
    </InfiniteScroll>
  );
}
