import * as Yup from "yup";
import { audienceInterestsAndPresetsValidation } from "./audienceAndPresetSchema";
import { locationValueSchema } from "./geoLocationSchema";

export const audienceDuplicateSchema = Yup.object({
  name: Yup.string().required("Name is required").max(50).min(3),
  useDefaultMetaAccounts: Yup.bool().required(),
  pageId: Yup.string().required("Meta page id is required"),
  adAccountId: Yup.string().required("ad account id is required"),
  instagramAccountId: Yup.string().nullable(),
  // .required("instagram account id is required"),
  pixelId: Yup.string().nullable(),
  ...audienceInterestsAndPresetsValidation,
  locations: Yup.array().of(locationValueSchema),
  minAge: Yup.number()
    .positive("Minimum age must be positive")
    .min(18, "Minimum age must be 18")
    .max(64, "Maximum age must be 64")
    .required("Minimum age is required"),
  maxAge: Yup.number()
    .positive("Maximum age must be positive")
    .required("Maximum age is required")
    .min(19, "Minimum age must be 19")
    .max(65, "Maximum age must be 65")
    .min(
      Yup.ref("minAge"),
      "Maximum age must be greater than or equal to minimum age",
    ),
  gender: Yup.string()
    .oneOf(["MALE", "FEMALE", "ALL"])
    .required("Gender is required"),
  languages: Yup.array()
    .of(Yup.string())
    .required("At least one language is required"),
});
