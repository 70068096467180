import { ReactNode } from "react";
import { sidebarDefaultSteps } from "../../modules/global/components/SidebarDefaultSteps";
import { Sidebar } from "../../modules/global/components/Sidebar";
import { SidebarSettings } from "../../modules/global/components/SidebarSettings";
import styles from "./Layout.module.scss";
import { User } from "src/graphql/generated/schema";
import classNames from "classnames";

export type LayoutType =
  | "centeredNoSidebar"
  | "centeredWithSidebar" // NOT WORKING NEED TO FIX AND IMPLEMENT
  | "fullWidthWithSidebar"
  | "fullWidthNoSidebar";

interface ILayout {
  children: ReactNode;
  user?: User;
  layoutType: LayoutType;
}

export default function Layout({
  children,
  user,
  layoutType = "fullWidthWithSidebar",
}: ILayout) {
  const showSidebar = layoutType.includes("WithSidebar");

  return (
    <div
      className={classNames(styles.layout, {
        [styles.layoutWithSidebar]: showSidebar,
      })}
    >
      {showSidebar &&
        (window.location.pathname.includes("/settings") ? (
          <SidebarSettings />
        ) : (
          <Sidebar config={sidebarDefaultSteps} user={user} />
        ))}
      <div
        className={classNames(styles.contentWrapper, {
          [styles.contentWithSidebar]: showSidebar,
          [styles.contentCentered]: layoutType.startsWith("centered"),
          [styles.contentFullWidth]: layoutType.startsWith("fullWidth"),
        })}
      >
        {layoutType.startsWith("centered") && (
          <div className={styles.contentInner}>{children}</div>
        )}
        {layoutType.startsWith("fullWidth") && children}
      </div>
    </div>
  );
}
